import React, { useRef } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';

const SearchWithHandler = ({ searchHandler, className, classNameInput }) => {
  const searchInput = useRef(null);
  const { t } = useTranslation('owner');

  /**
   * @summary get new files
   * @param {Event} e
   */
  const onInputHandler = (e) => {
    const value = e.target.value;
    searchHandler(value);
  };

  return (
    <div className={cn('search__field', className)}>
      <DebounceInput
        inputRef={searchInput}
        className={cn('search__field__input', classNameInput)}
        debounceTimeout={500}
        onChange={onInputHandler}
        placeholder={t('common.search')}
      />
    </div>
  );
};

SearchWithHandler.defaultProps = {
  searchHandler: () => {},
};

export default SearchWithHandler;
