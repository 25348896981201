// Refactoring №3
import React, { useEffect, useRef } from 'react';

import { ipfsLink } from 'utils/ipfs/link';

import { Panorama360 } from 'containers/ai-generator/Panorama360';

import CN from 'classnames';
import s from './style.module.scss';

const ImageContent = ({
  file,
  entityContent,
  fileContentRef,
  ipfsService,
  ipfsKey,
  isSvg = false,
  footerContent,
}) => {
  const svgRef = useRef(null);
  const isPanorama = file.mime === 'hdri';

  useEffect(() => {
    if (isSvg) {
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString(
        entityContent,
        'image/svg+xml'
      );
      const svgElement = svgDocument.documentElement;
      svgRef.current.innerHTML = '';
      svgRef.current.appendChild(svgElement);
    }
  }, [entityContent]);

  const content = isPanorama ? (
    <div className={s.panoramaContainer}>
      <Panorama360 shouldRotate={true} imageUrl={entityContent} />
    </div>
  ) : (
    <img
      loading="lazy"
      className={s.image}
      alt={file?.name ? file?.name : ''}
      src={ipfsService ? ipfsLink(ipfsKey) : entityContent}
    />
  );

  return (
    <div className={s.imageContainer}>
      <div
        className={CN(
          isSvg ? s.imageContent__svg : s.imageContent,
          isPanorama && s.panoramaContent
        )}
        ref={fileContentRef}
      >
        {isSvg ? <div ref={svgRef}></div> : content}
        <div className={s.fileName}>{footerContent}</div>
      </div>
    </div>
  );
};

export default ImageContent;
