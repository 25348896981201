export default {
  doc: {
    label: '#206ACF',
    background: '#EBF7FF',
    stroke: '#5BA1F5',
  },
  jpg: {
    label: '#206ACF',
    background: '#EBF7FF',
    stroke: '#5BA1F5',
  },
  jpeg: {
    label: '#206ACF',
    background: '#EBF7FF',
    stroke: '#5BA1F5',
  },
  pdf: {
    label: '#E02A39',
    background: '#FFEBEB',
    stroke: '#E02A39',
  },
  svg: {
    label: '#206ACF',
    background: '#EBF7FF',
    stroke: '#5BA1F5',
  },

  default: {
    label: '#206ACF',
    background: '#EBF7FF',
    stroke: '#5BA1F5',
  },
}
