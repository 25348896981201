import React from 'react'

const FolderIconDrag = ({ className, color }) => {
  return (
    <svg
      width="32"
      height="22"
      viewBox="4 5 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g filter="url(#folder-icon-drag-filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6994 4.7998H4.64772V6.8809H4.59998V27.1998H33.6791V6.8809H15.8474L14.6994 4.7998Z"
          fill={color}
        />
      </g>
      <rect
        x="5.65625"
        y="8.12939"
        width="25.9069"
        height="2.42156"
        rx="1.21078"
        fill="white"
      />
      <g filter="url(#folder-icon-drag-filter1_d)">
        <mask id="path-3-inside-1" fill="white">
          <path d="M3 9.03711H35L33.6781 27.1994H4.59893L3 9.03711Z" />
        </mask>
        <path
          d="M3 9.03711H35L33.6781 27.1994H4.59893L3 9.03711Z"
          fill="url(#folder-icon-drag-paint0_linear)"
        />
        <path
          d="M3 9.03711V8.53711H2.45405L2.50193 9.08096L3 9.03711ZM35 9.03711L35.4987 9.07341L35.5377 8.53711H35V9.03711ZM33.6781 27.1994V27.6994H34.143L34.1768 27.2357L33.6781 27.1994ZM4.59893 27.1994L4.10086 27.2433L4.14101 27.6994H4.59893V27.1994ZM3 9.53711H35V8.53711H3V9.53711ZM34.5013 9.00081L33.1794 27.1631L34.1768 27.2357L35.4987 9.07341L34.5013 9.00081ZM33.6781 26.6994H4.59893V27.6994H33.6781V26.6994ZM5.097 27.1556L3.49807 8.99326L2.50193 9.08096L4.10086 27.2433L5.097 27.1556Z"
          fill="white"
          mask="url(#path-3-inside-1)"
        />
      </g>
      <defs>
        <filter
          id="folder-icon-drag-filter0_d"
          x="2.59998"
          y="2.7998"
          width="37.0791"
          height="30.4"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="folder-icon-drag-filter1_d"
          x="0"
          y="6.03711"
          width="50"
          height="36.1623"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="6" dy="6" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0520105 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <linearGradient
          id="folder-icon-drag-paint0_linear"
          x1="19"
          y1="9.03711"
          x2="19"
          y2="27.1994"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9DCE3" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  )
}

FolderIconDrag.defaultProps = {
  color: '#7E8A9F',
}

export default FolderIconDrag
