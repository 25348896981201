import { CANCEL_FOLDER_UPLOADING } from '../actions/file/cancel-folder-uploading.action';

export const initialState = {
  isFolderUploadingCanceled: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case CANCEL_FOLDER_UPLOADING:
      return {
        ...state,
        isFolderUploadingCanceled: action.payload.isFolderUploadingCanceled,
      };

    default:
      return state;
  }
};
