import { DECRYPTED_KEYS } from 'config/file-action-types';

export const getKeyFromLS = ({ slug }: { slug: string }) => {
  const localStorageKeys = localStorage.getItem(DECRYPTED_KEYS);
  const parsedLocalStorageKeys = localStorageKeys
    ? JSON.parse(localStorageKeys)
    : {};

  return parsedLocalStorageKeys[slug];
};
