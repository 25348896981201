//Refactoring №3
import React, { useEffect, useRef } from 'react';
import CN from 'classnames';

import { ReactComponent as LinkIcon } from 'static/assets/svg/link.svg';

import styles from './style.module.scss';

const Title = ({ title, placeholder, onChange }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef?.current.focus();
  }, [inputRef]);

  const handleChange = ({ target }) => {
    onChange(target.value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.defaultLogo}>
        <LinkIcon />
      </div>
      <div className={CN(styles.inputWrapper)}>
        <input
          ref={inputRef}
          value={title}
          onChange={handleChange}
          type="text"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Title;
