import axios from 'axios';

const REACT_APP_UNREAL_SPEECH =
  'TOUgT1eNOjQ2iCvOmHTEjZI26vC0HQELE71cVkjT0gs48u1bbfuq2w';

export const unrealSpeechStream = async (text) => {
  const requestBody = {
    Text: text,
    VoiceId: 'Liv',
    Bitrate: '192k',
    Speed: '0.2',
    Pitch: '1',
    Codec: 'libmp3lame',
    Temperature: 0.25,
  };

  const config = {
    headers: {
      accept: 'audio/mpeg',
      'content-type': 'application/json',
      Authorization: `Bearer ${REACT_APP_UNREAL_SPEECH}`,
    },
    responseType: 'arraybuffer',
  };

  try {
    const response = await axios.post(
      'https://api.v6.unrealspeech.com/stream',
      requestBody,
      config
    );
    const audioBlob = new Blob([response.data], { type: 'audio/mpeg' });
    const audioUrl = URL.createObjectURL(audioBlob);
    return audioUrl;
  } catch (error) {
    console.error('Error fetching audio:', error);
    return null;
  }
};
