import type { ISecurity } from 'interfaces/ISecurity'
import type { IFile } from 'interfaces/IFile'
import selectEntityAllowAccessEffect from '../selected-entity/selected-entity-allow-access-effect'

export function getSecurityFilesEffect(data: ISecurity[], files: IFile[]) {
  return async (dispatch: Function) => {
    let params = {}
    data &&
      data.map((security) => {
        return (params[security['keyword']] = security.value)
      })

    try {
      dispatch(selectEntityAllowAccessEffect())
      return files
    } catch (e) {
      return new Error()
    }
  }
}
