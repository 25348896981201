import React, { useEffect } from 'react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import classNames from 'classnames';
import useNotification from 'utils/hooks/use-notification';

import NeyraTooltip from 'components/shared/NeyraTooltip/NeyraTooltip';
import IconMic from '../icons/icon-mic';
import IconRec from '../icons/icon-rec';
import IconRec2 from '../icons/icon-rec-2';

import styles from './RecordSpeech.module.scss';

interface RecordSpeechProps {
  onRecordEnds?: (arg: string) => void;
  onStatusChange?: (status: boolean) => void;
}

export default function RecordSpeech(props: RecordSpeechProps) {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition({ clearTranscriptOnListen: true });
  const { addNotification } = useNotification();

  useEffect(() => {
    props.onRecordEnds?.(transcript);
  }, [transcript]);

  useEffect(() => {
    props.onStatusChange?.(listening);
  }, [listening]);

  const startRecording = () => {
    if (!browserSupportsSpeechRecognition) {
      addNotification(
        'Your browser does not support speech recognition',
        'alert',
        undefined,
        false,
        false,
        5000
      );
      return;
    }

    resetTranscript();
    SpeechRecognition.startListening({ language: 'en-EN', continuous: true });
  };

  const stopRecording = () => {
    SpeechRecognition.stopListening();
  };

  return (
    <div className={styles.container}>
      {listening && (
        <NeyraTooltip text="Stop Record" position="top">
          <button
            onClick={() => {
              stopRecording();
            }}
            className={classNames(styles.button, styles.activeButton)}
          >
            <IconRec />
          </button>
        </NeyraTooltip>
      )}

      {!listening && (
        <NeyraTooltip text="Record" position="top">
          <button
            onClick={startRecording}
            className={classNames(styles.button, styles.startButton)}
          >
            <IconMic />
            <IconRec2 />
          </button>
        </NeyraTooltip>
      )}
    </div>
  );
}
