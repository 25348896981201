import i18next from 'i18next';
import axios from 'axios';
import { pki, util } from 'node-forge';
import { encodeExistingFile } from 'gdgateway-client/lib/es5';
import isDataprepUrl from 'gdgateway-client/lib/es5/utils/isDataprepUrl';

import getImagePreviewEffect from 'store/home/effects/file/get-image-preview.effect';
import { getDownloadOTT } from 'store/home/effects/files-upload/upload-file.effect';
import { getOneTimeToken } from 'store/home/effects';

import { encodeFileData } from 'utils/file/library-callbacks';
import { convertArrayBufferToBase64 } from 'utils/file/convertArrayBufferToBase64';
import { setExistingThumbnail } from 'utils/file/getThumbnail';
import { setKeyToLS } from 'utils/file/setKeyToLS';
import { saveEncryptedFileKeys } from 'utils/crypto/saveEncryptedFileKeys';
import { getKeysByWorkspace } from 'utils/crypto/getKeysByWorkspace';
import { canUserEncryptFile } from 'utils/canUserEncryptFile.js';
import { filesGetEffect } from 'store/file/effects';

export const encryptAction = async ({
  user,
  userTokens,
  file,
  dispatch,
  addNotification,
  getEncryptionStatus,
  afterCb,
}) => {
  if (!userTokens) {
    addNotification(
      i18next.t('contextMenu:main.imageToImage.lowBalanceText'),
      'alert'
    );
    return;
  }

  const canEncrypt = await canUserEncryptFile(user?.user_public_addresses);
  if (!canEncrypt.error) {
    const { handlers, callbacks } = encodeFileData;

    const callback = ({ type, params }) => {
      if (handlers.includes(type)) {
        callbacks[type]({ ...params, dispatch });
      } else {
        console.error(`Handler "${type}" isn't provided`);
      }
    };

    const key = await crypto.subtle.generateKey(
      { name: 'AES-GCM', length: 256 },
      true,
      ['encrypt', 'decrypt']
    );

    const {
      data: { keys },
    } = await getKeysByWorkspace();

    const fileSignal = axios.CancelToken.source();
    const hasThumbnail =
      file.mime.startsWith('image') || file.mime.startsWith('video');
    let thumbnail;

    if (hasThumbnail) {
      thumbnail = await getImagePreviewEffect(
        file.slug,
        300,
        164,
        'crop',
        fileSignal.token,
        file.mime
      );
    }
    const {
      data: {
        jwt_ott: downloadJwtOTT,
        user_tokens: { token: downloadToken },
        gateway: downloadEndpoint,
      },
    } = await getDownloadOTT([{ slug: file.slug }]);

    const {
      data: { jwt_ott, user_token, gateway },
    } = await getOneTimeToken([
      {
        filename: file.name,
        filesize: file.size,
        isPublic: true,
        slug: file.slug,
      },
    ]);
    const oneTimeToken = user_token[0].token;

    const result = await encodeExistingFile({
      file,
      getImagePreviewEffect,
      oneTimeToken,
      downloadJwtOTT,
      jwtOneTimeToken: jwt_ott[0],
      gateway,
      downloadToken,
      downloadEndpoint: downloadEndpoint.url,
      callback,
      handlers,
      key,
    });
    const slug = result?.data?.slug ?? file.slug;
    const bufferKey = await crypto.subtle.exportKey('raw', key);
    const base64Key = convertArrayBufferToBase64(bufferKey);

    setKeyToLS({ slug, base64Key });

    let encryptedKeys = [];

    for (let i = 0; i < keys.length; i++) {
      const publicKey = pki.publicKeyFromPem(keys[i]);
      const encryptedKey = await publicKey.encrypt(base64Key);
      const encryptedHexKey = util.bytesToHex(encryptedKey);
      encryptedKeys = [
        ...encryptedKeys,
        { publicKey: keys[i], encryptedFileKey: encryptedHexKey },
      ];
    }

    afterCb && afterCb();

    saveEncryptedFileKeys({
      slug,
      encryptedKeys: encryptedKeys,
    });

    const isDataprep = isDataprepUrl(downloadEndpoint.url);

    if (isDataprep && hasThumbnail) {
      setExistingThumbnail({ file: result.data, thumbnail });
    }
    if (!isDataprep) {
      const delays = [0, 3, 5, 10, 15, 30];
      const maxRetries = delays.length;
      let attempts = 0;
      let totalWaitTime = 0;

      const attemptFetch = async () => {
        const updatedFile = await filesGetEffect(file.slug);
        if (!updatedFile.entry.is_clientside_encrypted) {
          if (attempts < maxRetries) {
            totalWaitTime += delays[attempts];

            if (totalWaitTime < 60) {
              attempts++;
              setTimeout(attemptFetch, delays[attempts - 1] * 1000);
            }
          } else {
            console.warn('Max retries reached.');
          }
        } else {
          const isCancelModalOpen = document.body.querySelector(
            '.download__modal__button__cancel'
          );
          handlers.includes('onSuccess') &&
            callback({
              type: 'onSuccess',
              params: {
                isCancelModalOpen,
                response: { data: updatedFile.entry },
              },
            });
        }
      };

      attemptFetch();
    }

    getEncryptionStatus && getEncryptionStatus('success');
  } else {
    addNotification(canEncrypt?.message, 'alert');
  }
  return;
};
