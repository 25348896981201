//Refactoring №3
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CN from 'classnames';

import MainContext from 'store/main/context/main-context';
import {
  selectSidebarIsOpen,
  selectWorkspaceMenuIsOpen,
  toggleWorkspaceMenu,
  setSidebarVisibility,
} from 'features/leftMenu/leftMenuSlice';
import { handleCreateWorkspaceModal } from 'features/modals/modal-slice';
import { isMobile } from 'utils/mobile';

import ClickOutside from 'components/shared/ClickOutside';
import { Title } from './components/Title';
import WorkspaceMenu from './components/WorkspaceMenu';


import style from './style.module.scss';

export function WorkspaceButton({ isGuest, collapsed }) {
  const dispatch = useDispatch();
  const workspaceMenuIsOpen = useSelector(selectWorkspaceMenuIsOpen);
  const {
    state: { workspaces },
  } = useContext(MainContext);

  const sidebarIsOpen = useSelector(selectSidebarIsOpen);

  const {
    state: {
      workspace: { color, name: workspaceName, owner },
    },
  } = useContext(MainContext);

  const handleClickOutside = () => {
    dispatch(toggleWorkspaceMenu(false));
  };
  const openWorkspaceMenu = () => {
    dispatch(toggleWorkspaceMenu(!workspaceMenuIsOpen));
  };

  const onAddNewWorkspaceClick = () => {
    dispatch(handleCreateWorkspaceModal({ status: true }));
    handleClickOutside();
    isMobile && dispatch(setSidebarVisibility(false));
  };
  return (
    <div className={CN(style.container, collapsed && style.collapsed)}>
      <ClickOutside
        className={style.titleWrapper}
        onClickOutside={handleClickOutside}
      >
        <Title
          onClick={openWorkspaceMenu}
          title={(sidebarIsOpen && workspaceName) || null}
          name={workspaceName || ''}
          owner={owner}
          color={color}
        />
        {workspaceMenuIsOpen && workspaces.length > 1 && (
          <WorkspaceMenu
            data-test="main-sidebar_workspace_menu-button"
            isGuest={isGuest}
            onClick={handleClickOutside}
            workspaces={workspaces}
            className={style.workspaceMenu}
            onAddNewWorkspaceClick={onAddNewWorkspaceClick}
          />
        )}
      </ClickOutside>
    </div>
  );
}
