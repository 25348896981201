//Refactoring №3
// @flow
import React from 'react';
import ReactCodeInput from 'react-code-input';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import PropTypes from 'prop-types';

import PopUpsHead from '../../shared/PopUpsHead/index';

import s from './style.module.scss';

class TfaSecurity extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tfaCode: '',
      pageLoad: false,
      isValid: true,
    };
    this.onTfaChange = this.onTfaChange.bind(this);
    this.onTfaSubmit = this.onTfaSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({ pageLoad: true });
  }

  onTfaChange(code) {
    this.setState({
      tfaCode: code,
      isValid: true,
    });
  }

  onTfaSubmit() {
    const { data } = this.props;

    this.props.onSubmit(
      {
        ...data,
        value: this.state.tfaCode,
      },
      this
    );
  }

  renderError() {
    const { t } = this.props;
    const { isValid } = this.state;
    const canRender = !isValid;

    return (
      canRender && (
        <div className={s.activateError}>
          {t('rightFileMenu.security.incorrectCode')}
        </div>
      )
    );
  }

  renderContent() {
    const { t } = this.props;
    const props = {
      className: s.password_box,
      inputStyle: {},
      inputStyleInvalid: {},
    };

    return (
      <div className={s.tfaFile__content}>
        <h3 className={s.tfaFile__subtitle}>
          {t('rightFileMenu.security.2FA')}
        </h3>
        <h1 className={s.tfaFile__title}>
          {t('rightFileMenu.security.enterVerificationCode')}
        </h1>
        <div className={s.tfaFile__desc}>
          {t('rightFileMenu.security.enter6DigitsCode')}
        </div>
        <ReactCodeInput
          type="text"
          fields={6}
          {...props}
          onChange={this.onTfaChange}
        />
        {this.renderError()}
        <button onClick={this.onTfaSubmit} className={s.tfaFile__btn}>
          {t('common.confirm')}
        </button>
      </div>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <section className={s.file_security_modal}>
        <PopUpsHead
          title={t('rightFileMenu.security.securityCheck')}
          closePopup={this.props.onClose}
        />
        {this.state.pageLoad && this.renderContent()}
      </section>
    );
  }
}

TfaSecurity.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default compose(withRouter, withTranslation('owner'))(TfaSecurity);
