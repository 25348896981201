import React from 'react';

import { ReactComponent as AdaptationIcon } from './adaptation.svg';
import { ReactComponent as AmbitionsIcon } from './ambitions.svg';
import { ReactComponent as AnalyticsIcon } from './analytics.svg';
import { ReactComponent as AnswersAndSolutionsIcon } from './answers_and_solutions.svg';
import { ReactComponent as ArrangementAndInnovationIcon } from './arrangement_and_innovation.svg';
import { ReactComponent as ArtOfListeningIcon } from './art_of_listening.svg';
import { ReactComponent as AttentionToDetailsIcon } from './attention_to_details.svg';
import { ReactComponent as AuthorityIcon } from './authority.svg';
import { ReactComponent as AwarenessOfAMomentIcon } from './awareness_of_a_moment.svg';
import { ReactComponent as CareIcon } from './care.svg';
import { ReactComponent as CommitmentIcon } from './commitment.svg';
import { ReactComponent as CompassionIcon } from './compassion.svg';
import { ReactComponent as ComprehensionIcon } from './comprehension.svg';
import { ReactComponent as ConcentrationIcon } from './concentration.svg';
import { ReactComponent as ContributionToACommonIcon } from './contribution_to_a_common.svg';
import { ReactComponent as ConvergenceIcon } from './convergence.svg';
import { ReactComponent as CorrectionIcon } from './correction.svg';
import { ReactComponent as CreativityIcon } from './creativity.svg';
import { ReactComponent as DeepSolutionsIcon } from './deep_solutions.svg';
import { ReactComponent as DesiresIcon } from './desires.svg';
import { ReactComponent as DiplomacyIcon } from './diplomacy.svg';
import { ReactComponent as EfficiencyAnalysisIcon } from './efficiency_analysis.svg';
import { ReactComponent as EmotionalOpennessIcon } from './emotional_openness.svg';
import { ReactComponent as EndeavorsIcon } from './endeavors.svg';
import { ReactComponent as FocusOnDetailsIcon } from './focus_on_details.svg';
import { ReactComponent as FriendshipIcon } from './friendship.svg';
import { ReactComponent as FruitfulPowerIcon } from './fruitful_power.svg';
import { ReactComponent as GrowthAndExpansionIcon } from './growth_and_expansion.svg';
import { ReactComponent as GuideIcon } from './guide.svg';
import { ReactComponent as IdeasIcon } from './ideas.svg';
import { ReactComponent as ImaginationIcon } from './imagination.svg';
import { ReactComponent as InnerTruthIcon } from './inner_truth.svg';
import { ReactComponent as InnerVoiceIcon } from './inner_voice.svg';
import { ReactComponent as IntuitionIcon } from './intuition.svg';
import { ReactComponent as LeaderIcon } from './leader.svg';
import { ReactComponent as LifeIsAGameIcon } from './life_is_a_game.svg';
import { ReactComponent as LoveAndAcceptanceOfExtremesIcon } from './love_and_acceptance_of_extremes.svg';
import { ReactComponent as LoveOfLifeIcon } from './love_of_life.svg';
import { ReactComponent as OpinionsIcon } from './opinions.svg';
import { ReactComponent as PacesOfLifeIcon } from './paces_of_life.svg';
import { ReactComponent as PassionForChangesIcon } from './passion_for_changes.svg';
import { ReactComponent as PowerOfExpressedEmotionsIcon } from './power_of_expressed_emotions.svg';
import { ReactComponent as PowerIcon } from './power.svg';
import { ReactComponent as PremonitionIcon } from './premonition.svg';
import { ReactComponent as PreparationForInnovationsIcon } from './preparation_for_innovations.svg';
import { ReactComponent as ProvocationOfSpiritIcon } from './provocation_of_spirit.svg';
import { ReactComponent as ReformIcon } from './reform.svg';
import { ReactComponent as SalesIcon } from './sales.svg';
import { ReactComponent as SelfAwarenessIcon } from './self_awareness.svg';
import { ReactComponent as SelfLoveIcon } from './self_love.svg';
import { ReactComponent as SenseOfDirectionIcon } from './sense_of_direction.svg';
import { ReactComponent as SensitivityToPeoplesDemandsIcon } from './sensitivity_to_peoples_demands.svg';
import { ReactComponent as ShockIcon } from './shock.svg';
import { ReactComponent as SimplicityOfExpressionIcon } from './simplicity_of_expression.svg';
import { ReactComponent as SkillsIcon } from './skills.svg';
import { ReactComponent as SpeakerIcon } from './speaker.svg';
import { ReactComponent as SpiritAwakeningIcon } from './spirit_awakening.svg';
import { ReactComponent as SuccessThroughHealthIcon } from './success_through_health.svg';
import { ReactComponent as TrueValuesIcon } from './true_values.svg';
import { ReactComponent as ViabilityIcon } from './viability.svg';
import { ReactComponent as VigilanceIcon } from './vigilance.svg';
import { ReactComponent as WarriorIcon } from './warrior.svg';
import { ReactComponent as WealthManagementIcon } from './wealth_management.svg';
import { ReactComponent as WillpowerIcon } from './willpower.svg';

export default {
  adaptation: <AdaptationIcon />,  
  ambitions: <AmbitionsIcon />,  
  analytics: <AnalyticsIcon />,  
  answers_and_solutions: <AnswersAndSolutionsIcon />,  
  arrangement_and_innovation: <ArrangementAndInnovationIcon />,  
  art_of_listening: <ArtOfListeningIcon />,  
  attention_to_details: <AttentionToDetailsIcon />,  
  authority: <AuthorityIcon />,  
  awareness_of_a_moment: <AwarenessOfAMomentIcon />,  
  care: <CareIcon />,  
  commitment: <CommitmentIcon />,  
  compassion: <CompassionIcon />,  
  comprehension: <ComprehensionIcon />,  
  concentration: <ConcentrationIcon />,  
  contribution_to_a_common: <ContributionToACommonIcon />,  
  convergence: <ConvergenceIcon />,  
  correction: <CorrectionIcon />,  
  creativity: <CreativityIcon />,  
  deep_solutions: <DeepSolutionsIcon />,  
  desires: <DesiresIcon />,  
  diplomacy: <DiplomacyIcon />,  
  efficiency_analysis: <EfficiencyAnalysisIcon />,  
  emotional_openness: <EmotionalOpennessIcon />,  
  endeavors: <EndeavorsIcon />,  
  focus_on_details: <FocusOnDetailsIcon />,  
  friendship: <FriendshipIcon />,  
  fruitful_power: <FruitfulPowerIcon />,  
  growth_and_expansion: <GrowthAndExpansionIcon />,  
  guide: <GuideIcon />,  
  ideas: <IdeasIcon />,  
  imagination: <ImaginationIcon />,  
  inner_truth: <InnerTruthIcon />,  
  inner_voice: <InnerVoiceIcon />,  
  intuition: <IntuitionIcon />,  
  leader: <LeaderIcon />,  
  life_is_a_game: <LifeIsAGameIcon />,  
  love_and_acceptance_of_extremes: <LoveAndAcceptanceOfExtremesIcon />,  
  love_of_life: <LoveOfLifeIcon />,
  opinions: <OpinionsIcon />,
  paces_of_life: <PacesOfLifeIcon />,
  passion_for_changes: <PassionForChangesIcon />,
  power_of_expressed_emotions: <PowerOfExpressedEmotionsIcon />,
  power: <PowerIcon />,
  premonition: <PremonitionIcon />,
  preparation_for_innovations: <PreparationForInnovationsIcon />,
  provocation_of_spirit: <ProvocationOfSpiritIcon />,
  reform: <ReformIcon />,
  sales: <SalesIcon />,
  self_awareness: <SelfAwarenessIcon />,
  self_love: <SelfLoveIcon />,
  sense_of_direction: <SenseOfDirectionIcon />,
  sensitivity_to_peoples_demands: <SensitivityToPeoplesDemandsIcon />,
  shock: <ShockIcon />,
  simplicity_of_expression: <SimplicityOfExpressionIcon />,
  skills: <SkillsIcon />,
  speaker: <SpeakerIcon />,
  spirit_awakening: <SpiritAwakeningIcon />,
  success_through_health: <SuccessThroughHealthIcon />,
  true_values: <TrueValuesIcon />,
  viability: <ViabilityIcon />,
  vigilance: <VigilanceIcon />,
  warrior: <WarriorIcon />,
  wealth_management: <WealthManagementIcon />,
  willpower: <WillpowerIcon />,
}