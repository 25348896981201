import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CN from 'classnames';

import AiProgressBar from 'containers/ai-generator/ProgressBar';
import IconPolygon from './icons/icon-polygon';
import IconFullscreen from './icons/icon-fullscreen';
import NeyraTooltip from 'components/shared/NeyraTooltip/NeyraTooltip';
import ProductSelector from 'components/CommandLineTerminal/ProductSelector';
import ChatModal from 'components/ChatModal';
import RecordSpeech from './RecordSpeech/RecordSpeech';

import { handleNeyraPromptHelperModal } from 'features/modals/modal-slice';
import { selectIsGenerationInProgress } from 'features/studio';

import styles from './styles.module.scss';

type CommandLineTerminalProps = {
  isStudio: boolean;
  neyraWrites: boolean;
  sendMessage: (value: string) => void;
  handleInputChange?: (arg: string) => void;
  isStudioDrawTab?: boolean;
  cancelRequest: () => void;
};

const CommandLineTerminal = ({
  isStudio = false,
  neyraWrites,
  sendMessage,
  handleInputChange,
  isStudioDrawTab = false,
  cancelRequest,
}: CommandLineTerminalProps) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [value, setValue] = useState('');
  const [enterPressed, setEnterPressed] = useState(false);
  const [activeAnimation, setActiveAnimation] = useState(false);
  const [isRecodring, setIsRecording] = useState(false);
  const dispatch = useDispatch();
  const isImageGenerationInProgress = useSelector(selectIsGenerationInProgress);
  const [focus, setFocus] = useState(false);
  const placeholder = useMemo(
    () => (isStudio ? 'Type promt' : 'Ask Neyra...'),
    [isStudio]
  );

  useEffect(() => {
    neyraWrites
      ? setActiveAnimation(true)
      : setTimeout(() => {
          setActiveAnimation(false);
        }, 1200);
  }, [neyraWrites]);

  useEffect(() => {
    changeTexareaRows();
  }, [value]);

  const changeTexareaRows = () => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      const maxHeight = 172;
      textarea.style.height = 'auto';

      if (textarea.scrollHeight > maxHeight) {
        textarea.style.height = maxHeight + 'px';
      } else {
        textarea.style.height = textarea.scrollHeight + 'px';
      }
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (enterPressed) {
      setEnterPressed(false);
    } else {
      setValue(e.target.value);
      handleInputChange && handleInputChange(e.target.value);
    }
  };

  const handleMessage = () => {
    if (!value.trim()) {
      return;
    }
    if (neyraWrites) {
      cancelRequest();
    }
    sendMessage(value.trim());
    setValue('');
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (isStudioDrawTab || isRecodring) return;
    if (e.key === 'Enter' && !e.shiftKey) {
      handleMessage();
      setEnterPressed(true);
    }
  };

  const sendButton = useMemo(
    () => (
      <NeyraTooltip
        text={neyraWrites ? 'Stop Writing' : 'Send Message'}
        position="top"
      >
        <button
          onClick={neyraWrites ? cancelRequest : handleMessage}
          className={CN(
            styles.button,
            styles.buttonSend,
            value && styles.activeButton,
            activeAnimation &&
              (neyraWrites ? styles.triangleToSquare : styles.squareToTriangle)
          )}
        >
          <div className={styles.triangle}>
            <IconPolygon />
          </div>
          <div className={styles.square}></div>
        </button>
      </NeyraTooltip>
    ),
    [neyraWrites, value, activeAnimation]
  );

  const onFocus = () => {
    setFocus(true);
  };
  const onBlur = () => {
    setFocus(false);
  };

  if (isImageGenerationInProgress) {
    return (
      <div className={styles.mainWrapper}>
        <AiProgressBar />
      </div>
    );
  }

  return (
    <div className={styles.mainWrapper}>
      <div className={CN(styles.wrapper, focus && styles.wrapperFocus)}>
        <ProductSelector />
        <div className={styles.container}>
          <textarea
            ref={textareaRef}
            rows={1}
            className={CN(
              styles.textarea,
              isStudioDrawTab && styles.smallerPadding
            )}
            placeholder={focus ? '' : placeholder}
            onChange={onChange}
            onKeyDown={handleKeyPress}
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={isRecodring}
          />
        </div>
        <div className={styles.buttonsWrapper}>
          <div className={styles.buttonsList}>
            {isStudio ? (
              <>
                {isStudioDrawTab ? (
                  <></>
                ) : (
                  <>
                    <NeyraTooltip text="Show Propmpt Modal" position="top">
                      <button
                        className={CN(styles.button)}
                        onClick={() =>
                          dispatch(handleNeyraPromptHelperModal(true))
                        }
                      >
                        <IconFullscreen />
                      </button>
                    </NeyraTooltip>
                    {sendButton}
                  </>
                )}
              </>
            ) : (
              <>
                {!isRecodring && (value || activeAnimation) ? (
                  sendButton
                ) : (
                  <RecordSpeech
                    onRecordEnds={setValue}
                    onStatusChange={setIsRecording}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ChatModal />
    </div>
  );
};

export default CommandLineTerminal;
