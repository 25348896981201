//Refactoring №2
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import ClickOutside from 'components/shared/ClickOutside';
import UserIcon from 'components/users/user-icon';

import cn from 'classnames';

const Users = ({ users, color, classNames, userPopupRef, maxUsersToShow }) => {
  const { t } = useTranslation('owner');
  const [selectedUser, setSelectedUser] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [otherUser, setOtherUser] = useState(null);
  const [userPopupPosition, setUserPopupPosition] = useState({
    top: '41px',
    right: '0',
  });
  const [popupAdditionalClassName, setPopupAdditionalClassName] = useState('');
  const el = document.createElement('div');
  let userInfo = '';

  const closePopup = () => {
    setShowPopup(true);
    setOtherUser(null);
  };

  useEffect(() => {
    if (userPopupRef && userPopupRef.current) {
      userPopupRef.current.addEventListener('mouseleave', closePopup, false);
      return function cleanup() {
        userPopupRef.current.removeEventListener(
          'mouseleave',
          closePopup,
          false
        );
      };
    }
  }, []);

  useEffect(() => {
    const optionsContainer = userPopupRef?.current;
    if ((selectedUser || otherUser) && optionsContainer) {
      optionsContainer.appendChild(el);
    }

    return () => {
      if (optionsContainer) {
        optionsContainer.innerHTML = '';
      }
    };
  }, [selectedUser, otherUser, el, userPopupRef]);

  const updatePopupPosition = (e) => {
    const userPopupElement = e.target.closest('.user-info-item');
    const positionContainer = e.target.closest(
      '.user-popup-position-container'
    );
    if (userPopupRef?.current && userPopupElement && positionContainer) {
      const rect = userPopupElement.getBoundingClientRect();
      const positionRect = positionContainer.getBoundingClientRect();
      const maxHeight = window.innerHeight - (rect.top + 50);
      let top = `${rect.y + rect.height + 3 - positionRect.y}px`;

      let left = 'auto';
      let bottom = 'auto';
      let right = `${positionRect.right - rect.right}px`;
      let additionalClassNames = '';

      setUserPopupPosition({
        top,
        left,
        bottom,
        right,
        maxHeight: maxHeight < 100 ? 100 : maxHeight,
        overflow: 'auto',
      });
      setPopupAdditionalClassName(additionalClassNames);
    }
  };

  const onUserClickHandler = (user) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    updatePopupPosition(e);

    setSelectedUser(user);
    setOtherUser(null);
  };

  const onMouseLeaveHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedUser(null);
  };

  const onOtherUserClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    updatePopupPosition(e);
    if (otherUser) {
      setOtherUser(null);
    } else {
      setOtherUser(users.slice(maxUsersToShow));
    }
    setSelectedUser(null);
  };

  const openListPopup = () => {
    setOtherUser(users.slice(maxUsersToShow));
    setShowPopup(true);
  };

  const userItems = users.slice(0, maxUsersToShow).map((user, index) => {
    const userItem = user.share_right?.user || user.share_right?.invite;

    return (
      <button
        className="user-info-item"
        onClick={onUserClickHandler(user)}
        onMouseEnter={onUserClickHandler(user)}
        onMouseLeave={onMouseLeaveHandler}
        key={`user-info-${index}`}
      >
        <UserIcon
          user={userItem}
          color={color}
          bordered={!!user.share_right?.invite}
          profile
        />
      </button>
    );
  });

  if (users.length > maxUsersToShow) {
    const additionalUserNumber = `+${users.length - maxUsersToShow}`;
    userItems.push(
      <button
        className="user-info-item"
        onClick={openListPopup}
        onMouseEnter={onOtherUserClickHandler}
        onMouseLeave={onMouseLeaveHandler}
        key="user-info-other"
      >
        <ClickOutside onClickOutside={closePopup}>
          <UserIcon
            user={{ displayed_name: additionalUserNumber, color: '#7E8A9F' }}
            profile={false}
          />
        </ClickOutside>
      </button>
    );
  }

  if (selectedUser) {
    const getDisplayedCutText = (text) => {
      const textStart = text.substring(0, 3);
      const textEnding = text.slice(-3);
      return `${textStart}...${textEnding}`;
    };
    const getUserRole = (user) => {
      switch (user.user_role) {
        case 'Owner':
          return `(${t('rightFileMenu.meta.owner')})`;
        case 'ROLE_MEMBER':
          return `(${t('rightFileMenu.meta.member')})`;
        case 'ROLE_GUEST':
          return `(${t('rightFileMenu.meta.guest')})`;
        default:
          return '';
      }
    };
    const userRole = getUserRole(selectedUser);

    const userItem =
      selectedUser.share_right?.user || selectedUser.share_right?.invite;

    const visibleDisplayName =
      userItem?.displayed_name &&
      (userItem.displayed_name?.length < 40
        ? userItem?.displayed_name
        : getDisplayedCutText(userItem?.displayed_name));

    const visiblePublicAddress =
      userItem?.public_address &&
      !userItem.email &&
      !userItem.displayed_name &&
      getDisplayedCutText(userItem.public_address);

    const visibleUserName =
      userItem?.username && getDisplayedCutText(userItem?.username);

    userInfo = (
      <div className="user-info-popup-item">
        <div className="user-info-name">
          {visibleDisplayName ||
            visibleUserName ||
            userItem?.email ||
            visiblePublicAddress}
        </div>
        <div className="user-info-rights">{userRole}</div>
      </div>
    );
  } else if (otherUser) {
    userInfo = otherUser.map((user, index) => {
      const userItem = user.share_right?.user || user.share_right?.invite;

      const userRights = user.share_right?.can_edit
        ? t('rightFileMenu.meta.canEdit')
        : user.share_right?.can_comment
        ? t('rightFileMenu.meta.canComment')
        : user.share_right?.can_view
        ? t('rightFileMenu.meta.canView')
        : '';
      return (
        <div className="user-info-popup-item" key={`user-popup-info-${index}`}>
          <UserIcon
            bordered={!!user.share_right?.invite}
            user={userItem}
            color={color}
            profile
          />
          <div className="user-info-name">
            {userItem?.displayed_name || userItem.username || userItem.email}
            <div className="user-info-email">{userItem.email}</div>
          </div>
          <div className="user-info-rights">{userRights}</div>
        </div>
      );
    });
  }

  return users.length > 0 ? (
    <div className={cn('users-container', classNames)}>
      {userItems}
      {(selectedUser || otherUser || showPopup) &&
        userPopupRef &&
        ReactDOM.createPortal(
          <div
            className={cn('user-info-popup', popupAdditionalClassName)}
            style={userPopupPosition}
          >
            {userInfo}
          </div>,
          el
        )}
    </div>
  ) : null;
};

Users.defaultProps = {
  users: [],
  maxUsersToShow: 12,
};

export default Users;
