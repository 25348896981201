export const GET_ALL_TARIFFS = 'GET_ALL_TARIFFS'
export const GET_ALL_TARIFFS_SUCCESS = 'GET_ALL_TARIFFS_SUCCESS'
export const GET_ALL_TARIFFS_FAILURE = 'GET_ALL_TARIFFS_FAILURE'
export const GET_ALL_TARIFFS_CLEAR = 'GET_ALL_TARIFFS_CLEAR'

export function getAllTariffs() {
  return {
    type: GET_ALL_TARIFFS,
  }
}

export function getAllTariffsSuccess(data) {
  return {
    type: GET_ALL_TARIFFS_SUCCESS,
    payload: data,
  }
}

export function getAllTariffsFailure() {
  return {
    type: GET_ALL_TARIFFS_FAILURE,
  }
}

export function getAllTariffsClear() {
  return {
    type: GET_ALL_TARIFFS_CLEAR,
  }
}
