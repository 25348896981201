/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
//Refactoring №3
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import Switch, { SwitchTheme } from 'components/Switch';
import SharePopup from './sharePopup';
import HintPopup from 'components/HintPopup';
import { ReactComponent as AccessIcon } from './assets/access.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';
import { ReactComponent as ShareByAvatarIcon } from './assets/share-by-avatar.svg';
import { ReactComponent as ShareByTokenIcon } from './assets/shareByToken.svg';
import { ReactComponent as LogoIcon } from './assets/logo.svg';

import { entityChangeAccessEffect } from 'store/home/effects/entity-actions/entity-change-access.effect';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import updateDownloadEffect from 'store/home/effects/file/update-download.effect';
import updatePrintEffect from 'store/home/effects/file/update-print.effect';
import { shareFileMultisig } from 'store/web3/multisig';
import { handleSuccessModal } from 'features/modals/modal-slice';
import shareEffect, {
  unshareFile,
} from 'store/home/effects/file/share-file.effect';
import {
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
} from 'features/app';
import MainContext from 'store/main/context/main-context';
import USER_ROLE_TYPE from 'config/user-role-types';
import actionsOptions from 'config/actions-options';
import useNotification from 'utils/hooks/use-notification';
import { getTypeByEntityType } from 'utils/string';

import styles from './style.module.scss';
import { selectNeyrAIFiles } from 'features/neyra';

const HINTS_TYPE = {
  shareByLink: 'shareByLink',
  public: 'isPublic',
  download: 'download',
  shareByToken: 'shareByToken',
};

const Switches = ({
  slug,
  isPublic,
  isDownloaded,
  isSharedByToken,
  isPrinted,
  shares = [],
  entryGroups,
  updateInitialData,
  type,
  entity,
}) => {
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);
  const neyraFiles = useSelector(selectNeyrAIFiles)
  const { addNotification } = useNotification();
  const dispatch = useDispatch();
  const { t } = useTranslation('owner');
  const [hints, setHints] = useState([]);
  const isTokenized = entryGroups?.some((item) => item.is_tokenized);
  const isShared = shares.some((share) => share.type === 1);
  const publicShare = shares.find((share) => share.type === 1);
  const { state } = useContext(MainContext);
  const shareByLinkHint = hints.find(
    (hint) => hint.name === HINTS_TYPE.shareByLink
  );
  const publicHint = hints.find((hint) => hint.name === HINTS_TYPE.public);
  const downloadHint = hints.find((hint) => hint.name === HINTS_TYPE.download);
  const shareByTokenHint = hints.find(
    (hint) => hint.name === HINTS_TYPE.shareByToken
  );
  const isSelectedNeyraFile = neyraFiles.find((item) => item.uid === entity?.slug)

  const HINTS_TEXT = useMemo(
    () => ({
      shareByLinkTitle: t('rightFileMenu.sharing.shareByLinkTitle'),
      shareByLinkText: t('rightFileMenu.sharing.shareByLinkText'),
      publicTitle: t('rightFileMenu.sharing.publicTitle'),
      publicText: t('rightFileMenu.sharing.publicText'),
      downloadTitle: t('rightFileMenu.sharing.downloadTitle'),
      downloadText: t('rightFileMenu.sharing.downloadText'),
      shareByToken: t('rightFileMenu.sharing.youShouldTokenizeFile'),
    }),
    []
  );

  useEffect(() => {
    setHints([]);
    if (!isShared && !isPublic) {
      setHints([
        {
          name: HINTS_TYPE.shareByLink,
          text: HINTS_TEXT.shareByLinkText,
          title: HINTS_TEXT.shareByLinkTitle,
        },
      ]);
    }
    if (isPublic && isShared) {
      setHints([
        ...hints,
        {
          name: HINTS_TYPE.public,
          text: HINTS_TEXT.publicText,
          title: HINTS_TEXT.publicTitle,
        },
      ]);
    }
    if (!isPublic && !isDownloaded) {
      setHints([
        ...hints,
        {
          name: HINTS_TYPE.download,
          text: HINTS_TEXT.downloadText,
          title: HINTS_TEXT.downloadTitle,
        },
      ]);
    }
    if (!isTokenized) {
      setHints([
        ...hints,
        {
          name: HINTS_TYPE.shareByToken,
          text: HINTS_TEXT.shareByToken,
        },
      ]);
    }
  }, [isShared, isPublic, isDownloaded, isTokenized]);

  const aiHandler = () => {
    dispatch(fileFolderActionHandlerEffect(entity, actionsOptions.myAI, { addNotification }));
  }

  const renderHint = (hint) =>
    hint && (
      <>
        {hint.title && <h4 className={styles.hintTitleCenter}>{hint.title}</h4>}
        {hint.text && <p className={styles.hintTextCenter}>{hint.text}</p>}
      </>
    );

  const handleChangeAccess = () => {
    if (isPublic && isShared) {
      return;
    } else if (state.workspace.role === USER_ROLE_TYPE.MEMBER) {
      return;
    }
    entityChangeAccessEffect(slug, { isPublic: !isPublic }).then(() => {
      updateInitialData({ is_public: !isPublic });
    });
  };

  const toggleDownloadMode = () => {
    updateDownloadEffect(slug, {
      is_downloaded: !isDownloaded,
    }).then(() => {
      updateInitialData({ is_downloaded: !isDownloaded });
    });
  };

  const togglePrintMode = () => {
    updatePrintEffect(slug, {
      is_printed: !isPrinted,
    }).then(() => {
      updateInitialData({ is_printed: !isPrinted });
    });
  };

  const toggleShare = () => {
    if (isTokenized && !isShared) return;

    if (!isPublic && isShared) {
      return;
    }

    if (isShared) {
      const shareId = shares.find((share) => share.type === 1);
      unshareFile(slug, shareId.id).then((data) => {
        updateInitialData({ shares: data.shares });
      });
    } else {
      if (isMultisigActivated && isMultisigPartisipant) {
        shareFileMultisig(slug).then(() => {
          dispatch(
            handleSuccessModal({
              isOpen: true,
              text: `The ${getTypeByEntityType(
                type,
                false
              )} will be shared when all multi-signature users will sign the request.`,
            })
          );
        });
      } else {
        shareEffect(slug, '', 1, false, false, '', 0).then((data) => {
          updateInitialData({ shares: data.shares });
        });
      }
    }
  };

  return (
    <div className={styles.switches}>
      <div className={CN(styles.switch, isPublic && styles.switchActive)}>
        <div className={styles.switchView}>
          <AccessIcon />
          <p className={styles.switchViewText}>
            {t('rightFileMenu.sharing.access')}
          </p>
        </div>
        <div className={styles.switcher}>
          <p className={styles.switcherText}>
            {isPublic
              ? t('rightFileMenu.sharing.public')
              : t('rightFileMenu.sharing.private')}
          </p>
          <HintPopup
            disabled={!publicHint}
            content={renderHint(publicHint)}
            width="240px"
          >
            <Switch
              className={styles.switchItem}
              size="small"
              theme={SwitchTheme.DARK}
              forceChange
              onClick={handleChangeAccess}
              checked={isPublic}
              disabled={isMultisigActivated}
            />
          </HintPopup>
        </div>
      </div>
      <div className={CN(styles.switch, isShared && styles.switchActive)}>
        <div className={styles.switchView}>
          <ShareIcon />
          <p className={styles.switchViewText}>
            {publicShare?.share_right?.expired_at
              ? t('rightFileMenu.sharing.share')
              : t('rightFileMenu.sharing.shareByLink')}
          </p>
        </div>
        <div className={styles.switcher}>
          {isShared && !(isMultisigActivated && isShared) && (
            <SharePopup
              expiredTime={publicShare?.share_right?.expired_at}
              slug={slug}
              shareId={publicShare?.id}
              updateInitialData={updateInitialData}
            />
          )}
          <HintPopup
            disabled={!shareByLinkHint}
            content={renderHint(shareByLinkHint)}
            width="240px"
          >
            <Switch
              className={styles.switchItem}
              size="small"
              theme={SwitchTheme.DARK}
              disabled={(isTokenized && !isShared) || isMultisigActivated}
              forceChange
              onClick={toggleShare}
              checked={isShared}
            />
          </HintPopup>
        </div>
      </div>
      <div className={CN(styles.switch)}>
        <div className={styles.switchView}>
          <ShareByAvatarIcon />
          <p className={styles.switchViewText}>
            {t('rightFileMenu.sharing.shareByAvatar')}
          </p>
        </div>
        <div className={styles.switcher}>
          <Switch
            className={styles.switchItem}
            size="small"
            theme={SwitchTheme.DARK}
            forceChange
            disabled
            onClick={() => {}}
            checked={isTokenized}
          />
        </div>
      </div>
      <div className={styles.switch}>
        <div className={styles.switchView}>
          <ShareByTokenIcon />
          <p className={styles.switchViewText}>
            {t('rightFileMenu.sharing.shareByToken')}
          </p>
        </div>
        <div className={styles.switcher}>
          <HintPopup
            disabled={!shareByTokenHint}
            content={renderHint(shareByTokenHint)}
            width="220px"
          >
            <Switch
              className={styles.switchItem}
              size="small"
              theme={SwitchTheme.DARK}
              forceChange
              disabled
              onClick={() => {}}
              checked={isTokenized}
            />
          </HintPopup>
        </div>
      </div>
      <div className={styles.switch}>
        <div className={styles.switchView}>
          <LogoIcon />
          <p className={styles.switchViewText}>
            Share to Neyra
          </p>
        </div>
        <div className={styles.switcher}>
          <Switch
            className={styles.switchItem}
            size="small"
            theme={SwitchTheme.DARK}
            forceChange
            disabled={isSelectedNeyraFile}
            onClick={aiHandler}
            checked={!!isSelectedNeyraFile}
          />
        </div>
      </div>
      {/* <div className={CN(styles.switch, isPrinted && styles.switchActive)}>
        <div className={styles.switchView}>
          <PrintIcon />
          <p className={styles.switchViewText}>
            {t('rightFileMenu.sharing.printing')}
          </p>
        </div>
        <div className={styles.switcher}>
          <Switch
            size="small"
            theme={SwitchTheme.DARK}
            forceChange
            onClick={togglePrintMode}
            checked={isPrinted}
            disabled={isMultisigActivated}
          />
        </div>
      </div> */}
      {/* <div className={CN(styles.switch, isDownloaded && styles.switchActive)}>
        <div className={styles.switchView}>
          <DownloadIcon />
          <p className={styles.switchViewText}>
            {t('rightFileMenu.sharing.downloading')}
          </p>
        </div>
        <div className={styles.switcher}>
          <p className={styles.switcherText}></p>
          <HintPopup
            disabled={!downloadHint}
            content={renderHint(downloadHint)}
            width="240px"
          >
            <Switch
              size="small"
              theme={SwitchTheme.DARK}
              disabled={(!isPublic && !isDownloaded) || isMultisigActivated}
              forceChange
              onClick={toggleDownloadMode}
              checked={isDownloaded}
            />
          </HintPopup>
        </div>
      </div> */}
    </div>
  );
};

export default Switches;
