import React from 'react'

const Eye = ({ color }) => (
  <svg
    width="18"
    height="9"
    viewBox="0 0 18 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 8.556c-1.56 0-2.823-1.194-2.823-2.667C6.177 4.416 7.44 3.222 9 3.222c1.56 0 2.824 1.194 2.824 2.667 0 1.473-1.265 2.667-2.824 2.667z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.85 5.278a.489.489 0 01-.657.097.429.429 0 01-.103-.62C3.018 2.257 5.654 1 8.954 1c3.297 0 5.961 1.256 7.95 3.749a.428.428 0 01-.093.622.49.49 0 01-.659-.088c-1.81-2.27-4.197-3.394-7.198-3.394-3 0-5.353 1.123-7.103 3.389z"
      fill={color}
      stroke={color}
      strokeWidth=".2"
    />
  </svg>
)

Eye.defaultProps = {
  color: '#ffffff',
}

export default Eye
