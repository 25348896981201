//Refactoring №3
const securitiesTypes = {
  tfa: {
    id: 1,
    activatedStatusName: 'is_google_authenticator_enabled',
  },
  pin: {
    id: 2,
    activatedStatusName: 'is_pin_authenticator_enabled',
  },
  key: {
    id: 4,
    activatedStatusName: 'is_key_authenticator_enabled',
  },
  music: {
    id: 7,
  },
  multiSig: {
    activatedStatusName: 'is_multiSig_authenticator_enabled',
    id: 8,
  },
};

export default securitiesTypes;
