//Refactoring №3
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'ramda';

import { loginUsernameEffect } from 'store/auth/effects';
import SIGN_IN_ERROR_MESSAGES from 'config/errors/sign-in';

import SignInForm from './components/SignInForm';
import FileSecurityChecker from '../../security/EntitySecurityChecker/index';
import { SharedLayout } from '../components/SharedLayout';
import AbsoluteCorners from 'components/AbsoluteCorners';

import style from './style.module.scss';

const SignInContainer = () => {
  const { t } = useTranslation('auth');
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const isoauth = JSON.parse(searchParams.get('isoauth'));
  const [loading, setLoading] = useState(false);
  const workspace_securities = useSelector(
    (state) => state?.home?.entitiesSecurityCheck?.workspace_securities
  );

  const submitHandler = ({ username, password }, form) => {
    loginUsernameEffect(username, password, history).catch(({ message }) => {
      setLoading(false);
      if (message === SIGN_IN_ERROR_MESSAGES.NO_WORKSPACE) {
        history.push({
          pathname: '/no-workspace',
          state: { username, password },
        });
        return;
      }
      if (message) {
        form.current.setErrors({
          username: t('signup.invalidCredentials'),
          password: t('signup.invalidCredentials'),
        });
      }
    });
  };

  return (
    <SharedLayout
      isoauth={isoauth}
      history={history}
      blackFooter
      type="signinEmail"
    >
      <div className={style.container}>
        <div className={style.form}>
          <header>
            <h2 className={style.title}>{t('common.signin')}</h2>
            <p className={style.text}>{t('common.smartSolution')}</p>
          </header>
          <SignInForm
            history={history}
            onSubmit={submitHandler}
            loading={loading}
          />
          <AbsoluteCorners className={style.corners} />
        </div>
      </div>
      {!isEmpty(workspace_securities) && <FileSecurityChecker />}
    </SharedLayout>
  );
};

export default React.memo(SignInContainer);
