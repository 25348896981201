import React, { useEffect, useState } from 'react';
import SidebarItem from 'containers/main/NeyraDriveContainer/components/SidebarItem';
import GenerateKeyForm from './AddForm';
import APIList from './ApiList';
import { ReactComponent as SpaceIcon } from 'containers/account/Space/assets/space.svg';

import { MODES } from 'containers/account/Space/SpaceSidebar';
import {
  getApiKeys,
  deleteApiKey,
  generateApiKey as generateApiKeyReq,
} from 'client-neyra';

const MainContent = ({ setMode, selectedSpace }) => {
  const [apiKeys, setApiKeys] = useState([]);

  useEffect(() => {
    const init = async () => {
      try {
        const res = await getApiKeys();
        const secrets = res.data.secrets;
        setApiKeys(secrets);
      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, []);

  const generateApiKey = async (name) => {
    try {
      const newKey = await generateApiKeyReq({
        body: {
          avatar_id: selectedSpace.id,
          name: name,
        },
      });
      const res = await getApiKeys();
      const secrets = res.data.secrets;

      setApiKeys(
        secrets.map((item) => {
          if (item.expiration === newKey.data.exp) {
            return { ...item, fullSecret: newKey.data.secret };
          }
          return item;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onDelete = async (id) => {
    try {
      await deleteApiKey({ params: { id } });
      setApiKeys((data) => data.filter((item) => item.id !== id));
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <>
      <SidebarItem
        item={{
          icon: <SpaceIcon />,
          isMain: true,
          name: 'API',
          action: () => {},
          backButton: true,
          backOnClick: () => {
            setMode(MODES.menu);
          },
        }}
      />
      <APIList list={apiKeys} onDelete={onDelete} />
      <GenerateKeyForm list={apiKeys} onCreate={generateApiKey} />
    </>
  );
};

export default MainContent;
