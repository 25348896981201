import React, { useContext, useState, useRef } from 'react';
import cn from 'classnames';

import { validationRegExp } from 'utils/validation/regexp';
import UserContext from 'store/home/contexts/user-context';

const AddUser = ({
  users,
  team_users,
  onCancel,
  onAddUser,
  onRemoveNewUser,
  onSaveAddUsers,
}) => {
  const inputNewUserRef = useRef(null);
  const [canView, setCanView] = useState('true');
  const [canEdit, setCanEdit] = useState('true');
  const [canInvite, setCanInvite] = useState('true');
  const { user } = useContext(UserContext);
  const usersToShow = users || team_users;
  const [newUsers, setNewUsers] = useState([]);

  const existUsers =
    usersToShow &&
    usersToShow.map((item) => {
      const { user, invite } = item;
      const username = user ? user.username : invite ? invite.email : '';
      const key = user ? `exist-user-${user.id}` : `exist-user-${invite.token}`;
      return (
        <button
          className="button button--primary button--primary_outline text--medium text--12 text--uppercase remove-button"
          type="button"
          key={key}
        >
          {username}
        </button>
      );
    });

  const removeFromNewUsers = (removeIndex) => () => {
    onRemoveNewUser({
      email: newUsers[removeIndex],
    });
    setNewUsers(newUsers.filter((item, index) => index !== removeIndex));
  };

  const newUserItems =
    newUsers &&
    newUsers.map((item, index) => (
      <button
        className="button button--primary button--primary_outline text--medium text--12 text--uppercase remove-button"
        type="button"
        key={`new-user-${index}`}
        onClick={removeFromNewUsers(index)}
      >
        {item}
      </button>
    ));

  const addNewUserHandler = () => {
    const { value } = inputNewUserRef.current;
    const checkIndex = newUsers.indexOf(value);
    if (value && validationRegExp.email.test(value) && checkIndex === -1) {
      setNewUsers([...newUsers, value]);
      onAddUser({
        email: value,
      });
      inputNewUserRef.current.value = '';
    }
  };

  return (
    <div className="right-menu__container add-user">
      <label className="right-menu__label">
        Invite Users
        <div className="right-menu__input-container">
          <input
            type="email"
            name="users"
            className="right-menu__input"
            placeholder="Type E-mail Addresses"
            ref={inputNewUserRef}
          />
          <button
            type="button"
            className="add-button"
            onClick={addNewUserHandler}
          />
        </div>
      </label>

      <div className="add-user__user-button">
        {existUsers}
        {newUserItems}
      </div>

      <label className="right-menu__label">
        Your display name
        <input
          type="text"
          name="email-from"
          className="right-menu__input"
          defaultValue={user.username}
          placeholder="Your email"
        />
      </label>

      <label className="right-menu__label">
        Permissions
        <div className="checkbox__container">
          <div
            className={cn({
              'file__checkbox checkbox': true,
              active: canView,
            })}
            onClick={() => setCanView(!canView)}
          />
          <div className="checkbox__label">Can view</div>
          <div
            className={cn({
              'file__checkbox checkbox': true,
              active: canEdit,
            })}
            onClick={() => setCanEdit(!canEdit)}
          />
          <div className="checkbox__label">Can edit</div>
          <div
            className={cn({
              'file__checkbox checkbox': true,
              active: canInvite,
            })}
            onClick={() => setCanInvite(!canInvite)}
          />
          <div className="checkbox__label">Can invite</div>
        </div>
      </label>

      <button
        className="button button--primary text--medium text--12 text--uppercase bottom-button"
        type="button"
        onClick={onSaveAddUsers}
      >
        Add user
      </button>
      <button className="link" type="button" onClick={onCancel}>
        Cancel
      </button>
    </div>
  );
};

export default AddUser;
