export const FILE_REMOVE_FROM_TRASH = 'FILE_REMOVE_FROM_TRASH'
export const FILE_REMOVE_FROM_TRASH_SUCCESS = 'FILE_REMOVE_FROM_TRASH_SUCCESS'
export const FILE_REMOVE_FROM_TRASH_FAILURE = 'FILE_REMOVE_FROM_TRASH_FAILURE'

export function fileRemove() {
  return {
    type: FILE_REMOVE_FROM_TRASH,
  }
}

export function fileRemoveSuccess() {
  return {
    type: FILE_REMOVE_FROM_TRASH_SUCCESS,
  }
}

export function fileRemoveFailure() {
  return {
    type: FILE_REMOVE_FROM_TRASH_FAILURE,
  }
}
