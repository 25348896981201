//Refactoring №3
// @ts-nocheck
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CN from 'classnames';
// import '@google/model-viewer/dist/model-viewer';

import { selectUserInfo } from 'store/home/reducers/get-user-info.reducer';
import { getAvatarFileEffect } from 'store/home/effects';
import UserContext from 'store/home/contexts/user-context';

import Avatar from 'components/Avatar';
import avatar from 'components/Avatar/style.module.scss';

import style from './style.module.scss';
import { UPLOAD_AVATAR_TYPES } from 'config/user-role-types';

export default function AccountSettingsButton({
  className,
  isOpenedMenu,
  onClick,
}) {
  const {
    color = '',
    displayed_name = '',
    logo = '',
  } = useSelector(selectUserInfo) || {};
  const { t } = useTranslation('hint');

  const [logoValue, setLogoValue] = useState('');
  const is3DAvatar = logo && logo.indexOf('.glb') !== -1;
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (is3DAvatar || !logo) setLogoValue(logo);
    if (logo && !is3DAvatar && user?.id) {
      getAvatarFileEffect(user?.id, UPLOAD_AVATAR_TYPES.user).then((data) => data && setLogoValue(data));
    }
  }, [logo]);

  return (
    <button
      className={CN(
        {
          [style.menuIsOpen]: isOpenedMenu,
        },
        style.container,
        style.noUserName,
        className
      )}
      onMouseDown={onClick}
      data-test={`main-sidebar_account-settings-menu_account-settings_button`}
      title={t('settings')}
    >
      <div
        style={{
          background: color,
          width: 32,
          height: 32,
          flexShrink: 0,
        }}
        className={avatar.wrapper}
      >
        <Avatar
          className={style.avatar}
          name={displayed_name || ''}
          color={color}
          profile
          src={logoValue}
        />
      </div>
    </button>
  );
}
