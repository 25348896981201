//Refactoring №3
import { checkProviderInstalled } from 'utils/crypto/wallet/metamask';

export const redirectToDapp = (shadow, tokenized = false) => {
  const container = shadow?.querySelector('.wallets-container');
  const wallets = container?.querySelectorAll('.wallet-button-container');

  wallets.forEach((element) => {
    const wallet = element;
    const button = wallet.querySelector('.wallet-button-styling');
    const div = button.querySelector('.wallet-button-container-inner');
    const name = div.querySelector('.name').innerHTML;

    if (!checkProviderInstalled(name) && name === 'MetaMask') {
      wallet.addEventListener('click', async () => {
        !tokenized ? openMetamaskSignIn() : openMetamaskTokenized();
      });
    }
    if (!checkProviderInstalled(name) && name === 'Coinbase Wallet') {
      wallet.addEventListener('click', () => {
        openCoinbase();
      });
    }
  });
};

const openMetamaskSignIn = () => {
  const dappUrl = `${window?.location?.host}/sign-in`;
  const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
  window.location.href = metamaskAppDeepLink;
  setTimeout(() => {
    window.location.reload();
  }, 6000);
};

const openMetamaskTokenized = () => {
  const dappUrl = `${window?.location?.host}${window?.location?.pathname}`;
  const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
  window.location.href = metamaskAppDeepLink;
  setTimeout(() => {
    window.location.reload();
  }, 6000);
};

const openCoinbase = () => {
  const dappUrl = window?.location.href;
  const coinbaseAppDeepLink = 'https://go.cb-w.com/dapp?cb_url=' + dappUrl;
  window.open(coinbaseAppDeepLink, '_self');
  setTimeout(() => {
    window.location.reload();
  }, 6000);
};
