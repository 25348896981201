//Refactoring №3
import React from 'react';
import { withTranslation } from 'react-i18next';

import PopUpsHead from '../../shared/PopUpsHead/index';
import Pin from '../../shared/Pin/index';

import style from './style.module.scss';

class PinSecurity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
    };
    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm(pin, pinComponent) {
    const { data } = this.props;

    this.props.onSubmit(
      {
        ...data,
        value: pin,
      },
      pinComponent
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className={`${style.tfa__file} ${style.file_security_modal}`}>
        <PopUpsHead
          closePopup={this.props.onClose}
          title={t('rightFileMenu.security.enterPin')}
        />
        <Pin
          isError={!this.state.isValid}
          title={t('rightFileMenu.security.pinVerification')}
          onConfirm={this.onConfirm}
        />
      </div>
    );
  }
}

export default withTranslation('owner')(PinSecurity);
