// libs
import * as R from 'ramda'

// utils
import getData from 'utils/request/get-data'

// store
import * as actions from '../actions'

const initialState = {
  pending: false,
  data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USER_TARIFF:
      return R.merge(state, {
        pending: true,
      })
    case actions.GET_USER_TARIFF_SUCCESS:
      return R.merge(state, {
        pending: false,
        data: getData(action).data,
      })
    case actions.GET_USER_TARIFF_FAILURE:
      return R.merge(state, {
        pending: false,
        data: {},
      })
    case actions.GET_USER_TARIFF_CLEAR:
      return R.merge(state, {
        pending: false,
        data: {},
      })
    default:
      return state
  }
}
