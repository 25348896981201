import { Contract, ethers } from 'ethers';
import { addNotification } from 'store/main/actions'
import { CreateContract } from '../../docs/types/contract';
import { getCoinbaseProvider } from 'utils/getCoinbaseProvider';
import { isContainInArray } from "utils/array/is-contain";
import { isEmpty } from 'lodash';

const web3ErrorTextSwither = (text: string) => {
  let finalText = ''
  switch (text) {
    case "execution reverted: WorkspaceMultiSigUpgradeable: caller is not a participant":
      finalText = 'Change wallet to the one activated in Multisig'
      break;
    case `App\\Entity\\Network object not found by the @ParamConverter annotation.`:
    case `call revert exception [ See: https://links.ethers.org/v5-errors-CALL_EXCEPTION ] (method="currentParticipants()", data="0x", errorArgs=null, errorName=null, errorSignature=null, reason=null, code=CALL_EXCEPTION, version=abi/5.7.0)`:
      finalText = 'Select the Multisig Workspace network in the wallet'
      break;
    default:
      finalText = text
      break
  }

  if (isContainInArray(finalText, 'err: insufficient funds for gas * price + value:')) {
    return 'You do not have enough BNB in your account to pay'
  }

  if (text.includes('This file group contains encrypted files which may not be tokenized')) {
    finalText = 'This file group contains encrypted files which may not be tokenized'
  }

  return finalText;
};

export const getContract = async ({
  abi = [],
  address = '',
}: CreateContract): Promise<Contract | null> => {
  if (!abi.length || !address.length) return null;
  const provider = new ethers.providers.Web3Provider(window.metamask, 'any');
  const signer = provider.getSigner();
  return new Contract(address, abi, signer);
};

export const getUserPublicAddress = (addressList: any) => {
  return isEmpty(addressList) ? '' :  addressList[0]?.public_address?.toLowerCase()
}

export const web3ErrorHandler = (error: any, notify = true) => {
  let message
  if (typeof error === 'string') {
    message = error
  } else if (error.response) {
    message = error?.response?.data?.message || error?.response?.data
  } else if (error?.reason) {
    message = error?.reason
  } else {
    message = error?.data?.message || error?.message
  }

  if (notify)
    window.mainContextDispatch(addNotification({
      id: new Date().getTime(),
      message: web3ErrorTextSwither(message),
      type: 'alert',
    }))

    return web3ErrorTextSwither(message)
}

export const getProvider = () => {
  const providerName = localStorage.getItem('PROVIDER');
  const currentProvider =
    providerName === 'coinbase' ? getCoinbaseProvider() : window.metamask;

  return currentProvider;
};

export const setMultisigAction = (data: any) => {
  return localStorage.setItem('multisigAction', JSON.stringify(data));
};

export const getMultisigAction = () => {
  const multisigAction = localStorage.getItem('multisigAction');
  return multisigAction ? JSON.parse(multisigAction) : null;
};

export const deletMultisigAction = () => {
  localStorage.removeItem('multisigAction');
};
