//Refactoring №3
import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import CN from 'classnames';
import { DebounceInput } from 'react-debounce-input';

import Tooltip from 'components/Tooltip';

import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { ReactComponent as MetamaskIcon } from 'static/assets/svg/metamask-icon.svg';

import { selectIsMultisigActivated } from 'features/app';
import { entityShareAccessEffect } from 'store/home/effects/entity-actions/entity-change-access.effect';
import {
  handleNotRegisteredWalletsModal,
  handleSuccessModal,
} from 'features/modals/modal-slice';
import {
  shareFileByEmailMultisig,
  shareFileByWalletMultisig,
} from 'store/web3/multisig';
import useNotification from 'utils/hooks/use-notification';

import { getTypeByEntityType } from 'utils/string';
import { validationRegExp } from 'utils/validation/regexp';
import { isContainInArray } from 'utils/array/is-contain';
import { isMetamaskAddress } from 'utils/crypto/validation';
import { ENTER_KEY_CODE } from 'config/key-codes';

import styles from './style.module.scss';

const defaultPermission = {
  canComment: false,
  canEdit: false,
  expiredAt: 0,
  input: '',
  message: '',
};

const Input = ({
  slug,
  updateInitialData,
  members,
  setIsInvitedOpen,
  actionId,
  entityType,
}) => {
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const { t } = useTranslation('owner');
  const dispatch = useDispatch();
  const { addNotification } = useNotification();
  const [nextUser, setNextUser] = useState('');
  const [potentials, setPotentials] = useState([]);
  const [validateError, setValidateError] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateValue = (value) => {
    if (!value) {
      setValidateError('');
      return true;
    }
    if (
      isContainInArray(nextUser, '@') &&
      !validationRegExp.email.test(nextUser)
    ) {
      setValidateError(t('rightFileMenu.sharing.incorrectEmail'));
      return false;
    } else if (!isContainInArray(nextUser, '@') && !isMetamaskAddress(value)) {
      setValidateError(t('rightFileMenu.sharing.incorrectWallet'));
      return false;
    }
    setValidateError('');
    return true;
  };

  const isNewNotRegisteredWallet = ({ shares }) =>
    shares.some(
      (item) =>
        item.share_right.invite?.is_new &&
        item.share_right.invite?.public_address
    );

  const isValid = useMemo(() => {
    if (validateValue(nextUser)) return true;
    return false;
  }, [nextUser]);

  const addUser = () => {
    nextUser && handleAddPeople(nextUser);
  };

  const onSelectSuggestions = (next) => {
    setPotentials([]);
    setNextUser(next);
  };

  const handleAddPeople = (address) => {
    if (isMultisigActivated) {
      setLoading(true);
      const isEmailAddress = isContainInArray(address, '@');
      if (isEmailAddress) {
        shareFileByEmailMultisig(slug, address)
          .then(() => {
            dispatch(
              handleSuccessModal({
                isOpen: true,
                text: `The ${getTypeByEntityType(
                  entityType,
                  false
                )} will be shared when all multi-signature users will sign the request.`,
              })
            );
            updateInitialData({ actionId: ' ' });
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        shareFileByWalletMultisig(slug, address)
          .then(() => {
            dispatch(
              handleSuccessModal({
                isOpen: true,
                text: `The ${getTypeByEntityType(
                  entityType,
                  false
                )} will be shared when all multi-signature users will sign the request.`,
              })
            );
            updateInitialData({ actionId: ' ' });
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    } else if (!isEmpty(address)) {
      const defaultPermission = {
        canComment: false,
        canEdit: false,
        expiredAt: 0,
        input: address,
        message: '',
      };
      entityShareAccessEffect(slug, [defaultPermission]).then((data) => {
        addNotification(
          t('rightFileMenu.sharing.usersAdded'),
          'success',
          false,
          '241px',
          true
        );
        updateInitialData({ shares: data.shares });
        setIsInvitedOpen(true);
        setNextUser('');
        if (isNewNotRegisteredWallet(data)) {
          dispatch(handleNotRegisteredWalletsModal({ status: true, data }));
        }
        setPotentials([]);
      });
    }
  };

  const inputChange = ({ target: { value } }) => {
    const inputValue = (value && value.trim().toLowerCase()) || '';

    if (inputValue.length > 0) {
      const suggestionUser = members.filter((user) =>
        isContainInArray(user.email, value)
      );
      if (!isEmpty(suggestionUser)) {
        setPotentials(suggestionUser);
      } else {
        setPotentials([]);
      }
    } else {
      setPotentials([]);
    }

    if (value && validateValue(validateValue)) {
      entityShareAccessEffect(slug, [{ ...defaultPermission, input: value }])
        .then(() => {
          setDisabled(false);
        })
        .catch(() => {
          setDisabled(true);
        });
    } else {
      setDisabled(false);
    }
    setNextUser(value);
  };

  const handleKeyPress = ({ charCode }) => {
    if (charCode === ENTER_KEY_CODE && validateValue(nextUser)) {
      addUser();
      setNextUser('');
      setPotentials([]);
    }
  };

  const renderPotentionIcon = ({
    logo,
    color,
    displayed_name,
    index,
    public_address,
    email,
  }) => {
    if (isEmpty(logo) && (displayed_name || email)) {
      const name = displayed_name || email || '';
      return (
        <div
          style={{ backgroundColor: color }}
          className={styles.potentialMember}
        >
          <span>{name[0].toUpperCase()}</span>
        </div>
      );
    }
    if (isMetamaskAddress(public_address))
      return <MetamaskIcon className={styles.potentialMetamaskIcon} />;

    return (
      <img
        className={styles.potentialIcon}
        src={logo}
        alt={`Member Avarat ${index}`}
      />
    );
  };

  return (
    <div className={styles.inputContainer}>
      {/* <p className={styles.inputText}>
        {t('rightFileMenu.sharing.addEmailOrCryptoWallet')}
      </p> */}
      <div
        className={CN(styles.input, validateError && styles.inputContentError)}
      >
        <div
          className={CN(
            styles.inputContent,
            (actionId || disabled) && styles.inputContentDisabled
          )}
        >
          <DebounceInput
            className={styles.inputField}
            type="text"
            placeholder={t('rightFileMenu.sharing.addEmailOrCryptoWallet')}
            onChange={inputChange}
            onKeyPress={handleKeyPress}
            value={nextUser}
            disabled={actionId}
            error={validateError || disabled}
          />
          <Tooltip
            overlay={<span>{t('rightFileMenu.sharing.infoInLogs')}</span>}
            trigger={['hover']}
            showArrow={false}
            placement="top"
            overlayClassName={styles.tooltipHint}
            visible={actionId ? undefined : false}
          >
            <div>
              <button
                className={styles.inputButton}
                disabled={!isValid || disabled || validateError || loading}
                onClick={addUser}
                type="button"
              >
                <PlusIcon />
              </button>
            </div>
          </Tooltip>
        </div>
        {validateError && (
          <p className={styles.inputErrorMsg}>{validateError}</p>
        )}
        {!isEmpty(potentials) && (
          <div className={styles.potentials}>
            {potentials.map(({ email, displayed_name, logo, color }) => (
              <div
                className={styles.potential}
                key={email}
                onClick={() => {
                  onSelectSuggestions(email);
                }}
              >
                {renderPotentionIcon({ email, displayed_name, logo, color })}
                <div className={styles.potentialDetail}>
                  <p className={styles.potentialName}>{name}</p>
                  <p className={styles.potentialEmail}>{email}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
