import { BIOS_PRICE } from 'constants/bios-price';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { checkTokenPurchaseStatusInLocalStorage } from 'utils/tokenPurchase';

const useIsRegistrationFlow = () => {
  const location = useLocation();

  const isRegistrationFlow = useMemo(() => {
    const activateBios = checkTokenPurchaseStatusInLocalStorage();
    return (
      window.location.pathname === '/registration' ||
      (window.location.pathname === '/wallet/buy-credits' &&
        location.state?.price &&
        location.state?.price === BIOS_PRICE) ||
      (window.location.pathname.includes('/wallet/buy-credits') &&
        activateBios)
    );
  }, []);

  return isRegistrationFlow;
};

export default useIsRegistrationFlow;
