export const GET_GHOST_TIME_SUCCESS = 'GET_GHOST_TIME_SUCCESS'
export const ADD_GHOST_TIME_SUCCESS = 'ADD_GHOST_TIME_SUCCESS'

export function getGhostTimeSuccess(settings) {
  return {
    type: GET_GHOST_TIME_SUCCESS,
    payload: settings,
  }
}

export function addGhostTimeSuccess(settings) {
  return {
    type: ADD_GHOST_TIME_SUCCESS,
    payload: settings,
  }
}
