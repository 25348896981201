//Refactoring №3
import React from 'react';
import CN from 'classnames';

import CloseIcon from 'components/svg/close';
import styles from './styles.module.scss';

type Props = {
  hideBorder?: boolean;
  beforeTitle?: string;
  className?: string;
  title?: string;
  closePopup: React.MouseEventHandler<HTMLButtonElement>;
};

const PopUpsHead = (props: Props) => {
  const { title, closePopup, beforeTitle, hideBorder, className } = props;

  return (
    <div
      className={CN(styles.wrapper, hideBorder && styles.hideBorder, className)}
    >
      <span className={styles.title}>
        <span className={styles.title__before}>{beforeTitle}</span>
        {title}
      </span>
      <button type="button" className={styles.exit} onClick={closePopup}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default PopUpsHead;
