import React from 'react';
import CustomFileSmallIcon from 'components/CustomFileIcon/CustomFileSmallIcon';

import { ReactComponent as FolderSVG } from 'components/home/multy-upload-details/folderSvg.svg';

const getFileExtension = (name) => {
  if (!name) return '';
  const lastDotIndex = name.lastIndexOf('.');
  const extension = name.substring(lastDotIndex + 1).toLowerCase();
  return extension;
};

export const renderFileIcon = (file) => {
  const extension = getFileExtension(file.name || file.fileName);
  if (file?.dataType === 'folder') {
    return <FolderSVG />;
  }
  return <CustomFileSmallIcon type={extension} />;
};
