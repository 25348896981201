import * as actions from '../../actions/files-drag/files-drag.actions'
import * as R from 'ramda'

const getFiles = (action) => R.path(['payload', 'files'], action)

const initialState = {
  entities: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FILES_DRAG_ADD:
      return R.merge(state, {
        entities: getFiles(action),
      })

    case actions.FILES_DRAG_CLEAR:
      return R.merge(state, {
        entities: [],
      })

    default:
      return state
  }
}
