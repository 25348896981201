//New
import getToken from 'utils/auth/get-token';
import { API_FILE_NFT_INFO, API_FILE_MEMBER_INFO } from 'constants/api-urls';

export const getIsMemberEffect = async (slug: string) =>
  await fetch(`${API_FILE_MEMBER_INFO}/${slug}`, {
    method: 'GET',
    headers: { 'X-Token': getToken(), 'Content-Type': 'application/json' },
  })
    .then((res) => res.json())
    .then((data) => data);

export const getPubNftFileEffect = async (
  slug: string,
  address: string,
  signature: string,
  network: string,
  tokenAddress: string
) =>
  await fetch(`${API_FILE_NFT_INFO}/${slug}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      user_address: address,
      signature: signature,
      network: network,
      token_address: tokenAddress,
    }),
  })
    .then((res) => res.json())
    .then((data) => data);
