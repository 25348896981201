export const GET_TRUST_MEMBERS_SUCCESS = 'GET_TRUST_MEMBERS_SUCCESS'
export const ADD_TRUST_MEMBER_SUCCESS = 'ADD_TRUST_MEMBER_SUCCESS'
export const UPDATE_TRUST_MEMBER_SUCCESS = 'UPDATE_TRUST_MEMBER_SUCCESS'
export const DELETE_TRUST_MEMBER_SUCCESS = 'DELETE_TRUST_MEMBER_SUCCESS'

export function getTrustMembersSuccess(members) {
  return {
    type: GET_TRUST_MEMBERS_SUCCESS,
    payload: members,
  }
}

export function addTrustMembersSuccess(members) {
  return {
    type: ADD_TRUST_MEMBER_SUCCESS,
    payload: members,
  }
}

export function updateTrustMembersSuccess(members) {
  return {
    type: UPDATE_TRUST_MEMBER_SUCCESS,
    payload: members,
  }
}

export function deleteTrustMembersSuccess(id) {
  return {
    type: DELETE_TRUST_MEMBER_SUCCESS,
    payload: id,
  }
}
