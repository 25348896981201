import { path } from 'ramda';
import { setToCookies } from 'utils/cookies';

export default function (res, access_token, refresh_token) {
  const token = path(['data', 'access_token'], res) || '';
  const refreshToken = path(['data', 'refresh_token'], res) || '';

  if(access_token){
    setToCookies(access_token, 'access_token', '.neyratech.com');
  }
  if(refresh_token){
    setToCookies(refresh_token, 'refresh_token', '.neyratech.com');
  }
  
  if (token) {
    setToCookies(token, 'access_token', '.neyratech.com');
  }
  if (refreshToken) {
    setToCookies(refreshToken, 'refresh_token', '.neyratech.com');
  }
}
