import * as actions from '../actions/get-user-info.actions';
import getErrorData from 'utils/request/get-error-data';
import USER_ROLE_TYPES from 'config/user-role-types';
import getData from 'utils/request/get-data';

export const initialState = {
  user: null,
  freeActions: null,
  error: null,
  status: false,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_USER:
      return {
        ...state,
        error: null,
        status: true,
      };

    case actions.GET_USER_UPDATE:
      return {
        ...state,
        user: { ...state.user, ...getData(action) },
      };

    case actions.GET_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...action.payload,
          isGuest: action.payload.role === USER_ROLE_TYPES.GUEST,
        },
        status: false,
      };

    case actions.GET_USER_FAIL:
      return {
        ...state,
        error: getErrorData(action),
        status: false,
      };

    case actions.UPDATE_USER_INFO:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case actions.GET_USER_FREE_ACTIONS:
      return {
        ...state,
        freeActions: action.payload,
      };

    case actions.RESET_USER_INFO:
      return {
        ...state,
        user: null,
      };

    default:
      return state;
  }
};

//Selectors
export const selectUserInfo = (state) => state.home.userInfo.user;
export const selectUserFreeActions = (state) => state.home.userInfo.freeActions;
