export const WorkspaceActionTypes = {
  setCurrentWorkspace: 'setCurrentWorkspace',
  setWorkspaces: 'setWorkspaces',
}

export const setCurrentWorkspace = (workspace) => ({
  payload: {
    workspace,
  },
  type: WorkspaceActionTypes.setCurrentWorkspace,
})

export const setWorkspaces = (workspaces) => ({
  payload: {
    workspaces,
  },
  type: WorkspaceActionTypes.setWorkspaces,
})
