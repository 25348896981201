import React, { forwardRef } from "react";
import CN from 'classnames'

import { ReactComponent as NotificationIcon } from '../../assets/v2/notification.svg';
import style from '../MenuItem/style.module.scss'
import { selectCollapsed } from "features/leftMenu/leftMenuSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


// eslint-disable-next-line react/display-name
const NotificationBtn = forwardRef(({ unreadCount, ...rest }, ref) => {
  const { t } = useTranslation('sidebar');

  const collapsed = useSelector(selectCollapsed);

  return (
    <div
      {...rest}
      ref={ref}
      className={CN(style.wrapper, style.notContainer, collapsed && style.notContainerCollapsed)}
>
      <div className={CN(style.item, { [style.collapsed]: collapsed })}>
        <span className={style.icon}><NotificationIcon /></span>
        <span className={style.name}>{t('mainSidebar.notifications')}</span>
      </div>
      { (!collapsed && unreadCount > 0) && <span className={style.unreadBadge}>{unreadCount}</span>}
    </div>
  )
})

export default NotificationBtn;