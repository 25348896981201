// utils
import authRequest from 'utils/request/auth-request';
import { API_USERS, API_NOTIFICATIONS } from 'constants/api-urls';

const changeNotificationSharing = async (status = true) =>
  authRequest
    .post(`${API_USERS}/notify-sharing`, {
      sharingNotification: status,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((response) => {
      throw response.message;
    });

const changeSoundSharing = async (status = true) =>
  authRequest
    .post(`${API_USERS}/notify-sharing-sound`, {
      sharingNotificationSound: status,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((response) => {
      throw response.message;
    });

const changeNotificationComments = async (status = 1) =>
  authRequest
    .post(`${API_USERS}/new-comment-notification-status`, {
      status,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((response) => {
      throw response.message;
    });

const changeSoundComments = async (status = true) =>
  authRequest
    .post(`${API_USERS}/new-comment-sound-status`, {
      status,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((response) => {
      throw response.message;
    });

const changeNotificationWorkspace = async (status = true) =>
  authRequest
    .post(`${API_USERS}/notify-workspace`, {
      workspaceNotification: status,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((response) => {
      throw response.message;
    });

const changeSoundWorkspace = async (status = true) =>
  authRequest
    .post(`${API_USERS}/notify-workspace-sound`, {
      workspaceNotificationSound: status,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((response) => {
      throw response.message;
    });

const updateNotificationAdditionalData = async (id, additionalData) =>
  authRequest
    .post(`${API_NOTIFICATIONS}/edit/${id}`, { additional: additionalData })
    .then(({ data }) => {
      return data.data;
    })
    .catch((response) => {
      throw response.message;
    });

export {
  updateNotificationAdditionalData,
  changeNotificationSharing,
  changeNotificationComments,
  changeNotificationWorkspace,
  changeSoundSharing,
  changeSoundComments,
  changeSoundWorkspace,
};
