import React from 'react'

const Link = ({
  color,
  className,
  width = 16,
  height = 16,
  strokeWidth = 0.8,
}) => (
  <svg
    className={className}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip-link-0)">
      <path
        d="M8.418 13.7343L9.57997 12.5725C9.81058 12.3417 9.81058 11.9679 9.57997 11.7371C9.34917 11.5063 8.97495 11.5063 8.74434 11.7371L7.58237 12.8989C6.35483 14.1262 4.34306 14.125 3.10035 12.8989C1.87319 11.6717 1.87418 9.65993 3.10035 8.41683L4.2621 7.25485C4.4929 7.02424 4.4929 6.65005 4.2621 6.41922C4.03127 6.18839 3.6573 6.18839 3.42666 6.41922L2.26196 7.58393C1.42121 8.4361 1.00054 9.55118 1.00054 10.6639C1.00054 11.779 1.42299 12.8921 2.26767 13.7372C3.97418 15.4207 6.73296 15.4195 8.418 13.7343Z"
        fill={color}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M12.5739 9.57948L13.7388 8.41477C15.422 6.70868 15.4207 3.94971 13.7331 2.26171C12.0267 0.578254 9.26799 0.579621 7.58295 2.26466L6.42098 3.42664C6.30556 3.54186 6.24787 3.69309 6.24787 3.84436C6.24787 3.9954 6.30556 4.14666 6.42117 4.26208C6.65178 4.49288 7.02597 4.49288 7.25661 4.26208L8.41858 3.1001C9.64593 1.87276 11.6577 1.87393 12.9004 3.1001C14.1276 4.32764 14.1264 6.33941 12.9004 7.58212L11.7384 8.74409C11.5076 8.97492 11.5076 9.3487 11.7384 9.57953C11.9691 9.81031 12.3432 9.81031 12.5739 9.57948Z"
        fill={color}
        stroke={color}
        strokeWidth={strokeWidth}
      />
      <path
        d="M6.92077 9.91417L9.91428 6.92065C10.1451 6.69004 10.1451 6.31585 9.91428 6.08521C9.68367 5.85441 9.30948 5.85441 9.07884 6.08521L6.08533 9.07873C5.96992 9.19415 5.91223 9.34519 5.91223 9.49645C5.91223 9.64771 5.96992 9.79875 6.08533 9.91417C6.31597 10.145 6.69016 10.145 6.92077 9.91417Z"
        fill={color}
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </g>
    <defs>
      <clipPath id="clip-link-0">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(16) rotate(90)"
        />
      </clipPath>
    </defs>
  </svg>
)

Link.defaultProps = {
  color: '#7E8A9F',
}

export default Link
