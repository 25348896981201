/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Select, { components } from 'react-select';
import TimeField from 'react-simple-timefield'
import CN from 'classnames';

import { ReactComponent as ArrowIcon } from 'containers/mobile/assets/arrow.svg'

import styles from './styles.module.scss';

const colourStyles = {
  container: (styles) => ({ ...styles, width: '100%', border: 'none' }),
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    width: '100%',
    border: 'none',
    height: '48px',
    boxShadow: 'none'
  }),
  input: (styles, data) => {
    return ({
    ...styles,
    fontFamily: 'Nunito',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '400',
    color: '#fff',
    caretColor: '#F5E12D',
  })},
  placeholder: (styles) => ({
    ...styles,
    fontFamily: 'Nunito',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '400',
    color: '#FFFFFF80',
    paddingLeft: '0',
    marginLeft: '0',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none'
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    marginRight: '6px'
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '16px'
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    paddingLeft: 0,
    color: '#fff',
    fontFamily: 'Nunito',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '400',
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: '4px',
    backgroundColor: '#191A1A',
    padding: '13px 8px 13px 8px',
    borderRadius: '14px',
  }),
  menuList: (styles) => ({
    ...styles,
    padding: '0'
  }),
  option: (styles, { isSelected }) => {
    return {
      ...styles,
      height: '30px',
      backgroundColor: isSelected ? '#00000030' : 'transparent',
      fontFamily: 'Nunito',
      fontSize: '16px',
      padding: '4px 8px',
      lineHeight: '22px',
      cursor: 'pointer',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      ':active': {
        ...styles[':active'],
        backgroundColor: '#red'
      },
    };
  },
};

const Input = ({
  inputType,
  className,
  inputClassName,
  newStyles = {},
  ...rest
}) => {
  const [selectIsFocus, setSelectIsFocus] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = ''
      const id =  setTimeout(() => {
        inputRef.current.value = ''
      }, 1200)

      return () => {
        clearTimeout(id)
      }
    }
  }, [inputRef])
  
  if (inputType === 'textarea')
    return (
      <div className={styles.textareaContainer}>
        <textarea rows="5" className={styles.textareaInput} type='number' {...rest} />
      </div>
    )

  if (inputType === 'time')
    return (
      <div className={CN(className, styles.inputContainer)}>
        <TimeField
          inputRef={inputRef}
          className={styles.input}
          {...rest}
        />
      </div>
    )

  if (inputType === 'select') {
    const DropdownIndicator = props => {
      return (
        <components.DropdownIndicator {...props}>
          {props.isFocused ? <ArrowIcon transform='rotate(180)' /> : <ArrowIcon />}
        </components.DropdownIndicator>
      );
    };

    return (
      <div className={CN(
        className,
        styles.selectContainer,
        selectIsFocus && styles.selectContainerActive
      )}>
        <Select
          styles={{...colourStyles, ...newStyles}}
          {...rest}
          onFocus={() => {setSelectIsFocus(true)}}
          onBlur={() => {setSelectIsFocus(false)}}
          components={{ DropdownIndicator }}
        />
      </div>
    )
  }

  return (
    <div className={CN(styles.inputContainer, className)}>
      <input className={CN(styles.input, inputClassName)} type="text" {...rest} />
    </div>
  )
}

export default Input