//Refactoring №3
import React, { FC } from 'react';

import { ReactComponent as LightMode } from '../../../static/assets/svg/lightMode.svg';
import { ReactComponent as NightMode } from '../../../static/assets/svg/nightMode.svg';

import CN from 'classnames';
import s from '../styles.module.scss';

interface SwitcherProps {
  isPreviewMode: boolean;
  isDarkMode: boolean;
  handleModeSwitcher: () => void;
}

export const Switcher: FC<SwitcherProps> = ({
  isPreviewMode,
  isDarkMode,
  handleModeSwitcher,
}) => {
  return isPreviewMode ? (
    <div className={s.modeSelector}>
      <div
        className={CN(s.selector, !isDarkMode && s.active_left)}
        onClick={handleModeSwitcher}
      >
        <LightMode />
      </div>
      <div
        onClick={handleModeSwitcher}
        className={CN(s.selector, isDarkMode && s.active_right)}
      >
        <NightMode />
      </div>
    </div>
  ) : null;
};
