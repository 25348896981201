import useAxios from 'axios-hooks';
import { API_GET_FILE, API_FILE } from 'constants/api-urls';
import getToken from 'utils/auth/get-token';
import authRequest from 'utils/request/auth-request';

/**
 * @summary Send request to change access file
 * @function
 * @public
 * @param {string} slug
 * @param {object} body
 * @returns {Promise}
 */
export const entityChangeAccessEffect = async (slug, body) => {
  const url = `${API_FILE}/access/${slug}`;
  return authRequest
    .put(url, body)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};
export const useEntityChangeAccessEffect = (slug) => {
  const url = `${API_FILE}/access/${slug}`;

  const [{ data, loading, error }, changeSwitch] = useAxios(
    {
      url,
      method: 'PUT',

      headers: { 'X-Token': getToken() },
    },
    { manual: true }
  );

  return [{ data, loading, error }, changeSwitch];
};

/**
 * @summary Send request to share file with private/public access
 * @function
 * @public
 * @param {string} slug
 * @param {object} body
 * @returns {Promise}
 */
export const entityShareAccessEffect = async (slug, body) => {
  const url = `${API_GET_FILE}/multiply/${slug}/share/3`;
  return authRequest
    .post(url, body)
    .then((response) => response.data)
    .catch((response) => {
      throw response;
    });
};

export const entityDeleteSharesEffect = async (slug, body) => {
  const url = `${API_GET_FILE}/${slug}/delete/multiply/share`;
  return authRequest
    .put(url, body)
    .then((response) => response.data)
    .catch((response) => {
      throw response;
    });
};
