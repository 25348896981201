/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectCollapsed } from 'features/leftMenu/leftMenuSlice';
import CN from 'classnames';

import ThemeToggle from 'containers/header/header-container/components/AccountSettingsMenu/components/ThemeToggle';
import { ThemeContext } from 'store/context/theme';

import style from './components/SidebarMenuItem/style.module.scss';

export const SidebarMenuItemType = {
  NavLink: 'nav-link',
  Anchor: 'anchor',
  Button: 'button',
  Link: 'link',
};

export default function SidebarMenuItem({
  className,
  icon,
  children,
  onClick,
  name,
  showOnyIcon = false,
}) {
  const { theme } = useContext(ThemeContext);
  const { innerWidth: width } = window;
  const collapsed = useSelector(selectCollapsed) && !(width < 480);

  return (
    <div className={CN(
      style.wrapper,
      showOnyIcon && style.showOnlyIcon,
      className
    )}>
      <div className={CN(style.menuLogout, collapsed && style.menuLogoutColapsed )}>
        {icon && icon({ className: CN(style.icon, collapsed && style.collapsed) })}
        {!collapsed && (
        <>
            {!showOnyIcon && name && <span className={style.name}>{name}</span>}
          </>
        )}
      </div>
      {!collapsed && (<ThemeToggle />)}
    </div>
  );
}
