/* eslint-disable no-debugger */
//Refactoring №3
import axios from 'axios';

import {
  API_AUTH,
  API_USER,
  API_WORKSPACE,
  API_WORKSPACE_STORAGE_HISTORY,
} from 'constants/api-urls';

import setToken from 'utils/auth/set-token';
import authRequest from 'utils/request/auth-request';
import { setToCookies } from 'utils/cookies';

export const createWorkspace = async (name, storage) =>
  authRequest
    .post(API_WORKSPACE, {
      name,
      storage,
    })
    .then((response) => {
      return response.data?.data;
    })
    .catch((response) => {
      throw response;
    });


export const getWorkspaceFileCount = async () =>
  authRequest
    .get(`${API_WORKSPACE}/count/types`)
    .then((response) => {
      return response.data?.data;
    })
    .catch((response) => {
      throw response;
    });

export const getWorkspaceBySlug = async (slug) =>
  authRequest
    .get(`${API_USER}/workspaces/${slug}`)
    .then((response) => {
      return response.data?.data;
    })
    .catch((response) => {
      throw response;
    });

export const inviteMemberToWorkspace = async (input) =>
  authRequest
    .post(`${API_WORKSPACE}/member`, {
      input,
    })
    .then((response) => {
      return response.data?.data;
    })
    .catch((response) => {
      throw response;
    });

export const joinMemberToWorkspace = async (token) =>
  authRequest
    .post(`${API_WORKSPACE}/join`, {
      token,
    })
    .then((response) => {
      setToken(response);
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const switchWorkspace = async (workspace_id) =>
  authRequest
    .get(`${API_WORKSPACE}/switch?workspace_id=${workspace_id}`)
    .then((response) => {
      setToCookies(response.data.token, 'access_token', '.neyratech.com');
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const workspaceMembers = async () =>
  authRequest
    .get(`${API_WORKSPACE}/members`)
    .then((response) => {
      return response.data?.data;
    })
    .catch((response) => {
      throw response;
    });

export const workspaceStorageHistory = async () =>
  authRequest
    .get(`${API_WORKSPACE_STORAGE_HISTORY}`)
    .then((response) => response.data)
    .catch((response) => {
      throw response;
    });

export const userWorkspaces = async () =>
  authRequest
    .get(`${API_USER}/workspaces`)
    .then((response) => {
      return response.data?.data;
    })
    .catch((response) => {
      throw response;
    });

export const removeInviteMemberToWorkspace = async (memberId) =>
  authRequest
    .delete(`${API_WORKSPACE}/member/${memberId}`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const resendInviteMemberToWorkspace = async (memberId) =>
  authRequest
    .post(`${API_WORKSPACE}/member/${memberId}/resend`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const createUserWorkspace = async (payload) =>
  axios
    .post(`${API_AUTH}/workspace/create`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const toggleIPListWorkspace = async (payload) =>
  authRequest
    .post(`${API_WORKSPACE}/enable-whitelist`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const togglePublicIPWorkspace = async (payload) =>
  authRequest
    .post(`${API_WORKSPACE}/enable-public-whitelist`, payload)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const addIPWorkspace = async (payload) =>
  authRequest
    .post(`${API_WORKSPACE}/whitelist`, payload)
    .then((response) => {
      return response.data;
    })
    .catch(({ response }) => {
      throw response;
    });

export const getIPListWorkspace = async () =>
  authRequest
    .get(`${API_WORKSPACE}/whitelist`)
    .then((response) => {
      return response.data;
    })
    .catch(({ response }) => {
      throw response;
    });

export const updateIPtWorkspace = async (payload) =>
  authRequest
    .put(`${API_WORKSPACE}/whitelist/${payload.id}`, payload.data)
    .then((response) => {
      return response.data;
    })
    .catch(({ response }) => {
      throw response;
    });

export const deleteIPWorkspace = async (id) =>
  authRequest
    .delete(`${API_WORKSPACE}/whitelist/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const deleteAllIPWorkspace = async () =>
  authRequest
    .post(`${API_WORKSPACE}/whitelist/remove`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const toggleSettingWorkspace = async (body) =>
  authRequest
    .post(`${API_WORKSPACE}/toggle-settings`, body)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const addWorkTimeWorkspace = async (body) =>
  authRequest
    .post(`${API_WORKSPACE}/time-limit`, body)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const deleteWorkTimeWorkspace = async () =>
  authRequest
    .post(`${API_WORKSPACE}/delete-time-limit`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const getGeoListWorkspace = async () =>
  authRequest
    .get(`${API_WORKSPACE}/geo/country-list`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const addGeoLocationWorkspace = async (country) =>
  authRequest
    .post(`${API_WORKSPACE}/geo/security`, { country })
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const deleteGeoLocationWorkspace = async (country) =>
  authRequest
    .put(`${API_WORKSPACE}/geo/security/delete`, { country })
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const createDefaultWorkspace = async (input) =>
  axios
    .post(`${API_WORKSPACE}/create/default`, { input })
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const updateWorkspcePaymentPlan = async (body) =>
  authRequest
    .put(`${API_WORKSPACE}`, body)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const updateWorkspaceMultisigNames = async (body) =>
  authRequest
    .post(`${API_WORKSPACE}/multisig/users`, body)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export const getWorkspaceSmartContractActions = async () =>
  authRequest
    .get(`${API_WORKSPACE}/contract/actions`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });

export  const getMercureJwt = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_PATH}/demo/mercure-jwt`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('An error occurred while fetching the JWT:', error);
    throw error;
  }
}