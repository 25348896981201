import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectGenerationPrice,
  selectIsGenerationCompleted,
  selectIsImageUploading,
  setIsGenerationInProgress,
} from 'features/studio';

import { ReactComponent as LogoIcon } from '../assets/creatingProfile.svg';
import styles from './styles.module.scss';

const AiProgressBar = () => {
  const [progress, setProgress] = useState(0);
  const totalPrice = useSelector(selectGenerationPrice);
  const isUploading = useSelector(selectIsImageUploading);
  const isGenerationCompleted = useSelector(selectIsGenerationCompleted);
  const { t } = useTranslation('owner');
  const dispatch = useDispatch();

  useEffect(() => {
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress === 100 && isGenerationCompleted && !isUploading) {
      dispatch(setIsGenerationInProgress(false));
    }
  }, [progress, isGenerationCompleted, isUploading]);

  return (
    <div className={styles.progressBar}>
      <div className={styles.logo}>
        <LogoIcon />
      </div>
      <div className={styles.fill} style={{ width: `${progress}%` }}>
        <span>{t('studio.generationPrice', { totalPrice })}</span>
      </div>
      <div className={styles.percent}>{progress}%</div>
    </div>
  );
};

export default AiProgressBar;
