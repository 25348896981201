import React from 'react'

const BNB = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_858_40391)">
      <path
        d="M4.52224 6.30313L7.43525 3.39023L10.3498 6.30467L12.0448 4.60967L7.43525 0L2.82724 4.60813L4.52224 6.30313Z"
        fill="#F3BA2F"
      />
      <path
        d="M3.32525 7.49972L1.63028 5.80475L-0.0647694 7.4998L1.6302 9.19477L3.32525 7.49972Z"
        fill="#F3BA2F"
      />
      <path
        d="M4.52224 8.69686L7.43525 11.6098L10.3497 8.69543L12.0456 10.3895L12.0448 10.3904L7.43525 15L2.82712 10.392L2.82475 10.3896L4.52224 8.69686Z"
        fill="#F3BA2F"
      />
      <path
        d="M13.2404 9.1956L14.9354 7.50055L13.2405 5.80558L11.5454 7.50063L13.2404 9.1956Z"
        fill="#F3BA2F"
      />
      <path
        d="M9.15454 7.49911H9.15525L7.43525 5.77911L6.16416 7.05021H6.16404L6.01808 7.19629L5.7168 7.49757L5.71443 7.49994L5.7168 7.50242L7.43525 9.22088L9.15525 7.50088L9.15608 7.49994L9.15454 7.49911Z"
        fill="#F3BA2F"
      />
    </g>
    <defs>
      <clipPath id="clip0_858_40391">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default BNB
