import * as actions from '../actions/ghost-access.action'
import { merge } from 'ramda'

const initialState = {
  ghost_time: {
    time_count: null,
    time_type: null,
  },
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_GHOST_TIME_SUCCESS:
      return merge(state, {
        ghost_time: action.payload,
      })
    default:
      return state
  }
}

//Selectors
export const getGhostTime = (state) => state.account.ghostAccess.ghost_time
