import { getEncryptedFileKey } from 'containers/main/Main/components/Sharing/utils/get-key';
import { getDecryptedKey } from './getDecryptedKey';
import { getKeyFromLS } from './getKeyFromLS';
import { IFile } from 'interfaces/file-folder';
import { store } from 'store/root-store';

export const getDecryptionKey = async (file: IFile) => {
  const decryptedKeyFromLS = getKeyFromLS({ slug: file.slug });

  if (decryptedKeyFromLS) {
    return decryptedKeyFromLS;
  }

  try {
    const encryptedKey = await getEncryptedFileKey(file, store.dispatch);

    if (encryptedKey) {
      return await getDecryptedKey({ key: encryptedKey });
    }

    return '';
  } catch {
    return '';
  }
};
