import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from 'react-modal';

import ConversationHistoryTerminal from 'components/ConversationHistoryTerminal';
import { ReactComponent as CloseIcon } from 'static/assets/svg/close.svg';
import NeyraTooltip from 'components/shared/NeyraTooltip/NeyraTooltip';

import { setShowChatModal } from 'features/neyra'

import styles from './styles.module.scss';

const ChatModal = () => {
  const dispatch = useDispatch();
  const { showChatModal } = useSelector((store) => store.neyra);

  const onCloseModal = () => {
    dispatch(setShowChatModal(false));
  }

  return (
    <ReactModal
      isOpen={showChatModal}
      onRequestClose={onCloseModal}
      overlayClassName={styles.overlay}
      className={styles.modal}
    >
      <button
        onClick={onCloseModal}
        className={styles.closeBtn}
      >
        <NeyraTooltip text="Close Chat Terminal" position="left">
          <CloseIcon />
        </NeyraTooltip>
      </button>
      <ConversationHistoryTerminal className={styles.chatWrapper}/>
    </ReactModal>
  )
}

export default ChatModal;