// Refactoring №3
import React, { useMemo, useState } from 'react';

import style from './style.module.scss';

const buttons = {
  '1x': { size: 768, value: 1 },
  '2x': { size: 1024, value: 2 },
  '3x': { size: 1536, value: 3 },
  '4x': { size: 2048, value: 4 },
};
type SelectSizeButtonsProps = {
  setSelectedSize: ({ size, value }: { size: number; value: number }) => void;
};
export default function SelectSizeButtons({
  setSelectedSize,
}: SelectSizeButtonsProps) {
  const [selectedButton, setSelectedButton] = useState('');
  const nameOfButtons = useMemo(() => Object.keys(buttons), [buttons]);
  const clickOnButton = (e) => {
    setSelectedButton(e.target.id);
    setSelectedSize(buttons[e.target.id]);
  };
  return (
    <div className={style.wrapper} onClick={clickOnButton}>
      {nameOfButtons.map((name, i) => (
        <div
          id={name}
          key={name + i}
          className={`${style.buttonWrapper} ${
            selectedButton === name && style.buttonWrapper__selected
          }`}
        >
          {name}
        </div>
      ))}
    </div>
  );
}
