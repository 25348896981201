export const PENDING_FILES_ADD = 'PENDING_FILES_ADD'
export const PENDING_FILES_REMOVE = 'PENDING_FILES_REMOVE'
export const PENDING_FOLDERS_ADD = 'PENDING_FOLDERS_ADD'
export const PENDING_FOLDERS_REMOVE = 'PENDING_FOLDERS_REMOVE'
export const PENDING_CLEAR = 'PENDING_FILES_CLEAR'

/**
 *
 * @param {IFile[] | IFile} data
 * @returns {{type: string, payload: *}}
 */
export function pendingFilesAdd(data) {
  return {
    type: PENDING_FILES_ADD,
    payload: data,
  }
}

/**
 *
 * @param {IFile[] | IFile} data
 * @returns {{type: string, payload: *}}
 */
export function pendingFilesRemove(data) {
  return {
    type: PENDING_FILES_REMOVE,
    payload: data,
  }
}

export function pendingFoldersAdd(data) {
  return {
    type: PENDING_FOLDERS_ADD,
    payload: data,
  }
}

export function pendingFoldersRemove(data) {
  return {
    type: PENDING_FOLDERS_REMOVE,
    payload: data,
  }
}

export function pendingClear() {
  return {
    type: PENDING_CLEAR,
  }
}
