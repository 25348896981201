/* eslint-disable no-unused-vars */
//Refactoring №3
import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { useKeyPress } from 'ahooks';
import cn from 'classnames';

import {
  handleViewFileModal,
  handleEditModal,
  // selectIsEnterDisable,
} from 'features/modals/modal-slice';
import useClickOutside from 'utils/hooks/use-click-outside';
import actionsOptions from 'config/actions-options';

import ContextMenuItem from './context-menu-item';

import s from './contextMenu.module.scss';

const ContextMenu = ({
  actionHandler,
  onClickOutside,
  options,
  permission,
  contextPosition,
  className,
  isPublic,
  entity,
  isBreadcrumbs,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [hoveredOption, setHoveredOption] = useState(null);
  const contextMenuRef = useRef(null);
  useClickOutside(contextMenuRef, onClickOutside);

  useKeyPress(['enter'], () => {
    if (
      hoveredOption &&
      (!permission || hoveredOption.permissions?.includes(permission))
    ) {
      optionSwitcher(hoveredOption);
      actionHandler(hoveredOption);
      onClickOutside();
    }
  });

  const optionSwitcher = ({ type }) => {
    switch (type) {
      case actionsOptions.viewFile.type:
        dispatch(handleViewFileModal(true));
        break;
      case actionsOptions.edit.type:
        dispatch(handleEditModal(true));
        break;
      default:
        break;
    }
  };

  const optionClickHandler = (option) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!permission || option.permissions?.includes(permission)) {
      optionSwitcher(option);
      actionHandler(option);
      onClickOutside();
    }
  };

  // maybe it is useful

  // const hoverOnArea = () => {
  //   !disableEnter && dispatch(handleDisableEnter(true));
  // };
  // const hoverOutArea = () => {
  //   disableEnter && dispatch(handleDisableEnter(false));
  // };

  const optionHandler = (option) => {
    if (option.type === actionsOptions.myAI.type)
      return {
        ...option,
        name: entity.user_favorites.length ? option.secondName : option.name,
      };
    return option;
  };

  const actionsItems = options.map((option, index) => {
    let partitionsClassname = '';
    if (
      option &&
      (option.type === actionsOptions.edit.type ||
        option.type === actionsOptions.nft.type ||
        option.type === actionsOptions.guardians.type)
    ) {
      partitionsClassname = s.actionItemPartition;
    }

    if (option) {
      const disabled = option.disableOnSharedToMe
        ? location.pathname === '/sharing/with-me'
        : permission
        ? !option.permissions?.includes(permission)
        : false;
      const checkIsisDownloaded =
        option.type === actionsOptions.download.type &&
        isPublic &&
        !entity?.is_downloaded;
      return (
        <ContextMenuItem
          option={optionHandler(option)}
          className={cn(option.className, partitionsClassname)}
          onClickHandler={optionClickHandler(option)}
          key={`context-menu-item-${index}`}
          disabled={disabled || checkIsisDownloaded}
          onMouseEnter={() => setHoveredOption(option)}
          onMouseLeave={() => setHoveredOption('')}
          isBreadcrumbs={isBreadcrumbs}
        />
      );
    }
  });

  return (
    <section
      className={cn(s.context_menu, className)}
      ref={contextMenuRef}
      style={contextPosition}
      onContextMenu={(e) => e.stopPropagation()}
      // onMouseEnter={hoverOnArea}
      // onMouseLeave={hoverOutArea}
    >
      {actionsItems}
    </section>
  );
};

ContextMenu.defaultProps = {
  actionHandler: () => {},
  onClickOutside: () => {},
  options: [],
  permission: false,
  isPublic: false,
  entity: {},
};

export default ContextMenu;
