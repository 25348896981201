export const HistoryActionTypes = {
  loadHistory: 'loadHistory',
  filterByDate: 'filterByDate',
  clearHistoryFilter: 'clearHistoryFilter',
  reset: 'reset',
};

export const loadHistory = ({ count, data }) => ({
  payload: {
    count,
    items: data,
  },
  type: HistoryActionTypes.loadHistory,
});

export const filterByDate = (date) => ({
  payload: {
    date,
  },
  type: HistoryActionTypes.filterByDate,
});

export const resetHistory = () => ({
  payload: {},
  type: HistoryActionTypes.reset,
});

export const clearHistoryFilter = () => ({
  payload: {},
  type: HistoryActionTypes.clearHistoryFilter,
});
