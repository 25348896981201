export const SAVE_UPLOAD_FILE = 'SAVE_UPLOAD_FILE'
export const SAVE_UPLOAD_FILE_SUCCESS = 'SAVE_UPLOAD_FILE_SUCCESS'
export const SAVE_UPLOAD_FILE_FAIL = 'SAVE_UPLOAD_FILE_FAIL'

export function saveUploadFile() {
  return {
    type: SAVE_UPLOAD_FILE,
  }
}

export function saveUploadFileSuccess() {
  return {
    type: SAVE_UPLOAD_FILE_SUCCESS,
  }
}

export function saveUploadFileFail(err) {
  return {
    type: SAVE_UPLOAD_FILE_FAIL,
    payload: err,
  }
}
