/* eslint-disable no-unused-vars */
//Refactoring №3
import React from 'react';
import { useHistory } from 'react-router';
// import { useTranslation } from 'react-i18next';

import { ReactComponent as TelegramIcon } from 'containers/auth/SignIn/assets/telegram.svg';
// import { ReactComponent as WebIcon } from 'containers/auth/SignIn/assets/web.svg';
import { ReactComponent as WalletIcon } from 'containers/auth/SignIn/assets/wallet.svg';

import { ReactComponent as EmailIcon } from 'containers/auth/SignIn/assets/email.svg';
import { ReactComponent as NeyraIcon } from 'static/assets/svg/neyra-logo.svg';

import style from './style.module.scss';

export const Header = ({ type, isTelegramPage = false }) => {
  const history = useHistory();

  const goSignInWeb3 = () => {
    history.push('sign-in');
  };

  const goSignInTelegram = () => {
    history.push('/sign-in-telegram');
  };

  const goSignInEmail = () => {
    history.push('/sign-in-email');
  };

  return (
    <header className={style.container}>
      <a
        className={style.logo}
        href={process.env.REACT_APP_SIGN_LOGO_LINK}
        data-test={`sign-up_select_crypto_logo_link`}
      >
        <NeyraIcon />
      </a>
      {type === 'signinEmail' && (
        <div className={style.otherLoginOptions}>
          <button onClick={goSignInWeb3} className={style.emailLoginBtn}>
            <WalletIcon />
          </button>
          {isTelegramPage ? (
            <button onClick={goSignInEmail} className={style.emailLoginBtn}>
              <EmailIcon />
            </button>
          ) : (
            <button onClick={goSignInTelegram} className={style.emailLoginBtn}>
              <TelegramIcon />
            </button>
          )}
        </div>
      )}
    </header>
  );
};
