import authRequest from 'utils/request/auth-request'

export default async (fileId) => {
  const url = `${process.env.REACT_APP_API_PATH}/statistic/${fileId}`

  return authRequest
    .get(url)
    .then((response) => {
      return response.data.data
    })
    .catch((response) => {
      throw response
    })
}
