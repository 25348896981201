import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

// size: medium, large

const RoundButton = ({ active, onClick, title, size, text }) => {
  return (
    <button
      className={classNames(
        styles.wrapper,
        styles[size],
        !active && styles.disabledButton
      )}
      onClick={onClick}
    >
      {active && <div className={styles.rhombus} />}
      <div className={styles.title}>{title}</div>
      {text && <span className={styles.text}>{text}</span>}
    </button>
  );
};

export default RoundButton;
