//Refactoring №4
import React, { useCallback, useMemo, FC } from 'react';
import { useSelector, connect, MapDispatchToProps } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';

import {
  selectCollapsed,
  toggleCollapsed,
  onChangeWidth,
} from 'features/leftMenu/leftMenuSlice';
import { MenuItem } from './components/MenuItem';
import NotificationsPopup from 'containers/header/header-container/components/NotificationPopup';
import Back from './components/Back';

import { ReactComponent as AssetsIcon } from './assets/v2/drive.svg';
import { ReactComponent as CommentsIcon } from './assets/v2/comments.svg';
import { ReactComponent as DeletedIcon } from './assets/v2/deleted.svg';
// import { ReactComponent as EventsIcon } from './assets/v2/events.svg';
import { ReactComponent as LinksIcon } from './assets/v2/links.svg';
import { ReactComponent as MemoIcon } from './assets/v2/memo.svg';
import { ReactComponent as NotificationIcon } from './assets/v2/notification.svg';
import { ReactComponent as RecentIcon } from './assets/v2/recent.svg';
import { ReactComponent as ShareIcon } from './assets/v2/share.svg';
import { ReactComponent as StareIcon } from './assets/v2/star.svg';

import { ReactComponent as VideoIcon } from './assets/v2/video.svg';
import { ReactComponent as AudioIcon } from './assets/v2/audio.svg';
import { ReactComponent as DocumentsIcon } from './assets/v2/documents.svg';
import { ReactComponent as PicturesIcon } from './assets/v2/pictures.svg';
import { ReactComponent as TokenizeIcon } from './assets/v2/tokenized.svg';

import style from './style.module.scss';

interface IProps {
  isGuest: boolean;
  setCurrentSizes: (value: number[]) => void;
  currentSizes: number[];
}

interface IDispatchProps {
  toggleCollapsed: typeof toggleCollapsed;
  onChangeWidth: typeof onChangeWidth;
}

type Props = IProps & IDispatchProps;

const checkEmptyList = [
  'video',
  'audio',
  'pictures',
  'documents',
  'tokenized',
  'links',
];

const Container: FC<Props> = ({
  isGuest,
  setCurrentSizes,
  currentSizes,
  toggleCollapsed,
  onChangeWidth,
  fileCountByType,
}: IProps) => {
  const { t } = useTranslation('sidebar');
  const collapsed = useSelector(selectCollapsed);
  const isMobile = useMemo(() => window.screen.width < 480, []);

  const onHideScondLine = useCallback(() => {
    const res =
      fileCountByType.videos +
      fileCountByType.audios +
      fileCountByType.images +
      fileCountByType.docs +
      fileCountByType.tokenized +
      fileCountByType.links;

    return res || 0;
  }, [fileCountByType]);

  const MainMenu = useMemo(
    () => [
      {
        icon: <AssetsIcon />,
        name: 'Files',
        url: '/main',
        count: fileCountByType.total,
      },
      {
        icon: <MemoIcon />,
        url: '/pages',
        name: 'Memos',
        count: fileCountByType.pages,
      },
      {
        icon: <StareIcon />,
        name: 'Starred',
        url: '/favorites',
        count: fileCountByType.starred,
      },
      {
        icon: <ShareIcon />,
        name: t('mainSidebar.shared'),
        url: '/sharing',
        count: fileCountByType.shared,
      },
      {
        icon: <RecentIcon />,
        name: t('mainSidebar.recent'),
        url: '/recent',
      },
      {
        icon: <DeletedIcon />,
        name: t('mainSidebar.deleted'),
        url: '/deleted',
        count: fileCountByType.deleted,
      },
      {
        line: true,
      },
      {
        key: 'video',
        url: '/video',
        name: t('mainSidebar.video'),
        icon: <VideoIcon />,
        count: fileCountByType.videos,
      },
      {
        key: 'audio',
        url: '/audio',
        name: t('mainSidebar.audio'),
        icon: <AudioIcon />,
        count: fileCountByType.audios,
      },
      {
        key: 'pictures',
        url: '/pictures',
        name: t('mainSidebar.pictures'),
        icon: <PicturesIcon />,
        count: fileCountByType.images,
      },
      {
        key: 'documents',
        url: '/documents',
        name: t('mainSidebar.doc'),
        icon: <DocumentsIcon />,
        count: fileCountByType.docs,
      },
      {
        key: 'tokenized',
        url: '/tokenized-files',
        name: t('mainSidebar.tokenized'),
        icon: <TokenizeIcon />,
        count: fileCountByType.tokenized,
      },
      {
        key: 'links',
        url: '/links',
        name: t('mainSidebar.links'),
        icon: <LinksIcon />,
        count: fileCountByType.links,
      },
      {
        line: true,
      },
      // {
      //   icon: <EventsIcon />,
      //   name: t('mainSidebar.events'),
      //   url: '/automation',
      // },
      {
        icon: <CommentsIcon />,
        name: t('mainSidebar.comments'),
        url: '/comments',
      },
      {
        line: true,
      },
      {
        key: 'notification',
        icon: <NotificationIcon />,
        name: t('mainSidebar.notifications'),
      },
    ],
    [fileCountByType]
  ).filter((item, index) => {
    if (index === 6 && !onHideScondLine()) {
      return false;
    }
    return checkEmptyList.includes(item.key) ? !!item.count : true;
  });

  const GuestMenu = useMemo(
    () => [
      {
        icon: <ShareIcon />,
        name: t('mainSidebar.shared'),
        url: '/sharing',
      },
      {
        icon: <RecentIcon />,
        name: t('mainSidebar.recent'),
        url: '/recent',
      },
      {
        icon: <NotificationIcon />,
        name: t('mainSidebar.notifications'),
      },
    ],
    []
  );

  const toggleSplitPanel = useCallback(() => {
    const nextCollapsed = currentSizes[0] === 76;
    const collapsePane = nextCollapsed ? 240 : 76;
    toggleCollapsed(nextCollapsed);
    onChangeWidth(collapsePane);
    setCurrentSizes([collapsePane, window.innerWidth - collapsePane]);
  }, [currentSizes, window]);

  return (
    <div className={CN(style.container, collapsed && style.collapsed)}>
      <Back
        isMobile={isMobile}
        collapsed={collapsed}
        toggleSplitPanel={toggleSplitPanel}
      />
      <div className={style.menu}>
        <hr className={style.hr} />
        {isGuest
          ? GuestMenu.map((item) => {
              if (!item.url) {
                return <NotificationsPopup key={'notification'} />;
              }

              return <MenuItem key={item.url} {...item} isGuest={isGuest} />;
            })
          : MainMenu.map((item, index) => {
              if (item?.line) {
                return <hr key={`hr-${index}`} className={style.hr} />;
              }
              if (!item.url) {
                return <NotificationsPopup key={'notification'} />;
              } else {
                return <MenuItem key={item.url} {...item} />;
              }
            })}
      </div>
      {/* {!isGuest && (
        <div className={CN(style.menu, style.fileLibery)}>
          {!isBetaEnviroment && (
            <GroupItem
              name={t('mainSidebar.apps')}
              icon={<Web3File />}
              activeGroup={activeGroup}
              setActiveGroup={setActiveGroup}
              group={[
                {
                  key: 'safetyNet',
                  url: '/safety-net',
                  name: t('accountSettings.safetyNet'),
                  icon: <SafetyNet />,
                },
                {
                  key: 'vault',
                  url: '/vault',
                  name: t('mainSidebar.passwords'),
                  icon: <Password />,
                },
                {
                  key: 'pages',
                  url: '/pages',
                  name: t('mainSidebar.pages'),
                  icon: <Documents />,
                },
                {
                  key: 'aiGenerator',
                  url: '/ai-generator',
                  name: t('mainSidebar.aiGenerator'),
                  icon: <AiGenerator />,
                  unavailable: true,
                },
                {
                  key: 'MultiSig',
                  url: '/multisig',
                  name: t('mainSidebar.MultiSig'),
                  icon: <StrongShieldIcon />,
                  disabled: true,
                },
              ]}
            />
          )}
        </div>
      )} */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  fileCountByType: state.workspaceSettings.fileCountByType,
});

const mapDispatchToProps: MapDispatchToProps<IDispatchProps, IProps> = {
  toggleCollapsed,
  onChangeWidth,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
