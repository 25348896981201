/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import CN from 'classnames';

import styles from './styles.module.scss';

const TOTAL_TIMER = 3; // total time in seconds

const AnalyzeBtn = ({ onClick, text, startAnalyze, setStartAnalyze }) => {
  const interval = useRef(null)
  const [timeElapsed, setTimeElapsed] = useState(0.0);

  useEffect(() => {
    if (timeElapsed >= (TOTAL_TIMER - 0.1)) {
      clearInterval(interval.current)
    }
  }, [timeElapsed]);

  useEffect(() => {
    return () => {
      interval.current && clearInterval(interval.current)
    }
  }, [])

  useEffect(() => {
    if (startAnalyze) {
      const id = interval.current = setInterval(() => {
        setTimeElapsed(prevTime => prevTime + 0.1);
      }, 100);

      return () => {
        clearInterval(id)
      }
    }
  }, [startAnalyze])


  const renderText = () => {
    if (text)
      return text

    return startAnalyze ? `Analyzing ${((timeElapsed / TOTAL_TIMER) * 100).toFixed(0)}%` : 'Analyze'
  }

  return (
    <buutton
      onClick={onClick}
      className={
        CN(
          styles.analyzeBtn,
          startAnalyze && styles.analyzeBtnAnimate
        )
      }
      disabled={startAnalyze}
    >
      <div className={styles.analyzeBtnFilter}></div>
      <span className={styles.analyzeBtnText}>
        { renderText() }
      </span>
    </buutton>
  )
}

export default  AnalyzeBtn;