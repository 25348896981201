export const GET_STRIPE_DATA = 'GET_STRIPE_DATA'
export const GET_STRIPE_DATA_SUCCESS = 'GET_PAYMENT_TOKEN_SUCCESS'
export const GET_STRIPE_DATA_FAILURE = 'GET_PAYMENT_TOKEN_FAILURE'
export const GET_STRIPE_DATA_CLEAR = 'GET_PAYMENT_TOKEN_CLEAR'

export function getStripeData() {
  return {
    type: GET_STRIPE_DATA,
  }
}

export function getStripeDataSuccess(data) {
  return {
    type: GET_STRIPE_DATA_SUCCESS,
    payload: data,
  }
}

export function getStripeDataFailure() {
  return {
    type: GET_STRIPE_DATA_FAILURE,
  }
}

export function getStripeDataClear() {
  return {
    type: GET_STRIPE_DATA_CLEAR,
  }
}
