import getToken from 'utils/auth/get-token'
import checkToken from 'utils/auth/check-token'
import * as actions from '../actions/check-auth-actions'

export function checkAuthEffect() {
  const token = getToken()
  return (dispatch) => {
    dispatch(actions.checkAuth())
    if (checkToken(token)) {
      dispatch(actions.checkAuthSuccess())
      return true
    } else {
      dispatch(actions.checkAuthFail())
      return false
    }
  }
}
