import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import useColorConverter from '../../../../../../utils/color-converter';
import { isFile } from 'utils/file'

import { colorAddEffect } from 'store/colors/effects';
import {
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
} from 'features/app';
import ColorContext from 'store/colors/contexts/colors';
import MainContext from 'store/main/context/main-context';
import { ReactComponent as ColorEditorIcon } from './assets/color-edit.svg';
import { fileUpdateDataEffect } from 'store/home/effects/file/file-update.effect';
import { fileUpdateDataSelectedEntityEffect } from 'store/home/effects/entity-actions/entity-make-action.effect';
import { setCurrentFolder } from 'store/main/actions';
import { updateDocumentSuccess } from 'store/home/actions/documents.actions';
import ColorPicker from './color-picker';
import authRequest from '../../../../../../utils/request/auth-request';

import s from './style.module.scss';

const Colors = ({ selectedEntity }) => {
  const isFolder = isFile(selectedEntity.type);
  const colors = selectedEntity.color || [];
  const entityId = selectedEntity.slug || ''
  const { colors: allColors, updateColors } = useContext(ColorContext);
  const { dispatch: mainDispatch, state } = useContext(MainContext);
  const { hexToRGB, rgbToHex } = useColorConverter();
  const dispatch = useDispatch();
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);
  const [activeColor, setActiveColor] = useState(
    colors && colors.length > 0 ? colors[colors.length - 1] : {}
  );
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [newColor, setNewColor] = useState(hexToRGB(activeColor?.hex));
  const { isDeletedPage } = state;

  const yourColors = useMemo(
    () => allColors.slice(7, allColors.length),
    [allColors]
  );

  const handleEditColor = useCallback(() => {
    setIsColorPickerOpen(!isColorPickerOpen);
  }, [isColorPickerOpen]);

  const handleChangeColor = useCallback((color) => {
    setNewColor(color);
  }, []);

  const handleHexChangeColor = useCallback((color) => {
    const hexToRGBColor = hexToRGB(color);
    setNewColor(hexToRGBColor);
  }, []);

  const hexColor = useMemo(
    () => (newColor ? rgbToHex(newColor.r, newColor.g, newColor.b) : ''),
    [newColor]
  );

  const handleChangeRGB = useCallback((key, value) => {
    setNewColor({ ...newColor, [key]: value });
  }, []);

  const entityUpdateColor = (data) => {
    const nextData = {
      ...selectedEntity,
      color: data?.color ? data.color : selectedEntity.color,
    };
    dispatch(fileUpdateDataEffect(nextData));
    dispatch(fileUpdateDataSelectedEntityEffect(nextData));
  };

  const handleAddNewColor = useCallback(async () => {
    const hexColor = rgbToHex(newColor.r, newColor.g, newColor.b);
    const isExist = yourColors.find((color) => color.hex === hexColor);
    if (!isExist) {
      const {
        data: { data: file },
      } = await authRequest.post(
        `${process.env.REACT_APP_API_PATH}/files/${entityId}/add/color`,
        {
          hex: hexColor,
        }
      );
      const [newColorResponse] = file.color;
      updateColors([...allColors, newColorResponse]);
      entityUpdateColor(file);
    } else {
      setActiveColor(isExist);
      const file = await colorAddEffect(entityId, isExist.id);
      entityUpdateColor(file);
    }
    setIsColorPickerOpen(false);
  }, [entityId, newColor]);

  const colorClickHandler = (colorId) => {
    if (!isDeletedPage) {
      colorAddEffect(entityId, colorId).then((file) => {
        const { folder, isDocumentPage } = state;
        if (isDocumentPage && file) {
          dispatch(updateDocumentSuccess(file));
        }
        if (file && file.color && file.color.length) {
          setActiveColor(file.color[file.color.length - 1]);
        }
        if (file) {
          entityUpdateColor(file);
        }

        if (folder?.__children) {
          const nextChildren = folder.__children.map((child) => {
            if (child.slug === file.slug) {
              return {
                ...child,
                color: file.color,
              };
            }

            return child;
          });

          mainDispatch(
            setCurrentFolder(
              {
                ...folder,
                children: nextChildren,
              },
              state.breadcrumbs
            )
          );
        }
      });
    }
  };

  useEffect(() => {
    if (colors && colors.length > 0) {
      if (colors[colors.length - 1].id !== activeColor.id) {
        setActiveColor(colors[colors.length - 1]);
      }
    } else {
      setActiveColor({});
    }
  }, [entityId, setActiveColor, activeColor.id, colors]);

  const colorsSelector = allColors.map((color) => (
    <div key={`color-${color.id}`} className={s.colorItem__wrapper}>
      <button
        type="button"
        disabled={isMultisigActivated && !isMultisigPartisipant}
        className={cn(
          activeColor.hex === color.hex && s.activeColor,
          (isDeletedPage || (isMultisigActivated && !isMultisigPartisipant)) &&
            'entity-settings__colors-item-box-disabled'
        )}
        onClick={() => colorClickHandler(color.id)}
        data-test={`entity-settings_color_[${color.name}]`}
        style={activeColor.hex === color.hex ? { borderColor: color.hex } : {}}
      >
        <div
          className={cn(
            s.colorItem__content,
            color.name === 'empty' ? s.emptyColor : ''
          )}
          style={color.name === 'empty' ? {} : { backgroundColor: color.hex }}
        />
      </button>
    </div>
  ));

  return (
    <div className={cn('entity-settings__content', s.colorsContent)}>
      <ColorPicker
        yourColors={yourColors}
        isColorPickerOpen={isColorPickerOpen}
        color={newColor}
        onChange={handleChangeColor}
        hexColor={hexColor}
        handleChangeRGB={handleChangeRGB}
        onHexChange={handleHexChangeColor}
        handleAddNewColor={handleAddNewColor}
        activeColor={activeColor}
        isFolder={isFolder}
        onClose={() => {setIsColorPickerOpen(false)}}
      />
      <section
        className={cn('entity-settings__colors', s.colors)}
        data-test={`entity-settings_colors`}
      >
        <div className={s.colors__container}>
          {colorsSelector}
          <div className={s.colorItem__wrapper}>
            <button
              disabled={isMultisigActivated && !isMultisigPartisipant}
              type="button"
              className={s.colorEditor}
              onClick={handleEditColor}
              data-test={`entity-settings_color_[edit]`}
            >
              <ColorEditorIcon />
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Colors;
