// Frameworks
import React, { useState, useEffect } from 'react';

// libs
import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Home from './home'

// Store
import { checkAuthEffect } from 'store/auth/effects';
import { getUserInfoEffect } from 'store/home/effects';
import getFileViewTypeEffect from 'store/home/effects/file-view-type/get-file-view-type-effect';
import { resetUserHomeInfo } from 'store/home/actions/get-user-info.actions';
import { resetMultisigUsers } from 'features/workspaceSettings/workspaceSettingsSlice';
import { resetUserInfo } from 'store/account/actions';
import { workspacePlanClear } from 'store/plan/actions';

// Utils

// Components
import Preloader from 'components/shared/Preloader/index';

const HomeResolver = ({
  checkAuthEffect,
  getUserInfoEffect,
  resetMultisigUsers,
  getFileViewTypeEffect,
  workspacePlanClear,
  resetUserInfo,
  user,
}) => {
  const [isAuthUser, setIsAuthUser] = useState(false);

  const authCheck = () => {
    return checkAuthEffect();
  };

  const loadInfo = () => {

    getUserInfoEffect();
    getFileViewTypeEffect();
  };

  useEffect(() => {
    const isAuthUserNow = authCheck();

    if (isAuthUserNow) {
      loadInfo();
      setIsAuthUser(isAuthUserNow);
    }

    return () => {
      resetUserInfo();
      workspacePlanClear();
      resetUserHomeInfo();
      resetMultisigUsers();
    };
  }, []);

  const canRenderHome = () => isAuthUser && !R.isEmpty(user) && !R.isNil(user);

  if (canRenderHome()) {
      return <Home />
  }
  return <Preloader />;

};

const mapDispatchToProps = {
  checkAuthEffect,
  getUserInfoEffect,
  getFileViewTypeEffect,
  resetUserInfo,
  workspacePlanClear,
  resetMultisigUsers,
};

const mapStateToProps = (state) => ({
  user: state.home?.userInfo?.user,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  HomeResolver
);
