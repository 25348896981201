//Refactoring №3
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { loginPhantom } from 'store/auth/effects/index';
import CryptoCheckPluginModal from '../crypto-check-plugin-modal';

import Preloader from 'components/shared/Preloader';

function SignInPhantomContainer({ history }) {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [account, setAccount] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [signature, setSignature] = useState('');

  useEffect(() => {
    // TODO: In the future, add a signature
    if (account) loginPhantom(account, signature, history, dispatch);
  }, [account?.length < 4, signature?.length < 4]);

  useEffect(() => {
    if (!window.solana) setModalOpen(true);
    connect();
  }, []);

  const connect = async () => {
    try {
      const resp = await window.solana.connect();
      setAccount(resp.publicKey.toString());
    } catch (error) {
      console.warn(error);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <CryptoCheckPluginModal
        onClose={closeModal}
        isOpen={modalOpen}
        type="phantom"
      />
      <Preloader />
    </>
  );
}

export const SignInPhantom = React.memo(SignInPhantomContainer);
