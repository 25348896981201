//Refactoring №2
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import ClickOutside from 'components/shared/ClickOutside';

const ActionPopup = ({
  className,
  options,
  onAction,
  customActionArea,
  closeOnClick,
  vertical,
  openUp,
  topSm,
}) => {
  const { t } = useTranslation('workspace');
  const [isOpen, setIsOpen] = useState(false);

  const close = () => {
    setIsOpen(false);
  };

  const toggle = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const renderOptions = () => {
    return options.map((option, index) => {
      if (option === 'divider') {
        return (
          <div
            className="file-action__item option option-divider"
            key={index}
          />
        );
      }
      return (
        <button
          className={cn('file-action__item option', option.className)}
          key={index}
          onClick={(e) => {
            e.stopPropagation();
            onAction(option);
            if (closeOnClick) {
              close();
            }
          }}
        >
          {t(`invitationModal.${option.type}`)}
        </button>
      );
    });
  };

  const renderModal = () => {
    return (
      <div
        className={cn('file-action__modal', {
          ['open-up']: openUp,
          ['top-sm']: topSm,
        })}
      >
        <div className="file-action__list">{renderOptions()}</div>
      </div>
    );
  };

  const renderActionArea = () => {
    return (
      <div
        className={cn('dots-action', vertical && 'vertical')}
        onClick={toggle}
      >
        {!customActionArea ? (
          <button
            type="button"
            className={cn({
              'dots-action__button': true,
              open: isOpen,
            })}
          />
        ) : (
          customActionArea
        )}
      </div>
    );
  };

  return (
    <ClickOutside
      className={cn('file-action file-action-relative', className)}
      onClickOutside={close}
    >
      {renderActionArea()}
      {isOpen && renderModal()}
    </ClickOutside>
  );
};

export default ActionPopup;
