import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  API_PRE_SIGN_UP_URL,
  API_CONFIRM_PRE_REGISTRATION,
} from 'constants/api-urls';

const getInviteSlice = createSlice({
  name: 'getInvite',
  initialState: {
    showForm: true,
    processing: false,
    error: false,
    name: '',
    preRegistrationConfirmed: null,
    token: '',
  },
  reducers: {
    inviteRequested: (state) => {
      state.showForm = false;
      state.processing = true;
      state.error = false;
    },
    requestSuccess: (state, action) => {
      state.showForm = false;
      state.processing = false;
      state.error = false;
      state.name = action.payload;
    },
    requestError: (state) => {
      state.showForm = false;
      state.error = true;
      state.processing = false;
    },
    confirmPreRegistration: (state, action) => {
      const status = action.payload.status;
      state.preRegistrationConfirmed = status;
      if (status === preRegistrationState.CONFIRMED) {
        state.token = action.payload.token;
      }
    },
  },
});

export default getInviteSlice.reducer;

// Actions
export const requestInvite = (userInfo) => (dispatch) => {
  dispatch(getInviteSlice.actions.inviteRequested());
  axios
    .post(API_PRE_SIGN_UP_URL, userInfo)
    .then(() => {
      dispatch(getInviteSlice.actions.requestSuccess(userInfo.name));
    })
    .catch(() => {
      dispatch(getInviteSlice.actions.requestError());
    });
};

export const preRegistrationState = {
  CONFIRMED: 'confirmed',
  NOT_CONFIRMED: 'not_confirmed',
};

export const confirmPreRegistration = (token) => async (dispatch) => {
  try {
    await axios.get(`${API_CONFIRM_PRE_REGISTRATION}/${token}`);
    dispatch(
      getInviteSlice.actions.confirmPreRegistration({
        status: preRegistrationState.CONFIRMED,
        token,
      })
    );
  } catch (e) {
    dispatch(
      getInviteSlice.actions.confirmPreRegistration({
        status: preRegistrationState.NOT_CONFIRMED,
      })
    );
  }
};

export const preRegistrationIsConfirmed = (token) =>
  getInviteSlice.actions.confirmPreRegistration({
    status: preRegistrationState.CONFIRMED,
    token,
  });

// Selectors
export const selectInviteRequestState = (state) => state.getInvite;
export const selectPreRegistrationToken = (state) => state.getInvite.token;
