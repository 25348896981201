
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import NeyraLogo from 'containers/auth/NeyraWelcome/component/NeyraLogo';

import useWalletHandler from 'utils/hooks/useWalletHandler';
import { getUserInfoClear } from 'store/home/effects/user-info/get-user-info-effect';

import { ReactComponent as DotsIcon } from 'containers/mobile/assets/dots.svg'
import { getNeyraAvatar } from 'store/neyra/effects';
import getToken from 'utils/auth/get-token';

import styles from './styles.module.scss';

const Start = ({ setAvatar, avatar }) => {
  const token = getToken();
  const history = useHistory();

  useEffect(() => {
    if (token) {
      history.push('/chat')
    }
  }, [token, avatar])

  const onLoginSuccess =  () => {
    setTimeout(async () => {
      const user = await getUserInfoClear();
      const avatar = await getNeyraAvatar();
      const avatarItem = avatar?.length ? { ...avatar[0].profile, tokens: user.tokens } : { tokens: user.tokens }
      setAvatar(avatarItem)
      // history.push('/chat')

    }, 1400)
  };

  const { handleClick } = useWalletHandler(history, 'chat', onLoginSuccess);

  const signUp = () => {
    handleClick('wallet');
  }

  const onLogin = () => {
    handleClick('wallet');
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <DotsIcon />
        <div className={styles.title}>Own AI Legacy</div>
        <div className={styles.subtitle}>Intellectual ai space</div>
      </div>
      <div className={styles.logoWrapper}>
        <NeyraLogo />
      </div>
      <div className={styles.actions}>
        <div
          className={styles.neyraButton}
          onClick={signUp}
        >
          <div className={styles.buttonText}>Neyra</div>
          <div className={styles.plusIcon}>+</div>
          <div className={styles.buttonText}>Profile</div>
        </div>
        <div
          className={styles.loginButton}
          onClick={onLogin}
        >
          Sign in
        </div>
      </div>
    </div>
  );
};

export default Start;
