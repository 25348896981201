// Refactoring №3
import React from 'react';

import { ReactComponent as GDLogo } from 'static/assets/svg/gd-logo-border.svg';

import cn from 'classnames';
import s from './style.module.scss';

const EditorName = ({ className }) => {
  return (
    <div className={cn(s.EditorName_wrapper, className)}>
      <GDLogo className={s.EditorName_icon} />
    </div>
  );
};

export default EditorName;
