import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';

import { ReactComponent as CloseIcon } from 'containers/header/header-container/assets/close.svg'
import Button, { ButtonTheme } from 'components/Button';
import style from './style.module.scss';

const CreateEntityModal = ({
  modalIsOpen,
  closeModal,
  onCreate,
  title,
  contentLabel,
  inputPlaceholder,
  defaultValue,
  acceptButtonText,
  name,
}) => {
  const { t } = useTranslation('common');
  const inputRef = useRef(null);
  const [isValid, setIsValid] = useState(true);

  const onCreateClickHandler = () => {
    if (inputRef?.current?.value && inputRef?.current?.value?.trim()) {
      !isValid && setIsValid(true);
      onCreate(inputRef.current.value);
    } else {
      isValid && setIsValid(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && inputRef.current.value) {
      onCreate(inputRef.current.value);
      return;
    }
    isValid && setIsValid(false);
  };

  const handleChange = (e) => {
    if (e.target.value) {
      !isValid && setIsValid(true);
    } else {
      isValid && setIsValid(false);
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel={contentLabel}
      className="Modal"
      overlayClassName="Overlay"
      shouldCloseOnOverlayClick
    >
      <section className={style.createWrapper}>
        <button
          type="button"
          className={style.createExit}
          onClick={closeModal}
          data-test="create-entity-modal_close_button"
        >
          <CloseIcon />
        </button>
        <div className={style.createTitle}>{title}</div>
        <div className={CN(style.createInputWrapper, !isValid && style.errorInput)}>
          <input
            ref={inputRef}
            type="text"
            className={style.createInput}
            placeholder={inputPlaceholder}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            defaultValue={defaultValue}
            tabIndex={0}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            data-test="create-entity-modal_input_field"
          />
          {!isValid && (
            <span className={style.errorMessage}>
              {`${name} ${t('common.isEmpty')}`}
            </span>
          )}
        </div>
        <div className={style.createButtons}>
          <button
            className={style.createDeclineBtn}
            type="button"
            onClick={closeModal}
            data-test="create-entity-modal_cancel_button"
          >
            {t('common.cancel')}
          </button>
          <Button
            className={style.createAcceptBtn}
            theme={ButtonTheme.DARK}
            onClick={onCreateClickHandler}
            data-test="create-entity-modal_accept_button"
          >
            {acceptButtonText}
          </Button>
        </div>
      </section>
    </Modal>
  );
};

CreateEntityModal.defaultProps = {
  title: '',
  contentLabel: '',
  inputPlaceholder: '',
  acceptButtonText: 'Create',
  nodeEffect: () => {},
  name: '',
};

export default CreateEntityModal;
