// Refactoring №3
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Portal } from 'components/Portal';
import { Modal } from 'components/Modal';

import { ReactComponent as MetamaskIcon } from 'static/assets/svg/crypto/metamask.svg';

import styles from './style.module.scss';

const NoAccessWeb3Modal = ({ onClose, isOpen, onConnect }) => {
  const { t } = useTranslation('account');
  return (
    <Portal id={'no-access-web3-modal'}>
      <Modal onClose={onClose} isOpen={isOpen}>
        <div className={styles.modalContent}>
          <p className={styles.modalTitle}>{t('security.noAccessToWeb3')}</p>
          <p className={styles.modalSubtitle}>
            {t('security.addCryptoWallet')}
          </p>
          <button
            onClick={onConnect}
            type="button"
            className={styles.metamaskIcon}
          >
            <MetamaskIcon />
            <p className={styles.metamask}>Metamask</p>
          </button>
        </div>
      </Modal>
    </Portal>
  );
};

export default NoAccessWeb3Modal;
