export const FILE_MOVE = 'FILE_MOVE'
export const FILE_MOVE_SUCCESS = 'FILE_MOVE_SUCCESS'
export const FILE_MOVE_FAILURE = 'FILE_MOVE_FAILURE'

export function fileMove() {
  return {
    type: FILE_MOVE,
  }
}

export function fileMoveSuccess() {
  return {
    type: FILE_MOVE_SUCCESS,
  }
}

export function fileMoveFailure(err) {
  return {
    type: FILE_MOVE_FAILURE,
    params: {
      err,
    },
  }
}
