import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import { MainContextWrapper } from 'store/context';

import NeyraLogo from './component/NeyraLogo';
import NeyraBorder from 'components/NeyraBorder';
import NeyraProfileButton from 'components/NeyraProfileButton';
import RegistrationChat from './component/RegistrationChat';
import { PasswordRecoveryModal } from '../LoginWithEmailModal/PasswordRecovery';
import NotificationBubble from 'containers/notifications/notification-bubble';

import styles from './styles.module.scss';

const NeyraSignIn = () => {
  const { t } = useTranslation('auth');
  const [initialScreen, setInitialScreen] = useState(true);
  const [isPasswordRecovery, setIsPasswordRecovery] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/reset')) {
      setIsPasswordRecovery(true);
    }
  }, [location.pathname]);

  return (
    <MainContextWrapper>
      <NeyraBorder isLoginPage={initialScreen}>
        {initialScreen ? (
          <div className={styles.container}>
            {/* <div className={styles.infoText}>
            The community launch
            <br />
            kicks off on March 24, 2024.
          </div> */}
            <div className={styles.content}>
              <NeyraLogo />
              <h1 className={styles.title}>
                {t('initialPage.craftAI')}
                <br />
                {t('initialPage.learnCreateMonetize')}
              </h1>
            </div>
            <NeyraProfileButton
              handleClick={() => {
                setInitialScreen(false);
              }}
            />
          </div>
        ) : (
          <RegistrationChat
            closeRegistrationChat={() => {
              setInitialScreen(true);
            }}
          />
        )}
        {isPasswordRecovery && (
          <PasswordRecoveryModal
            isPasswordRecovery={isPasswordRecovery}
            setIsPasswordRecovery={setIsPasswordRecovery}
          />
        )}
      </NeyraBorder>
      <NotificationBubble />
    </MainContextWrapper>
  );
};

export default NeyraSignIn;
