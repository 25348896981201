/* eslint-disable no-async-promise-executor */
// utils
import authRequest from 'utils/request/auth-request';
import { API_NOTIFICATIONS } from 'constants/api-urls';

import { INotification } from 'interfaces/notification';

import { NOTIFICATION_TYPES } from '../actions';

export type IGetNotificationByTypeEffect = {
  data: {
    count: number,
    data: INotification[]
  }
}

type IDefaultResponceEffect = {
  unread: {
    count: number,
    data: INotification[]
  },
  read: {
    count: number,
    data: INotification[]
  }
}

// eslint-disable-next-line no-async-promise-executor
export default async (page = 1): Promise<IDefaultResponceEffect> =>
  new Promise(async (resolve) => {
    const unread = await authRequest
      .get(
        `${API_NOTIFICATIONS}?read=${NOTIFICATION_TYPES.unread}&page=${page}`
      )
      .then(({ data }: any) => {
        return data;
      });
    const read = await authRequest
      .get(`${API_NOTIFICATIONS}?read=${NOTIFICATION_TYPES.read}&page=${page}`)
      .then(({ data }: any) => {
        return data;
      });

    resolve({ unread, read });
  });

const getNextNotificationByTypeEffect = (
  type: number = NOTIFICATION_TYPES.unread,
  page: number
): Promise<IGetNotificationByTypeEffect> =>
  authRequest
    .get(`${API_NOTIFICATIONS}?read=${type}&page=${page}`)
    .then(({ data }: any) => {
      return data ;
    });

export { getNextNotificationByTypeEffect, NOTIFICATION_TYPES };
