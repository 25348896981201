// Refactoring №3
import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import CloseIcon from 'components/svg/close';
import { ReactComponent as MainLogo } from 'static/assets/svg/ghost-drive-main-logo.svg';

import styles from './style.module.scss';

export default function SuccessSendedLinkToEmailModal({ isOpen, close }) {
  const { t } = useTranslation('account');

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={styles.overlay}
      className={styles.modal}
      contentLabel="Example Modal"
      onRequestClose={close}
      shouldCloseOnOverlayClick
    >
      <div className={styles.modal__wrapper}>
        <button type="button" className={styles.modal__exit} onClick={close}>
          <CloseIcon color="#4B576C" />
        </button>
        <div className={styles.modal__successSendedContent}>
          <MainLogo />
          <div>
            <div
              className={`${styles.modal__title} ${styles.modal__title_success}`}
            >
              {t('Profile.weJustEmailedYou')}
            </div>
            <div className={styles.modal__description}>
              {`${t('Profile.emailSent')} ${`\n`} ${t(
                'Profile.instructionsOnCompleting'
              )}`}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
