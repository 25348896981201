import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Form, Formik } from 'formik';

import InputFieldFormik from 'components/InputFieldFormik';
import ResentCodeButton from './ResentCodeButton';

import styles from './styles.module.scss';

const initialValues = {
  code: '',
};

export const VerificationCode = ({
  formRef,
  submitHandler,
  onFocus,
  errors,
  resentCode,
}) => {
  const { t } = useTranslation('auth');
  return (
    <>
      <div className={styles.verificationCodeTitle}>
        <h2>{t('emailLogin.verificationCode')}</h2>
        <p>
          {t('emailLogin.provideCode')}{' '}
          <ResentCodeButton onClick={resentCode} />
        </p>
      </div>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={submitHandler}
      >
        <Form>
          <div className={styles.fields}>
            <div className={styles.relative}>
              <InputFieldFormik
                className={classNames(
                  styles.input,
                  errors.code && styles.inputError
                )}
                name="code"
                placeholder="000-000"
                autoComplete="off"
                data-test="sign-in-container_email_field"
                onFocus={onFocus}
              />
              <div className={styles.errorMessage}>{errors.code}</div>
            </div>
          </div>
          <button
            type="submit"
            className={styles.continueBtn}
            data-test="sign-in-container_log_email_button"
          >
            {t('common.continue')}
          </button>
        </Form>
      </Formik>
    </>
  );
};
