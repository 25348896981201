import * as actions from '../../actions/file/file-update.actions'

const initialState = {
  pending: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FILE_UPDATE:
      return {
        ...state,
        pending: true,
      }
    case actions.FILE_UPDATE_SUCCESS:
      return {
        ...state,
        pending: false,
      }
    case actions.FILE_UPDATE_FAILURE:
      return {
        ...state,
        pending: false,
      }
    default:
      return state
  }
}
