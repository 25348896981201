import React from 'react';

import { ReactComponent as LogoIcon } from './assets/logo.svg';

import CN from 'classnames';
import style from './style.module.scss';

const NeyraSmallLogo = ({ handleClick, className, disabled = false }) => {
  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className={CN(style.logo, className)}
    >
      <LogoIcon />
    </button>
  );
};

export default NeyraSmallLogo;
