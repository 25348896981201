import { orderBy } from 'lodash'
import { NotificationActionTypes, NOTIFICATION_TYPES } from '../actions'

const initialsState = {
  unreadCount: 0,
  readCount: 0,
  unreadPage: 1,
  readPage: 1,
  unreadNotifications: [],
  readNotifications: [],
}

export default (state = initialsState, action) => {
  const { type, payload } = action
  switch (type) {
    case NotificationActionTypes.setNotifications:
      return {
        ...state,
        unreadCount: payload.unread.count,
        readCount: payload.read.count,
        unreadNotifications: payload.unread.data,
        readNotifications: payload.read.data,
      }

    case NotificationActionTypes.reviewsNotifications:
      return {
        ...state,
        unreadCount: state.unreadCount > 0 ? state.unreadCount - 1 : 0,
        readCount: state.readCount + 1,
        unreadNotifications:
          state.unreadNotifications.filter((item) => item.id !== payload.id) ||
          [],
        readNotifications: orderBy(
          [
            ...state.readNotifications,
            {
              ...state.unreadNotifications.find(
                (item) => item.id === payload.id
              ),
              viewed: true,
            },
          ],
          'created_at',
          'desc'
        ),
      }

    case NotificationActionTypes.setNextNotificationsByType: {
      const { type, notifications, page } = payload
      const notificationType =
        type === NOTIFICATION_TYPES.read ? 'read' : 'unread'
      const currentPage =
        type === NOTIFICATION_TYPES.read ? 'readPage' : 'unreadPage'
      const previousNotifications =
        page === 1 ? [] : state[`${notificationType}Notifications`]
      const nextNotification = [...previousNotifications, ...notifications]
      return {
        ...state,
        [`${notificationType}Notifications`]: nextNotification,
        [`${notificationType}Count`]: nextNotification.length,
        [currentPage]: page,
      }
    }

    case NotificationActionTypes.updateNotification:
      return {
        ...state,
        unreadNotifications: state.unreadNotifications.map((nf) => nf.id === payload.id ? payload.notification : nf),
        readNotifications: state.readNotifications.map((nf) => nf.id === payload.id ? payload.notification : nf)
      }

    case NotificationActionTypes.reset:
      return {
        ...initialsState,
      }

    default:
      return state
  }
}
