import * as actions from '../actions/ghost-files.actions'
import getData from 'utils/request/get-data'
import removeBy from 'utils/array/remove-by'
import getArrayFromData from 'utils/array/get-array-from-data'
import { updateObjInArray } from 'utils/array/update-obj-in-array'

const initialState = {
  entities: [],
  count: 0,
  wasLoaded: false,
  pending: false,
}

export default (state = initialState, action) => {
  let newFiles, data, file
  switch (action.type) {
    case actions.GHOST_FILES_GET:
      return {
        ...state,
        pending: true,
      }

    case actions.GHOST_FILES_LOAD_MORE_SUCCESS:
      return {
        ...state,
        entities: [...state.entities, ...getArrayFromData(getData(action))],
        wasLoaded: true,
        pending: false,
      }

    case actions.GHOST_FILES_ADD:
      newFiles = getArrayFromData(getData(action))
      return {
        ...state,
        entities: [...state.entities, ...newFiles],
        count: state.count + newFiles.length,
        wasLoaded: true,
        pending: false,
      }

    case actions.GHOST_FILES_UPDATE:
      data = action?.payload

      return {
        ...state,
        entities: updateObjInArray(
          data.entity,
          state.entities,
          'slug',
          data.updatedPropsList
        ),
      }

    case actions.GHOST_FILES_REMOVE:
      file = getData(action)
      return {
        ...state,
        entities: removeBy(file, state.entities, 'slug'),
        count: state.count - 1,
      }

    case actions.GHOST_FILES_CLEAR:
      return {
        state,
        pending: false,
        wasLoaded: false,
        count: 0,
        entities: [],
      }

    case actions.GHOST_FILES_GET_SUCCESS:
      return {
        ...state,
        pending: false,
        count: getData(action).count,
        wasLoaded: true,
        entities: getData(action).data,
      }

    case actions.GHOST_FILES_GET_FAILURE:
      return {
        ...state,
        pending: false,
      }

    default:
      return state
  }
}
