/* eslint-disable no-debugger */
// Refactoring №3
import { createSlice } from '@reduxjs/toolkit';

const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    multisigStatusModal: false,
    multisigShareModal: false,
    createDocumentOpen: false,
    createFolderOpen: false,
    createAiGeneratorOpen: false,
    createWorkspaceModal: false,
    accessPreferencesOpen: false,
    notRegisteredWalletsModal: false,
    deletAllModal: false,
    wsBuilder: false,
    viewFileOpen: false,
    showMoveOpen: false,
    geoModal: false,
    editOpen: false,
    disableEnter: false,
    analyticsOpen: false,
    actionsOpen: false,
    saftyAlertOpen: false,
    recordModal: false,
    nftModal: false,
    safetyNetModal: false,
    ipWhiteListModal: false,
    vaultModal: false,
    linkModal: false,
    notEnoughToken: false,
    uploadOpen: false,
    successModal: false,
    deleteModal: false,
    cancelUploadModal: false,
    connectWalletModal: false,
    bugReportModal: false,
    previewModal: false,
    moveModal: false,
    neyraPromptHelperModal: false,
    additionalData: null,
  },
  reducers: {
    handleCreateDocumentModal(state, { payload }) {
      state.createDocumentOpen = payload;
    },
    handleCreateFolderModal(state, action) {
      state.createFolderOpen = action.payload;
    },
    handleCreateWorkspaceModal(state, action) {
      state.createWorkspaceModal = action.payload.status;
      state.additionalData = action.payload.data;
    },
    handleOpenAiGeneratorModal(state, action) {
      state.createAiGeneratorOpen = action.payload;
    },
    handleAccessPreferencesModal(state, action) {
      state.accessPreferencesOpen = action.payload;
    },
    handleNotRegisteredWalletsModal(state, action) {
      state.notRegisteredWalletsModal = action.payload.status;
      state.additionalData = action.payload.data;
    },
    handleViewFileModal(state, action) {
      state.viewFileOpen = action.payload;
    },
    handleShowMoveModal(state, action) {
      state.showMoveOpen = action.payload;
    },
    handleEditModal(state, action) {
      state.editOpen = action.payload;
    },
    handleConnectWalletModal(state, action) {
      state.connectWalletModal = action.payload;
    },
    handleGeoModal(state, action) {
      state.geoModal = action.payload.modal;
      state.additionalData = action.payload.entity;
    },
    handleSafetyNetModal(state, action) {
      state.safetyNetModal = action.payload.modal;
      state.additionalData = action.payload.entity;
    },
    handleActionsModal(state, action) {
      state.actionsOpen = action.payload.modal;
      state.additionalData = action.payload.data;
    },
    handleSaftyAlerttModal(state, action) {
      state.saftyAlertOpen = action.payload.isOpen;
      state.additionalData = action.payload.data;
    },
    handleAnalyticsModal(state, action) {
      state.analyticsOpen = action.payload.status;
      state.additionalData = action.payload.slug;
    },
    handleNftModal(state, action) {
      state.nftModal = action.payload.status;
    },
    handleVaultModal(state, action) {
      state.vaultModal = action.payload.isOpen;
      state.additionalData = {
        slug: action.payload.slug || null,
        isEdit: action.payload.isEdit || false,
      };
    },
    handleLinkModal(state, action) {
      state.linkModal = action.payload.isOpen;
      state.additionalData = {
        slug: action.payload.slug || null,
        isEdit: action.payload.isEdit || false,
      };
    },
    handleRecordModal(state, action) {
      state.recordModal = action.payload;
    },
    handleNotEnoughTokenModal(state, action) {
      state.notEnoughToken = action.payload;
    },
    handleUpload(state, action) {
      state.uploadOpen = action.payload;
    },
    handleAdditionalData(state, action) {
      state.additionalData = action.payload;
    },
    handleDisableEnter(state, action) {
      state.disableEnter = action.payload;
    },
    handleConvertModal(state, action) {
      state.convertModal = action.payload.modal;
      state.additionalData = action.payload.entity;
    },
    handleMultisigStatusModal(state, action) {
      state.multisigStatusModal = action.payload.isOpen;
      state.additionalData = action.payload.payload;
    },
    handleMultisigShareModal(state, action) {
      state.multisigShareModal = action.payload.isOpen;
      state.additionalData = action.payload.entity || null;
    },
    handleWsBuilderModal(state, action) {
      state.wsBuilder = action.payload.isOpen;
    },
    handleSuccessModal(state, action) {
      state.successModal = action.payload.isOpen;
      state.additionalData = action.payload.text;
    },
    handleDeleteModal(state, action) {
      state.deleteModal = action.payload.isOpen;
      state.additionalData = action.payload.entity;
    },
    handleCancelUploadModal(state, action) {
      state.cancelUploadModal = action.payload;
    },
    handleIPWhiteListModal(state, action) {
      state.ipWhiteListModal = action.payload;
    },
    handleDeletAllModal(state, action) {
      state.deletAllModal = action.payload;
    },
    handleBugReportModal(state, action) {
      state.bugReportModal = action.payload;
    },
    handlePreviewModal(state, action) {
      state.previewModal = action.payload.isOpen;
      state.additionalData = action.payload.entity;
    },
    handleNeyraPromptHelperModal(state, action) {
      state.neyraPromptHelperModal = action.payload;
    },
    handleMoveModal(state, action) {
      state.moveModal = action.payload.isOpen;
      state.additionalData = action.payload.entity;
    },
    onCloseAllModal(state) {
      const closeList = []; 
      Object.keys(state).forEach((key) => {
        if (state[key] === true)
          closeList.push(key)
      })
      closeList.forEach((key) => {
        state[key] = false
      })
      state.additionalData = null
    },
  },
});

const { reducer, actions } = modalsSlice;
export const {
  handleCreateDocumentModal,
  handleCreateFolderModal,
  handleCreateWorkspaceModal,
  handleOpenAiGeneratorModal,
  handleAccessPreferencesModal,
  handleNotRegisteredWalletsModal,
  handleNotEnoughTokenModal,
  handleMultisigStatusModal,
  handleMultisigShareModal,
  handleWsBuilderModal,
  handleViewFileModal,
  handleShowMoveModal,
  handleVaultModal,
  handleLinkModal,
  handleEditModal,
  handleGeoModal,
  handleMoveModal,
  handlePreviewModal,
  handleSafetyNetModal,
  handleIPWhiteListModal,
  handleAnalyticsModal,
  handleActionsModal,
  handleSaftyAlerttModal,
  handleNftModal,
  handleDisableEnter,
  handleUpload,
  handleRecordModal,
  handleAdditionalData,
  handleConvertModal,
  handleSuccessModal,
  handleDeleteModal,
  handleCancelUploadModal,
  handleConnectWalletModal,
  handleDeletAllModal,
  handleBugReportModal,
  handleNeyraPromptHelperModal,
  onCloseAllModal,
} = actions;

export const selectIsEnterDisable = (state) => state.modals.disableEnter;
export const selectVaultModal = (state) => state.modals.vaultModal;
export const selectLinkModal = (state) => state.modals.linkModal;
export const selectAdditionalData = (state) => state.modals.additionalData;
export const selectOpenUpload = (state) => state.modals.uploadOpen;
export const selectRecordModal = (state) => state.modals.recordModal;
export const selectWSBuilderModal = (state) => state.modals.wsBuilder;
export const selectMultisigStatusModal = (state) =>
  state.modals.multisigStatusModal;
export const selectCancelUploadModal = (state) =>
  state.modals.cancelUploadModal;
export const selectConnectWalletModal = (state) =>
  state.modals.connectWalletModal;
export const selectNotEnoughTokensModal = (state) =>
  state.modals.notEnoughToken;
export const selectBugReportModal = (state) => state.modals.bugReportModal;
export const selectNeyraPromptHelperModal = (state) =>
  state.modals.neyraPromptHelperModal;

export default reducer;
