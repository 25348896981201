import React from 'react'
import Card from 'components/Card'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import cn from 'classnames'
import InputFieldFormik from 'components/InputFieldFormik'
import { InputFieldTheme } from 'components/InputField'
import Button from 'components/Button'

import s from './style.module.css'

const initialValues = {
  name: '',
  email: '',
  company: '',
  profession: '',
}

const REQUIRED_MESSAGE = 'This field is required'

const validationSchema = Yup.object({
  name: Yup.string()
    .min(2, 'Must have more then 2 characters')
    .required(REQUIRED_MESSAGE)
    .test('full name', 'please, enter your full name', (value) => {
      return /\s.+/.test(value)
    }),
  email: Yup.string().email('enter valid email').required(REQUIRED_MESSAGE),
  company: Yup.string(),
  profession: Yup.string(),
})

export default function InviteForm({ className, onSubmit }) {
  return (
    <Card className={cn(s.wrapper, className)}>
      <h1 className={s.title}>
        Tell us a little about yourself to get an invitation link
      </h1>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form>
          <InputFieldFormik
            className={s.input}
            name="name"
            label="Full name"
            placeholder="Your name"
            theme={InputFieldTheme.GET_INVITE}
          />
          <InputFieldFormik
            className={s.input}
            name="email"
            label="Email"
            placeholder="Your email"
            type="email"
            theme={InputFieldTheme.GET_INVITE}
          />
          <InputFieldFormik
            className={s.input}
            name="company"
            label="Company name"
            optional
            placeholder="Company name"
            theme={InputFieldTheme.GET_INVITE}
          />
          <InputFieldFormik
            className={s.input}
            name="profession"
            label="Position"
            optional
            placeholder="Position"
            theme={InputFieldTheme.GET_INVITE}
          />
          <Button
            className={s.button}
            type="submit"
            value="Get invitation link"
          />
        </Form>
      </Formik>
    </Card>
  )
}
