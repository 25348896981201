import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { selectCollapsed } from 'features/leftMenu/leftMenuSlice';
import { Link, NavLink } from 'react-router-dom';
import CN from 'classnames';

import { ThemeContext, LIGHT_THEME } from 'store/context/theme';

import style from './style.module.scss';

export const SidebarMenuItemType = {
  NavLink: 'nav-link',
  Anchor: 'anchor',
  Button: 'button',
  Link: 'link',
};

const LIGHT_ACTIVE_COLOR = '#007AFF';
const LIGHT_ACTIVE_BG_COLOR = 'var(--sidebarActiveItem)';
const DARK_ACTIVE_COLOR = '#FFFFFF';
const DARK_ACTIVE_BG_COLOR = 'var(--sidebarActiveItem';

export default function SidebarMenuItem({
  className,
  type = SidebarMenuItemType.NavLink,
  name,
  url,
  icon,
  active = false,
  onClick,
  showOnyIcon = false,
}) {
  const { theme } = useContext(ThemeContext);
  const bg_color =
    theme === LIGHT_THEME ? LIGHT_ACTIVE_BG_COLOR : DARK_ACTIVE_BG_COLOR;
  const text_color =
    theme === LIGHT_THEME ? LIGHT_ACTIVE_COLOR : DARK_ACTIVE_COLOR;
  const { innerWidth: width } = window;

  const collapsed = useSelector(selectCollapsed) && !(width < 480);
  const SidebarMenuItemWrapper = ({ children }) => {
    switch (type) {
      case SidebarMenuItemType.Anchor:
        return (
          <a
            style={
              active
                ? { backgroundColor: bg_color, color: text_color }
                : undefined
            }
            href={url}
            className={CN(
              style.wrapper,
              showOnyIcon && style.showOnlyIcon,
              className
            )}
            onClick={onClick}
            title={name}
          >
            {children}
          </a>
        );
      case SidebarMenuItemType.NavLink:
        return (
          <NavLink
            activeClassName={style.active}
            activeStyle={{
              backgroundColor: bg_color,
              color: text_color,
            }}
            to={url}
            className={CN(
              style.wrapper,
              collapsed && style.collapseWrapper,
              showOnyIcon && style.showOnlyIcon,
              className
            )}
            onClick={onClick}
            title={name}
          >
            {children}
          </NavLink>
        );

      case SidebarMenuItemType.Button:
        return (
          <div
            className={CN(
              style.wrapper,
              showOnyIcon && style.showOnlyIcon,
              className
            )}
            onClick={onClick}
          >
            {children}
          </div>
        );

      case SidebarMenuItemType.Link:
        return (
          <Link
            className={CN(
              style.wrapper,
              showOnyIcon && style.showOnlyIcon,
              className,
              active && style.active
            )}
            style={
              active
                ? {
                    backgroundColor: bg_color,
                    color: text_color,
                  }
                : undefined
            }
            onClick={onClick}
            to={url}
            title={name}
          >
            {children}
          </Link>
        );
      default:
        return null;
    }
  };

  return (
    <SidebarMenuItemWrapper>
      {icon &&
        icon({ className: CN(style.icon, collapsed && style.collapsed) })}
      {!collapsed && (
        <>
          {' '}
          {!showOnyIcon && name && <span className={style.name}>{name}</span>}
          <div style={{  }} className={CN(style.nameContainer, 'popover popover-right')}>
            {name}
          </div>
        </>
      )}
    </SidebarMenuItemWrapper>
  );
}
