import { UUIDBase64Converter } from 'gdgateway-client/lib/es5/utils/UUIDBase64Converter';

export const generateSharingLink = (slug) => {
  const shortenerURL = process.env.REACT_APP_SHARING_FILE_URL;
  const ghostdriveURL = window.location.origin;
  const converter = new UUIDBase64Converter(shortenerURL, ghostdriveURL);
  const base64Url = converter.generateShortenerURL(slug);

  return base64Url;
};
