import React from 'react';
import CN from 'classnames';
import { useDispatch } from 'react-redux';

import {
  getFilesRemove,
  getFilesUpdate,
} from 'store/home/actions/files/get-files.actions';
import styles from './style.module.css';
import { isNumber } from 'lodash';

const DOT_COUNT = 36;

const Progress = ({ total, progress, slug, error, file }) => {
  const dispatch = useDispatch();
  const interest = parseInt((progress / total) * 100);
  const array = Array.from(Array(DOT_COUNT), (_, x) => x);

  if ((isNumber(total) && progress >= total) || error) {
    if (file.type === 2) {
      delete file.preload;
      delete file.uploadId;
      dispatch(getFilesUpdate({ entity: file }));
    } else {
      dispatch(
        getFilesRemove({
          slug,
        })
      );
    }
  }

  const arrayInterest = parseInt((interest / 100) * DOT_COUNT);

  return (
    <div className={styles.progress}>
      <div className={styles.progressBar}>
        <div className={styles.progressBarBg}>
          {array.map((index) => (
            <div
              className={CN(
                styles.progressBarDot,
                index + 1 > arrayInterest
                  ? styles.progressBarDotBg
                  : styles.progressBarDotActive
              )}
              key={`dot-${index}`}
            ></div>
          ))}
        </div>
      </div>
      <p className={styles.progressBtn}>{interest || 0}%</p>
    </div>
  );
};

export default Progress;
