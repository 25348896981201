import { combineReducers } from 'redux';
import userInfo from './user-info.reducer';
import userRecover from './user-recover.reducer';
import securitiesDeactivate from './securities/securities-deactivate.reducer';
import trustMembers from './trust-members.reduce';
import ghostAccess from './ghost-access.reduce';
import web3Checker from './web3-checker.reducer';
import web3 from './web3.reducer';

export const accountSettingsReducer = combineReducers({
  userInfo,
  userRecover,
  securitiesDeactivate,
  trustMembers,
  ghostAccess,
  web3Checker,
  web3,
});
