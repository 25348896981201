//Refactoring №3
import React, {
  FC,
  useMemo,
  useState,
  useContext,
  useCallback,
  ReactNode,
} from 'react';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';

import AppSelect from 'components/AppSelect';
import { InputFieldTheme } from 'components/InputField';
import CreateCollectionForm from '../CreateCollectionForm';
import CreateItemForm from '../CreateItemForm';

import {
  createCollection,
  createToken,
  switchNetwork,
  COLECTION_COLORS,
} from 'store/web3';
import { setCurrentWorkspace } from 'store/main/actions';
import useNotification from 'utils/hooks/use-notification';
import { getUserInfoEffect } from 'store/home/effects';
import MainContext from 'store/main/context/main-context';
import { addRewards } from 'store/file/effects';
import { getFilesUpdate } from 'store/home/actions/files/get-files.actions';
import { unshareFile } from 'store/home/effects/file/share-file.effect';
import { selectedEntityUpdateByProps } from 'store/home/actions/selected-entity.actions';
import styles from './styles.module.scss';

import {
  INetwork,
  ISmartContract,
  ICreateTokenFormData,
  ICreateColectionFormData,
} from '../../interfaces';
import {
  getNetworkIcon,
  getCurrentChainId,
  getNetworkById,
} from 'utils/crypto';
import { SMART_CONTRACT_TYPES } from 'config/smart-contrat-types';
import { SHARE_TYPES } from 'config/share-types';
import { getCoinbaseProvider } from 'utils/getCoinbaseProvider';
import { createMonetize } from 'components/PreviewMonetize/api';

type Option = {
  label: any;
  value: string;
};

interface CreateFormProps {
  className: string;
  collections: ISmartContract[];
  networks: INetwork[];
  entity: any;
  setCollections: (colection: ISmartContract) => void;
  chainId: number;
  updateTokenization: (callback: () => void) => void;
  handleChangeNetwork: (chainId: number) => string;
  setTokenizationData: (data: any) => void;
}

const selectStyle = {
  control: (styles: any, state: any) => ({
    ...styles,
    height: 44,
    borderRadius: 8,
    boxShadow: 'none',
    padding: '0 18px',
    cursor: 'pointer',
    background: 'transparent',
    fontFamily: "'SF Pro Text', sans-serif",
    fontSize: '14px',
    lineHeight: '20px',
    '&:hover': {
      borderColor: !state.isFocused && '#0F73EF',
    },
    borderColor: state.isFocused ? '#0F73EF' : 'var(--inputBorder)',
  }),
  valueContainer: (styles: any) => {
    return {
      ...styles,
      position: 'initial',
      padding: '0',
    };
  },
  option: (styles: any, state: any) => {
    return {
      ...styles,
      padding: '12px 16px',
      textAlign: 'start',
      fontFamily: "'SF Pro Text', sans-serif",
      fontSize: '14px',
      lineHeight: '20px',
      cursor: 'pointer',
      color: '#fff',
      backgroundColor: (state.isSelected && '#242424') || '#000',
      '&:hover': {
        backgroundColor: '#242424',
      },
    };
  },
  singleValue: (styles: any) => {
    return {
      ...styles,
      color: '#fff',
    };
  },
};

const ValueContainer: FC<{ children: ReactNode }> = ({
  children,
  ...props
}) => (
  <components.ValueContainer {...props}>
    <div className={styles.selectedOption}>{children}</div>
  </components.ValueContainer>
);

const CreateForm: FC<CreateFormProps> = ({
  collections,
  networks,
  entity,
  chainId,
  handleChangeNetwork,
  setTokenizationData,
  setCollections,
}) => {
  const { t } = useTranslation('owner');
  const mainDispatch = useDispatch();
  const { state: mainState, dispatch } = useContext(MainContext);
  const { workspace } = mainState;
  const { addNotification } = useNotification();
  const [selectedColection, setSelectedColection] = useState<Option>();
  const [loading, setLoading] = useState<boolean>(false);
  const collection = useMemo(
    () =>
      selectedColection
        ? collections.find(
            (item) => item.smart_contract === selectedColection.value
          )
        : '',
    [collections, selectedColection]
  );

  const defaultOption = {
    label: (
      <div className={styles.optionCreate}>
        {t('rightFileMenu.item.createCollection')}
      </div>
    ),
    value: 'createCollection',
  };

  const renderOption = useCallback(
    (col: ISmartContract): Option => ({
      label: (
        <div className={styles.option}>
          <div className={styles.optionNetwork}>
            {getNetworkIcon(col.network.symbol)}
            <span className={styles.optionNetworkMarker}>
              {col.network.symbol}
            </span>
          </div>
        </div>
      ),
      value: col.smart_contract,
    }),
    []
  );

  const options = [defaultOption, ...collections.map(renderOption)];

  const handleOnChange = useCallback(
    async (collection: Option) => {
      try {
        const nextColection = collections.find(
          (item) => item.smart_contract === collection.value
        );
        if (nextColection) {
          if (chainId === nextColection.network.chain_id) {
            setSelectedColection(collection);
          } else {
            const res = await switchNetwork(
              networks.find(
                (item) => item.chain_id === nextColection.network.chain_id
              )
            );
            res && setSelectedColection(collection);
          }
        } else {
          setSelectedColection(collection);
        }
      } catch (error) {
        console.warn(error);
      }
    },
    [networks]
  );

  const onErrorHandler = () => {
    setLoading(false);
  };

  const handleCreateCollection = useCallback(
    async (data: ICreateColectionFormData) => {
      try {
        const providerName = localStorage.getItem('PROVIDER');
        const currentProvider =
          providerName === 'coinbase' ? getCoinbaseProvider() : window.metamask;
        const currentChain = await getCurrentChainId(
          { useMetamask: true },
          currentProvider
        );

        if (currentChain !== data.network.chain_id) {
          const nextChain = await handleChangeNetwork(data.network.chain_id);
          if (nextChain) {
            await setLoading(true);
            await createCollection(data.name, addCollection, onErrorHandler);
            mainDispatch(getUserInfoEffect());
          } else {
            console.warn('Please change NETWORK');
          }
        } else {
          await setLoading(true);
          await createCollection(
            data.name,
            addCollection,
            onErrorHandler,
            currentProvider
          );
          mainDispatch(getUserInfoEffect());
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [networks]
  );

  const addCollection = useCallback(
    (data) => {
      const newCollection = {
        smart_contract: data.contract,
        type: data.type,
        network: {
          chain_id: data.network,
          id: data.network,
          symbol: getNetworkById(data.network),
        },
        tariff1_title: null,
        tariff1_description: null,
        name: data.name,
        description: null,
        is_active: 1,
      };

      const nextWorkspace = {
        ...workspace,
        smart_contracts: [...workspace.smart_contracts, newCollection],
      };

      const filteredContract = nextWorkspace.smart_contracts.filter(
        (item) => item.type === SMART_CONTRACT_TYPES.collection
      );

      setCollections(filteredContract);
      setSelectedColection(
        renderOption({
          ...newCollection,
          color: COLECTION_COLORS[(filteredContract.length - 1) % 3],
        })
      );
      dispatch(setCurrentWorkspace(nextWorkspace));
      setLoading(false);
    },
    [dispatch]
  );

  const handleCreateToken = useCallback(
    async (data: ICreateTokenFormData) => {
      try {
        const currentChain = await getCurrentChainId({ useMetamask: true });
        const currentCollection = collections.find(
          (item) => item.smart_contract === selectedColection?.value
        );
        const selectedNetwork = networks.find(
          (net) => net.chain_id === currentCollection?.network.chain_id
        );

        const fetchData = {
          ...data,
          royalties: data.royalties * 100,
          supply: data.supply,
          fileId: entity.slug,
          hash: selectedColection?.value,
        };

        if (currentChain !== selectedNetwork?.chain_id) {
          const nextChain = await handleChangeNetwork(
            selectedNetwork?.chain_id
          );
          if (nextChain) {
            createTokenProcess(fetchData);
          } else {
            console.warn('Please change NETWORK');
          }
        } else {
          createTokenProcess(fetchData);
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [selectedColection, collection]
  );

  const tokenizeEntryGroups = () => {
    return {
      ...entity,
      entry_groups: entity.entry_groups.length
        ? entity.entry_groups.map((el, index) => {
            if (index === 0) return { ...el, is_tokenized: true };
            return el;
          })
        : [{ is_tokenized: true }],
    };
  };

  const onSuccessCreateToken = useCallback(
     async (data: any) => {
      const sharedByLinkId = entity?.shares?.reduce(
        (acumulator: any, share: any) => {
          if (share.type === SHARE_TYPES.SHARED_BY_LINK) return share.id;
        },
        false
      );

      const token = {
        amount: '0',
        chain_id: data.network,
        collection: collection?.name,
        description: data.description,
        fileId: data.slug,
        ghost1155Collection: {
          id: data.collection,
          workspaceId: workspace.id,
        },
        id: `${data.collection}.${data.token}`,
        maxSupply: `${data.tokenMaxSupply}`,
        name: data.name,
        network_id: data.network,
        owners: [],
        royalties: data.royalties,
        tokenId: data.token,
        totalSupply: '0',
      };
      setTokenizationData([token]);
      const body = {
        collection: data.collection,
        token: data.token,
        description: data.description,
        group: data.slug,
        price: '0',
      };
      await createMonetize(body);
      mainDispatch(
        getFilesUpdate({
          entity: tokenizeEntryGroups(),
          updatedPropsList: ['entry_groups'],
        })
      );

      mainDispatch(
        selectedEntityUpdateByProps({
          entity: tokenizeEntryGroups(),
          updatedPropsList: ['entry_groups'],
        })
      );
      sharedByLinkId &&
        unshareFile(entity.slug, sharedByLinkId).then((data) => {
          mainDispatch(
            selectedEntityUpdateByProps({
              entity: data,
              updatedPropsList: ['shares'],
            })
          );
        });
      addRewards(2).then(() => {
        mainDispatch(getUserInfoEffect());
      });
      addNotification(
        t('rightFileMenu.item.itemCreatedNotification'),
        'success'
      );
      setLoading(false);
    },
    [collection, workspace]
  );

  const createTokenProcess = useCallback(
    async (data: any) => {
      await setLoading(true);
      await createToken(data, onSuccessCreateToken, onErrorHandler);
    },
    [collection, workspace]
  );

  return (
    <div className={styles.createForm}>
      <AppSelect
        className={styles.input}
        isSearchable={false}
        components={{ ValueContainer }}
        name="collection"
        label={t('rightFileMenu.item.collection')}
        placeholder={t('rightFileMenu.item.chooseCollection')}
        theme={InputFieldTheme.GET_INVITE}
        options={options}
        newStyles={selectStyle}
        onChange={handleOnChange}
        value={selectedColection}
      />
      {selectedColection?.value && (
        <>
          {selectedColection?.value === 'createCollection' ? (
            <CreateCollectionForm
              onSubmit={handleCreateCollection}
              networks={networks}
              loading={loading}
            />
          ) : (
            <CreateItemForm onSubmit={handleCreateToken} loading={loading} />
          )}
        </>
      )}
    </div>
  );
};

export default CreateForm;
