/*eslint-disable*/
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { Form, Formik } from 'formik';

import { validationRegExp } from 'utils/validation/regexp';
import { recoveryPasswordCompleteEffect } from 'store/auth/effects/recovery-password.effect';
import useNotification from 'utils/hooks/use-notification';

import InputFieldFormik from 'components/InputFieldFormik';

import styles from './styles.module.scss';

const initialValues = {
  newPassword: '',
  repeatPassword: '',
};

const initialErrorValues = {
  newPassword: '',
  repeatPassword: '',
};

export const PasswordRecoveryModal = ({
  formRef,
  isPasswordRecovery,
  setIsPasswordRecovery,
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState(initialErrorValues);
  const { token } = useParams();
  const history = useHistory();
  const { addNotification } = useNotification();
  const { t } = useTranslation('auth');

  const validatePassword = (password) => {
    const isMinLengthValid = password.length >= 8;
    const isCapitalValid =
      validationRegExp.hasUpperCaseCharacter.test(password);
    const isLowerCaseValid =
      validationRegExp.hasLowCaseCharacter.test(password);
    const isNumberValid = validationRegExp.hasDigits.test(password);

    return (
      isMinLengthValid && isCapitalValid && isLowerCaseValid && isNumberValid
    );
  };

  const validateNewPassword = ({ value }) => {
    setNewPassword(value);
    if (value && !validatePassword(value)) {
      setError((prev) => ({
        ...prev,
        newPassword: t('initialPage.invalidPassword'),
      }));
      return;
    } else {
      setError((prev) => ({ ...prev, newPassword: '' }));
    }
  };

  const ValidateRepeatedPassword = ({ value }) => {
    if (!!newPassword && !!value && value !== newPassword) {
      setError((prev) => ({
        ...prev,
        repeatPassword: t('common.passwordDoNotMatch'),
      }));
      return;
    } else {
      setError((prev) => ({ ...prev, repeatPassword: '' }));
    }
  };

  const submitHandler = ({ newPassword, repeatPassword }) => {
    if (!newPassword) {
      setError((prev) => ({
        ...prev,
        newPassword: t('emailLogin.enterNewPassword'),
      }));
      return;
    } else if (!repeatPassword) {
      setError((prev) => ({
        ...prev,
        repeatPassword: t('emailLogin.repeatPassword'),
      }));
      return;
    }
    recoveryPasswordCompleteEffect(token, newPassword, repeatPassword)
      .then(() => {
        addNotification(t('emailLogin.newPasswordCreated'), 'success');
        history.push('/');
        setIsPasswordRecovery(false);
      })
      .catch(({ errors, message }) => {
        if (errors?.children) {
          setError({
            newPassword:
              errors?.children.plainPassword?.children?.first?.errors?.join(
                ', '
              ),
            repeatPassword:
              errors?.children.plainPassword?.children?.second?.errors?.join(
                ', '
              ),
          });
        } else {
          setError({
            newPassword: '',
            repeatPassword: message ? message : t('emailLogin.somethingWrong'),
          });
        }
      });
  };

  const onModalClose = () => {
    setIsPasswordRecovery(false);
    history.push('/');
  };

  return (
    <Modal
      isOpen={isPasswordRecovery}
      onRequestClose={onModalClose}
      contentLabel="Analytics"
      className={styles.modal}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick
    >
      <div
        className={classNames(
          styles.formWrapper,
          styles.passwordRecoveryWrapper
        )}
      >
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={submitHandler}
        >
          <Form>
            <div className={styles.fields}>
              <div className={styles.relative}>
                <InputFieldFormik
                  className={classNames(
                    styles.passwordInput,
                    styles.input,
                    error.newPassword && styles.inputError
                  )}
                  name="newPassword"
                  placeholder={t('common.createNewPassword')}
                  autoComplete="new-password"
                  type="password"
                  isPassword
                  data-test="sign-in-container_password_field"
                  onBlur={validateNewPassword}
                  tabindex="1"
                />
                <div
                  className={classNames(
                    styles.errorMessage,
                    styles.passwordError
                  )}
                >
                  {error.newPassword}
                </div>
              </div>
              <div className={styles.relative}>
                <InputFieldFormik
                  className={classNames(
                    styles.passwordInput,
                    styles.input,
                    error.repeatPassword && styles.inputError
                  )}
                  name="repeatPassword"
                  placeholder={t('common.repeatPassword')}
                  autoComplete="new-password"
                  type="password"
                  isPassword
                  data-test="sign-in-container_password_field"
                  onBlur={ValidateRepeatedPassword}
                  tabindex="2"
                />
                <div className={styles.errorMessage}>
                  {error.repeatPassword}
                </div>
              </div>
            </div>
            <button
              type="submit"
              className={styles.createPasswordBtn}
              data-test="sign-in-container_log_email_button"
              disabled={!!error.newPassword || !!error.repeatPassword}
            >
              {t('common.createPassword')}
            </button>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
};
