import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import Input from '../Input';
import { ReactComponent as LocationIcon } from './assets/location.svg';
import CityModal from '../CityModal';
import { enterKeyPress } from 'utils/actions/enter-key-press';

import styles from './styles.module.scss';

const ShippingForm = ({ type, size, setIsFormValid, setFormValues }) => {
  const { t } = useTranslation('auth');
  const [list, setList] = useState([]);
  const [startCitySelctor, setStartCitySelctor] = useState(false);
  const formik = useFormik({
    initialValues: {
      city: '',
      address: '',
      zip: '',
    },
  });

  useEffect(() => {
    setIsFormValid(
      Boolean(formik.values.city && formik.values.address && formik.values.zip)
    );
    setFormValues({
      city: formik.values.city,
      address: formik.values.address,
      zip: formik.values.zip,
    });
  }, [formik.values]);

  const onCitySelect = (city) => {
    formik.setFieldValue('city', city);
    getNextInputByName('city');
    setStartCitySelctor(false);
  };

  const getNextInputByName = (name) => {
    const form = document.getElementById('shippingForm');
    const inputs = form.getElementsByTagName('input');
    let currentIndex = 0;

    for (var i = 0; i < inputs.length; ++i) {
      if (inputs[i].name === name) currentIndex = i;
    }

    if (currentIndex >= inputs.length - 1) {
      inputs[currentIndex].blur();
    } else {
      inputs[currentIndex + 1].focus();
    }
  };

  const onCityKeyPress = (e) => {
    if (enterKeyPress(e)) {
      if (list.length) {
        const city = list[0];
        onCitySelect(city.city, city.land, city.sdiv, city.tz);
        setStartCitySelctor(false);
      } else {
        getNextInputByName('city');
      }
    } else {
      setStartCitySelctor(true);
    }
  };

  const fieldKyeDown = (e) => {
    if (enterKeyPress(e)) {
      getNextInputByName(e.target.name);
    }
  };

  return (
    <form id="shippingForm" className={styles.shippingForm}>
      <div className={styles.info}>
        <div className={styles.shippingInfo}>
          <p className={styles.shippingInfoText}>{t('ring.type')}:</p>
          <span className={styles.shippingInfoValue}>{type}</span>
        </div>
        <div className={styles.shippingInfo}>
          <p className={styles.shippingInfoText}>{t('ring.size')}:</p>
          <span className={styles.shippingInfoValue}>{size}</span>
        </div>
      </div>
      <div className={styles.form}>
        <Input
          icon={<LocationIcon />}
          name="city"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.city}
          placeholder={t('ring.city')}
          onKeyDown={onCityKeyPress}
        />
        <Input
          icon={<LocationIcon />}
          name="address"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.address}
          placeholder={t('ring.address')}
          onKeyDown={fieldKyeDown}
        />
        <Input
          icon={<LocationIcon />}
          name="zip"
          type="number"
          onChange={formik.handleChange}
          value={formik.values.zip}
          placeholder={t('ring.zip')}
          onKeyDown={fieldKyeDown}
        />
      </div>
      <CityModal
        onCitySelect={onCitySelect}
        city={formik.values.city}
        list={list}
        setList={setList}
        startCitySelctor={startCitySelctor}
      />
    </form>
  );
};

export default ShippingForm;
