import React from 'react';

const Link = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7353 13.582L18.5735 12.42C18.3427 12.1894 17.9689 12.1894 17.7381 12.42C17.5073 12.6508 17.5073 13.025 17.7381 13.2557L18.8998 14.4176C20.1272 15.6452 20.126 17.6569 18.8998 18.8997C17.6727 20.1268 15.6609 20.1258 14.4178 18.8997L13.2558 17.7379C13.0252 17.5071 12.651 17.5071 12.4202 17.7379C12.1894 17.9687 12.1894 18.3427 12.4202 18.5733L13.5849 19.738C14.4371 20.5788 15.5522 20.9995 16.6649 20.9995C17.78 20.9995 18.8931 20.577 19.7382 19.7323C21.4217 18.0258 21.4205 15.267 19.7353 13.582Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M15.5795 9.42615L14.4148 8.26122C12.7087 6.57796 9.94971 6.57932 8.26171 8.26694C6.57825 9.97326 6.57962 12.732 8.26466 14.417L9.42664 15.579C9.54186 15.6944 9.69309 15.7521 9.84436 15.7521C9.9954 15.7521 10.1467 15.6944 10.2621 15.5788C10.4929 15.3482 10.4929 14.974 10.2621 14.7434L9.1001 13.5814C7.87276 12.3541 7.87393 10.3423 9.1001 9.09959C10.3276 7.87243 12.3394 7.87364 13.5821 9.09959L14.7441 10.2616C14.9749 10.4924 15.3487 10.4924 15.5795 10.2616C15.8103 10.0309 15.8103 9.65676 15.5795 9.42615Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
    <path
      d="M15.9144 15.0792L12.9209 12.0857C12.6903 11.8549 12.3161 11.8549 12.0855 12.0857C11.8547 12.3163 11.8547 12.6905 12.0855 12.9212L15.079 15.9147C15.1944 16.0301 15.3454 16.0878 15.4967 16.0878C15.648 16.0878 15.799 16.0301 15.9144 15.9147C16.1452 15.684 16.1452 15.3098 15.9144 15.0792Z"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0.2"
    />
  </svg>
);

Link.defaultProps = {};

export default Link;
