//Refactoring №3
import React from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { handleNotEnoughTokenModal } from 'features/modals/modal-slice';

import { ReactComponent as CloseIcon } from 'static/assets/svg/close.svg';
import Button, { ButtonTheme } from 'components/Button';

import style from './style.module.scss';

export default function NotEnoughTokenModal() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('contextMenu');

  const goBuyTokens = () => {
    closeModal();
    history.push('/wallet/buy-credits');
  };

  const closeModal = () => {
    dispatch(handleNotEnoughTokenModal(false));
  };

  return (
    <Modal
      isOpen
      overlayClassName={style.overlay}
      className={style.modal}
      contentLabel="Example Modal"
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
    >
      <div className={style.wrapper}>
        <button
          onClick={closeModal}
          type="button"
          className={style.wrapper__exit}
        >
          <CloseIcon className={style.closeIcon} />
        </button>
        <div className={style.content}>
          <h2 className={style.title}>{t('main.imageToImage.lowBalance')}</h2>
          <p className={style.description}>
            {t('main.imageToImage.lowBalanceText')}
          </p>
          <div className={style.actions}>
            <Button
              className={style.cancelBtn}
              theme={ButtonTheme.TRANSPARENT}
              onClick={closeModal}
            >
              {t('main.createFolder.cancel')}
            </Button>
            <Button
              className={style.buyBtn}
              theme={ButtonTheme.DARK}
              onClick={goBuyTokens}
            >
              {t('main.imageToImage.buyToken')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
