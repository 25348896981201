export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
// export const isMobile =
//   'ontouchstart' in window || 'onmsgesturechange' in window

export const isAndroid =
  navigator.userAgent.toLowerCase()?.indexOf('android') > -1;

export const isIphone =
  navigator.userAgent.toLowerCase()?.indexOf('iphone') > -1;
