import { cancelingUpload } from 'gdgateway-client/lib/es5';

import { store } from 'store/root-store';
import { encryptExistingFile, filterFiles } from 'store/home/actions';
import { cancelFolderUploading } from 'store/home/actions/file/cancel-folder-uploading.action';

export const cancelAllOngoingUploading = (dispatch) => {
  const state = store.getState();
  const uploadingFiles = state.home?.uploadFiles?.entities;
  const needEncryptExistingFile = state?.home?.encrypt?.needEncryptExistingFile;

  needEncryptExistingFile && dispatch(encryptExistingFile(undefined));

  uploadingFiles.map((file) => {
    cancelingUpload(file.uploadId);
    if (file?.dataType === 'folder') {
      dispatch(cancelFolderUploading(true));
    }
    if (file.source) {
      file.source.cancel('User cancel upload');
    }
    if (file.controller) file.controller.abort();

    dispatch(filterFiles(file.uploadId));
  });
};

export const isUploadingInProgress = () => {
  const state = store.getState();
  const totalSize = state?.home?.uploadFiles?.totalSize;
  const totalProgress = state.home?.uploadFiles?.totalProgress;
  const uploadingFiles = state.home?.uploadFiles?.entities;

  const canceledUploads = uploadingFiles.reduce((progress, file) => {
    let newProgress;
    if (file?.source?.token?.reason || file?.error) {
      newProgress = file.size - file.progress;
    } else {
      newProgress = 0;
    }
    return progress + newProgress;
  }, 0);

  const newTotalProgress = totalProgress + canceledUploads;

  return newTotalProgress < totalSize;
};
