import React, { useContext, useEffect, useMemo, useState } from 'react';
import styles from './TriangleAction.module.scss';
import { DARK_THEME, ThemeContext } from 'store/context/theme';

interface TriangleAction {
  active: boolean;
  activateCb?: (status: boolean) => void;
  size?: string;
}

const TriangleAction = (props: TriangleAction) => {
  const themeContext = useContext(ThemeContext);
  const isDarkTheme = useMemo(() => themeContext?.theme === DARK_THEME, [themeContext]);

  /** Initial State is needed for not running animation at the initialization */
  const [isInitialState, setIsInitialState] = useState<boolean | null>(null);
  const trianglePoints = '50,11 1,90 99,90';
  const circleRadius = 1;
  const circleCenterX = 50;
  const circleCenterY = 60;
  const size = props.size || '24px';

  useEffect(() => {
    /** This condition helps isInitialState to work properly.
     * After mounting I need initialState to be true.
     * But if I write setIsInitialState(false); based on props.active,
     * the state is always will be true without the effect I need
     */
    if (isInitialState === null) {
      setIsInitialState(true);
    } else if (isInitialState === true) {
      setIsInitialState(false);
    }
  }, [props.active]);

  const toggleActive = () => {
    setIsInitialState(false);
    props.activateCb?.(!props.active);
  };

  const getButtonClass = () => {
    if (isInitialState) {
      return props.active
        ? `${styles.button} ${styles['active-no-animation']}`
        : `${styles.button}`;
    } else {
      return props.active
        ? `${styles.button} ${styles.active}`
        : `${styles.button} ${styles.inactive}`;
    }
  };

  return (
    <button className={getButtonClass()} onClick={toggleActive}>
      <svg width={size} height={size} viewBox="0 0 100 100">
        <defs>
          <clipPath id="triangleClip">
            <polygon points={trianglePoints} />
          </clipPath>
        </defs>

        <polygon
          points={trianglePoints}
          fill="none"
          stroke={isDarkTheme ? "white" : "black"}
          strokeWidth={4}
          className={styles.triangle}
        />

        <g clipPath="url(#triangleClip)">
          <circle
            cx={circleCenterX}
            cy={circleCenterY}
            r={circleRadius}
            fill={isDarkTheme ? "white" : "black"}
            clipPath="url(#triangleClip)"
            style={{
              transformOrigin: `${circleCenterX}px ${circleCenterY}px`,
            }}
            className={styles.circle}
          />
        </g>
      </svg>
    </button>
  );
};

export default TriangleAction;
