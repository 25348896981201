import React, { useMemo, useContext } from 'react';
import CN from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { ReactComponent as SettingsIcon } from 'components/NeyraBorder/assets/settings.svg';
import { ReactComponent as SunIcon } from 'components/NeyraBorder/assets/sun.svg';
import { ReactComponent as FlagIcon } from 'components/NeyraBorder/assets/flag.svg';
import { ReactComponent as MoonIcon } from 'components/NeyraBorder/assets/moon.svg';
import { ReactComponent as RecordIcon } from 'components/NeyraBorder/assets/record.svg';
import TextToVoiceSpeaker from 'components/TextToVoiceSpeaker';

import { ThemeContext, LIGHT_THEME, DARK_THEME } from 'store/context/theme';
import useIsRegistrationFlow from 'utils/hooks/use-is-registration-flow';
import { handleBugReportModal } from 'features/modals/modal-slice';
import { selectTokenCountState } from 'features/app';
import getToken from 'utils/auth/get-token';
import { selectIsPromptRecording, setIsPromptRecording } from 'features/studio';
import NeyraTooltip from 'components/shared/NeyraTooltip/NeyraTooltip';
import { formatNumberToTokens } from 'utils/string';

import styles from './styles.module.scss';

const SidebarFooter = ({ isCollapsed, isStudio = false, className }) => {
  const dispatch = useDispatch();
  const isAuth = !!getToken();
  const history = useHistory();
  const { theme, setTheme } = useContext(ThemeContext);
  const light = theme === LIGHT_THEME;
  const tokenCountRedux = useSelector(selectTokenCountState);
  const isPromptRecording = useSelector(selectIsPromptRecording);
  const isRegistrationFlow = useIsRegistrationFlow();
  const isZeroCredit = useMemo(
    () => isAuth && !+tokenCountRedux,
    [tokenCountRedux, isAuth]
  );

  const handleBugReport = () => {
    dispatch(handleBugReportModal(true));
  };

  const actionOptions = useMemo(() => {
    const options = isStudio
      ? [
          {
            name: 'record',
            icon: <RecordIcon />,
            action: () => {
              dispatch(setIsPromptRecording(!isPromptRecording));
            },
            tooltip: 'Record',
          },
          {
            name: 'speaker',
            icon: <TextToVoiceSpeaker />,
            action: () => {},
            isComponent: true,
            tooltip: 'Speaker',

          },
        ]
      : [
          {
            name: 'speaker',
            icon: <TextToVoiceSpeaker />,
            action: () => {},
            isComponent: true,
            tooltip: 'Speaker',
          },
          {
            name: 'settings',
            icon: <SettingsIcon />,
            action: () => {
              isAuth && history.push('/account/settings');
            },
            tooltip: 'Account Settings',
          },
          {
            name: 'bugReport',
            icon: <FlagIcon />,
            action: handleBugReport,
            tooltip: 'Bug Report',
          },
        ];
    if (isAuth)
      options.push({
        name: 'theme',
        icon: light ? <MoonIcon /> : <SunIcon />,
        action: (e) => {
          changeTheme(e);
        },
        tooltip: 'Theme',
      });
    return options;
  }, [isAuth, light, isStudio, isPromptRecording]);

  const changeTheme = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const nextTheme = theme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME;
    setTheme(nextTheme);
  };

  const onTokensReirect = () => {
    isAuth ? history.push('/wallet/buy-credits') : history.push('/token');
  };

  const activeButton = useMemo(() => {
    const currentRoute = location.pathname;
    return currentRoute === '/token' || currentRoute === '/wallet/buy-credits';
  }, [location.pathname]);

  return (
    <div
      className={CN(
        styles.sidebarFooter,
        isCollapsed && styles.sidebarFooterCollapsed,
        className
      )}
    >
      {actionOptions.map(
        ({ name, icon, action, className = '', isComponent = false, tooltip }) =>
          isComponent ? (
            <NeyraTooltip
              text={tooltip}
              position='top'
              id={name}
              key={name}
            >
             <div>{icon}</div>
            </NeyraTooltip>
          ) : (
            <NeyraTooltip
              text={tooltip}
              position='top'
              key={name}
            >
              <button
                disabled={isRegistrationFlow}
                className={CN(styles.footerActionsBtn, className)}
                key={name}
                onClick={action}
              >
                {icon}
              </button>
            </NeyraTooltip>
          )
      )}
      <NeyraTooltip text='Buy Credits' position='top' >
        <button
          disabled={isRegistrationFlow}
          onClick={onTokensReirect}
          className={CN(
            styles.footerActionsTokens,
            activeButton && !isRegistrationFlow && styles.activeButtonToken,
            isZeroCredit && styles.warningButtonToken
          )}
        >
          <span className={styles.footerActionsTokensСircle} />
          <span className={styles.footerActionsTokensText}>
            {formatNumberToTokens(
              Math.round(parseFloat(tokenCountRedux)) || 0,
              0
            )}
          </span>
        </button>
      </NeyraTooltip>

    </div>
  );
};

export default SidebarFooter;
