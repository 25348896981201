//Refactoring №3
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { authToken } from 'store/auth/effects';

import Preloader from 'components/shared/Preloader';

import s from './style.module.scss';

const AuthToken = ({ match, history }) => {
  const {
    params: { token },
  } = match;
  const [error, setError] = useState(false);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (token) {
      authToken(token)
        .then(() => {
          history.replace('/main');
        })
        .catch(() => {
          setError(true);
        });
    }
  }, [history, token]);

  if (error) {
    return (
      <section id="preloader" className={s.preloader}>
        <h1>{t('common.shortError')}</h1>
        <p></p>
      </section>
    );
  }

  return <Preloader />;
};

export default AuthToken;
