import { createSlice } from '@reduxjs/toolkit';
import { createStudioFolderEffect } from 'store/aiGenerator/effects';

const folder_names = {
  draw: 'My draws',
  skybox: 'My panoramas',
  generate: 'My generation',
};

const initialState = {
  isGenerationInProgress: false,
  isImageUploading: false,
  isGenerationCompleted: false,
  generationPrice: 0,
  isPromptRecording: false,
  tabs: {
    isGenerateTab: true,
    isDrawTab: false,
    is3DTab: false,
  },
  folders: {
    drawFolder: null,
    generationFolder: null,
    panoramaFolder: null,
  },
};

const slice = createSlice({
  name: 'studio',
  initialState,
  reducers: {
    setIsImageUploading(state, { payload }) {
      state.isImageUploading = payload;
    },
    setIsGenerationInProgress(state, { payload }) {
      state.isGenerationInProgress = payload;
    },
    setIsGenerationCompleted(state, { payload }) {
      state.isGenerationCompleted = payload;
    },
    setGenerationPrice(state, { payload }) {
      state.generationPrice = payload;
    },
    setIsPromptRecording(state, { payload }) {
      state.isPromptRecording = payload;
    },
    setCurrentTab(state, { payload }) {
      state.tabs.isGenerateTab = false;
      state.tabs.isDrawTab = false;
      state.tabs.is3DTab = false;
      switch (payload) {
        case 'generate':
          state.tabs.isGenerateTab = true;
          break;
        case 'draw':
          state.tabs.isDrawTab = true;
          break;
        case '3D':
          state.tabs.is3DTab = true;
          break;
        default:
          break;
      }
    },
    cleanState() {
      return { ...initialState };
    },
    getStudioFolders(state, { payload }) {
      const folders = payload
        .filter((el) => el?.type === 2)
        .reduce((acc, el) => {
          if (el?.name === folder_names.generate) {
            return { ...acc, generationFolder: el?.slug };
          } else if (el?.name === folder_names.draw) {
            return { ...acc, drawFolder: el?.slug };
          } else if (el?.name === folder_names.skybox) {
            return { ...acc, panoramaFolder: el?.slug };
          } else {
            return acc;
          }
        }, {});

      state.folders = { ...state.folders, ...folders };
    },
  },
});

const { reducer, actions } = slice;

export const {
  setIsImageUploading,
  setIsGenerationInProgress,
  setIsGenerationCompleted,
  setGenerationPrice,
  setCurrentTab,
  cleanState,
  setIsPromptRecording,
  getStudioFolders,
} = actions;

export const createStudioFolder = (folderType) => async (dispatch) => {
  const foldername = folder_names[folderType];
  const newfolder = await createStudioFolderEffect(foldername);
  dispatch(getStudioFolders([newfolder]));
  return newfolder?.slug;
};

export default reducer;

export const selectStudioTabs = (state) => state.studio.tabs;
export const selectStudioFolders = (state) => state.studio.folders;
export const selectIsGenerationInProgress = (state) =>
  state.studio.isGenerationInProgress;
export const selectIsImageUploading = (state) => state.studio.isImageUploading;
export const selectGenerationPrice = (state) => state.studio.generationPrice;
export const selectIsPromptRecording = (state) =>
  state.studio.isPromptRecording;
export const selectIsGenerationCompleted = (state) =>
  state.studio.isGenerationCompleted;
