// libs
import * as R from 'ramda'

// utils
import getData from 'utils/request/get-data'

// store
import * as actions from '../actions'
import { CONTRACT_MULTYSIG_TYPE } from 'config/multisig'

export const DEFAULT_STORAGE_SIZE = 2684354560

const initialState = {
  plan: {},
  currentWs: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.WORKSPACE_PLAN_ADD:
      return {
        ...state,
        plan: getData(action),
      }
    case actions.WORKSPACE_PLAN_CLEAR:
      return R.merge(state, {
        plan: {},
        currentWs: {},
      })
    case actions.WORKSPACE_ADD_CURRENT:
      return {
        ...state,
        currentWs: getData(action),
      }
    default:
      return state
  }
}

export const selectWorkspacePlan = (state) =>  state?.plan?.workspacePlan?.plan
export const selectWorkspaceCurrent = (state) =>  state?.plan?.workspacePlan?.currentWs
export const selectMultisigContract = (state) =>  state?.plan?.workspacePlan?.currentWs?.smart_contracts?.find((contract) => contract.type === CONTRACT_MULTYSIG_TYPE)