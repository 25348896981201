//Refactoring №3
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import { ethers } from 'ethers';
import Web3 from 'web3';

import { signUpMetamaskEffect, loginMetamask } from 'store/auth/effects/index';
import { isMobile } from 'utils/mobile';
import { checkMetamaskInstalled } from 'utils/crypto/wallet/metamask';
import { injected } from 'utils/crypto/wallet/connectors';
import { getSearchParamByLocation } from 'utils/url/get-search-param-by-location';
import {
  getUserByReferral,
  getNFTMeta,
} from 'store/plan/effects/subscription-effects';

import Preloader from 'components/shared/Preloader';
import NetworkNotSupported from 'components/Web3Checker/components/NetworkNotSupported';
import CryptoCheckPluginModal from '../crypto-check-plugin-modal';
import { signMessage } from '../login-crypto';

const serverChainId = process.env.TESTNET ? 97 : 56;

export const getAddress = async (setErrors) => {
  try {
    const { providers } = window.ethereum;
    let selectedProvider;

    for (const provider of providers || []) {
      if (provider.isMetaMask) {
        selectedProvider = provider;
      }
    }
    if (selectedProvider) {
      window.ethereum.setSelectedProvider &&
        window.ethereum.setSelectedProvider(selectedProvider);
    }
    await window.metamask.send('eth_requestAccounts');
    const provider = new ethers.providers.Web3Provider(window.metamask);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    return {
      address,
    };
  } catch (err) {
    window.location.replace('https://www.ghostdrive.io/nft?reject=connection');
    setErrors && setErrors(err.message, err?.code);
    throw err;
  }
};

const TOKEN_ID = 1;

function SignUpMetamaskContainer({ history }) {
  const { t } = useTranslation('auth');
  const dispatch = useDispatch();
  const referral = getSearchParamByLocation(location, 'referral');
  const [referer, setReferer] = useState(
    '0x0000000000000000000000000000000000000000'
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [check, setCheck] = useState(false);
  const [account, setAccount] = useState(false);
  const [isIncorrectNetwork, setIsIncorrectNetwork] = useState(false);

  useEffect(() => {
    referral &&
      getUserByReferral(referral).then((data) => {
        setReferer(data.wallet);
      });
  }, [referral]);

  const closeIncorrectNetworkModal = () => {
    setIsIncorrectNetwork(false);
    handleSend(account, serverChainId);
  };

  const login = async () => {
    const { signature, address } = await signMessage();
    loginMetamask(address, signature, history, dispatch).catch(() => {
      const chainId = window.metamask.networkVersion;
      signUpMetamaskEffect(account, referral, chainId).then(() => {
        loginMetamask(address, signature, history, dispatch);
      });
    });
  };

  useEffect(() => {
    if (account && check) {
      login();
    }
  }, [check, account]);

  useEffect(() => {
    if (!window.metamask) setModalOpen(true);
    connect();
  }, []);

  const startPayment = async ({ from, chainId }) => {
    try {
      if (!window.metamask)
        throw new Error('No crypto wallet found. Please install it.');
      const TOKEN_NAME = ethers.utils.id('NFT_BLUE');
      const {
        data: { abi, address },
      } = await getNFTMeta(chainId || window.metamask.networkVersion);

      const provider = Web3.givenProvider;
      const web3 = new Web3(provider);
      const contractInstance = new web3.eth.Contract(abi, address, {
        gas: 500000,
      });
      const price = await contractInstance.methods
        .getPrice(TOKEN_NAME, TOKEN_ID)
        .call();

      const tx = await contractInstance.methods
        .buy(TOKEN_NAME, TOKEN_ID, referer, TOKEN_NAME, 1)
        .send({ from, value: price })
        .on('error', function (error) {
          if (error.code === 4001)
            window.location.replace(
              'https://www.ghostdrive.io/nft?reject=payment'
            );
        });

      // Проверка оплаты напрямую

      if (tx) {
        axios({
          baseURL: `${process.env.REACT_APP_API_PATH}/nft/check`,
          method: 'POST',
          data: { address: from, tokenId: TOKEN_ID },
        }).then((res) => {
          const chainId = window.metamask.networkVersion;
          signUpMetamaskEffect(from, referral, chainId).then(() =>
            setCheck(res?.data?.valid ?? false)
          );
        });
      }
    } catch (err) {
      setIsIncorrectNetwork(true);

      console.warn(err);
    }
  };

  const handleSend = async (account, chainId) => {
    // Проверкка покупки
    const res = await axios({
      baseURL: `${process.env.REACT_APP_API_PATH}/nft/check`,
      method: 'POST',
      data: { address: account, tokenId: TOKEN_ID },
    });
    setCheck(res?.data?.valid ?? false);
    // Покупка
    if (!res.data.valid) {
      await startPayment({
        from: account,
        chainId: chainId,
      });
    }
  };

  const connect = async () => {
    if (isMobile && !checkMetamaskInstalled()) {
      const dappUrl = `${window?.location?.host}/sign-in`;
      const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
      return window.open(metamaskAppDeepLink, '_self');
    }
    if (!checkMetamaskInstalled()) {
      setModalOpen(true);
      return;
    }
    const { address } = await getAddress(injected);
    setAccount(address);
    handleSend(address);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <CryptoCheckPluginModal
        onClose={closeModal}
        isOpen={modalOpen}
        type="metamask"
      />
      <NetworkNotSupported
        chainId={window.metamask.networkVersion}
        isOpen={isIncorrectNetwork}
        onClose={closeIncorrectNetworkModal}
        isNetworkCorrectLocal={({ neededChainId }) => ({
          isCorrect: neededChainId == serverChainId,
          networkList: [
            process.env.TESTNET
              ? {
                  id: 97,
                  name: 'Binance Smart Chain Testnet',
                  chain_id: 97,
                  symbol: 'tBNB',
                  rpc_url: 'https://data-seed-prebsc-1-s3.binance.org:8545',
                  file_tokenization_factory:
                    '0xe864ca515C26e569B6fa65F8dbC59551e8dc7d2f',
                  note_tokenization_factory:
                    '0x4Eb033577aFb604Ff4c8A574B1d69F51b3b3a99d',
                  workspace_access_factory:
                    '0x662Ec82b7aFed8be17C565B754845c24EccE6C43',
                  nft_subscription_v1:
                    '0xfE6a7d5Ff89974663773847EaA2Cf334748Beb4B',
                  nft_astro: '0xFCd16BB16698e27eB33d26E217b1CD1FDB8377c0',
                  nft_blue: '0x19301f93B75144a1073e9511B136455b6e96Cadd',
                  shop_simple: '0xEcAF35A8Ca843C224434f8FbA817CA9e49dB178C',
                  nft_metadata_url_file:
                    'http://us-central-1.telnyxstorage.com/',
                  explorer_url: 'https://testnet.bscscan.com/',
                  smart_contracts: [],
                }
              : {
                  id: 56,
                  name: 'Binance Smart Chain',
                  chain_id: 56,
                  symbol: 'BNB',
                  rpc_url: 'https://bsc-dataseed.binance.org/',
                  file_tokenization_factory:
                    '0xe864ca515C26e569B6fa65F8dbC59551e8dc7d2f',
                  note_tokenization_factory:
                    '0x4Eb033577aFb604Ff4c8A574B1d69F51b3b3a99d',
                  workspace_access_factory:
                    '0x662Ec82b7aFed8be17C565B754845c24EccE6C43',
                  nft_subscription_v1:
                    '0xfE6a7d5Ff89974663773847EaA2Cf334748Beb4B',
                  nft_astro: '0xFCd16BB16698e27eB33d26E217b1CD1FDB8377c0',
                  nft_blue: '0x19301f93B75144a1073e9511B136455b6e96Cadd',
                  shop_simple: '0xEcAF35A8Ca843C224434f8FbA817CA9e49dB178C',
                  nft_metadata_url_file:
                    'http://us-central-1.telnyxstorage.com/',
                  explorer_url: 'https://bscscan.com',
                  smart_contracts: [],
                },
          ],
        })}
      />
      {!isIncorrectNetwork && <Preloader text={t('signup.pleaseWait')} />}
    </>
  );
}

export const SignUpMetamask = React.memo(SignUpMetamaskContainer);
