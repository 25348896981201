//Refactoring №5
import React, { useState, useEffect, useRef, useCallback } from 'react';
import CN from 'classnames'

import { ReactComponent as KeyIcon } from 'components/VaultModal/assets/key.svg'

import { validationRegExp } from 'utils/validation/regexp';
import { doesFileExist } from 'utils/request/does-file-exist';

import styles from './styles.module.scss'

type IProps = {
  passwordName: string,
  placeholder: string,
  onChange: (name: string) => void,
  iconValue: string,
}

const VaultTitle = ({
  passwordName,
  placeholder,
  onChange,
  iconValue,
}: IProps) => {
  const [iconUrl, setIconUrl] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (iconValue && validationRegExp.isLink.test(iconValue)) {
      doesFileExist(iconValue)
      .then((url) => {
        setIconUrl(url);
      })
      .catch(() => {
        setIconUrl('')
      });
    } else {
      setIconUrl('')
    }
  }, [iconValue]);

  
  useEffect(() => {
    inputRef?.current?.focus()
  }, [inputRef])

  const handleChange = useCallback(({ target }: React.ChangeEvent<HTMLInputElement>) => {
    onChange(target.value)
  }, [])
  
  return (
    <div className={styles.wrapper}>
      {iconUrl ?
        (<div className={styles.logo}><img className={styles.logoImage} src={iconUrl} alt="logo" /></div>) :
        (<div className={styles.defaultLogo}><KeyIcon /></div>)
      }
      <div className={CN(styles.inputWrapper)}>
        <input
          ref={inputRef}
          value={passwordName}
          onChange={handleChange}
          type="text"
          placeholder={placeholder}
        />
      </div>
    </div>
  )
};

export default VaultTitle;