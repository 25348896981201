import { API_GET_FILE, API_FILE, API_COMMENTS } from 'constants/api-urls';
import authRequest from 'utils/request/auth-request';

export const commentAddEffect = async (
  itemId,
  comment,
  parentId,
  securities
) => {
  const url = `${API_GET_FILE}/${itemId}/comment`;
  const params = {};

  securities &&
    securities.forEach((security) => {
      params[security['keyword']] = security.value;
    });

  return authRequest
    .post(
      url,
      {
        text: comment,
        parent: parentId,
      },
      {
        params,
      }
    )
    .then((response) => {
      const {
        data: { data },
      } = response;
      if (Array.isArray(data)) {
        return data;
      } else if (typeof data === 'object' && !data.id) {
        return Object.keys(data).map((key) => data[key]);
      }
    })
    .catch((response) => {
      throw response.message;
    });
};

export const commentGetEffect = async (itemId, securities, cancelToken) => {
  const url = `${API_FILE}/${itemId}/comments`;
  const params = {};

  securities &&
    securities.forEach((security) => {
      params[security['keyword']] = security.value;
    });

  return authRequest
    .get(url, {
      params,
      cancelToken,
    })
    .then((response) => {
      const { data } = response;
      if (Array.isArray(data)) {
        return data;
      } else if (typeof data === 'object') {
        return Object.keys(data).map((key) => data[key]);
      }
    })
    .catch((response) => {
      throw response.message;
    });
};

export const loadAllComments = async (page = 1, cancelToken) =>
  authRequest
    .get(`${API_COMMENTS}?page=${page}`, {
      cancelToken,
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((response) => {
      throw response.message;
    });
