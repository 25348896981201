/* eslint-disable */
import { useState, useEffect, useRef } from 'react';
import { useWeb3React } from '@web3-react/core';
import { useConnectWallet } from '@web3-onboard/react';
import { utils } from 'ethers';
import { useReactive, useUpdateEffect } from 'ahooks';
import { useDispatch } from 'react-redux';
import SIGN_IN_ERROR_MESSAGES from 'config/errors/sign-in';
import { showMetamaskMobileOption } from 'utils/showMetamaskMobileOption';
import { redirectToDapp } from 'containers/auth/redirectToDapp';
import useNotification from 'utils/hooks/use-notification';
import { signMessage } from 'containers/auth/login-crypto';
import { isElectron } from 'utils/isElectron';
import { isMobile } from 'utils/mobile';
import { loginUnstoppableEffect } from 'store/auth/effects';
import { userWorkspaces, switchWorkspace } from 'store/workspace/effects';

import { profileLogin } from 'features/profile/profileSlice';
import { NEYRA_AI_API } from 'constants/api-urls';
import { getSearchParamByLocation } from 'utils/url/get-search-param-by-location';
import { loginWeb3 } from 'web3-auth/lib/es5';

const errorMessages = {
  unregistered: 'Public address not found',
};
const useWalletHandler = (history, routeType, callback) => {
  const [isConnecting, setIsConnecting] = useState(false);
  const [currentAccount, setCurrentAccount] = useState('');
  const accountsChanged = useRef(false);
  const [provider, setProvider] = useState(null);
  const [label, setLabel] = useState('');
  const dispatch = useDispatch();
  const state = useReactive({
    checked: '',
    modalOpen: false,
    signature: '',
    metamaskError: '',
    signInError: false,
  });

  const locationArray = history.location.pathname.split('/') || [];
  const searchParams = new URLSearchParams(history.location.search);
  const isoauth = JSON.parse(searchParams.get('isoauth'));
  const { addNotification } = useNotification();
  const redirectUrl = !isoauth ? routeType : `/oauth${history.location.search}`;
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const { account } = useWeb3React();

  useEffect(() => {
    const shadow = document.getElementsByTagName('onboard-v2')[0].shadowRoot;
    if (connecting) {
      const svelteElement = shadow?.querySelector('.svelte-b3j15j');
      if (svelteElement) {
        svelteElement.style.border = '1px solid #5e5e5e';
        svelteElement.style.borderRadius = '20px';
      }
      const noticeContainer = shadow?.querySelector('.notice-container');
      if (noticeContainer) {
        noticeContainer.style.gridColumn = 'span 1';
      }
      if (isMobile) {
        const container = shadow?.querySelector('.modal-container-mobile');
        container.style.bottom = 'auto';
        noticeContainer.style.display = 'none';
        container.querySelector('.content').style.width = 'calc(100vw - 40px)';
        container.querySelector('.wallets-container').style.justifyContent =
          'space-around';
        redirectToDapp(shadow);
      }
    }
  }, [connecting]);

  useEffect(() => {
    if (
      isConnecting &&
      (currentAccount || window.metamask.selectedAddress) &&
      !accountsChanged.current
    ) {
      connectMetamaskViaElectron();
    }
  }, [isConnecting, currentAccount]);

  const setSignatureError = (error) => {
    if (errorMessages.unregistered === error) state.signInError = true;
  };

  const connectMetamaskViaElectron = async () => {
    const provider = window.metamask;
    setLabel('MetaMask');
    setProvider(provider);
    try {
      const sig = await signMessage(setSignatureError, provider);
      if (sig) {
        state.signature = sig.signature;
      }
      localStorage.setItem('PROVIDER', 'metamask');
      setIsConnecting(false);
    } catch (error) {
      accountsChanged.current = false;
      setIsConnecting(false);
    }
  };

  useUpdateEffect(() => {
    if (
      label === 'MetaMask' ||
      label === 'Coinbase Wallet' ||
      label === 'WalletConnect'
    ) {
      loginWeb3({
        publicAddress: utils.getAddress(currentAccount),
        GHOST_DRIVE_API: `${process.env.REACT_APP_API_PATH}`,
        NEYRA_AI_API,
        autoRedirect: false,
        provider,
      })
        .then(({ isNewUser }) => {
          dispatch(profileLogin());
          setTimeout(() => {
            callback && callback();
            userWorkspaces().then((data) => {
              data.map(({ workspace }) => {
                if (workspace.current)
                  if (
                    !workspace.workspace_securities ||
                    workspace.workspace_securities.length == 0 ||
                    !workspace.is_workspace_owner
                  ) {
                    const fallbackUrl =
                      localStorage.getItem('fallbackUrl') || '';
                    const wsId = parseInt(
                      getSearchParamByLocation(
                        new URL(window.location.origin + fallbackUrl),
                        'workspaceId'
                      )
                    );
                    if (wsId && workspace.id !== wsId) {
                      switchWorkspace(wsId).then(() => {
                        history &&
                          history.push(isNewUser ? '/welcome' : '/chat');
                      });
                    } else {
                      history &&
                        history.push(isNewUser ? '/welcome' : redirectUrl);
                    }
                  } else {
                    dispatch(
                      workspaceSecurityCheck({
                        data: [...workspace.workspace_securities],
                        nextWorkspace: workspace,
                      })
                    );
                  }
              });
            });
          }, 4000);
        })
        .catch((error) => {
          if (error.message === SIGN_IN_ERROR_MESSAGES.NO_WORKSPACE) {
            history.push({
              pathname: '/no-workspace',
              state: { account },
            });
          } else {
            addNotification(error.message, 'error');
          }
        });
    }
    if (state.signature && label === 'Unstoppable') {
      loginUnstoppableEffect(history, redirectUrl);
    } else if (label === 'Unstoppable') {
      const sig = signMessage(setSignatureError, provider).then(
        (res) => res.signature
      );
      if (sig) {
        state.signature = sig;
      }
    }
  }, [currentAccount, state.signature]);

  const handleClick = (clickType) => {
    switch (clickType) {
      case 'wallet':
        if (isElectron) {
          window.metamask
            .request({
              method: 'eth_requestAccounts',
              params: [],
            })
            .catch((error) => {
              console.warn('error', error);
              setIsConnecting(false);
            });

          showMetamaskMobileOption();
          setIsConnecting(true);
        } else {
          if (wallet) {
            disconnect(wallet);
          } else {
            connect().then(async (res) => {
              if (res.length) {
                const provider = res[0].provider;
                setLabel(res[0].label);
                setProvider(provider);
                setCurrentAccount(res[0].accounts[0].address);
              }
            });
          }
        }
        break;
      default:
        break;
    }
  };

  return { isConnecting, currentAccount, handleClick };
};

export default useWalletHandler;
