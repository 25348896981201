//Refactoring №3
import React, { useMemo } from 'react';
import { DragLayer } from 'react-dnd';

import dndTypes from 'config/dnd-types';

import DragLayerComponent from './drag-layer';

let dragLayerRef = null;

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 10000,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

const dragLayerCollector = (monitor) => {
  if (dragLayerRef) {
    const offset = monitor.getClientOffset();

    if (offset) {
      dragLayerRef.style[
        'transform'
      ] = `translate(${offset.x}px, ${offset.y}px)`;
    } else {
      dragLayerRef.style['display'] = `none`;
    }
  }

  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
  };
};

const CustomDragLayer = (props) => {
  const { itemType, isDragging, item, selectedSlugs } = props;
  const count = useMemo(
    () =>
      !selectedSlugs?.includes(item?.file?.slug) ? 0 : selectedSlugs.length,
    [isDragging]
  );

  if (!isDragging) {
    return null;
  }

  function renderItem() {
    switch (itemType) {
      case dndTypes?.FILE:
        if (item?.file) {
          return <DragLayerComponent item={item?.file} count={count} />;
        }
        return null;
      default:
        return null;
    }
  }

  return (
    <div style={layerStyles}>
      <div ref={(ref) => (dragLayerRef = ref)}>{renderItem()}</div>
    </div>
  );
};

export default DragLayer(dragLayerCollector)(CustomDragLayer);
