import React from 'react';

const Restore = ({ color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip-restore-icon-0)">
      <path
        d="M13.2688 13.6595C10.1481 16.7802 5.07048 16.7802 1.9498 13.6595C1.74151 13.4512 1.74151 13.1135 1.9498 12.9052C2.15805 12.697 2.49576 12.697 2.70405 12.9052C5.4088 15.61 9.80983 15.61 12.5145 12.9052C15.2193 10.2005 15.2193 5.79952 12.5145 3.09474C9.93661 0.516829 5.8178 0.395954 3.09539 2.73208L3.51358 3.15027C3.8038 3.44042 3.67017 3.93658 3.27448 4.04255L1.06255 4.63523C0.666046 4.74136 0.303296 4.3777 0.409327 3.98202L1.00201 1.77008C1.1088 1.37155 1.60589 1.24249 1.8943 1.53098L2.33964 1.97633C5.48461 -0.778641 10.2785 -0.649828 13.2688 2.34045C16.3895 5.46108 16.3895 10.5388 13.2688 13.6595ZM1.7933 2.93845L1.67876 3.36577L2.10611 3.25127L1.7933 2.93845Z"
        fill={color}
      />
      <path
        d="M9.64965 7.84027H7.90615V4.86968C7.90615 4.57509 7.6674 4.3363 7.37281 4.3363C7.07824 4.3363 6.83946 4.57512 6.83946 4.86965V8.37362C6.83946 8.66818 7.07827 8.90696 7.37281 8.90696H9.64965C9.94421 8.90696 10.183 8.66815 10.183 8.37362C10.183 8.07909 9.94418 7.84027 9.64965 7.84027Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip-restore-icon-0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Restore.defaultProps = {
  color: 'currentColor',
};

export default Restore;
