//Refactoring №5
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ReactComponent as KeyIcon } from 'components/VaultModal/assets/key.svg'
import { selectProfileLogin } from 'features/profile/profileSlice';
import { selectUserInfo } from 'store/home/reducers/get-user-info.reducer';
import { trimLongText } from 'utils';

import styles from './styles.module.scss'

type IProps = {
  toggleView: () => void,
  logo: string,
  name: string,
  ownerId?: number,
}

const PreviewHeader = ({ toggleView, logo, name, ownerId }: IProps) => {
  const isLogin = useSelector(selectProfileLogin);
  const myProfile = useSelector(selectUserInfo);
  const isMyFile = useMemo(() => (ownerId === myProfile?.id), [ownerId, myProfile])

  return (
    <div className={styles.header}>
      { logo ? (
        <div className={styles.logoWrapper}>
          <img className={styles.logo} src={logo} alt="Girl in a jacket" /> 
        </div>) : 
          (<div className={styles.defaultLogo}><KeyIcon /></div>)
      }
      {name && <h2 className={styles.name}>{ trimLongText(name, 34) }</h2>}
      {isLogin && isMyFile && (
        <button onClick={toggleView} className={styles.editBtn}>
          Edit
        </button>
      )}
    </div>
  )
};

export default PreviewHeader;