//Refactoring №3
import React, { forwardRef, ReactNode } from 'react';

import cn from 'classnames';

import s from './style.module.scss';

interface Props {
  children?: ReactNode;
  text?: string | ReactNode;
  className?: string;
}
export type Ref = HTMLButtonElement;

// eslint-disable-next-line react/display-name
const Tooltip = forwardRef<Ref, Props>(({ text, className }, ref) => {
  return (
    <div
      ref={ref as React.RefObject<HTMLDivElement>}
      className={cn(s.tooltip, className)}
    >
      {text}
    </div>
  );
});

export default Tooltip;
