/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { React, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import UserContext from 'store/home/contexts/user-context';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import updatePrintEffect from 'store/home/effects/file/update-print.effect';
import {
  selectedEntityAdd,
  selectedEntityClear,
} from 'store/home/actions/selected-entity.actions';

import { ReactComponent as ShieldIcon } from '../assets/shield.svg';
import { ReactComponent as LockIcon } from '../assets/lock.svg';
import { ReactComponent as PrintIcon } from '../assets/print.svg';
import { ReactComponent as DownloadIcon } from '../assets/download.svg';

import actionsOptions from 'config/actions-options';
import securityOptions from 'config/security-options';

import Switch from 'components/Switch';
import TooltipComponent from 'components/Tooltip';
import HashWidget from '../MetaContainer/HashWidget';
import LocationWidget from '../Location';

import styles from './styles.module.scss';

const SettingsTab = ({ selectedEntity }) => {
  const { t } = useTranslation('owner');
  const { t: accountT } = useTranslation('account');
  const [remixState, setRemixState] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const { tfa, password } = securityOptions;

  const successCallback = (securityId) => {
    const nextEntity = {
      ...selectedEntity,
      securities: selectedEntity.securities.filter((item) => {
        item.type !== securityId;
      }),
    };
    dispatch(selectedEntityAdd({ entity: nextEntity }));
  };

  const toggleSecurityStatus = (securityId, entity, isSecurityActive) => {
    const { toggleSecurity } = actionsOptions;

    const additionalData = {
      securityId,
      entity,
      isSecurityActive,
      successCallback: () => {
        successCallback(securityId, isSecurityActive);
      },
    };

    dispatch(
      fileFolderActionHandlerEffect(entity, toggleSecurity, additionalData)
    );
  };

  const isActivatedInUser = (option) => {
    return !!user[option.nameValue];
  };

  const getIsSecurityActivated = (option) => {
    return (
      selectedEntity.securities.find(
        (security) => security.keyword === option.name.toLowerCase()
      ) && isActivatedInUser(option)
    );
  };

  const onToggleClickHandler = (securityOption) => {
    if (!isActivatedInUser(securityOption)) return;
    toggleSecurityStatus(
      securityOption.type,
      selectedEntity,
      getIsSecurityActivated(securityOption)
    );
  };

  const togglePrintMode = () => {
    updatePrintEffect(selectedEntity.slug, {
      is_printed: !selectedEntity.is_printed,
    }).then(() => {
      dispatch(
        selectedEntityAdd({
          entity: { ...selectedEntity, is_printed: !selectedEntity.is_printed },
        })
      );
    });
  };

  const secureOptions = [
    {
      type: tfa,
      icon: <ShieldIcon />,
      value: getIsSecurityActivated(tfa),
      onChange: onToggleClickHandler,
      useTooltio: !isActivatedInUser(tfa),
    },
    {
      type: password,
      icon: <LockIcon />,
      value: getIsSecurityActivated(password),
      onChange: onToggleClickHandler,
      useTooltio: !isActivatedInUser(password),
    },
  ];

  const propertiesOptions = [
    {
      type: { name: 'allowPrint' },
      icon: <PrintIcon />,
      value: selectedEntity.is_printed,
      onChange: togglePrintMode,
      useTooltio: false,
    },
    {
      type: { name: 'allowRemix' },
      icon: <DownloadIcon />,
      value: remixState,
      onChange: () => {
        setRemixState(!remixState);
      },
      useTooltio: false,
    },
  ];

  const cleanSelectedEntity = () => {
    dispatch(selectedEntityClear());
    history.push('/account/security-layers');
  };

  const renderOption = (list) => {
    return (
      <div className={styles.list}>
        {list.map(({ icon, type, value, onChange, useTooltio }) => {
          return (
            <div className={styles.switchItem} key={type.name}>
              <div className={styles.switchLeft}>
                {icon}
                <span className={styles.switchName}>
                  {t(`rightFileMenu.security.${type.name}`)}
                </span>
              </div>
              <TooltipComponent
                visible={useTooltio ? undefined : false}
                trigger={['hover']}
                overlay={
                  <p className={styles.tooltipContent}>
                    {t(`rightFileMenu.security.notActivated`)}
                    <br />
                    <span onClick={cleanSelectedEntity}>
                      {accountT('account')}
                    </span>
                  </p>
                }
                placement="topRight"
                showArrow={false}
                align={{ offset: [0, 10] }}
              >
                <div>
                  <Switch
                    disabled={useTooltio}
                    className={styles.switchComponent}
                    checked={value}
                    forceChange
                    onClick={() => {
                      onChange(type);
                    }}
                    size="small"
                  />
                </div>
              </TooltipComponent>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.settingsTab}>
        <h2 className={styles.label}>
          {t(`rightFileMenu.security.properties`)}
        </h2>
        {renderOption(propertiesOptions)}
        <h2 className={styles.label}>{t(`common.security`)}</h2>
        {renderOption(secureOptions)}
      </div>
      <HashWidget selectedEntity={selectedEntity} />
      <LocationWidget selectedEntity={selectedEntity} />
    </div>
  );
};

export default SettingsTab;
