// Utils
import authRequest from 'utils/request/auth-request';
import { createSecurityReqString } from 'utils/request/create-security-req-string';

// Store
import * as actions from '../../actions/file/file-update.actions';
import * as getFilesActions from '../../actions/files/get-files.actions';
import { API_FOLDERS, API_GET_FILE } from 'constants/api-urls';
import * as ghostFilesActions from '../../actions/ghost-files.actions';
import * as selectedEntityActions from '../../actions/selected-entity.actions';
import { setCurrentFolder } from 'store/main/actions';

export default (file, afterCb, securityData, additionalData) => {
  return async (dispatch) => {
    dispatch(actions.fileUpdate());

    let reqFile;

    if (file.type === 2) {
      reqFile = additionalData?.move
        ? {
            parent: additionalData.update?.folder,
          }
        : { name: file.name, ...additionalData.update };
    } else {
      reqFile = additionalData?.move
        ? {
            folder: additionalData.update?.folder,
          }
        : {
            name: file.name,
            service: file.service,
            size: file.size,
            mime: file.mime,
            ghost_mode: file.ghost_mode,
            ...additionalData.update,
          };
    }

    const url = `${file.type === 2 ? API_FOLDERS : API_GET_FILE}/${file.slug}${
      additionalData?.move ? '/move' : ''
    }${createSecurityReqString(securityData)}`;
    authRequest
      .put(url, {
        ...reqFile,
      })
      .then((result) => {
        dispatch(actions.fileUpdateSuccess());

        if (additionalData.updatedPropsList?.indexOf('folder') !== -1) {
          dispatch(getFilesActions.getFilesRemove(file));
        } else {
          const updateData = {
            entity: result.data?.data,
            updatedPropsList: additionalData.updatedPropsList,
          };
          dispatch(getFilesActions.getFilesUpdate(updateData));
          additionalData?.mainDispatch &&
            additionalData?.mainDispatch(
              setCurrentFolder({
                ...additionalData.folder,
                name: result.data?.data?.name,
              })
            );
        }
        afterCb(file);
      })
      .catch(() => {
        dispatch(actions.fileUpdateFailure());
        afterCb(file);
      });
  };
};

export const fileUpdateDataEffect = (updateData) => async (dispatch) => {
  if (updateData) {
    const data = {
      entity: updateData,
    };
    dispatch(actions.fileUpdateSuccess());
    dispatch(ghostFilesActions.ghostFilesUpdate(data));
    dispatch(getFilesActions.getFilesUpdate(data));
    dispatch(
      selectedEntityActions.selectedEntityAdd({
        entity: updateData,
      })
    );
  }
};

export const updateFileEffect = async (file, updateFields) =>
  authRequest
    .put(`${API_GET_FILE}/${file.slug}`, {
      name: file.name,
      service: file.service,
      size: file.size,
      mime: file.mime,
      ghost_mode: file.ghost_mode,
      ...updateFields,
    })
    .then(({ data: { data } }) => {
      return { ...data, folderId: file.folderId };
    })
    .catch((e) => {
      return e.message;
    });

export const updateFileNameEffect = async (slug, newName) =>
  authRequest
    .put(`${API_GET_FILE}/${slug}`, {
      name: newName,
    })
    .then(({ data: { data } }) => {
      return data;
    })
    .catch((e) => {
      return e.message;
    });
