import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { getPreviewObject } from 'store/home/effects/file/get-preview-file-blob';
import actionsOptions from 'config/actions-options';
import { ReactComponent as LinkIcon } from 'static/assets/svg/link.svg';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import { ReactComponent as DeleteIcon } from 'components/VaultModal/assets/delete.svg';
import NeyraTooltip from 'components/shared/NeyraTooltip/NeyraTooltip';

import styles from './styles.module.scss';

const SidebarItem = ({ name, slug, token, file }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [urlLogo, seturlLogo] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    if (slug && token) {
      getPreviewObject({ file, controller, sendStatistic: false })
        .then((response) => {
          if (response) {
            response.logo && seturlLogo(response.logo);
          }
        })
        .catch((response) => {
          return response.message;
        });
    }

    return () => {
      controller.abort();
    };
  }, [slug, token]);

  const onSelectLink = () => {
    history.push(`/link/${slug}`);
  };

  const onDeleteLink = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(fileFolderActionHandlerEffect({ slug }, actionsOptions.remove));
  };

  return (
    <div onClick={onSelectLink} className={styles.secondItem}>
      <div className={styles.preview}>
        <div className={styles.logo}>
          {urlLogo ? <img src={urlLogo} alt="Site logo" /> : <LinkIcon />}
        </div>
        <span>{name}</span>
      </div>
      <NeyraTooltip text="Delete" position="bottom">
        <button className={styles.deleteBtn} onClick={onDeleteLink}>
          <DeleteIcon />
        </button>
      </NeyraTooltip>
    </div>
  );
};

export default SidebarItem;
