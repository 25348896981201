import React from 'react';

const Analytics = ({ classNames }) => (
  <svg
    className={classNames}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_23633_72116)">
      <path
        d="M13.8316 8.40502H7.59692V2.17029C7.59692 1.91835 7.39537 1.7168 7.14343 1.7168C3.20641 1.7168 0.00170898 4.9215 0.00170898 8.85851C0.00170898 12.7955 3.20641 16.0002 7.14343 16.0002C11.0804 16.0002 14.2851 12.7955 14.2851 8.85851C14.2851 8.60993 14.0802 8.40502 13.8316 8.40502ZM7.14343 15.0932C3.70694 15.0932 0.9087 12.295 0.9087 8.85851C0.9087 5.57319 3.46171 2.87237 6.68993 2.64058V8.85851C6.68993 9.11046 6.89148 9.31201 7.14343 9.31201H13.3614C13.1296 12.5402 10.4288 15.0932 7.14343 15.0932Z"
        fill="currentColor"
      />
      <path
        d="M15.9983 7.1182C15.9916 5.38484 15.3567 3.71531 14.2112 2.41528C12.8541 0.880118 10.9057 0 8.85657 0C8.60463 0 8.40308 0.201554 8.40308 0.453496V7.14172C8.40308 7.39366 8.60463 7.59521 8.85657 7.59521H15.5448C15.7967 7.59521 15.9983 7.39366 15.9983 7.14172C15.9983 7.135 15.9983 7.12828 15.9983 7.1182ZM9.31007 6.69158V0.927147C10.9292 1.04472 12.4476 1.79383 13.5293 3.01995C14.4363 4.04451 14.9737 5.33445 15.0745 6.69158H9.31007Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_23633_72116">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Analytics.defaultProps = {
  classNames: '',
};

export default Analytics;
