export const CANCEL_FOLDER_UPLOADING = 'CANCEL_FOLDER_UPLOADING';

export function cancelFolderUploading(bool: boolean) {
  return {
    type: CANCEL_FOLDER_UPLOADING,
    payload: {
      isFolderUploadingCanceled: bool,
    },
  };
}
