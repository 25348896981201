//Refactoring №3
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DebounceInput } from 'react-debounce-input';

import cn from 'classnames';
import _omit from 'lodash/omit';

import Info from 'components/Info';
import EyeIcon from 'components/svg/eye';

import s from './style.module.scss';

export const InputFieldTheme = {
  GENERAL: s.themeGeneral,
  GET_INVITE: s.getInvite,
  UNDERLINED: s.themeUnderlined,
};

export default function InputField({
  ref,
  name,
  type = 'text',
  label,
  calloutText,
  autoFocus = false,
  className,
  inputClassName,
  errorClassName,
  error,
  touched = true,
  optional = false,
  placeholder = '',
  withValidation = true,
  hideErrorMessage = false,
  autoComplete = 'off',
  value,
  onChange,
  onBlur,
  onKeyPress,
  theme = InputFieldTheme.GENERAL,
  readOnly = false,
  disabled,
  tooltip,
  step,
  min,
  isRequired = false,
  isPassword = false,
  customIcon,
  customEye,
  ...rest
}) {
  const inputProps = _omit(rest, [
    `validate`,
    `initialError`,
    `initialValue`,
    `initialTouched`,
    `hideLabel`,
  ]);
  const { t } = useTranslation('owner');
  const [inputType, setInputType] = useState(type);

  const changeType = () => {
    if (inputType === 'text') {
      setInputType(type);
    } else {
      setInputType('text');
    }
  };

  return (
    <div
      className={cn(
        theme,
        className,
        withValidation && touched && error && (errorClassName || s.isError),
        disabled && s.disabled
      )}
    >
      {label && (
        <label className={s.label} htmlFor="">
          {label}
          {optional && (
            <span className={s.optional}>{t('common.optional')}</span>
          )}
          {isRequired && <span className={s.required}>*</span>}
        </label>
      )}
      {calloutText && <Info calloutText={calloutText} />}
      <DebounceInput
        ref={ref}
        className={cn(s.input, inputClassName)}
        type={isPassword ? inputType : type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
        autoComplete={autoComplete}
        disabled={disabled}
        /* eslint-disable jsx-a11y/no-autofocus */
        autoFocus={autoFocus}
        onKeyPress={onKeyPress}
        step={step}
        min={min}
        {...inputProps}
      />
      {customIcon && (
        <button type="button" className={s.customIcon} onClick={changeType}>
          {customIcon}
        </button>
      )}
      {isPassword && value && (
        <button type="button" className={s.showPassword} onClick={changeType}>
          {customEye || <EyeIcon color="grey" />}
        </button>
      )}
      {withValidation && !hideErrorMessage && touched && error && (
        <div className={s.errorMessage}>{(touched && error) || '\u00A0'}</div>
      )}
      {tooltip}
    </div>
  );
}
