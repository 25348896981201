import React from "react";

import styles from './styles.module.scss';

const CreateRenameModal = ({ file, entityContent }: any) => {
  return (
    <div className={styles.imageContent}>
      <img
        className={styles.myImage}
        alt={file.name}
        src={entityContent}
      />
    </div>
  )
}

export default CreateRenameModal;