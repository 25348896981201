//Refactoring №3
import React, { useState, useRef } from 'react';
import TagsInput from 'react-tagsinput';
import AutosizeInput from 'react-input-autosize';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from 'static/assets/svg/close.svg';

import CN from 'classnames';
import styles from './style.module.scss';

const Tags = ({ tags, setTags }) => {
  const wrapperFef = useRef(null);
  const inputRef = useRef(null);
  const [nextTag, setNextTag] = useState('');
  const { t } = useTranslation('contextMenu');

  const onChangeHandler = (newTags) => {
    const updatedTags = newTags.map((name) => {
      const result = tags.find((tag) => tag === name);
      return result ? result : name;
    });

    setTimeout(() => {
      wrapperFef.current?.scrollTo(0, wrapperFef.current?.scrollHeight);
    }, 300);

    setTags(updatedTags);
  };

  const onChangeInputHandler = (newTag) => {
    setNextTag(newTag);
  };

  const renderInput = ({ onChange, value, ...other }) => {
    return (
      <AutosizeInput type="text" onChange={onChange} value={value} {...other} />
    );
  };

  const renderTag = ({ key, tag, getTagDisplayValue, onRemove, ...other }) => {
    return (
      <span {...other} className={styles.tagItem} key={key}>
        {`#${getTagDisplayValue(tag)}`}
        <button
          type="button"
          className={styles.tagItemDelete}
          onClick={() => onRemove(key)}
        >
          <CloseIcon />
        </button>
      </span>
    );
  };

  const renderLayout = (tagElements, inputElement) => {
    return (
      <span className={styles.tagLayout}>
        {tagElements}
        {inputElement}
      </span>
    );
  };

  return (
    <div ref={wrapperFef} className={styles.wrapper}>
      <TagsInput
        onlyUnique
        value={tags}
        renderTag={renderTag}
        renderInput={renderInput}
        renderLayout={renderLayout}
        inputValue={nextTag}
        onChangeInput={onChangeInputHandler}
        onChange={onChangeHandler}
        className={styles.inputWrapper}
        placeholder=""
        inputProps={{
          ref: inputRef,
          placeholder: `+ ${t('main.addPassword.addTag')}`,
          autoComplete: 'off',
          className: CN(
            styles.activeInput,
            tags.length && styles.activeInputTagExist
          ),
        }}
      />
    </div>
  );
};

export default Tags;
