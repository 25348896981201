import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';

import {
  encryptionKeyError,
  closeEncryptionModal,
  changeEncryptionStatus,
} from 'store/home/actions/file/encryption-key.action';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';

import CloseIcon from 'components/svg/close';
import Spinner from 'components/Spinner';

import actionsOptions from 'config/actions-options';

import style from './style.module.scss';

const EncryptionKeyModal = ({ modalIsOpen, closeModal, file }) => {
  const { t } = useTranslation('common');
  const [inputValue, setInputValue] = useState('');
  const [isValid, setIsValid] = useState(true);
  const { download } = actionsOptions;

  const dispatch = useDispatch();
  const keyError = useSelector(
    (state) => state?.home?.encryptionKey?.encryptionKeyError
  );
  const closeEncryptModal = useSelector(
    (state) => state?.home?.encryptionKey?.closeEncryptionModal
  );
  const decryptionProgress = useSelector(
    (state) => state?.home?.encryptionKey?.isDecryptionProgress
  );

  useEffect(() => {
    if (closeEncryptModal) {
      closeModal();
      dispatch(closeEncryptionModal(false));
      keyError.error &&
        dispatch(encryptionKeyError({ error: false, message: '' }));
    }
    return () => {
      setInputValue('');
      setIsValid(true);
    };
  }, [closeEncryptModal]);

  const compareKeys = () => {
    dispatch(changeEncryptionStatus(true));
    dispatch(
      fileFolderActionHandlerEffect(file, download, {
        encryptionKey: inputValue,
      })
    );
  };

  const onCreateClickHandler = async () => {
    if (inputValue && inputValue.trim()) {
      !isValid && setIsValid(true);
      keyError.error &&
        dispatch(encryptionKeyError({ error: false, message: '' }));
      compareKeys();
    } else {
      isValid && setIsValid(false);
    }
  };

  const handleKeyPress = async (event) => {
    if (event.key === 'Enter' && inputValue) {
      compareKeys();
      return;
    }
    isValid && setIsValid(false);
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.value) {
      !isValid && setIsValid(true);
      keyError.error &&
        dispatch(encryptionKeyError({ error: false, message: '' }));
    } else {
      isValid && setIsValid(false);
    }
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Enter Encryption Key"
      className="Modal"
      overlayClassName="OverlayHard"
      shouldCloseOnOverlayClick
    >
      <section className="modal modal__contented" style={{ maxWidth: '600px' }}>
        <button type="button" className="modal__exit" onClick={closeModal}>
          <CloseIcon color="#4B576C" />
        </button>
        <div className={style.titleEncrypt}>Enter Encryption Key</div>
        <div className={style.subtitleEncrypt}>
          <span>To perform this action, an encryption key is required. </span>
          You can obtain the encryption key from the workspace owner.
        </div>

        <div className="modal__content">
          <div
            className={CN(
              'modal__content-notice modal__content-notice-left',
              !isValid || (keyError.error && style.errorInput)
            )}
          >
            <input
              type="text"
              className={`${style.decryptInput} ${
                !isValid || keyError.error ? style.error : ''
              }`}
              placeholder="Enter activation key"
              value={inputValue}
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              tabIndex={0}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            {keyError && (
              <div className={`${style.errorMessage} ${style.invalidKey}`}>
                {keyError.message}
              </div>
            )}
          </div>
        </div>
        <div className={style.decryptButtons}>
          <button
            className={style.cancelDecrypt}
            type="button"
            onClick={closeModal}
          >
            {t('common.cancel')}
          </button>
          <button
            className={`${style.decrypt} ${
              inputValue && !decryptionProgress ? style.active : ''
            }`}
            type="button"
            onClick={onCreateClickHandler}
            disabled={!inputValue || decryptionProgress}
          >
            {!decryptionProgress ? 'Decrypt' : <Spinner />}
          </button>
        </div>
        <div className={style.message}>
          *Please ensure that you have the correct encryption key before
          proceeding
        </div>
      </section>
    </Modal>
  );
};

export default EncryptionKeyModal;
