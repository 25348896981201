/* eslint-disable no-debugger */
//Refactoring №3
import React, { useState, useRef, useEffect } from 'react';

import PropTypes from 'prop-types';
import cn from 'classnames';

import useClickOutside from 'utils/hooks/use-click-outside';

import s from './style.module.scss';

const Popover = ({
  children,
  text,
  open,
  displayArrow,
  trigger,
  onChange,
  className,
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const popoverRef = useRef(null);
  const triggerWrapperRef = useRef(null);
  const isHoverTrigger = trigger === 'hover';
  const isClickTrigger = trigger === 'click';

  useEffect(() => {
    onChange && onChange(show);
  }, [show]);

  const closePopover = (e) => {
    if (
      e.target !== triggerWrapperRef.current &&
      Array.from(triggerWrapperRef?.current?.children)?.every(
        (child) => child !== e.target && e.target.parentElement !== child
      ) &&
      !open
    ) {
      setShow(false);
    }
  };

  const openPopover = () => setShow(open ? false : true);
  useClickOutside(popoverRef, closePopover);

  return (
    <div className={cn(s.wrapper, className)}>
      <div
        style={open ? { display: 'block' } : undefined}
        className={cn(s.popover, show && open && s.visible)}
        ref={popoverRef}
      >
        {text}
        {displayArrow && <span className={s.popoverArrow} />}
      </div>
      <div
        onMouseEnter={isHoverTrigger ? openPopover : undefined}
        onMouseLeave={isHoverTrigger ? closePopover : undefined}
        onClick={isClickTrigger ? () => setShow(true) : undefined}
        ref={triggerWrapperRef}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};

Popover.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  displayArrow: PropTypes.bool,
  trigger: PropTypes.oneOf(['hover', 'click']),
};

Popover.defaultProps = {
  open: false,
  displayArrow: true,
  trigger: 'hover',
  className: '',
};

export default Popover;
