// Refactoring №3
import React, { useEffect, useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';

import card from './assets/card.png';
import diamondBackground from './assets/diamond-background.png';
import illustration from './assets/illustration.png';
import squareBackground from './assets/square-background.jpg';
import sharingImage from './assets/sharing.png';
import tokenizationImage from './assets/tokenization.png';

import { ReactComponent as Rectangle } from './assets/rectangle.svg';
import { ReactComponent as Corner } from 'static/assets/svg/ai-generator-corner.svg';
import { ReactComponent as Triangle } from 'static/assets/svg/ai-triangle.svg';
import { ReactComponent as CloseIcon } from 'static/assets/svg/close.svg';

import style from './style.module.scss';

type modalProps = {
  isOpen: boolean;
  handleClose: (arg: boolean) => void;
};

const OnboardingModal = ({ isOpen, handleClose }: modalProps) => {
  const { t } = useTranslation('account');
  const [slideIndex, setSlideIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(0);
  const [closeButtonIcon, setCloseButtonIcon] = useState<any>('Skip');
  const rectangles = [Rectangle, Rectangle, Rectangle, Rectangle];

  useEffect(() => {
    const id = setInterval(() => {
      setSlideIndex((prevIndex) => prevIndex + 1);
    }, 4000);

    setIntervalId(Number(id));

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (slideIndex > 3) handleClose(false);
  }, [slideIndex]);

  const handleOnModalClick = () => {
    clearInterval(intervalId);
    setCloseButtonIcon(<CloseIcon />);
  };

  const onClose = () => handleClose(false);

  const onRectangleClick = (e: React.SyntheticEvent<EventTarget>) => {
    if ((e.target as SVGElement).nodeName === 'svg') {
      setSlideIndex(Number((e.target as SVGElement).id));
    }
  };
  const monetizationModalContent = useMemo(
    () => (
      <div className={style.slideContainer}>
        <div className={style.figureWrapper}>
          <Corner className={style.figureWrapper__firstLine} />
          <Corner className={style.figureWrapper__secondtLine} />
          <Corner className={style.figureWrapper__thirdLine} />
          <Triangle className={style.figureWrapper__triangle} />
          <img src={card} alt="card" className={style.figureWrapper__card} />
          <img
            src={diamondBackground}
            alt="diamond"
            className={style.figureWrapper__diamondBackground}
          />
        </div>
        <h2 className={style.modal_header}>
          {t('onboardingModal.monetizationTitle')}
        </h2>
        <p className={style.modal_description}>
          {t('onboardingModal.monetizationText')}
        </p>
      </div>
    ),
    []
  );
  const privacyModalContent = useMemo(
    () => (
      <div className={style.slideContainer}>
        <div className={style.figureWrapper}>
          <img
            src={squareBackground}
            alt="background"
            className={style.figureWrapper__squareBackground}
          />
          <img
            src={illustration}
            alt="illustration"
            className={style.figureWrapper__illustration}
          />
        </div>
        <h2 className={style.modal_header}>
          {t('onboardingModal.privacyTitle')}
        </h2>
        <p className={style.modal_description}>
          {t('onboardingModal.privacyText')}
        </p>
      </div>
    ),
    []
  );
  const sharingModalContent = useMemo(
    () => (
      <div className={style.slideContainer}>
        <div className={style.figureWrapper}>
          <img
            src={sharingImage}
            alt="sharing screenshot"
            className={style.figureWrapper__sharingImage}
          />
        </div>
        <h2 className={style.modal_header}>
          {t('onboardingModal.sharingTitle')}
        </h2>
        <p className={style.modal_description}>
          {t('onboardingModal.sharingText')}
        </p>
      </div>
    ),
    []
  );
  const tokenizationModalContent = useMemo(
    () => (
      <div className={style.slideContainer}>
        <div className={style.figureWrapper}>
          <img
            src={tokenizationImage}
            alt="tokenization screenshot"
            className={style.figureWrapper__tokenizationImage}
          />
        </div>
        <h2 className={style.modal_header}>
          {t('onboardingModal.tokenizationTitle')}
        </h2>
        <p className={style.modal_description}>
          {t('onboardingModal.tokenizationText')}
        </p>
      </div>
    ),
    []
  );
  const renderModalContent = () => {
    switch (slideIndex) {
      case 0:
        return monetizationModalContent;
      case 1:
        return privacyModalContent;
      case 2:
        return sharingModalContent;
      case 3:
        return tokenizationModalContent;
      default:
        break;
    }
  };

  return (
    <div onClick={handleOnModalClick}>
      <ReactModal
        isOpen={isOpen}
        onRequestClose={onClose}
        className={style.modal}
        overlayClassName={style.overlay}
        shouldCloseOnOverlayClick
      >
        <button className={style.skipBtn} onClick={onClose}>
          {closeButtonIcon}
        </button>
        <div>{renderModalContent()}</div>
        <div className={style.rectangleContainer} onClick={onRectangleClick}>
          {rectangles.map((Rectangle, index) => (
            <Rectangle
              key={index}
              id={`${index}`}
              className={
                index === slideIndex
                  ? style.activeRectangle
                  : style.fadeRectangle
              }
            />
          ))}
        </div>
      </ReactModal>
    </div>
  );
};

export default OnboardingModal;
