//New
import axios from 'axios';
import authRequest from 'utils/request/auth-request';

export const getTokenExchangeRate = async () =>
  axios
    .get(`${process.env.REACT_APP_API_PATH}/crypto/range`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.message;
    });


export const getNetworkList = async () => 
  authRequest.get(`${process.env.REACT_APP_API_PATH}/net/list`)
    .then((response) => {
      return response.data.data;
    })
    .catch((response) => {
      return response.message;
    });
