import React from 'react';

import SuccessIcon from 'components/svg/success';

import styles from './styles.module.scss';

const Notification = ({ icon, text }) => {
  return (
    <div className={styles.notification}>
      <div className={styles.notificationIcon}>{icon || <SuccessIcon />}</div>
      <div className={styles.notificationTyper}>
        <p className={styles.notificationText}>
          {text} <span className={styles.blinkingPipe}>|</span>
        </p>
      </div>
    </div>
  );
};

export default Notification;
