import React, { useRef } from 'react'

const FileRename = ({ onCancel }) => {
  const fileNameInputRef = useRef(null)

  const rename = () => {
    // console.warn(fileNameInputRef.current.value)
  }

  return (
    <div className="right-menu__container file-rename">
      <label className="right-menu__label">
        File Name
        <input
          type="text"
          name="file-name"
          className="right-menu__input"
          ref={fileNameInputRef}
          placeholder="Name your shared folder"
        />
      </label>

      <button
        className="button button--primary text--medium text--12 text--uppercase bottom-button"
        type="button"
        onClick={rename}
      >
        SAVE
      </button>
      <button className="link bottom-link" type="button" onClick={onCancel}>
        CANCEL
      </button>
    </div>
  )
}

export default FileRename
