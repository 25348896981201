import React from 'react'

const BigBell = ({ color }) => (
  <svg
    width="27"
    height="34"
    viewBox="0 0 27 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.4426 27.0574C26.3423 26.9925 25.5874 26.4656 24.8315 24.8715C23.4433 21.9442 23.152 17.8205 23.152 14.8767C23.152 14.8638 23.1517 14.8512 23.1512 14.8384C23.1361 10.9477 20.798 7.59382 17.4521 6.08899V3.78755C17.4521 1.69907 15.7498 0 13.6572 0H13.3427C11.2502 0 9.54784 1.69907 9.54784 3.78755V6.08886C6.19079 7.59854 3.84801 10.9696 3.84801 14.8767C3.84801 17.8205 3.55664 21.9441 2.16849 24.8715C1.41267 26.4655 0.657778 26.9925 0.55741 27.0574C0.134747 27.2523 -0.0736527 27.7026 0.0235825 28.1588C0.121751 28.6196 0.554011 28.9373 1.02606 28.9373H8.35349C8.39441 31.7357 10.6866 34 13.5 34C16.3134 34 18.6056 31.7357 18.6466 28.9373H25.974C26.446 28.9373 26.8783 28.6196 26.9765 28.1588C27.0736 27.7026 26.8652 27.2523 26.4426 27.0574V27.0574ZM11.5473 3.78755C11.5473 2.79936 12.3527 1.99544 13.3428 1.99544H13.6573C14.6474 1.99544 15.4529 2.79936 15.4529 3.78755V5.44219C14.8219 5.31223 14.1687 5.24384 13.4998 5.24384C12.831 5.24384 12.1781 5.31217 11.5473 5.44199V3.78755H11.5473ZM13.5 32.0046C11.789 32.0046 10.3934 30.6355 10.3528 28.9374H16.6471C16.6066 30.6354 15.211 32.0046 13.5 32.0046V32.0046ZM17.5254 26.9418C17.5252 26.9418 3.30432 26.9418 3.30432 26.9418C3.47727 26.6733 3.65328 26.3692 3.82822 26.0255C5.16805 23.393 5.84743 19.642 5.84743 14.8767C5.84743 10.6654 9.28026 7.23928 13.4997 7.23928C17.7191 7.23928 21.152 10.6654 21.152 14.88C21.152 14.8923 21.1521 14.9045 21.1526 14.9167C21.1564 19.6629 21.8357 23.4004 23.1718 26.0255C23.3468 26.3693 23.5228 26.6733 23.6957 26.9418H17.5254Z"
      fill={color}
    />
  </svg>
)

BigBell.defaultProps = {
  color: 'currentColor',
}
export default BigBell
