import { DECRYPTED_KEYS } from 'config/file-action-types';

export const setKeyToLS = ({
  slug,
  base64Key,
}: {
  slug: string;
  base64Key: string;
}) => {
  const localStorageKeys = localStorage.getItem(DECRYPTED_KEYS);
  const parsedLocalStorageKeys = localStorageKeys
    ? JSON.parse(localStorageKeys)
    : {};

  parsedLocalStorageKeys[slug] = base64Key;

  localStorage.setItem(DECRYPTED_KEYS, JSON.stringify(parsedLocalStorageKeys));
};
