import { createContext } from 'react'

const EntitySettings = createContext({
  entity: null,
  props: {},
  setProps: () => {},
  show: false,
  toggleSettings: () => {},
})

export default EntitySettings
