const MINUSE_KEY_CODE = 45;
const E_KEY_CODE = 101;
const BACKSPACE_KEY_CODE = 8;
const ENTER_KEY_CODE = 13;
const COMMA_KEY_CODE = 188;
const DOT_KEY_CODE = 190;

export {
  MINUSE_KEY_CODE,
  E_KEY_CODE,
  ENTER_KEY_CODE,
  COMMA_KEY_CODE,
  DOT_KEY_CODE,
  BACKSPACE_KEY_CODE,
};
