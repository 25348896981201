//Refactoring №4
import React from 'react';

import CN from 'classnames';

import { ReactComponent as CutTopLeftCornerIcon } from 'static/assets/svg/corners/TopLeftCutCorner.svg';
import { ReactComponent as TopLeftCornerIcon } from 'static/assets/svg/corners/TopLeftCorner.svg';
import { ReactComponent as TopRightCornerIcon } from 'static/assets/svg/corners/TopRightCorner.svg';
import { ReactComponent as BotLeftCornerIcon } from 'static/assets/svg/corners/BottomLeftCorner.svg';
import { ReactComponent as BotRightCornerIcon } from 'static/assets/svg/corners/BottomRightCorner.svg';

import styles from './style.module.scss';

type IProps = {
  className: string,
  noCutTopLeftCorder: boolean
}

const RelativeBorder = ({ className, noCutTopLeftCorder }: IProps) => (
  <div className={CN(styles.corners, className)}>
    {noCutTopLeftCorder ? (
      <TopLeftCornerIcon className={styles.topLeftCorner} />
    ) : (
      <CutTopLeftCornerIcon className={styles.topLeftCorner} />
    )}
    <TopRightCornerIcon className={styles.topRightCorner} />
    <BotLeftCornerIcon className={styles.botLeftCorner} />
    <BotRightCornerIcon className={styles.botRightCorner} />
  </div>
);

export default RelativeBorder