import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import OpenEye from 'components/svg/eyeOpen';
import CloseEye from 'components/svg/eyeClose';
import { getLocation } from 'client-neyra';
import Dropdown from '../Dropdown';
import { useMask } from '@react-input/mask';
import styles from './styles.module.scss';

const Input = ({
  addAnswer,
  step,
  autoFocus,
  error,
  placeholder,
  onFocus,
  onBlur,
}) => {
  const { t } = useTranslation('auth');
  const { t: workspaceT } = useTranslation('workspace');
  const inputRef = useRef(null);
  const inputRefDate = useMask({
    showMask: true,
    mask: 'YYYY-MM-DD hh:mm',
    replacement: {
      Y: /\d/,
      M: /\d/,
      D: /\d/,
      h: /\d/,
      m: /\d/,
    },
  });
  const [showPassword, setShowPassword] = useState(step !== 'password');
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    inputRef.current && inputRef.current.blur();
    inputRefDate.current && inputRefDate.current.blur();

    if (step === 'dateAndTime') {
      autoFocus && inputRefDate.current.focus();
    } else {
      autoFocus && inputRef.current.focus();
    }
  }, [step, autoFocus]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && step !== 'city') {
      event.preventDefault();
      addAnswer(event.target.value);
      setValue('');
    }
  };

  useEffect(() => {
    if (!error) {
      setValue('');
    }
  }, [error, step]);

  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleChange = ({ target: { value } }) => {
    setValue(value);
  };

  useEffect(async () => {
    if (step === 'city') {
      if (value) {
        try {
          const data = await getLocation({ params: { q: value } });
          const list = [];
          data.data.forEach((item) => {
            list.push({
              label: `${item.city} / ${item.land} / ${item.sdiv} / ${item.tz}`,
              value: `${item.city} / ${item.land} / ${item.sdiv} / ${item.tz}`,
            });
          });

          setOptions(list);
        } catch (error) {
          console.warn(error);
        }
      } else {
        setOptions([]);
      }
    }
  }, [step, value]);

  return (
    <div className={styles.inputContainer}>
      <input
        placeholder={placeholder ? placeholder : workspaceT('settings.type')}
        type={showPassword ? 'text' : 'password'}
        onKeyPress={handleKeyPress}
        className={`${styles.input} ${error ? styles.input_error : ''}`}
        ref={step === 'dateAndTime' ? inputRefDate : inputRef}
        onChange={handleChange}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {step === 'password' && (
        <button
          title={`${
            showPassword
              ? t('initialPage.hidePassword')
              : t('initialPage.showPassword')
          }`}
          onClick={toggleShowPassword}
          className={styles.eyeButton}
        >
          {showPassword ? (
            <CloseEye color="white" />
          ) : (
            <OpenEye color="white" />
          )}
        </button>
      )}
      {error && <div className={styles.error}>{error}</div>}
      {step === 'city' && (
        <Dropdown
          options={options}
          onClick={(value) => {
            addAnswer(value);
          }}
        />
      )}
    </div>
  );
};

export default Input;
