import React from 'react';
import { useTranslation } from 'react-i18next';

import CN from 'classnames';
import styles from './styles.module.scss';

const PayButton = ({ onClick, disabled, price }) => {
  const { t } = useTranslation('auth');
  return (
    <button
      disabled={!disabled}
      onClick={onClick}
      className={CN(styles.payButton, !disabled && styles.payButtonDisabled)}
    >
      {t('ring.checkout')} <span>$ {price}</span>
    </button>
  );
};

export default PayButton;
