import axios from 'axios';
import { API_AUTH } from 'constants/api-urls';

export const recoveryPasswordEffect = async (email) =>
  axios
    .post(`${API_AUTH}/recovery`, { email })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data;
    });

export const recoveryPasswordCompleteEffect = async (
  confirmationToken,
  newPassword,
  repeatPassword
) =>
  axios
    .post(`${API_AUTH}/recovery/complete`, {
      confirmationToken,
      plainPassword: {
        first: newPassword,
        second: repeatPassword,
      },
    })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      // eslint-disable-next-line no-throw-literal
      throw error?.response?.data;
    });
