//Refactoring №2
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomFolderIcon from 'components/CustomFileIcon/CustomFolderIcon';
import {
  AccessIcon,
  ColorIcon,
  CommentIcon,
  DeleteIcon,
  DownloadIcon,
  EmojiIcon,
  LockIcon,
  MoveIcon,
  PreviewIcon,
  PrintIcon,
  RenameIcon,
  SecurityIcon,
  ShareIcon,
  TagIcon,
  RestoreIcon,
  GeoSecurityIcon,
  AnalyticsIcon,
  AiGenerator,
  AiPlayground,
  UploadFileIcon,
  AddPageIcon,
  RecordIcon,
  PasswordIcon,
  EditIcon,
  EncryptIcon,
  AiUpscaller,
  SafetyNetIcon,
  LinksIcon,
  ConvertIcon,
  MyAiIcon
} from '../svg';

import DiamondIcon from '../svg/settings/diamondIcon';
import { ReactComponent as FolderTransparentIcon } from 'static/assets/svg/folder-transparent.svg';
import actionsOptions from 'config/actions-options';

import cn from 'classnames';
import style from './breadcrumbs.module.scss';

const optionIcons = {
  viewFile: PreviewIcon,
  access: AccessIcon,
  manageShare: ShareIcon,
  accessPreferences: ShareIcon,
  showMove: MoveIcon,
  edit: RenameIcon,
  color: ColorIcon,
  tag: TagIcon,
  emoji: EmojiIcon,
  comment: CommentIcon,
  nft: DiamondIcon,
  goToComments: CommentIcon,
  print: PrintIcon,
  download: DownloadIcon,
  goToSecuritySettings: SecurityIcon,
  lock: LockIcon,
  remove: DeleteIcon,
  removeFromTrash: DeleteIcon,
  restoreFromTrash: RestoreIcon,
  removeFromShared: DeleteIcon,
  removeShared: DeleteIcon,
  removeDocument: DeleteIcon,
  geoSecurity: GeoSecurityIcon,
  'my-ai': MyAiIcon,
  folder: CustomFolderIcon,
  analytics: AnalyticsIcon,
  guardians: SafetyNetIcon,
  aiGenerator: AiGenerator,
  aiPlayground: AiPlayground,
  aiUpscaller: AiUpscaller,
  createFolder: FolderTransparentIcon,
  upload: UploadFileIcon,
  addPage: AddPageIcon,
  addRecord: RecordIcon,
  addPassword: PasswordIcon,
  editFile: EditIcon,
  editPassword: EditIcon,
  encrypt: EncryptIcon,
  addLink: LinksIcon,
  convert: ConvertIcon,
  default: () => <div />,
};

const ContextMenuItem = ({
  option,
  onClickHandler,
  disabled,
  divider,
  className,
  onMouseEnter,
  onMouseLeave,
  isBreadcrumbs,
}) => {
  const { t } = useTranslation('contextMenu');
  const IconSwitcher = optionIcons[option.type]
    ? optionIcons[option.type]
    : optionIcons.default;

  const optionNameHandler = (option) => {
    if (option.type === 'folder' || option.type === 'file') return option.name;
    if (option.type === 'folder' || option.type === 'file') return option.name;
    if (option.type === actionsOptions.myAI.type && option.name !== actionsOptions.myAI.name) {
      return t(`contextMenuOptions.${option.type}.secondName`);
    }
    return t(`contextMenuOptions.${option.type}.name`);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className={cn(
        'context-menu-item',
        disabled && 'disabled',
        divider && 'context-menu-item-divider',
        className,
        isBreadcrumbs && style.breadcrumbContextMenu
      )}
      onClick={!disabled ? onClickHandler : () => {}}
      onKeyDown={() => {}}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-test={`context-menu_item_${option.type}`}
    >
      <div className="context-menu-item-icon">
        {option.type === 'folder' ? (
          <CustomFolderIcon
            viewType={'context-menu'}
            color={option?.color[0]?.hex}
          />
        ) : (
          <IconSwitcher />
        )}
      </div>
      <div className="context-menu-item-name">{optionNameHandler(option)}</div>
    </div>
  );
};

export default ContextMenuItem;
