//Refactoring №3
import React, { useEffect } from 'react';

import authRequest from 'utils/request/auth-request';

import Preloader from 'components/shared/Preloader';

const Google = ({ history }) => {
  const code = new URLSearchParams(history.location.search).get('code');
  localStorage.setItem('code', '');
  useEffect(() => {
    authRequest
      .post(`${process.env.REACT_APP_API_PATH}/cloud-auth/google/code`, {
        code: code,
      })
      .then((e) => {
        localStorage.setItem('code', e?.data?.data?.id);
      });
  }, []);
  return <Preloader />;
};

export default Google;
