import axios from 'axios';
import { getAccessToken } from 'utils/auth/get-token';
import { API_CHECK_SECURITY } from 'constants/api-urls';

/**
 * @summary Send request to server with security data, and return token if success
 * @function
 * @public
 * @param {string} securityType
 * @param {string} value
 * @returns {Function}
 */
export const entityCheckSecurityEffect = (securityType, value) => {
  return async () => {
    const url = `${API_CHECK_SECURITY}?securityType=${securityType}`;
    const data = new FormData();
    data.append('data', value);

    const token = await getAccessToken();

    return axios
      .create({
        headers: { 'X-Token': token },
      })
      .post(url, data);
  };
};
