//Refactoring №3
import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import Button, { ButtonTheme } from 'components/Button';
import CloseIcon from 'components/svg/close';

import style from './style.module.scss';

type ModalProps = {
  isOpen: boolean;
  onClose: (type: string | undefined) => void;
  error?: string;
};

export const AiGeneratorsErrorModal = ({
  isOpen,
  onClose,
  error,
}: ModalProps) => {
  const { t } = useTranslation('contextMenu');
  const message = !error ? t('main.imageToImage.somethingWentWrong') : error;
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={style.overlay}
      className={style.modal}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
    >
      <div className={style.modal__wrapper}>
        <button type="button" className={style.modal__exit} onClick={onClose}>
          <CloseIcon color="#4B576C" />
        </button>
        <div className={style.modal__bugReportMessageModalContent}>
          <h3 className={style.modal__title}>{t('main.imageToImage.oops')}</h3>
          <span className={style.modal__description}>{message}</span>
          <div className={style.modal__buttons}>
            <Button
              className={style.modal__buttons__button}
              theme={ButtonTheme.DARK}
              type="button"
              name="generate"
              value="Generate image"
              onClick={onClose}
            >
              {t('main.imageToImage.ok')}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
