import { createSlice } from '@reduxjs/toolkit';

const speakerSlice = createSlice({
  name: 'speaker',
  initialState: {
    autoSpeaker: false,
    messageQueue: [],
    audioQueue: [],
  },
  reducers: {
    setAutoSpeaker(state, action) {
      if (!action.payload) {
        state.messageQueue = [];
        state.audioQueue = [];
      }
      state.autoSpeaker = action.payload;
    },
    addMessageToQueue(state, action) {
      state.messageQueue.push(action.payload);
    },
    removeMessageFromQueue(state) {
      state.messageQueue.shift();
    },
    addAudioToQueue(state, action) {
      state.audioQueue.push(action.payload);
    },
    removeAudioFromQueue(state) {
      state.audioQueue.shift();
    },
  },
});

export const {
  setAutoSpeaker,
  addMessageToQueue,
  removeMessageFromQueue,
  addAudioToQueue,
  removeAudioFromQueue,
} = speakerSlice.actions;

export const selectAutoSpeaker = (store) => store.speaker.autoSpeaker;

export default speakerSlice.reducer;
