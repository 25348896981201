const ALLOW_NEYRA_READ_FORMATS = [ 'log', 'txt', 'memo' ]

export const validationErrors = (errorsObject) => {
  if (Object.keys(errorsObject).length === 0) return false

  for (const prop in errorsObject) {
    if (errorsObject[prop]) return true
  }

  return false
}

export const validationNeyraReader = ({ entity, addNotification }) => {
  const {extension = '',  size, name, mime } = entity;
  if (extension === 'webp')
    return true;

  if (!extension || (!ALLOW_NEYRA_READ_FORMATS.includes(extension.toLowerCase()) && !mime.includes('image/'))) {
    const text = extension ? "File extension {extension} is not allowed" : "Folder is not allowed";
    addNotification && addNotification(text.replace('{extension}', `.${extension}`), 'alert')
    return false;
  }
  if (size >= 10485760) {
    addNotification && addNotification("File {name} exceeds limit of 10MB per file".replace('{name}', name), 'alert')
    return false;
  }

  return true
}