import * as actions from '../../actions/file/download-file.action'

const initialState = {
  status: false,
  size: null,
  files: [],
  cancelTokenSource: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.START_DOWNLOAD:
      return { 
        status: true,
        size: action.size,
        slug: action.slug,
        files: action.files,
        cancelTokenSource: state.cancelTokenSource,
      }

    case actions.END_DOWNLOAD:
      return {
        status: false,
        size: null,
        files: [],
        cancelTokenSource: null,
      }

    case actions.ADD_TOKEN_DOWNLOAD:
      return { 
        status: state.status,
        size: state.size,
        files: state.files,
        cancelTokenSource: action.cancelTokenSource,
      }

    case actions.CANCEL_DOWNLOAD:
      return { 
        status: false,
        size: null,
        files: state.files,
        cancelTokenSource: null,
      }

    default:
      return state
  }
}
