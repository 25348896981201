import { React, useState } from 'react';

import Checkbox from 'components/Checkbox';
import { ReactComponent as PublishIcon } from '../assets/publish.svg'

import styles from './styles.module.scss';

const PublishWidget = () => {
  const [publishType, setPublishType] = useState('')
  
  const handlePublishType = (type) => {
    setPublishType(type)
  }

  return (
    <div className={styles.myAiTab}>
      <div className={styles.header}>
        <h2 className={styles.label}>
          <div className={styles.icon}>
            <PublishIcon />
          </div>
          Neyra Report
        </h2>
      </div>
      <p className={styles.textSecond}>Allows users to make content accessible to the public</p>
      <div className={styles.publishOptions}>
        <div onClick={() => {handlePublishType('neyra')}} className={styles.option}>
          <Checkbox checked={publishType === 'neyra'} />
          <span>Neyra</span>
        </div>
        <div onClick={() => {handlePublishType('avatar')}} className={styles.option}>
          <Checkbox checked={publishType === 'avatar'} />
          <span>Avatar</span>
        </div>
        <div onClick={() => {handlePublishType('community')}} className={styles.option}>
          <Checkbox checked={publishType === 'community'} />
          <span>Community</span>
        </div>
      </div>
      <button disabled className={styles.createBtn}>
        Publish 
      </button>
    </div>
  );
};

export default PublishWidget;
