import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import useAnimatedMessage from 'utils/hooks/useAnimatedMessage';
import NeyraLogo from '../NeyraWelcome/component/NeyraLogo';
import { useHistory } from 'react-router';
import classNames from 'classnames';
import { DARK_THEME, ThemeContext } from 'store/context/theme';
import { getUser, saveSettings } from 'client-neyra';
import { neyraUserSettings } from 'utils/hooks/useNewlyRegisteredUser';

const messages = {
  default: `Welcome to our non-toxic network, where creativity and AI come together. Here, you'll have the chance to craft your own AI profile on the cutting-edge Web4 network.\n\nQuick Guide to Our Terms:\n<ul><li><span>Your Privacy:</span> We prioritize keeping your information safe with top-notch security.</li><li><span>Positive Vibes:</span> Respect and kindness are musts here. No negativity allowed.</li><li><span>Your Work, Your Rights:</span> Whatever you create, it stays yours.</li><li><span>Craft Your AI Persona:</span> Design an AI profile to connect, engage, and monetize your skills.</li></ul>\nYou're now part of a community aiming for a smarter, friendlier digital world.
  Thanks for joining us.`,

  space: `Welcome to our Spaces, where creativity and AI come together. Here, you'll have the chance to craft your own AI profile on the cutting-edge Web4 network.\n\nQuick Guide to Our Terms:\n<ul><li><span>Your Privacy:</span> We prioritize keeping your information safe with top-notch security.</li><li><span>Positive Vibes:</span> Respect and kindness are musts here. No negativity allowed.</li><li><span>Your Work, Your Rights:</span> Whatever you create, it stays yours.</li><li><span>Craft Your AI Persona:</span> Design an AI profile to connect, engage, and monetize your skills.</li></ul>\nYou're now part of a community aiming for a smarter, friendlier digital world.
  Thanks for joining us.`,
};

type ReferrerType = 'default' | 'space';

const WelcomePage = () => {
  const [activate, setActivate] = useState(false);
  const [progress, setProgress] = useState(0);
  const history = useHistory();
  const themeContext = useContext(ThemeContext);
  const [referrerType, setReferrerType] = useState<ReferrerType>('default');
  const messageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const newUser = localStorage.getItem('connectNewUser');
    newUser ? localStorage.removeItem('connectNewUser') : history.goForward();
  }, []);

  useEffect(() => {
    const referrer = localStorage.getItem('referrer');
    referrer && setReferrerType('space');
  }, []);

  useEffect(() => {
    const saveNeyraSettings = async (): Promise<void> => {
      const res = await getUser();
      const user = res.data;

      if (user?.settings === null) {
        try {
          await saveSettings({ body: { settings: neyraUserSettings } });
        } catch (error) {
          console.error(error);
        }
      }
    };
    saveNeyraSettings();
  }, []);

  const { animatedMessage, isPrinting } = useAnimatedMessage(
    messages[referrerType]
  );

  useEffect(() => {
    if (activate) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevProgress + 1;
        });
      }, 60);

      return () => clearInterval(interval);
    }
  }, [activate]);

  useEffect(() => {
    if (progress === 100) {
      setTimeout(() => {
        const referrer = localStorage.getItem('referrer');
        if (referrer) {
          localStorage.removeItem('referrer');
          window.location.href = referrer;
        } else {
          history.push('/chat');
        }
      }, 200);
    }
  }, [progress]);

  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [animatedMessage, isPrinting]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.border}>
        <div className={styles.cube}>
          <div className={`${styles.circle}`}></div>
        </div>
        {activate ? (
          <div className={styles.setupContainer}>
            <div className={styles.setupBlock}>
              <NeyraLogo className={styles.setupLogo} />
              <div className={styles.setupText}>
                Drive Setup is in progress, please wait...
              </div>
            </div>
            <div
              className={classNames(
                styles.setupProgress,
                progress < 50 && themeContext?.theme === DARK_THEME
                  ? styles.whiteText
                  : ''
              )}
            >
              <div
                className={styles.fill}
                style={{ width: `${progress}%` }}
              ></div>
              <span>{progress}%</span>
            </div>
          </div>
        ) : (
          <div className={styles.messageContainer}>
            <div
              className={styles.messageBlock}
              ref={messageContainerRef}
              dangerouslySetInnerHTML={{
                __html: animatedMessage,
              }}
            ></div>
            <button
              className={classNames(
                styles.activateButton,
                isPrinting && styles.hideButton
              )}
              onClick={() => {
                !isPrinting && setActivate(true);
              }}
            >
              Agree and Continue
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomePage;
