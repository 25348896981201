import * as pendingFilesFoldersActions from '../../actions/pending-files-folders.actions'

/**
 *
 * @param {IFile[]} [files=[]]
 * @returns {Function}
 */
export function pendingFilesAddEffect(files = []) {
  return (dispatch) => {
    dispatch(pendingFilesFoldersActions.pendingFilesAdd(files))
  }
}
