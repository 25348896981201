import React from 'react'

const SharedIcon = ({ color }) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip-shared-0)">
      <path
        d="M10.3583 4.18583C9.28292 4.18583 8.40723 3.3107 8.40723 2.23476C8.40723 1.15881 9.28292 0.283691 10.3583 0.283691C11.4338 0.283691 12.3094 1.15881 12.3094 2.23476C12.3094 3.3107 11.4338 4.18583 10.3583 4.18583Z"
        fill={color}
      />
      <path
        d="M10.3583 12.7703C9.28292 12.7703 8.40723 11.8952 8.40723 10.8192C8.40723 9.74329 9.28292 8.86816 10.3583 8.86816C11.4338 8.86816 12.3094 9.74329 12.3094 10.8192C12.3094 11.8952 11.4338 12.7703 10.3583 12.7703Z"
        fill={color}
      />
      <path
        d="M2.81386 8.47831C1.73839 8.47831 0.862793 7.60318 0.862793 6.52724C0.862793 5.45129 1.73839 4.57617 2.81386 4.57617C3.88933 4.57617 4.76493 5.45129 4.76493 6.52724C4.76493 7.60318 3.88933 8.47831 2.81386 8.47831Z"
        fill={color}
      />
      <path
        d="M4.17229 6.14733C4.03644 6.14733 3.9043 6.07655 3.83304 5.9507C3.72634 5.7634 3.79141 5.52505 3.97871 5.41844L8.80645 2.66608C8.99317 2.55843 9.23201 2.62398 9.33814 2.81184C9.44484 2.99914 9.37977 3.2374 9.19247 3.3441L4.36473 6.09636C4.30385 6.13066 4.23783 6.14733 4.17229 6.14733Z"
        fill={color}
      />
      <path
        d="M8.99944 10.4396C8.93389 10.4396 8.86778 10.4229 8.8069 10.3881L3.97915 7.63579C3.79186 7.52918 3.72688 7.29083 3.83349 7.10353C3.93962 6.91576 4.17797 6.85069 4.36527 6.95787L9.19292 9.71013C9.38022 9.81683 9.44529 10.0551 9.33868 10.2424C9.26685 10.3688 9.13472 10.4396 8.99944 10.4396Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip-shared-0">
        <rect
          width="12.4868"
          height="12.4868"
          fill="white"
          transform="translate(0.350586 0.283691)"
        />
      </clipPath>
    </defs>
  </svg>
)

SharedIcon.defaultProps = {
  color: '#202124',
}

export default SharedIcon
