import React from 'react'

const PictureIconDrag = ({ className, color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="32" height="32" rx="1" fill={color} />
      <path d="M12.727 16L20.9088 29.0909H4.54517L12.727 16Z" fill="white" />
      <path
        d="M22.546 22.5459L27.4551 29.0914H17.6369L22.546 22.5459Z"
        fill="white"
      />
      <ellipse
        cx="22.5447"
        cy="12.3635"
        rx="2.18182"
        ry="2.18182"
        fill="white"
      />
    </svg>
  )
}

PictureIconDrag.defaultProps = {
  color: '#C1C9D9',
}

export default PictureIconDrag
