export const GHOST_DRIVE_API = `${process.env.REACT_APP_API_PATH}`;

export const API_AUTH = `${process.env.REACT_APP_API_PATH}/auth`;
// [ Auth ] pre register
export const API_PRE_SIGN_UP_URL = `${process.env.REACT_APP_API_PATH}/auth/pre_registration`;
// [ Auth ] confirm pre registration
export const API_CONFIRM_PRE_REGISTRATION = '/auth/confirm_pre_registration';
// [ Auth ] register
export const API_SIGN_UP_URL = `${process.env.REACT_APP_API_PATH}/auth/registration`;
// [ Auth ] login
export const API_SIGN_IN_URL = `${process.env.REACT_APP_API_PATH}/auth/login_check`;
// [ Folder ] folder get list
export const API_FOLDERS = `${process.env.REACT_APP_API_PATH}/folders`;
// [ files ] files get list
export const API_GET_FILE = `${process.env.REACT_APP_API_PATH}/files`;
// [ files ] files searchs
export const API_FILES_SEARCH = `${process.env.REACT_APP_API_PATH}/files/search`;
// [ files ] save upload
export const API_SAVE_UPLOAD_FILES = `${process.env.REACT_APP_API_PATH}/files`;
// [ File ] single file
export const API_FILE = `${process.env.REACT_APP_API_PATH}/files/file`;
// [ User ] user info
export const API_USER_INFO = `${process.env.REACT_APP_API_PATH}/me`;
// [ User ] users
export const API_USERS = `${process.env.REACT_APP_API_PATH}/users`;
export const API_USER = `${process.env.REACT_APP_API_PATH}/user`;
// [ User ] user tarrif
export const API_USER_TARIFF = `${process.env.REACT_APP_API_PATH}/user/tariff`;
// [ User ] user edit
export const API_EDIT_USER_INFO = `${process.env.REACT_APP_API_PATH}/users/edit`;
// [ User ] user security add tfa
export const API_GOOGLE_TFA = `${process.env.REACT_APP_API_PATH}/google2fa`;
// [ User ] user security add pin
export const API_PIN2FA = `${process.env.REACT_APP_API_PATH}/pin2fa`;
// [ User ] user payment
export const API_PAYMENT = `${process.env.REACT_APP_API_PATH}/payment`;
// [ User ] user notifications
export const API_NOTIFICATIONS = `${process.env.REACT_APP_API_PATH}/notifications`;
// [ Tags ] all tags
export const API_TAGS = `${process.env.REACT_APP_API_PATH}/tags.json`;
// [ Colors ] all colors
export const API_COLORS = `${process.env.REACT_APP_API_PATH}/colors`;
// [ Security ] token
export const API_CHECK_SECURITY = `${process.env.REACT_APP_API_PATH}/user/security/token`;
// [ Tariffs ] tarrifs
export const API_TARIFFS = `${process.env.REACT_APP_API_PATH}/tariffs`;
// [ Team ] Teams get list
export const API_TEAMS = `${process.env.REACT_APP_API_PATH}/teams`;
// [ Trash ] Delete and Restore files/folders
export const API_TRASH = `${process.env.REACT_APP_API_PATH}/trash`;
// [ Comments ] Get all comments
export const API_COMMENTS = `${process.env.REACT_APP_API_PATH}/comments`;
// [ Actions ] Get all scheduled actions
export const API_ACTIONS = `${process.env.REACT_APP_API_PATH}/schedule/schedules`;
// [ Documents ] Documents operations
export const API_DOCUMENTS = `${process.env.REACT_APP_API_PATH}/documents`;
// [ Wiki ] Wiki operations
export const API_NOTE = `${process.env.REACT_APP_API_PATH}/wikis`;
// [ Workspace ] Workspace operations
export const API_WORKSPACE = `${process.env.REACT_APP_API_PATH}/workspace`;
// [ Public ] For public shared files
export const API_PUB = `${process.env.REACT_APP_API_PATH}/pub`;
// [ Tasks Manager ]
export const API_TASK_MANAGER = `${process.env.REACT_APP_API_PATH}/tasks`;
export const API_SIGN_IN_METAMASK = `${process.env.REACT_APP_API_PATH}/auth/login_check_metamask`;
export const API_UPLOAD_FILES_GHOST_IPFS = `${process.env.REACT_APP_API_PATH}/files/node/ipfs`;
// [ Bug Report ]
export const API_BUG_REPORT = `${process.env.REACT_APP_API_PATH}/bug/report`;
export const API_FILE_NFT_INFO = `${process.env.REACT_APP_API_PATH}/files/group`;
// [ File ] single file
export const API_FILE_MEMBER_INFO = `${process.env.REACT_APP_API_PATH}/files/member/info`;
// [ Auth ] register
export const API_SIGN_UP_METAMASK = `${process.env.REACT_APP_API_PATH}/auth/registration_metamask`;
// [ files ] save upload
export const API_UPLOAD_FILES_IPFS = `${process.env.REACT_APP_API_PATH}/files/ipfs`;
export const API_WORKSPACE_STORAGE_HISTORY = `${process.env.REACT_APP_API_PATH}/workspace/storage/history`;
// [ Promt History ]
export const API_ADD_AI_PROMT_URL = `${process.env.REACT_APP_API_PATH}/ai/history`;
export const API_GET_AI_PROMT_LIST_URL = `${process.env.REACT_APP_API_PATH}/ai/history/list`;
// [ Metamask info ]
export const API_PUB_KEY_SAVE = `${process.env.REACT_APP_API_PATH}/keys/pub_key_save`;
export const API_VAULT = `${process.env.REACT_APP_API_PATH}/files/passwords`;
export const API_REWARD_FIRST_TYPE = `${process.env.REACT_APP_API_PATH}/add/reward`;
export const API_REWARD_CLAIM = `${process.env.REACT_APP_API_PATH}/user/claim/rewards`;
export const API_REWARD_ACHIEVEMENT = `${process.env.REACT_APP_API_PATH}/user/rewards`;
export const API_TOKEN_UTILIZATION = `${process.env.REACT_APP_API_PATH}/user/subtract/points`;
// [ File conversion ]
export const API_CONVERSION_TOKEN = `${process.env.REACT_APP_API_PATH}/convert/generate/token`;
// [ Tokens ]
export const API_TOKENS = `${process.env.REACT_APP_API_PATH}/tokens`;
// [ Telegram ]
export const API_TELEGRAM = `${process.env.REACT_APP_API_PATH}/auth/telegram`;
// [ Telegram bot name ]
export const TELEGRAM_BOT_NAME = process.env.REACT_APP_TELEGRAM_BOT_NAME;
// [ Telegram bot name ]
export const NEYRA_AI_API = `${process.env.REACT_APP_NEYRA_API_BASE}/api`;
export const NEYRA_AI_API_BASE = process.env.REACT_APP_NEYRA_API_BASE;
export const NEIRA_WHISPER_API = 'https://dev.neyra.ai/api/whisper';
// [ Mobile ]
export const MOBILE_LINKING = 'ghostdrive://';
// [AI Generator]
export const API_AI_GENERATOR_STABLE_DIFFUSION =
  'https://api.stability.ai/v1/generation/stable-diffusion-v1-6/text-to-image';
export const API_AI_GENERATOR_REALTIME =
  'https://cloud.leonardo.ai/api/rest/v1/generations-lcm';
export const API_AI_GENERATOR_BLOCKADELABS =
  'https://backend.blockadelabs.com/api/v1/skybox';
