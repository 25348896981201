import axios from 'axios';
import { getAccessToken } from 'utils/auth/get-token';
import { setErrors } from 'store/errors/actions/index';
import { handleUnauthorizedError } from './handleUnauthorizedError';

import { store } from 'store/root-store';

const base = (method: string, url: string, data?: any, other?: any) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    const { headers = {}, options = {} } = other || {};
    const token = await getAccessToken();
    const requestConfig = {
      method,
      data,
      url,
      headers: {
        'X-Token': token,
        Accept: 'application/json, text/plain, */*',
        ...headers,
      },
      ...options,
    };
    if (!token) delete requestConfig.headers['X-Token'];

    try {
      const response = await axios(requestConfig);
      resolve(response);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        try {
          const refreshedResponse = await handleUnauthorizedError(
            requestConfig,
            'ghost'
          );
          resolve(refreshedResponse);
        } catch (refreshError) {
          reject(refreshError);
        }
      }
      if (error?.response?.status === 403 || error?.response?.status === 412) {
        const me = store.getState().account.userInfo.entities;
        if (
          !(
            me.is_password_access &&
            me.password_access &&
            !me.password_access?.enabled
          )
        ) {
          store.dispatch(
            setErrors(error?.response?.data, error?.response?.status)
          );
        }
      }
      reject(error);
    }
  });

const authRequest: {
  [key: string]: (url: string, data?: any, other?: any) => Promise<unknown>;
} = {};
['get', 'post', 'put', 'delete', 'patch'].forEach((method: string) => {
  authRequest[method] = (url: string, data?: any, other?: any) =>
    base(method.toUpperCase(), url, data, other);
});
export default authRequest;
