//Refactoring №3
import React, { useEffect, useState, useContext, useMemo } from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';

import { useKeyPress } from 'ahooks';
import { isObject } from 'lodash';

import { setLinkFileContent } from 'store/file/effects';
import { getPreviewObject } from 'store/home/effects/file/get-preview-file-blob';
import MainContext from 'store/main/context/main-context';
import { getFilesAdd } from 'store/home/actions/files/get-files.actions';
import { linksGetSuccess } from 'store/home/actions/links.actions';

import {
  handleLinkModal,
  selectLinkModal,
  selectAdditionalData,
  handleAdditionalData,
} from 'features/modals/modal-slice';

import CloseIcon from 'components/svg/close';
import EditFrom from 'components/LinkModal/EditForm';
import Preview from 'components/LinkModal/Preview';
import { DEFAULT_VIEW } from 'components/LinkModal/constants';

import styles from './style.module.scss';

const LinkModal = ({ files = [], links = [] }) => {
  const dispatch = useDispatch();
  const {
    state,
    state: { folder: mainFolder },
  } = useContext(MainContext);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const isOpen = useSelector(selectLinkModal);
  const additionalData = useSelector(selectAdditionalData);
  const [myVault, setMyVault] = useState(DEFAULT_VIEW);
  const [slug, setSlug] = useState('');
  const [file, setFile] = useState({});
  const folderSlug = useMemo(() => mainFolder?.slug || null, [mainFolder]);

  useEffect(() => {
    if (!slug) {
      if (additionalData?.slug) {
        setSlug(additionalData?.slug);
        const item = files.find((item) => item.slug === additionalData.slug);
        getPreviewObject({ file: item }).then((data) => {
          setMyVault(isObject(data) ? data : DEFAULT_VIEW);
          additionalData.isEdit ? setIsEdit(true) : setIsEdit(false);
        });
      } else {
        setMyVault(DEFAULT_VIEW);
        setIsEdit(true);
      }
    }
  }, [additionalData, files]);

  useEffect(() => {
    if (slug) {
      if (state.pageUrl === '/link') {
        setFile(links.find((item) => item.slug === slug));
      } else {
        setFile(files.find((item) => item.slug === slug));
      }
    }
  }, [slug, files, links, state.pageUrl]);

  useKeyPress(['escape'], () => {
    onClose();
  });

  const onClose = () => {
    dispatch(handleLinkModal({ isOpen: false }));
    dispatch(handleAdditionalData(null));
    setIsEdit(true);
    setMyVault(DEFAULT_VIEW);
    setSlug('');
  };

  const toggleView = () => {
    setIsEdit(!isEdit);
  };

  const onCreateLinkDocument = (data) => {
    setLoading(true);
    setLinkFileContent(data, slug, file, dispatch, folderSlug).then(
      (createdFile) => {
        setLoading(false);
        setSlug(createdFile.data?.slug);
        setMyVault(data);
        setIsEdit(false);
        if (state.pageUrl === '/main' && !slug) {
          dispatch(
            getFilesAdd({
              ...createdFile.data,
              folderId: folderSlug || 'main',
            })
          );
        } else if (state.pageUrl === '/links' && !slug) {
          dispatch(
            linksGetSuccess({
              data: [createdFile.data],
            })
          );
        }
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="LinkModal"
      overlayClassName={styles.overlay}
      className={styles.wrapper}
      shouldCloseOnOverlayClick
    >
      <section className={styles.wrapper}>
        <header className={styles.header}>
          <button onClick={onClose} className={styles.closeBtn}>
            <CloseIcon />
          </button>
        </header>
        {isEdit ? (
          <EditFrom
            slug={slug}
            vault={myVault}
            onCreateLinkDocument={onCreateLinkDocument}
            onClose={onClose}
            loading={loading}
          />
        ) : (
          <Preview
            toggleView={toggleView}
            vault={myVault}
            ownerId={file?.user?.id}
          />
        )}
      </section>
    </Modal>
  );
};

export default LinkModal;
