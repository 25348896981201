import * as actions from '../../actions/get-tfa.actions';
import * as activateTfaActions from '../../actions/activate-tfa.actions';
import authRequest from 'utils/request/auth-request';
import { API_GOOGLE_TFA } from 'constants/api-urls';

export function getTfaEffect() {
  return async (dispatch) => {
    dispatch(actions.getTfaData());
    dispatch(activateTfaActions.clearActivateTfaState());

    const url = API_GOOGLE_TFA;

    try {
      const result = await authRequest.get(url);
      dispatch(actions.getTfaDataSuccess(result));
    } catch (e) {
      dispatch(actions.getTfaDataFail(e));
    }
  };
}
