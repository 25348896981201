import React from 'react';

const SmallRowIcon = ({ color, className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="22" height="13" fill="none">
    <path
      fill={color}
      d="M0 12.833v-.887h22v.887H0ZM0 6.88v-.888h22v.888H0ZM0 .888V0h22v.888H0Z"
    />
  </svg>
);

SmallRowIcon.defaultProps = {
  color: 'currentColor',
};

export default SmallRowIcon;
