import React from 'react';
import style from './style.module.scss';
import Lock from '../folder/Lock';
import Button from 'components/Button';
import Header from '../header/header-container/no-auth-header';

export default function AccessDenied() {
  return (
    <section className={style.sharedByLink}>
      <Header isForbidden={true} />
      <main>
        <div className={style.sharedByLink__main}>
          <div className={style.sharedByLink__main__accessDenied}>
            <div className={style.sharedByLink__main__accessDenied__lock}>
              <Lock />
            </div>
            <span className={style.sharedByLink__main__accessDenied__boldText}>
              Access denied
            </span>
            <span className={style.sharedByLink__main__accessDenied__text}>
              Access to the current address is denied. Please make sure that the
              correct address is connected in the extension
            </span>
            <Button
              value="Retry"
              name="Retry"
              disabled={false}
              onClick={() => window.location.reload()}
              className={style.sharedByLink__main__accessDenied__button}
            >
              Retry
            </Button>
          </div>
        </div>
      </main>
    </section>
  );
}
