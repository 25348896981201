//Refactoring №3
import React, { FC, useRef, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import InputFieldFormik from 'components/InputFieldFormik';
import SelectFieldFormik from 'components/SelectFieldFormik';
import { InputFieldTheme } from 'components/InputField';
import Button, { ButtonTheme } from 'components/Button';

import { switchNetwork } from 'store/web3';
import { getNetworkIcon } from 'utils/crypto/index';
import { validationRegExp } from 'utils/validation/regexp';

import styles from './styles.module.scss';

import { INetwork, ISmartContract } from '../../interfaces';
import { getCoinbaseProvider } from 'utils/getCoinbaseProvider';

type FormData = {
  name: string;
  network: any;
};

interface CreateFormProps {
  onSubmit: (data: { name: string; network: INetwork }) => void;
  networks: ISmartContract[];
  loading: boolean;
}

type OptionType = {
  label: any;
  value: string;
  isDisabled: boolean;
};

type CallbackType = () => OptionType[];

const selectStyle = {
  control: (styles: any, state: any) => ({
    ...styles,
    height: 44,
    borderRadius: 8,
    boxShadow: 'none',
    padding: '0 15px 0 18px',
    cursor: 'pointer',
    fontFamily: "'SF Pro Text', sans-serif",
    fontSize: '14px',
    lineHeight: '20px',
    '&:hover': {
      borderColor: !state.isFocused && 'var(--inputBorder)',
    },
    color: 'var(--textColor)',
    backgroundColor: 'transparent',
    borderColor: 'var(--inputBorder)',
  }),
  valueContainer: (styles: any) => {
    return {
      ...styles,
      position: 'initial',
      padding: '0',
      color: '#fff',
    };
  },
  singleValue: (styles: any) => {
    return {
      ...styles,
      color: '#fff',
    };
  },
  option: (styles: any, state: any) => {
    return {
      ...styles,
      padding: '12px 16px',
      textAlign: 'start',
      fontFamily: "'SF Pro Text', sans-serif",
      fontSize: '14px',
      lineHeight: '20px',
      cursor: 'pointer',
      color: '#fff',
      backgroundColor:
        (state.isSelected && '#242424') || '#000',
      '&:hover': {
        backgroundColor: '#242424',
      },
    };
  },
};

const initialValues = {
  name: '',
  network: '',
};

const CreateCollection: FC<CreateFormProps> = ({
  onSubmit,
  networks,
  loading,
}) => {
  const { t } = useTranslation('owner');
  const form = useRef<HTMLFormElement | null>(null);

  const validationSchema = useMemo(
    () =>
      Yup.object({
        name: Yup.string()
          .trim()
          .matches(
            validationRegExp.collection,
            `Name can contain alphanumeric characters and space only`
          )
          .required(),
        network: Yup.number().required(),
      }),
    []
  );

  const handleSubmit = useCallback(
    (data: FormData) => {
      onSubmit({
        ...data,
        network: networks.find((item) => item.chain_id === data.network),
      });
    },
    [networks]
  );

  const renderOption = useCallback<CallbackType>(
    (): OptionType[] =>
      networks.map(({ symbol, chain_id }: INetwork) => ({
        label: (
          <div className={styles.option}>
            {getNetworkIcon(symbol)}
            <span className={styles.optionName}>{symbol}</span>
          </div>
        ),
        value: chain_id,
        isDisabled: chain_id === 420,
      })),
    [networks]
  );

  const handleValidate = useCallback(
    (value: FormData) => {
      const nextChain = value.network;
      if (nextChain !== form?.current?.values?.network) {
        const providerName = localStorage.getItem('PROVIDER');
        const currentProvider =
          providerName === 'coinbase' ? getCoinbaseProvider() : window.metamask;
        switchNetwork(
          networks.find((item) => item.chain_id === nextChain),
          currentProvider
        );
      }
    },
    [networks]
  );

  return (
    <Formik
      innerRef={form}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      {({ isValid, dirty }) => (
        <Form>
          <InputFieldFormik
            className={styles.input}
            name="name"
            label={t('rightFileMenu.item.collectionName')}
            placeholder={t('rightFileMenu.item.placeholder')}
            theme={InputFieldTheme.GET_INVITE}
            withValidation={true}
          />
          <SelectFieldFormik
            className={styles.input}
            name="network"
            label={`${t('rightFileMenu.item.network')}`}
            placeholder={`${t('rightFileMenu.item.chooseNetwork')}`}
            theme={InputFieldTheme.GET_INVITE}
            newStyles={selectStyle}
            options={renderOption()}
          />
          <Button
            theme={ButtonTheme.DARK}
            className={styles.saveButton}
            disabled={!(isValid && dirty) || loading}
            showPreloader={loading}
            type="submit"
            value={t('rightFileMenu.item.mintCollection')}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateCollection;
