//Refactoring №3
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import cn from 'classnames';

import { getAvatarFileEffect } from 'store/home/effects';

import Avatar from 'components/Avatar';

import s from './style.module.scss';
import { UPLOAD_AVATAR_TYPES } from 'config/user-role-types';

const UserIcon = ({ user, color, className, bordered, profile = false }) => {
  const [logoValue, setLogoValue] = useState('');
  const is3DAvatar = user.logo && user.logo.indexOf('.glb') !== -1;

  useEffect(() => {
    if (user.logo && !is3DAvatar) {
      getAvatarFileEffect(user?.id, UPLOAD_AVATAR_TYPES.user).then((data) => data && setLogoValue(data));
    }
    if (is3DAvatar) setLogoValue(user.logo);
  }, [user.logo]);

  const userName = user.displayed_name
    ? user.displayed_name
    : user.username
    ? user.username
    : user.email
    ? user.email
    : user.public_address;
  return (
    <div className={cn(s.userIcon, className)}>
      <Avatar
        className={s.userIcon__image}
        name={userName || ''}
        src={logoValue}
        color={user.color || color?.hex}
        bordered={bordered}
        profile={profile}
      />
    </div>
  );
};

UserIcon.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
  useFullName: PropTypes.bool,
};

UserIcon.defaultProps = {
  className: '',
  user: {},
  useFullName: false,
};

export default UserIcon;
