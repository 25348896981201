export const FILES_DRAG_ADD = 'FILES_DRAG_ADD'
export const FILES_DRAG_CLEAR = 'FILES_DRAG_CLEAR'

export function filesDragAdd(data) {
  return {
    type: FILES_DRAG_ADD,
    payload: data,
  }
}

export function filesDragClear() {
  return {
    type: FILES_DRAG_CLEAR,
  }
}
