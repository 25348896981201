// Frameworks
import * as React from 'react';

// Libs
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose as reduxCompose } from 'redux';

// Config
import securitiesTypes from 'config/securities-types';

// Components
import TfaFile from 'components/security/TfaSecurity/index';
import PinSecurity from 'components/security/PinSecurity';
import KeySecurity from 'components/security/KeySecurity';
import ClickOutside from 'components/shared/ClickOutside';
import Modal from 'react-modal';

// Store
import entityMakeActionEffect from 'store/home/effects/entity-actions/entity-make-action.effect';
import { entitySecurityCheckResetEffect } from 'store/home/effects/entity-actions/entity-security-check-reset.effect';
import { entityCheckSecurityEffect } from 'store/home/effects/entity-actions/entity-check-security.effect';

class EntitySecurityChecker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      /**@type {ISecurity[]}*/
      securities: [],
      currentStep: 0,
      stepAmount: 0,
    };

    this.securityItemSubmitHandler = this.securityItemSubmitHandler.bind(this);
    this.onCloseSecurityHandler = this.onCloseSecurityHandler.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { securitiesList, workspace_securities } = this.props;
    const workspace_length = workspace_securities?.data?.length ?? 0;
    this.setState({
      currentStep: 0,
      stepAmount: securitiesList.length + workspace_length,
    });
  }

  onCloseSecurityHandler() {
    this.props.entitySecurityCheckResetEffect();
  }

  isLastStep() {
    return this.state.currentStep + 1 === this.state.stepAmount;
  }

  nextStep() {
    this.setState({
      currentStep: this.state.currentStep + 1,
    });

    if (this.isLastStep()) {
      this.endSecurityCheck();
    }
  }

  endSecurityCheck() {
    const { history } = this.props;
    const { securities } = this.state;

    this.props.entityMakeActionEffect(securities, history);
  }

  /**
   *
   * @param {ISecurity} data
   * @param {*} component
   * @returns {Promise<void>}
   */
  async securityItemSubmitHandler(data, component) {
    let res;

    if (data.keyword !== 'voice') {
      try {
        res = await this.props.entityCheckSecurityEffect(
          data.keyword,
          data.value
        );
      } catch (e) {
        component.setState({ isValid: false });
        return;
      }
    } else {
      try {
        res = await this.props.entityCheckSecurityEffect('voice', data.value);
      } catch (e) {
        component.setState({ step: 'error' });
        return;
      }
    }
    const token = R.path(['data', 'token'], res);

    data = {
      ...data,
      value: token,
    };

    let currentSecurities = R.clone(this.state.securities);
    if (!currentSecurities?.filter((item) => item.type == data.type).length) {
      currentSecurities.push(data);

      this.setState(
        {
          securities: currentSecurities,
        },
        () => {
          this.nextStep();
        }
      );
    }
  }

  canRenderSecurityItem(currentIteration) {
    const { currentStep } = this.state;

    return currentIteration === currentStep;
  }

  /**
   *
   * @param {*} Component
   * @param {ISecurity} security
   * @param {number} index
   * @returns {*}
   */
  renderSecurityItem(Component, security, index) {
    return (
      <Component
        data={security}
        key={index}
        onSubmit={this.securityItemSubmitHandler}
        onClose={this.onCloseSecurityHandler}
      />
    );
  }

  renderSecurities() {
    const { tfa, pin, key } = securitiesTypes;

    const {
      /**@type {ISecurity[]}*/
      securitiesList,
      workspace_securities,
    } = this.props;
    const workspace_securities_data = workspace_securities?.data ?? [];
    return [...securitiesList, ...workspace_securities_data].map(
      (security, index) => {
        switch (security.type) {
          case tfa.id:
            return (
              this.canRenderSecurityItem(index) &&
              this.renderSecurityItem(TfaFile, security, index)
            );

          case pin.id:
            return (
              this.canRenderSecurityItem(index) &&
              this.renderSecurityItem(PinSecurity, security, index)
            );

          case key.id:
            return (
              this.canRenderSecurityItem(index) &&
              this.renderSecurityItem(KeySecurity, security, index)
            );
          default:
            return null;
        }
      }
    );
  }

  render() {
    const { workspace_securities } = this.props;
    const contend = (
      <div
        className={`file-security-checker ${
          workspace_securities?.data?.length > 0
            ? `modal security-${workspace_securities.data[0].keyword}`
            : 'asside-popup'
        }`}
      >
        <ClickOutside onClickOutside={this.onCloseSecurityHandler}>
          {this.renderSecurities()}
        </ClickOutside>
      </div>
    );
    const wrap = workspace_securities?.data?.length ? (
      <Modal isOpen={true} className="Modal" overlayClassName="Overlay">
        {contend}
      </Modal>
    ) : (
      contend
    );
    return wrap;
  }
}

const mapDispatchToProps = {
  entityMakeActionEffect,
  entitySecurityCheckResetEffect,
  entityCheckSecurityEffect,
};

const mapStateToProps = (state) => ({
  securitiesList: R.path(
    ['home', 'entitiesSecurityCheck', 'securitiesList'],
    state
  ),
  workspace_securities: R.path(
    ['home', 'entitiesSecurityCheck', 'workspace_securities'],
    state
  ),
});

export default reduxCompose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(EntitySecurityChecker);
