import React from 'react';

const RenameIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33627 16L8.775 14.5805H17V16H7.33627ZM3.66127 15.3481H4.4196L12.7029 7.17557L11.9446 6.42738L3.66127 14.5999V15.3481ZM14.1029 6.71515L12.4113 5.04611L13.2863 4.18282C13.4029 4.05482 13.5552 3.99405 13.7431 4.00049C13.931 4.00694 14.0899 4.07401 14.2196 4.2017L14.9779 4.93101C15.1074 5.0587 15.1721 5.2154 15.1721 5.4011C15.1721 5.5865 15.1074 5.74304 14.9779 5.87073L14.1029 6.71515ZM13.6363 7.17557L4.69167 16H3V14.331L11.9446 5.50654L13.6363 7.17557ZM12.3137 6.79158L11.9446 6.42738L12.7029 7.17557L12.3137 6.79158Z"
      fill="currentColor"
    />
  </svg>
);

RenameIcon.defaultProps = {};

export default RenameIcon;
