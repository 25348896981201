import React from 'react';

const DownloadIcon = ({ color, viewBox, width=22, height=24  }) => (
<svg viewBox={viewBox} xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none"><path fill={color} d="M4.678 19c-1.313 0-2.421-.456-3.324-1.37C.451 16.719 0 15.618 0 14.329c0-1.257.454-2.354 1.36-3.292.908-.938 2.004-1.398 3.29-1.38.132-1.463.798-2.771 1.998-3.925S9.195 4 10.688 4c.17 0 .317.052.44.155a.48.48 0 0 1 .184.38v9.062l2.665-2.674.453.479L11 14.779l-3.402-3.377.454-.479 2.636 2.674V4.59c-1.474-.019-2.755.572-3.841 1.773-1.087 1.2-1.63 2.514-1.63 3.94h-.568c-1.105 0-2.053.394-2.842 1.182-.789.788-1.183 1.745-1.183 2.87 0 1.126.397 2.078 1.19 2.857.794.779 1.751 1.168 2.872 1.168h13.77a2.82 2.82 0 0 0 2.07-.849 2.78 2.78 0 0 0 .85-2.04c0-.795-.283-1.473-.85-2.036s-1.26-.844-2.08-.844h-1.72v-2.308c0-1.032-.235-1.96-.708-2.786a5.775 5.775 0 0 0-1.871-1.998v-.732a5.586 5.586 0 0 1 2.367 2.28c.558.994.837 2.073.837 3.236v1.688h.907c1.04-.056 1.923.254 2.65.93.728.675 1.092 1.528 1.092 2.56 0 .976-.345 1.806-1.035 2.49-.69.686-1.526 1.028-2.509 1.028H4.678Z"/></svg>
);

DownloadIcon.defaultProps = {
  color: 'currentColr',
};
export default DownloadIcon;
