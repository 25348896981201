//Refactoring №3
import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import s from './style.module.scss';

export default function SpaceRow({
  className,
  title,
  subTitle,
  subTitleClass = '',
  children,
  contentAlign,
  rows,
}) {
  let alignClass;
  switch (contentAlign) {
    case false:
      alignClass = '';
      break;

    case 'start':
      alignClass = s.row__content_around;
      break;

    case 'around':
      alignClass = s.row__content_around;
      break;

    case 'end':
      alignClass = s.row__content_end;
      break;

    default:
      alignClass = s.row__content_end;
  }

  const header = (
    <div className={s.row__heder}>
      <h3 className={s.row__heder_title}>{title}</h3>
      <div className={cn(s.row__heder_subtitle, subTitleClass)}>{subTitle}</div>
    </div>
  );

  if (rows) {
    return (
      <div className={cn(s.row_list, className)}>
        {header}
        {children}
      </div>
    );
  }

  return (
    <div className={cn(s.row, className)}>
      {header}
      <div className={cn(s.row__content, alignClass)}>{children}</div>
    </div>
  );
}

SpaceRow.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.bool,
  children: PropTypes.node,
};
