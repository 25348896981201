//Refactoring №3
import React from 'react';

const AiUpscaller = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.16663 15.8334V11.7292H4.81246V15.1876H8.27079V15.8334H4.16663ZM4.16663 8.27088V4.16672H8.27079V4.81255H4.81246V8.27088H4.16663ZM11.7291 15.8334V15.1876H15.1875V11.7292H15.8333V15.8334H11.7291ZM15.1875 8.27088V4.81255H11.7291V4.16672H15.8333V8.27088H15.1875Z"
      fill="currentColor"
    />
  </svg>
);

AiUpscaller.defaultProps = {};

export default AiUpscaller;
