import React, { useMemo, useState, useEffect, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router";
import { useDispatch } from 'react-redux';
import { isEmpty } from "lodash";

import TemplateForm from "containers/main/NeyraDriveContainer/components/ContentDrive/Template";
import { handleCreateFolderModal, handleAdditionalData } from 'features/modals/modal-slice';
import { createFolderEffect } from 'store/home/effects';
import MainContext from 'store/main/context/main-context';
import updateFileEffect from 'store/home/effects/file/file-update.effect';
import Button, { BUTTON_TYPE } from "../Template/Button";
import Input from "../Template/Input";
import { IFile } from 'interfaces/file-folder';

import styles from './styles.module.scss';
import { isFile } from "utils/file";
import { cutExtention } from "utils/string";
import useNotification from 'utils/hooks/use-notification';
import getFolderId from 'utils/files-folders/get-folder-id';

type IProps = {
  entity: IFile,
  onClose: () => void,
  dispatch: any,
}

const CreateRename = ({ onClose, entity, dispatch }: IProps) => {
  const { dispatch: mainDispatch } = useContext(MainContext);
  const { addNotification } = useNotification();
  const { t } = useTranslation('contextMenu');
  const [value, setValue] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (entity) {
      setValue(cutExtention(entity.name))
    }
  }, [entity])

  const onChange = ({ target: { value : nextValue } }: React.ChangeEvent<HTMLInputElement>) => {
    setValue(nextValue);
  }

  const placeholder = useMemo(() => {
    if (isEmpty(entity))
      return t('main.createFolder.enterName');
    return ''
  }, [entity]);

  const onRename = async () => {
    dispatch(
      updateFileEffect(
        entity,
        () => {
          dispatch(handleCreateFolderModal(false));
          dispatch(handleAdditionalData(null));
        },
        null,
      {
        update: { name: `${value}.${entity.extension}` },
        updatedPropsList: ['name'],
        mainDispatch: mainDispatch,
        folder: entity,
      })
    );
}

  const onCreateFolder = () => {
      const folder = getFolderId(history.location);
      const notificationMessage = (
        <>
          <b>{t('main.createFolder.folderCreated')}</b>
          <br />
          {t('main.createFolder.tapToOpen')}
        </>
      );
      dispatch(createFolderEffect(
        value,
        folder,
        addNotification,
        notificationMessage
      )).then();
      dispatch(handleCreateFolderModal(false));
  }

  const handleAction = () => {
    if (!value.trim())
      return

    if (isEmpty(entity)) {
      onCreateFolder()
    } else {
      onRename()
    }
  }

  return (
    <div className={styles.createContainer}>
      <Input
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
      <div className={styles.footer}>
        <Button
          onClick={onClose}
          type={BUTTON_TYPE.cancel}
        >
          {t('main.createFolder.cancel')}
        </Button>
        <Button
          onClick={handleAction}
        >
          { isEmpty(entity) ? t('main.createFolder.create') : t('main.createFolder.rename')}
        </Button>
      </div>
    </div>
  )
}

const CreateRenameModal = ({ entity, ...rest }: any) => {
  const { t } = useTranslation('contextMenu');
  const dispatch = useDispatch();

  const title = useMemo(() => {
    if (entity)
      return (
        `${isFile(entity) ? 
          `${t('files.rename.rename')} ${t('files.move.file')}` :
          `${t('files.rename.rename')} ${t('files.move.folder')}`
      }`)

    return t('main.createFolder.name')
  }, [entity]);

  const onCloseModal= () => {
    dispatch(handleCreateFolderModal(false));
    dispatch(handleAdditionalData(null));
  }

  return (
    <TemplateForm
      onClose={onCloseModal}
      title={title}
    >
      <CreateRename
        {...rest}
        entity={entity}
        dispatch={dispatch}
        onClose={onCloseModal}
      />
    </TemplateForm>
  )
}

export default CreateRenameModal;