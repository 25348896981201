import * as actions from '../../actions/file/restore-file.actions'
import getErrData from 'utils/request/get-error-data'

const initialState = {
  status: false,
  err: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FILE_RESTORE:
      return {
        ...state,
        pending: true,
      }

    case actions.FILE_RESTORE_SUCCESS:
      return {
        ...state,
        status: false,
        err: {},
      }

    case actions.FILE_RESTORE_FAILURE:
      return {
        ...state,
        status: false,
        err: getErrData(action),
      }

    default:
      return state
  }
}
