//Refactoring №4
import React, { useLayoutEffect } from 'react';

const LIGHT_THEME = 'light';
const DARK_THEME = 'dark';
const DEFAULT_THEME = DARK_THEME;
 const STORAGE_KEY = 'app-theme';
const initialValue = localStorage.getItem(STORAGE_KEY) === LIGHT_THEME ? LIGHT_THEME : DEFAULT_THEME;
type ThemeContextType = {
  theme: 'light' | 'dark';
  setTheme: (theme: 'light' | 'dark') => void;
}

// Context has been created
const ThemeContext = React.createContext<ThemeContextType | undefined>(undefined);
type IProps = { children: any }

// Provider
const ThemeProvider = ({ children }: IProps ) => {
  const [theme, setTheme] = React.useState<ThemeContextType['theme']>(initialValue);

  useLayoutEffect(() => {
    document.documentElement.setAttribute(STORAGE_KEY, theme);
    localStorage.setItem(STORAGE_KEY, theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider, LIGHT_THEME, DARK_THEME, STORAGE_KEY };
