/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/no-autofocus */
import React from "react";
import CN from 'classnames';

import styles from './styles.module.scss';

const InputButton = ({
  logo,
  icon,
  name,
  text,
  onFieledFocus,
  isEditMode,
  values,
  isCurrentStep,
}) => {
  const isActive = isEditMode ? true 
    : isCurrentStep || logo || Boolean(values.name && values.city && values.date && values.time && values.description);

  const onClick = () => {
    isActive && onFieledFocus(false, name);
  }

  return (
    <div
      className={CN(
        styles.inputSidebar,
        isActive && styles.inputButtonCurrent,
      )}
      onClick={onClick}
    >
      <div className={styles.inputSidebarIcon}>
        { icon }
      </div>
      <div className={styles.inputBtnText}>
        { text }
      </div>
    </div>
  )
}

export default InputButton;