// Refactoring №3
export const PageActionTypes = {
  setCurrentPage: 'setCurrentPage',
  setDeletedPage: 'setDeletedPage',
};

export const setCurrentPage = ({
  name,
  url,
  additionalLabel,
  isDeletedPage = false,
  isActionPage = false,
  isRecentPage = false,
  isNotificationPage = false,
  isDocumentPage = false,
  isVaultPage = false,
  isLinkPage = false,
  isFilteredFilesPage = false,
  isAiGeneratorPage = false,
  isProductsPage = false,
}) => ({
  payload: {
    name,
    url,
    additionalLabel,
    isDeletedPage,
    isActionPage,
    isRecentPage,
    isNotificationPage,
    isDocumentPage,
    isVaultPage,
    isLinkPage,
    isFilteredFilesPage,
    isAiGeneratorPage,
    isProductsPage,
  },
  type: PageActionTypes.setCurrentPage,
});
