/* eslint-disable jsx-a11y/mouse-events-have-key-events */
//Refactoring №3
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonTheme } from 'components/Button';
import Tooltip from 'components/VaultModal/components/Footer/tooltip';

import styles from './style.module.scss';

const Footer = ({ onClose, isDisabled, loading }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const { t } = useTranslation('common');

  const onHover = () => {
    setShowTooltip(true);
  };

  const onLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className={styles.wrapper}>
      <Button onClick={onClose} theme={ButtonTheme.NEW_BORDERRED_DARK}>
        {t('common.cancel')}
      </Button>
      <Button
        type="submit"
        className={styles.submitBtn}
        disabled={isDisabled || loading}
        theme={ButtonTheme.NEW_BORDERRED_DARK}
      >
        <p onMouseOver={onHover} onMouseLeave={onLeave} onMouseOut={onLeave}>
          {t('common.save')}
        </p>
      </Button>
      {isDisabled && showTooltip && <Tooltip />}
    </div>
  );
};

export default Footer;
