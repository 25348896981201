//Refactoring №4
import React, { useMemo, useContext, useCallback, useEffect } from 'react';
import { getInitials } from 'utils';
import { useCreation } from 'ahooks';
import cn from 'classnames';

import { ThemeContext, LIGHT_THEME } from 'store/context/theme';

import { ReactComponent as MetamaskIcon } from 'static/assets/svg/metamask-icon.svg';
import { ReactComponent as CoinbaseIcon } from 'static/assets/svg/coinbase-wallet-icon.svg';
import { ReactComponent as UnstoppableIcon } from 'static/assets/svg/unstoppable-wallet-icon.svg';
import { ReactComponent as BorderSvg } from './assets/border.svg';

import { IUserPublicAddresses } from 'interfaces/user'

import s from './style.module.scss';

const DEFAULT_LIGHT = 'linear-gradient(86.92deg, #1A67BB 0%, #2F39C1 99.55%)';
const DEFAULT_DARK = '#FFFFFF';

type IProps = {
  className?: string,
  src?: string | null,
  name?: string,
  profile?: boolean,
  color?: string
  bordered?: boolean,
  initialsClassName?: string,
  userPublicAddresses?: IUserPublicAddresses[],
}

export default function Avatar({
  className,
  src = '',
  name = '',
  profile = false,
  color,
  bordered = false,
  initialsClassName = '',
  userPublicAddresses,
}: IProps) {
  const themeContext = useContext(ThemeContext);
  const theme = useMemo(() => (themeContext?.theme || LIGHT_THEME), [themeContext]);
  const myColor = useMemo(() => (
    color || (theme === LIGHT_THEME ? DEFAULT_LIGHT : DEFAULT_DARK)), [color, theme]);
  const isWallet = useMemo(
    () => name?.startsWith('0x') && name?.length === 42,
    [name]
  );
  const isGlb = useMemo(() => (src?.substring(src?.length - 3, src?.length) === 'glb'), [src]);
  const is3DSource = useMemo(() => (src?.includes('.glb')), [src]);

  useEffect(() => {
    if (isGlb) {
      //@ts-ignore
      import('@google/model-viewer/dist/model-viewer');
    }
  }, [isGlb]);

  const getWallet = useCallback(() => {
    const currentAddress = userPublicAddresses?.find(
      ({ public_address }) =>
        name.toLowerCase() === public_address.toLowerCase()
    );
    if (currentAddress?.is_coinbase) {
      return <CoinbaseIcon />;
    }
    if (currentAddress?.is_unstoppable) {
      return <UnstoppableIcon />;
    }
    return <MetamaskIcon />;
  }, [userPublicAddresses, name]);

  const initials = useCreation(
    () => getInitials(name, profile),
    [name, profile]
  );

  return (
    <div
      style={{
        background: bordered ? 'transparent' : myColor,
      }}
      className={cn(s.wrapper, bordered && s.bordered, className)}
    >
      {isGlb && (
        // @ts-ignore
        <model-viewer
          className={s.avatar}
          src={src}
          shadow-intensity="1"
          camera-controls
          loading="lazy"
          minimumRenderScale={1}
          ar
          ar-modes="webxr scene-viewer quick-look fallback"
          ar-scale="auto"
          remove-hands="true"
          ios-src="avatar.usdz"
          alt="Avatar"
        />
      )}
      {!isGlb && !is3DSource && src ? (
        <img className={s.image} src={src} alt={name} />
      ) : (
        !isGlb && (
          <span className={cn(s.initials, initialsClassName)}>
            {!isWallet ? initials : <div>{getWallet()}</div>}
          </span>
        )
      )}
      {bordered && <BorderSvg />}
    </div>
  );
}
