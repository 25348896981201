import React from 'react';

const ShareIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.9375 15.4165L2.25 12.729L2.771 12.2085L4.5835 14.021V6.625C4.5835 5.83333 4.86467 5.15633 5.427 4.594C5.98967 4.03133 6.67367 3.75 7.479 3.75C8.27067 3.75 8.94783 4.03133 9.5105 4.594C10.0728 5.15633 10.354 5.83333 10.354 6.625V12.521C10.354 13.146 10.5623 13.6668 10.979 14.0835C11.3957 14.5002 11.9165 14.7085 12.5415 14.7085C13.1528 14.7085 13.6668 14.5002 14.0835 14.0835C14.5002 13.6668 14.7085 13.146 14.7085 12.521V5.146L12.896 6.9585L12.375 6.4375L15.0625 3.75L17.75 6.4375L17.229 6.9585L15.4165 5.146V12.521C15.4165 13.3263 15.1353 14.0103 14.573 14.573C14.0103 15.1353 13.3332 15.4165 12.5415 15.4165C11.7362 15.4165 11.0522 15.1353 10.4895 14.573C9.92717 14.0103 9.646 13.3263 9.646 12.521V6.625C9.646 6.014 9.43767 5.50017 9.021 5.0835C8.60433 4.66683 8.09033 4.4585 7.479 4.4585C6.854 4.4585 6.33317 4.66683 5.9165 5.0835C5.49983 5.50017 5.2915 6.014 5.2915 6.625V14.021L7.104 12.2085L7.625 12.729L4.9375 15.4165Z"
      fill="currentColor"
    />
  </svg>
);

ShareIcon.defaultProps = {};

export default ShareIcon;
