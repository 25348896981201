const FORBIDDEN_CHAT_URL = [ '/chat', '/memos', '/studio', '/bios', '/tg-analyze' ];
const SHARE_BOBILE_URL = [ 'dev.ghostdrive.com', 'app.ghostdrive.com', 'beta.ghostdrive.com' ]

export const isProductPage = (): boolean => {
  return window.location.pathname === '/products'
}

export const isBiosPage = (): boolean => {
  return window.location.pathname === '/bios'
}

export const isTGAnalyzePage = (): boolean => {
  return window.location.pathname === '/tg-analyze'
}

export const canShowChatModal = (): boolean => {
  return !FORBIDDEN_CHAT_URL.some((url) => window.location.pathname.includes(url))
}

export const isShareMobileApp = (): boolean => {
  return SHARE_BOBILE_URL.some((url) => window.location.host === url)
}
