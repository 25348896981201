/**
 *
 * @param {Array} elementsArr - array of files or Folders
 * @param dispatch - dispatch function
 * @param effectFunc - effect
 * @param params - array of parameters, we want to pass into effect function
 */

import { ipfsLink } from 'utils/ipfs/link'
import { analyticsActionTypes } from 'config/analytics-action-types'
import updateAnalyticsEffect from 'store/home/effects/analytics/update-analytics.effects'
import { map } from 'lodash'
import downloadFromBlob from 'utils/download-from-blob'

const dispatchToEvery = (dispatch, elementsArr, effectFunc, params = []) => {
  return map(elementsArr, async (element) => {
    if (element.service === 'ipfs' && params.includes('download')) {
      const urlCreator = window.URL || window.webkitURL
      let file = await fetch(ipfsLink(element.key))
        .then((res) => res.blob())
        .then((blob) => urlCreator.createObjectURL(blob))
      updateAnalyticsEffect(element.slug, analyticsActionTypes.downloaded)
      downloadFromBlob(file, element.name)
    } else {
      dispatch(effectFunc(element, ...params))
    }
  })
}

export default dispatchToEvery
