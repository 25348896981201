//Refactoring №3
import React, { FC, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import CN from 'classnames';

import InputFieldFormik from 'components/InputFieldFormik';
import { ReactComponent as PlusIcon } from 'static/assets/svg/plus.svg';
import Button, { ButtonTheme } from 'components/Button';

import { MINUSE_KEY_CODE, E_KEY_CODE } from 'config/key-codes';

import styles from './styles.module.scss';
import { ISmartContract } from '../../interfaces';

type Address = {
  id: number;
  address: string;
  count: number;
};

type FormData = {
  address: Array<Address>;
};

interface MintFormProps {
  onSubmit: (data: any) => void;
  avaibleSupply: number;
  networks: ISmartContract[];
  loading: boolean;
  isUnlimitedSupply: boolean;
}

const initialValues = {
  address: [
    {
      id: Math.random(),
      address: '',
      count: '',
    },
  ],
};

const validationSchema = Yup.object({
  address: Yup.array().of(
    Yup.object({
      address: Yup.string().trim().required(),
      count: Yup.number().required(),
    })
  ),
});

const CreateForm: FC<MintFormProps> = ({
  onSubmit,
  networks,
  avaibleSupply,
  isUnlimitedSupply,
  loading,
}) => {
  const { t } = useTranslation('owner');
  const form = useRef(null);

  const handleSubmit = useCallback(
    (data: FormData) => {
      const supplysArray = data.address.map((item) => item.count);
      const addressArray = data.address.map((item) => item.address);
      onSubmit({ supplysArray, addressArray });
    },
    [networks]
  );

  const numberKeyPress = useCallback((e: KeyboardEvent) => {
    const code = e.keyCode || e.which;
    if (MINUSE_KEY_CODE === code || E_KEY_CODE === code) e.preventDefault();
  }, []);

  const everyFilter = (item) => item;

  const createAdderss = useCallback(
    (values, push) => {
      const supplysArray = values.address.map((item) => item.count);
      const addressArray = values.address.map((item) => item.address);
      const nextTotalSupply = supplysArray.reduce((prev, next) => prev + next);
      if (
        addressArray.every(everyFilter) &&
        (nextTotalSupply < avaibleSupply || isUnlimitedSupply) &&
        supplysArray.every(everyFilter)
      ) {
        push({ id: Math.random(), address: '', count: '' });
      }
    },
    [isUnlimitedSupply]
  );

  const validate = useCallback(
    (values) => {
      const supplysArray = values.address.map((item) => item.count);
      const addressArray = values.address.map((item) => item.address);
      const nextTotalSupply = supplysArray.reduce((prev, next) => prev + next);
      const errors = {};
      if (!addressArray.every(everyFilter)) {
        errors.address = t('rightFileMenu.tokenization.addressEmpty');
      }
      if (!isUnlimitedSupply && nextTotalSupply > avaibleSupply) {
        errors.address = t('rightFileMenu.tokenization.supplyLimited');
      }

      return errors;
    },
    [isUnlimitedSupply, avaibleSupply]
  );

  return (
    <div className={styles.mintForm}>
      <Formik
        innerRef={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ values, isValid, dirty }) => (
          <Form className={styles.mintFormContent}>
            <FieldArray name="address">
              {({ push }) => (
                <div>
                  <div className={styles.mintFormHeader}>
                    <h4>{t('rightFileMenu.tokenization.tokenAccess')}</h4>
                    <button
                      className={styles.mintFormAdd}
                      onClick={() => createAdderss(values, push)}
                    >
                      <PlusIcon />
                    </button>
                  </div>
                  {values.address.map((a, index) => {
                    const address = `address[${index}].address`;
                    const count = `address[${index}].count`;
                    return (
                      <div key={a.id} className={styles.mintFormRow}>
                        <InputFieldFormik
                          className={CN(
                            styles.mintFormInput,
                            styles.mintFormInputAddress
                          )}
                          name={address}
                          placeholder="0x206c3c23fb38c26032a23f15fe2"
                          withValidation={false}
                        />
                        <InputFieldFormik
                          className={CN(
                            styles.mintFormInput,
                            styles.mintFormInputCount
                          )}
                          name={count}
                          placeholder="1"
                          type="number"
                          min="0"
                          onKeyPress={numberKeyPress}
                          withValidation={false}
                        />
                      </div>
                    );
                  })}
                  <Button
                    theme={ButtonTheme.DARK}
                    className={styles.mintFormSubmit}
                    disabled={
                      !(isValid && dirty) ||
                      (!isUnlimitedSupply && avaibleSupply === 0) ||
                      loading
                    }
                    showPreloader={loading}
                    type="submit"
                    value={t('rightFileMenu.tokenization.mint')}
                  />
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateForm;
