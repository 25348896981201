import React from 'react';

const Edit = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.08477 11.2004H1.16365L4.96929 10.4058C5.04342 10.3939 5.11211 10.361 5.16646 10.3112L11.6143 4.12451C12.0853 3.67355 12.3481 3.06017 12.3439 2.42177C12.3448 1.77841 12.0828 1.16036 11.6143 0.700091C11.1443 0.248151 10.5051 -0.00400082 9.83968 9.12581e-05C9.17006 -0.0055942 8.52776 0.254597 8.06505 0.71903L1.63686 6.90568C1.58017 6.94946 1.53884 7.00889 1.51854 7.07595L0.690399 10.7274C0.667792 10.8585 0.711723 10.9921 0.80872 11.0869C0.881525 11.1582 0.980749 11.199 1.08477 11.2004ZM9.83968 0.756873C10.7871 0.756801 11.5552 1.4937 11.5553 2.40276C11.5553 2.4091 11.5552 2.41543 11.5552 2.42177C11.5616 2.85484 11.3836 3.27169 11.0622 3.57585L8.63688 1.22982C8.9564 0.925155 9.38919 0.754954 9.83968 0.756873ZM8.08475 1.77852L10.5101 4.10561L4.89037 9.47873L2.46503 7.17054L8.08475 1.77852ZM2.14954 7.92736L4.10164 9.80038L1.59742 10.3301L2.14954 7.92736Z"
      fill="currentColor"
    />
    <path
      d="M13.6056 13.2432H0.394367C0.176557 13.2432 0 13.4126 0 13.6216C0 13.8305 0.176557 13.9999 0.394367 13.9999H13.6056C13.8234 13.9999 14 13.8305 14 13.6216C14 13.4126 13.8234 13.2432 13.6056 13.2432Z"
      fill="currentColor"
    />
  </svg>
);

Edit.defaultProps = {};

export default Edit;
