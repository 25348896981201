import * as actions from '../actions/files/search.action'
import { merge, prop } from 'ramda'

const initialState = {
  status: false,
  searchData: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SEARCH_ADD_DATA:
      return merge(state, {
        status: true,
        searchData: prop('payload', action),
      })
    case actions.SEARCH_CLEAR_DATA:
      return merge(state, {
        status: false,
      })
    default:
      return state
  }
}
