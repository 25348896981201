import React, { useState } from 'react'
import { IPFS } from './ipfs'

const ipfs = new IPFS()

function Ipfs() {
  // eslint-disable-next-line no-unused-vars
  const [disableState, setDisableState] = useState(false)
  const [selectedFile, setSelectedFile] = useState()
  // eslint-disable-next-line no-unused-vars
  const [fileInfo, setFileInfo] = useState('')
  const [isFilePicked, setIsFilePicked] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isFileSend, setIsFileSend] = useState(false)

  const fileChangeHandler = (event) => {
    setSelectedFile(event.target.files[0])

    setIsFilePicked(true)
  }

  const handleSendFileClick = async () => {
    const resp = await ipfs.set(selectedFile)
    
    console.warn(resp)
    // await setFileInfo(resp)
    // setIsFileSend(true)
  }

  return (
    <div style={{ height: '100vh', background: '#000' }}>
      {isFileSend ? (
        <div className="body">
          {fileInfo}
          <div style={{ margin: '20px' }}>
            <a href={`https://ipfs.infura.io/ipfs/${fileInfo}`}>
              <button
                style={{ background: '#ccc' }}
                type="Button"
                disabled={disableState}
                className="choose-button"
              >
                Test file getting
              </button>
            </a>
          </div>
        </div>
      ) : (
        <p>Get file from</p>
      )}
      <button
        type="Button"
        disabled={!isFilePicked}
        onClick={handleSendFileClick}
        className="choose-button"
        style={{ background: '#ccc' }}
      >
        Send file to ipfs
      </button>
      <input
        style={{ background: '#ccc' }}
        type="file"
        name="file"
        onChange={fileChangeHandler}
      />
    </div>
  )
}

export default Ipfs
