import React from 'react';

const DeleteIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.91751 16.6666C5.60084 16.6666 5.32708 16.5451 5.09623 16.3021C4.86538 16.0591 4.74995 15.778 4.74995 15.4586V4.81265H3.95813V4.10415H7.04563V3.52115H11.9543V4.10415H15.0418V4.81265H14.25V15.4586C14.25 15.792 14.1379 16.0766 13.9137 16.3126C13.6891 16.5486 13.4121 16.6666 13.0824 16.6666H5.91751ZM13.5769 4.81265H5.42303V15.4586C5.42303 15.5973 5.46911 15.7153 5.56125 15.8126C5.65372 15.91 5.77247 15.9586 5.91751 15.9586H13.0824C13.2141 15.9586 13.3296 15.9065 13.4287 15.8021C13.5275 15.6981 13.5769 15.5836 13.5769 15.4586V4.81265ZM7.79803 14.2501H8.47063V6.50015H7.79803V14.2501ZM10.5293 14.2501H11.2019V6.50015H10.5293V14.2501ZM5.42303 4.81265V15.9586V15.4586V4.81265Z"
      fill="currentColor"
    />
  </svg>
);

DeleteIcon.defaultProps = {};

export default DeleteIcon;
