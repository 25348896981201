//Refactoring №3
import React from 'react';

import web3 from 'web3';

import { ReactComponent as BNBIcon } from 'static/assets/svg/crypto/bnb.svg';
import { ReactComponent as POLIcon } from 'static/assets/svg/crypto/pol.svg';
import { ReactComponent as ETHIcon } from 'static/assets/svg/crypto/eth.svg';
import { ReactComponent as FILcon } from 'static/assets/svg/crypto/fil.svg';

const chainIdNeworks = {
  bnb: 56,
  tBnb: 97,
  matic: 137,
  tMatic: 80001,
  tFil: 314159,
  FIL: 314,
};

const getCurrentChainId = async ({ useMetamask } = {}, provider) => {
  try {
    const providers = window?.ethereum?.providers;
    let currentChainId;

    if (useMetamask || !providers) {
      currentChainId = provider
        ? await provider.request({
            method: 'eth_chainId',
          })
        : await window.metamask.request({
            method: 'eth_chainId',
          });
    } else {
      currentChainId = await window.metamask.request({
        method: 'eth_chainId',
      });
    }
    if (!currentChainId) return;

    return web3.utils.hexToNumber(currentChainId);
  } catch (error) {
    return;
  }
};

const getNetworkIcon = (icon) => {
  switch (icon) {
    case 'tETH':
    case 'ETH':
      return <ETHIcon />;
    case 'tMATIC':
    case 'MATIC':
      return <POLIcon />;
    case 'tBNB':
      return <BNBIcon />;
    case 'tFIL':
    case 'FIL':
      return <FILcon />;
    default:
      return <BNBIcon />;
  }
};

// @todo use data fetched from networks/list endpoint
const getNetworkById = (chain_id) => {
  switch (chain_id) {
    case 1:
      return 'ETH';
    case 420:
      return 'tETH';
    case chainIdNeworks.tMatic:
      return 'tMATIC';
    case chainIdNeworks.matic:
      return 'MATIC';
    case chainIdNeworks.tBnb:
      return 'tBNB';
    case chainIdNeworks.bnb:
      return 'BNB';
    case chainIdNeworks.tFil:
      return 'tFIL';
    case chainIdNeworks.FIL:
      return 'FIL';
    default:
      return 'GXD';
  }
};

const isBnbNetwork = (chain_id) =>
  chain_id === chainIdNeworks.tBnb || chain_id === chainIdNeworks.bnb;
const isMaticNetwork = (chain_id) =>
  chain_id === chainIdNeworks.matic || chain_id === chainIdNeworks.tMatic;

export {
  getCurrentChainId,
  getNetworkIcon,
  getNetworkById,
  isBnbNetwork,
  isMaticNetwork,
  chainIdNeworks,
};
