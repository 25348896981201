import React from 'react'

const ArrowPrev = ({ color }) => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="72"
      width="72"
      height="72"
      transform="rotate(90 72 0)"
      fill="black"
      fillOpacity="0.5"
    />
    <rect
      x="42"
      y="24"
      width="24"
      height="14"
      transform="rotate(90 42 24)"
      fill="white"
      fillOpacity="0.01"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.3643 45.9497L39.95 47.3639L28.5861 35.9999L39.95 24.636L41.3643 26.0502L31.4145 35.9999L41.3643 45.9497Z"
      fill={color}
    />
  </svg>
)

ArrowPrev.defaultProps = {
  color: '#FFFFFF',
}
export default ArrowPrev
