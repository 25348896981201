import React  from 'react';
import { useHistory, useLocation } from 'react-router';
import CN from 'classnames';

import { ReactComponent as ArrowBack } from 'components/MemosSidebar/assets/arrow-back.svg';
import NeyraTooltip from 'components/shared/NeyraTooltip/NeyraTooltip';

import styles from './styles.module.scss';

const SidebarItem = ({ item }) => {
  const location = useLocation();
  const history = useHistory();

  const doRedirect = (e) => {
    if (item?.action) {
      item?.action(e)
    } else {
      history.push(item?.url)
    }
  };

  const onBack = (e) => {
    e.preventDefault();
    e.stopPropagation();
    item?.backOnClick(e)
  }

  return (
    <div
      className={CN(
        styles.sidebarItem,
        item.collapsed && styles.sidebarItemCollapsed,
        (location.pathname === item?.url && !item?.showCurrentRef) && styles.sidebarItemActive,
        item?.isMain && styles.sidebarItemMain,
        item?.className
      )}
      onClick={doRedirect}
    >
      <div className={styles.sidebarInfo}>
        <div className={styles.iconContainer}>{item?.icon}</div>
        <span className={styles.name}>{item?.name}</span>
      </div>
      {!!item?.count && <span className={styles.count}>{item?.count}</span>}
      {item?.backButton && !item?.count && (
        item?.tooltipText ? (
          <NeyraTooltip text={item?.tooltipText} position="bottom">
            <button onClick={onBack} className={styles.backBtn}>
              { item.backIcon ?  item.backIcon  : <ArrowBack /> }
            </button>
          </NeyraTooltip>
        ) : (
          <button onClick={onBack} className={styles.backBtn}>
            { item.backIcon ?  item.backIcon  : <ArrowBack /> }
          </button>
        ))}
    </div>
  );
};

export default SidebarItem;
