import * as actions from '../../actions/ghost-files.actions';
import authRequest from 'utils/request/auth-request';
import { API_GET_FILE } from 'constants/api-urls';

export default (
    folderId = null,
    data = {},
    mustAdd,
    modifier = '',
    cancelToken
  ) =>
  async (dispatch) => {
    dispatch(actions.ghostFileGet());
    const url = `${API_GET_FILE}${folderId ? `/` + folderId : ''}${
      modifier ? '/' + modifier : ''
    }`;

    try {
      const result = await authRequest.get(url, {
        params: {
          ghost_mode: false,
          folder: folderId,
          ...data,
        },
        cancelToken,
      });

      if (mustAdd) {
        dispatch(actions.ghostFileLoadMoreSuccess(result.data.data));
        return;
      }
      dispatch(actions.ghostFileGetSuccess(result));
    } catch (e) {
      dispatch(actions.ghostFileGetFailure());
    }
  };
