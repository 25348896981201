//Refactoring №4
import React from 'react';

import { ReactComponent as InfoIcon } from 'static/assets/svg/info.svg';

import Callout from 'components/Callout';

import cn from 'classnames';
import s from './style.module.scss';

type InfoProps = {
  className?: string;
  calloutText: string;
};

export default function Info({ className, calloutText }: InfoProps) {
  return (
    <div className={cn(s.wrapper, className)}>
      <InfoIcon className={s.icon} />
      <Callout className={s.callout}>{calloutText}</Callout>
    </div>
  );
}
