// utils
import authRequest from 'utils/request/auth-request';
import { API_USER } from 'constants/api-urls';

export function generateKeyEffect() {
  return authRequest.post(`${API_USER}/security/private_key`).then((res) => {
    return res.data;
  });
}

export function toggleSecurityKey({ value, privateKey }) {
  return authRequest
    .put(`${API_USER}/security/private_key`, {
      privateKey,
      value,
    })
    .then((res) => {
      return res.data;
    });
}
