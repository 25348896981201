import { loadStripe } from '@stripe/stripe-js/pure';

export const getDollarsFromCent = (cent) => {
  return cent ? Math.round(Number(cent) * 100) : '';
};

let stripePromise;
export const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLICK_KEY);
  }
  return stripePromise;
};
