import { ethers } from 'ethers';
import { util } from 'node-forge';

import { getUserRSAKeys } from 'gdgateway-client/lib/es5';

export const getDecryptedKey = async ({ key }: { key: string }) => {
  const provider = new ethers.providers.Web3Provider(window.metamask);
  const signer = provider.getSigner();
  const keypair = await getUserRSAKeys({ signer });
  const bytesKey = util.hexToBytes(key);
  const decryptedKey = keypair.privateKey.decrypt(bytesKey);

  return decryptedKey;
};
