import * as actions from '../actions/filtered-files.actions';
import { Entity } from 'containers/filtered-files';

export const initialState = {
  count: 0,
  files: [],
};

export default (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case actions.UPDATE_FILTERED_FILES:
      return {
        ...state,
        files: [...state.files, ...payload],
      };

    case actions.GET_FILTERED_FILES:
      return {
        ...payload,
      };

    case actions.DELETE_FILTERED_FILE:
      return {
        ...state,
        files: state.files.filter((file: Entity) => file.slug !== payload.slug),
      };

    case actions.RESET_FILTERED_FILES:
      return { ...initialState };

    default:
      return state;
  }
};
