import authRequest from 'utils/request/auth-request'

export const getGeoSecurityBySlugEffect = async (slug) => {
  const url = `${process.env.REACT_APP_API_PATH}/geo-security/country-list/${slug}`

  return authRequest
    .get(url, null)
    .then((response) => response.data)
    .catch((response) => {
      throw response
    })
}

export const addGeoSecurityEffect = async (country, entry) => {
  const url = `${process.env.REACT_APP_API_PATH}/geo-security`

  return authRequest
    .post(url, { country, entry })
    .then((response) => response.data)
    .catch((response) => {
      throw response
    })
}

export const deleteGeoLocationEffect = async (slug, country) => {
  const url = `${process.env.REACT_APP_API_PATH}/geo-security/delete`

  return authRequest
    .put(url, { slug, country })
    .then((response) => response.data)
    .catch((response) => {
      throw response
    })
}
