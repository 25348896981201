import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fields: {}
};

const slice = createSlice({
  name: 'editForm',
  initialState,
  reducers: {
    setFields(state, { payload }) {
      state.fields = { ...state.fields, ...payload };
    },
    cleanState() {
      return { ...initialState };
    },
  },
});

const { reducer, actions } = slice;

export const {
  setFields,
  cleanState,
} = actions;

export const selectFormFields = (state) => state.editForm.fields;

export default reducer;


