import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import classNames from 'classnames';

import InputFieldFormik from 'components/InputFieldFormik';

import styles from './styles.module.scss';

const initialValues = {
  email: '',
  password: '',
};

export const LoginForm = ({
  formRef,
  submitHandler,
  onFocus,
  errors,
  setForgotPassword,
}) => {
  const { t } = useTranslation('auth');
  return (
    <Formik
      innerRef={formRef}
      initialValues={initialValues}
      onSubmit={submitHandler}
    >
      <Form>
        <div className={styles.fields}>
          <div className={styles.relative}>
            <InputFieldFormik
              className={classNames(
                styles.input,
                errors.email && styles.inputError
              )}
              name="email"
              placeholder={t('common.email')}
              autoComplete="off"
              data-test="sign-in-container_email_field"
              onFocus={onFocus}
            />
            <div className={styles.errorMessage}>{errors.email}</div>
          </div>
          <div className={styles.relative}>
            <InputFieldFormik
              className={classNames(
                styles.passwordInput,
                styles.input,
                errors.password && styles.inputError
              )}
              name="password"
              placeholder={t('signup.password')}
              autoComplete="new-password"
              type="password"
              isPassword
              data-test="sign-in-container_password_field"
              onFocus={onFocus}
            />
            <div className={styles.errorMessage}>{errors.password}</div>
          </div>
        </div>
        <div className={styles.forgotPassword}>
          <a
            onClick={() => {
              setForgotPassword(true);
            }}
          >
            {t('emailLogin.forgotPassword')}
          </a>
        </div>
        <button
          type="submit"
          className={styles.login}
          data-test="sign-in-container_log_email_button"
        >
          {t('common.login')}
        </button>
      </Form>
    </Formik>
  );
};
