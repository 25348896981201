import { createContext } from 'react'

const RightMenu = createContext({
  props: {},
  setProps: () => {},
  show: false,
  toggleMenu: () => {},
})

export default RightMenu
