import React from 'react';

const EmojiIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C3.589 0 0 3.589 0 8C0 12.411 3.589 16 8 16C12.411 16 16 12.411 16 8C16 3.589 12.411 0 8 0ZM8 15C4.14 15 1 11.86 1 8C1 4.14 4.14 1 8 1C11.86 1 15 4.14 15 8C15 11.86 11.86 15 8 15Z"
      fill="currentColor"
    />
    <path
      d="M5 4.5C4.173 4.5 3.5 5.173 3.5 6C3.5 6.827 4.173 7.5 5 7.5C5.827 7.5 6.5 6.827 6.5 6C6.5 5.173 5.827 4.5 5 4.5ZM5 6.5C4.724 6.5 4.5 6.276 4.5 6C4.5 5.724 4.724 5.5 5 5.5C5.276 5.5 5.5 5.724 5.5 6C5.5 6.276 5.276 6.5 5 6.5Z"
      fill="currentColor"
    />
    <path
      d="M11 4.5C10.173 4.5 9.5 5.173 9.5 6C9.5 6.827 10.173 7.5 11 7.5C11.827 7.5 12.5 6.827 12.5 6C12.5 5.173 11.827 4.5 11 4.5ZM11 6.5C10.724 6.5 10.5 6.276 10.5 6C10.5 5.724 10.724 5.5 11 5.5C11.276 5.5 11.5 5.724 11.5 6C11.5 6.276 11.276 6.5 11 6.5Z"
      fill="currentColor"
    />
    <path
      d="M11.5361 10.8286C11.3411 10.6336 11.0241 10.6336 10.8291 10.8286C9.26912 12.3886 6.73112 12.3886 5.17212 10.8286C4.97612 10.6326 4.66012 10.6326 4.46412 10.8286C4.26912 11.0236 4.26912 11.3406 4.46412 11.5356C5.43912 12.5096 6.72012 12.9976 8.00012 12.9976C9.28012 12.9976 10.5611 12.5096 11.5361 11.5356C11.7311 11.3406 11.7311 11.0236 11.5361 10.8286Z"
      fill="currentColor"
    />
  </svg>
);

EmojiIcon.defaultProps = {};

export default EmojiIcon;
