import React, { useEffect, useReducer } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import RSC from 'react-scrollbars-custom';

import { entitiesTypes } from 'config/entities-types';
import { historyGetEffect } from 'store/history/effects';
import reducer, { defaultState, initiateState } from 'store/history/reducers';
import { loadHistory, filterByDate, resetHistory } from 'store/history/actions';
import styles from './style.module.scss';

import HistoryBlock from './history-block';

import 'components/DataPickerFormik/react-datepicker.css';

export const HistoryTab = ({ entity }) => {
  const { t } = useTranslation('owner');
  const [state, dispatch] = useReducer(reducer, defaultState, initiateState);
  const { startDate, historyBlocks, historyItems, page, count } = state;
  const { folder, file } = entitiesTypes;

  const signal = axios.CancelToken.source();
  const color = entity.color?.[entity.color.length - 1];

  const getDataHandler = (responseData) => {
    if (typeof responseData === 'object') {
      dispatch(loadHistory(responseData));
    }
  };

  useEffect(() => {
    if (entity.slug) {
      dispatch(resetHistory());
      historyGetEffect(entity.slug, signal.token, 1).then(getDataHandler);
    }

    return () => {
      dispatch(resetHistory());
      signal.cancel('commentGetEffect is being canceled');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.slug]);

  const handleDatePickerChange = (date) => {
    dispatch(filterByDate(date));
  };

  const scrollStopHandler = ({ clientHeight, scrollTop, scrollHeight }) => {
    if (
      clientHeight + scrollTop === scrollHeight &&
      historyItems.length < count
    ) {
      historyGetEffect(entity.slug, signal.token, page).then(getDataHandler);
    }
  };

  const historyItemElements = Object.keys(historyBlocks).map((item) => (
    <HistoryBlock
      historyItems={historyBlocks[item]}
      name={item}
      color={color}
      key={`history-block-${item}`}
      entityType={entity.type === 2 ? folder.type : file.type}
      entityName={entity.name}
    />
  ));

  return (
    <section className="entity-settings__history">
      <div className={styles.datePicker}>
        <DatePicker
          placeholderText={t('rightFileMenu.history.date')}
          selected={startDate}
          onChange={handleDatePickerChange}
          placeholder={t('rightFileMenu.history.date')}
          className="input"
        />
        {/* <CalendarIcon className={styles.pickerIcon} /> */}
      </div>
      <RSC
        className="entity-settings__history-owerflove"
        thumbYProps={{
          style: { background: '#206ACF', width: '1px', height: '60px' },
        }}
        trackYProps={{ style: { background: 'none', width: '1px' } }}
        onScrollStop={scrollStopHandler}
      >
        <div className="entity-settings__history-container">
          {historyItemElements}
        </div>
      </RSC>
    </section>
  );
};

export default HistoryTab;
