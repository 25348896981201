import React from 'react'

const HistoryFolder = ({ color }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="12" height="12" rx="1" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.18802 3H2V4.53198V5V8.99999H10V4.53198H6.57598L5.18802 3Z"
      fill="white"
    />
  </svg>
)

HistoryFolder.defaultProps = {
  color: '#0F73EF',
}

export default HistoryFolder
