import React from 'react';
import Tooltip from 'components/shared/Tooltip/tooltip';

export const renderFileName = (fileName, ref) => {
  const maxLength = 34;

  const sliceName = () => fileName?.slice(0, maxLength - 3).trim() + '...';
  return fileName?.length <= maxLength ? (
    fileName
  ) : (
    <>
      {sliceName()}
      <Tooltip text={fileName} className="folder-tooltip" ref={ref} />
    </>
  );
};
