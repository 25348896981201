/* eslint-disable no-unused-vars */
import React from 'react';

import SidebarInput from './input-item';
import ProgressInput from './input-progress';

import BiosInput from './input-bios';
import Progress from 'containers/auth/NeyraChat/components/ConnectRingProgress';
import InputButton from './input-button';

import { ReactComponent as PlusIcon } from './assets/plus.svg';
// import { ReactComponent as SkillsIcon } from './assets/skills.svg';
import { ReactComponent as DescIcon } from './assets/desc.svg';
import { ReactComponent as ImgIcon } from './assets/img.svg';
import { ReactComponent as DomainIcon } from './assets/domain.svg';
import { ReactComponent as VoiceIcon } from './assets/voice.svg';
import { ReactComponent as DriveIcon } from './assets/drive.svg';
import { ReactComponent as TokensIcon } from './assets/tokens.svg';
// import { ReactComponent as ProfitIcon } from './assets/profit.svg';
import { ReactComponent as SetupLogoIcon } from './assets/setupLogo.svg';

// import { componentStateList } from 'containers/auth/NeyraBios';

import styles from './styles.module.scss';

const list = [
  {
    icon: <PlusIcon />,
    placeholder: 'Avatar Name',
    name: 'name',
    type: 'input',
    step: [0],
  },
  {
    name: 'info',
    type: 'bio',
    step: [1, 2, 3],
  },
  {
    icon: <DescIcon />,
    placeholder: 'Description',
    name: 'description',
    type: 'input',
    step: [4],
  },
  {
    icon: <ImgIcon />,
    placeholder: 'Image',
    disabled: true,
    name: 'image',
    type: 'button',
    step: [5],
  },
];

const setupList = [
  {
    icon: <DomainIcon />,
    placeholder: 'Domain',
    name: 'domain',
    type: 'progress',
  },
  {
    icon: <ImgIcon />,
    name: 'images',
    type: 'progress',
    initialValue: '1,000 Images',
  },
  {
    icon: <VoiceIcon />,
    name: 'voice',
    type: 'progress',
    initialValue: '64H Voice',
  },
  {
    icon: <DriveIcon />,
    name: 'drive',
    type: 'progress',
    initialValue: '1TB Drive',
  },
  {
    icon: <TokensIcon />,
    name: 'tokens',
    type: 'progress',
    initialValue: '1M Credit',
  },
];

const AuthSidebar = ({
  logo,
  timeRef,
  setInputValue,
  values,
  setValues,
  setComponentState,
  cityList,
  onPublish,
  setCityList,
  setupState,
  isEditMode,
  onEnterPress,
  editStep,
  onFieledFocus,
  isFormValid,
}) => {
  const submitList = setupState ? setupList : list;

  const inputRender = (item, index) => {
    switch (item.type) {
      case 'bio':
        return (
          <BiosInput
            key={item.name}
            setInputValue={setInputValue}
            setValues={setValues}
            valueForm={values}
            setComponentState={setComponentState}
            timeRef={timeRef}
            cityList={cityList}
            setCityList={setCityList}
            isEditMode={isEditMode}
            onEnterPress={onEnterPress}
            isCurrentStep={item.step.includes(editStep)}
            editStep={editStep}
            onFieledFocus={onFieledFocus}
          />
        );

      case 'button':
        return (
          <InputButton
            icon={item.icon}
            text={item.placeholder}
            key={item.name}
            setInputValue={setInputValue}
            setValues={setValues}
            values={values}
            isCurrentStep={item.step.includes(editStep)}
            setComponentState={setComponentState}
            isEditMode={isEditMode}
            onFieledFocus={onFieledFocus}
            logo={logo}
            {...item}
          />
        );

      case 'progress':
        return (
          <ProgressInput
            setInputValue={setInputValue}
            setValues={setValues}
            valueForm={values[item.name]}
            isCurrentStep={editStep === index}
            editStep={editStep}
            key={item.name}
            {...item}
          />
        );

      default:
        return (
          <SidebarInput
            setInputValue={setInputValue}
            setValues={setValues}
            valueForm={values[item.name]}
            values={values}
            isCurrentStep={item.step.includes(editStep)}
            editStep={editStep}
            key={item.name}
            isEditMode={isEditMode}
            onEnterPress={onEnterPress}
            onFieledFocus={onFieledFocus}
            {...item}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.list}>{submitList.map(inputRender)}</div>
      {setupState ? (
        <div className={styles.publishBtn}>
          <div className={styles.setupBtnInfo}>
            <SetupLogoIcon />
            <p className={styles.setupBtnInfoText}>1 year pass</p>
          </div>
          <div className={styles.setupBtnPrice}>
            <p className={styles.setupBtnPriceTotal}>100K</p>
            <span className={styles.setupBtnPriceDetail}>neyra cretis</span>
          </div>
        </div>
      ) : (
        <button
          disabled={!isFormValid}
          onClick={onPublish}
          className={styles.publishBtn}
        >
          <div className={styles.publishBtnComponent}>
            <div className={styles.publishBtnCircle}></div>
            <span className={styles.publishBtnText}>Submit</span>
          </div>
          <Progress />
        </button>
      )}
    </div>
  );
};

export default AuthSidebar;
