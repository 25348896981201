// Refactoring №2
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import isExistById from 'utils/files-folders/is-exist-by-id';
import getPropBy from 'utils/array/get-prop-by';
import filesSortOptions from 'config/files-sort-options';

import FilesSkeleton from 'components/skeletons/files-skeleton';
import Checkbox, { checkboxStyle } from 'components/Checkbox';
import { FileContainer } from '../shared/file-container';
import { FolderContainer } from '../shared/folder-container';

const FolderContent = ({
  files,
  fileViewType,
  filesPending,
  folderId,
  folderPreview,
  selectedFiles,
  showFolderPreview,
  pendingFiles,
  getFilesDispatchEffect,
  fileClickDoubleClickHandler,
  previewDoubleClickHandler,
  activeFile,
  selectedFilesAdd,
  selectedFilesRemove,
  count,
  onLoadMore,
  selectAll,
  changeSelectAll,
}) => {
  const [currentFilePage, setCurrentFilePage] = useState(1);
  const filesCount = files?.length;
  const slugs = [];
  const { t } = useTranslation('owner');

  useEffect(() => {
    setCurrentFilePage(Math.ceil(Number(filesCount / 15)));
  }, [filesCount]);

  const onSelectAllFileHandler = () => {
    changeSelectAll();
  };

  const renderFileHeader = () => {
    if (fileViewType !== 'small-row') return null;
    const fileClassNames = cn({
      folder: true,
      folder_small_row: fileViewType === 'small-row',
      animated: true,
      fadeIn: true,
      header: true,
    });

    return (
      <div className={fileClassNames}>
        <div className="folder__checker">
          <Checkbox
            name="select-all"
            inputStyle={checkboxStyle.LIGHT}
            className={cn('select-item-checkbox', {
              ['select-all-checked']: selectAll,
            })}
            onClick={onSelectAllFileHandler}
            checked={selectAll}
            data-test="file-container-header_select_checkbox"
          />
        </div>
        <div className="folder__data folder__title text--11">
          {t('common.fileName')}
        </div>
        <div className="folder__created folder__title">
          {t('rightFileMenu.meta.modified')}
        </div>
        <div className="folder__created folder__title">
          {t('analytics.members')}
        </div>
        <div className="folder__created folder__title">
          {t('common.security')}
        </div>
        <div className="folder__type folder__title text--11">
          {t('common.size')}
        </div>
      </div>
    );
  };

  const renderFilePending = () => {
    return filesPending && <FilesSkeleton viewType={fileViewType} count={5} />;
  };

  const loadMore = () => {
    const nextPage = currentFilePage + 1;
    if (currentFilePage * 15 < count) {
      if (onLoadMore) {
        onLoadMore(nextPage);
      } else {
        getFilesDispatchEffect(folderId, {
          ...filesSortOptions.names.reqParams,
          page: nextPage,
        });
      }
    }
  };

  const hasMore = () => filesCount < count && !filesPending;

  const renderFolderPreview = () => {
    /**@type {boolean}*/
    const hasPending = isExistById(folderPreview, pendingFiles);
    /**@type {boolean}*/
    const isSelected = !!getPropBy(selectedFiles, 'slug', folderPreview.slug);
    if (!folderPreview.slug) return;
    return (
      <FolderContainer
        folder={folderPreview}
        viewType={fileViewType}
        canDrag={false}
        disabled={hasPending}
        isSelected={isSelected}
        canSelect
        isDragged={false}
        hideContext
        hideActions
        onForceDoubleClick={previewDoubleClickHandler}
        fileFolderActionHandlerEffect={fileClickDoubleClickHandler}
        selectedEntity={{ entity: activeFile }}
        selectedFoldersAddEffect={selectedFilesAdd}
        selectedFoldersRemoveEffect={selectedFilesRemove}
        isPublic
      />
    );
  };

  const renderFilesItems = () => {
    return files
      .filter((item) => {
        if (!slugs[item.slug]) {
          slugs[item.slug] = 1;
          return true;
        }
        return false;
      })
      .filter((file) => file.folderId === folderId)
      .map((file, index) => {
        /**@type {boolean}*/
        const hasPending = isExistById(file, pendingFiles);
        /**@type {boolean}*/
        const isSelected = !!getPropBy(selectedFiles, 'slug', file.slug);

        if (file.type === 1) {
          return (
            <FileContainer
              file={file}
              key={index}
              viewType={fileViewType}
              options={[]}
              canDrag={false}
              disabled={hasPending}
              isSelected={isSelected}
              isDragged={false}
              fileFolderActionHandlerEffect={fileClickDoubleClickHandler}
              selectedEntity={{ entity: activeFile }}
              selectedFilesAddEffect={selectedFilesAdd}
              selectedFilesRemoveEffect={selectedFilesRemove}
              isPublic
            />
          );
        }

        return (
          <FolderContainer
            folder={file}
            key={index}
            viewType={fileViewType}
            canDrag={false}
            disabled={hasPending}
            isSelected={isSelected}
            isDragged={false}
            fileFolderActionHandlerEffect={fileClickDoubleClickHandler}
            selectedEntity={{ entity: activeFile }}
            selectedFoldersAddEffect={selectedFilesAdd}
            selectedFoldersRemoveEffect={selectedFilesRemove}
            isPublic
          />
        );
      });
  };

  return (
    <InfiniteScroll
      className={cn({
        'files-container__content': fileViewType !== 'square',
        'files-container__content-square': fileViewType === 'square',
      })}
      initialLoad={true}
      pageStart={2}
      loadMore={loadMore}
      hasMore={hasMore()}
      threshold={250}
    >
      {renderFileHeader()}
      {showFolderPreview ? renderFolderPreview() : renderFilesItems()}
      {renderFilePending()}
    </InfiniteScroll>
  );
};

FolderContent.defaultProps = {
  pendingFiles: [],
  selectedFiles: [],
  folderPreview: {},
  filesPending: false,
  showFolderPreview: false,
  getFilesDispatchEffect: () => {},
  previewDoubleClickHandler: () => {},
  selectedFilesAdd: () => {},
  selectedFilesRemove: () => {},
};

export default FolderContent;
