import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { selectSidebarIsOpen } from 'features/leftMenu/leftMenuSlice';
import s from './style.module.scss';
import SearchWithHandler from '../search-field/search-with-handler';
import Breadcrumbs from '../breadcrumbs';
import actionsOptions from 'config/actions-options';
import ActionPopup from 'components/popups/action-popup';

const NotificationsHeader = () => {
  const leftMenuIsOpen = useSelector(selectSidebarIsOpen);

  const onSearchHandler = (value) => {
    
    console.warn(value);
  };

  const onActionHandler = (option) => {
    
    console.warn(option);
  };

  const optionsList = [
    actionsOptions.clearAllNotification,
    actionsOptions.goToNotificationSetting,
  ];

  return (
    <header
      className={classNames(
        'main__header',
        !leftMenuIsOpen && s.leftMenuIsClosed
      )}
    >
      <div className="main__header__actions">
        <SearchWithHandler searchHandler={onSearchHandler} />
        <div className="main__header__info main__header__info-right notification-header-actions">
          <ActionPopup
            onAction={onActionHandler}
            options={optionsList}
            closeOnClick
          />
        </div>
      </div>
      <div className="main__header__divider" />
      <div className="main__header__actions main__header__actions-bottom">
        <Breadcrumbs />
      </div>
    </header>
  );
};

NotificationsHeader.defaultParams = {
  title: '',
};
export default NotificationsHeader;
