// utils
import authRequest from 'utils/request/auth-request';
import { API_FILE } from 'constants/api-urls';

export const historyGetEffect = async (itemId, cancelToken, page = 1) => {
  const url = `${API_FILE}/${itemId}/history?page=${page}`;

  return authRequest
    .get(url, {
      cancelToken,
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((response) => {
      return response.message;
    });
};
