import React, { useReducer } from 'react'
import MainContext from 'store/main/context/main-context'
import reducer, { initiateState, defaultState } from 'store/main/reducers'

const MainContextWrapper = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState, initiateState)

  return (
    <MainContext.Provider value={{ state, dispatch }}>
      {children}
    </MainContext.Provider>
  )
}

export { MainContextWrapper }
