//Refactoring №3
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import countryList from 'react-select-country-list';

import Select from 'components/AppSelect';

import s from './style.module.scss';

function CountrySelector({
  selectedOptions,
  addNotification,
  onChange,
  ...rest
}) {
  const { t } = useTranslation('account');
  const [value, setValue] = useState('');
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    if (!selectedOptions.includes(value.label)) {
      onChange(value.value);
      setValue(value.label);
      return;
    }
    addNotification && addNotification(t('Settings.countrySelected'), 'alert');
  };

  const translatedOptions = () => {
    const regionNamesInLocalLanguage = new Intl.DisplayNames(
      [i18next.language],
      {
        type: 'region',
      }
    );

    const newOptions = options.map((option) => ({
      ...option,
      label: regionNamesInLocalLanguage.of(option.value),
    }));

    return newOptions;
  };

  return (
    <Select
      className={s.geoInput}
      label={t('Settings.addLocation')}
      placeholder={t('Settings.selectCountry')}
      options={translatedOptions()}
      value={value}
      menuPlacement="bottom"
      maxMenuHeight={150}
      onChange={changeHandler}
      {...rest}
    />
  );
}

export default CountrySelector;
