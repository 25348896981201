import jwtDecode from 'jwt-decode'
import getToken from './get-token'

const checkToken = (token) => {
  if (!token) return false
  const result = jwtDecode(token)
  const toDay = Math.floor(Date.now() / 1000)
  return result.exp > toDay
}

export default checkToken

export const validateToken = () => checkToken(getToken())
