import authRequest from 'utils/request/auth-request';
import { AxiosResponse } from 'axios';
import { handleShowEncryptionKeyModal } from 'store/home/actions/file/encrypt.action';

export const getEncryptedFileDetailsEffect = (
  file: any,
  dispatch: any
): AxiosResponse<GetEncryptedFileDetailsEffect> => {
  const url = `${process.env.REACT_APP_API_PATH}/keys/get-encrypted-file-details?slug=${file.slug}`;
  // @ts-ignore
  return authRequest.get(url).then((res) => {
    if (!res.data.count) {
      dispatch(handleShowEncryptionKeyModal(file));
    }
    return res;
  });
};

interface GetEncryptedFileDetailsEffect {
  count: number;
  data: GetEncryptedFileDetails[];
}

type GetEncryptedFileDetails = {
  id: number;
  user_public_address: {
    public_address: string;
    public_key: string;
    active: boolean;
    is_unstoppable: boolean;
    is_coinbase: boolean;
  };
  encrypted_key: string;
};
