import * as tfaActions from '../actions/activate-tfa.actions'
import getErrorData from 'utils/request/get-error-data'
import getData from 'utils/request/get-data'
import { merge } from 'ramda'

export const initialState = {
  data: null,
  error: null,
  status: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case tfaActions.ACTIVATE_TFA:
      return merge(state, {
        data: null,
        error: null,
        status: true,
      })
    case tfaActions.ClEAR_ACTIVATE_TFA:
      return merge(state, {
        data: null,
        error: null,
      })

    case tfaActions.ACTIVATE_TFA_SUCCESS:
      return merge(state, {
        data: getData(action),
        status: false,
      })

    case tfaActions.ACTIVATE_TFA_FAIL:
      return merge(state, {
        error: getErrorData(action),
        status: false,
      })

    default:
      return state
  }
}
