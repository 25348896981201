import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import GhostLoader from 'components/GhostLoader'
import ManageLink from './manageLink'
import History from './history'
import FileRename from './rename'
import CreateSharedFolder from './createSharedFolder'
import ManageShare from './manageShare'
import AddUser from './addUser'
import RemoveUser from './removeUser'
import SettingsSharedFolder from './settingsSharedFolder'
import Unshare from './unshare'
import CreateTeam from './createTeam'
import TeamInfo from './teamInfo'
import DeleteTeam from './deleteTeam'
import EditTeam from './editTeam'
import RemoveUserFromTeam from './removeUserFromTeam'
import CreateWorkspace from './createWorkspace'

const components = {
  addUser: AddUser,
  inviteToTeam: AddUser,
  removeUser: RemoveUser,
  createSharedFolder: CreateSharedFolder,
  createTeam: CreateTeam,
  deleteTeam: DeleteTeam,
  editTeam: EditTeam,
  createWorkspace: CreateWorkspace,
  history: History,
  manageLink: ManageLink,
  manageShare: ManageShare,
  edit: FileRename,
  removeUserFromTeam: RemoveUserFromTeam,
  settingsSharedFolder: SettingsSharedFolder,
  teamInfo: TeamInfo,
  unshare: Unshare,
  default: () => <></>,
}

const RightMenu = ({
  activeItem,
  option,
  showLogo,
  fileExist,
  onCloseSettings,
  hideHeader,
  notPopup,
  whiteBackground,
  ...other
}) => {
  const menuRef = useRef(null)
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && menuRef.current.offsetWidth > 0 && e.offsetX < 0) {
        onCloseSettings(e)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef, onCloseSettings])

  const { type } = option
  const MenuContent = Object.prototype.hasOwnProperty.call(components, type)
    ? components[type]
    : components.default

  const header = !hideHeader && (
    <div className="right-menu__head">
      <span className="right-menu__head__title text--32">
        <span className="right-menu__head__title__before"></span>
        {option.name}
      </span>
      <span
        className="right-menu__head__cancel-icon cancel-icon cancel-icon--big"
        onClick={onCloseSettings}
      />
    </div>
  )

  const classNames = cn({
    'right-menu': true,
    'right-menu__white': whiteBackground,
    'asside-popup': !notPopup,
  })

  const wrapperClassNames = cn({
    'right-menu__content-wrapper': true,
    'right-menu__content-wrapper-headless': hideHeader,
  })

  return (
    <section className={classNames} ref={menuRef}>
      {header}
      <div className={wrapperClassNames}>
        {showLogo && <GhostLoader />}
        {fileExist && (
          <MenuContent {...activeItem} onCancel={onCloseSettings} {...other} />
        )}
      </div>
    </section>
  )
}

RightMenu.propType = {
  fileExist: PropTypes.bool,
  showLogo: PropTypes.bool,
  onCloseSettings: PropTypes.func,
}

RightMenu.defaultProps = {
  fileExist: true,
  showLogo: false,
}

export default RightMenu
