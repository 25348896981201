//Refactoring №3
import { setCurrentWorkspace, setWorkspaces } from 'store/main/actions';

export const setWorkspacesHandler = (dispatch, list) => {
  const nextWorkspaces = list.map((item) => ({
    ...item.workspace,
    role: item.role,
  }));
  const currentWorkspaceMember = nextWorkspaces?.find(
    (workspace) => workspace?.current
  );
  if (currentWorkspaceMember) {
    dispatch(setCurrentWorkspace(currentWorkspaceMember));
    localStorage.setItem('workspace', JSON.stringify(currentWorkspaceMember));
  }
  dispatch(setWorkspaces(nextWorkspaces));
};
