//Refactoring №5
import React from 'react';

import { ReactComponent as LogoIcon } from 'containers/auth/components/Header/assets/darkLogo.svg'
import style from './style.module.scss';

const Header = () => (
  <div className={style.header}>
    <LogoIcon className={style.icon} />
  </div>
);

export default Header;
