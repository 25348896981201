import { RootStore } from 'docs/types/default';
import {
  addAudioToQueue,
  removeAudioFromQueue,
  removeMessageFromQueue,
  setAutoSpeaker,
} from 'features/speaker/speakerSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unrealSpeechStream } from 'utils/unrealSpeach';

interface TextToVoiceSpeakerProps {
  playAudio: boolean;
}

const useTextToVoiceSpeaker = ({ playAudio }: TextToVoiceSpeakerProps) => {
  const [audioPlayer, setAudioPlayer] = useState<HTMLAudioElement | null>(null);
  const [queueProcessing, setQueueProcessing] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const dispatch = useDispatch();
  const { messageQueue, audioQueue, autoSpeaker } = useSelector(
    (state: RootStore) => state.speaker
  );

  useEffect(() => {
    const speakNextMessage = async () => {
      if (autoSpeaker && messageQueue.length > 0 && !queueProcessing) {
        setQueueProcessing(true);
        const nextMessage = messageQueue[0];
        dispatch(removeMessageFromQueue());
        try {
          const outputUri = await unrealSpeechStream(nextMessage);
          dispatch(addAudioToQueue(outputUri as string));
        } catch (error) {
          console.error('Error during speech synthesis:', error);
        } finally {
          setQueueProcessing(false);
        }
      }
    };
    playAudio && speakNextMessage();
  }, [autoSpeaker, playAudio, messageQueue, queueProcessing]);

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0 && playAudio) {
      playNextAudio(audioQueue);
    }
  }, [audioQueue, isPlaying, playAudio]);

  const playNextAudio = (audioQueue: string[]) => {
    if (audioQueue.length > 0) {
      const nextAudio = audioQueue[0];
      const newAudioPlayer = new Audio(nextAudio);
      setAudioPlayer(newAudioPlayer);
      newAudioPlayer.play();
      newAudioPlayer.onended = () => {
        dispatch(removeAudioFromQueue());
        setIsPlaying(false);
      };
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    const storedAutoSpeaker = localStorage.getItem('AutoSpeaker');
    if (storedAutoSpeaker !== null) {
      dispatch(setAutoSpeaker(storedAutoSpeaker === 'true'));
    }
  }, []);

  useEffect(() => {
    if (!autoSpeaker && audioPlayer !== null && playAudio) {
      audioPlayer.pause();
      setIsPlaying(false);
    }
  }, [autoSpeaker, playAudio]);

  const handleSpeaker = () => {
    const newAutoSpeakerSetting = !autoSpeaker;
    localStorage.setItem('AutoSpeaker', newAutoSpeakerSetting.toString());
    dispatch(setAutoSpeaker(newAutoSpeakerSetting));
  };

  return { handleSpeaker, autoSpeaker };
};

export default useTextToVoiceSpeaker;
