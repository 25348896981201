import { createSlice } from '@reduxjs/toolkit';
import i18next from 'i18next';

import { editUserInfoProfileEffect } from 'store/account/effects/edit-user-info.effects';
import { updateUserInfo } from 'store/home/actions/get-user-info.actions';
import { changeUserInfo } from 'store/account/actions';
import { addNotification } from 'store/main/actions';

import { validateToken } from 'utils/auth/check-token';
import { setToCookies } from 'utils/cookies';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLogin: validateToken(),
    updating: false,
    error: false,
  },
  reducers: {
    updateStarted: (state) => {
      state.updating = true;
      state.error = false;
    },
    updateError: (state) => {
      state.error = true;
      state.updating = false;
    },
    updateSuccess: (state) => {
      state.error = false;
      state.updating = false;
    },
    login: (state) => {
      state.isLogin = true;
    },
    logout: (state) => {
      state.isLogin = false;
    },
  },
});

export default profileSlice.reducer;

// Actions
export const profileUpdateStarted = profileSlice.actions.updateStarted;
export const profileUpdateError = profileSlice.actions.updateError;
export const profileUpdateSuccess = profileSlice.actions.updateSuccess;
export const profileLogout = profileSlice.actions.logout;
export const profileLogin = profileSlice.actions.login;

export const updateProfile = (profileData) => async (dispatch, getState) => {
  dispatch(profileUpdateStarted());
  let { dateformat } = getState().account.userInfo.entities;

  const submitData = {
    dateformat,
    ...profileData,
  };
  const submitDataForEditRequest = {
    ...submitData,
    displayedName: submitData.displayed_name,
  };
  delete submitDataForEditRequest.displayed_name;

  try {
    const result = await editUserInfoProfileEffect(submitDataForEditRequest);
    setToCookies(result.token, 'access_token', '.neyratech.com');
    dispatch(changeUserInfo(submitData));
    dispatch(updateUserInfo(submitData));
    dispatch(profileUpdateSuccess());
    window.mainContextDispatch(
      addNotification({
        id: new Date().getTime(),
        message: i18next.t('account:Profile.profileSettingsUpdated'),
        type: 'success',
      })
    );
  } catch (e) {
    dispatch(profileUpdateError());
  }
};

export const resetProfile = (profileData) => updateUserInfo(profileData);

// Selectors
export const selectProfile = (state) => state.profile;
export const selectProfileLogin = (state) => state.profile.isLogin;
