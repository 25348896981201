export const FILE_UPDATE = 'FILE_UPDATE'
export const FILE_UPDATE_SUCCESS = 'FILE_UPDATE_SUCCESS'
export const FILE_UPDATE_FAILURE = 'FILE_UPDATE_FAILURE'

export function fileUpdate() {
  return {
    type: FILE_UPDATE,
  }
}

export function fileUpdateSuccess() {
  return {
    type: FILE_UPDATE_SUCCESS,
  }
}

export function fileUpdateFailure() {
  return {
    type: FILE_UPDATE_FAILURE,
  }
}
