import * as actions from '../actions/upload-file.actions';
import { propEq, when, assoc, map, find } from 'ramda';

export const initialState = {
  entities: [],
  error: null,
  status: false,
  totalProgress: 0,
  totalSize: 0,
  displayed: false,
};

const getTotalProgress = (files = []) => {
  if (files.length === 0) return 0;
  return files.reduce((progress, file) => progress + file.progress, 0);
};

const getTotalSize = (files = []) => {
  if (files.length === 0) return 0;

  return files.reduce((size, file) => size + file.size, 0);
};

export default (state = initialState, action) => {
  const file = action.payload;
  let files;
  let entities;

  switch (action.type) {
    case actions.UPLOAD_FILE:
      file.timeLeft = null;
      file.progress = 0;
      if (find((el) => el.uploadId === file.uploadId, state.entities))
        entities = map(
          when(propEq('uploadId', file.uploadId), (el) => ({
            ...el,
            error: false,
            progress: 0,
            timeLeft: null,
            source: file.source,
            folderId: file.folderId,
          })),
          file?.dataType === 'folder'
            ? state.entities
            : [...state.entities, file]
        );
      else entities = [...state.entities, file];

      return {
        ...state,
        entities,
        error: null,
        status: true,
        totalSize: getTotalSize(entities),
      };

    case actions.CHANGE_PROGRESS:
      entities = map(
        when(propEq('uploadId', file.id), assoc('progress', file.progress)),
        state.entities
      );
      return {
        ...state,
        entities,
        totalProgress: getTotalProgress(entities),
      };
    case actions.CHANGE_SECONDS_LEFT:
      entities = map(
        when(propEq('uploadId', file.id), assoc('timeLeft', file.timeLeft)),
        state.entities
      );
      return {
        ...state,
        entities,
        totalProgress: getTotalProgress(entities),
      };
    case actions.ADD_SLUG:
      entities = map(
        when(propEq('uploadId', file.uploadId), assoc('slug', file.slug)),
        state.entities
      );
      return {
        ...state,
        entities,
        totalProgress: getTotalProgress(entities),
      };
    case actions.FILTER_FILES:
      files = state.entities.filter(
        (fileUploading) => fileUploading.uploadId !== file
      );
      return {
        ...state,
        entities: files,
        totalProgress: getTotalProgress(files),
        totalSize: getTotalSize(files),
        displayed: false,
      };

    case actions.END_UPLOAD:
      return {
        ...state,
        status: false,
      };

    case actions.SET_DISPLAYED:
      return {
        ...state,
        displayed: action.payload,
      };
  
    case actions.UPLOAD_FILE_FAILURE:
      entities = map(
        when(propEq('uploadId', action.payload.file_id), assoc('error', true)),
        state.entities
      );
      return {
        ...state,
        entities: entities,
        error: action.payload.err,
        status: false,
      };

    case actions.RESET_UPLOADING_FILES:
      return { ...initialState };
    default:
      return state;
  }
};
