import React from 'react'

const FolderSmall = ({ color }) => {
  return (
    <svg
      width="46"
      height="38"
      viewBox="0 0 46 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2864_73781)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2368 3H4.44165V4.77881H4.39941V22.1473H29.8437V4.77881H14.2413L13.2368 3Z"
          fill="url(#paint0_linear_2864_73781)"
        />
      </g>
      <rect
        x="5.32446"
        y="5.8457"
        width="22.6685"
        height="2.06994"
        rx="1.03497"
        fill="white"
      />
      <g filter="url(#filter1_d_2864_73781)">
        <mask id="path-3-inside-1_2864_73781" fill="white">
          <path d="M3 6.62207H31L29.8433 22.1471H4.39906L3 6.62207Z" />
        </mask>
        <path
          d="M3 6.62207H31L29.8433 22.1471H4.39906L3 6.62207Z"
          fill="url(#paint1_linear_2864_73781)"
        />
        <path
          d="M3 6.62207V5.62207H1.90583L2.00404 6.71182L3 6.62207ZM31 6.62207L31.9972 6.69637L32.0773 5.62207H31V6.62207ZM29.8433 22.1471V23.1471H30.7716L30.8405 22.2214L29.8433 22.1471ZM4.39906 22.1471L3.4031 22.2369L3.48513 23.1471H4.39906V22.1471ZM3 7.62207H31V5.62207H3V7.62207ZM30.0028 6.54777L28.8461 22.0728L30.8405 22.2214L31.9972 6.69637L30.0028 6.54777ZM29.8433 21.1471H4.39906V23.1471H29.8433V21.1471ZM5.39503 22.0574L3.99596 6.53232L2.00404 6.71182L3.4031 22.2369L5.39503 22.0574Z"
          fill="white"
          mask="url(#path-3-inside-1_2864_73781)"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_2864_73781"
          x="1.39941"
          y="0"
          width="43.4443"
          height="37.1475"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="6" dy="6" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0520105 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2864_73781"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2864_73781"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_2864_73781"
          x="0"
          y="3.62207"
          width="46"
          height="33.5249"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="6" dy="6" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0520105 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2864_73781"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2864_73781"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2864_73781"
          x1="4.44123"
          y1="6.91174"
          x2="30.7942"
          y2="4.44114"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2864_73781"
          x1="17"
          y1="6.62207"
          x2="17"
          y2="22.1471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9DCE3" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  )
}

FolderSmall.defaultProps = {
  color: '#E9EBEF',
}

export default FolderSmall
