export const ACTIVATE_PIN = 'ACTIVATE_PIN'
export const ACTIVATE_PIN_SUCCESS = 'ACTIVATE_PIN_SUCCESS'
export const ACTIVATE_PIN_FAILURE = 'ACTIVATE_PIN_FAILURE'

export function activatePin() {
  return {
    type: ACTIVATE_PIN,
  }
}

export function activatePinSuccess(success) {
  return {
    type: ACTIVATE_PIN_SUCCESS,
    payload: success,
  }
}

export function activatePinFailure() {
  return {
    type: ACTIVATE_PIN_FAILURE,
  }
}
