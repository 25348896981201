import * as R from 'ramda'
import { updateProperties } from '../../object/updateProperties'

/**
 *
 * @param {object} obj - object with last data
 * @param {Array} array - array with objects
 * @param {string} idFinder - prop using it we will find object that we need update
 * @param {Array<string>} updatedPropsList - array of properties that should be updated
 */
export const updateObjInArray = (obj, array, idFinder, updatedPropsList) => {
  if (R.isEmpty(array) || R.isNil(array)) return array
  // clone array
  array = array.slice(0)
  const indexObjectFromArray = R.findIndex(R.propEq(idFinder, obj[idFinder]))(
    array
  )

  if (R.isEmpty(indexObjectFromArray)) return array
  const objFromArray = array[indexObjectFromArray]

  if (R.isEmpty(objFromArray) || R.isNil(objFromArray)) {
    return array
  }

  const updatedObject =
    R.isEmpty(updatedPropsList) || R.isNil(updatedPropsList)
      ? obj
      : updateProperties(obj, objFromArray, updatedPropsList)

  return R.update(indexObjectFromArray, updatedObject, array)
}

export const updateObjInArrayByUploadSlug = (obj, array, prevSlug) => {
  if (R.isEmpty(array) || R.isNil(array)) return array
  const newArray = array.slice(0);

  const indexObjectFromArray = R.findIndex(R.propEq('slug', prevSlug))(newArray);
  if (R.isEmpty(indexObjectFromArray)) return array
  const objFromArray = array[indexObjectFromArray]

  if (R.isEmpty(objFromArray) || R.isNil(objFromArray)) {
    return array
  }

  return R.update(indexObjectFromArray, obj, array)
}