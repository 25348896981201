import { imageMediaTypes } from 'config/image-file-extensions'
import { docMediaTypes } from 'config/docs-file-extensions'

const canBePrint = (entity = {}) => {
  return (
    imageMediaTypes.includes(entity?.mime) ||
    entity?.mime === 'application/pdf' ||
    docMediaTypes.includes(entity?.mime)
  )
}

export default canBePrint
