// @flow

import * as selectedFilesActions from '../../actions/selected-files-folders.actions'

type DataType = {
  entities: any,
}

export function selectedFilesAddEffect(data: DataType) {
  return (dispatch: Function) => {
    dispatch(selectedFilesActions.selectedFilesAdd(data))
  }
}
