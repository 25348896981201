// Refactoring №3
import React from 'react';
import { useTranslation } from 'react-i18next';

import Bell from 'components/svg/big-bell';

import s from './style.module.scss';

// eslint-disable-next-line react/display-name
export default () => {
  const { t } = useTranslation('popup');
  return (
    <div className={s.emptyNotification}>
      <Bell />
      <h1>{t('notification.noNotification')}</h1>
      <p>
        {t('notification.notificationText1')}
        <br />
        {t('notification.notificationText2')}
      </p>
    </div>
  );
};
