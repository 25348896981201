import React from 'react';

const Record = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.35415 12.6458L12.4791 9.49999L12.0208 9.04166L9.35415 11.7292L6.16665 8.52082H8.35415V7.87499H5.04165V11.125H5.68748V8.97916L9.35415 12.6458ZM4.06248 15.8333C3.74304 15.8333 3.46873 15.7222 3.23956 15.5C3.0104 15.2778 2.89581 15.0069 2.89581 14.6875V5.31249C2.89581 4.99305 3.0104 4.72221 3.23956 4.49999C3.46873 4.27777 3.74304 4.16666 4.06248 4.16666H13.4166C13.7361 4.16666 14.0069 4.27777 14.2291 4.49999C14.4514 4.72221 14.5625 4.99305 14.5625 5.31249V9.52082L17.1041 6.99999V12.9792L14.5625 10.4583V14.6875C14.5625 15.0069 14.4514 15.2778 14.2291 15.5C14.0069 15.7222 13.7361 15.8333 13.4166 15.8333H4.06248ZM4.06248 15.1875H13.4166C13.5694 15.1875 13.691 15.1389 13.7812 15.0417C13.8715 14.9444 13.9166 14.8264 13.9166 14.6875V5.31249C13.9166 5.1736 13.8715 5.05555 13.7812 4.95832C13.691 4.8611 13.5694 4.81249 13.4166 4.81249H4.06248C3.9097 4.81249 3.7847 4.8611 3.68748 4.95832C3.59026 5.05555 3.54165 5.1736 3.54165 5.31249V14.6875C3.54165 14.8264 3.59026 14.9444 3.68748 15.0417C3.7847 15.1389 3.9097 15.1875 4.06248 15.1875Z"
      fill="currentColor"
    />
  </svg>
);

Record.defaultProps = {};

export default Record;
