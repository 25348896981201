// New

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getCollectionContract } from 'store/web3';
import { setErrors } from 'store/errors/effects/set-error.effect';
import { loginMetamaskClear } from 'store/auth/effects';

import { useConnectWallet } from '@web3-onboard/react';
import {
  CryptoButton,
  CryptoButtonTheme,
} from 'containers/auth/components/CryptoButton';
import { signMessage } from 'containers/auth/login-crypto';
import { PreviewParams } from 'components/PreviewMonetize';
import { ReactComponent as DarkLogo } from 'static/assets/svg/neyra-logo.svg'
import { isElectron } from 'utils/isElectron';
import { isMobile } from 'utils/mobile';

import { redirectToDapp } from 'containers/auth/redirectToDapp';

import style from './style.module.scss';

type AuthWalletsPageProps = {
  match: { params: PreviewParams };
  isLogin: boolean,
  nextFlowCallback: (
    isUserHolder: number,
    selecteedPublicAddress: string,
    chainId: string
  ) => void;
};

export const AuthWalletsPage = ({
  match,
  isLogin,
  nextFlowCallback,
}: AuthWalletsPageProps) => {
  const { t } = useTranslation('auth');
  const { slug, contract_address } = match.params;
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const setSignatureError = (error: Error) => {
    setErrors(error);
  };

  useEffect(() => {
    const shadow = document.getElementsByTagName('onboard-v2')[0].shadowRoot;
    if (connecting) {
      setTimeout(() => {
        const svelteElement = shadow?.querySelector('.svelte-b3j15j') as HTMLElement;
        if (svelteElement) {
          svelteElement.style.border = '1px solid #5e5e5e';
          svelteElement.style.borderRadius = '20px';
        }
        const noticeContainer: any = shadow?.querySelector('.notice-container');
        if (noticeContainer) {
          noticeContainer.style.gridColumn = 'span 1';
        }
        if (isMobile) {
          const container: any = shadow?.querySelector(
            '.modal-container-mobile'
          );
          container.style.bottom = 'auto';
          noticeContainer.style.display = 'none';
          container.querySelector('.content').style.width =
            'calc(100vw - 40px)';
          container.querySelector('.wallets-container').style.justifyContent =
            'space-around';
          redirectToDapp(shadow, true);
        }
      }, 10);
    }
  }, [connecting]);

  useEffect(() => {
    connectWalletWithOnboard();
  }, []);

  const connectFromElectron = async () => {
    window.metamask
      .request({
        method: 'eth_requestAccounts',
        params: [],
      })
      .then(async (res: any) => {
        const provider = window.metamask;
        const contract = await getCollectionContract(
          contract_address,
          provider
        );
        const tokenId = await contract.fileToToken(slug);
        const balance = await contract.balanceOf(res[0], tokenId);

        const { address } = await signMessage(setSignatureError, provider);

        if (address) {
          window.metamask
            .request({
              method: 'eth_chainId',
              params: [],
            })
            .then((network: any) => {
              if (network) {
                nextFlowCallback(balance.toNumber(), address, network);
              }
            })
            .catch((error: any) => {
              console.warn('eth_chainId ERR', error);
            });
        }
      })

      .catch((e: any) => console.warn('request eth_chainId ERR', e));
  };

  const connectWalletWithOnboard = async () => {
    if (isElectron) {
      connectFromElectron();
    } else {
      if (wallet) {
        disconnect(wallet);
      } else {
        connect().then(async (res) => {
          if (res?.length) {
            const provider = res[0].provider;
            const { address, signature } = await signMessage(setSignatureError, provider);
            !isLogin && await loginMetamaskClear(address, signature)
            const contract = await getCollectionContract(
              contract_address,
              provider
            );
            const tokenId = await contract.fileToToken(slug);
            const balance = await contract.balanceOf(
              res[0].accounts[0].address,
              tokenId
            );

            const [wallet] = res;
            if (address)
              nextFlowCallback(
                balance.toNumber(),
                address,
                wallet.chains[0].id
              );
          }
        });
      }
    }
  };
  return (
    <div className={style.authWallets_wrapper}>
      <DarkLogo className={style.authWallets_logo} />
      <div className={style.authWallets_content}>
        <div>
          <h2>{t('signup.pleaseWait')}</h2>
          <p>
            {t('signup.logInViaCryptoWallet')}
            {`\n`}
            {t('signup.cryptoWallet')}
          </p>
        </div>
        <CryptoButton
          type={CryptoButtonTheme.white}
          cryptoType="wallet"
          onClick={connectWalletWithOnboard}
        >
          {t('common.cryptoWallet')}
        </CryptoButton>
      </div>
    </div>
  );
};
