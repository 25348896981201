/* eslint-disable no-debugger */
import { createSlice, createSelector } from '@reduxjs/toolkit';
import { changePasswordEffect } from 'store/account/effects/edit-user-info.effects';
//import { updateUserInfo } from 'store/home/actions/get-user-info.actions'
import setToken from 'utils/auth/set-token';
import authRequest from 'utils/request/auth-request';
import { API_WORKSPACE } from 'constants/api-urls';

const workspaceSettings = createSlice({
  name: 'workspaceSettings',
  initialState: {
    data: {
      updating: false,
      error: false,
      success: true,
    },
    password: {
      updating: false,
      error: false,
      success: false,
    },
    multisigUsers: [],
    fileCountByType: {},
  },
  reducers: {
    updateStarted: (state) => {
      state.data.updating = true;
      state.data.error = false;
      state.data.success = false;
    },
    updateError: (state) => {
      state.data.error = true;
      state.data.updating = false;
      state.data.success = false;
    },
    updateSuccess: (state) => {
      state.data.success = true;
      state.data.updating = false;
      state.data.error = false;
    },

    passwordUpdateStarted: (state) => {
      state.password.updating = true;
      state.password.error = false;
      state.password.success = false;
    },
    passwordUpdateError: (state) => {
      state.password.error = true;
      state.password.updating = false;
      state.password.success = false;
    },
    passwordUpdateSuccess: (state) => {
      state.password.error = false;
      state.password.updating = false;
      state.password.success = true;
    },
    resetWorkspaceSettingsPasswordState: (state) => {
      state.password.error = false;
      state.password.updating = false;
      state.password.success = false;
    },
    setMultisigUsers: (state, action) => {
      state.multisigUsers = action.payload
    },
    resetMultisigUsers: (state, action) => {
      state.multisigUsers = action.payload
    },
    setFileCountByType: (state, action) => {
      state.fileCountByType = action.payload
    },
    resetFileCountByType: (state) => {
      state.fileCountByType = {}
    },
  },
});

export default workspaceSettings.reducer;

// Actions
export const workspaceUpdateStarted = workspaceSettings.actions.updateStarted;
export const setMultisigUsers = workspaceSettings.actions.setMultisigUsers;
export const resetMultisigUsers = workspaceSettings.actions.resetMultisigUsers;
export const workspaceUpdateSuccess = workspaceSettings.actions.updateSuccess;
export const workspaceUpdateError = workspaceSettings.actions.updateError;
export const workspaceSetFileCountByType = workspaceSettings.actions.setFileCountByType;
export const workspaceResetFileCountByType = workspaceSettings.actions.resetFileCountByType;

export const updateWorkspaceSettings = (workspace) => async (dispatch) => {
  dispatch(workspaceUpdateStarted());
  try {
    const { data } = await authRequest.put(API_WORKSPACE, workspace);
    dispatch(workspaceUpdateSuccess());
    return data.data;
  } catch (e) {
    dispatch(workspaceUpdateError());
    throw e;
  }
};

export const passwordUpdateStarted =
  workspaceSettings.actions.passwordUpdateStarted;
export const passwordUpdateError =
  workspaceSettings.actions.passwordUpdateError;
export const passwordUpdateSuccess =
  workspaceSettings.actions.passwordUpdateSuccess;
export const resetWorkspaceSettingsPasswordState =
  workspaceSettings.actions.resetWorkspaceSettingsPasswordState;

export const updatePassword =
  (oldPassword, newPassword, repeatNewPassword) => async (dispatch) => {
    dispatch(passwordUpdateStarted());
    try {
      const data = await changePasswordEffect(
        oldPassword,
        newPassword,
        repeatNewPassword
      );
      setToken({ data });
      dispatch(passwordUpdateSuccess());
    } catch (e) {
      dispatch(passwordUpdateError());
    }
  };

// Selectors
export const selectWorkspaceSettings = createSelector(
  (state) => state.workspaceSettings.data.error,
  (state) => state.workspaceSettings.data.success,
  (state) => state.workspaceSettings.data.updating,
  (error, success, updating) => ({ error, success, updating })
);
export const selectWorkspaceSettingsPassword = createSelector(
  (state) => state.workspaceSettings.password.error,
  (state) => state.workspaceSettings.password.success,
  (state) => state.workspaceSettings.password.updating,
  (error, success, updating) => ({ error, success, updating })
);
export const selectMultisigUsers = (store) => store.workspaceSettings.multisigUsers
