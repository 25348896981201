//Refactoring №5
/* eslint-disable no-constant-condition */
import React, { useEffect,  useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import { FormikProps, FormikValues } from 'formik/dist/types'
import moment from 'moment';
import { isEqual } from 'lodash';

import TitleInput from 'components/VaultModal/components/Title';
import Input from 'components/VaultModal/components/Input';
import AddField from 'components/VaultModal/components/AddField';
import Tags from 'components/VaultModal/components/Tags';
import Footer from 'components/VaultModal/components/Footer';
import DeleteModal from 'components/VaultModal/components/DeleteModal';

import { validationRegExp } from 'utils/validation/regexp';
import {
  INITIAL_FIELDS,
  VAULT_DATA_FORMAT,
  MAX_TITLE_LENGTH,
  VaultItemType,
  FieldItemType
} from 'components/VaultModal/constants';

import styles from './styles.module.scss';

type IProps = {
  onCreateVaultDocument: (data: VaultItemType) => void,
  onClose: () => void,
  vault: VaultItemType,
  slug?: string,
  loading: boolean, 
}

type ObjectType = {
  [name: string]: string
}

const EditForm = ({ onCreateVaultDocument, onClose, vault, slug, loading }: IProps) => {
  const { t } = useTranslation('contextMenu');
  const formRef = useRef<FormikProps<FormikValues>>(null);
  const scrollRef = useRef<HTMLInputElement>(null);
  const [passwordName, setPasswordName] = useState('');
  const [fields, setFields] = useState<FieldItemType[]>(INITIAL_FIELDS);
  const [isDeleteOpen, setIsDeleteOpen] = useState('');
  const [tags, setTags] = useState<string[]>([]);
  const [iconValue, setIconValue] = useState('');

  useEffect(() => {
    if (slug) {
      const firstLinkField = getFirstLinkField(vault.fields);
      firstLinkField && setIconValue(firstLinkField.value);
      const initialValue = vault.fields.reduce((accumulator, currentValue) => {
        const value = currentValue.value;

        if (currentValue.type === 'date')
          return {
            ...accumulator,
            [currentValue.name]: currentValue.value
              ? moment(value, 'DD-MM-YYYY')
              : null,
          };

        return {
          ...accumulator,
          [currentValue.name]: value,
        };
      }, {});

      formRef?.current?.setValues(initialValue);
      setFields(vault.fields);
      setPasswordName(vault.passwordName);
      setTags(vault.tags);
    }
  }, [slug, vault]);

  const onDeleteField = useCallback((selectedName: string) => {
    setIsDeleteOpen(selectedName);
  }, []);

  const deleteField = useCallback((selectedName: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { [selectedName]: value, ...restValues } =
      formRef?.current?.values || {};
    setFields(fields.filter(({ name }) => selectedName !== name));
    formRef?.current?.setFieldValue(selectedName, '');
  }, [ formRef, fields]);

  const isNameNusy =  useCallback((name: string) => {
    return fields.some((field) => field.name === name);
  },[fields]);

  const getFirstLinkField = useCallback((fields: FieldItemType[]): FieldItemType | undefined => {
    return fields.find((item) => item.type === 'website');
  }, []);

  const onIconFieldChange = useCallback((value: string) => {
    setIconValue(value);
  }, []);

  const getFieldName = useCallback((name: string) => {
    let index = 0;

    while (true) {
      if (isNameNusy(index === 0 ? name : `${name}${index}`)) {
        ++index;
      } else {
        break;
      }
    }

    return `${name}${index !== 0 ? index : ''}`;
  }, []);

  const onAddField = useCallback((field: FieldItemType) => {
    if (fields.length === 20) return;

    const nextField = {
      label: field.name,
      type: field.type,
      name: getFieldName(field.name),
      value: ''
    };

    setFields([...fields, nextField]);

    setTimeout(() => {
      scrollRef?.current?.scrollTo(0, scrollRef?.current?.scrollHeight);
    }, 100);
  }, [scrollRef]);

  const handleSubmit = useCallback(() => {
    const formValues = formRef?.current?.values || {};

    onCreateVaultDocument({
      passwordName: passwordName || t('main.addPassword.DEFAULT_PASSWORD_NAME'),
      fields: fields.map((field) => {
        if (field.type === 'date' && formValues[field.name])
          return {
            ...field,
            value:
              moment(formValues[field.name]).format(VAULT_DATA_FORMAT) || '',
          };
        return { ...field, value: formValues[field.name] || '' };
      }),
      tags,
    });
  }, [formRef, passwordName, fields]);

  const checkTitleValid = useCallback((title: string) => {
    if (title.length >= MAX_TITLE_LENGTH && title.length !== 0) {
      return false;
    } else {
      return true;
    }
  }, []);

  const changePasswordName = useCallback((newName = '') => {
    newName.length < MAX_TITLE_LENGTH && setPasswordName(newName);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsDeleteOpen('');
  }, []);

  const isFistWeblink = useCallback((name: string) => {
    const firstWeblinkField = getFirstLinkField(fields) || {name: ''};

    return firstWeblinkField.name === name;
  }, [fields]);

  const validate = useCallback((values: {[name: string]: string}) => {
    const errors:ObjectType = {};
    fields.forEach((field) => {
      const value = values[field.name];
      if (field.type === 'website') {
        if (value?.length && !validationRegExp.isLink.test(value))
          errors[field.name] = t('main.addPassword.invalidWebsite');
      } else if (field.type === 'text') {
        if (value?.length > 32)
          errors[field.name] = t('main.addPassword.maxLength');
      }
    });

    return errors;
  }, [fields]);

  const onLabelChange = useCallback((label: string, id: string) => {
    setFields(
      fields.map((item) => (item.name === id ? { ...item, label } : item))
    );
  }, [fields]);

  const isLabelChnage = useCallback((isValid: boolean, currentValues: {[name: string]: string}) => {
    const currnetLabels = fields.map((item) => item.label);
    const initialLabels = vault.fields.map((item) => item.label);
    const initialValues = vault.fields.map((item) => item.value);
    const isCurrnetLabelsEmepy = !Object.values(currnetLabels).some(
      (value) => value
    );
    const currnetNames = fields
      .filter((field) => currnetLabels.includes(field.label))
      .map((filed) => filed.name);

    return (
      (isValid && isCurrnetLabelsEmepy) ||
      (isValid &&
        !isCurrnetLabelsEmepy &&
        !Object.values(currnetNames).some((label) => currentValues[label])) ||
      (isValid &&
        !isCurrnetLabelsEmepy &&
        isEqual(currnetLabels, initialLabels) &&
        isEqual(currentValues, initialValues))
    );
  }, [fields, vault]);

  return (
    <div className={styles.body}>
      <TitleInput
        iconValue={iconValue}
        passwordName={passwordName}
        onChange={changePasswordName}
        placeholder={t('main.addPassword.DEFAULT_PASSWORD_NAME')}
      />
      <Formik
        innerRef={formRef}
        initialValues={{}}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ dirty, isValid, values }) => {
          const isDisabled =
            !checkTitleValid(passwordName) ||
            !dirty ||
            (dirty && !isValid) ||
            isLabelChnage(isValid, values);
          return (
            <Form className={styles.form}>
              <div ref={scrollRef} className={styles.formContainer}>
                {fields.map(({ label, type, name }) => (
                  <Input
                    isFirstWebLink={isFistWeblink(name)}
                    key={name}
                    className={styles.fieldItem}
                    label={t(`main.addPassword.${label}`)}
                    deleteField={onDeleteField}
                    type={type}
                    name={t(`main.addPassword.${name}`)}
                    onLabelChange={onLabelChange}
                    onWebLinkChange={onIconFieldChange}
                  />
                ))}
              </div>
              <AddField onAddField={onAddField} />
              <Tags tags={tags} setTags={setTags} />
              <Footer
                loading={loading}
                isDisabled={isDisabled}
                onClose={onClose}
              />
            </Form>
          );
        }}
      </Formik>
      <div id="vaultForm"></div>
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={handleCloseModal}
        deleteField={deleteField}
      />
    </div>
  );
};

export default EditForm;
