import axios from 'axios'

// TODO CREATE INTERCEPTOR AFTER WILL BE REMOVED CORS
export default () => {
  axios.interceptors.request.use(
    function (response) {
      return response
    },
    function (error) {
      return Promise.reject(error)
    }
  )
}
