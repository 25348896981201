const UPLOAD_AVATAR_TYPES = {
  user: 1,
  workspace: 2,
}

export default {
  OWNER: 'ROLE_OWNER',
  MEMBER: 'ROLE_MEMBER',
  GUEST: 'ROLE_GUEST',
}

export { UPLOAD_AVATAR_TYPES }