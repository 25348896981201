// Refactoring №2
import React from 'react';
import { Portal } from 'react-portal';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

import { length } from 'ramda';

import { filterFiles } from 'store/home/actions/upload-file.actions';
import {
  handleCancelUploadModal,
  selectCancelUploadModal,
} from 'features/modals/modal-slice';
import { cancelAllOngoingUploading } from 'utils/files-folders/file-upload';
import { hideBodyOverflow, showBodyOverflow } from 'utils/actions/body-style';

import FileView, { transformSize } from './FileView';
import CancelUploadModal from './CancelUploadModal';

import { ReactComponent as Dot } from './dot.svg';
import ArrowIcon from 'components/svg/triangle';
import CloseIcon from 'components/svg/closeSmall';

class MultyUploadDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      isOpened: window.screen.width > 480,
      totalProgress: 0,
      speed: 0,
      scrollTop: 0,
      tickCount: [0, 0, 0, 0, 0],
      timeout: null,
    };

    this.getFilesSize = this.getFilesSize.bind(this);
  }

  componentDidMount() {
    const timeout = setInterval(() => {
      this.setState({
        speed: this.state.tickCount.reduce((sum, item) => sum + item, 0) / 5,
        totalProgress: this.props.totalProgress,
        tickCount: this.state.tickCount.map((el, index, arr) =>
          index < 4
            ? arr[index + 1]
            : this.props.totalProgress - this.state.totalProgress
        ),
      });
      if (this.props.totalProgress > this.props.totalSize) {
        clearInterval(this.state.timeout);
      }
    }, 1000);
    this.setState({
      timeout,
    });
  }

  getFilesSize(files) {
    try {
      const sizeFiles = files
        .map((el) => el.size)
        .reduce((sum, size) => sum + size);
      return transformSize(sizeFiles);
    } catch (e) {
      return transformSize(0);
    }
  }

  render() {
    const {
      totalProgress,
      totalSize,
      uploadingFiles,
      filterFiles,
      history,
      t,
      handleCancelUploadModal,
      isOpenCancelUploadModal,
      dispatch,
      needEncryptExistingFileState,
    } = this.props;
    const onClose = () => {
      showBodyOverflow();
    };

    const width = window.screen.width > 480 ? 447 : window.screen.width;
    if (length(uploadingFiles) <= 0) {
      return <></>;
    }

    const uploadingFilesCount = length(uploadingFiles);

    const handleClose = () => {
      const canceledUploads = uploadingFiles.reduce((progress, file) => {
        let newProgress;
        if (file?.source?.token?.reason || file?.error) {
          newProgress = file.size - file.progress;
        } else {
          newProgress = 0;
        }
        return progress + newProgress;
      }, 0);

      const newTotalProgress = totalProgress + canceledUploads;

      if (newTotalProgress < totalSize) handleCancelUploadModal(true);
      else {
        onClose && onClose();
        uploadingFiles.map((file) => filterFiles(file.uploadId));
      }
    };

    const continueUpload = () => {
      showBodyOverflow();
      handleCancelUploadModal(false);
    };

    const cancelUpload = () => {
      showBodyOverflow();
      handleCancelUploadModal(false);
      cancelAllOngoingUploading(dispatch);
    };

    const encryptionTitle =
      uploadingFilesCount > 1
        ? t('uploadDetails.encryptMultiple', {
            uploadingFilesCount,
          })
        : t('uploadDetails.encrypt1Object');
    const uploadingTitle =
      uploadingFilesCount > 1
        ? t('uploadDetails.uploadMultiple', {
            uploadingFilesCount,
          })
        : t('uploadDetails.upload1Object');

    return (
      <Portal>
        <section
          onMouseEnter={hideBodyOverflow}
          onMouseLeave={showBodyOverflow}
          className="multy-upload-details animated fadeIn"
        >
          {isOpenCancelUploadModal && (
            <CancelUploadModal
              isOpen={isOpenCancelUploadModal}
              onCancel={cancelUpload}
              onContinue={continueUpload}
            />
          )}

          <div className="download">
            <div className="download__info ">
              <div className="download__file text--16 text--bold">
                {needEncryptExistingFileState ? (
                  <span>{encryptionTitle}</span>
                ) : (
                  <span>{uploadingTitle}</span>
                )}
              </div>

              <div className="download__buttons">
                <button
                  className="download__buttons__button"
                  onClick={() => {
                    showBodyOverflow();
                    this.setState({ isOpened: !this.state.isOpened });
                  }}
                >
                  <ArrowIcon rotate={this.state.isOpened ? 0 : 180} />
                </button>
                <button
                  className="download__buttons__button"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </button>
              </div>
            </div>
            {!this.state.isOpened &&
              (totalProgress < totalSize ? (
                <div className="download__status">
                  <span className="download__file_size text--12 text--medium">
                    {`${t('uploadDetails.totalSize')} ${this.getFilesSize(
                      uploadingFiles
                    )}`}
                  </span>

                  <div>
                    <span className="download__status__incompete text--12">
                      {Number((totalProgress / totalSize) * 100).toFixed()}%
                    </span>

                    <Dot />
                  </div>
                </div>
              ) : (
                <span className="download__status__complete text--12">
                  {t('uploadDetails.completed')}
                </span>
              ))}
            {!this.state.isOpened && (
              <div
                className="download__upload"
                style={{
                  width: `${Number(
                    (this.state.totalProgress / totalSize) * width
                  ).toFixed()}px`,
                }}
              >
                <div className="download__upload__bar"></div>
              </div>
            )}
            <div
              onScroll={(e) => {
                let element = e.target;
                this.setState({ scrollTop: element.scrollTop ?? 0 });
              }}
              className={`download__container ${
                !this.state.isOpened && 'isClosed'
              }`}
            >
              {uploadingFiles.map((el, i) => {
                const encryptError =
                  this.props.encryptExistingFileError &&
                  el?.slug === this.props.encryptExistingFileError;
                return (
                  <FileView
                    file={el}
                    key={el.uploadId || el.folderId + el.name + i}
                    history={history}
                    speed={this.state.speed}
                    scrollTop={this.state.scrollTop}
                    existingEncryption={this.props.needEncryptExistingFile}
                    encryptExistingFileError={encryptError}
                  />
                );
              })}
            </div>
          </div>
        </section>
      </Portal>
    );
  }
}

const mapStateToProps = (state) => ({
  uploadingFiles: state.home?.uploadFiles?.entities,
  totalProgress: state.home?.uploadFiles?.totalProgress,
  totalSize: state.home?.uploadFiles?.totalSize,
  encryptExistingFileError: state?.home?.encrypt?.encryptExistingFileError,
  needEncryptExistingFileState: state?.home?.encrypt?.encryptionStatus,
  isOpenCancelUploadModal: selectCancelUploadModal(state),
});
const mapDispatchToProps = {
  filterFiles: filterFiles,
  handleCancelUploadModal: handleCancelUploadModal,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation('popup')
)(MultyUploadDetails);
