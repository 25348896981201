// Refactoring №3
import React from 'react';

export const Icon = ({ type }: { type: string }) => {
  switch (type) {

    case 'add':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
          <mask id="addIcon" width="21" height="21" x="0" y="0" maskUnits="userSpaceOnUse" style={{maskType:"alpha"}}>
            <path fill="#D9D9D9" d="M.5.5h20v20H.5z"/>
          </mask>
          <g mask="url(#addIcon)">
            <path fill="currentColor" d="M10.084 10.917H5.917a.403.403 0 0 1-.297-.12.404.404 0 0 1-.12-.298c0-.118.04-.217.12-.296.08-.08.18-.12.297-.12h4.167V5.917c0-.118.04-.217.12-.297.08-.08.179-.12.297-.12s.217.04.297.12c.08.08.12.179.12.297v4.166h4.166c.118 0 .217.04.297.12.08.08.12.18.12.298s-.04.217-.12.296a.404.404 0 0 1-.297.12h-4.167v4.166c0 .118-.04.217-.12.297-.08.08-.179.12-.297.12a.402.402 0 0 1-.297-.12.404.404 0 0 1-.12-.297v-4.166Z"/>
          </g>
        </svg>

      );

    case 'search': {
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
          <mask id="searchIcon" width="21" height="21" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: "alpha"}}>
            <path fill="currentColor" d="M.5.5h20v20H.5z"/>
          </mask>
          <g mask="url(#searchIcon)">
            <path fill="currentColor" d="m16.342 16.794-4.947-4.947a4.735 4.735 0 0 1-1.371.751c-.497.174-.994.26-1.49.26-1.211 0-2.24-.423-3.088-1.27-.849-.848-1.273-1.877-1.273-3.086 0-1.209.424-2.238 1.272-3.087.847-.85 1.876-1.274 3.085-1.274s2.238.424 3.087 1.272c.85.848 1.274 1.877 1.274 3.087 0 .53-.092 1.042-.276 1.54a4.78 4.78 0 0 1-.735 1.322l4.947 4.947-.485.485Zm-7.81-4.602c1.037 0 1.911-.356 2.624-1.069.712-.713 1.069-1.587 1.069-2.623 0-1.037-.357-1.911-1.07-2.624-.712-.712-1.586-1.069-2.623-1.069-1.036 0-1.91.357-2.623 1.07C5.196 6.588 4.84 7.462 4.84 8.5c0 1.036.356 1.91 1.069 2.623.713.713 1.587 1.069 2.623 1.069Z"/>
            </g>
        </svg>
      )
    }

    case 'folder':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
          <mask id="folderIcon" width="21" height="21" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: "alpha"}}>
            <path fill="#D9D9D9" d="M.5.5h20v20H.5z"/>
          </mask>
          <g mask="url(#folderIcon)">
            <path fill="currentColor" d="M4.347 16.334c-.384 0-.704-.129-.961-.386a1.304 1.304 0 0 1-.386-.96V6.013c0-.383.129-.704.386-.96.257-.258.577-.386.96-.386h3.592c.18 0 .354.036.523.109.17.073.315.17.435.29l1.268 1.268h6.49c.384 0 .704.128.961.385s.386.577.386.96v7.309c0 .383-.129.703-.386.96a1.304 1.304 0 0 1-.96.386H4.346Zm0-.834h12.307c.15 0 .273-.048.369-.144a.5.5 0 0 0 .144-.368V7.68a.499.499 0 0 0-.144-.369.5.5 0 0 0-.369-.144H9.83L8.307 5.645a.465.465 0 0 0-.353-.145H4.347a.5.5 0 0 0-.369.145.5.5 0 0 0-.144.368v8.975a.5.5 0 0 0 .144.368.499.499 0 0 0 .369.144Zm8.237-3.75V13c0 .119.04.218.12.298.079.08.178.119.296.119.12 0 .218-.04.298-.12.08-.079.12-.178.12-.297v-1.25h1.25c.118 0 .217-.04.296-.12.08-.079.12-.178.12-.296a.404.404 0 0 0-.12-.298.403.403 0 0 0-.297-.119h-1.25v-1.25a.403.403 0 0 0-.12-.297.404.404 0 0 0-.296-.12.404.404 0 0 0-.298.12.403.403 0 0 0-.12.297v1.25h-1.25a.403.403 0 0 0-.296.12.404.404 0 0 0-.12.297c0 .118.04.217.12.297.08.08.178.12.297.12h1.25Z"/>
          </g>
        </svg>
      );
    case 'password':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
          <mask id="passwordIcon" width="21" height="21" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: "alpha"}}>
            <path fill="#D9D9D9" d="M.5.5h20v20H.5z"/>
          </mask>
          <g mask="url(#passwordIcon)">
            <path fill="currentColor" d="M10.5 16.393c-.818.818-1.8 1.227-2.945 1.228-1.145 0-2.127-.408-2.944-1.226-.818-.817-1.228-1.8-1.229-2.947-.001-1.147.407-2.13 1.226-2.948a4.132 4.132 0 0 1 2.099-1.126 3.945 3.945 0 0 1 2.37.192l5.186-5.185c.261-.262.579-.393.951-.393.373 0 .69.131.952.393l.454.453c.261.262.392.58.392.952 0 .373-.13.69-.392.952l-.227.226.816.816c.262.262.393.58.393.952 0 .373-.131.69-.393.952l-.453.453a1.296 1.296 0 0 1-.952.393c-.373 0-.69-.13-.952-.393l-.816-.816-2.602 2.602c.307.754.371 1.544.193 2.37a4.132 4.132 0 0 1-1.127 2.1Zm-.589-.59c.648-.648.97-1.367.965-2.158-.005-.79-.16-1.416-.464-1.878l3.624-3.624 1.405 1.405a.5.5 0 0 0 .363.159.5.5 0 0 0 .362-.159l.454-.453a.5.5 0 0 0 .158-.363.5.5 0 0 0-.158-.362l-1.406-1.406.816-.815a.499.499 0 0 0 .159-.363.499.499 0 0 0-.159-.363l-.453-.453a.499.499 0 0 0-.363-.159.499.499 0 0 0-.362.16l-5.618 5.617c-.462-.304-1.088-.459-1.879-.464-.79-.005-1.51.317-2.158.965a3.21 3.21 0 0 0-.972 2.357c0 .924.324 1.71.972 2.357a3.21 3.21 0 0 0 2.357.973 3.21 3.21 0 0 0 2.357-.973ZM8.28 14.171c.2-.199.3-.44.3-.725 0-.284-.1-.525-.3-.725-.199-.2-.44-.3-.725-.3-.284 0-.526.1-.725.3-.2.2-.3.441-.3.725s.1.526.3.726c.2.199.441.299.725.299s.526-.1.725-.3Z"/>
          </g>
        </svg>
      );
    case 'document':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
          <mask id="documentIcon" width="21" height="21" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: "alpha" }}>
            <path fill="#D9D9D9" d="M.5.5h20v20H.5z"/>
          </mask>
          <g mask="url(#documentIcon)">
            <path fill="currentColor" d="M11.879 17.327v-.894c0-.09.015-.174.046-.251a.679.679 0 0 1 .155-.225l4.243-4.221a.867.867 0 0 1 .256-.16.735.735 0 0 1 .545.003.7.7 0 0 1 .248.161l.771.787c.068.083.12.168.156.257a.706.706 0 0 1 .003.534.858.858 0 0 1-.159.259l-4.221 4.221a.68.68 0 0 1-.225.155.668.668 0 0 1-.251.047h-.894a.653.653 0 0 1-.673-.673Zm.737-.064h.791l2.89-2.894-.375-.412-.396-.391-2.91 2.905v.792ZM6.013 18c-.383 0-.704-.128-.96-.385a1.304 1.304 0 0 1-.386-.961V4.346c0-.383.128-.704.385-.96C5.31 3.127 5.63 3 6.013 3h6.011c.18 0 .354.036.524.109.169.073.314.17.435.29l2.952 2.952c.12.12.217.266.29.435.072.17.109.344.109.523v1.604H15.5V7.167h-2.66a.653.653 0 0 1-.673-.673v-2.66H6.013a.49.49 0 0 0-.352.16.49.49 0 0 0-.16.352v12.308a.49.49 0 0 0 .16.352.49.49 0 0 0 .352.16h3.878V18H6.013Zm9.909-4.043-.396-.391.77.803-.374-.412Z"/>
          </g>
        </svg>
      );
    case 'link':
      return (
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
        <mask id="linkIcon" width="21" height="21" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: "alpha"}}>
          <path fill="currentColor" d="M.5.5h20v20H.5z"/>
        </mask>
        <g mask="url(#linkIcon)">
          <path fill="currentColor" d="M10.002 15.804A3.275 3.275 0 0 1 7.6 16.8a3.273 3.273 0 0 1-2.402-.997 3.276 3.276 0 0 1-.997-2.402c0-.937.332-1.738.996-2.403l1.79-1.79a.403.403 0 0 1 .295-.126c.113 0 .212.042.295.126a.402.402 0 0 1 .125.295c0 .112-.042.21-.125.294L5.785 11.59A2.467 2.467 0 0 0 5.04 13.4c0 .71.249 1.315.748 1.813a2.47 2.47 0 0 0 1.813.748c.71 0 1.314-.248 1.811-.746l1.792-1.792a.404.404 0 0 1 .295-.125c.113 0 .211.042.295.125a.402.402 0 0 1 .125.295c0 .113-.042.21-.126.294l-1.79 1.79Zm-.975-3.241a.403.403 0 0 1-.294.125.404.404 0 0 1-.295-.126.402.402 0 0 1-.125-.294c0-.113.042-.211.125-.294l3.536-3.536a.403.403 0 0 1 .294-.125c.114 0 .212.042.295.125a.402.402 0 0 1 .125.295c0 .113-.042.21-.125.294l-3.536 3.536Zm4.987-.77a.403.403 0 0 1-.295.124.404.404 0 0 1-.295-.125.402.402 0 0 1-.125-.295c0-.112.042-.21.125-.294l1.792-1.792a2.467 2.467 0 0 0 .747-1.812c0-.71-.25-1.314-.748-1.813a2.469 2.469 0 0 0-1.813-.748c-.71 0-1.314.25-1.812.747L9.798 7.577a.403.403 0 0 1-.295.125.404.404 0 0 1-.295-.126.402.402 0 0 1-.125-.294c0-.113.042-.211.126-.294l1.79-1.79a3.275 3.275 0 0 1 2.402-.998c.937 0 1.738.332 2.402.996.665.665.997 1.466.997 2.403s-.332 1.738-.996 2.403l-1.79 1.79Z"/>
        </g>
      </svg>
    );

    case 'download':
      return (
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none">
        <mask id="downloadIcon" width="21" height="20" x="0" y="0" maskUnits="userSpaceOnUse" style={{ maskType: "alpha" }}>
          <path fill="#D9D9D9" d="M.5 0h20v20H.5z"/>
        </mask>
        <g mask="url(#downloadIcon)">
          <path fill="currentColor" d="m10.5 13.425-3.028-3.028L8 9.904l2.137 2.136V3.833h.725v8.207L13 9.904l.528.493-3.027 3.028Zm-4.346 2.741c-.368 0-.672-.122-.914-.366a1.25 1.25 0 0 1-.363-.917v-1.11h.725v1.11c0 .14.059.267.175.384a.534.534 0 0 0 .384.174h8.678c.139 0 .267-.058.383-.175a.534.534 0 0 0 .175-.383v-1.11h.725v1.11c0 .367-.122.673-.366.917-.245.244-.55.366-.918.366H6.154Z"/>
        </g>
      </svg>
    );
  
    case 'delete':
      return (
      <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none">
        <path fill="currentColor" d="M6.729 16.667c-.334 0-.622-.122-.865-.365a1.158 1.158 0 0 1-.364-.843V4.813h-.834v-.709h3.25v-.583h5.167v.583h3.25v.709H15.5v10.646c0 .333-.118.618-.354.854a1.19 1.19 0 0 1-.876.354H6.73ZM14.79 4.813H6.208v10.646c0 .139.049.257.146.354a.508.508 0 0 0 .375.146h7.542a.5.5 0 0 0 .364-.156.477.477 0 0 0 .156-.344V4.813Zm-6.083 9.438h.708V6.5h-.708v7.75Zm2.875 0h.708V6.5h-.708v7.75ZM6.208 4.813v11.146V4.813Z"/>
      </svg>
    );
      
    case 'share':
      return (
        <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.9375 15.4165L2.25 12.729L2.771 12.2085L4.5835 14.021V6.625C4.5835 5.83333 4.86467 5.15633 5.427 4.594C5.98967 4.03133 6.67367 3.75 7.479 3.75C8.27067 3.75 8.94783 4.03133 9.5105 4.594C10.0728 5.15633 10.354 5.83333 10.354 6.625V12.521C10.354 13.146 10.5623 13.6668 10.979 14.0835C11.3957 14.5002 11.9165 14.7085 12.5415 14.7085C13.1528 14.7085 13.6668 14.5002 14.0835 14.0835C14.5002 13.6668 14.7085 13.146 14.7085 12.521V5.146L12.896 6.9585L12.375 6.4375L15.0625 3.75L17.75 6.4375L17.229 6.9585L15.4165 5.146V12.521C15.4165 13.3263 15.1353 14.0103 14.573 14.573C14.0103 15.1353 13.3332 15.4165 12.5415 15.4165C11.7362 15.4165 11.0522 15.1353 10.4895 14.573C9.92717 14.0103 9.646 13.3263 9.646 12.521V6.625C9.646 6.014 9.43767 5.50017 9.021 5.0835C8.60433 4.66683 8.09033 4.4585 7.479 4.4585C6.854 4.4585 6.33317 4.66683 5.9165 5.0835C5.49983 5.50017 5.2915 6.014 5.2915 6.625V14.021L7.104 12.2085L7.625 12.729L4.9375 15.4165Z"
          fill="currentColor"
        />
      </svg>
    );


    case 'restore':
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip-restore-icon-0)">
            <path
              d="M13.2688 13.6595C10.1481 16.7802 5.07048 16.7802 1.9498 13.6595C1.74151 13.4512 1.74151 13.1135 1.9498 12.9052C2.15805 12.697 2.49576 12.697 2.70405 12.9052C5.4088 15.61 9.80983 15.61 12.5145 12.9052C15.2193 10.2005 15.2193 5.79952 12.5145 3.09474C9.93661 0.516829 5.8178 0.395954 3.09539 2.73208L3.51358 3.15027C3.8038 3.44042 3.67017 3.93658 3.27448 4.04255L1.06255 4.63523C0.666046 4.74136 0.303296 4.3777 0.409327 3.98202L1.00201 1.77008C1.1088 1.37155 1.60589 1.24249 1.8943 1.53098L2.33964 1.97633C5.48461 -0.778641 10.2785 -0.649828 13.2688 2.34045C16.3895 5.46108 16.3895 10.5388 13.2688 13.6595ZM1.7933 2.93845L1.67876 3.36577L2.10611 3.25127L1.7933 2.93845Z"
              fill='currentColor'
            />
            <path
              d="M9.64965 7.84027H7.90615V4.86968C7.90615 4.57509 7.6674 4.3363 7.37281 4.3363C7.07824 4.3363 6.83946 4.57512 6.83946 4.86965V8.37362C6.83946 8.66818 7.07827 8.90696 7.37281 8.90696H9.64965C9.94421 8.90696 10.183 8.66815 10.183 8.37362C10.183 8.07909 9.94418 7.84027 9.64965 7.84027Z"
              fill='currentColor'
            />
          </g>
          <defs>
            <clipPath id="clip-restore-icon-0">
              <rect width="16" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );

      case 'tokenization':
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <mask
              id="mask0_1872_10854"
              style={{ maskType: "alpha" }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="18"
              height="18"
            >
              <rect width="18" height="18" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_1872_10854)">
              <path
                d="M9 15.1034C8.83054 15.1034 8.6676 15.0708 8.51117 15.0056C8.35475 14.9404 8.21788 14.8361 8.10056 14.6927L2.27374 7.69274C2.1825 7.58846 2.11406 7.4744 2.06844 7.35056C2.02281 7.22672 2 7.09311 2 6.94972C2 6.85847 2.01304 6.77049 2.03911 6.68576C2.06518 6.60103 2.09777 6.51304 2.13687 6.42179L3.50559 3.64525C3.60987 3.44972 3.75652 3.2933 3.94553 3.17598C4.13454 3.05866 4.33985 3 4.56145 3H13.4385C13.6601 3 13.8655 3.05866 14.0545 3.17598C14.2435 3.2933 14.3901 3.44972 14.4944 3.64525L15.8631 6.42179C15.9022 6.51304 15.9348 6.60103 15.9609 6.68576C15.987 6.77049 16 6.85847 16 6.94972C16 7.09311 15.9772 7.22672 15.9316 7.35056C15.8859 7.4744 15.8175 7.58846 15.7263 7.69274L9.89944 14.6927C9.78212 14.8361 9.64525 14.9404 9.48883 15.0056C9.3324 15.0708 9.16946 15.1034 9 15.1034ZM6.32123 6.91062H11.6788L9.99721 3.54749H8.00279L6.32123 6.91062ZM8.72626 14.595V7.4581H2.80168L8.72626 14.595ZM9.27374 14.595L15.1983 7.4581H9.27374V14.595ZM12.2849 6.91062H15.4916L13.986 3.89944C13.9339 3.79516 13.8557 3.71043 13.7514 3.64525C13.6471 3.58007 13.5363 3.54749 13.419 3.54749H10.6034L12.2849 6.91062ZM2.50838 6.91062H5.71508L7.39665 3.54749H4.58101C4.46369 3.54749 4.35289 3.58007 4.2486 3.64525C4.14432 3.71043 4.06611 3.79516 4.01397 3.89944L2.50838 6.91062Z"
                fill="currentColor"
              />
            </g>
          </svg>
        );

    default:
      return <span />;
  }
};
