//Refactoring №3
import React, { useState, useRef, useEffect } from 'react';

import CN from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as CopyIcon } from 'static/assets/svg/copy-button.svg';
import { ReactComponent as SuccessCycleIcon } from 'static/assets/svg/success-cycle.svg';

import s from './style.module.scss';

const CopyButton = ({ className, copyIcon, onClick, timeDelay = 2000, ...restProps }) => {
  const [isCopy, setCopy] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const onCopy = () => {
    setCopy(true);
    onClick();

    setTimeout(() => {
      setCopy(false);
    }, timeDelay);
  };

  return (
    <button
      type="button"
      onClick={onCopy}
      className={CN(s.copyButton, className)}
      {...restProps}
    >
      {isCopy ? <SuccessCycleIcon /> : (copyIcon ? copyIcon : <CopyIcon /> )}
    </button>
  );
};

CopyButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CopyButton.defaultProps = {
  className: '',
  onClick: () => {},
  disabled: false,
};

export default CopyButton;
