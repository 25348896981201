//Refactoring №3
import React from 'react';
import { useHistory } from 'react-router';
import CN from 'classnames';

import { ReactComponent as SettingsIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/settings.svg'
import ThemeToggle from 'containers/header/header-container/components/AccountSettingsMenu/components/ThemeToggle'

import style from '../Container/components/MenuItem/style.module.scss';
import localStyle from './style.module.scss';


export function SettingsInfo({ collapsed = false }) {
  const history = useHistory();

  const goToAccountStorage = () => history.push('/account/bank');

  return (
    <div className={CN(localStyle.container, collapsed && localStyle.containerColapsed)} onClick={goToAccountStorage}>
      <div className={CN(style.wrapper, localStyle.settingsContent, style.notContainer, collapsed && style.notContainerCollapsed)}>
        <div className={CN(style.item, { [style.collapsed]: collapsed })}>
          <span className={style.icon}><SettingsIcon /></span>
          <span className={style.name}>{'Settings'}</span>
        </div>
       {!collapsed &&  <ThemeToggle />}
      </div>
    </div>
  );
}
