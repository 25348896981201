import React, { useRef } from 'react'

import { validationRegExp } from 'utils/validation/regexp'

const CreateTeam = ({
  newTeamUsers,
  onCancel,
  onAddTeam,
  onAddUserToNewTeam,
}) => {
  const inputRef = useRef(null)
  const inputUserRef = useRef(null)

  const existUsers =
    newTeamUsers &&
    newTeamUsers.map((item) => (
      <button
        className="button button--primary button--primary_outline text--medium text--12 text--uppercase remove-button"
        type="button"
        key={`exist-user-${item}`}
      >
        {item}
      </button>
    ))

  const addTeamHandler = () => {
    if (inputRef.current && inputRef.current.value) {
      onAddTeam({ name: inputRef.current.value })
    }
  }

  const addUserHandler = () => {
    if (
      inputUserRef.current &&
      inputUserRef.current.value &&
      validationRegExp.email.test(inputUserRef.current.value)
    ) {
      onAddUserToNewTeam({ email: inputUserRef.current.value })
      inputUserRef.current.value = ''
    }
  }

  return (
    <div className="right-menu__container add-user">
      <label className="right-menu__label">
        {`What's the name of your team?`}
        <input
          type="text"
          name="name"
          className="right-menu__input"
          placeholder="Ex.Acme or Acme Marketing"
          ref={inputRef}
        />
      </label>

      <label className="right-menu__label">
        Add Users
        <div className="right-menu__input-container">
          <input
            type="email"
            name="users"
            className="right-menu__input"
            placeholder="Type E-mail Addresses"
            ref={inputUserRef}
          />
          <button
            type="button"
            className="add-button"
            onClick={addUserHandler}
          />
        </div>
      </label>

      <div className="add-user__user-button">{existUsers}</div>

      <button
        className="button button--primary text--medium text--12 text--uppercase bottom-button"
        type="button"
        onClick={addTeamHandler}
      >
        Add team
      </button>
      <button className="link" type="button" onClick={onCancel}>
        Cancel
      </button>
    </div>
  )
}

export default CreateTeam
