import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Application } from '@splinetool/runtime';

import NeyraBorder from 'components/NeyraBorder';
import NeyraSidebar from 'components/NeyraWelcomePageSidebar';
import WelcomeText from '../NeyraWelcome/component/WelcomeText';
import NeyraSmallLogo from 'components/NeyraSmallLogo';

import styles from './styles.module.scss';

const canvasUrls = {
  chat: 'https://prod.spline.design/0GmPeAMhqbUaycNY/scene.splinecode',
  drive: 'https://prod.spline.design/e0c6XyOPnfqoogOy/scene.splinecode',
  token: 'https://prod.spline.design/wx9Os075SEOTaNxP/scene.splinecode',
};

const NeyraAI = () => {
  const history = useHistory();
  const currentRoute = useMemo(
    () => location.pathname.replace('/', ''),
    [location.pathname]
  );

  useEffect(() => {
    const canvas = document.getElementById('logoCanvas');
    if (canvas) {
      const spline = new Application(canvas);
      spline.load(canvasUrls[currentRoute]);
    }
  }, [currentRoute]);

  const onLogoClick = () => history.push('/');

  return (
    <div className={styles.container}>
      <NeyraBorder>
        <NeyraSmallLogo
          className={styles.NeyraLogo}
          handleClick={onLogoClick}
        />
        <canvas id="logoCanvas" className={styles.canvas}></canvas>
        <div>{currentRoute === 'token' && <WelcomeText isImage />}</div>
      </NeyraBorder>
      <NeyraSidebar type={currentRoute} />
    </div>
  );
};

export default NeyraAI;
