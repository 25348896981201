import { playNotificationSong } from 'utils/audio'
import { setNextNotificationsByType } from 'store/notifications/actions'
import {
  getNextNotificationByTypeEffect,
  NOTIFICATION_TYPES,
} from 'store/notifications/effects/notifications-get.effect'

export const notificationChecker = (notifications, dispatch) => {
  getNextNotificationByTypeEffect(NOTIFICATION_TYPES.unread, 1).then(
    ({ data }) => {
      if (
        notifications.length < data.length ||
        notifications[0]?.id !== data[0]?.id
      ) {
        playNotificationSong()
        dispatch(setNextNotificationsByType(1, data, NOTIFICATION_TYPES.unread))
      }
    }
  )
}
