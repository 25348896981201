import web3 from 'web3';

const checkMetamaskInstalled = () => {
  return typeof window.ethereum !== 'undefined';
};

const checkProviderInstalled = (provider) => {
  if (provider === 'Unstoppable') {
    return true;
  }
  if (typeof window.ethereum !== 'undefined') {
    const { providers } = window.ethereum;
    if (!providers) {
      if (provider === 'MetaMask') {
        return window.ethereum.isMetaMask;
      }
      if (provider === 'Coinbase Wallet') {
        return window.ethereum.isWalletLink;
      }
    }
    if (provider === 'MetaMask') {
      return providers.find((provider) => provider.isMetaMask);
    }
    if (provider === 'Coinbase Wallet') {
      return providers.find((provider) => provider.isWalletLink);
    }
  }
  return false;
};

const getCurrentChainIdAsync = async (provider = null) => {
  const providers = window?.ethereum?.providers;
  let currentChainId;

  if (!providers) {
    currentChainId = provider
      ? await provider.request({
          method: 'eth_chainId',
        })
      : await window.metamask.request({
          method: 'eth_chainId',
        });
  } else {
    currentChainId = await window.metamask.request({
      method: 'eth_chainId',
    });
  }

  return web3.utils.hexToNumber(currentChainId);
};

const getCurrentChainId = async (currentProvider = null) => {
  const currentChainId = currentProvider?.chainId ?? window.metamask.chainId;

  return web3.utils.hexToNumber(currentChainId);
};

export {
  checkMetamaskInstalled,
  getCurrentChainIdAsync,
  getCurrentChainId,
  checkProviderInstalled,
};
