// eslint-disable-next-line import/no-extraneous-dependencies
import { CarReader } from '@ipld/car';
import { getFileCids } from './get-file-cid';
import { IFile } from 'interfaces/file-folder';
import { downloadFile, getCidLevelByFileSize } from 'gdgateway-client/lib/es5';
import { getDownloadOTT } from '../files-upload/upload-file.effect';
import { sendFileViewStatistic } from '../statistic/file-statistic-effect';
import { actionsType } from 'config/actions-options';

export const getPreviewFileBlob = async ({
  file,
  controller = null,
  sendStatistic = true,
}: {
  file: IFile;
  controller?: AbortController | null;
  sendStatistic?: boolean;
}) => {
  const abortController = controller || new AbortController();
  let cidData;

  const {
    data: {
      jwt_ott,
      user_tokens: { token: oneTimeToken },
      gateway,
      upload_chunk_size,
    },
  } = await getDownloadOTT(
    [{ slug: file.slug }],
    sendStatistic ? actionsType.VIEWED : false
  );

  if (file?.is_on_storage_provider) {
    const level = getCidLevelByFileSize(file);
    cidData = await getFileCids({ slug: file.slug, level });
  }
  sendStatistic && (await sendFileViewStatistic(file.slug));
  const blob = await downloadFile({
    file,
    oneTimeToken,
    jwtOneTimeToken: jwt_ott,
    endpoint: gateway.url,
    isEncrypted: false,
    signal: abortController.signal,
    carReader: CarReader,
    uploadChunkSize: upload_chunk_size[file.slug] || gateway.upload_chunk_size,
    cidData,
  });
  if (blob) {
    const realBlob = new Blob([blob]);
    return realBlob;
  }
};

export const getPreviewObject = async ({
  file,
  controller = null,
  sendStatistic = true,
}: {
  file: IFile;
  controller?: AbortController | null;
  sendStatistic?: boolean;
}) => {
  const blob = await getPreviewFileBlob({ file, controller, sendStatistic });
  if (blob) {
    const text = await blob.text();
    const obj = JSON.parse(text);
    return obj;
  }
};
