/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-unused-vars */
//Refactoring №2
import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactCodeInput from 'react-code-input';
import { useTranslation } from 'react-i18next';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import CN from 'classnames';
import { isEmpty } from 'lodash';
import { useKeyPress } from 'ahooks';

import {
  signUpEffect,
  signUpEmailConfirmationEffect,
  signUpCheckEmailEffect,
  signUpCheckCodeEffect,
} from 'store/auth/effects/index';
import { MainContextWrapper } from 'store/context';
import { prepareStripeDataEffect } from 'store/payment/effects/stripe/get-stripe-data.effect';

import { validationRegExp } from 'utils/validation/regexp';
import { SUBSCRIPTIONS_PRICING } from 'config/tarrifs-types';
import { getStripe } from 'utils/payment/get-dollars-from-cent';
import STORAGE_TYPES from 'config/storage-types';
import useNotification from 'utils/hooks/use-notification';

import InputFieldFormik from 'components/InputFieldFormik';
import WarningIcon from 'components/svg/warning';
import Button, { ButtonTheme } from 'components/Button';
import { SharedLayout } from '../components/SharedLayout';
import AbsoluteCorners from 'components/AbsoluteCorners';
import NotificationBubble from 'containers/notifications/notification-bubble';

import style from './style.module.scss';

const maxWorkspaceLength = 50;

const SignUpEmail = ({ history }) => {
  const codeRef = useRef(null)
  const searchParams = new URLSearchParams(history.location.search);
  const isoauth = JSON.parse(searchParams.get('isoauth'));
  const { t } = useTranslation('auth');
  const { addNotification } = useNotification();
  const formRef = useRef();
  const [step, setStep] = useState(1);
  const [scrollTo, setScrollTo] = useState('down');
  const [confirmCode, setConfirmCode] = useState('');
  const [confirmCodeError, setConfirmCodeError] = useState('');

  const url = new URL(window.location.href);
  const queryParams = url.searchParams;
  const isReferralCode = queryParams.get('referral');

  const DEFAULT_ERROR_MESSAGES = useMemo(
    () => ({
      workspaceRequired: t('signup.workspaceNameRequired'),
      workspaceInvalid: t('signup.tooLongName', { maxWorkspaceLength }),
      usernameRequired: t('signup.emailRequired'),
      usernameInvalid: t('signup.invalidEmail'),
      passwordRequired: t('signup.requiredPassword'),
      passwordInvalid: t('signup.invalidPassword'),
      codeRequired: t('signup.codeRequired'),
      codeInvalid: t('signup.codeInvalid'),
    }),
    []
  );

  const VERIFICATION_CODE = useMemo(
    () => t('signup.verificationCodeWasSent'),
    []
  );
  const VERIFICATION_CODE_RESEND = useMemo(
    () => t('signup.verificationCodeResent'),
    []
  );

  const validationSchema = useMemo(
    () =>
      Yup.object({
        email: Yup.string()
          .email(t('common.enterValidEmail'))
          .required(t('common.requiredField')),
        workspaceName: Yup.string().required(t('signup.workspaceNameRequired')),
      }),
    []
  );

  useEffect(() => {
    if (step === 2 && codeRef.current) {
      const codeInputs =
        document.getElementsByClassName('react-code-input')[0]?.childNodes ||
        [];
      codeInputs.forEach((input) => {
        input.setAttribute('placeholder', '-');
        input.setAttribute('maxLength', '1')
        input.onpaste = (e) => {
          const startIndex = e.currentTarget.dataset.id
          const data = (e.clipboardData || window.clipboardData).getData("text").match(/\d+/g)?.join('') || ''

          if (data) {
            let textIndex = 0
            const myArray = Array(6).fill("").map((item, index) => {
              if (index >=  parseInt(startIndex)) {
                ++textIndex  

                return data[textIndex - 1]
              }
               return item 
            })

            codeRef.current.setState({ input: myArray })
            setConfirmCode(data)
            codeInputs[codeInputs.length - 1].focus()
          } 

          e.preventDefault()
        }
      });
    }
  }, [step, codeRef]);

  useKeyPress(['enter'], () => {
    goNextStep();
  });

  const onKeyPress = (event) => {
    if ('Enter' === event.key) {
      goNextStep();
    }
  };

  const onConfirmCodeodeChange = (code) => {
    setConfirmCode(code);
  };

  const passswordValidMinLength = (value = '') => value.length >= 8;
  const passswordValidLowLetters = (value = '') =>
    validationRegExp.hasLowCaseCharacter.test(value);
  const passswordValidUppLetters = (value = '') =>
    validationRegExp.hasUpperCaseCharacter.test(value);
  const passswordValidNumbers = (value = '') =>
    validationRegExp.hasDigits.test(value);

  const isStrongPassword = (pass = '') => {
    return (
      passswordValidMinLength(pass) &&
      passswordValidLowLetters(pass) &&
      passswordValidUppLetters(pass) &&
      passswordValidNumbers(pass)
    );
  };

  const isPasswordsEquel = (pass, secondPass) => pass === secondPass;

  const resendEmail = (email) => {
    addNotification(VERIFICATION_CODE_RESEND, 'success');
    signUpEmailConfirmationEffect(email);
  };

  const renderInputs = ({ values }) => {
    switch (step) {
      case 1:
        return (
          <CSSTransition
            appear
            timeout={{
              appear: 1000,
              enter: 1000,
              exit: 1000,
            }}
            classNames="step"
            key={`step-content-${step}`}
          >
            <div className={style.emailStep}>
              <InputFieldFormik
                autoFocus
                onKeyPress={onKeyPress}
                className={style.input}
                errorClassName={style.inputError}
                labelClassName={style.inputLabel}
                errorIcon={
                  <WarningIcon width={21} height={18} color="#FFB800" />
                }
                name="email"
                label="Enter Email"
                placeholder={t('common.yourEmail')}
                type="email"
              />
            </div>
          </CSSTransition>
        );

      case 2:
        return (
          <CSSTransition
            timeout={{
              appear: 1000,
              enter: 1000,
              exit: 1000,
            }}
            classNames="step"
            key={`step-content-${step}`}
          >
            <div className={style.verification}>
              <h3 className={style.verificationTitle}>
                {t('signup.enterCode')}
              </h3>
              <p className={style.verificationDescription}>
                {t('signup.verificationCodeSentToEmail')}
                <strong className={style.strong}>{values.email}</strong>.{' '}
                {t('signup.verificationCodeMessage')}
              </p>
              <div className={style.verificationInputWrapper}>
                <ReactCodeInput
                  ref={codeRef}
                  autoFocus
                  onKeyPress={onKeyPress}
                  type="number"
                  className={style.verificationInput}
                  name="verification"
                  onChange={onConfirmCodeodeChange}
                  fields={6}
                />
                {confirmCodeError && (
                  <div className={style.verificationInputError}>
                    <WarningIcon width={21} height={18} color="#FFB800" />
                    {confirmCodeError}
                  </div>
                )}
              </div>
              <div className={style.resend}>
                <p className={style.resendTitle}>{t('signup.didntGetEmail')}</p>
                <button
                  type="button"
                  className={style.resendBtn}
                  onClick={() => resendEmail(values.email)}
                >
                  {t('signup.resendEmail')}
                </button>
              </div>
            </div>
          </CSSTransition>
        );

      case 3:
        return (
          <CSSTransition
            timeout={{
              appear: 1000,
              enter: 1000,
              exit: 1000,
            }}
            classNames="step-code step"
            key={`step-content-${step}`}
          >
            <div>
              <InputFieldFormik
                autoFocus
                onKeyPress={onKeyPress}
                className={CN(style.input, style.inputPassword)}
                errorClassName={style.inputError}
                labelClassName={CN(style.inputLabel, style.inputLabelPassword)}
                errorIcon={
                  <WarningIcon width={21} height={18} color="#FFB800" />
                }
                label={t('signup.masterPassword')}
                name="password"
                placeholder={t('signup.password')}
                type="password"
                isPassword
              />
              <InputFieldFormik
                onKeyPress={onKeyPress}
                className={CN(style.input, style.inputPassword)}
                errorClassName={style.inputError}
                labelClassName={CN(style.inputLabel, style.inputLabelPassword)}
                errorIcon={
                  <WarningIcon width={21} height={18} color="#FFB800" />
                }
                label={t('signup.confirmPassword')}
                name="confirmPassword"
                placeholder={t('signup.confirmPassword')}
                type="password"
                isPassword
              />
              <div className={style.passwordHints}>
                <p className={style.passwordHintsTitle}>
                  {t('signup.keepPasswordSafe')}
                </p>
                <ul className={style.passwordHintsList}>
                  <li
                    className={CN(
                      style.passwordHint,
                      passswordValidMinLength(values.password) &&
                        style.passwordHintActive
                    )}
                  >
                    {t('signup.rule1')}
                  </li>
                  <li
                    className={CN(
                      style.passwordHint,
                      passswordValidLowLetters(values.password) &&
                        style.passwordHintActive
                    )}
                  >
                    {t('signup.rule2')}
                  </li>
                  <li
                    className={CN(
                      style.passwordHint,
                      passswordValidUppLetters(values.password) &&
                        style.passwordHintActive
                    )}
                  >
                    {t('signup.rule3')}
                  </li>
                  <li
                    className={CN(
                      style.passwordHint,
                      passswordValidNumbers(values.password) &&
                        style.passwordHintActive
                    )}
                  >
                    {t('signup.rule4')}
                  </li>
                </ul>
              </div>
            </div>
          </CSSTransition>
        );

      case 4:
        return (
          <CSSTransition
            timeout={{
              appear: 1000,
              enter: 1000,
              exit: 1000,
            }}
            classNames="step"
            key={`step-content-${step}`}
          >
            <div className={style.workspace}>
              <InputFieldFormik
                autoFocus
                onKeyPress={onKeyPress}
                className={style.input}
                errorClassName={style.inputError}
                errorIcon={
                  <WarningIcon width={21} height={18} color="#FFB800" />
                }
                labelClassName={style.inputLabel}
                name="workspaceName"
                placeholder={t('signup.workspaceName')}
              />
            </div>
          </CSSTransition>
        );
      default:
        return <div />;
    }
  };

  const goNextStep = () => {
    const myForm = formRef.current;
    const values = formRef.current.values;
    const emailField = 'email';
    const passField = 'password';
    const confirmField = 'confirmPassword';
    const workspaceField = 'workspaceName';

    if (step === 1) {
      myForm.setFieldTouched(emailField, true, true).then((res) => {
        if (isEmpty(res[emailField])) {
          signUpCheckEmailEffect(values[emailField])
            .then(() => {
              signUpEmailConfirmationEffect(values[emailField]);
              addNotification(VERIFICATION_CODE, 'success');
              setStep(step + 1);
            })
            .catch((error) => {
              addNotification(error, 'alert');
            });
        }
      });
    } else if (step === 2) {
      if (confirmCode.length < 6) {
        setConfirmCodeError(DEFAULT_ERROR_MESSAGES.codeRequired);
      } else {
        signUpCheckCodeEffect(values[emailField], confirmCode)
          .then(() => {
            setStep(step + 1);
          })
          .catch(() => {
            setConfirmCodeError(DEFAULT_ERROR_MESSAGES.codeInvalid);
          });
      }
    } else if (step === 3) {
      myForm.setFieldTouched(passField, true, false);
      myForm.setFieldTouched(confirmField, true, false);
      if (values[passField]) {
        if (isStrongPassword(values[passField])) {
          myForm.setFieldError(passField);
          if (isPasswordsEquel(values[confirmField], values[passField])) {
            setStep(step + 1);
          } else {
            myForm.setFieldError(confirmField, t('signup.passwordNotEquel'));
          }
        } else {
          myForm.setFieldError(passField, t('signup.passwordNotSafe'));
        }
      } else {
        myForm.setFieldError(passField, t('signup.requiredPassword'));
      }
    } else if (step === 4) {
      myForm.setFieldTouched(workspaceField, true, true);
      if (values[workspaceField]) {
        handleCreateUser(values);
      }
    }
  };

  const handleCreateUser = (values) => {
    const { email, password, workspaceName } = values;
    const price = 0;
    const payedTariff = history?.location?.state?.tariff;

    signUpEffect(
      email,
      password,
      confirmCode,
      workspaceName,
      'ghostdirectfriends',
      STORAGE_TYPES.ghostIpfs,
      price,
      payedTariff ? null : history,
      isReferralCode
    ).then(async () => {
      const payedPrice = SUBSCRIPTIONS_PRICING[history?.location?.state?.price];
      if (payedTariff) {
        const stripe = await getStripe();
        const { token: sessionId } = await prepareStripeDataEffect(payedPrice, {
          unregistered: true,
        });
        await stripe.redirectToCheckout({
          sessionId,
        });
      }
    });
  };

  const goBacks = () => {
    if (step === 1) {
      history.goBack();
      return;
    }
    setConfirmCodeError('');
    setStep(step - 1);
  };

  const renderFooter = () => {
    return (
      <div className={style.actions}>
        <Button
          className={style.backBtn}
          theme={ButtonTheme.TRANSPARENT}
          type="button"
          onClick={goBacks}
        >
          {t('common.back')}
        </Button>
        <Button
          className={style.nextBtn}
          theme={ButtonTheme.NEW_BORDERRED_DARK}
          onClick={goNextStep}
          type="button"
        >
          {t('common.continue')}
        </Button>
      </div>
    );
  };

  return (
    <SharedLayout
      history={history}
      isoauth={isoauth}
      blackFooter
      className={style.wrapper}
      type="signUpEmail"
    >
      <div className={style.container}>
        {step !== 4 && <h2 className={style.title}>{t('signup.signup')}</h2>}
        <Formik
          innerRef={formRef}
          validationSchema={validationSchema}
          initialValues={{}}
          onSubmit={() => {}}
        >
          {({ values }) => {
            return (
              <Form className={style.form}>
                <TransitionGroup
                  className={CN(
                    style.transitions,
                    `scroll-${scrollTo}`,
                    style[`step_${step}`]
                  )}
                >
                  {renderInputs({ values })}
                </TransitionGroup>
                <AbsoluteCorners className={style.corners} />
              </Form>
            );
          }}
        </Formik>
        {renderFooter()}
      </div>
      <NotificationBubble />
    </SharedLayout>
  );
};

const SignUpEmailomponent = (props) => (
  <MainContextWrapper>
    <SignUpEmail {...props} />
  </MainContextWrapper>
);

export default SignUpEmailomponent;
