import {
  NEEDED_ENCRYPT_FILE,
  ENCRYPT_EXISTING_FILE,
  HANDLE_ENCRYPT_MODAL,
  HANDLE_UPLOAD_DROPED_FILES,
  ENTER_ENCRYPTION_KEY,
  ENCRYPT_EXISTING_FILE_ERROR,
  ENCRYPT_DROPED_FILES,
} from '../actions/file/encrypt.action';

export const initialState = {
  needEncryption: undefined,
  dropedFiles: [],
  needEncryptExistingFile: undefined,
  openModal: false || undefined,
  uploadDropedFiles: false,
  showEncryptionKeyModal: false,
  encryptExistingFileError: undefined,
  encryptionStatus: false,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case NEEDED_ENCRYPT_FILE:
      return {
        ...state,
        needEncryption: action.payload.needEncryptFile,
      };
    case ENCRYPT_EXISTING_FILE:
      return {
        ...state,
        needEncryptExistingFile: action.payload.encryptExistingFile,
        encryptionStatus: true,
      };
    case ENCRYPT_EXISTING_FILE_ERROR:
      return {
        ...state,
        encryptExistingFileError: action.payload.handleEncryptFileError,
      };

    case HANDLE_ENCRYPT_MODAL:
      return {
        ...state,
        openModal: action.payload.isOpen,
      };

    case HANDLE_UPLOAD_DROPED_FILES:
      return {
        ...state,
        uploadDropedFiles: action.payload.isStart,
      };
    case ENTER_ENCRYPTION_KEY:
      return {
        ...state,
        showEncryptionKeyModal: action.payload.encryptionKeyModal,
      };
    case ENCRYPT_DROPED_FILES:
      return {
        ...state,
        dropedFiles: action.payload,
      };

    default:
      return state;
  }
};
