import React from 'react';

const IconRec = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <circle cx="12.3171" cy="12.4978" r="10.8571" fill="#FF0000" />
      <circle
        cx="12.3174"
        cy="12.5"
        r="11.9007"
        stroke="#FF0000"
        strokeWidth="0.198572"
      />
      <rect x="8.31689" y="8.5" width="8" height="8" fill="white" />
    </svg>
  );
};

export default IconRec;
