import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';

import NeyraLaunchMessage from '../NeyraLaunchMessage';
import useAnimatedMessage from 'utils/hooks/useAnimatedMessage';

import styles from './styles.module.scss';
import { BIOS_PRICE } from 'constants/bios-price';
import RegistrationAfterSuccessPayment from '../RegistrationAfterSuccessPayment';
import { saveTokenPurchaseStatusToLocalStorage } from 'utils/tokenPurchase';
import getToken, {
  getRefreshToken as refreshToken,
} from 'utils/auth/get-token';
import { setToken } from 'web3-auth/lib/es5';
import { getRefreshToken } from 'client-neyra';

const RegistrationStepNewFlow = () => {
  const { t } = useTranslation('auth');
  const { t: accountT } = useTranslation('account');
  const scrollRef = useRef(null);
  const [currentStep, setCurrentStep] = useState('initial');
  const [autoScroll, setAutoScroll] = useState(true);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state;
  const [successPayment, setSuccessPayment] = useState(false);
  const steps = {
    initial: '',
    offerToBuy: t('initialPage.offerToBuy', { BIOS_PRICE }),
  };
  const token = getToken();
  const { animatedMessage, isPrinting } = useAnimatedMessage(
    steps[currentStep]
  );

  useEffect(() => {
    if (!token) {
      window.location.href = `${window.location.origin}/connect`;
    }
  }, []);

  useEffect(() => {
    if (locationState?.successPayment) {
      setSuccessPayment(true);

      const timeoutId = setTimeout(async () => {
        const refresh_token = refreshToken();
        const res = await getRefreshToken({ body: { refresh_token } });
        setToken(res);
      }, 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [locationState]);

  useEffect(() => {
    setAutoScroll(!(currentStep === 'offerToBuy' && !isPrinting));
  }, [currentStep, isPrinting]);

  useEffect(() => {
    let interval;
    if (autoScroll) {
      interval = setInterval(scrollToBottom, 500);
    } else {
      scrollToBottom();
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [autoScroll]);

  useEffect(() => {
    scrollToBottom();
  }, [currentStep]);

  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, []);

  const handleContinue = () => {
    setAutoScroll(true);
    setTimeout(() => {
      setCurrentStep('offerToBuy');
      scrollToBottom();
    }, 1000);
  };

  const handleActivate = () => {
    saveTokenPurchaseStatusToLocalStorage();
    const pathname = '/wallet/buy-credits';
    history.push({
      pathname,
      state: { price: BIOS_PRICE },
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = scrollRef.current;
      if (element) {
        const isAtBottom =
          element.scrollHeight - element.scrollTop === element.clientHeight;
        setIsScrolledToBottom(isAtBottom);
      }
    };

    const element = scrollRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <>
      {successPayment ? (
        <RegistrationAfterSuccessPayment />
      ) : (
        <>
          <div className={styles.registrationStepContainer}>
            <div ref={scrollRef} className={styles.scrollContent}>
              <div className={styles.shadowBlock} />
              <div
                className={CN(
                  styles.shadowBlockBottom,
                  !currentStep === 'offerToBuy' && styles.lowerShadow,
                  isScrolledToBottom || autoScroll ? '' : styles.visible
                )}
              />
              <NeyraLaunchMessage
                finishTyping={() => {
                  setAutoScroll(false);
                }}
                hideButton={currentStep === 'offerToBuy'}
                msgType={'registration'}
              />
              <div className={styles.question}>{animatedMessage}</div>
            </div>
            {!autoScroll && (
              <div className={styles.footerBlock}>
                <button
                  onClick={() => {
                    currentStep === 'offerToBuy'
                      ? handleActivate()
                      : handleContinue();
                  }}
                  className={CN(
                    styles.continueButton,
                    currentStep === 'offerToBuy' && styles.smallButton
                  )}
                >
                  {currentStep === 'offerToBuy'
                    ? accountT('security.activate')
                    : t('common.continue')}
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default RegistrationStepNewFlow;
