import { STORAGE_KEY } from 'store/context/theme'

const isiOSPlatform = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

const checkDeviceAndScroll = () => {
  // For mobiles and tablets.
  let isIOS
  let isScroll
  setTimeout(() => {
    const element = document.querySelector('.files-container__content')
    if (element !== null) {
      isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
      isScroll = element.scrollHeight > element.clientHeight
      if (isIOS && isScroll) {
        !element.classList.contains('ios') && element.classList.add('ios')
      }
      if (isIOS && !isScroll) {
        element.classList.contains('ios') && element.classList.remove('ios')
      }
    }
  }, 1500)
}

const cleanSearchUrl = () => {
  window.history.pushState(null, null, window.location.pathname);
};

const cleanTheme = () => {
  document.documentElement.removeAttribute(STORAGE_KEY);
  localStorage.removeItem(STORAGE_KEY);
};

export { isiOSPlatform, checkDeviceAndScroll, cleanSearchUrl, cleanTheme }
