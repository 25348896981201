import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import NeyraLogo from '../NeyraLogo';

import styles from './styles.module.scss';

const BiosSetupInProgress = ({ successActive }) => {
  const { t } = useTranslation('auth');
  const [progress, setProgress] = useState(0);
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 60);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress === 100 && successActive) {
      setTimeout(() => {
        history.push('/chat');
      }, 200);
    }
  }, [progress]);

  return (
    <div className={styles.container}>
      <NeyraLogo className={styles.logo} />
      <div>
        <div className={styles.text}>
          <span>{progress}%</span>
          {t('initialPage.biosSetupProgress')}
        </div>
      </div>
    </div>
  );
};

export default BiosSetupInProgress;
