/* eslint-disable no-unused-vars */
// libs
import axios from 'axios';

import { API_AUTH } from 'constants/api-urls';
import setToken from 'utils/auth/set-token';
import { getRefreshToken } from 'utils/auth/get-token';
import authRequest from 'utils/request/auth-request';
// import { getFromCookies, setToCookies } from 'utils/cookies';

export const authToken = async (token) => {
  return axios
    .get(`${API_AUTH}/login_check_fleettec`, {
      params: {
        token,
      },
    })
    .then((response) => {
      setToken(response);
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const authTokenVerify = async (token) => {
  return axios
    .post(`${API_AUTH}/verify`, {
      token,
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const authTokenInvite = async (displayed_name, password, token) => {
  return axios
    .post(`${API_AUTH}/invite`, {
      displayed_name,
      password,
      token,
    })
    .then((response) => {
      setToken(response);
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const authTokenRefresh = async () => {
  return axios
    .post(`${process.env.REACT_APP_API_PATH}/token/refresh`, {
      refresh_token: getRefreshToken(),
    })
    .then((response) => {
      setToken(response);
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const getSigner = async ({
  type,
  contract,
  contractAddress,
  chainId,
  req,
  isPublic = false,
  currentProvider,
}) => {
  const provider = currentProvider || window.metamask;
  const [publicAddress] = await provider.request({
    method: 'eth_requestAccounts',
  });

  const body = {
    type,
    contract,
    contractAddress,
    req,
    chainId,
    publicAddress,
  };
  return await authRequest.post(
    `${process.env.REACT_APP_API_PATH}${isPublic ? '/public' : ''}/signer`,
    body
  );
};

export const getAuthInfo = async (publicAddress) => {
  return authRequest
    .post(`${process.env.REACT_APP_API_PATH}/user/auth/info`, { publicAddress })
    .then((response) => {
      setToken(response);
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
};
