export const ADD_CALLBACK = 'ADD_CALLBACK';
export const RUN_CHECK = 'RUN_CHECK';
export const STOP_CHECK = 'STOP_CHECK';

export function runCheckWeb3(
  isWeb3FeatureRun: boolean,
  callback: () => void,
  forceChainId?: number
) {
  return {
    type: RUN_CHECK,
    payload: { isWeb3FeatureRun, callback, forceChainId: forceChainId ?? null },
  };
}

export function stopCheckWeb3() {
  return { type: STOP_CHECK };
}

export function addCallback(callback: () => void) {
  return {
    type: ADD_CALLBACK,
    payload: { callback },
  };
}
