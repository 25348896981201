import authRequest from 'utils/request/auth-request'
import getToken from 'utils/auth/get-token'

export default async (slug, action) => {
  const url = `${process.env.REACT_APP_API_PATH}/statistic/${slug}/${action}`
  if (!getToken()) return

  return authRequest
    .post(url)
    .then((response) => {
      return response.data.data
    })
    .catch((response) => {
      throw response
    })
}
