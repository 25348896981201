import React from 'react';

import styles from 'components/Spinners/styles.module.scss'

const DotLoader = () => {

  return (
    <div className={styles.dotLoader} />
  )
}

export default DotLoader