// Refactoring №3
import React, { useState, useContext } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import UserContext from 'store/home/contexts/user-context';

import GhostPassForm from './Form';
import CloseIcon from 'components/svg/close';
import { ReactComponent as Logo } from 'static/assets/img/ghost-logo.svg';

import style from './style.module.scss';

const GhostPassModal = () => {
  const { t } = useTranslation('account');
  const { user: me } = useContext(UserContext);
  const { is_password_access, password_access } = me;
  const initialState =
    !!is_password_access && password_access?.enabled === false;
  const [isOpen, setState] = useState(initialState);

  const handleOnClose = () => {
    setState(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleOnClose}
      contentLabel="Access preferences"
      className={style.modal}
      overlayClassName={style.overlay}
      shouldCloseOnOverlayClick
    >
      <section className={style.container}>
        <div className={style.header}>
          <span className={style.close} onClick={handleOnClose}>
            <CloseIcon color="#4B576C" />
          </span>
        </div>
        <div className={style.content}>
          <div className={style.logo}>
            <Logo />
          </div>
          <h2 className={style.titile}>{t('Settings.GhostPass')}</h2>
          <p className={style.description}>{t('Settings.enterPassword')}</p>
          <GhostPassForm onClose={handleOnClose} />
        </div>
      </section>
    </Modal>
  );
};

GhostPassModal.defaultProps = {
  entity: [],
  clear: () => {},
};

export default GhostPassModal;
