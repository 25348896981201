//Refactoring №3
import React, { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ethers } from 'ethers';
import { getUserRSAKeys, publicKeyToPem } from 'gdgateway-client/lib/es5';

import { checkMetamaskInstalled } from 'utils/crypto/wallet/metamask';
import { isMobile } from 'utils/mobile';
import { isElectron } from 'utils/isElectron';
import { showMetamaskMobileOption } from 'utils/showMetamaskMobileOption';
import { getCoinbaseProvider } from 'utils/getCoinbaseProvider';

import {
  connectWalletToAccountEffect,
  getUnstoppableAdressEfect,
  loginMetamask,
} from 'store/auth/effects';
import UserContext from 'store/home/contexts/user-context';

import AddWalletModal from './addWalletModal';
import SuccessSendedLinkToEmailModal from '../ProfileForm/ProfileEmail/successSendedLinkToEmailModal';
import AddEmailFlow from '../ProfileForm/ProfileEmail/addEmailFlow';

import CryptoCheckPluginModal from 'containers/auth/crypto-check-plugin-modal';
import { addWalletMessage, signMessage } from 'containers/auth/login-crypto';

export default function AddWalletFlow({
  isAddingWalletStart,
  close,
  addEmailModal,
  setAddEmailModal,
  isSuccessSendedLinkModal,
  setSuccessSendedLinkModal,
}) {
  const [isAddingWallet, setAddingWallet] = useState(false);
  const [isInstallExtChecker, setInstallExtCheker] = useState(false);
  const [isWaiting, setWaiting] = useState(false);

  const closeModalHandler = () => {
    if (isAddingWallet) setAddingWallet(false);
    if (isInstallExtChecker) setInstallExtCheker(false);
    if (addEmailModal) setAddEmailModal(false);
    if (isSuccessSendedLinkModal) setSuccessSendedLinkModal(false);
    if (isWaiting) setWaiting(false);
    close();
  };

  useEffect(() => {
    if (isAddingWalletStart) setAddingWallet(true);
  }, [isAddingWalletStart]);

  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useContext(UserContext);
  const email = user.email;

  const signInUnstoppable = async () => {
    setWaiting(true);
    if (!email) {
      closeModalHandler();
      setAddEmailModal(true);
    } else {
      const public_address = await getUnstoppableAdressEfect();
      try {
        // @todo get public key instead of null
        await connectWalletToAccountEffect(public_address, null, true).then(
          () => {
            closeModalHandler();
            setSuccessSendedLinkModal(true);
          }
        );
      } catch (error) {
        setWaiting(false);
      }
    }
  };

  const handleSignUpMetamaskCoinbase = async (selectedWallet) => {
    let currentProvider = null;
    if (isElectron) {
      currentProvider =
        selectedWallet === 'coinbase' ? getCoinbaseProvider() : window.metamask;
      if (selectedWallet === 'metamask') {
        currentProvider.request({
          method: 'eth_requestAccounts',
          params: [],
        });
        showMetamaskMobileOption();
      }
      if (selectedWallet === 'coinbase') {
        await currentProvider.request({
          method: 'eth_requestAccounts',
          params: [],
        });
      }
    }

    setWaiting(true);
    if (isMobile && !checkMetamaskInstalled()) {
      const dappUrl = `${window?.location?.host}/sign-in`;
      const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
      return window.open(metamaskAppDeepLink, '_self');
    } else {
      if (!checkMetamaskInstalled() && !isElectron) {
        closeModalHandler();
        setInstallExtCheker(true);
      } else if (!email) {
        closeModalHandler();
        setAddEmailModal(true);
      } else {
        try {
          const isCoinbase = selectedWallet === 'coinbase';
          const { address } = await addWalletMessage(email, currentProvider);
          const provider = new ethers.providers.Web3Provider(
            currentProvider ?? window.metamask
          );
          const signer = provider.getSigner();
          const keys = await getUserRSAKeys({ signer });
          const public_key = publicKeyToPem({ publicKey: keys.publicKey });

          await connectWalletToAccountEffect(
            address,
            public_key,
            false,
            isCoinbase
          ).then(() => {
            setAddingWallet(false);
            setSuccessSendedLinkModal(true);
          });
        } catch (error) {
          setWaiting(false);
          if (
            error?.errors?.children?.publicAddress?.errors?.includes(
              'This value is already used.'
            )
          ) {
            const { signature, address } = await signMessage(false);
            await loginMetamask(address, signature, history, dispatch);
          }
        }
      }
    }
  };
  return (
    <>
      <AddWalletModal
        isOpen={isAddingWallet}
        close={closeModalHandler}
        clickMetamaskOrCoinbase={handleSignUpMetamaskCoinbase}
        clickUnstoppable={signInUnstoppable}
        isWaiting={isWaiting}
      />
      <AddEmailFlow isStarting={addEmailModal} close={closeModalHandler} />
      <CryptoCheckPluginModal
        type="metamask"
        isOpen={isInstallExtChecker}
        onClose={closeModalHandler}
      />
      <SuccessSendedLinkToEmailModal
        isOpen={isSuccessSendedLinkModal}
        close={closeModalHandler}
      />
    </>
  );
}
