import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { MessageItem as MessageItemType } from '../../types/messageTypes';
import WafeSurfer from 'containers/main/EntitySettingsContainer/components/NeiroTab/waveSafer';
import MessageActions from '../MessageActions/MessageActions';
import Spinners from 'components/Spinners';

import styles from './styles.module.scss';

interface MessageItemProps {
  message: MessageItemType;
  initialMessage: boolean;
}

const MessageItem = ({ message, initialMessage }: MessageItemProps) => {
  const [animationText, setAnimationText] = useState('');
  const { id, isNeyro, text, timeStamp, voice, blob } = message;
  const textMessage = initialMessage ? animationText : text;

  useEffect(() => {
    if (initialMessage) {
      let currentIndex = 0;

      const interval = setInterval(() => {
        if (currentIndex <= text.length) {
          setAnimationText(text.substring(0, currentIndex));
          currentIndex++;
        } else {
          clearInterval(interval);
        }
      }, 30);

      return () => clearInterval(interval);
    }
  }, [text, initialMessage]);

  return (
    <div
      id={id}
      className={`${styles.wrapper} ${!isNeyro ? styles.userMessage : ''}`}
    >
      <div
        className={`${styles.messageContainer} ${
          voice && blob ? styles.wafeSurfer : ''
        }`}
      > 
        { textMessage ? (
          <div>
            {voice && blob ? (
              <WafeSurfer
                url={blob}
                volume={0.7}
                className={styles.wafeSurferProgress}
                nextParams={{
                  barWidth: 1,
                  barGap: 2,
                }}
              />
            ) : (
              <p className={styles.text}>
                {initialMessage ? animationText : text}
              </p>
            )}
          </div>
        ) : (
          <div className={styles.dotSpinner}>
            <Spinners type='dot' />
          </div>
          )
        }
        <div className={styles.footer}>
          {isNeyro && (
            <MessageActions
              isLiked={false}
              isAddedToMemo={false}
              textToCopy={text}
            />
          )}
          <div className={styles.data}>{moment(timeStamp).format('LT')}</div>
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
