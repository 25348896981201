// Libs
import { isNil, isEmpty } from 'ramda';
import { stringify } from 'querystring';
import { createSecurityReqObj } from './create-security-req-obj/index';

export const createSecurityReqString = (data) => {
  const securityObject = createSecurityReqObj(data);

  if (isNil(data) || isEmpty(data)) return '';
  return '?' + stringify(securityObject);
};
