//Refactoring №3
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import getToken from 'utils/auth/get-token';

function PrivateRoute(props) {
  const isAuthenticated = !!getToken();
  const isFolderPage = window.location.pathname.startsWith('/folder/');
  const { component: Component, ...rest } = props;

  const render = (props) => {
    if (!isAuthenticated && !isFolderPage) {
      return <Redirect to="/" />;
    }

    return <Component {...props} />;
  };

  return <Route {...rest} render={render} />;
}

export default PrivateRoute;
