import React from 'react';

const EncryptedIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.68657 15.1738H10.3134V12.4944H13V11.8692H10.3134V9.18979H9.68657V11.8692H7V12.4944H9.68657V15.1738ZM5.34328 17.2727C4.97388 17.2727 4.65765 17.1415 4.39459 16.8792C4.13153 16.6168 4 16.3014 4 15.933V8.43062C4 8.0622 4.13153 7.74681 4.39459 7.48445C4.65765 7.22209 4.97388 7.09091 5.34328 7.09091H6.68657V5.30463C6.68657 4.38171 7.00746 3.60021 7.64925 2.96013C8.29104 2.32004 9.07463 2 10 2C10.9254 2 11.709 2.32004 12.3507 2.96013C12.9925 3.60021 13.3134 4.38171 13.3134 5.30463V7.09091H14.6567C15.0261 7.09091 15.3424 7.22209 15.6054 7.48445C15.8685 7.74681 16 8.0622 16 8.43062V15.933C16 16.3014 15.8685 16.6168 15.6054 16.8792C15.3424 17.1415 15.0261 17.2727 14.6567 17.2727H5.34328ZM5.34328 16.6475H14.6567C14.8657 16.6475 15.0373 16.5805 15.1716 16.4466C15.306 16.3126 15.3731 16.1414 15.3731 15.933V8.43062C15.3731 8.22222 15.306 8.05104 15.1716 7.91707C15.0373 7.78309 14.8657 7.71611 14.6567 7.71611H5.34328C5.13433 7.71611 4.96269 7.78309 4.82836 7.91707C4.69403 8.05104 4.62687 8.22222 4.62687 8.43062V15.933C4.62687 16.1414 4.69403 16.3126 4.82836 16.4466C4.96269 16.5805 5.13433 16.6475 5.34328 16.6475ZM7.31343 7.09091H12.6866V5.30463C12.6866 4.56034 12.4254 3.9277 11.903 3.4067C11.3806 2.8857 10.7463 2.6252 10 2.6252C9.25373 2.6252 8.6194 2.8857 8.09701 3.4067C7.57463 3.9277 7.31343 4.56034 7.31343 5.30463V7.09091Z"
      fill={color}
    />
  </svg>
);

EncryptedIcon.defaultProps = {
  color: 'currentColor',
};

export default EncryptedIcon;
