export const SECURITIES_DEACTIVATE = 'SECURITIES_DEACTIVATE'
export const SECURITIES_DEACTIVATE_SUCCESS = 'SECURITIES_DEACTIVATE_SUCCESS'
export const SECURITIES_DEACTIVATE_FAILURE = 'SECURITIES_DEACTIVATE_FAILURE'

/**
 *
 * @param {IAccountSecurityOption} securityOption
 * @returns {{type: string, payload: *}}
 */
export function securitiesDeactivate(securityOption) {
  return {
    type: SECURITIES_DEACTIVATE,
    payload: securityOption,
  }
}

/**
 *
 * @param {IAccountSecurityOption} securityOption
 * @returns {{type: string, payload: *}}
 */
export function securitiesDeactivateSuccess(securityOption) {
  return {
    type: SECURITIES_DEACTIVATE_SUCCESS,
    payload: securityOption,
  }
}

/**
 *
 * @param {IAccountSecurityOption} securityOption
 * @returns {{type: string, payload: *}}
 */
export function securitiesDeactivateFailure(securityOption) {
  return {
    type: SECURITIES_DEACTIVATE_FAILURE,
    payload: securityOption,
  }
}
