//Refactoring №3
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { matchPath } from 'react-router';

import UserContext from 'store/home/contexts/user-context';
import { isMemberChecker, isGuestChecker, isOwnerChecker } from 'utils/roles'

import MainSidebar from './components/MainSidebar';
import SettingsSidebar from './components/SettingsSidebar';

export const Sidebar = ({ className = '', setCurrentSizes, currentSizes }) => {
  const { user } = useContext(UserContext);
  const showLayout = !matchPath(location.pathname, { path: '/editor/:fileId' });
  const isGuest =  isGuestChecker(user.role);
  const isMember = isMemberChecker(user.role);
  const isOwner = isOwnerChecker(user.role)

  return (
    <>
      {showLayout && (
        <Switch>
          <Route path={(isMember || isGuest) ? ['/account'] : ['/account', '/workspace']}>
            <SettingsSidebar isOwner={isOwner} isGuest={isGuest} className={className} />
          </Route>
          <Route path="/">
            <MainSidebar
              setCurrentSizes={setCurrentSizes}
              currentSizes={currentSizes}
              isGuest={isGuest}
              isMember={isMember}
              className={className}
            />
          </Route>
        </Switch>
      )}
    </>
  );
};
