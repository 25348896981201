import React from 'react'

const Alert = ({ color }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.9"
      y="0.9"
      width="22.2"
      height="22.2"
      rx="11.1"
      stroke="white"
      strokeWidth="1.2"
    />
    <path
      d="M8.82364 15.176C8.9411 15.2935 9.09281 15.3522 9.24942 15.3522C9.40603 15.3522 9.55774 15.2935 9.67519 15.176L12.0047 12.8465L14.3342 15.176C14.4517 15.2935 14.6034 15.3522 14.76 15.3522C14.9166 15.3522 15.0683 15.2935 15.1858 15.176C15.4207 14.9411 15.4207 14.5643 15.1858 14.3293L12.8465 11.9998L15.176 9.6703C15.4109 9.43539 15.4109 9.05855 15.176 8.82364C14.9411 8.58873 14.5643 8.58873 14.3294 8.82364L11.9998 11.1532L9.6703 8.82364C9.43539 8.58873 9.05855 8.58873 8.82364 8.82364C8.58873 9.05855 8.58873 9.43539 8.82364 9.6703L11.1532 11.9998L8.82364 14.3293C8.58873 14.5643 8.58873 14.9411 8.82364 15.176Z"
      fill={color}
    />
  </svg>
)

Alert.defaultProps = {
  color: '#FFFFFF',
}

export default Alert
