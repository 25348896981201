//Refactoring №3
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { ethers } from 'ethers';
import axios from 'axios';

import { signUpMetamaskEffect, loginMetamask } from 'store/auth/effects/index';
import { isMobile } from 'utils/mobile';
import { checkMetamaskInstalled } from 'utils/crypto/wallet/metamask';
import { injected } from 'utils/crypto/wallet/connectors';
import { getSearchParamByLocation } from 'utils/url/get-search-param-by-location';

import Preloader from 'components/shared/Preloader';

import CryptoCheckPluginModal from '../crypto-check-plugin-modal';
import LoginMetamaskError from '../login-metamask-error';
import { signMessage } from '../login-crypto';

export const getAddress = async (setErrors) => {
  try {
    await window.metamask.send('eth_requestAccounts');
    const provider = new ethers.providers.Web3Provider(window.metamask);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    return {
      address,
    };
  } catch (err) {
    setErrors && setErrors(err.message, err?.code);
    throw err;
  }
};

const TOKEN_ID = 1;

function SignInMetamaskContainer({ history }) {
  const dispatch = useDispatch();
  const referral = getSearchParamByLocation(location, 'referral');
  const [modalOpen, setModalOpen] = useState(false);
  const [buyNftOpen, setBuyNftOpen] = useState(false);
  const [check, setCheck] = useState(false);
  const [account, setAccount] = useState(false);

  const login = async () => {
    const { signature, address } = await signMessage();
    loginMetamask(address, signature, history, dispatch).catch(() => {
      const chainId = window.metamask.networkVersion;
      signUpMetamaskEffect(account, referral, chainId).then(() => {
        loginMetamask(address, signature, history, dispatch);
      });
    });
  };

  useEffect(() => {
    if (account && check) {
      login();
    }
  }, [check, account]);

  useEffect(() => {
    if (!window.metamask) setModalOpen(true);
    connect();
  }, []);

  const handleSend = async (account) => {
    // Проверкка покупки
    const res = await axios({
      baseURL: `${process.env.REACT_APP_API_PATH}/nft/check`,
      method: 'POST',
      data: { address: account, tokenId: TOKEN_ID },
    });
    if (!res?.data?.valid) setBuyNftOpen(true);
    setCheck(res?.data?.valid ?? false);
  };

  const connect = async () => {
    if (isMobile && !checkMetamaskInstalled()) {
      const dappUrl = `${window?.location?.host}/sign-in`;
      const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
      return window.open(metamaskAppDeepLink, '_self');
    }
    if (!checkMetamaskInstalled()) {
      setModalOpen(true);
      return;
    }
    const { address } = await getAddress(injected);
    setAccount(address);
    handleSend(address);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <CryptoCheckPluginModal
        onClose={closeModal}
        isOpen={modalOpen}
        type="metamask"
      />
      <LoginMetamaskError isOpen={buyNftOpen} />
      {!buyNftOpen && !modalOpen && <Preloader />}
    </>
  );
}

export const SignInMetamask = React.memo(SignInMetamaskContainer);
