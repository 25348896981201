import React from 'react'

const File = ({ color, type }) => (
  <svg
    viewBox="0 0 106 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="default default-image"
  >
    <g filter="url(#filter0_dd)">
      <mask id="path-1-inside-1" fill="white">
        <path d="M16.4609 16H70.0758L80.5373 26.4614L90.9987 36.9229V114.076H16.4609V16Z" />
      </mask>
      <path
        d="M16.4609 16H70.0758L80.5373 26.4614L90.9987 36.9229V114.076H16.4609V16Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M16.4609 16V15H15.4609V16H16.4609ZM90.9987 114.076V115.076H91.9987V114.076H90.9987ZM16.4609 114.076H15.4609V115.076H16.4609V114.076ZM90.9987 36.9229H91.9987V36.5087L91.7058 36.2158L90.9987 36.9229ZM70.0758 16L70.7829 15.2929L70.4901 15H70.0758V16ZM90.9987 113.076H16.4609V115.076H90.9987V113.076ZM17.4609 114.076V16H15.4609V114.076H17.4609ZM79.8302 27.1686L90.2916 37.63L91.7058 36.2158L81.2444 25.7543L79.8302 27.1686ZM89.9987 36.9229V114.076H91.9987V36.9229H89.9987ZM16.4609 17H70.0758V15H16.4609V17ZM69.3687 16.7071L79.8302 27.1686L81.2444 25.7543L70.7829 15.2929L69.3687 16.7071Z"
        fill="white"
        mask="url(#path-1-inside-1)"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 76.1528H62.2303V103.614H6V76.1528Z"
      fill="#206ACF"
    />
    <text className="icon__img--text" fill="#FFFFFF">
      <tspan x="15" y="95">
        {type?.split('/').pop().replace('.', '')}
      </tspan>
    </text>
    <path d="M87 35L70 18V35H87Z" fill={color} />
    <defs>
      <filter
        id="filter0_dd"
        x="0.460938"
        y="0"
        width="105.538"
        height="129.076"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="6" dy="6" />
        <feGaussianBlur stdDeviation="4.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0520105 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx="-6" dy="-6" />
        <feGaussianBlur stdDeviation="5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.295974 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow"
          result="effect2_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="53.7298"
        y1="16"
        x2="53.7298"
        y2="114.076"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9DCE3" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

File.defaultProps = {
  color: '#C1C9D9',
}

export default File
