import React from 'react'

const Unshare = ({ onCancel }) => {
  return (
    <div className="right-menu__container remove-user">
      <p>
        Everyone will be removed from this folder. You will still keep a copy of
        this folder in GhostDrive.
      </p>

      <div className="right-menu__label">
        Remove wipe
        <div className="right-menu__input-container">
          <label className="right-menu__input-radio-label">
            <input
              type="radio"
              name="remove"
              className="right-menu__input-radio"
              value="1"
              checked
            />
            <span className="right-menu__input-radio-styled" />
            <span className="right-menu__input-radio-title">Yes</span>
          </label>
          <label className="right-menu__input-radio-label">
            <input
              type="radio"
              name="remove"
              className="right-menu__input-radio"
              value="0"
            />
            <span className="right-menu__input-radio-styled" />
            <span className="right-menu__input-radio-title">No</span>
          </label>
        </div>
      </div>

      <button
        className="button button--primary text--medium text--12 text--uppercase bottom-button"
        type="button"
      >
        Unshare
      </button>
      <button className="link" type="button" onClick={onCancel}>
        Cancel
      </button>
    </div>
  )
}

export default Unshare
