import React from 'react'

const Eye = ({ color }) => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><mask id="a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse" style={{maskType:"alpha"}}> <path fill={color} d="M0 0h24v24H0z"/></mask><g mask="url(#a)"><path fill={color} d="m15.45 12.65-.8-.8c.15-.867-.096-1.621-.737-2.263-.642-.641-1.396-.887-2.263-.737l-.8-.8c.167-.067.342-.117.525-.15.183-.033.392-.05.625-.05 1.017 0 1.88.354 2.588 1.062.708.709 1.062 1.571 1.062 2.588 0 .233-.017.446-.05.637a2.185 2.185 0 0 1-.15.513Zm3.15 3.075-.75-.675a10.962 10.962 0 0 0 1.688-1.588A8.9 8.9 0 0 0 20.8 11.5c-.833-1.683-2.03-3.021-3.588-4.013C15.654 6.496 13.917 6 12 6c-.483 0-.958.033-1.425.1a9.625 9.625 0 0 0-1.375.3l-.775-.775A9.64 9.64 0 0 1 10.2 5.15c.6-.1 1.2-.15 1.8-.15 2.167 0 4.15.596 5.95 1.787 1.8 1.192 3.125 2.763 3.975 4.713-.367.8-.821 1.554-1.363 2.262a10.913 10.913 0 0 1-1.962 1.963Zm1.125 5.425-3.9-3.9c-.433.2-.979.375-1.637.525A9.866 9.866 0 0 1 12 18c-2.183 0-4.167-.596-5.95-1.788A10.87 10.87 0 0 1 2.075 11.5c.383-.883.896-1.713 1.538-2.488.641-.775 1.32-1.42 2.037-1.937l-2.8-2.8.725-.7 16.85 16.85-.7.725ZM6.35 7.775c-.567.417-1.15.958-1.75 1.625a8.246 8.246 0 0 0-1.4 2.1c.833 1.683 2.029 3.02 3.587 4.012C8.346 16.504 10.083 17 12 17c.583 0 1.167-.054 1.75-.163.583-.108 1.025-.22 1.325-.337l-1.65-1.65c-.15.083-.367.154-.65.212a3.849 3.849 0 0 1-.775.088c-1.017 0-1.879-.354-2.587-1.063-.709-.708-1.063-1.57-1.063-2.587 0-.217.03-.463.088-.738.058-.275.129-.504.212-.687l-2.3-2.3Z"/></g></svg>
)

Eye.defaultProps = {
  color: 'currentColor',
}

export default Eye
