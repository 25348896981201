import React from 'react';

const ColorIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 3.98334C16 2.15297 14.9791 0.316312 14.9357 0.239031C14.8527 0.091375 14.6965 0 14.5271 0C14.3577 0 14.2015 0.091375 14.1185 0.239C14.075 0.316281 13.0542 2.15297 13.0542 3.98331C13.0542 4.60206 13.4378 5.13253 13.9796 5.35031C13.9273 5.63156 13.8556 6.03269 13.775 6.52706H13.5229C12.1183 6.52706 10.9792 5.39059 10.9792 3.98331C10.9792 2.06084 9.42387 0.502062 7.49791 0.502062C3.35616 0.502094 0 3.85319 0 8C0 12.1417 3.35109 15.4979 7.49791 15.4979C9.65572 15.4979 11.6808 14.5688 13.0872 12.9978C13.066 13.5057 13.0542 14.0177 13.0542 14.5271C13.0542 15.3392 13.7149 16 14.5271 16C15.3392 16 16 15.3392 16 14.5271C16 10.6745 15.3215 6.67728 15.0745 5.35034C15.6163 5.13256 16 4.60206 16 3.98334ZM13.193 11.259C12.0236 13.298 9.85075 14.5604 7.49791 14.5604C3.8805 14.5604 0.9375 11.6174 0.9375 8C0.9375 4.38259 3.8805 1.43959 7.49791 1.43959C8.90272 1.43959 10.0417 2.57622 10.0417 3.98334C10.0417 5.51166 11.1861 7.46459 13.6279 7.46459C13.4363 8.76178 13.2903 10.0353 13.193 11.259ZM14.5271 15.0625C14.2319 15.0625 13.9917 14.8223 13.9917 14.5271C13.9917 12.1035 14.2665 9.61787 14.5272 7.80528C14.7878 9.61722 15.0625 12.1022 15.0625 14.5271C15.0625 14.8223 14.8223 15.0625 14.5271 15.0625ZM14.5271 4.51875C14.2319 4.51875 13.9917 4.27856 13.9917 3.98334C13.9917 3.11706 14.2723 2.21897 14.5276 1.58116C14.7826 2.21772 15.0625 3.11447 15.0625 3.98334C15.0625 4.27856 14.8223 4.51875 14.5271 4.51875Z"
      fill="currentColor"
    />
    <path
      d="M7.4983 2.51074C6.68614 2.51074 6.02539 3.17149 6.02539 3.98365C6.02539 4.79581 6.68614 5.45656 7.4983 5.45656C8.31049 5.45656 8.97125 4.79581 8.97125 3.98365C8.97125 3.17149 8.31049 2.51074 7.4983 2.51074ZM7.4983 4.51909C7.20308 4.51909 6.96289 4.2789 6.96289 3.98368C6.96289 3.68846 7.20308 3.44828 7.4983 3.44828C7.79355 3.44828 8.03374 3.68846 8.03374 3.98368C8.03374 4.2789 7.79355 4.51909 7.4983 4.51909Z"
      fill="currentColor"
    />
    <path
      d="M7.4983 10.543C6.68614 10.543 6.02539 11.2037 6.02539 12.0159C6.02539 12.828 6.68614 13.4888 7.4983 13.4888C8.31049 13.4888 8.97125 12.828 8.97125 12.0159C8.97125 11.2037 8.31049 10.543 7.4983 10.543ZM7.4983 12.5513C7.20308 12.5513 6.96289 12.3111 6.96289 12.0159C6.96289 11.7207 7.20308 11.4805 7.4983 11.4805C7.79355 11.4805 8.03374 11.7207 8.03374 12.0159C8.03374 12.3111 7.79355 12.5513 7.4983 12.5513Z"
      fill="currentColor"
    />
    <path
      d="M4.01933 4.51953C3.20714 4.51953 2.54639 5.18028 2.54639 5.99244C2.54639 6.80459 3.20714 7.46534 4.01933 7.46534C4.83149 7.46534 5.49224 6.80459 5.49224 5.99244C5.49224 5.18028 4.83149 4.51953 4.01933 4.51953ZM4.01933 6.52788C3.72408 6.52788 3.48389 6.28769 3.48389 5.99247C3.48389 5.69725 3.72408 5.45706 4.01933 5.45706C4.31455 5.45706 4.55473 5.69725 4.55473 5.99247C4.55473 6.28769 4.31455 6.52788 4.01933 6.52788Z"
      fill="currentColor"
    />
    <path
      d="M10.9763 8.53516C10.1642 8.53516 9.50342 9.19591 9.50342 10.0081C9.50342 10.8202 10.1642 11.481 10.9763 11.481C11.7885 11.481 12.4492 10.8202 12.4492 10.0081C12.4492 9.19591 11.7885 8.53516 10.9763 8.53516ZM10.9763 10.5435C10.6811 10.5435 10.4409 10.3033 10.4409 10.0081C10.4409 9.71288 10.6811 9.47269 10.9763 9.47269C11.2715 9.47269 11.5117 9.71288 11.5117 10.0081C11.5117 10.3033 11.2715 10.5435 10.9763 10.5435Z"
      fill="currentColor"
    />
    <path
      d="M4.01933 8.53516C3.20714 8.53516 2.54639 9.19591 2.54639 10.0081C2.54639 10.8202 3.20714 11.481 4.01933 11.481C4.83149 11.481 5.49224 10.8202 5.49224 10.0081C5.49224 9.19591 4.83149 8.53516 4.01933 8.53516ZM4.01933 10.5435C3.72408 10.5435 3.48389 10.3033 3.48389 10.0081C3.48389 9.71288 3.72408 9.47269 4.01933 9.47269C4.31455 9.47269 4.55473 9.71288 4.55473 10.0081C4.55473 10.3033 4.31455 10.5435 4.01933 10.5435Z"
      fill="currentColor"
    />
  </svg>
);

ColorIcon.defaultProps = {};

export default ColorIcon;
