// Libs
import * as R from 'ramda'

// Utils
import getSecuritiesList from 'utils/files-folders/get-securities-list'
import getArrayFromData from 'utils/array/get-array-from-data'

// Store
import * as fileSecurityCheckActions from '../../actions/file/entities-security-check.actions'
import fileMakeActionEffect from './entity-make-action.effect'

/**
 *
 * @param {object | []} selectedEntities
 * @param {object} actionType
 * @param {object} [addData={}]
 * @param {object} history
 *
 * @returns {Function}
 */
export default (selectedEntities, actionType, addData = {}, history) =>
  async (dispatch) => {
    const actionEntities = getArrayFromData(selectedEntities)
    const securityCheckEntities = R.concat(
      actionEntities,
      getArrayFromData(addData?.securityCheckEntities)
    )
    const securitiesList = getSecuritiesList(securityCheckEntities)

    dispatch(
      fileSecurityCheckActions.entitiesSecurityCheck({
        entities: actionEntities,
        actionType,
        securitiesList,
        addData,
      })
    )
    if (
      securitiesList.length &&
      securitiesList.some((security) => !security.value)
    )
      return
    return dispatch(fileMakeActionEffect(null, history, addData))
  }
