import React from 'react'

const SettingsSharedFolder = ({ onCancel }) => {
  return (
    <div className="right-menu__container settings-shared-folder">
      <label className="right-menu__label">
        Folder Name
        <input
          type="text"
          name="name"
          className="right-menu__input"
          placeholder="Name your shared folder"
        />
      </label>

      <button
        className="button button--primary text--medium text--12 text--uppercase middle-button"
        type="button"
      >
        REMOVE
      </button>
      <button className="link" type="button" onClick={onCancel}>
        Cancel
      </button>

      <button
        className="button button--primary button--primary_outline text--medium text--12 text--uppercase bottom-button"
        type="button"
      >
        Unshare Folder
      </button>
    </div>
  )
}

export default SettingsSharedFolder
