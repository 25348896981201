//Refactoring №4
import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import cn from 'classnames';

import {
  acceptMultisigAction,
  declineMultisigAction,
} from 'store/web3/multisig';
import MainContext from 'store/main/context/main-context';
import UserContext from 'store/home/contexts/user-context';
import { getAvatarFileEffect } from 'store/home/effects';

import { getUserPublicAddress } from 'utils/web3Helper';
import { isContainInArray } from 'utils/array/is-contain';
import { IUser } from 'interfaces/user';
import { IWorkspace } from 'interfaces/workspace';

import Avatar from 'components/Avatar';
import Tooltip from 'components/shared/Tooltip/tooltip';
import Button, { ButtonTheme } from 'components/Button';

import s from './style.module.scss';
import { UPLOAD_AVATAR_TYPES } from 'config/user-role-types';

export const NOTIFICATION_MESSAGE = {
  multisig_delete: 'sends a request to delete',
  multisig_share: 'has sent a request of public share',
  multisig_unshare: 'has requested to unshare the',
  multisig_change_required:
    'has sent a request to modify the threshold number from',
  multisig_delete_myself: 'You will be removed from the Multi-Signature group',
  multisig_delete_participant: 'has sent a request of delete',
  multisig_add_participant: 'sends a request to add a member',
  multisig_add_participant2: 'of the Multi-Signature group of',
  multisig_canceled_request: 'canceled a request to',
  multisig_share_by_email: 'has sent a request of share the',
};

export const isMultisigMessage = (message: string) => {
  return (
    isContainInArray(message, NOTIFICATION_MESSAGE.multisig_delete) ||
    isContainInArray(message, NOTIFICATION_MESSAGE.multisig_share_by_email) ||
    isContainInArray(message, NOTIFICATION_MESSAGE.multisig_share) ||
    isContainInArray(message, NOTIFICATION_MESSAGE.multisig_unshare) ||
    isContainInArray(message, NOTIFICATION_MESSAGE.multisig_change_required) ||
    isContainInArray(message, NOTIFICATION_MESSAGE.multisig_delete_myself) ||
    isContainInArray(
      message,
      NOTIFICATION_MESSAGE.multisig_delete_participant
    ) ||
    (isContainInArray(message, NOTIFICATION_MESSAGE.multisig_add_participant) &&
      isContainInArray(message, NOTIFICATION_MESSAGE.multisig_add_participant2))
  );
};

type IProps = {
  additional: string | null;
  created_at: number;
  id: number;
  index: number;
  join_link: string | null;
  message: string;
  onReview: (id: number) => void;
  owner: IUser;
  status: number | null;
  updated_at: string;
  user: IUser;
  viewed: boolean;
};

const NotificationItem = (props: IProps) => {
  const {
    id,
    owner,
    viewed,
    message,
    created_at,
    onReview,
    additional,
    status,
  } = props;
  const {
    state: { workspace: currentWorkspace, workspaces },
  } = useContext(MainContext);
  const { user: me } = useContext(UserContext);
  const { t } = useTranslation('popup');
  const [hover, setHover] = useState(false);
  const [logoValue, setLogoValue] = useState('');
  const is3DAvatar = owner.logo && owner.logo.indexOf('.glb') !== -1;
  const additionalData = useMemo(
    () => (additional && JSON.parse(additional)) || {},
    [additional]
  );
  const startDay = useMemo(() => moment().startOf('day').unix(), []);
  const weekAgo = useMemo(() => moment().subtract(7, 'days').unix(), []);
  const twoWeekAgo = useMemo(() => moment().subtract(14, 'days').unix(), []);
  const joinLinks = useMemo(() => props?.join_link, []);

  const timePrefix = useMemo(() => {
    if (created_at > startDay) {
      return t('notification.today');
    } else if (created_at < startDay && created_at > weekAgo) {
      return `${moment.unix(created_at).format('dddd')}`;
    } else if (created_at < weekAgo && created_at > twoWeekAgo) {
      return `${t('notification.last')} ${moment
        .unix(created_at)
        .format('dddd')}`;
    } else {
      return moment.unix(created_at).format('DD-MM-YYYY');
    }
  }, [created_at, startDay, twoWeekAgo, weekAgo, t]);

  useEffect(() => {
    if (owner.logo && !is3DAvatar) {
      getAvatarFileEffect(owner?.id, UPLOAD_AVATAR_TYPES.user).then((data) => data && setLogoValue(data));
    }
    if (is3DAvatar) setLogoValue(owner?.logo);
  }, [owner.logo]);

  const onAcceptMultisigAction = () => {
    acceptMultisigAction({
      additional: additionalData,
      id,
      address: getUserPublicAddress(me.user_public_addresses).toLowerCase(),
    });
  };

  const onDeclineMultisigAction = () => {
    declineMultisigAction({
      additional: additionalData,
      id,
      address: getUserPublicAddress(me.user_public_addresses).toLowerCase(),
    });
  };

  const renderActions = () => {
    if (props.join_link)
      return (
        <a className={s.notification_join} href={joinLinks || undefined}>
          {t('notification.join')}
        </a>
      );

    if (
      isContainInArray(message, NOTIFICATION_MESSAGE.multisig_canceled_request)
    )
      return (
        <div className={s.multysigResult}>
          <Button className={s.multysigAcceptBtn} disabled>
            Canceled
          </Button>
        </div>
      );
    if (isMultisigMessage(message)) {
      const nextWSid = additionalData.workspaceId;
      const isDisabled = nextWSid !== currentWorkspace.id;
      const nextWorkspace = workspaces.find(
        (ws: IWorkspace) => ws.id === nextWSid
      );
      const userAddress = getUserPublicAddress(
        me.user_public_addresses
      ).toLowerCase();
      const acceptedByUser = !!additionalData?.accept?.find(
        (address: string) => address === userAddress
      );
      const declinedByUser = !!additionalData?.decline?.find(
        (address: string) => address === userAddress
      );
      if (acceptedByUser || declinedByUser || status !== 0) {
        return (
          <div className={s.multysigResult}>
            <Button
              className={
                status === 1 || acceptedByUser
                  ? s.multysigAcceptBtn
                  : s.multysigDeclineBtn
              }
              disabled
            >
              {status === 1 || acceptedByUser ? 'Accepted' : 'Rejected'}
            </Button>
          </div>
        );
      }

      return (
        <div className={s.multysigAction}>
          <Button
            className="tooltip-wrapper"
            theme={ButtonTheme.DARK}
            disabled={isDisabled}
            onClick={onAcceptMultisigAction}
          >
            {isDisabled && (
              <Tooltip
                className={cn(s.tooltip, 'tooltip')}
                text={
                  <span>
                    You should switch to the{' '}
                    <strong>{nextWorkspace?.name}</strong> workspace to perform
                    this activity
                  </span>
                }
              />
            )}
            Sign
          </Button>
          <Button
            className="tooltip-wrapper"
            theme={ButtonTheme.BORDERRED_DARK}
            disabled={isDisabled}
            onClick={onDeclineMultisigAction}
          >
            {isDisabled && (
              <Tooltip
                className={cn(s.tooltip, 'tooltip')}
                text={
                  <span>
                    You should switch to the{' '}
                    <strong>{nextWorkspace?.name}</strong> workspace to perform
                    this activity
                  </span>
                }
              />
            )}
            Reject
          </Button>
        </div>
      );
    }
  };

  return (
    <li
      className={cn(s.notificationItem, hover && s.notificationItem_hover)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-test={`main-sidebar_notification_popup_item[${props.index}]`}
    >
      <div className={s.notificationItem__avatar}>
        <Avatar
          className={s.notificationItem__avatar_icon}
          initialsClassName={s.notificationItem__avatar_initials}
          src={logoValue}
          name={owner?.displayed_name}
          color={owner?.color || ''}
          profile
        />
      </div>
      <div className={s.notificationItem__body}>
        <div
          className={s.notificationItem__body_text}
          dangerouslySetInnerHTML={{ __html: message }}
        ></div>
        {renderActions()}
        {!viewed && (
          <button
            className={s.notificationItem__body_read}
            onClick={() => onReview(id)}
            data-test={`main-sidebar_notification_popup_mark_as_read_button`}
          >
            {t('notification.markAsRead')}
          </button>
        )}

        <p className={cn(s.notificationItem__body_detail, viewed && s.viewed)}>
          {`${timePrefix} at ${moment.unix(created_at).format('h:mm A')}`}
        </p>
      </div>
    </li>
  );
};

export default NotificationItem;
