import authRequest from 'utils/request/auth-request';

export const saveEmailHashEffect = async (hash, email) => {
  return authRequest
    .post(`${process.env.REACT_APP_API_PATH}/save/hash/email`, { hash, email })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
};
