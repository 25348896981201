//Refactoring №2
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectStatusRecover,
  selectRecoverUser,
} from 'store/account/reducers/user-recover.reducer';
import { failUserRecovery } from 'store/account/actions/recover-user.actions';
import { createUserWorkspace } from 'store/workspace/effects';

import AuthInput from './auth-input';

const ERROR_MESSAGE = 'Please enter workspace name!';

const CreateWorkspace = () => {
  const [workspace, setWorkspace] = useState('');
  const [error, setError] = useState('');
  const dispatch = useDispatch();
  const recoveredUser = useSelector(selectRecoverUser);
  const startRecover = useSelector(selectStatusRecover);
  const history = useHistory();

  if (!startRecover) history.push('/');

  const enterWorkspace = ({ target: { value } }) => {
    if (error && value) {
      setError('');
    }
    setWorkspace(value);
  };

  const createWorkspace = () => {
    if (workspace) {
      createUserWorkspace({
        password: recoveredUser.password,
        email: recoveredUser.username,
        workspace,
      })
        .then(() => {
          history.push('/invite-member');
        })
        .catch(() => {
          history.push('/');
          dispatch(failUserRecovery());
        });
    } else {
      setError(ERROR_MESSAGE);
    }
  };

  return (
    <section className="auth">
      <header className="head__content">
        <div className="auth-title-wrapper">
          <div className="auth-beta-version-wrapper">
            <Link
              to="/"
              className="header__logo"
              data-test="create-workspace_logo_link"
            >
              <img
                src={require('static/assets/img/ghost-logo.svg').default}
                alt="Logo"
              />
            </Link>
          </div>
        </div>
      </header>
      <div className="login-form-wrapper">
        <div className="auth-left" />
        <div className="auth-right auth-right__no-height  auth-right_signup">
          <div className="auth__form auth__shared step-4 scroll-down">
            <h3 className="form__title">What’s the name of your workspace ?</h3>
            <p className="form__desc">
              This can be the name of an organization, team, firm, or type of
              business.
            </p>
            <AuthInput
              type="text"
              value={workspace || ''}
              onChange={enterWorkspace}
              onBlur={() => {}}
              hasError={error}
              errorMsg={error}
              name="workspace"
              placeholder="Workspace"
              data-test="create-workspace_workspace_field"
            />
            <div className="end-iem">
              <button
                type="submit"
                className="auth__square"
                onClick={createWorkspace}
                disabled={!!error}
                data-test="create-workspace_next_button"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateWorkspace;
