import * as activateTfaActions from 'store/home/actions/activate-tfa.actions';
import * as getUserInfoActions from 'store/home/actions/get-user-info.actions';
import { getUserInfoEffect } from 'store/home/effects';

import securitiesType from 'config/securities-types';
import authRequest from 'utils/request/auth-request';
import { API_GOOGLE_TFA } from 'constants/api-urls';

export function activateTfaEffect(params) {
  return async (dispatch) => {
    dispatch(activateTfaActions.activateTfa());

    const url = API_GOOGLE_TFA;
    try {
      const result = await authRequest.post(url, params);
      const { tfa } = securitiesType;
      dispatch(getUserInfoEffect());
      dispatch(activateTfaActions.activateTfaSuccess(result));
      dispatch(
        getUserInfoActions.getUserUpdate({
          data: {
            [tfa.activatedStatusName]: true,
          },
        })
      );
    } catch (e) {
      dispatch(activateTfaActions.activateTfaFail(e));
      throw e;
    }
  };
}
