//Refactoring №3
import React from 'react';

import classNames from 'classnames';
import styles from './style.module.scss';

const Corners = ({ top, bottom, left, right }) => {
  const cornersClasses = classNames(
    styles.corners,
    { [styles.top]: top },
    { [styles.bottom]: bottom },
    { [styles.left]: left },
    { [styles.right]: right }
  );
  return <div className={cornersClasses}></div>;
};

export default Corners;
