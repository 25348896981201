import React from 'react';

const TagIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip-tag-0)">
      <path
        d="M3.50001 5.00003C2.67287 5.00003 2 4.32716 2 3.50001C2 2.67287 2.67287 2 3.50001 2C4.32716 2 5.00003 2.67287 5.00003 3.50001C5.00003 4.32716 4.32719 5.00003 3.50001 5.00003ZM3.50001 3.00004C3.22414 3.00004 3.00001 3.22417 3.00001 3.50005C3.00001 3.77592 3.22414 4.00005 3.50001 4.00005C3.77589 4.00005 4.00002 3.77592 4.00002 3.50005C4.00002 3.22417 3.77592 3.00004 3.50001 3.00004Z"
        fill="currentColor"
      />
      <path
        d="M8.50002 16C8.3721 16 8.24414 15.9512 8.14652 15.8535L0.14647 7.85351C0.0527506 7.75976 0 7.63281 0 7.50001V0.500003C0 0.223626 0.223626 0 0.500003 0H7.50001C7.63282 0 7.75979 0.0527506 7.85351 0.14647L15.8535 8.14649C16.0488 8.3418 16.0488 8.6582 15.8535 8.85352L8.85352 15.8535C8.75586 15.9512 8.62794 16 8.50002 16ZM1.00001 7.29298L8.50002 14.793L14.793 8.50002L7.29298 1.00001H1.00001V7.29298Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip-tag-0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

TagIcon.defaultProps = {};

export default TagIcon;
