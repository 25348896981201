//Refactoring №3
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';

import getImagePreviewEffect from 'store/home/effects/file/get-image-preview.effect';
import { selectedEntityView } from 'store/home/actions/selected-entity.actions';
import { filesGetEffect } from 'store/file/effects';
import { trimLongText } from 'utils';
import { isIpfsService } from 'utils/ipfs/link';
import {
  canBePreview,
  imageMediaTypesPreview,
} from 'config/image-file-extensions';

import CustomFileSmallIcon from 'components/CustomFileIcon/CustomFileSmallIcon';
import CustomFolderIcon from 'components/CustomFileIcon/CustomFolderIcon';

import style from './style.module.scss';

const previewImageSizePerViewType = {
  width: 60,
  height: 60,
};

const SearchOption = ({ extension, title, updated, type, slug, onClick }) => {
  const [filePreview, setFilePreview] = useState(null);
  const [file, setFile] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    filesGetEffect(slug, { filename: title, filesize: 1 })
      .then((file) => {
        setFile(file.entry);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (file) {
      const fileSignal = axios.CancelToken.source();
      if (file?.slug && canBePreview.includes(file.mime)) {
        if (!isIpfsService(file.service) && !file.is_clientside_encrypted) {
          getImagePreviewEffect(
            file.slug,
            previewImageSizePerViewType?.width,
            previewImageSizePerViewType?.height,
            'crop',
            fileSignal.token,
            file.mime,
            {
              filename: file.name,
              filesize: file.size,
            }
          ).then((data) => {
            if (data) {
              setFilePreview(data);
            }
          });
        }
      }

      return () => {
        fileSignal.cancel('getImagePreviewEffect is being canceled');
      };
    }
  }, [file]);

  const handleClick = () => {
    onClick();
    if (extension === 'pages') {
      const win = window.open(`editor/${slug}`, '_blank');
      win.focus();
      return;
    }
    if (type !== 'folder' && extension !== 'pages') {
      dispatch(selectedEntityView({ entity: file }));
      return;
    }

    if (history.location.pathname.includes('folder')) history.push(slug);
    else history.push(`folder/${slug}`);
  };

  const imageHasPreview = () =>
    !file?.securities?.length && imageMediaTypesPreview.includes(file?.mime);

  const renderIcon = () => {
    return type === 'file' ? (
      <CustomFileSmallIcon type={extension} />
    ) : (
      <CustomFolderIcon viewType="small-row" color={file?.color[0]?.hex} />
    );
  };

  return (
    <div className={style.search__options__option} onClick={handleClick}>
      <div className={style.search__option__preview}>
        <div className={style.search__option__preview__image}>
          {imageHasPreview() && filePreview ? (
            <img
              key={file.id}
              className={style.search__option__image}
              alt={file.name}
              loading="eager"
              src={filePreview}
            />
          ) : (
            renderIcon()
          )}
        </div>
        <p>{trimLongText(title, 35)}</p>
      </div>
      <p className={style.search__option__create}>{updated}</p>
    </div>
  );
};

export default SearchOption;
