/* eslint-disable no-debugger */
import { Contract } from 'ethers';

import authRequest from 'utils/request/auth-request';
import { getCurrentChainId } from 'utils/crypto';
import { getCoinbaseProvider } from 'utils/getCoinbaseProvider';
import { getSigner } from 'store/auth/effects';
import {  getAbi, getSigne, saveWorkspaceTransaction, attachContract, networkSwitcher } from 'store/web3'
import { setMultisigActivated, setMultisigPartisipant } from 'features/app'
import { CONTRACT_ALICE , CONTRACT_MULTYSIG_TYPE, FACTORY_ALICE } from 'config/multisig'
import {
  workspaceMembers,
  updateWorkspaceMultisigNames,
  getWorkspaceSmartContractActions
} from 'store/workspace/effects';
import { selectMultisigContract } from 'store/plan/reducers/workspace-plan.reducer';
import { updateNotificationAdditionalData } from 'store/notifications/effects/notification-settings.effect'
import { saveEmailHashEffect } from 'store/general/effects/hash'
import { updateNotification } from 'store/notifications/actions'
import { convertStringToBytes32, stringToHashBytes32 } from 'utils/string'
import { web3ErrorHandler, getUserPublicAddress, setMultisigAction, deletMultisigAction } from 'utils/web3Helper';
import { store } from 'store/root-store'
import { API_WORKSPACE } from 'constants/api-urls';

export const getMultisigPendingActions = async () => {
  try {
    return authRequest.get(`${API_WORKSPACE}/pending/multisig/actions`)
  } catch (error) {
    console.warn(error);
    throw error;
  }
};

export const creatMultySigContact = async ({ userList, ...data }, onSuccessDeploy, onErrorDeploy) => {
  try {
    const providerName = localStorage.getItem('PROVIDER');
    const currentProvider =
    providerName === 'coinbase' ? getCoinbaseProvider() : window.metamask;
    const network = await getCurrentChainId(
      { useMetamask: true },
      currentProvider
    );
    const signer = await getSigne(currentProvider);
    const { abi: factoryAbi, address: factoryAddress } = await getAbi(
      network,
      FACTORY_ALICE
    );
    const factoryContract = new Contract(factoryAddress, factoryAbi, signer);
    const req = {
      ...data,
      from: signer._address
    };
    const {
      data: { reqCooked, signature },
    } = await getSigner({
      type: 'SNewMultisigCall',
      contract: 'WorkspaceMultisigFactoryUpgradeable',
      contractAddress: factoryContract.address,
      chainId: network,
      req,
      currentProvider,
    });

    const contract = await factoryContract.newWorkspaceMultisigContract(
      reqCooked,
      signature,
      { value: reqCooked.callCost }
    );

    const tx = await contract.wait();
    const contractAddress = tx.events.find(
      (item) => item.event === 'AddNewWorkspaceMultisigContract'
    ).args['contractAddress'];
    const collectionOption = {
      name: 'Multisig',
      contract: contractAddress,
      type: CONTRACT_MULTYSIG_TYPE,
      need_confirm: data.requiredSignatures,
      network,
    };

    await attachContract(collectionOption)
    await updateWorkspaceMultisigNames(userList.map((user) => ({name: user.name, public_address: user.address })))
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash })
    onSuccessDeploy()
  } catch(error) {
    web3ErrorHandler(error)
    onErrorDeploy(error)
    console.warn(error)
  }
}

export const getMultisigContract = async (address) => {
  try {
    const contractAddress = address || store.getState().plan?.workspacePlan?.currentWs?.smart_contracts?.find((cntr) => cntr.type === CONTRACT_MULTYSIG_TYPE)?.smart_contract
    const providerName = localStorage.getItem('PROVIDER');
    const currentProvider =
    providerName === 'coinbase' ? getCoinbaseProvider() : window.metamask;
    const network = await getCurrentChainId(
      { useMetamask: true },
      currentProvider
    );
    const signer = await getSigne(currentProvider);
    const { abi: factoryAbi} = await getAbi(
      network,
      CONTRACT_ALICE
    );
    const contract = new Contract(contractAddress, factoryAbi, signer);
    return { contract: contract, network: network }
  } catch (error) {
    console.warn(error);
    throw error;
  }
};

export const deleteMultisigEffect = async (slug) => {
  try {
    const { contract, network } = await getMultisigContract()
    const contractDeleteFile = await contract.deleteFile(convertStringToBytes32(slug));
    await setMultisigAction({ chainId: network, txHash: contractDeleteFile.hash })
    const tx = await contractDeleteFile.wait();
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash });
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const getMultisigValuesEffect = async () => {
  try {
    const { contract } = await getMultisigContract()
    const participants = await contract.currentParticipants()
    const participantsRequired = await contract.participantsRequired()
    const { data } = await getWorkspaceSmartContractActions()
    const disabled = data.some((action) => parseInt(action.action_status) === 0)
    return { participants, participantsRequired, disabled }
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const isMultisigParticipant = async (participants = [], user, dispatch) => {
  try {
    const userCryptoWallet = getUserPublicAddress(user.user_public_addresses)
    const isParticipant = participants.some(({ public_address }) => public_address.toLowerCase() === userCryptoWallet.toLowerCase())
    dispatch(setMultisigActivated(true))
    isParticipant && dispatch(setMultisigPartisipant(true))
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const acceptMultisigAction= async ({ additional, address, id }) => {
  try {
    const multisig = selectMultisigContract(store.getState())
    multisig && await networkSwitcher(multisig?.network?.chain_id, false)
    const { contract, network } = await getMultisigContract()
    const additionalReq = {
      ...additional,
      accept: additional.accept
        ? [...additional.accept, address]
        : [address]
    }
    const acceptActionContract = await contract.acceptAction(additional.actionId)
    await setMultisigAction({ chainId: network, txHash: acceptActionContract.hash, notification: { additionalReq, id } })
    const tx =  await acceptActionContract.wait()
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash });
    const notification = await updateNotificationAdditionalData(id, additionalReq)
    store.dispatch(updateNotification(id, notification))
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const declineMultisigAction= async ({ additional, address, id }) => {
  try {
    const multisig = selectMultisigContract(store.getState())
    multisig && await networkSwitcher(multisig?.network?.chain_id, false)
    const { contract, network } = await getMultisigContract()
    const declineActionContract = await contract.declineAction(additional.actionId)
    const additionalReq = {
      ...additional,
      decline: additional.decline
        ? [...additional.decline, address]
        : [address]
    }
    await setMultisigAction({ chainId: network, txHash: declineActionContract.hash, notification: { additionalReq, id } })
    const tx = await declineActionContract.wait()
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash });
    const notification = await updateNotificationAdditionalData(id, additionalReq)
    store.dispatch(updateNotification(id, notification))
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const getMultisigActionStatus= async (actionId) => {
  try {
    const { contract } = await getMultisigContract()
    const actionInfo = await contract.getActionInfo(actionId)
    const partisipants = await contract.currentParticipants()
    const members = await workspaceMembers()
    const partisipantRequests = partisipants.
      map((address) => contract.getParticipantStatusInAction(actionId, address)
      .then((value) => ({ status: value, address })))
    const participantsRequired = await contract.participantsRequired()
    const actions = await Promise.all(partisipantRequests)
    const userActions = actions.map((action) => {
      const member = members.find((member) => {
        const localMember = member.user

        return localMember && localMember?.user_public_addresses[0]?.public_address?.toLowerCase() === action?.address?.toLowerCase()
      })

      return member ? { ...action, user: member.user } : false
    })

    return { participantsRequired, actions: userActions, actionInfo }
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const shareFileMultisig = async (slug) => {
  try {
    const { contract, network } = await getMultisigContract()
    const contractShareFile = await contract.shareFilePublic(convertStringToBytes32(slug));
    await setMultisigAction({ chainId: network, txHash: contractShareFile.hash })
    const tx = await contractShareFile.wait();
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash });
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const unshareFileMultisig = async (slug) => {
  try {
    const { contract, network } = await getMultisigContract()
    const contractUnshareFile = await contract.unShareFilePublic(convertStringToBytes32(slug));
    await setMultisigAction({ chainId: network, txHash: contractUnshareFile.hash })
    const tx = await contractUnshareFile.wait();
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash });
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const updateThresholdMultisig = async (threshold) => {
  try {
    const multisig = selectMultisigContract(store.getState())
    multisig && await networkSwitcher(multisig?.network?.chain_id, false)
    const { contract, network } = await getMultisigContract()
    const contractThresholdMultisig = await contract.changeParticipantsRequired(threshold)
    await setMultisigAction({ chainId: network, txHash: contractThresholdMultisig.hash })
    const tx = await contractThresholdMultisig.wait();
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash });
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const addParticipantMultisig = async ({ address, name }) => {
  try {
    const multisig = selectMultisigContract(store.getState())
    multisig && await networkSwitcher(multisig?.network?.chain_id, false)
    const { contract, network } = await getMultisigContract()
    const contractAddParticipantMultisig = await contract.addParticipant(address)
    await setMultisigAction({ chainId: network, txHash: contractAddParticipantMultisig.hash })
    const tx = await contractAddParticipantMultisig.wait();
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash, name });
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const removeParticipantMultisig = async (threshold) => {
  try {
    const multisig = selectMultisigContract(store.getState())
    multisig && await networkSwitcher(multisig?.network?.chain_id, false)
    const { contract, network } = await getMultisigContract()
    const contractRemoveParticipantMultisig = await contract.removeParticipant(threshold)
    await setMultisigAction({ chainId: network, txHash: contractRemoveParticipantMultisig.hash })
    const tx = await contractRemoveParticipantMultisig.wait();
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash });
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};


export const cancelMultisigRequest = async (actionId) => {
  try {
    const multisig = selectMultisigContract(store.getState())
    multisig && await networkSwitcher(multisig?.network?.chain_id, false)
    const { contract, network } = await getMultisigContract()
    const contractDeclineActionMultisig = await contract.declineAction(actionId)
    await setMultisigAction({ chainId: network, txHash: contractDeclineActionMultisig.hash })
    const tx = await contractDeclineActionMultisig.wait();
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash });
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const shareFileByEmailMultisig = async (slug, email) => {
  try {
    const { contract, network } = await getMultisigContract()
    const { hashString, hashBytes32 } = stringToHashBytes32(email)
    await saveEmailHashEffect(hashString, email)
    
    const contractShareFile = await contract.shareFileByEmail(convertStringToBytes32(slug), hashBytes32);
    await setMultisigAction({ chainId: network, txHash: contractShareFile.hash })
    const tx = await contractShareFile.wait();
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash });
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const shareFileByWalletMultisig = async (slug, address) => {
  try {
    const { contract, network } = await getMultisigContract()
    const contractShareFile = await contract.shareFileByWallet(convertStringToBytes32(slug), address);
    await setMultisigAction({ chainId: network, txHash: contractShareFile.hash })
    const tx = await contractShareFile.wait();
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash });
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const unshareFileByEmailMultisig = async (slug, email) => {
  try {
    const { contract, network } = await getMultisigContract()
    const { hashBytes32 } = stringToHashBytes32(email)
    const contractShareFile = await contract.unShareFileByEmail(convertStringToBytes32(slug), hashBytes32);
    await setMultisigAction({ chainId: network, txHash: contractShareFile.hash })
    const tx = await contractShareFile.wait();
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash });
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const unshareFileByWalletMultisig = async (slug, address) => {
  try {
    const { contract, network } = await getMultisigContract()
    const contractShareFile = await contract.unShareFileByWallet(convertStringToBytes32(slug), address);
    await setMultisigAction({ chainId: network, txHash: contractShareFile.hash })
    const tx = await contractShareFile.wait();
    await saveWorkspaceTransaction({ chainId: network, txHash: tx.transactionHash });
    await deletMultisigAction()
  } catch (error) {
    web3ErrorHandler(error, true)
    console.warn(error);
    throw error;
  }
};

export const getActionInfoMultisig = async (actionId) => {
  return authRequest
    .get(`${process.env.REACT_APP_API_PATH}/action/${actionId}`)
    .then(({ data: { actionUpdated } }) => (actionUpdated))
    .catch((e) => {
      throw e;
    });
};
