//Refactoring №4
import React, { useMemo } from 'react';
import CN from 'classnames';

import Spinner from 'components/Spinner';

import AddButton from './add-button';
import CopyButton from './copy-button';
import { ReactComponent as Corner } from './assets/corner.svg';
import { ReactComponent as ObtuseCorner } from './assets/obtuseCorner.svg';

import s from './style.module.scss';

export const ButtonTheme = {
  PRIMARY: s.themePrimary,
  DARK: s.themeDark,
  SECONDARY: s.themeSecondary,
  TRANSPARENT: s.themeTransparent,
  BORDERED: s.themeBordered,
  BORDERRED_DARK: s.themeBorderedDark,
  DISABLED_PRIMARY: s.themeDisabledPrimary,
  DISABLED_SECONDARY: s.themeDisabledSecondary,
  DISABLED_DARK: s.themeDisabledDark,
  NEW_BORDERRED: s.themeNewBordered,
  NEW_BORDERRED_DARK: s.themeNewBorderedDark,
  NEW_BORDERRED_BLUE: s.themeNewBorderedBlue,
  DISABLED_NEW_BORDERRED_DARK: s.themeDisabledNewBorderedDark,
  DISABLED_NEW_BORDERRED_BLUE: s.themeDisabledNewBorderedBlue,
};

type IProps = {
  type?: 'button' | 'submit' | 'reset' | undefined;
  htmlElement?: string;
  name?: string;
  className?: string;
  value?: string | number;
  showPreloader?: boolean;
  theme?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  children?: any;
  disabled?: boolean;
  'data-test'?: string;
};

export default function Button({
  type = 'button',
  htmlElement = 'button',
  name,
  className,
  value,
  showPreloader = false,
  theme = ButtonTheme.PRIMARY,
  onClick,
  children,
  disabled,
  ...props
}: IProps) {
  const content = useMemo(() => {
    if (showPreloader) {
      return <Spinner className={s.spinner} />;
    } else if (
      theme === ButtonTheme.NEW_BORDERRED ||
      theme === ButtonTheme.NEW_BORDERRED_DARK ||
      theme === ButtonTheme.NEW_BORDERRED_BLUE
    ) {
      return (
        <>
          <ObtuseCorner />
          <Corner />
          {children || value}
          <Corner />
          <Corner />
        </>
      );
    } else if (children) {
      return children;
    } else {
      return value;
    }
  }, [showPreloader, children, value]);

  const currentTheme = useMemo(() => {
    if (disabled) {
      switch (theme) {
        case ButtonTheme.PRIMARY:
          return ButtonTheme.DISABLED_PRIMARY;
        case ButtonTheme.DARK:
          return ButtonTheme.DISABLED_DARK;
        case ButtonTheme.NEW_BORDERRED_DARK:
          return CN(
            ButtonTheme.NEW_BORDERRED_DARK,
            ButtonTheme.DISABLED_NEW_BORDERRED_DARK
          );
        case ButtonTheme.NEW_BORDERRED:
          return CN(
            ButtonTheme.NEW_BORDERRED,
            ButtonTheme.DISABLED_NEW_BORDERRED_BLUE
          );
        case ButtonTheme.NEW_BORDERRED_BLUE:
          return CN(
            ButtonTheme.NEW_BORDERRED_BLUE,
            ButtonTheme.DISABLED_NEW_BORDERRED_BLUE
          );
        default:
          return ButtonTheme.DISABLED_SECONDARY;
      }
    }

    return theme;
  }, [theme, disabled]);

  return htmlElement === 'button' ? (
    <button
      onClick={onClick}
      type={type}
      className={CN(s.button, currentTheme, className)}
      disabled={disabled}
      data-test={props['data-test']}
      {...props}
    >
      {content}
    </button>
  ) : (
    <input
      type="button"
      name={name}
      className={CN(s.button, currentTheme, className)}
      value={value}
      onClick={onClick}
      disabled={disabled}
      data-test={props['data-test']}
    />
  );
}

export { AddButton, CopyButton };
