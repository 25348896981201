export const TrashActionTypes = {
  trashHeaderActionHandler: 'trashHeaderActionHandler',
  clearAllAction: 'clearAllAction',
};

export const setTrashHeaderActionHandler = (trashHeaderActionHandler) => ({
  payload: {
    trashHeaderActionHandler,
  },
  type: TrashActionTypes.trashHeaderActionHandler,
});

export const setIsClearAllPressed = (isClearAllPressed) => ({
  payload: { isClearAllPressed },
  type: TrashActionTypes.clearAllAction,
});
