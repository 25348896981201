/* eslint-disable no-unused-vars */
//Refactoring №2
import React, { useState, useEffect, useContext } from 'react';
import Loadable from 'react-loadable';
import { connect, useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'ramda';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';

import cn from 'classnames';

import DownloadLoader from 'components/DownloadLoader';
import GhostPassModal from 'containers/main/GhostPassModal';
import RightMenu from 'containers/main/Main/components/RightMenu';
import ErrorModal from 'containers/main/Main/components//ErrorModal';
import HeaderContainer from 'containers/header/header-container/newHeader';
import FileSecurityChecker from 'containers/security/EntitySecurityChecker';

import { fileUpdateDataEffect } from 'store/home/effects/file/file-update.effect';
import { setErrors } from 'store/errors/actions/index';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import MainContext from 'store/main/context/main-context';
import UserContext from 'store/home/contexts/user-context';
import RightMenuContext from 'store/home/contexts/right-menu';
import {
  selectedEntityShareClearEffect,
  selectedEntityMoveClearEffect,
  selectedEntityAccessPreferencesClear,
  selectedEntityClearEffect,
} from 'store/home/effects/selected-entity/selected-entity-clear-effect';
import { handleShowEncryptionKeyModal } from 'store/home/actions/file/encrypt.action';
import {
  closeEncryptionModal,
  changeEncryptionStatus,
} from 'store/home/actions/file/encryption-key.action';
import { fileFolderActionMiddleware } from 'containers/main/Main/middleware';

import { ConnectWalletModal } from 'containers/main/Main/components/ConnectWalletModal';
import ImageToImageModal from 'containers/header/header-container/components/ImageToImageModal';
import AiUpScallerModal from 'containers/header/header-container/components/AiUpScallerModal';
import { useLoadWorkspace } from 'containers/main/Main/hooks/useLoadWorkspace';
import useWindowSize from 'utils/hooks/use-window-size';
import Wrapper from './Main'
import EncryptionKeyModal from 'containers/header/header-container/encryption-key-modal';

import style from 'containers/main/Main/style.module.scss';

const LazyFileView = Loadable({
  loader: () => import('containers/main/FileView/file-view'),
  loading: () => null,
});

const LazySharingModal = Loadable({
  loader: () => import('components/SharingModal/sharing-modal'),
  loading: () => null,
});

const LazyAccessPreferencesModal = Loadable({
  loader: () => import('containers/main/Main/components/AccessPreferencesModal'),
  loading: () => null,
});
const LazyNotRegisteredWalletsModal = Loadable({
  loader: () => import('containers/main/Main/components/NotRegisteredWalletsModal'),
  loading: () => null,
});

const LazyMoveModal = Loadable({
  loader: () => import('containers/main/Main/components/MoveModal'),
  loading: () => null,
});

const LazyGeoModal = Loadable({
  loader: () => import('components/GeoSecurityModal'),
  loading: () => null,
});

const LazyConvertModal = Loadable({
  loader: () => import('components/ConvertModal'),
  loading: () => null,
});

const LazySafetynetModal = Loadable({
  loader: () => import('components/SafetynetModal'),
  loading: () => null,
});

const LazyIpWhiteListModal = Loadable({
  loader: () => import('components/IPWhiteListModal'),
  loading: () => null,
});

const NotEnoughTokenModal = Loadable({
  loader: () => import('components/NotEnoughTokenModal'),
  loading: () => null,
});

const CreateWorkspaceModal = Loadable({
  loader: () => import('components/CreateWorkspacePopup'),
  loading: () => null,
});

const SuccessModal = Loadable({
  loader: () => import('components/SuccessModal'),
  loading: () => null,
});

const LazyActionModal = Loadable({
  loader: () =>
    import(
      'containers/main/EntitySettingsContainer/components/InfoTab/EventsTab/actions-modal'
    ),
  loading: () => null,
});

const LazyWSBuilderModal = Loadable({
  loader: () => import('containers/workspace/Builder/modal'),
  loading: () => null,
});

const LazyDeleteModal = Loadable({
  loader: () => import('components/DeleteModal'),
  loading: () => null,
});

const LazyDeleteSaftyNetAletModal = Loadable({
  loader: () => import('components/SaftyAlertModal'),
  loading: () => null,
});

const LazyMultisigStatusModal = Loadable({
  loader: () => import('components/MultisigStatusModal'),
  loading: () => null,
});

const LazyMultisigShareModal = Loadable({
  loader: () => import('components/MultisigShareModal'),
  loading: () => null,
});

const Content = ({
  aiSidebarMenu,
  securitiesList,
  workspace_securities,
  modalState,
  entityView,
  fileViewType,
  entityViewPrev,
  entityViewNext,
  entityShare,
  entitiesAccess,
  entitiesMove,
  entitiesDownload,
  entitiesGenerator,
  entitiesUpscaller,
  selectedEntity,
  selectedEntityShareClearEffect,
  selectedEntityAccessPreferencesClear,
  selectedEntityMoveClearEffect,
  selectedEntityClearEffect,
  fileUpdateDataEffect,
  fileFolderActionHandlerEffect,
  errors,
  setErrors,
}) => {
  const {
    state,
    state: { isDeletedPage, isAiGeneratorPage },
  } = useContext(MainContext);
  const [showView, setShowView] = useState(false);
  const { user } = useContext(UserContext);
  const { props: rightMenuProps, show: showRightMenu } =
    useContext(RightMenuContext);
  const location = useLocation();
  const showLayout = !matchPath(location.pathname, { path: '/editor/:fileId' });
  const windowSize = useWindowSize();
  const isMobile = windowSize[0] <= 480;
  const isAiSidebarGenerator =
    isAiGeneratorPage &&
    aiSidebarMenu &&
    fileViewType === 'small-row' &&
    !isMobile;
  const dispatch = useDispatch();
  const showEncryptionModal = useSelector(
    (state) => state.home.encrypt.showEncryptionKeyModal
  );

  useLoadWorkspace();

  useEffect(() => {
    return () => {
      selectedEntityClearEffect();
    };
  }, [location.pathname, selectedEntityClearEffect]);

  useEffect(() => {
    if (entityView) {
      setShowView(true);
    }
  }, [entityView]);

  const onCloseErrorModal = () => {
    setErrors(null);
  };

  const hideHeader =
    location.pathname.includes('/account') ||
    location.pathname.includes('/workspace') ||
    matchPath(location.pathname, { path: '/workspace/members' });

  const isSmallHeader =
    location.pathname.includes('/account') ||
    location.pathname.includes('/workspace') ||
    matchPath(location.pathname, { path: '/notes' }) ||
    matchPath(location.pathname, { path: '/passwords' });

  const isWorkspace = location.pathname.includes('/workspace');

  const middlewareFileFolderActionHandlerEffect = fileFolderActionMiddleware(
    fileFolderActionHandlerEffect,
    setShowView
  );

  const modalView =
    showView && entityView ? (
      <LazyFileView
        isOpen={showView}
        entity={entityView}
        entityPrev={entityViewPrev}
        entityNext={entityViewNext}
        fileFolderActionHandlerEffect={fileFolderActionHandlerEffect}
        onRequestClose={() => setShowView(false)}
      />
    ) : null;

  const sharingModal = entityShare ? (
    <LazySharingModal
      entity={entityShare}
      clear={selectedEntityShareClearEffect}
      fileUpdateDataEffect={fileUpdateDataEffect}
    />
  ) : null;

  const accessModal =
    entitiesAccess && !modalState.notRegisteredWalletsModal ? (
      <LazyAccessPreferencesModal
        selectedEntity={selectedEntity}
        entity={entitiesAccess}
        clear={selectedEntityAccessPreferencesClear}
        modalIsOpen={modalState.accessPreferencesOpen}
      />
    ) : null;

  const notRegisteredWalletsModal = modalState.notRegisteredWalletsModal ? (
    <LazyNotRegisteredWalletsModal
      isOpen={modalState.notRegisteredWalletsModal}
      entity={entitiesAccess}
      additionalData={modalState.additionalData}
      clear={selectedEntityAccessPreferencesClear}
    />
  ) : null;

  const moveModal = entitiesMove ? (
    <LazyMoveModal
      entities={entitiesMove}
      clear={selectedEntityMoveClearEffect}
      fileFolderActionHandlerEffect={fileFolderActionHandlerEffect}
      modalIsOpen={modalState.showMoveOpen}
    />
  ) : null;

  const notEnoughTokenModal = modalState.notEnoughToken ? (
    <NotEnoughTokenModal />
  ) : null;

  const safetynetModal = modalState.safetyNetModal ? (
    <LazySafetynetModal entity={modalState.additionalData} />
  ) : null;

  const ipWhiteListModal = modalState.ipWhiteListModal ? (
    <LazyIpWhiteListModal />
  ) : null;

  const multisigStatusModal = modalState.multisigStatusModal ? (
    <LazyMultisigStatusModal />
  ) : null;

  const multisigShareModal = modalState.multisigShareModal ? (
    <LazyMultisigShareModal />
  ) : null;

  const createWorkspaceModal = modalState.createWorkspaceModal ? (
    <CreateWorkspaceModal data={modalState.additionalData} />
  ) : null;

  const buildWSModal = modalState.wsBuilder ? <LazyWSBuilderModal /> : null;

  const geoSecurityModal = modalState.geoModal ? (
    <LazyGeoModal
      entity={modalState.additionalData}
      modalIsOpen={modalState.geoModal}
      isWorkspace={isWorkspace}
    />
  ) : null;

  const convertModal = modalState.convertModal ? (
    <LazyConvertModal
      entity={modalState.additionalData}
      modalIsOpen={modalState.convertModal}
      isWorkspace={isWorkspace}
    />
  ) : null;

  const actionModal = modalState.actionsOpen ? (
    <LazyActionModal {...modalState.additionalData} />
  ) : null;

  const deleteModal = modalState.deleteModal ? (
    <LazyDeleteModal entity={modalState.additionalData} />
  ) : null;

  const alertModal = modalState.saftyAlertOpen ? (
    <LazyDeleteSaftyNetAletModal
      entities={modalState.additionalData?.entities}
      additionalData={modalState.additionalData?.additionalData}
      doAfterAction={modalState.additionalData?.doAfterAction}
    />
  ) : null;

  const sucessModal = modalState.successModal ? (
    <SuccessModal text={modalState.additionalData} />
  ) : null;

  const connectWalletModal = modalState.connectWalletModal ? (
    <ConnectWalletModal isOpen={modalState.connectWalletModal} />
  ) : null;

  const sitePageClassNames = cn(
    {
      site__pages: showLayout,
      'site__pages-right-menu': showRightMenu,
      'site__pages-entity-settings':
        isAiSidebarGenerator || (selectedEntity && !isDeletedPage),
    },
    style.pageContent
  );
  const isPageEditor = location.pathname.includes('/memo/');

  return (
    <>
      <section className={sitePageClassNames}>
        {!isAiGeneratorPage && showLayout && !hideHeader && !isPageEditor && (
          <HeaderContainer showBurger={false} size={isSmallHeader && 'small'} />
        )}
        <Wrapper
          currentState={state.workspace.current}
          showLayout={showLayout}
          hideHeader={hideHeader}
          middlewareFileFolderActionHandlerEffect={
            middlewareFileFolderActionHandlerEffect
          }
        />
        {(!isEmpty(securitiesList) || !isEmpty(workspace_securities)) && (
          <FileSecurityChecker />
        )}
        {showRightMenu && <RightMenu {...rightMenuProps} />}
      </section>
      {modalView}
      {sharingModal}
      {accessModal}
      {notRegisteredWalletsModal}
      {moveModal}
      {alertModal}
      {sucessModal}
      {actionModal}
      {safetynetModal}
      {ipWhiteListModal}
      {geoSecurityModal}
      {convertModal}
      {deleteModal}
      {notEnoughTokenModal}
      {multisigStatusModal}
      {multisigShareModal}
      {createWorkspaceModal}
      {buildWSModal}
      {connectWalletModal}
      <DownloadLoader
        visible={entitiesDownload.status}
        size={entitiesDownload.size}
        files={entitiesDownload.files}
      />
      <GhostPassModal />
      <ErrorModal onClose={onCloseErrorModal} errors={errors} />
      {entitiesGenerator.status && (
        <ImageToImageModal
          isOpen={entitiesGenerator.status}
          imageAsObj={entitiesGenerator.imageObj}
        />
      )}
      {entitiesUpscaller.status && (
        <AiUpScallerModal
          isOpen={entitiesUpscaller.status}
          imageAsObj={entitiesUpscaller.imageObj}
        />
      )}
      <EncryptionKeyModal
        closeModal={() => {
          dispatch(handleShowEncryptionKeyModal(false));
          dispatch(changeEncryptionStatus(false));
          dispatch(closeEncryptionModal(true));
        }}
        modalIsOpen={showEncryptionModal}
        file={showEncryptionModal}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  securitiesList: state.home?.entitiesSecurityCheck?.securitiesList,
  workspace_securities: state.home?.entitiesSecurityCheck?.workspace_securities,
  modalState: state.modals,
  selectedEntity: state.home?.selectedEntity?.entity,
  entityView: state.home.selectedEntity.entityView,
  entityViewPrev: state.home.selectedEntity.entityViewPrev,
  entityViewNext: state.home.selectedEntity.entityViewNext,
  entityShare: state.home.selectedEntity.entityShare,
  entitiesAccess: state.home.selectedEntity.entitiesAccess,
  entitiesMove: state.home.selectedEntity.entitiesMove,
  entitiesDownload: state.home?.fileDownload,
  entitiesGenerator: state.home.imageToImage,
  entitiesUpscaller: state.home.aiUpscaller,
  fileViewType: state.home?.fileView?.type,
  aiSidebarMenu: state.leftMenu?.aiSidebarMenu,

  errors: state.errors,
});

const mapDispatchToProps = {
  selectedEntityShareClearEffect,
  selectedEntityAccessPreferencesClear,
  fileFolderActionHandlerEffect,
  selectedEntityMoveClearEffect,
  selectedEntityClearEffect,
  fileUpdateDataEffect,
  setErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
