import React from "react";
import CN from 'classnames';

import CloseIcon from 'components/svg/close';
import styles from "./styles.module.scss";

type IProps = {
  title: string,
  children: React.ReactElement,
  onClose: () => void,
  actions?: React.ReactElement,
}

const TemplateModal = ({ title, children, actions, onClose }: IProps) => {

  return (
    <div className={CN(
      styles.templateContainer, actions && styles.noPadding)}>
      <div className={CN(styles.header, actions && styles.actionHeader)}>
        <h1 className={styles.title}>{title}</h1>
        { actions ? (
          <div className={styles.actionContent}>
            { actions }
          </div>
        ) : (
          <button className={styles.close} onClick={onClose}>
            <CloseIcon />
          </button>
        )}
      </div>
      <div className={styles.content}>
        { children }
      </div>
    </div>
  )
}

export default TemplateModal;