/* eslint-disable no-unused-vars */
//Refactoring №3
import React from 'react';

// import CN from 'classnames';
// import moment from 'moment';
import { isEmpty } from 'lodash';

import { trimLongText } from 'utils';
// import { copyToClipboard } from 'utils/string';

import Header from 'components/LinkModal/components/PreviewHeader';
import Link from './components/Link';
// import PasswordText from 'components/VaultModal/components/PasswordText';
// import CopyButton from 'components/Button/copy-button';

import styles from './style.module.scss';

const MAX_TEXT_LENGTH = 60;

const Preview = ({ toggleView, vault, ownerId }) => {
  const { title, fields, tags } = vault;

  const validateLink = (link) => {
    let pattern = new RegExp('^https?://');
    if (!pattern.test(link)) {
      return `https://${link}`;
    }

    return link;
  };

  return (
    <div className={styles.previewBody}>
      <Header toggleView={toggleView} name={title} ownerId={ownerId} />
      <div className={styles.fieldList}>
        {fields.map(({ name, value, label }) => (
          <Link key={name} link={value} text={label} />
        ))}
      </div>
      {!isEmpty(tags.length) && (
        <div className={styles.taglist}>
          {tags.map((tag, index) => (
            <p
              key={`${tag}-${index}`}
              className={styles.tagItem}
            >{`#${trimLongText(tag, 20)}`}</p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Preview;
