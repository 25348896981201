export const START_USER_RECOVERY = 'SET_RECOVER_USER'
export const SUCCESS_USER_RECOVERY = 'SUCCESS_USER_RECOVERY'
export const FAIL_USER_RECOVERY = 'FAIL_USER_RECOVERY'

export function startUserRecovery(user) {
  return {
    type: START_USER_RECOVERY,
    payload: user,
  }
}

export function successUserRecovery() {
  return {
    type: SUCCESS_USER_RECOVERY,
  }
}

export function failUserRecovery() {
  return {
    type: FAIL_USER_RECOVERY,
  }
}
