import web3 from 'web3';
import { PublicKey } from '@solana/web3.js';

const isMetamaskAddress = (address) => {
  return web3.utils.isAddress(address);
};

const isPhantomAddress = (address = '') => {
  let publicKey;
  try {
    publicKey = new PublicKey(address);
    return PublicKey.isOnCurve(publicKey.toBytes());
  } catch (err) {
    return false;
  }
};

export { isMetamaskAddress, isPhantomAddress };
