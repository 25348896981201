/* eslint-disable no-debugger */
// Refactoring №3
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';
import {
  selectSorterState,
  updateSorter,
  SORT_DIR,
  updateFilter,
  updateFilterColor,
  selectFilterState,
  resetFileFilter,
} from 'features/app';
import { updateCurrentPage } from 'store/home/actions/files/get-files.actions';
import ColorContext from 'store/colors/contexts/colors';
import { updateEntrySorting } from 'store/general/effects/update-entry-sorting';
import setFileViewTypeEffect from 'store/home/effects/file-view-type/set-file-view-type-effect';
import useClickOutside from 'utils/hooks/use-click-outside';
import Tooltip from 'components/Tooltip';
import Popover from 'components/Popover';

import { ReactComponent as Video } from '../Sorter/assets/Video.svg';
import { ReactComponent as Audio } from '../Sorter/assets/Audio.svg';
import { ReactComponent as Pictures } from '../Sorter/assets/Pictures.svg';
import { ReactComponent as Documents } from '../Sorter/assets/Documents.svg';
import { ReactComponent as Tokenized } from '../Sorter/assets/Tokenized.svg';
import { ReactComponent as Links } from '../Sorter/assets/Links.svg';

import Icon from './Icons';

import styles from './styles.module.scss';
import { isContainInArray } from 'utils/array/is-contain';

type SorterPropTypes = {
  dispatch: (arg: any) => void;
  filterState: {
    filterName: null | string;
    color: null | string;
    fileType: null | string;
    isTokenized: null | boolean;
  };
  sorterState: {
    option: string;
    dir: string;
    page: string;
    initialCommit: boolean;
  };
};

const settingUrl = ['/workspace/', '/account/'];

const Sorter = ({
  dispatch,
  sorterState,
  filterState,
  history,
  actionBtnRef,
  searchOn,
}: SorterPropTypes) => {
  const sorterRef = useRef(null);
  const [smallView, setSmallView] = useState(false);
  const viewType = useSelector((state) => state.home?.fileView?.type);
  const { option, dir, page } = sorterState;
  const { filterName, color } = filterState;
  const { t } = useTranslation('owner');
  const { t: sidebarT } = useTranslation('sidebar');
  const { t: hint } = useTranslation('hint');
  const sortRef = useRef(null);
  const typeRef = useRef(null);
  const colorRef = useRef(null);
  const [isOpenSort, setIsOpenSort] = useState(false);
  const [isOpenTypes, setIsOpenTypes] = useState(false);
  const [isOpenColors, setIsOpenColors] = useState(false);
  const { colors: allColors } = useContext(ColorContext);
  const isSettingsPage = settingUrl.some((url) =>
    isContainInArray(history.location.pathname, url)
  );

  const calculateWidth = () => {
    const right =
      actionBtnRef?.current?.lastChild?.getBoundingClientRect()?.right;
    const left = sorterRef?.current?.getBoundingClientRect().left;

    if (right && left) {
      if (left - right < 200) {
        setSmallView(true);
      } else {
        setSmallView(false);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', calculateWidth);
    return () => window.removeEventListener('resize', calculateWidth);
  }, []);

  useEffect(() => {
    calculateWidth();
  }, [actionBtnRef, sorterRef, searchOn]);

  useEffect(() => {
    dispatch(resetFileFilter());
    dispatch(updateFilterColor(null));
  }, [location.pathname]);

  const options = {
    name: {
      label: t('rightFileMenu.events.name'),
      value: 'name',
    },
    createdAt: {
      label: t('common.created'),
      value: 'createdAt',
    },
    size: {
      label: t('common.size'),
      value: 'size',
    },
  };

  const viewOptios = [
    {
      name: 'list',
      icon: <Icon type="listSmall" />,
      title: 'List',
      value: 'small-row',
    },
    {
      name: 'list',
      icon: <Icon type="listTable" />,
      title: 'Table',
      value: 'square',
    },
  ];

  const fileTypes = {
    video: {
      label: sidebarT('mainSidebar.video'),
      value: 'video',
      icon: <Video />,
    },
    audio: {
      label: sidebarT('mainSidebar.audio'),
      value: 'audio',
      icon: <Audio />,
    },
    pictures: {
      label: sidebarT('mainSidebar.pictures'),
      value: 'image',
      icon: <Pictures />,
    },
    documents: {
      label: sidebarT('mainSidebar.doc'),
      value: 'docs',
      icon: <Documents />,
    },

    tokenized: {
      label: sidebarT('mainSidebar.tokenized'),
      value: 'tokenized',
      icon: <Tokenized />,
    },
    links: {
      label: sidebarT('mainSidebar.links'),
      value: 'link',
      icon: <Links />,
    },
  };

  // const goToSettings = () => {
  //   history.push('/account/profile');
  // }

  const changeSortName = (value: string) => {
    if (value !== option) {
      updateEntrySorting({
        orderBy: value,
        orderDirection: dir,
        page: page,
      }).then(() => {
        dispatch(updateCurrentPage(1));
        dispatch(
          updateSorter({
            initialCommit: false,
            option: value,
          })
        );
      });
    }
    setIsOpenSort(false);
  };

  const changeDirection = () => {
    const newDirection = dir === SORT_DIR.asc ? SORT_DIR.desc : SORT_DIR.asc;
    updateEntrySorting({
      orderBy: option,
      orderDirection: newDirection,
      page: page,
    }).then(() => {
      dispatch(updateCurrentPage(1));
      dispatch(
        updateSorter({
          initialCommit: false,
          dir: newDirection,
        })
      );
    });
  };

  const changeFileTypeFilter = (option: {
    label: string;
    value: string;
    icon: JSX.Element;
  }) => {
    if (option.value === 'tokenized') {
      dispatch(updateFilter({ isTokenized: true, filterName: option.label }));
    } else {
      dispatch(
        updateFilter({ fileType: option.value, filterName: option.label })
      );
    }
    setIsOpenTypes(false);
  };

  const changeColorFilter = (color: string) => {
    dispatch(updateFilterColor(color));
    setIsOpenColors(false);
  };

  const resetColorFilter = () => dispatch(updateFilterColor(null));
  const resetFileTypeFilter = () => dispatch(resetFileFilter());

  const reloadPage = () => {
    window.location.reload();
  };

  const changeView = () => {
    dispatch(
      setFileViewTypeEffect(viewType === 'small-row' ? 'square' : 'small-row')
    );
  };

  const changeViewBy = (nextView) => {
    dispatch(setFileViewTypeEffect(nextView));
  };

  useClickOutside(sortRef, () => {
    setIsOpenSort(false);
  });
  useClickOutside(typeRef, () => {
    setIsOpenTypes(false);
  });
  useClickOutside(colorRef, () => {
    setIsOpenColors(false);
  });

  const renderOption = (
    <div ref={sortRef} className={styles.options}>
      {Object.values(options).map((item) => (
        <div
          className={CN(
            styles.optionSort,
            item.value === option && styles.activeOption
          )}
          onClick={() => {
            item.value !== option && changeSortName(item.value);
          }}
          key={item.value}
        >
          <div className={styles.optionSortData}>
            <button
              className={CN(item.value === option && styles.optionSortBtn)}
              onClick={changeDirection}
            >
              {item.value === option && (
                <Icon type="direction" rotate={dir === 'asc' ? 180 : 0} />
              )}
            </button>
            {item.label}
          </div>
          {item.value === option && <Icon type="check" />}
        </div>
      ))}
    </div>
  );

  const rendertypes = (
    <div ref={typeRef} className={styles.options}>
      {Object.values(fileTypes).map((option) => (
        <div
          className={`${styles.option} ${styles.typeOption}`}
          onClick={() => {
            if (option.label === filterName) {
              resetFileTypeFilter();
            } else {
              changeFileTypeFilter(option);
            }
          }}
          key={option.value}
        >
          <div className={styles.optionInfo}>
            <div>{option.icon}</div>
            <div className={styles.optionText}>{option.label}</div>
          </div>
          {option.label === filterName && <Icon type="check" />}
        </div>
      ))}
    </div>
  );

  const renderColors = (
    <div ref={colorRef} className={styles.colors}>
      {allColors.map((colorItem: { name: string; id: number; hex: string }) => (
        <button
          type="button"
          className={`${styles.colorItem__box} ${
            colorItem.name === 'empty' ? styles.emptyColor : ''
          }`}
          key={`color-${colorItem.id}`}
          onClick={() => {
            if (color === colorItem.hex) {
              resetColorFilter();
            } else {
              changeColorFilter(
                colorItem.name === 'empty' ? 'empty' : colorItem.hex
              );
            }
          }}
          style={
            colorItem.name === 'empty' ? {} : { backgroundColor: colorItem.hex }
          }
        >
          <div className={styles.colorItem}>
            {color === colorItem.hex && <Icon type="checkSmall" />}
          </div>
        </button>
      ))}
    </div>
  );

  const renderViewOptions = (
    <div className={styles.moreTooltip}>
      {viewOptios.map(({ name, title, icon, value }) => (
        <div
          className={CN(
            styles.viewTooltipItem,
            value === viewType && styles.viewTooltipItemActive
          )}
          key={name}
          onClick={() => {
            changeViewBy(value);
          }}
        >
          <div className={styles.optionInfo}>
            {icon}
            <span className={styles.optionText}>{title}</span>
          </div>
          {value === viewType && <Icon type="check" />}
        </div>
      ))}
    </div>
  );

  const moreOptions = [
    {
      name: 'view',
      title: 'View',
      onClick: () => ({}),
      arrow: true,
      render: renderViewOptions,
    },
    // {
    //   name: 'group',
    //   title: 'Use Groups',
    //   onClick: () => ({}),
    //   arrow: true,
    //   render: rendertypes
    // },
    {
      name: 'color',
      title: 'Color',
      onClick: () => ({}),
      arrow: true,
      render: renderColors,
    },
    {
      name: 'refresh',
      title: 'Refresh',
      onClick: reloadPage,
      arrow: false,
      render: renderColors,
    },
  ];

  const renderMore = (
    <div className={styles.moreTooltip}>
      {moreOptions.map(({ name, title, arrow, onClick, render }) => (
        <Tooltip
          key={name}
          showArrow={false}
          overlay={render}
          trigger="click"
          visible={arrow ? undefined : false}
          placement="leftTop"
          align={{ offset: [-1] }}
        >
          <div
            className={styles.moreTooltipItem}
            key={name}
            onClick={() => {
              onClick(name);
            }}
          >
            {title}
            {arrow && <Icon type="right" />}
          </div>
        </Tooltip>
      ))}
    </div>
  );

  return (
    <div
      ref={sorterRef}
      className={CN(styles.sorter, isSettingsPage && styles.sorterSettings)}
    >
      <>
        {!isSettingsPage && !smallView && (
          <Popover
            className={styles.popover}
            trigger="click"
            text={renderColors}
            displayArrow={false}
            open={isOpenColors}
          >
            <button
              type="button"
              className={styles.choosenColor}
              title="Color"
              onClick={() => {
                setIsOpenColors(true);
              }}
            >
              {color && color !== 'empty' ? (
                <Icon type="colorSelected" color={color} />
              ) : (
                <Icon type="color" />
              )}
            </button>
          </Popover>
        )}
        {!isSettingsPage && (
          <Popover
            className={styles.popover}
            trigger="click"
            text={renderOption}
            displayArrow={false}
            open={isOpenSort}
          >
            <button
              className={styles.sortWrapper}
              title="Sort"
              onClick={() => {
                setIsOpenSort(true);
              }}
            >
              <Icon type="sort" />
            </button>
          </Popover>
        )}
        {/* {!isSettingsPage && !smallView && (
        <Popover
          trigger="click"
          text={rendertypes}
          displayArrow={false}
          open={isOpenTypes}
        >
          <button
            className={CN(styles.choosenFileType, filterName && styles.choosenFileTypeSelected )}
            title="Filter"
            onClick={() => {
              setIsOpenTypes(true);
            }}
          >
            { filterName ?
              fileTypes[filterName.toLocaleLowerCase()].icon :
              <Icon type="filter" /> }
          </button>
        </Popover>)} */}
      </>
      {!isSettingsPage && !smallView && (
        <button className={styles.viewOption} onClick={changeView} title="View">
          <Icon
            type={viewType === 'square' ? 'view' : 'viewList'}
            width="24px"
            heigth="24px"
          />
        </button>
      )}
      {!isSettingsPage && !smallView && (
        <button
          className={styles.optionItem}
          title={hint('refresh')}
          onClick={reloadPage}
        >
          <Icon type="reload" />
        </button>
      )}
      {!isSettingsPage && smallView && (
        <Tooltip
          placement="bottom"
          showArrow={false}
          overlay={renderMore}
          trigger="click"
        >
          <button className={styles.moreOptions} title={hint('more')}>
            <Icon type="settings" />
          </button>
        </Tooltip>
      )}
      {/* { !isSettingsPage && (
        <button
          className={styles.accountButtonWrapper}
          onClick={goToSettings}
          title='Settings'
        >
          <SettingsIcon />
        </button>
      )} */}
    </div>
  );
};

const mapStateToProps = (store: any) => ({
  sorterState: selectSorterState(store),
  filterState: selectFilterState(store),
});

export default connect(mapStateToProps)(Sorter);
