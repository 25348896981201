import getToken from 'utils/auth/get-token';
import authRequest from 'utils/request/auth-request';

export const sendFileViewStatistic = async (slug) => {
  const token = getToken();
  if (!token) {
    return;
  }

  try {
    const response = await authRequest.post(
      `${process.env.REACT_APP_API_PATH}/statistic/${slug}/1`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const sendFileDownloadStatistic = async (slug) => {
  const token = getToken();
  if (!token) {
    return;
  }

  try {
    const response = await authRequest.post(
      `${process.env.REACT_APP_API_PATH}/statistic/${slug}/2`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
