import USER_ROLE_TYPES from 'config/user-role-types';

export const getRole = (role, t) => {
  switch (role) {
    case USER_ROLE_TYPES.OWNER:
      return t('accountType.owner');
    case USER_ROLE_TYPES.GUEST:
      return t('accountType.guest');
    case USER_ROLE_TYPES.MEMBER:
      return t('accountType.member');
    default:
      return '';
  }
};
