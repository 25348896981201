//Refactoring №5
import React, { useCallback } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import Button, { ButtonTheme } from 'components/Button';
import CloseIcon from 'components/svg/close';

import styles from './style.module.scss';

type IProps = {
  isOpen: string;
  onClose: () => void;
  deleteField: (slug: string) => void;
};

const DeleteModal = ({ isOpen, onClose, deleteField }: IProps) => {
  const { t } = useTranslation('account');

  const handleOnClose = useCallback(() => {
    onClose();
  }, []);

  const handleDelete = useCallback(() => {
    deleteField(isOpen);
    onClose();
  }, [isOpen]);

  return (
    <Modal
      isOpen={!!isOpen}
      onRequestClose={onClose}
      overlayClassName={styles.overlay}
      className={styles.wrapper}
      shouldCloseOnOverlayClick
    >
      <section>
        <header className={styles.header}>
          <button onClick={onClose} className={styles.closeBtn}>
            <CloseIcon />
          </button>
        </header>
        <div className={styles.content}>
          <h2 className={styles.title}>{t('Profile.confirmDeletion')}</h2>
          <div className={styles.actions}>
            <Button
              className={styles.cancelBtn}
              onClick={handleOnClose}
              theme={ButtonTheme.TRANSPARENT}
            >
              {t('Profile.cancel')}
            </Button>
            <Button
              className={styles.submitBtn}
              onClick={handleDelete}
              theme={ButtonTheme.DARK}
            >
              {t('deleteAccount.delete')}
            </Button>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default DeleteModal;
