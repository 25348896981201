import * as actions from '../actions/trust-members.action'
import { merge } from 'ramda'
import * as _ from 'lodash'

import getData from 'utils/request/get-data'
import getArrayFromData from 'utils/array/get-array-from-data'

const initialState = {
  trust_members: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.GET_TRUST_MEMBERS_SUCCESS:
      return merge(state, {
        trust_members: action.payload,
      })
    case actions.ADD_TRUST_MEMBER_SUCCESS:
      return {
        ...state,
        trust_members: [
          ...getArrayFromData(getData(action)),
          ...state.trust_members,
        ],
      }
    case actions.UPDATE_TRUST_MEMBER_SUCCESS:
      return {
        ...state,
        trust_members: _.map(state.trust_members, (member) => {
          return member.id === action.payload.id ? action.payload : member
        }),
      }
    case actions.DELETE_TRUST_MEMBER_SUCCESS:
      return {
        ...state,
        trust_members: _.reject(state.trust_members, (member) => {
          return member.id === action.payload
        }),
      }
    default:
      return state
  }
}

//Selectors
export const getTrustMembers = (state) =>
  state.account.trustMembers.trust_members
