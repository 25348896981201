/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
//Refactoring №3
import React, { useContext, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { workspaceSecurityCheck } from 'store/home/actions/file/entities-security-check.actions';
import UserContext from 'store/home/contexts/user-context';
import { switchWorkspace } from 'store/workspace/effects';
import { resetUploadingFiles } from 'store/home/actions';

import USER_ROLE_TYPES from 'config/user-role-types';
import {
  handleCancelUploadModal,
  selectCancelUploadModal,
} from 'features/modals/modal-slice';
import {
  cancelAllOngoingUploading,
  isUploadingInProgress,
} from 'utils/files-folders/file-upload';

// import { ReactComponent as AddStorageIcon } from '../Title/assets/addStorage.svg'
import { WorkspaceMenuItem } from './WorkspaceMenuItem';
import CancelUploadModal from 'components/home/multy-upload-details/CancelUploadModal';

import cn from 'classnames';
import s from './style.module.scss';

const WorkspaceMenu = ({
  workspaces = [],
  onAddNewWorkspaceClick,
  className,
  onClick,
  isOpenCancelUploadModal,
  handleCancelUploadModal,
  resetUploadingFiles,
}) => {
  const { t } = useTranslation('workspace');
  const dispatch = useDispatch();
  const { user: me } = useContext(UserContext);
  const history = useHistory();
  const [currentWs, setCurrentWs] = useState('');

  const handleWorkspaceSwitch = (nextWorkspace) => {
    if (
      nextWorkspace.workspace_securities[0] &&
      nextWorkspace.is_workspace_owner
    )
      dispatch(
        workspaceSecurityCheck({
          data: [...nextWorkspace.workspace_securities],
          nextWorkspace,
          onClick,
        })
      );
    else {
      onClick && onClick();
      switchWorkspace(nextWorkspace.id).then((data) => {
        if (data.token) {
          localStorage.setItem('workspace', JSON.stringify(nextWorkspace));
          window.location = '/main';
        }
      });
    }
  };

  const handleCancelUpload = () => {
    handleCancelUploadModal(false);
    cancelAllOngoingUploading(dispatch);
    resetUploadingFiles();
    handleWorkspaceSwitch(currentWs);
  };

  const handleContinueUpload = () => {
    handleCancelUploadModal(false);
  };

  const handleItemClick = (ws) => () => {
    setCurrentWs(ws);
    if (isUploadingInProgress()) {
      handleCancelUploadModal(true);
    } else {
      resetUploadingFiles();
      handleWorkspaceSwitch(ws);
    }
  };

  const sortedWorkspaces = [...workspaces.filter((w) => !w.current)];

  const handleAddWorkSpace = () => {
    const { role, funds } = me;
    if (
      (role === USER_ROLE_TYPES.GUEST || role === USER_ROLE_TYPES.MEMBER) &&
      funds === 0
    ) {
      history.push('/');
      return;
    }

    onAddNewWorkspaceClick();
  };

  return (
    <>
      <ul className={cn(s.container, className)}>
        {sortedWorkspaces.map((workspace, index) => {
          return (
            <WorkspaceMenuItem
              key={workspace.id}
              workspace={workspace}
              handleItemClick={handleItemClick(workspace)}
              index={index}
            />
          );
        })}
        {/* { sortedWorkspaces.length ? (<hr className={s.hrSecont} />) : ''}
        <li
          onClick={handleAddWorkSpace}
          role="button"
          tabIndex={0}
          data-test={`main-sidebar_workspace_menu_add-workspace-button`}
        >
          <div className={s.menuItem}>
            <AddStorageIcon className={s.avatar} />
            <span className={s.newWorkspaceLabel}>
              {t('common.newWorkspace')}
            </span>
          </div>
        </li> */}
      </ul>
      {isOpenCancelUploadModal && (
        <CancelUploadModal
          isOpen={isOpenCancelUploadModal}
          onCancel={handleCancelUpload}
          onContinue={handleContinueUpload}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  isOpenCancelUploadModal: selectCancelUploadModal(state),
});

const mapDispatchToProps = {
  handleCancelUploadModal: handleCancelUploadModal,
  resetUploadingFiles: resetUploadingFiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceMenu);
