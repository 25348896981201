//Refactoring №3
import React, { useRef, useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import printJS from 'print-js';
import axios from 'axios';
import cn from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CarReader } from '@ipld/car';

import * as actions from 'store/home/actions/file/download-file.action';
import UserContext from 'store/home/contexts/user-context';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import { selectedEntityViewClear } from 'store/home/actions/selected-entity.actions';
import { handleGeoModal } from 'features/modals/modal-slice';
import { getTotalDownloadFileSize } from 'store/home/effects/entity-actions/entity-make-action.effect';
import {
  encryptionKeyError,
  closeEncryptionModal,
  changeEncryptionStatus,
} from 'store/home/actions/file/encryption-key.action';
import { addTokenDownload } from 'store/home/actions/file/download-file.action';
import { getDownloadOTT } from 'store/home/effects/files-upload/upload-file.effect';
import { getFileCids } from 'store/home/effects/file/get-file-cid';

import {
  selectTokenCountState,
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
} from 'features/app';

import FileContent from '../../file/file-content';
import ArrowPrev from 'components/svg/arrow-prev';
import ArrowNext from 'components/svg/arrow-next';

import { ReactComponent as DownloadIcon } from './assets/download.svg';
import { ReactComponent as PrintIcon } from './assets/print.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { ReactComponent as ShareIcon } from './assets/share.svg';
import { ReactComponent as MoveIcon } from './assets/move.svg';
import { ReactComponent as RenameIcon } from './assets/rename.svg';
import { ReactComponent as GeoIcon } from './assets/geo.svg';
import { ReactComponent as EncryptionIcon } from './assets/encryption.svg';

import ActionsIconVertical from 'components/svg/actions-icon-vertical';
import GhostLoader from 'components/GhostLoader';
import ContextMenu from 'components/context-menu';
import { getEncryptedFileKey } from 'containers/main/Main/components/Sharing/utils/get-key';

import downloadFromBlob from 'utils/download-from-blob';
import { ipfsLink, isIpfsService } from 'utils/ipfs/link';
import canBePrint from 'utils/files-folders/can-be-print';
import { downloadFile, getCidLevelByFileSize } from 'gdgateway-client/lib/es5';
import useNotification from 'utils/hooks/use-notification';
import { transformSize } from 'utils/storage';
import { isMobile } from 'utils/mobile';
import { downloadFileData } from 'utils/file/library-callbacks';
import { getDecryptedKey } from 'utils/file/getDecryptedKey';
import { encryptAction } from 'utils/file/encryptAction';
import { getKeyFromLS } from 'utils/file/getKeyFromLS';

import imageFileExtensions, {
  imageMediaTypes,
  imageMediaTypesPreview,
  imagesWithoutPreview,
} from 'config/image-file-extensions';
import {
  docMediaTypes,
  docMediaTypesPreview,
  docMediaTypesContent,
  pdfMediaTypes,
} from 'config/docs-file-extensions';
import { audioMediaTypesContent } from 'config/audio-file-extensions';
import { videoMediaExtentionPreview } from 'config/video-file-extensions';
import actionsOptions, { actionsType } from 'config/actions-options';
import { sendFileViewStatistic } from 'store/home/effects/statistic/file-statistic-effect';

import s from './style.module.scss';

const FileView = ({
  entity,
  entityPrev,
  entityNext,
  isOpen,
  onRequestClose,
  isPublic,
  fileContentExternal,
  shouldCloseOnOverlayClick,
}) => {
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);
  const userTokens = useSelector(selectTokenCountState);
  const [entityContent, setEntityContent] = useState(fileContentExternal);
  const [showContext, setShowContext] = useState(false);
  const [isLoader, setLoader] = useState(true);
  const [isSvg, setIsSvg] = useState(false);
  const [encryptionStatus, setEncryptionStatus] = useState('');
  const [encryptingFiles, setEncryptingFiles] = useState([]);
  const headerRef = useRef(null);
  const modalRef = useRef(null);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const dispatch = useDispatch();
  const { t: accountT } = useTranslation('account');
  const { t: popupT } = useTranslation('popup');
  const { user } = useContext(UserContext);
  const { addNotification } = useNotification();
  const isShowContextButton = !isPublic && !user.isGuest;
  const canDownload =
    isMultisigActivated && !isMultisigPartisipant
      ? false
      : entity?.is_downloaded;
  const isFileEncrypted = entity?.is_clientside_encrypted;
  const isMobileDevice = isMobile && window.innerWidth <= 480;
  const multisigCanPreviewe =
    !isMultisigActivated || (isMultisigActivated && isMultisigPartisipant);
  const isMinted = !!entity?.entry_groups?.some((item) => item.is_tokenized);

  useEffect(() => {
    const fileSignal = axios.CancelToken.source();
    if (entity?.slug) {
      if (
        !videoMediaExtentionPreview.includes(entity.extension) &&
        !audioMediaTypesContent.includes(entity.mime) &&
        !docMediaTypesContent.includes(entity.mime) &&
        !pdfMediaTypes.includes(entity.mime)
      ) {
        if (imageMediaTypesPreview.includes(entity.mime)) {
          viewImageFile(entity?.is_clientside_encrypted);
          return;
        }
        setEntityContent(entity);
        setLoader(false);
      } else {
        setEntityContent(entity);
        setLoader(false);
      }
    }
    return () => {
      fileSignal.cancel('getFileContent is being canceled');
    };
  }, [entity]);

  if (!entity?.mime) return null;

  const viewImageFile = async (isClientsideEncrypted) => {
    const { CancelToken } = axios;
    const signal = CancelToken.source();
    let cidData;
    const {
      data: {
        jwt_ott,
        user_tokens: { token: oneTimeToken },
        gateway,
        upload_chunk_size,
      },
    } = await getDownloadOTT([{ slug: entity.slug }], actionsType.VIEWED);

    if (entity.is_on_storage_provider) {
      const level = getCidLevelByFileSize(entity);
      cidData = await getFileCids({ slug: entity.slug, level });
    }

    dispatch(addTokenDownload(signal));
    try {
      if (isClientsideEncrypted) {
        if (!isPublic) {
          let key;
          const decryptedKeyFromLS = getKeyFromLS({ slug: entity.slug });
          key = decryptedKeyFromLS
            ? decryptedKeyFromLS
            : await getEncryptedFileKey(entity, dispatch);
          if (key) {
            const { handlers, callbacks } = downloadFileData;

            const callback = ({ type, params }) => {
              if (handlers.includes(type)) {
                callbacks[type]({ ...params, dispatch });
              } else {
                console.error(`Handler "${type}" isn't provided`);
              }
            };
            const decryptedKey = decryptedKeyFromLS
              ? key
              : await getDecryptedKey({ key });

            await sendFileViewStatistic(entity.slug);
            const blob = await downloadFile({
              file: entity,
              oneTimeToken,
              jwtOneTimeToken: jwt_ott,
              endpoint: gateway.url,
              isEncrypted: true,
              key: decryptedKey,
              callback,
              handlers,
              carReader: CarReader,
              uploadChunkSize:
                upload_chunk_size[entity.slug] || gateway.upload_chunk_size,
              cidData,
            }).catch((error) => {
              let message = '';
              if (
                error?.message?.includes('HTTP') ||
                (error instanceof TypeError &&
                  error.message.includes('Failed to fetch'))
              ) {
                message = popupT('notification.failedToFetch');
              } else if (
                error instanceof DOMException ||
                error?.message === 'AES key data must be 128 or 256 bits' ||
                error?.message ===
                  "Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded."
              ) {
                message = popupT('notification.invalidKey');
              } else {
                message = popupT('notification.somethingWrong');
              }
              dispatch(changeEncryptionStatus(false));
              dispatch(
                encryptionKeyError({
                  error: true,
                  message,
                })
              );
            });
            if (blob) {
              dispatch(changeEncryptionStatus(false));
              dispatch(closeEncryptionModal(true));
              const realBlob = new Blob([blob]);
              const url = URL.createObjectURL(realBlob);
              if (entity.extension === 'svg') {
                const text = await realBlob.text();
                setIsSvg(true);
                setEntityContent(text);
                setLoader(false);
                return;
              }
              setEntityContent(url);
              setLoader(false);
            }
          } else {
            setLoader(false);
            addNotification(popupT('notification.switchAddress'), 'alert');
          }
        } else {
          setLoader(false);
          setEntityContent(null);
        }
      } else {
        await sendFileViewStatistic(entity.slug);
        const blob = await downloadFile({
          file: entity,
          oneTimeToken,
          jwtOneTimeToken: jwt_ott,
          endpoint: gateway.url,
          isEncrypted: false,
          carReader: CarReader,
          uploadChunkSize:
            upload_chunk_size[entity.slug] || gateway.upload_chunk_size,
          cidData,
        }).catch((error) => {
          let message = '';
          if (
            error?.message?.includes('HTTP') ||
            (error instanceof TypeError &&
              error.message.includes('Failed to fetch'))
          ) {
            message = popupT('notification.failedToFetch');
          } else if (
            error instanceof DOMException ||
            error?.message === 'AES key data must be 128 or 256 bits' ||
            error?.message ===
              "Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded."
          ) {
            message = popupT('notification.invalidKey');
          } else {
            message = popupT('notification.somethingWrong');
          }
          dispatch(changeEncryptionStatus(false));
          dispatch(
            encryptionKeyError({
              error: true,
              message,
            })
          );
        });
        if (blob) {
          const realBlob = new Blob([blob]);

          dispatch(changeEncryptionStatus(false));
          dispatch(closeEncryptionModal(true));
          const url = URL.createObjectURL(realBlob);
          if (entity.extension === 'svg') {
            const text = await realBlob.text();
            setIsSvg(true);
            const imgSrc = `data:image/svg+xml,${encodeURIComponent(text)}`;
            setEntityContent(imgSrc);
            setLoader(false);
            return;
          }
          setEntityContent(url);
          setLoader(false);
        }
      }
    } catch {
      dispatch(changeEncryptionStatus(false));
    }
  };

  const handleActionsButtonClick = (event) => {
    event && event.preventDefault();
    event && event.stopPropagation();
    setShowContext(!showContext);
  };

  const handleClickOutsideContextMenu = (event) => {
    event && event.preventDefault();
    event && event.stopPropagation();
    setShowContext(false);
  };

  const optionChecker = (options) => {
    return options.filter((option) => {
      if (option === actionsOptions.print && !entity?.is_printed) {
        return false;
      }
      return true;
    });
  };

  const canBePrinted = canBePrint(entity) && entity?.is_printed;

  const printFile = async () => {
    let url;
    if (
      typeof entityContent === 'string' &&
      entityContent.startsWith('blob:')
    ) {
      url = entityContent;
    } else {
      const { handlers, callbacks } = downloadFileData;
      let cidData;

      const callback = ({ type, params }) => {
        if (handlers.includes(type)) {
          callbacks[type]({ ...params, dispatch });
        } else {
          console.error(`Handler "${type}" isn't provided`);
        }
      };
      const {
        data: {
          jwt_ott,
          user_tokens: { token: oneTimeToken },
          gateway,
          upload_chunk_size,
        },
      } = await getDownloadOTT([{ slug: entity.slug }]);
      if (entity?.is_on_storage_provider) {
        const level = getCidLevelByFileSize(entity);
        cidData = await getFileCids({ slug: entity.slug, level });
      }
      if (entity.is_clientside_encrypted) {
        if (!isPublic) {
          let key;
          const decryptedKeyFromLS = getKeyFromLS({ slug: entity.slug });
          key = decryptedKeyFromLS
            ? decryptedKeyFromLS
            : await getEncryptedFileKey(entity, dispatch);
          if (key) {
            const decryptedKey = decryptedKeyFromLS
              ? key
              : await getDecryptedKey({ key });

            const blob = await downloadFile({
              file: entity,
              oneTimeToken,
              jwtOneTimeToken: jwt_ott,
              endpoint: gateway.url,
              isEncrypted: true,
              key: decryptedKey,
              callback,
              handlers,
              carReader: CarReader,
              uploadChunkSize:
                upload_chunk_size[entity.slug] || gateway.upload_chunk_size,
              cidData,
            });

            url = URL.createObjectURL(blob);
          }
        }
      } else {
        if (entity?.mime === 'text/plain') {
          printJS({ printable: 'preview-container', type: 'html' });
          return;
        }
        const controller = new AbortController();
        const blob = await downloadFile({
          file: entity,
          oneTimeToken,
          jwtOneTimeToken: jwt_ott,
          endpoint: gateway.url,
          isEncrypted: false,
          callback,
          handlers,
          signal: controller.signal,
          carReader: CarReader,
          uploadChunkSize:
            upload_chunk_size[entity.slug] || gateway.upload_chunk_size,
          cidData,
        });
        url = URL.createObjectURL(blob);
      }
    }
    let fileType = 'pdf';
    if (imageMediaTypes.includes(entity?.mime)) {
      fileType = 'image';
    } else if (entity?.mime === 'application/msword') {
      fileType = 'pdf';
    } else if (docMediaTypes.includes(entity?.mime)) {
      fileType = 'html';
    }
    printJS({ printable: url, type: fileType });
  };

  /* TODO - define optionHandler */
  const optionHandler = () => {};

  /* TODO - define trash */
  let trash = false;

  const contextOptions = optionChecker(
    trash
      ? [actionsOptions.restoreFromTrash, actionsOptions.removeFromTrash]
      : [
          actionsOptions.accessPreferences,
          actionsOptions.showMove,
          actionsOptions.edit,
        ]
  );

  const getEncryptionStatus = (status) => {
    setEncryptionStatus(status);
  };

  const actionHandler = (option) => {
    if (
      [
        actionsOptions.removeFromTrash.type,
        actionsOptions.restoreFromTrash.type,
        actionsOptions.removeFromShared.type,
        actionsOptions.removeShared.type,
        actionsOptions.remove.type,
      ].includes(option.type)
    ) {
      optionHandler(/*file, option, entitiesTypes.file.type*/);
      return;
    }

    if (option.type === actionsOptions.encrypt.type) {
      const isAlreadyEncrypting = encryptingFiles.find(
        (slug) => slug === entity.slug
      );
      if (!isAlreadyEncrypting) {
        setEncryptingFiles((prev) => [...prev, entity.slug]);
        const afterCb = () => {
          const clearedFiles = encryptingFiles.filter(
            (slug) => slug !== entity.slug
          );
          setEncryptingFiles(clearedFiles);
        };
        encryptAction({
          user,
          userTokens,
          file: entity,
          dispatch,
          addNotification,
          getEncryptionStatus,
          afterCb,
        });
      }
    }

    if (option.type === actionsOptions.geoSecurity.type) {
      dispatch(handleGeoModal({ modal: true, entity }));
      return;
    }

    dispatch(fileFolderActionHandlerEffect(entity, option));
  };

  const onClickOutSideFileContentHandler = (e) => {
    if (
      headerRef?.current &&
      !headerRef.current.contains(e.target) &&
      prevButtonRef?.current &&
      !prevButtonRef.current.contains(e.target) &&
      nextButtonRef?.current &&
      !nextButtonRef.current.contains(e.target)
    ) {
      handleOnClose();
    }
  };

  const onPrevNextClick = (entityToShow) => () => {
    setIsSvg(entityToShow.extension === 'svg');
    dispatch(
      fileFolderActionHandlerEffect(entityToShow, actionsOptions.viewFile)
    );
  };

  const downloadFileLocal = () => {
    if (canDownload) {
      dispatch(
        actions.startDownload(getTotalDownloadFileSize([entity]), entity.slug, [
          entity,
        ])
      );
      dispatch(fileFolderActionHandlerEffect(entity, actionsOptions.download));
    }
  };

  const handleOnClose = () => {
    onRequestClose();
    dispatch(selectedEntityViewClear());
  };

  const headerContent = (
    <header className={cn('file-view__header', s.header)} ref={headerRef}>
      <div></div>
      <div className={cn('file-view__menu-buttons', s.header__buttons)}>
        {canDownload && (
          <button
            onClick={downloadFileLocal}
            className="file-view__header-item active circle-hover"
            data-test="file-view_download_link"
            title="Download"
          >
            <DownloadIcon viewBox="0 2 19 19" />
          </button>
        )}
        {!isMobileDevice && canBePrinted && (
          <button
            type="button"
            className="file-view__header-item active circle-hover"
            onClick={printFile}
            data-test="file-view_print_button"
            title="Print"
          >
            <PrintIcon viewBox="0 2 17 17" />
          </button>
        )}
        {!user?.isGuest && (
          <>
            {!isPublic && multisigCanPreviewe && (
              <button
                type="button"
                className="file-view__header-item active circle-hover"
                onClick={() => {
                  actionHandler(actionsOptions.accessPreferences);
                }}
                data-test="file-view_print_button"
                title="Share"
              >
                <ShareIcon />
              </button>
            )}
            {!isPublic && multisigCanPreviewe && (
              <button
                type="button"
                className="file-view__header-item active circle-hover"
                onClick={() => {
                  actionHandler(actionsOptions.showMove);
                }}
                data-test="file-view_print_button"
                title="Move"
              >
                <MoveIcon viewBox="1 2 18 18" />
              </button>
            )}
            {!isPublic && multisigCanPreviewe && (
              <button
                type="button"
                className="file-view__header-item active circle-hover"
                onClick={() => {
                  actionHandler(actionsOptions.edit);
                }}
                data-test="file-view_print_button"
                title="Rename"
              >
                <RenameIcon viewBox="1 2 18 18" />
              </button>
            )}
            {!isMobileDevice && multisigCanPreviewe && !isPublic && (
              <button
                type="button"
                className="file-view__header-item active circle-hover"
                onClick={() => {
                  actionHandler(actionsOptions.geoSecurity);
                }}
                data-test="file-view_print_button"
                title="Geo Security"
              >
                <GeoIcon viewBox="1 2 18 18" />
              </button>
            )}
            {!isMobileDevice &&
              !isFileEncrypted &&
              multisigCanPreviewe &&
              !isPublic &&
              !isMinted &&
              !encryptionStatus && (
                <button
                  type="button"
                  className="file-view__header-item active circle-hover"
                  onClick={() => {
                    actionHandler(actionsOptions.encrypt);
                  }}
                  data-test="file-view_print_button"
                  title="Encryption"
                >
                  <EncryptionIcon viewBox="1 2 17 17" />
                </button>
              )}
          </>
        )}
      </div>
      <button
        className="file-view__go-back-button"
        onClick={handleOnClose}
        type="button"
        data-test="file-view_go-back_button"
        title="Close"
      >
        <CloseIcon />
      </button>
    </header>
  );

  const footerContent = `${entity.name} | ${transformSize(entity.size)}`;
  const showFooter = imagesWithoutPreview.includes(`.${entity.extension}`)
    ? true
    : !imageFileExtensions.includes(`.${entity.extension}`);

  const modalContent =
    (isFileEncrypted && !imageMediaTypesPreview.includes(entity.mime)) ||
    !isLoader ? (
      <div className="file-view__content">
        {headerContent}
        {entityPrev && (
          <button
            type="button"
            className="file-view__prev"
            ref={prevButtonRef}
            onClick={onPrevNextClick(entityPrev)}
            data-test="file-view_prev_button"
            title="Go back"
          >
            <ArrowPrev />
          </button>
        )}
        <FileContent
          file={entity}
          entityContent={entityContent}
          setLoader={setLoader}
          isSvg={isSvg}
          setDownloadUrl={setEntityContent}
          scrollElementRef={modalRef}
          footerContent={footerContent}
          onClickOutSide={
            shouldCloseOnOverlayClick ? null : onClickOutSideFileContentHandler
          }
          data-test="file-view_file-content_actions"
        />
        {entityNext && (
          <button
            type="button"
            className="file-view__next"
            ref={nextButtonRef}
            onClick={onPrevNextClick(entityNext)}
            data-test="file-view_next_button"
            title="Go next"
          >
            <ArrowNext />
          </button>
        )}
        {showFooter && (
          <div className="file-view__footer">
            <div className="file-view__title">{footerContent}</div>
          </div>
        )}
      </div>
    ) : isIpfsService(entity.service) ? (
      <div className="file-view__content">
        <header className="file-view__header" ref={headerRef}>
          <div className="file-view__buttons-wrapper">
            <div className="file-view__title">{entity.name}</div>
            <div className="file-view__menu-buttons">
              <a
                rel="noopener noreferrer"
                className="file-view__header-item active"
                data-test="file-view_ipfs_download_link"
                onClick={() =>
                  axios({
                    url: `${ipfsLink(entity.key)}`,
                    method: 'GET',
                    responseType: 'blob',
                  }).then((response) => {
                    const url = window.URL.createObjectURL(
                      new Blob([response.data])
                    );
                    downloadFromBlob(url, entity.name);
                  })
                }
              >
                <DownloadIcon color="#FFFFFF" />
              </a>
              {canBePrinted && (
                <button
                  type="button"
                  className="file-view__header-item active"
                  onClick={printFile}
                  data-test="file-view_ipfs_print_button"
                >
                  <PrintIcon color="#FFFFFF" />
                </button>
              )}
              {isShowContextButton && (
                <button
                  className="file-view__actions-icon-vertical"
                  onMouseDown={handleActionsButtonClick}
                  type="button"
                  data-test="file-view_ipfs_file-actions_button"
                >
                  <ActionsIconVertical />
                </button>
              )}
              {showContext && !isPublic && (
                <ContextMenu
                  className="file-view__context-menu"
                  onClickOutside={handleClickOutsideContextMenu}
                  actionHandler={actionHandler}
                  options={contextOptions}
                  data-test="file-view_ipfs_context-menu_button"
                />
              )}
              <button
                className="file-view__go-back-button"
                onClick={handleOnClose}
                type="button"
                data-test="file-view_ipfs_go-back_button"
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        </header>
        {entityPrev && (
          <button
            type="button"
            className="file-view__prev"
            ref={prevButtonRef}
            onClick={onPrevNextClick(entityPrev)}
            data-test="file-view_entity_prev_button"
          >
            <ArrowPrev />
          </button>
        )}
        <FileContent
          file={entity}
          isPublic={isPublic}
          setLoader={setLoader}
          handleOnClose={handleOnClose}
          ipfsKey={isIpfsService(entity.service) ? entity.key : false}
          setEntityContent={setEntityContent}
          ipfsService={isIpfsService(entity.service)}
          entityContent={isIpfsService(entity.service) ? true : entityContent}
          scrollElementRef={modalRef}
          onClickOutSide={
            shouldCloseOnOverlayClick ? null : onClickOutSideFileContentHandler
          }
        />
        {entityNext && (
          <button
            type="button"
            className="file-view__next"
            ref={nextButtonRef}
            onClick={onPrevNextClick(entityNext)}
            data-test="file-view_entity_next_button"
          >
            <ArrowNext />
          </button>
        )}
      </div>
    ) : (
      <>
        {headerContent}
        <div className="file-view__content file-view__content-preloader">
          <GhostLoader
            texts={[
              accountT('convertModal.loading'),
              accountT('convertModal.pleaseWait'),
            ]}
          />
        </div>
        <div className="file-view__footer">
          <div className="file-view__title">{`${entity.name} | ${transformSize(
            entity.size
          )}`}</div>
        </div>
      </>
    );

  return (
    <div className="file-view">
      <Modal
        isOpen={isOpen}
        onRequestClose={handleOnClose}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        className={cn(
          'Modal',
          docMediaTypesPreview.includes(entity?.mime) && 'Modal--scroll'
        )}
        overlayClassName="Overlay"
        contentRef={(node) => (modalRef.current = node)}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

FileView.defaultProps = {
  entity: null,
  isOpen: false,
  onRequestClose: () => {},
  isPublic: false,
  shouldCloseOnOverlayClick: true,
  fileContentExternal: null,
};

export default FileView;
