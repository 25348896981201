import { isEmpty, isNil } from 'ramda';

/**
 *
 * @param {ISecurity[]} [data=[]]
 */
export const createSecurityReqObj = (data = []) => {
  if (isNil(data) || isEmpty(data)) return {};
  let req = {};

  data.map((security) => {
    return (req[security.keyword] = security.value);
  });

  return req;
};
