import * as actions from '../../actions/securities/securities-deactivate.actions'
import { merge, prop } from 'ramda'
import removeBy from 'utils/array/remove-by'

const initialState = {
  pending: false,
  options: [],
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.SECURITIES_DEACTIVATE:
      return merge(state, {
        pending: true,
        options: [...state.options, prop('payload', action)],
      })
    case actions.SECURITIES_DEACTIVATE_SUCCESS:
      return merge(state, {
        pending: false,
        options: removeBy(prop('payload', action), state.options, 'type'),
      })
    case actions.SECURITIES_DEACTIVATE_FAILURE:
      return merge(state, {
        pending: false,
        options: removeBy(prop('payload', action), state.options, 'type'),
      })
    default:
      return state
  }
}
