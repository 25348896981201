//Refactoring №3
import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import SpaceRow from './spaceRow';

import s from './style.module.scss';

export default function Space({
  previewClassName,
  contentClassName,
  headerClassName,
  className,
  children,
  icon,
  title,
  subTitle,
  actions,
  onlyTitle,
  id,
}) {
  return (
    <div id={id} className={cn(s.space, className)}>
      <div
        className={cn(
          s.space__preview,
          !icon && s.space__preview_no,
          previewClassName
        )}
      >
        <div className={s.space__preview_icon}>{icon}</div>
      </div>
      <div
        className={cn(
          s.space__content,
          !icon && s.space__content_full,
          contentClassName
        )}
      >
        {(title || subTitle) && (
          <div className={cn(s.header, headerClassName)}>
            {!onlyTitle ? (
              <div className={s.header__content}>
                <div>
                  <h3 className={s.header__content_title}>{title}</h3>
                  <p className={s.header__content_subtitle}>{subTitle}</p>
                </div>
              </div>
            ) : (
              <h3 className={s.header__content_title}>{title}</h3>
            )}
            <div className={s.header__actions}>{actions}</div>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

Space.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onlyTitle: PropTypes.bool,
};

Space.defaultProps = {
  subTitle: '',
  title: '',
  className: '',
  icon: '',
  child: '',
  actions: '',
  onlyTitle: false,
};

export { SpaceRow };
