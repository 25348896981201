import {
  DimensionalModel,
  AnalogFilm,
  Anime,
  Cinematic,
  ComicBook,
  DigitalArt,
  Enhance,
  FantasyArt,
  Isometric,
  LineArt,
  LowPolly,
  Modeling,
  NeonPunk,
  Origami,
  Photografic,
  PixelArt,
  TileTexture,
  NeyraPank,
  NeyraArt,
  NeyraFace,
} from './icons';

export const GENERATION_OPTIONS = [
  { title: '3D Model', label: '3d-model', icon: DimensionalModel },
  { title: 'Movie effect', label: 'analog-film', icon: AnalogFilm },
  { title: 'Anime', label: 'anime', icon: Anime },
  { title: 'Cinematic', label: 'cinematic', icon: Cinematic },
  { title: 'Comics', label: 'comic-book', icon: ComicBook },
  { title: 'Digital', label: 'digital-art', icon: DigitalArt },
  { title: 'Enhance', label: 'enhance', icon: Enhance },
  { title: 'Fantasy', label: 'fantasy-art', icon: FantasyArt },
  { title: 'Isometric', label: 'isometric', icon: Isometric },
  { title: 'Line Art', label: 'line-art', icon: LineArt },
  { title: 'Low Poly', label: 'low-poly', icon: LowPolly },
  { title: 'Modeling', label: 'modeling-compound', icon: Modeling },
  { title: 'Neon Punk', label: 'neon-punk', icon: NeonPunk },
  { title: 'Origami', label: 'origami', icon: Origami },
  { title: 'Photographic', label: 'photographic', icon: Photografic },
  { title: 'Pixel', label: 'pixel-art', icon: PixelArt },
  { title: 'Tile Texture', label: 'tile-texture', icon: TileTexture },
];

export const REALTIME_GENERATION_OPTIONS = [
  { title: 'Anime', label: 'ANIME', icon: Anime },
  { title: 'Cinematic', label: 'CINEMATIC', icon: Cinematic },
  { title: 'Concept Art', label: 'DIGITAL_ART', icon: DigitalArt },
  { title: 'Dynamic', label: 'DYNAMIC', icon: AnalogFilm },
  { title: 'Environment', label: 'ENVIRONMENT', icon: Isometric },
  { title: 'Fantasy Art', label: 'FANTASY_ART', icon: FantasyArt },
  { title: 'Painting', label: 'ILLUSTRATION', icon: Modeling },
  { title: 'Photography', label: 'PHOTOGRAPHY', icon: Photografic },
  { title: 'Product', label: 'RENDER_3D', icon: Origami },
  { title: 'Raytraced', label: 'RAYTRACED', icon: NeonPunk },
  { title: 'Sketch B/W', label: 'SKETCH_BW', icon: LineArt },
  { title: 'Sketch Color', label: 'SKETCH_COLOR', icon: PixelArt },
  { title: 'Vibrant', label: 'VIBRANT', icon: LowPolly },
  { title: 'None', label: 'NONE', icon: TileTexture },
];

export const NEYRA_OPTIONS = [
  {
    title: 'Neyra Punk',
    label: 'neyra-punk',
    icon: NeyraPank,
    prompt:
      'cyber face with very delicate electronic waves, in ultra clothes with cryptic labyrinth pattern on the floor add topographic thin line hills and waves on the digital high tech wind of changes, hd, micro elements, sandy effect in motion',
  },
  {
    title: 'Neyra Art',
    label: 'neyra-art',
    icon: NeyraArt,
    prompt:
      'cyber face with very delicate electronic waves, in ultra clothes with cryptic labyrinth pattern on the floor add topographic thin line hills and waves on the digital high tech wind of changes, hd, micro elements, sandy effect in motion',
  },
  {
    title: 'Neyra Face',
    label: 'neyra-face',
    icon: NeyraFace,
    prompt:
      'face portrait, featuring microscopic but enlarged flower pollen, spores, in structure of face, mammatus clouds, liquid mercury, and open eyes integrated into the terrain, all in hd colors. The new viewpoint provides a more dynamic view of the various elements in the scene. in colors aesthetics',
  },
  ...GENERATION_OPTIONS,
];

export const getGenerationFilterOptions = (isDrawTab) => {
  return isDrawTab ? REALTIME_GENERATION_OPTIONS : NEYRA_OPTIONS;
};
