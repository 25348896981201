import React, { useState, useRef, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';

import styles from './NeyraTooltip.module.scss';

interface NeyraTooltipProps {
  text: string;
  position?: 'top' | 'right' | 'bottom' | 'left';
  children: ReactNode;
}

const NeyraTooltip = ({
  text,
  position = 'bottom',
  children,
}: NeyraTooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    updatePosition();
  }, [showTooltip]);

  useEffect(() => {
    window.addEventListener('scroll', updatePosition);
    return () => {
      window.removeEventListener('scroll', updatePosition);
    };
  }, []);

  const updatePosition = () => {
    if (
      !showTooltip ||
      !tooltipRef.current ||
      !containerRef.current ||
      !arrowRef.current
    )
      return;

    const hoveredElement = containerRef.current as HTMLElement;
    const tooltipElement = tooltipRef.current as HTMLElement;
    const arrowElement = arrowRef.current as SVGSVGElement;

    if (hoveredElement && tooltipElement && arrowElement) {
      const rect = hoveredElement.getBoundingClientRect();

      const tooltipHalfWidth = tooltipElement.offsetWidth / 2;

      switch (position) {
        case 'top':
          tooltipElement.style.left = `${
            rect.left + rect.width / 2 - tooltipHalfWidth
          }px`;
          tooltipElement.style.transform = 'translateX(-50%)';
          tooltipElement.style.top = `${
            rect.top - tooltipElement.offsetHeight - 5
          }px`;

          arrowElement.style.left = '50%';
          arrowElement.style.transform = 'translateX(-50%) rotate(180deg)';
          arrowElement.style.bottom = `-5px`;
          break;

        case 'right':
          tooltipElement.style.top = `${
            rect.top + rect.height / 2 - tooltipElement.offsetHeight / 2
          }px`;
          tooltipElement.style.left = `${rect.right + 5}px`;

          arrowElement.style.top = '50%';
          arrowElement.style.transform = 'translateY(-2px) rotate(-90deg)';
          arrowElement.style.left = `calc(-50% + 19px)`;
          break;

        case 'left':
          tooltipElement.style.top = `${
            rect.top + rect.height / 2 - tooltipElement.offsetHeight / 2
          }px`;
          tooltipElement.style.left = `${
            rect.left - tooltipElement.offsetWidth - 5
          }px`;

          arrowElement.style.top = '50%';
          arrowElement.style.transform = 'translateY(-2px) rotate(90deg)';
          arrowElement.style.right = `calc(-50% + 19px)`;
          break;

        case 'bottom':
          tooltipElement.style.left = `${
            rect.left + rect.width / 2 - tooltipElement.offsetWidth / 2
          }px`;
          tooltipElement.style.top = `${rect.bottom + 15}px`;

          arrowElement.style.left = '50%';
          arrowElement.style.transform = 'translateX(-50%)';
          arrowElement.style.top = `-5px`;
          break;
      }
    }
  };

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={containerRef}
    >
      {children}
      {showTooltip &&
        createPortal(
          <div className={styles.tooltip} ref={tooltipRef}>
            <svg
              ref={arrowRef}
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="5"
              viewBox="0 0 11 5"
              fill="none"
            >
              <path
                d="M0.5 5L10.5 5L6.91421 1.41421C6.13317 0.633165 4.86684 0.633164 4.08579 1.41421L0.5 5Z"
                fill="var(--tooltipBackground)"
              />
            </svg>
            {text}
          </div>,
          document.getElementById('tooltip-root') as HTMLElement
        )}
    </div>
  );
};

export default NeyraTooltip;
