import React, { useLayoutEffect } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from 'static/assets/svg/close.svg';
import NotificationFileSVGImage from 'components/svg/notification-file';
import AlertIcon from 'components/svg/alert';
import WarningIcon from 'components/svg/warning-notification';
import SuccessIcon from 'components/svg/success';
import ipfsLoader from 'components/svg/ipfs-loader.gif';

const DEFAULT_DURATION = 5000;

const NOTIFICATION_ICON = {
  alert: AlertIcon,
  warning: WarningIcon,
  success: SuccessIcon,
  default: NotificationFileSVGImage,
};
const sidebarSelector = (state) => state.leftMenu.sidebarIsOpen;

const NotificationItem = ({ notification, removeItem }) => {
  const {
    message,
    showButtons,
    acceptMessage,
    acceptHandler,
    cancelMessage,
    cancelHandler,
    hideIcon,
    type,
    width,
    merged,
  } = notification;
  const sidebarIsOpen = useSelector(sidebarSelector);

  const Icon =
    type && Object.prototype.hasOwnProperty.call(NOTIFICATION_ICON, type)
      ? NOTIFICATION_ICON[type]
      : NOTIFICATION_ICON.default;

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      removeItem(notification);
    }, DEFAULT_DURATION);

    return () => clearTimeout(timer);
  });

  const closeNotification = () => {
    removeItem(notification);
  };

  return (
    <div
      className={cn('notifications-item', {
        ['notifications-menu-collapsed']: sidebarIsOpen,
      })}
      style={{ width }}
      data-test={`toster_container`}
    >
      <div
        className={cn('notifications-item-icon', type, {
          ['notifications-item-merged']: merged,
        })}
      >
        {type === 'ipfsLoader' ? (
          <img src={ipfsLoader} alt="IPFS loading" />
        ) : (
          !hideIcon && <Icon />
        )}
      </div>
      <div
        className={cn('notifications-item-message', {
          ['notifications-item-merged']: merged,
        })}
        data-test={`toster_message`}
      >
        {message}
      </div>
      {showButtons && acceptMessage && (
        <button
          className="link link--shrink notifications-accept"
          onClick={acceptHandler}
          data-test={`toster_accept_message_button`}
        >
          {acceptMessage}
        </button>
      )}
      {showButtons && cancelMessage && (
        <button
          className="link link--shrink notifications-cancel"
          onClick={cancelHandler}
          data-test={`toster_cancel_message_button`}
        >
          {cancelMessage}
        </button>
      )}
      <button
        type="button"
        className="notifications-close"
        onClick={closeNotification}
        data-test={`toster_close_button`}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

NotificationItem.defaultProps = {
  notification: {},
  removeItem: () => {},
};

export default NotificationItem;
