//Refactoring №3
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import UserContext from 'store/home/contexts/user-context';
import { calcPercentageByFive, fromByteToGb } from 'utils/storage';
import { selectCollapsed } from 'features/leftMenu/leftMenuSlice';

import CN from 'classnames';
import style from './style.module.scss';

export function NewStorageInfo() {
  const { t } = useTranslation('workspace');
  const history = useHistory();
  const { user: me } = useContext(UserContext);
  const collapsed = useSelector(selectCollapsed);
  const storageSize = me?.space_total;
  const goToAccountStorage = () => history.push('/workspace/storage');

  if (collapsed) return null;

  return (
    <div className={style.storage__wrapper}>
      <div className={style.storage__row} onClick={goToAccountStorage}>
        <p className={style.storage__text}>{t('settings.storageUpper')}</p>
        <span
          className={CN(
            style[`storage__space-info__indicator`],
            style[
              `storage__space-info__indicator-${calcPercentageByFive(
                me?.storage,
                storageSize
              )}`
            ]
          )}
        />
        <p className={style.storage__calculationText}>
          {`${fromByteToGb(me?.storage)}/${fromByteToGb(storageSize)}GB`}
        </p>
      </div>
      <div className={style.storage__row} onClick={goToAccountStorage}>
        <p className={style.storage__text}>{t('settings.points')}</p>
        <div
          className={CN(
            style[`storage__space-info__indicator`],
            style[
              `storage__space-info__indicator-${calcPercentageByFive(100, 100)}`
            ]
          )}
        />
        <p className={style.storage__calculationText}>100/86</p>
      </div>
    </div>
  );
}
