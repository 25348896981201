//Refactoring №3
import React, {
  FC,
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  memo,
} from 'react';
import { useTranslation } from 'react-i18next';

import { uniqBy } from 'lodash';
import { useDispatch } from 'react-redux';

import { mintToken, getGroupSlug, getFactoryContract } from 'store/web3';
import { addRewards, reindexNftMetadataEffect } from 'store/file/effects';
import { getUserInfoEffect } from 'store/home/effects';

import { ReactComponent as AvatarIcon } from 'containers/main/EntitySettingsContainer/components/Web3Tab/components/TokenizationItem/assets/avatar.svg';
import CopyButton from 'components/Button/copy-button';
import MintForm from './MintForm';

import {
  getNetworkIcon,
  getNetworkById,
  getCurrentChainId,
} from 'utils/crypto';

import styles from './styles.module.scss';
import { copyToClipboard } from 'utils/string';
import useNotification from 'utils/hooks/use-notification';
import { ITokenization } from '../../interfaces';
import { INetwork } from 'web3modal/dist/providers/connectors/portis';
import { Monetization } from './components/Monetization';
import {
  ContractInfo,
  getContractInfo,
} from './components/Monetization/helper';

const HIDE_DESCRIPTION_COUNT = 100;

interface NetworkInterface extends INetwork {
  id?: number;
  explorer_url?: string;
}

interface CreateFormProps {
  tokenization: any;
  entity: any;
  initialOption?: string;
  networks: NetworkInterface[];
  setTokenizationData: (tokenization: ITokenization) => void;
  handleChangeNetwork: (chainId: number) => string | undefined;
}

// type TokenizOption = {
//   name: string;
//   icon: FC;
//   text: string;
//   className: string;
// };

type NetworkUrls = {
  [key: number]: string;
};

const initialContractInfo = {
  description: '',
  image: '',
  name: '',
  royalty: '',
  tokenId: '',
};

const Tokenization: FC<CreateFormProps> = ({
  tokenization,
  entity,
  networks,
  handleChangeNetwork,
  setTokenizationData,
}) => {
  const { t } = useTranslation('owner');
  const { addNotification } = useNotification();
  const dispatch = useDispatch();
  const [option, setOption] = useState<string>('info');
  const [showMoreText, setShowMoreText] = useState<boolean>(true);

  // const options = useMemo(
  //   () => [
  //     {
  //       name: 'info',
  //       icon: <InfoIcon />,
  //       text: t('rightFileMenu.tokenization.information'),
  //       className: styles.infoOption,
  //     },
  //     {
  //       name: 'mint',
  //       icon: <GhostIcon />,
  //       text: t('rightFileMenu.tokenization.tokenAccess'),
  //       className: '',
  //     },
  //     {
  //       name: 'hold',
  //       icon: <UsersIcon />,
  //       text: t('rightFileMenu.tokenization.holders'),
  //       className: '',
  //     },
  //     {
  //       name: 'monetization',
  //       icon: <MonetizationIcon />,
  //       text: t('rightFileMenu.tokenization.monetizationIDO'),
  //       className: '',
  //     },
  //     // {
  //     //   name: 'activity',
  //     //   icon: <ActivityIcon />,
  //     //   text: 'Activity',
  //     //   className: ''
  //     // },
  //   ],
  //   []
  // );

  const {
    ghost1155Collection,
    chain_id,
    collection,
    name: tokenName,
    description = '',
    royalties,
    tokenId,
    owners,
    maxSupply,
    totalSupply,
  } = tokenization[0];
  const countRef = useRef(totalSupply);
  countRef.current = totalSupply;
  const [loading, setLoading] = useState<boolean>(false);
  const [fileId, setFileId] = useState<string>('');
  const [contractInfo, setContractInfo] =
    useState<ContractInfo>(initialContractInfo);
  // const [contractAddress, setContractAddress] = useState('');
  const avaibleSupply = useMemo(
    () => parseInt(maxSupply) - parseInt(totalSupply),
    [maxSupply, totalSupply]
  );
  const isUnlimitedSupply = useMemo(
    () => parseInt(maxSupply) === 0,
    [maxSupply]
  );

  useEffect(() => {
    const fetchData = async () => {
      const fileId = await getGroupSlug(entity.slug);
      setFileId(fileId);
      const factoryContract = await getFactoryContract(ghost1155Collection.id);
      // setContractAddress(factoryContract.address);
      const contractInfo = await getContractInfo({
        contract_address: factoryContract.address,
        slug: fileId,
      });
      setContractInfo(contractInfo);
    };

    entity.slug && ghost1155Collection.id && void fetchData();
    return () => {
      setContractInfo(initialContractInfo);
      // setContractAddress('');
      setFileId('');
    };
  }, [entity.slug, ghost1155Collection.id]);

  useEffect(() => {
    setShowMoreText(description.length > 101);
  }, [description]);

  // const toggleOption = useCallback((newOption: string) => {
  //   setOption(newOption);
  // }, []);

  const copyAddress = useCallback((text: string) => {
    copyToClipboard(text);
  }, []);

  // const shareUrl = useMemo(() => {
  //   const host = window.location.origin;
  //   if (
  //     !ghost1155Collection?.workspaceId ||
  //     !fileId ||
  //     !contractAddress ||
  //     !chain_id
  //   )
  //     return '';
  //   return `${host}/tokenized/${ghost1155Collection?.workspaceId}/${fileId}/${contractAddress}/${chain_id}`;
  // }, [fileId, ghost1155Collection, chain_id, contractAddress]);

  const cutAddress = useCallback(
    (text = '', delta = 21) =>
      text.length <= delta
        ? text
        : `${text.slice(0, 35 - delta)}...${text.substr(text.length - 7)}`,
    []
  );

  const generateContractLink = (
    chainId: number,
    contractAddress: string
  ): string => {
    let networksUrl: NetworkUrls = {};
    for (const network of networks) {
      networksUrl = {
        ...networksUrl,
        [network.id as number]: network.explorer_url as string,
      };
    }

    return `${networksUrl[chainId]}address/${contractAddress}`;
  };

  const stopLoading = useCallback(() => {
    setLoading(false);
  }, []);

  const handleMintToken = useCallback(
    async (data) => {
      try {
        const currentChain = await getCurrentChainId();
        if (currentChain !== chain_id) {
          const nextChain = await handleChangeNetwork(chain_id);
          if (nextChain) {
            await mintProccess(data);
          } else {
            console.warn('Please change NETWORK');
          }
        } else {
          await mintProccess(data);
        }
      } catch (error) {
        await setLoading(false);
      }
    },
    [networks]
  );

  const mintProccess = useCallback(
    async (data) => {
      await setLoading(true);
      const responce = await mintToken(
        {
          ...data,
          tokenId,
          collectionHash: ghost1155Collection.id,
          slug: entity.slug,
        },
        stopLoading
      );
      if (responce) {
        const supplyCount = data.supplysArray.reduce(
          (sum, supply) => sum + supply
        );
        const owners = [
          ...tokenization[0].owners.map((address: { id: string }) => ({
            id: address.id.split('.')[0],
          })),
          ...data.addressArray.map((address: string) => ({
            id: address.toLowerCase(),
          })),
        ];
        const nextTokenization = tokenization && [
          {
            ...tokenization[0],
            totalSupply: String(parseInt(countRef.current) + supplyCount),
            owners: uniqBy(owners, 'id'),
          },
        ];
        setTokenizationData(nextTokenization);
        stopLoading();
        addNotification(t('rightFileMenu.tokenization.NFTMinted'), 'success');
        addRewards(3).then(() => {
          dispatch(getUserInfoEffect());
        });
        reindexNftMetadataEffect(entity.slug, ghost1155Collection.id);
      } else {
        stopLoading();
      }
    },
    [ghost1155Collection, entity, networks, tokenization]
  );

  const renderContent = useCallback(
    () => {
      return (
        <div className={styles.wrapper}>
          <div className={styles.optionBody}>
            <h2 className={styles.label}>
              {t('rightFileMenu.tokenization.information')}
            </h2>
            <div className={styles.info}>
              <p className={styles.infoTitle}>
                {t('rightFileMenu.tokenization.contract')}
              </p>
              <div className={styles.infoValue}>
                <a
                  className={styles.infoValueLink}
                  target="_blank"
                  href={generateContractLink(chain_id, ghost1155Collection.id)}
                  rel="noreferrer"
                >
                  {cutAddress(ghost1155Collection.id)}
                </a>
                <CopyButton
                  className={styles.infoValueCopy}
                  onClick={() => {
                    copyAddress(ghost1155Collection.id);
                  }}
                />
              </div>
            </div>
            <div className={styles.info}>
              <p className={styles.infoTitle}>
                {t('rightFileMenu.item.network')}
              </p>
              <div className={styles.infoValue}>
                <p className={styles.infoValueNetwork}>
                  {getNetworkById(chain_id)}
                </p>
                {getNetworkIcon(getNetworkById(chain_id))}
              </div>
            </div>
            <div className={styles.info}>
              <p className={styles.infoTitle}>
                {t('rightFileMenu.item.collection')}
              </p>
              <div className={styles.infoValue}>{collection}</div>
            </div>
            <div className={styles.info}>
              <p className={styles.infoTitle}>
                {t('rightFileMenu.item.itemName')}
              </p>
              <div className={styles.infoValue}>{tokenName}</div>
            </div>
            <div className={styles.info}>
              <p className={styles.infoTitle}>
                {t('rightFileMenu.item.royalties')}
              </p>
              <div className={styles.infoValue}>{`${royalties}%`}</div>
            </div>
            <div className={styles.info}>
              <p className={styles.infoTitle}>
                {t('rightFileMenu.tokenization.ID')}
              </p>
              <div className={styles.infoValue}>{tokenId}</div>
            </div>
            <div className={styles.info}>
              <p className={styles.infoTitle}>
                {t('rightFileMenu.tokenization.tokenStandart')}
              </p>
              <div className={styles.infoValue}>ERC-1155</div>
            </div>
            <div className={styles.description}>
              <p className={styles.descriptionTitle}>
                {t('rightFileMenu.item.description')}
              </p>
              <p className={styles.descriptionValue}>
                {showMoreText ? (
                  <>
                    {`${description.slice(0, HIDE_DESCRIPTION_COUNT)}...`}
                    <span
                      className={styles.descriptionMore}
                      onClick={() => {
                        setShowMoreText(false);
                      }}
                    >
                      {t('rightFileMenu.item.more')}
                    </span>
                  </>
                ) : (
                  description
                )}
              </p>
            </div>
          </div>
          <div className={styles.optionBody}>
            <h2 className={styles.label}>
              {t('rightFileMenu.tokenization.minting')}
            </h2>
            <div className={styles.info}>
              <p className={styles.infoTitle}>
                {t('rightFileMenu.item.maxSupply')}
              </p>
              <div className={styles.infoValue}>
                {isUnlimitedSupply
                  ? t('rightFileMenu.sharing.unlimited')
                  : maxSupply}
              </div>
            </div>
            <div className={styles.info}>
              <p className={styles.infoTitle}>
                {t('rightFileMenu.tokenization.available')}
              </p>
              <div className={styles.infoValue}>
                {isUnlimitedSupply
                  ? t('rightFileMenu.sharing.unlimited')
                  : avaibleSupply}
              </div>
            </div>
            <MintForm
              avaibleSupply={avaibleSupply}
              isUnlimitedSupply={isUnlimitedSupply}
              onSubmit={handleMintToken}
              networks={networks}
              loading={loading}
            />
          </div>
          {!!owners?.length && (
            <div className={styles.optionBody}>
              <h2 className={styles.label}>
                {t('rightFileMenu.tokenization.holders')}
              </h2>
              <div className={styles.hodlerList}>
                {owners &&
                  owners.length > 0 &&
                  owners.map(({ id }) => (
                    <div className={styles.holder} key={id}>
                      <AvatarIcon />
                      <a
                        className={styles.holderAddress}
                        target="_blank"
                        href={`https://testnet.bscscan.com/address/${
                          id.split('.')[0]
                        }`}
                        rel="noreferrer"
                      >
                        {cutAddress(id.split('.')[0], 4)}
                      </a>
                      <CopyButton
                        className={styles.holderCopy}
                        onClick={() => {
                          copyAddress(id.split('.')[0]);
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}
          <div className={styles.optionBody}>
            <h2 className={styles.label}>
              {t('rightFileMenu.tokenization.monetize')}
            </h2>
            <Monetization
              contractInfo={contractInfo}
              chainId={chain_id}
              networks={networks}
              slug={fileId}
              tokenId={tokenId}
              tokenAddress={ghost1155Collection.id}
              tokenName={tokenName}
            />
          </div>
        </div>
      );
    },
    [
      ghost1155Collection,
      owners,
      avaibleSupply,
      maxSupply,
      showMoreText,
      collection,
      tokenName,
      royalties,
      tokenId,
      description,
      loading,
      option,
      fileId,
      contractInfo,
    ]
  );

  return (
    <div className={styles.optionList}>
      {renderContent()}
      {/* <div className={styles.footer}>
        <div className={styles.footerField}>
          {shareUrl ? (
            <>
              <a
                className={styles.footerAddress}
                target="_blank"
                href={shareUrl}
                rel="noreferrer"
              >
                {cutAddress(shareUrl, 0)}
              </a>
              <CopyButton
                onClick={() => {
                  copyAddress(shareUrl);
                }}
              />
            </>
          ) : (
            <Spinner className={styles.footerField__loader} />
          )}
        </div>
      </div> */}
    </div>
  );
};

export default memo(Tokenization);
