export const FILE_RESTORE = 'FILE_RESTORE'
export const FILE_RESTORE_SUCCESS = 'FILE_RESTORE_SUCCESS'
export const FILE_RESTORE_FAILURE = 'FILE_RESTORE_FAILURE'

export function fileRestore() {
  return {
    type: FILE_RESTORE,
  }
}

export function fileRestoreSuccess() {
  return {
    type: FILE_RESTORE_SUCCESS,
  }
}

export function fileRestoreFailure() {
  return {
    type: FILE_RESTORE_FAILURE,
  }
}
