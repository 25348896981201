import { getEncryptedFileDetailsEffect } from 'store/home/effects/file/get-encrypted-file-details.effect';

export const getEncryptedFileKey = async (file: any, dispatch: any) => {
  const {
    data: { data: encryptedData, count },
  } = await getEncryptedFileDetailsEffect(file, dispatch);

  if (count) {
    const [userPublicAddress] = await window.metamask.request({
      method: 'eth_requestAccounts',
    });
    const userKey = encryptedData.find(
      (el) => el.user_public_address.public_address === userPublicAddress
    );
    return userKey?.encrypted_key;
  }
};
