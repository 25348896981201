import React from 'react';

const LockIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip-lock-0)">
      <path
        d="M0.467437 15.9999C0.209124 15.9991 -6.90146e-07 15.7887 -6.78883e-07 15.531L-5.62965e-07 12.8791C-5.5753e-07 12.7548 0.0494057 12.6356 0.137343 12.5476L5.94306 6.74188C5.34862 4.9057 5.82553 2.88132 7.19884 1.50804C9.20956 -0.502679 12.4813 -0.502679 14.492 1.50804C16.5027 3.51873 16.5028 6.79038 14.492 8.80116C13.1187 10.1745 11.0943 10.6514 9.25812 10.0569L8.85687 10.4582L8.55322 11.9765C8.51609 12.1621 8.37103 12.3072 8.18544 12.3443L6.83437 12.6145L6.56416 13.9656C6.52703 14.1514 6.38172 14.2965 6.19594 14.3335L4.907 14.5899L4.907 15.5312C4.907 15.7901 4.69709 16 4.43819 16L0.468812 16C0.468343 16 0.467906 15.9999 0.467437 15.9999ZM3.96934 15.0624L3.96934 14.2051C3.96934 13.9815 4.12734 13.789 4.34669 13.7453L5.70609 13.4749L5.97625 12.1242C6.01337 11.9386 6.15844 11.7935 6.34403 11.7564L7.69509 11.4862L7.96531 10.1351C7.98347 10.0444 8.02806 9.96101 8.09353 9.89557L8.80478 9.18432C8.93647 9.05263 9.13362 9.01135 9.30706 9.0792C10.8707 9.69082 12.6457 9.32141 13.829 8.13813C15.4739 6.49323 15.4744 3.81641 13.829 2.17101C12.1839 0.525883 9.50703 0.525883 7.86191 2.17101C6.67672 3.35623 6.31016 5.13166 6.92084 6.69298C6.98869 6.86648 6.94744 7.06357 6.81572 7.19526L0.937656 13.0733L0.937656 14.3994L6.76744 8.56954C6.95053 8.38645 7.24734 8.38645 7.43044 8.56954C7.52197 8.66107 7.56778 8.78107 7.56778 8.90104C7.56778 9.02101 7.52203 9.14101 7.43044 9.23254L1.60066 15.0624L3.96934 15.0624Z"
        fill="currentColor"
      />
      <path
        d="M11.1769 4.82316C10.6285 4.27478 10.6285 3.38251 11.1769 2.83413C11.7253 2.28576 12.6176 2.28576 13.1659 2.83413C13.7143 3.38247 13.7144 4.27478 13.1659 4.82316C12.6176 5.37153 11.7253 5.37153 11.1769 4.82316ZM12.5029 4.16016C12.6862 3.97688 12.6862 3.68041 12.5029 3.49716C12.3196 3.31391 12.0232 3.31394 11.8399 3.49716C11.6571 3.68 11.6571 3.97738 11.8399 4.16016C12.0227 4.34294 12.3201 4.34294 12.5029 4.16016Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip-lock-0">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="matrix(-4.37114e-08 1 1 4.37114e-08 0 0)"
        />
      </clipPath>
    </defs>
  </svg>
);

LockIcon.defaultProps = {};

export default LockIcon;
