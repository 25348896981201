import { isEmpty } from 'ramda'

const getErrorArray = (error, defaultMessage = 'Something went wrong!') => {
  const responseErrors = error?.response?.data?.errors || { children: {} }

  let errors = []
  if (error instanceof Error) {
    errors.push(error.toString());
  } 
  if (typeof responseErrors === 'string') {
    errors.push(responseErrors)
  } else {
    const error = Object.values(responseErrors.children).filter(
      (option) => !!option.errors
    )
    error.forEach((item) => errors.push(item.errors))
  }
  if (isEmpty(errors) && !isEmpty(responseErrors.errors))
    errors = errors.concat(responseErrors.errors)
  if (isEmpty(errors)) errors.push(defaultMessage)

  return errors
}

export default getErrorArray
