import React from 'react';

const RenameIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9266 14.8591C12.7525 14.8591 12.6058 14.8001 12.4865 14.6822C12.3673 14.5642 12.3077 14.4181 12.3077 14.2437V12.4458C12.3077 12.276 12.3667 12.1315 12.4846 12.0123C12.6026 11.8931 12.7487 11.8335 12.9231 11.8335H12.9872V11.1668C12.9872 10.8343 13.1007 10.5546 13.3276 10.3277C13.5545 10.1008 13.8342 9.98729 14.1667 9.98729C14.4992 9.98729 14.7789 10.1008 15.0058 10.3277C15.2327 10.5546 15.3462 10.8343 15.3462 11.1668V11.8335H15.4103C15.5774 11.8335 15.7174 11.8924 15.8305 12.0104C15.9435 12.1283 16 12.2745 16 12.4488V14.2467C16 14.4165 15.9411 14.561 15.8233 14.6802C15.7055 14.7995 15.5595 14.8591 15.3853 14.8591H12.9266ZM13.5 11.8335H14.8333V11.1668C14.8333 10.9779 14.7695 10.8196 14.6417 10.6918C14.5139 10.564 14.3556 10.5001 14.1667 10.5001C13.9778 10.5001 13.8195 10.564 13.6917 10.6918C13.5639 10.8196 13.5 10.9779 13.5 11.1668V11.8335ZM10 14.8591L2.94873 7.80781C3.91881 6.93515 5.01005 6.27255 6.22245 5.81999C7.43484 5.36743 8.69403 5.14114 10 5.14114C11.306 5.14114 12.5652 5.36743 13.7776 5.81999C14.99 6.27255 16.0812 6.93515 17.0513 7.80781L16.009 8.85013C15.8953 8.79029 15.7806 8.74115 15.6648 8.70269C15.5489 8.66423 15.4291 8.63089 15.3051 8.60269L16.0667 7.84114C15.1889 7.17448 14.2333 6.66892 13.2 6.32448C12.1667 5.98003 11.1 5.80781 10 5.80781C8.90001 5.80781 7.83335 5.98003 6.80001 6.32448C5.76668 6.66892 4.81113 7.17448 3.93335 7.84114L10 13.9078L10.3333 13.5745C10.3616 13.6984 10.3949 13.8183 10.4333 13.9341C10.4718 14.0499 10.521 14.1646 10.5808 14.2783L10 14.8591Z"
      fill="currentColor"
    />
  </svg>
);

RenameIcon.defaultProps = {};

export default RenameIcon;
