import React, { useContext, ReactNode, useCallback } from 'react';
import CN from 'classnames';
import { useSelector } from 'react-redux';
import { NavLink, matchPath } from 'react-router-dom';
import { selectCollapsed } from 'features/leftMenu/leftMenuSlice';
import MainContext from 'store/main/context/main-context';
import UserContext from 'store/home/contexts/user-context';
import { checkDeviceAndScroll } from 'utils/browser';

import { ILocation } from 'interfaces/utils/history'

import style from './style.module.scss';
import { isNumber } from 'lodash';

const strokes = ['Vault', 'Task Manager'];
const folderUrl = '/folder/:folderId';

type IProps = {
  name: string,
  icon: ReactNode,
  url: string,
  disabled?: boolean,
  isGuest?: boolean,
  isBeta?: boolean,
  fill?: boolean,
  className?: string,
  count?: boolean,
}

export function MenuItem({
  name,
  icon,
  url,
  disabled = false,
  isGuest = false,
  isBeta = false,
  fill = true,
  className,
  count,
}: IProps) {
  const collapsed = useSelector(selectCollapsed);
  const {
    state: { folder },
  } = useContext(MainContext);
  const { user } = useContext(UserContext);

  const isActionHandler = useCallback((_: null, location: ILocation) => {
    const isMyFolder = folder?.user?.id === user.id;
    if (
      isGuest &&
      url === '/sharing' &&
      matchPath(location.pathname, '/folder')
    )
      return true;
    if (
      (isMyFolder &&
        !location?.state?.sharedPage &&
        matchPath(location.pathname, folderUrl) &&
        url === '/main') ||
      (isMyFolder && location?.state?.sharedPage && url === '/sharing')
    )
      return true;

    return !!matchPath(location.pathname, url);
  }, [folder, user, isGuest, url]);

  return (
    <NavLink
      data-test={`sidebar-button-${name}`}
      isActive={isActionHandler}
      to={!disabled && url}
      className={CN(
        { [style.collapsed]: collapsed },
        style.wrapper,
        strokes.includes(name) && style.stroke,
        !fill && style.noFill,
        className
      )}
      title={name}
      activeClassName={style.active}
      onClick={checkDeviceAndScroll}
    >
      <div className={style.item}>
        <div className={style.itemContent}>
          <span className={style.icon}>{icon}</span>
          <span className={style.name}>{name}</span>
        </div>
        {isBeta && <span className={style.betaMatker}>BETA</span>}
        {(isNumber(count) && !collapsed) && <span className={style.countMarker}>{count}</span>}
        {/* {disabled && <span className={style.disableMarker}> Comming soon</span>} */}
      </div>
    </NavLink>
  );
}
