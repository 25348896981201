/* eslint-disable */
import * as actions from '../actions/file-fiew.actions'
import getData from 'utils/request/get-data'
import { assoc } from 'ramda'

const initialState = {
  type: 'square',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_FILE_VIEW:
      return assoc('type', getData(action), state)

    case actions.SET_FILE_VIEW:
      return assoc('type', getData(action), state)

    default:
      return state
  }
}

//selectors
export const selectFileViewType = (state) => state.home?.fileView?.type
