// Refactoring №2
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GHOST_DRIVE_API } from 'constants/api-urls';
import { VideoPlayer } from 'fe-ui-kit/dist/index.esm';
import GhostLoader from 'components/GhostLoader';
import { getDecryptionKey } from 'utils/file/getDecryptKey';
import { sendFileViewStatistic } from 'store/home/effects/statistic/file-statistic-effect';

import s from './style.module.scss';

const VideoViewer = ({ file }) => {
  const { t: accountT } = useTranslation('account');
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [decryptionKey, setDecryptionKey] = useState('');

  const onStart = () => {
    setIsLoaded(true);
    sendFileViewStatistic(file.slug);
  };

  const onError = (err) => {
    setIsLoaded(true);
    setError(err);
  };

  useEffect(() => {
    if (file.is_clientside_encrypted) {
      getDecryptionKey(file).then((key) => {
        key ? setDecryptionKey(key) : onError('Error fetching decryption key');
      });
    }
  }, [file.is_clientside_encrypted]);

  const renderVideo = useCallback(() => {
    if (file.is_clientside_encrypted && !decryptionKey) {
      return null;
    }

    if (file.slug) {
      return (
        <div className={isLoaded ? s.mediaVisible : s.mediaInvisible}>
          <VideoPlayer
            slug={file.slug}
            apiUrl={GHOST_DRIVE_API}
            decryptionKey={decryptionKey}
            muted
            height={'75vh'}
            onError={onError}
            className={s.videoContainer}
            playerProps={{
              onStart: onStart,
            }}
          />
        </div>
      );
    }
  }, [file.slug, isLoaded, decryptionKey, file.is_clientside_encrypted]);

  return (
    <div className="file-view__video-container">
      <div className="file-content-image-container video-player">
        {error ? <div className="error">{error}</div> : renderVideo()}
        {!isLoaded && (
          <div className="loader">
            <GhostLoader
              texts={[
                accountT('convertModal.loading'),
                accountT('convertModal.pleaseWait'),
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoViewer;
