import * as actions from '../actions';
import * as R from 'ramda';
import authRequest from 'utils/request/auth-request';
import { API_USERS, API_USER, API_EDIT_USER_INFO } from 'constants/api-urls';

export function editUserInfoEffect(params = {}) {
  const { userInfo } = params;
  return async (dispatch, getState) => {
    dispatch(actions.editUserInfo());
    dispatch(actions.changeUserInfo(userInfo));

    const state = getState();

    const accountInfo = R.path(['account', 'userInfo', 'entities'], state);

    const reqData = R.pick(
      ['dateformat', 'email', 'phone', 'telegram', 'timezone', 'wechat'],
      accountInfo
    );

    try {
      const result = await authRequest.post(`${API_EDIT_USER_INFO}`, reqData);

      return dispatch(actions.editUserInfoSuccess(result));
    } catch (e) {
      return dispatch(actions.editUserInfoFailure());
    }
  };
}

export const editUserInfoProfileEffect = async (reqData) =>
  authRequest
    .patch(`${API_EDIT_USER_INFO}`, reqData)
    .then((response) => {
      return response?.data;
    })
    .catch((response) => {
      throw response.message;
    });

export const changePasswordEffect = async (
  oldPassword,
  newPassword,
  repeatPassword
) =>
  authRequest
    .post(`${API_USER}/password`, {
      current_password: oldPassword,
      plainPassword: {
        first: newPassword,
        second: repeatPassword,
      },
    })
    .then((response) => {
      return response?.data;
    })
    .catch((req) => {
      throw req.response.data.errors;
    });

export const changeEmailEffect = async (newEmail, currentPassword) =>
  authRequest
    .post(`${API_USER}/change/email`, {
      email: newEmail,
      password: currentPassword,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((req) => {
      throw req?.response?.data?.message;
    });
export const addEmailAndPasswordEffect = async (
  newEmail,
  fitstPassword,
  repeatPassword,
  signature,
  address
) =>
  authRequest
    .post(`${API_USER}/set/email`, {
      email: newEmail,
      signature: signature,
      plainPassword: { first: fitstPassword, second: repeatPassword },
      address: address,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((req) => {
      throw req.response.data.errors;
    });

export const activetedLinkChangeEffect = async (activeCode) =>
  authRequest
    .post(`${API_USER}/email/change`, {
      hash: activeCode,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((req) => {
      throw req.response.data.errors;
    });

export const changeRecoveryInfoEffect = async (recoveryEmail, recoveryPhone) =>
  authRequest
    .put(`${API_USERS}/recovery`, {
      recovery_email: recoveryEmail,
      recovery_phone: recoveryPhone,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((response) => {
      throw response.data.errors;
    });

export const changeUserLogoutEffect = async (logout) =>
  authRequest
    .put(`${API_USERS}/logout`, {
      logout,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((req) => {
      throw req.response.data.errors;
    });

export const updateUserFirstLoginEffect = async () =>
  authRequest
    .post(`${API_USERS}/first/login`)
    .then((response) => {
      return response?.data;
    })
    .catch((req) => {
      throw req.response.data.errors;
    });
