import React from 'react';

import { ReactComponent as BigCircleIcon } from '../../assets/big-circle.svg';
import NeyraPass from '../../assets/neyra-pass.png'

import baseStyles from '../AuthContent/styles.module.scss';
import styles from './styles.module.scss';

const SetupContent = () => {

  return (
    <div className={baseStyles.container}>
      <div className={styles.avatarContainer}>
        <BigCircleIcon className={baseStyles.bigCircle} />
        <div className={styles.neyraPass}>
          <img className={styles.neyraPassImg} src={NeyraPass} alt='Neyra Pass' />
        </div>
      </div>
      <div className={styles.info}>
        <h1 className={styles.title}>Neyra Pass</h1>
        <h2 className={styles.subTitle}>Monetize Your AI Legacy</h2>
        <p className={styles.description}>
          {`With the Neyra Pass, you're empowered to craft, launch, and profit from your unique AI legacy, pioneering the future of digital assets and creativity. Unlock complete access to Neyra expansive suite of features for an unmatched digital experience.`}
        </p>
      </div>
    </div>
  );
};

export default SetupContent;
