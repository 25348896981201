import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import logo from '../../assets/neyraLogo.png';

const WelcomeText = ({ isImage = false }) => {
  const [animationText, setAnimationText] = useState('');
  const { t } = useTranslation('auth');

  useEffect(() => {
    let currentIndex = 0;
    const textText = t('initialPage.helloImNeyra');

    const interval = setInterval(() => {
      if (currentIndex <= textText.length) {
        setAnimationText(textText.substring(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.welcomeNeyra}>
      <div className={styles.dron}></div>
      <div className={styles.text}>
        {isImage ? <img src={logo} alt="neyra" /> : animationText}
      </div>
      ;
    </div>
  );
};

export default WelcomeText;
