import { isShareMobileApp } from 'utils/url/is-page';

const useRedirect = () => {
  const isGdApp = isShareMobileApp();

  if (isGdApp && location.pathname === '/') {
    window.location.replace("https://ghostdrive.com/");
  }

  return isGdApp && location.pathname === '/'
}

export { useRedirect };