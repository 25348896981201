import * as actions from '../actions'
import { merge } from 'ramda'

const initialState = {
  pending: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.MAKE_STRIPE_PAYMENT:
      return merge(state, {
        pending: true,
      })
    case actions.MAKE_STRIPE_PAYMENT_SUCCESS:
      return merge(state, {
        pending: false,
      })
    case actions.MAKE_STRIPE_PAYMENT_FAILURE:
      return merge(state, {
        pending: false,
      })
    default:
      return state
  }
}
