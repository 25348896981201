export type transforms = {
  [key: string]: any;
  delimiter(): string;
  header(block: block): string;
  paragraph(block: block): string;
  list(block: block): string;
  image(block: block): string;
  quote(block: block): string;
  code(block: block): string;
  embed(block: block): string;
  warning(block: block): string;
};

type ListItem = {
  content: string;
  items: Array<ListItem>;
};

const alignType = ["left", "right", "center", "justify"]

export type block = {
  type: string;
  data: {
    text?: string;
    level?: number;
    caption?: string;
    message?: string;
    title?: string;
    url?: string;
    file?: {
      url?: string;
    };
    stretched?: boolean;
    withBackground?: boolean;
    withBorder?: boolean;
    items?: Array<string> | Array<ListItem>;
    style?: string;
    code?: string;
    service?: "vimeo" | "youtube";
    source?: string;
    embed?: string;
    width?: number;
    height?: number;
    alignment?: "left" | "right" | "center" | "justify";
    align?: "left" | "right" | "center" | "justify";
  };
};

const transforms: transforms = {
  delimiter: () => {
    return `\n`;
  },

  header: ({ data }) => {
    return `${data.text}\n`;
  },

  paragraph: ({ data }) => {
    const paragraphAlign = data.alignment || data.align;

    if (typeof paragraphAlign !== 'undefined' && alignType.includes(paragraphAlign)) {
      return `${data.text}\n`;
    } else {
      return `${data.text}\n`
    }
  },

  list: ({ data }) => {
    const listStyle = data.style === "unordered" ? "ul" : "ol";

    const recursor = (items: any, listStyle: string) => {
      const list = items.map((item: any) => {
        if (!item.content && !item.items) return `${item}\n`;

        let list = "";
        if (item.items) list = recursor(item.items, listStyle);
        if (item.content) return `${item.content} ${list}\n`;
      });

      return `${list.join("")}\n`;
    };

    return recursor(data.items, listStyle);
  },

  image: () => {
    return ` `;
  },

  quote: ({ data }) => {
    return `${data.text} - ${data.caption}\n`;
  },

  code: ({ data }) => {
    return `<code>${data.code}</code>\n`;
  },

  warning: ({ data }) => {
    return `${data.title}\n${data.message}`;
  },

  embed: ({ data }) => {
    switch (data.service) {
      case "vimeo":
        return `iframe src="${data.embed}"\n`;
      case "youtube":
        return `video src="${data.embed}"\n`;
      default:
        throw new Error("");
    }
  },
};

export default transforms;