import React from 'react';

const Memebrs = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10.625"
      cy="9.5"
      r="2.775"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="17.9078"
      cy="11.4182"
      r="1.65"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.625 14C6.625 14 6.125 16.5184 6.125 19.625H15.125C15.125 16.5184 14.625 14 10.625 14Z"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1356 15.125C20.6236 15.125 21.875 16.8125 21.875 19.625H17.6126"
      stroke="currentColor"
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Memebrs.defaultProps = {};

export default Memebrs;
