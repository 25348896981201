import { createContext } from 'react'
import { IUser } from 'interfaces/user'

export type ContexType = {
  user: IUser | Record<string, never>,
  setUser: { (user: IUser) : void },
}

const UserContext = createContext<ContexType>({
  user: {},
  setUser: () => ({})
})

export default UserContext
