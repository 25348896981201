// @flow

import { matchPath } from 'react-router-dom'
import { defaultTo, path } from 'ramda'

const getFolderId = (location) => {
  const paramsFromUrl = matchPath(location.pathname, {
    path: '/folder/:folderId',
  })
  return defaultTo(null, path(['params', 'folderId'], paramsFromUrl))
}

export default getFolderId
