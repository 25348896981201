import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './root-reducer'

import rootSaga from 'store/rootSaga'

const sagaMiddleware = createSagaMiddleware()

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware, sagaMiddleware))
)

export type IRootState = ReturnType<typeof store.getState>
export type IAppDispatch = typeof store.dispatch

sagaMiddleware.run(rootSaga)
