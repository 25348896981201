import React, { useState, useRef, useLayoutEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import useClickOutside from 'utils/hooks/use-click-outside';
import EntitySettings from 'store/home/contexts/entity-settings';
import UserIcon from 'components/users/user-icon';

import { unix } from 'moment';

import styles from './styles.module.scss';

const CommentItem = ({
  comment,
  saveComment,
  showActive,
  allowReply = true,
}) => {
  const { id, author, children, text, created_at } = comment;
  const { t } = useTranslation('owner');
  const { t: sidebarT } = useTranslation('sidebar');
  const {
    props: { activeComment },
  } = useContext(EntitySettings);
  const [showReplyInput, setShowReplyInput] = useState(
    () => showActive && id === activeComment?.id * 1
  );

  const [loadingComment, setLoadingComment] = useState(false);
  const inputRef = useRef(null);
  const inputWrapperRef = useRef(null);

  useLayoutEffect(() => {
    if (showReplyInput && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showReplyInput, inputRef]);

  const onCancelComment = () => setShowReplyInput(false);

  useClickOutside(inputWrapperRef, onCancelComment);

  const repliesContent =
    children && children.length > 0 ? (
      <div className={styles.reply}>
        {children.map((reply) => (
          <CommentItem
            comment={reply}
            key={`reply-item-${reply.id}`}
            showActive={false}
            allowReply={false}
          />
        ))}
      </div>
    ) : null;

  const addCommentHandler = () => {
    if (inputRef && inputRef.current) {
      const comment = inputRef.current.value.trim();
      setLoadingComment(true);
      saveComment(comment, id).then(() => {
        inputRef.current.value = '';
        setShowReplyInput(false);
        setLoadingComment(false);
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addCommentHandler();
    }
  };

  const replyClickHandler = () => {
    setShowReplyInput(true);
  };

  const inputContent = showReplyInput ? (
    <div>
      <input
        type="text"
        className={styles.commentsWrapper__input}
        placeholder={t('rightFileMenu.meta.type')}
        ref={inputRef}
        onKeyPress={handleKeyPress}
      />
      <div className={styles.btnsWrapper}>
        <button
          className={styles.replayBtn}
          onClick={addCommentHandler}
          disabled={loadingComment}
        >
          {sidebarT('footer.send')}
        </button>
        <button
          type="button"
          className={styles.cancelBtn}
          onClick={onCancelComment}
        >
          {t('common.cancel')}
        </button>
      </div>
    </div>
  ) : null;

  const replyAction = showReplyInput ? (
    inputContent
  ) : (
    <button className={styles.replayBtn} onClick={replyClickHandler}>
      {t('rightFileMenu.comment.reply')}
    </button>
  );

  return (
    <section className={styles.commentItem}>
      <div className={styles.commentInfo}>
        <div className={styles.commentInfo__icon}>
          <UserIcon profile user={author} />
        </div>
        <div className={styles.commentInfo__username}>
          {author?.displayed_name || author?.username}
        </div>
        {created_at && (
          <div className={styles.commentInfo__date}>
            {unix(created_at).format('MMM DD, YYYY, h:mma')}
          </div>
        )}
      </div>

      <p className={styles.commentMessage}>{text}</p>
      {allowReply && replyAction}
      {repliesContent}
    </section>
  );
};

CommentItem.defaultProps = {
  showActive: true,
};

export default CommentItem;
