/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react';
import { Api, TelegramClient } from "telegram";
import { StringSession } from "telegram/sessions";

import { CONNECTION_MODE } from 'containers/auth/Terminal/TgAnalyzeSidebar/AddTelegram';
import { margePageMessages } from 'containers/auth/Terminal/TgAnalyzeSidebar/utils';

const apiId = 26487434;
const apiHash = "6aab19a52071a76160901aeb843d6afb";
const stringSession = new StringSession("");

const SESSION_KEY = 'tg-session';

let localClient;

const PAGE_SIZE = 100;

const useTelegramClient = () => {
  const [ isConnected, setIsConnect ] = useState(false);
  const clientRef = useRef(null);
  const session = localStorage.getItem(SESSION_KEY);

  useEffect(() => {
    if (localStorage.getItem(SESSION_KEY)) {
      authFlow()
    }
  }, [])

  const authFlow = async () => {
    try {
      const LOCAL_SESSION = localStorage.getItem(SESSION_KEY);
      const SESSION = await new StringSession(LOCAL_SESSION); // Get session from local storage
      const client = await new TelegramClient(SESSION, apiId, apiHash, { connectionRetries: 1 }) // Immediately create a client using your application data

      await client.connect()
      clientRef.current = client;
      setIsConnect(true)
      return client;
    } catch (error) {
      console.log('auth flow error', error)
    }
  }

  const noAuthFlow = async (setMode) => {
    try {
      const client = await new TelegramClient(stringSession, apiId, apiHash, {
        connectionRetries: 1,
      });
      await client.start({
        phoneNumber: async () => {
          setMode(CONNECTION_MODE.phone)
          return new Promise((resolve, reject) => {
            const node = document.getElementById('tg-connector-btn-phone')
            if (node) {
              node.addEventListener('click', function(e) {
                const input = document.getElementById('tg-connector-input-phone');
                resolve(input.defaultValue)
              }, { once: true })
            } else {
              reject(`cano't find node`)
            }      

          })
        },
        password: '',
        phoneCode: async () => {
          return new Promise((resolve, reject) => {
          console.log('startProccess code');
          setMode(CONNECTION_MODE.code)

          const node = document.getElementById('tg-connector-btn-code')
          if (node) {
            node.addEventListener('click', function(e) {
              const input = document.getElementById('tg-connector-input-code');
              resolve(input.defaultValue)
            }, { once: true })
          } else {
            reject(`cano't find node`)
          }
        })
        },
        onError: (err) => console.log(err),
      });
      clientRef.current = client;
      setIsConnect(true)
      localStorage.setItem(SESSION_KEY, client.session.save()) // Save session to local storage
      return client;
    } catch (error) {
      console.log('auth flow error', error)
    }
  }

  const getClient = async () => {
    try {
      const session = localStorage.getItem(SESSION_KEY);
      if (session) {
        return await authFlow();
      } else {
        return noAuthFlow();
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  const getChatList = async () => {
    try {
      const client = await getClient();
      const result = await client.invoke(new Api.messages.GetDialogs({
        offsetDate: 0,
        offsetId: 0,
        offsetPeer: "username",
        limit: 10000,
        excludePinned: false,
        folderId: 0,
      }));
      return result      
    } catch (error) {
      throw new Error(error)
    }
  }

  const getHistory = async (peer, count) => {
    try {
      let client 
      if (localClient) {
        client = localClient;
      } else {
        client = await getClient();
      }

      const result = await client.invoke(
        new Api.messages.GetHistory({
          peer: peer,
          offsetId: 0,
          offsetDate: 0,
          addOffset: 0,
          limit: PAGE_SIZE,
          maxId: 0,
          minId: 0,
        })
      );

      if (count < PAGE_SIZE || result?.messages?.length < PAGE_SIZE)
        return result;

      const pagesList = await paginationRequest({ count, firstRes: result, client, peer })
      
      return pagesList;
    } catch (error) {
      throw new Error(error);
    }
  }

  const paginationRequest = async ({ count, firstRes, client, peer }) => {
    try {
      const pages = [firstRes];
      let requestCount = 0;
      let lastId = firstRes.messages[firstRes.messages.length-1].id;
      const totalCount = firstRes.count;

      if (count > totalCount) {
        requestCount = Math.round((totalCount - PAGE_SIZE) / PAGE_SIZE);
      } else {
        requestCount = Math.round((count - PAGE_SIZE) / PAGE_SIZE);
      }

      const requestArray =Array.from(Array(requestCount).keys())   
      for (let i = 0; i < requestArray.length; i++) {
        const pageReq = await client.invoke(
          new Api.messages.GetHistory({
            peer: peer,
            offsetId: lastId,
            offsetDate: 0,
            addOffset: 0,
            limit: PAGE_SIZE,
            maxId: 0,
            minId: 0,
          })
        );
        lastId = pageReq.messages[pageReq.messages.length-1].id;
        pages.push(pageReq);
      }
      const margetPages = margePageMessages(pages, count);

      return margetPages;
    } catch (error) {
      console.log(error)
    }
  }

  const getFullHistory = async (peer) => {
    try {
      let client 
      if (localClient) {
        client = localClient;
      } else {
        client = await getClient();
      }

      const result = await client.invoke(
        new Api.messages.GetHistory({
          peer: peer,
          offsetId: 0,
          offsetDate: 0,
          addOffset: 0,
          limit: PAGE_SIZE,
          maxId: 0,
          minId: 0,
        })
      );

      if (result.count >= result.messages.length ) {
        const pagesList = await paginationRequest({ count: result.count, firstRes: result, client, peer })
        return pagesList
      } else {
        return result
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  const startConnection = async (setMode) => {
    try {
      if (session) {
        return
      } else {
        return noAuthFlow(setMode);
      }      
    } catch (error) {
      throw new Error(error);
    }

  }

  return {
    getHistory,
    getChatList,
    startConnection,
    getFullHistory,
    isConnected,
  }
}

export { useTelegramClient, SESSION_KEY }