// Refactoring №2
import React, {
  useLayoutEffect,
  Suspense,
  useState,
  lazy,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CN from 'classnames';

import { setErrors } from 'store/errors/actions/index';
import { getPreviewFileBlob } from 'store/home/effects/file/get-preview-file-blob';

import GhostLoader from 'components/GhostLoader';

import DefaultContent from './default-content';

const Document = lazy(() =>
  import('react-pdf/dist/esm/entry.webpack').then((module) => ({
    default: module.Document,
  }))
);

const Page = lazy(() =>
  import('react-pdf/dist/esm/entry.webpack').then((module) => ({
    default: module.Page,
  }))
);
const PDF_PAGE_OFFSET = 140;

const PDFContent = ({
  entity,
  className,
  rootClassName,
  setEntityType,
  scrollElementRef,
  fileContentRef,
  handleOnClose,
  defaultTextGenerator,
}) => {
  const [loading, setLoading] = useState(true);
  const [ipfsDoc, setIpfsDoc] = useState();
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfPageHeight, setPdfPageHeight] = useState(null);
  const [showDefaultView, setShowDefaultView] = useState(false);
  const dispatch = useDispatch();
  const { t: accountT } = useTranslation('account');
  const { t: popupT } = useTranslation('popup');

  useEffect(() => {
    if (ipfsDoc && !loading) {
      setTimeout(() => {
        const pdfPage = document.getElementsByClassName(
          'react-pdf__Page__canvas'
        );
        const additional = numPages === 2 ? 300 : 0;
        setPdfPageHeight(pdfPage[0]?.offsetHeight - additional);
      }, 500);
    }
  }, [ipfsDoc, loading]);

  useEffect(() => {
    const mainRequest = async () => {
      try {
        const blob = await getPreviewFileBlob({ file: entity });
        if (blob) {
          setIpfsDoc(blob);
        }
      } catch (error) {
        const errorData = JSON.parse(await error.response.data.text());
        if (errorData.message === 'Cannot find PDF preview') {
          setShowDefaultView(true);
          setLoading(false);
        }
        if (error.response?.status === 403) {
          handleOnClose();
          dispatch(
            setErrors({ data: 403, message: popupT('notification.accessDeny') })
          );
        }
      }
      return;
    };
    mainRequest();
  }, []);

  useLayoutEffect(() => {
    if (ipfsDoc && pdfPageHeight) {
      const elem = fileContentRef?.current;
      const handleScroll = (event) => {
        const scrollTop = event.srcElement.scrollTop;
        const nextPage = Math.round(
          (scrollTop - PDF_PAGE_OFFSET + pdfPageHeight) / pdfPageHeight
        );
        if (nextPage !== pageNumber && nextPage <= numPages) {
          setPageNumber(nextPage);
        }
      };
      if (elem) {
        elem.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (elem) {
          elem.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [scrollElementRef, pageNumber, numPages, ipfsDoc, pdfPageHeight]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setLoading(false);
    setNumPages(numPages);
  };

  const onDocumentLoadError = () => {
    setEntityType('default');
    setLoading(false);
  };

  return (
    <Suspense fallback="loading">
      <div className={CN('file-view__document-container', rootClassName)}>
        {loading && (
          <div className="file-content-pdf-view-loader">
            <GhostLoader
              texts={[
                accountT('convertModal.loading'),
                accountT('convertModal.pleaseWait'),
              ]}
            />
          </div>
        )}
        {!showDefaultView && (
          <div
            className={CN(
              'file-content-image-container file-content-pdf-view',
              loading && 'file-content-pdf-view-hide',
              className
            )}
            ref={fileContentRef}
          >
            <Document
              file={ipfsDoc}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentLoadError}
              className="pdf-view-document"
              renderMode="canvas"
            >
              {Array.from(Array(numPages).keys()).map((item) => (
                <Page
                  pageNumber={item + 1}
                  className="file-content-pdf-view-page"
                  key={`pdf-page-number-${item}`}
                />
              ))}
            </Document>
            <p className="file-content-pdf-view-page-number">
              Page {pageNumber} of {numPages}
            </p>
          </div>
        )}
        {showDefaultView && (
          <DefaultContent
            file={entity}
            fileContentRef={fileContentRef}
            text={defaultTextGenerator()}
          />
        )}
      </div>
    </Suspense>
  );
};

export default React.memo(PDFContent);
