import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { CopyButton } from 'components/Button';
import { copyToClipboard } from 'utils/string';

const HashWidget = ({ selectedEntity }) => {
  const { t } = useTranslation('owner');
  const hashLink = `https://filfox.info/en/block/${selectedEntity?.ipfs_hash}`;

  const copyHash = () => {
    copyToClipboard(hashLink);
  };

  return (
    <div className={styles.meta}>
      <div className={styles.valueHeader}>
        <div className={styles.valueLogo}>
          <h1 className={styles.progressHeaderTitle}>
            {t('rightFileMenu.meta.hash')}
          </h1>
        </div>
      </div>
      <div className={styles.hashContainer}>
        <p>{selectedEntity?.ipfs_hash}</p>
        <CopyButton onClick={copyHash} />
      </div>
    </div>
  );
};

export default HashWidget;
