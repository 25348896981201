//Refactoring №3
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as FolderIcon } from './assets/folder.svg';
import Button from 'components/Button';

import { createDefaultWorkspace } from 'store/workspace/effects';
import { loginUsernameEffect, loginMetamask } from 'store/auth/effects';
import { signMessage } from '../login-crypto';

import style from './style.module.scss';

const NoWorkspace = ({ history }) => {
  const { t } = useTranslation('workspace');
  const {
    location: { state },
  } = history;

  const createDefaultWorkspaceHandler = useCallback(async () => {
    const input = state.username;
    await createDefaultWorkspace(input);
    if (state.username) {
      await loginUsernameEffect(state.username, state.password, history);
      history.push('/main');
    } else {
      const { signature, address } = await signMessage();
      await loginMetamask(address, signature);
      history.push('/main');
    }
  }, [state, history]);

  return (
    <div className={style.container}>
      <div className={style.content}>
        <FolderIcon />
        <h1 className={style.title}>{t('common.oops')}</h1>
        <p className={style.text}>{t('common.notMemberOfWorkspace')}</p>
        <Button disabled={!state} onClick={createDefaultWorkspaceHandler}>
          {t('common.createWorkspace')}
        </Button>
      </div>
    </div>
  );
};

NoWorkspace.propTypes = {};

NoWorkspace.defaultProps = {};

export default NoWorkspace;
