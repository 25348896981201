//Refactoring №4
import React from 'react';

import securitiesType from './securities-types';

import { ReactComponent as ShieldIcon } from 'static/assets/svg/shield.svg';
import { ReactComponent as LockIcon } from 'static/assets/svg/lock.svg';
import { ReactComponent as KeyIcon } from 'static/assets/svg/key.svg';
import { ReactComponent as StrongShieldIcon } from 'static/assets/svg/strong-shield.svg';

const { pin, tfa, key, music, multiSig } = securitiesType;

const securityOptions = {
  tfa: {
    nameValue: tfa.activatedStatusName,
    name: '2FA',
    icon: 'protection-icon',
    text: 'Secure your account with two factor authentication',
    type: tfa.id,
    getSvgIcon: (className: string) => <ShieldIcon className={className} />,
  },
  password: {
    nameValue: pin.activatedStatusName,
    name: 'PIN',
    icon: 'locked-icon',
    text: 'Secure your account by entering your preferred pin',
    type: pin.id,
    getSvgIcon: (className: string) => <LockIcon className={className} />,
  },
  key: {
    nameValue: key.activatedStatusName,
    name: 'Key',
    icon: 'key-icon',
    text: 'Create your private key and save it securely',
    type: key.id,
    subscription: 'Prime',
    getSvgIcon: (className: string) => <KeyIcon className={className} />,
    isComing: true,
  },
  music: {
    name: 'Music',
    icon: 'music-icon',
    text: 'Record your personal melody for login access',
    type: music.id,
  },
  multiSig: {
    nameValue: multiSig.activatedStatusName,
    name: 'MultiSig',
    icon: 'multi-sig-icon',
    subscription: 'NFT +',
    text: 'Not activated for your account.',
    type: multiSig.id,
    isComing: true,
    getSvgIcon: (className: string) => (
      <StrongShieldIcon className={className} />
    ),
  },
};

export default securityOptions;
