import React, { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useSetChain } from '@web3-onboard/react';
import CN from 'classnames';

import { ReactComponent as DescriptionIcon } from 'static/assets/svg/preview.description.svg';
import { ReactComponent as HistoryIcon } from 'static/assets/svg/preview.history.svg';
import { ReactComponent as RecentIcon } from 'static/assets/svg/recent.svg';

import { ContactDetailsByURi } from 'containers/share-by-link-nft';
import { InitialValues } from 'containers/main/EntitySettingsContainer/components/Web3Tab/components/Tokenization/components/Monetization';

import { getNetworksEffects } from 'store/web3';

import { Network } from 'docs/types/contract';

import { Monetize, PreviewParams } from '../index';

import s from '../styles.module.scss';

const TEXT = {
  desc: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.\n',
  desc1:
    'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. ',
  a: 'No events have occurred yet',
  b: 'Check back later',
};

const labels = {
  smartContract: 'SmartContract',
  price: 'Current Price',
  tokenId: 'Token ID',
  network: 'Network',
  creator: 'Token name',
  updatedAt: 'Last Update',
};

interface BackCardProps {
  info?: ContactDetailsByURi;
  isPreview: boolean;
  formData?: InitialValues;
  monetize: Monetize | null;
  isDarkMode?: boolean;
  networkName?: string;
}

const BackCardNoMemo: FC<BackCardProps> = ({
  info,
  isPreview,
  formData,
  monetize,
  isDarkMode,
  networkName,
}) => {
  const params = useParams<PreviewParams>();
  const [active, setActive] = useState<boolean>(true);
  const [network, setNetwork] = useState<number>(0);
  const [{ connectedChain }] = useSetChain();
  const creator = useSelector((state) => {
    // @ts-ignore
    const value = state.home?.userInfo?.user?.displayed_name;
    return value && value?.length > 16 ? value?.slice(0, 16) : value;
  });

  useEffect(() => {
    const getNetwork = async () => {
      const networks = await getNetworksEffects();
      const correctNetwork = networks.find(
        (el: Network) => el.id === parseInt(connectedChain?.id ?? '0')
      )?.name;
      const smallerNetwork =
        correctNetwork?.length > 20
          ? correctNetwork.slice(0, 20)
          : correctNetwork;
      setNetwork(smallerNetwork);
    };

    void getNetwork();
    return () => {
      setNetwork(0);
    };
  }, []);

  const shortAddress = useMemo<string>(() => {
    if (isPreview) {
      return generateStarts(22);
    }
    const dots = '...';
    const start = params.contract_address.slice(0, 9);
    const end = params.contract_address.slice(-3);
    return `${start}${dots}${end}`;
  }, [params.contract_address, isPreview]);

  const handleActive = () => {
    setActive(!active);
  };

  return (
    <div className={CN(s.back_container, isDarkMode && s.back_container_dark)}>
      <ContentTitle onClick={handleActive} Icon={DescriptionIcon} isDarkMode={isDarkMode} >
        Description
      </ContentTitle>
      {active ? (
        <Content>
          <p className={CN(s.back_description, isDarkMode && s.back_dark_input)}>
            {isPreview ? formData?.description : info?.description || TEXT.a}
          </p>
          <div className={s.blocks}>
            <Block
              isActive
              title={labels.smartContract}
              value={shortAddress}
              isDarkMode={isDarkMode}
            />
            <Block
              title={labels.price}
              value={`${isPreview ? formData?.price : monetize?.price}$`}
              isDarkMode={isDarkMode}
            />
            <Block
              title={labels.tokenId}
              value={isPreview ? generateStarts(3) : info?.tokenId.toString()}
              isDarkMode={isDarkMode}
            />
            <Block
              title={labels.network}
              value={new String(networkName || `${network}`).toUpperCase()}
              isDarkMode={isDarkMode}
            />
            <Block
              title={labels.creator}
              value={isPreview ? creator : info?.name}
              isDarkMode={isDarkMode}
            />
          </div>
        </Content>
      ) : null}
      <Delimiter isDarkMode={isDarkMode} />
      <ContentTitle isDarkMode={isDarkMode} onClick={handleActive} Icon={HistoryIcon}>
        History price
      </ContentTitle>
      {!active ? (
        <Content>
          <div className={CN(s.chart_container, isDarkMode && s.chart_container_back)}>
            <RecentIcon />
            <div>
              <h3 className={s.empty_title}>{TEXT.a}</h3>
              <p className={s.empty_desc}>{TEXT.b}</p>
            </div>
          </div>
        </Content>
      ) : null}
    </div>
  );
};

export const BackCard = React.memo(BackCardNoMemo);

interface ContentTitleProps {
  Icon?: FC;
  children: string;
  onClick: () => void;
  isDarkMode?: boolean; 
}

const ContentTitle: FC<ContentTitleProps> = ({ Icon, children, onClick, isDarkMode }) => {
  return (
    <div onClick={onClick} className={s.header}>
      {Icon && <Icon />}
      <p className={CN(isDarkMode && s.back_dark_color, s.header_title)}>{children}</p>
    </div>
  );
};

interface BlockProps {
  title?: string;
  value?: string;
  isActive?: boolean;
  isDarkMode?: boolean;
}

const Block: FC<BlockProps> = ({ title, value, isDarkMode, isActive = false }) => {
  return (
    <div className={s.header_block}>
      <p className={CN(s.header_subtitle, isDarkMode && s.back_dark_color)}>{title}</p>
      <div className={CN(s.header_value, isActive && s.header_value_active, isDarkMode && s.back_dark_input)}>
        {value}
      </div>
    </div>
  );
};

interface ContentProps {
  children: any;
}

const Content: FC<ContentProps> = ({ children }) => {
  return <div className={s.back_content}>{children}</div>;
};

interface DelimiterProps {
  isDarkMode?: boolean;
}

const Delimiter = ({ isDarkMode }: DelimiterProps) => {
  return <div className={CN(s.delimiter, isDarkMode && s.delimiter_dark)} />;
};

const generateStarts = (num: number): string => {
  let result = '';
  const star = '*';
  for (let i = 0; i < num; i++) {
    result += star;
  }
  return result;
};
