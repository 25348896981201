import { entitiesTypes } from 'config/entities-types';
import { audioMediaTypes } from 'config/audio-file-extensions';
import imageFileExtensions, {
  imageMediaTypes,
  imagesWithoutPreview,
} from 'config/image-file-extensions';
import {
  videoMediaExtentionPreview,
  videoMediaTypes,
} from 'config/video-file-extensions';
import { docMediaTypes, pdfMediaTypes, docMediaTypesPreview } from 'config/docs-file-extensions';

export const getShortenedName = (name, maxLength = 30) => {
  if (!name) return '';
  if (name.length < maxLength) return name;

  const shortName = `${name.slice(0, 10)}...${name.slice(name.length - 10)}`;
  return shortName;
};

export const isFile = (entyti) => entyti.type === entitiesTypes.file.id;

export const isSvg = (mimeType = '') => mimeType === 'image/svg+xml';

export const isFileHavePreview = (entity) => {
  const { mime = '', extension = '' } = entity;
  if (imageMediaTypes.includes(mime)) {
    return 'img';
  } else if (audioMediaTypes.includes(mime)) {
    return 'audio';
  } else if (videoMediaExtentionPreview.includes(extension.toLowerCase())) {
    return 'video';
  } else if (mime === 'application/pdf') {
    return 'pdf';
  } else if (docMediaTypes.includes(mime)) {
    return 'document';
  }

  return false;
};

export const isVideoFile = (mimeType = '') => {
  return videoMediaTypes.includes(mimeType);
};

export const isPdfFile = (mimeType = '') => pdfMediaTypes.includes(mimeType);

export const isFileTokenized = (entity) =>
  entity?.entry_groups?.some((group) => group.is_tokenized);

export const isFileHavePreviewV2 = (entity) => {
  if (
    entity?.slug && window.location.pathname !== '/deleted' &&
    ((imageFileExtensions.includes(`.${entity.extension}`) &&
      !imagesWithoutPreview.includes(`.${entity.extension}`)) ||
      docMediaTypesPreview.includes(entity.mime) ||
      videoMediaExtentionPreview.includes(entity?.extension?.toLowerCase()))
  ) {
    return true
  }

  return false;
};
