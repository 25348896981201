//new
import { getAccessToken } from 'utils/auth/get-token';
import { API_BUG_REPORT } from 'constants/api-urls';
import axios from 'axios';

export const sendBugReportEffect = async (form: any) => {
  const token = await getAccessToken();
  return axios.post(`${API_BUG_REPORT}`, form, {
    headers: {
      'x-token': token,
      'Content-Type': 'multipart/form-data',
    },
  });
};
