//Refactoring №2
import React, { useState, useRef, useContext, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { cancelingUpload } from 'gdgateway-client/lib/es5';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import MainContext from 'store/main/context/main-context';
import { uploadMultiFileEffectRedux } from 'store/home/effects';
import { showFile } from 'store/home/actions/selected-files-folders.actions';
import { cancelFolderUploading } from 'store/home/actions/file/cancel-folder-uploading.action';

import useNotification from 'utils/hooks/use-notification';
import { renderFileIcon } from 'utils/file/renderFileIcon.jsx';
import { renderFileName } from 'utils/file/renderFileName.jsx';

import style from './style.module.scss';

export const transformSize = (size) => {
  let bytes = Number(size);
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = Math.floor(Math.log(bytes) / Math.log(1024));
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};

const FileView = (props) => {
  const {
    file,
    history,
    showFile,
    existingEncryption = false,
    encryptExistingFileError = false,
    uploadFileEffect,
  } = props;
  const [canceled, setCancel] = useState(false);
  const [hovered, setHover] = useState(false);
  const { addNotification } = useNotification();
  const {
    state: { workspace },
  } = useContext(MainContext);
  const storageType = workspace.storage;
  const { t } = useTranslation('popup');
  const dispatch = useDispatch();
  const tokensCount = useSelector((state) => state.app.tokensCount);

  useEffect(() => {
    setCancel(false);
  }, [file.source]);

  const ref = useRef();
  const uploadingPercent = Number((file.progress / file.size) * 100).toFixed();

  const renderTimeLeft = () => {
    const secondsLeft = file.timeLeft;
    if (secondsLeft === null || Number(uploadingPercent) === 0) {
      return 'in queue';
    }
    const isMinute = secondsLeft > 60;
    const minutesLeft = Math.ceil(secondsLeft / 60);
    const isHour = minutesLeft > 60;
    const hoursLeft = Math.ceil(minutesLeft / 60);

    return `${isHour ? hoursLeft : isMinute ? minutesLeft : secondsLeft} ${
      isHour
        ? `hour${hoursLeft > 1 ? 's' : ''}`
        : isMinute
        ? `minute${minutesLeft > 1 ? 's' : ''}`
        : `second${secondsLeft > 1 ? 's' : ''}`
    } left`;
  };

  const renderStatus = () => {
    if (existingEncryption) {
      return 'Encryption';
    }
    if (!file.needEncryption) {
      return 'Uploading';
    }
    if (file.needEncryption) {
      return uploadingPercent < 50 ? 'Encryption' : 'Uploading';
    }
  };

  return (
    <>
      <div
        className={classNames('download__item', style.uploadItem)}
        onMouseEnter={(el) => {
          if (ref && ref.current) {
            const left =
              ref.current.clientWidth > 450
                ? 206 - (ref.current.clientWidth - 450) / 2
                : ref.current.style.left;
            ref.current.style.left = left + 'px';
            ref.current.style.top =
              el.currentTarget.offsetTop - props.scrollTop - 15 + 'px';
          }
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <div className={classNames('download__item__icon', style.imageWrapper)}>
          {renderFileIcon(file)}
        </div>
        <span className="download__item__name">
          {renderFileName(file.name || file.fileName, ref)}
          {canceled || file.error || encryptExistingFileError ? (
            <span className="download__item__canceled">
              {t(canceled ? 'uploadDetails.canceled' : 'uploadDetails.failed')}
            </span>
          ) : file.progress < file.size ? (
            <div>
              <span className="download__item__status">{renderStatus()}</span>
            </div>
          ) : (
            <span className="download__item__completed">
              {t('uploadDetails.completed')}
            </span>
          )}
        </span>
        {hovered ? (
          file.progress >= file.size ? (
            <span
              className="download__item__button download__item__show"
              onClick={() => {
                if (file?.dataType === 'folder') {
                  history.push(`/folder/${file.folderId}`);
                } else {
                  history.push(
                    file.folderId ? `/folder/${file.folderId}` : 'main'
                  );
                  setTimeout(() => showFile(file), 1000);
                }
              }}
            >
              {t('uploadDetails.show')}
            </span>
          ) : canceled || file.error ? (
            <span
              className="download__item__button download__item__repeat"
              onClick={() => {
                const folderId = file.folderId;
                setCancel(false);
                uploadFileEffect({
                  folderData: folderId,
                  files: [file],
                  folderId: folderId,
                  storageType: storageType,
                  addNotification: addNotification,
                  encryption: file.needEncryption,
                  userTokens: tokensCount,
                });
              }}
            >
              {t('uploadDetails.repeat')}
            </span>
          ) : (
            <div className="download__item__status">
              {
                <span
                  className="download__item__button download__item__cancel"
                  onClick={() => {
                    cancelingUpload(file.uploadId);
                    if (file?.dataType === 'folder') {
                      dispatch(cancelFolderUploading(true));
                    }
                    setCancel(true);
                    if (file.source) file.source.cancel('User cancel upload');
                  }}
                >
                  {t('uploadDetails.cancel')}
                </span>
              }
            </div>
          )
        ) : (
          !canceled &&
          !file.error &&
          file.progress < file.size && (
            <span className=" download__item__status download__item__status__right">
              <span className="download__item__status download__item__status_percent">
                {uploadingPercent} %{' '}
              </span>
              <span className="download__item__status download__item__status__time">
                {renderTimeLeft()}
              </span>
            </span>
          )
        )}
      </div>
      <div
        className="download__item__upload"
        style={{
          backgroundColor:
            renderStatus() === 'Encryption' ? '#202124' : '#0F73EF',
          width: `${
            file.progress / file.size > 1 || file.size === 0
              ? `450`
              : Number((file.progress / file.size) * 450).toFixed()
          }px`,
        }}
      ></div>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  uploadFileEffect: uploadMultiFileEffectRedux,
  showFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileView);
