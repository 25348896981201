export const EDIT_USER_INFO = 'EDIT_USER_INFO'
export const EDIT_USER_INFO_SUCCESS = 'EDIT_USER_INFO_SUCCESS'
export const EDIT_USER_INFO_FAILURE = 'EDIT_USER_INFO_FAILURE'
export const EDIT_USER_INFO_CHANGE_CONFIGURATION =
  'EDIT_USER_INFO_CHANGE_CONFIGURATION'

export function editUserInfo() {
  return {
    type: EDIT_USER_INFO,
    payload: {},
  }
}

export function editUserInfoSuccess() {
  return {
    type: EDIT_USER_INFO_SUCCESS,
  }
}

export function editUserInfoFailure() {
  return {
    type: EDIT_USER_INFO_FAILURE,
    payload: {},
  }
}

export function editUserInfoChangeConfiguration(configuration) {
  return {
    type: EDIT_USER_INFO_CHANGE_CONFIGURATION,
    payload: configuration,
  }
}
