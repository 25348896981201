// libs
import { merge } from 'ramda'

// utils
import getData from 'utils/request/get-data'

// actions
import * as actions from '../actions'

const initialState = {
  pending: false,
  data: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_STRIPE_DATA:
      return merge(state, {
        pending: true,
      })
    case actions.GET_STRIPE_DATA_SUCCESS:
      return merge(state, {
        pending: false,
        data: getData(action).data,
      })
    case actions.GET_STRIPE_DATA_FAILURE:
      return merge(state, {
        pending: false,
        data: {},
      })
    case actions.GET_STRIPE_DATA_CLEAR:
      return merge(state, {
        pending: false,
        data: {},
      })
    default:
      return state
  }
}
