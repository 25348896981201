import actionsOptions from 'config/actions-options'

export const fileFolderActionMiddleware =
  (effect, setShowView) => (selectedEntities, actionType, addData, history) => {
    const { viewFile } = actionsOptions
    if (actionType.type === viewFile.type) {
      setShowView(true)
    }

    effect(selectedEntities, actionType, addData, history)
  }
