//Refactoring №2
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { useConnectWallet } from '@web3-onboard/react';

import cn from 'classnames';
import { isEmpty } from 'ramda';
import { getUserRSAKeys, publicKeyToPem } from 'gdgateway-client/lib/es5';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { useCreation } from 'ahooks';

import { MainContextWrapper } from 'store/context';
import {
  authTokenVerify,
  authTokenInvite,
  loginUsernameEffect,
  shortSignUpMetamaskEffect,
  loginMetamask,
  savePubKey,
} from 'store/auth/effects';
import {
  getUserInvitesEffect,
  acceptInviteEffect,
} from 'store/auth/effects/join';
import { joinMemberToWorkspace } from 'store/workspace/effects';
import { setErrors as setGlobalErrors } from 'store/errors/actions/index';

import useNotification from 'utils/hooks/use-notification';
import { validateToken } from 'utils/auth/check-token';
import { isMobile } from 'utils/mobile';
import { cutByMaxLength } from 'utils/string';
import { enterKeyPress } from 'utils/actions/enter-key-press';
import { validationRegExp } from 'utils/validation/regexp';
import { checkMetamaskInstalled } from 'utils/crypto/wallet/metamask';
import { isMetamaskAddress } from 'utils/crypto/validation';
import { getInitials } from 'utils';
import { metamaskTypes } from 'config/metamask-types';
import RESPONSE_STATUS_CODE from 'config/response-status-code';

import { signMessage } from '../login-crypto';
import AccountInput from '../../../components/AccountInput/account-input';
import Preloader from 'components/shared/Preloader';
import { CryptoButton } from '../components/CryptoButton';
import InviteWorkspaceList from '../invite-workspace-list';
// import Tooltip from 'components/shared/Tooltip/tooltip';
import CryptoCheckPluginModal from '../crypto-check-plugin-modal';
import NotificationBubble from 'containers/notifications/notification-bubble';
import { SharedLayout } from 'containers/auth/components/SharedLayout';
import Button, { ButtonTheme } from 'components/Button';
import { JoinToDriveMobileModal } from './JoinToDriveMobileModal';

import {
  SignInModal,
  DEFAULT_ERROR_MESSAGES,
  validations,
  minCharsLength,
} from './sign-in-modal';

import style from './style.module.scss';

const views = {
  joinWorkspace: 'joinWorkspace',
  alreadyJoined: 'alreadyJoined',
  cryptoSignIn: 'cryptoSignIn',
  cryptoSingUp: 'cryptoSignUp',
  signIn: 'signIn',
  signUp: 'signUp',
  canceledInvitation: 'canceledInvitation',
};

const AcceptInvite = ({ match, history }) => {
  const {
    params: { token },
  } = match;
  const [fieldData, setFieldData] = useState({
    email: '',
    password: '',
    name: '',
  });
  const { addNotification } = useNotification();
  const mainDispatch = useDispatch();
  const { connector } = useWeb3React();
  const [errors, setErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState(DEFAULT_ERROR_MESSAGES);
  const [workspaceName, setWorkspaceName] = useState('');
  const [invites, setInvites] = useState([]);
  const [currentInvite, setCurrentInvite] = useState({});
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState('');
  const [workspaceExist, setWorkspaceExist] = useState(true);
  const [valid, setValid] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showPluginModal, setShowPluginModal] = useState(false);
  const [userExist, setUserExist] = useState(true);
  const [pluginType, setPluginType] = useState('metamask');
  const [isTokenValid, setIsTokenValid] = useState(validateToken());
  const [{ wallet }, connect] = useConnectWallet();

  const searchParams = new URLSearchParams(history.location.search);
  const isTelegram = searchParams.get('is_telegram') === 'true';
  const [isOpenMobileModal, setIsOpenMobileModal] = useState(true);
  const closeMobileModal = () => {
    setIsOpenMobileModal(false);
  };

  const initials = useCreation(
    () => getInitials(workspaceName, false),
    [workspaceName]
  );
  const workspaceId = useMemo(
    () => history.location.search.slice(4),
    [history]
  );
  const workspaceHasContract = currentInvite?.workspace?.access_smart_contract;
  const public_address = currentInvite?.public_address;

  useEffect(() => {
    if (isTokenValid) {
      getUserInvitesEffect(token, workspaceId)
        .then(({ message, name, data }) => {
          if (message === 'You are a member of this workspace') {
            setView(views.alreadyJoined);
            setWorkspaceName(renderWSName(name));
          } else {
            setView(views.joinWorkspace);
            const inviteByToken = data.find((invite) => invite.token === token);
            const restOfWorkspace = data.filter(
              (invite) => invite.token !== token
            );
            setInvites(restOfWorkspace);
            setWorkspaceName(renderWSName(inviteByToken.workspace?.name));
            setCurrentInvite(inviteByToken);
          }
        })
        .catch((e) => {
          setLoading(false);
          if (
            e.message === 'Invite not found' ||
            e.message === 'Access Denied.'
          ) {
            setView(views.canceledInvitation);
          }
        });
    } else {
      authTokenVerify(token)
        .then((data) => {
          const {
            token_exist,
            user_exist,
            workspace_exist,
            is_public_address,
            workspace_name = '',
          } = data;
          !workspace_exist && setWorkspaceExist(false);
          setWorkspaceName(renderWSName(workspace_name));
          setUserExist(user_exist);
          if (is_public_address) {
            if (!token_exist) {
              history.push('/');
            } else if (user_exist) {
              setView(views.cryptoSignIn);
            } else if (!user_exist) {
              setView(views.cryptoSingUp);
            }
          } else {
            if (!token_exist) {
              history.push('/');
            } else if (user_exist) {
              setView(views.signIn);
            } else if (!user_exist) {
              setView(views.signUp);
            }
          }
        })
        .catch((error) => {
          const response = error.response;
          if (response.data.errors === 'Invite not found') {
            setView(views.signIn);
            return;
          }
          if (
            response.status === RESPONSE_STATUS_CODE.NOT_FOUND &&
            response.data.message
          ) {
            addNotification(response.data.message);
            setTimeout(() => {
              history.push('/');
            }, 500);
          }
        });
    }
  }, [history, isTokenValid, token]);

  const metamaskAction = () => {
    if (view === views.cryptoSignIn) {
      metamaskLoginAndJoin();
    } else if (view === views.cryptoSingUp) {
      metamaskSignUp();
    }
  };

  const renderWSName = useCallback((name) => {
    if (isMetamaskAddress(name)) {
      return cutByMaxLength(name, 14, 4);
    }
    return name;
  }, []);

  const activateMetamask = async () => {
    if (isMobile && !checkMetamaskInstalled()) {
      const dappUrl = `${window?.location?.host}/sign-in`;
      const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
      return window.open(metamaskAppDeepLink, '_self');
    }
    if (checkMetamaskInstalled()) {
      if (!wallet) {
        await connect();
      }
      metamaskAction();
    } else {
      setShowPluginModal(true);
      setPluginType('metamask');
    }
  };

  const startPayment = async () => {
    const provider = await connector.getProvider();
    const web3 = new Web3(provider);

    setLoading(true);
    web3.eth
      .sendTransaction({
        to: '0x70e8807F7Aff321EEF2c526E220487d8226d8426',
        from: public_address,
        value: '40000000000000000',
        chainId: 0x38,
      })
      .then(() => {
        joinMemberToWorkspace(token).then((data) => {
          if (data) history.push('/');
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const validatePassword = (value) => {
    const validRules = {};
    validations.forEach((validation) => {
      if (validation === 'hasMinLength') {
        validRules[validation] = value.length >= minCharsLength;
      } else {
        validRules[validation] = validationRegExp[validation].test(value);
      }
    });
    return validRules;
  };

  const onInputChange = (e) => {
    let isValid = true;
    const name = e.target.name;
    const value = e.target.value;
    if (name) {
      if (name === 'email') {
        isValid = validationRegExp.email.test(value);
      } else if (name === 'password') {
        const newValid = validatePassword(value);
        setValid(newValid);
        isValid = !!isEmpty(
          Object.values(newValid).filter((item) => item === false)
        );
      } else {
        isValid = !!value;
      }

      setFieldData({
        ...fieldData,
        [name]: value,
      });
    }

    setErrors({
      ...errors,
      [name]: !isValid,
    });
  };

  const onJoinClickHandler = (token) => () => {
    joinMemberToWorkspace(token)
      .then((data) => {
        if (data) history.push('/');
      })
      .catch(async (e) => {
        // Removing an unnecessary notification window
        mainDispatch(setGlobalErrors(null));
        // Status 412 is returned for unpaid workspace
        if (e.response.status == 412) {
          startPayment();
        }
      });
  };

  const onJoinOtherClickHandler = (token) => () => {
    if (token) {
      // history.push(`/join-now/${token}`)
      const newWindow = window.open(`/join-now/${token}`, '_blank');
      newWindow.focus();
    }
  };

  const onNotRegisteredJoinWorkspaceClickHandler = () => {
    !submitted && setSubmitted(true);
    if (
      fieldData.name &&
      !errors.name &&
      fieldData.password &&
      !errors.password &&
      Object.values(valid).every((value) => value) &&
      token
    ) {
      authTokenInvite(fieldData.name, fieldData.password, token).then(
        (data) => {
          if (data) history.push('/');
        }
      );
    } else {
      if (!fieldData.password) {
        setErrors({ ...errors, password: true });
      }
    }
  };

  const catchCredentialError = ({ errors, message }) => {
    !submitted && setSubmitted(true);

    if (errors) {
      const fieldErrors = {};
      const fieldErrorMsg = {};
      Object.keys(errors?.children).forEach((fieldName) => {
        fieldErrors[fieldName] = !!errors?.children[fieldName].errors;
        fieldErrorMsg[fieldName] =
          errors?.children[fieldName].errors?.join(', ') || '';
      });
      setErrors(fieldErrors);
      setErrorMessages(fieldErrorMsg);
    } else {
      setErrors({
        email: true,
        password: true,
      });
      setErrorMessages({
        email: message ? message : '',
        password: message ? message : '',
      });
    }
  };

  const onRegisteredJoinWorkspaceClickHandler = () => {
    !submitted && setSubmitted(true);
    if (
      fieldData.email &&
      !errors.email &&
      fieldData.password &&
      !errors.password &&
      token
    ) {
      if (!workspaceExist) {
        acceptInviteEffect(token).then(() => {
          setWorkspaceExist(true);
          loginUsernameEffect(fieldData.email, fieldData.password)
            .then(() => {
              history.push('/');
            })
            .catch(catchCredentialError);
        });

        return;
      }

      loginUsernameEffect(fieldData.email, fieldData.password)
        .then(() => {
          if (validateToken()) {
            setIsTokenValid(true);
          }
        })
        .catch(catchCredentialError);
    }
  };

  const handleKeyDown = (e) => {
    if (enterKeyPress(e)) {
      if (views.signIn === view) {
        onRegisteredJoinWorkspaceClickHandler();
      } else {
        onNotRegisteredJoinWorkspaceClickHandler();
      }
    }
  };

  const addNotificationMessage = (message, code) => {
    code !== metamaskTypes.cancelCode &&
      addNotification(message, 'alert', false, false, false);
  };

  const metamaskLoginAndJoin = async () => {
    try {
      const { providers } = window.ethereum;
      let selectedProvider;

      for (const provider of providers || []) {
        if (provider.isMetaMask) {
          selectedProvider = provider;
        }
      }
      if (selectedProvider) {
        window.ethereum.setSelectedProvider &&
          window.ethereum.setSelectedProvider(selectedProvider);
      }
      const { signature, address } = await signMessage(false, undefined);
      await loginMetamask(address, signature, null, mainDispatch);
      await setIsTokenValid(true);
    } catch (error) {
      addNotificationMessage(error?.message || error?.errors, error?.code);
    }
  };

  async function metamaskSignUp() {
    try {
      const { providers } = window.ethereum;
      let selectedProvider;

      for (const provider of providers || []) {
        if (provider.isMetaMask) {
          selectedProvider = provider;
        }
      }
      if (selectedProvider) {
        window.ethereum.setSelectedProvider &&
          window.ethereum.setSelectedProvider(selectedProvider);
      }
      const provider = new ethers.providers.Web3Provider(window.metamask);
      const signer = provider.getSigner();
      const currentAddress = await signer.getAddress();
      await shortSignUpMetamaskEffect(currentAddress);
      const { signature, address } = await signMessage(false, undefined);
      await loginMetamask(address, signature, null, mainDispatch);
      const keys = await getUserRSAKeys({ signer });
      await savePubKey(
        address || window.metamask.selectedAddress,
        publicKeyToPem({ publicKey: keys.publicKey })
      );
      await setIsTokenValid(true);
    } catch (error) {
      addNotificationMessage(error?.message || error?.errors, error?.code);
    }
  }

  const closePluginModal = () => {
    setShowPluginModal(false);
  };

  // const tooltipContent = (
  //   <section className={cn('tooltip-content auth-invite-validation')}>
  //     <div>Keep your password safe</div>
  //     <br />
  //     <ul className="auth-invite-validate">
  //       <li
  //         className={valid[validations[0]] ? 'green' : submitted ? 'red' : ''}
  //       >
  //         Min 8 chars
  //       </li>
  //       <li
  //         className={valid[validations[1]] ? 'green' : submitted ? 'red' : ''}
  //       >
  //         Include (a-z)
  //       </li>
  //       <li
  //         className={valid[validations[2]] ? 'green' : submitted ? 'red' : ''}
  //       >
  //         Include Capitals (A-Z)
  //       </li>
  //       <li
  //         className={valid[validations[3]] ? 'green' : submitted ? 'red' : ''}
  //       >
  //         Include Numbers (0-9)
  //       </li>
  //     </ul>
  //   </section>
  // );

  const renderContent = () => {
    if (
      isOpenMobileModal &&
      isMobile &&
      !isTelegram &&
      (view === views.cryptoSingUp || view === views.signUp)
    ) {
      return (
        <JoinToDriveMobileModal
          closeMobileModal={closeMobileModal}
          workspaceName={workspaceName}
          link={token}
        />
      );
    }

    switch (view) {
      case views.joinWorkspace:
        return (
          <>
            <div className="auth-invite-join-container">
              <div
                className={cn('user-icon', 'auth-invite-join-logo')}
                style={{
                  color: currentInvite?.workspace?.color || '#000000',
                }}
              >
                {initials}
              </div>
              <div className={style.title}>
                Join the {renderWSName(currentInvite?.workspace?.name)}{' '}
                workspace
              </div>
              {workspaceHasContract && (
                <div className="auth-invite-join-price">
                  To join Workspace you must pay <strong>0.04 BNB</strong>
                </div>
              )}
              <Button
                type="button"
                className={`${style.button} ${style.button__join}`}
                theme={ButtonTheme.DARK}
                onClick={onJoinClickHandler(currentInvite?.token)}
              >
                Join now
              </Button>
              {userExist && (
                <Button
                  type="button"
                  className={`${style.button} ${style.button__ghost}`}
                  onClick={() => history.push('/')}
                >
                  Maybe later
                </Button>
              )}
            </div>
            {invites.length > 0 ? (
              <InviteWorkspaceList
                list={invites}
                current={currentInvite}
                onJoinClick={onJoinOtherClickHandler}
              />
            ) : null}
          </>
        );
      case views.alreadyJoined:
        return (
          <>
            <div className="auth-invite-join-container">
              <div
                className={cn('user-icon', 'auth-invite-join-logo')}
                style={{
                  color: currentInvite?.workspace?.color || '#000000',
                }}
              >
                {initials}
              </div>
              <div className={style.title}>
                You are a member of this workspace
              </div>
              <Button
                type="button"
                className={`${style.button} ${style.button__join}`}
                theme={ButtonTheme.DARK}
                onClick={() => history.push('/')}
              >
                Cancel
              </Button>
            </div>
          </>
        );

      case views.signIn:
        return (
          <SignInModal
            onSignInHandler={onRegisteredJoinWorkspaceClickHandler}
            fieldData={fieldData}
            onInputChange={onInputChange}
            handleKeyDown={handleKeyDown}
            currentErrors={errors}
            errorMessages={errorMessages}
          />
        );
      case views.signUp:
        return (
          <>
            <div className="auth-invite-content">
              <div className="auth-invite-title">
                Fill the form to join the workspace
              </div>
              <AccountInput
                label="Full name"
                value={fieldData.name}
                placeholder="Your name"
                name="name"
                type="text"
                errorMsg={errorMessages.username}
                onChange={onInputChange}
                onKeyDown={handleKeyDown}
                onBlur={onInputChange}
                hasError={errors.name}
              />
              <AccountInput
                label="Password"
                value={fieldData.password}
                placeholder="******"
                name="password"
                type="password"
                isPassword
                onKeyDown={handleKeyDown}
                autoComplete="new-password"
                hasError={errors.password}
                errorMsg={errorMessages.password}
                onChange={onInputChange}
                // tooltip={
                //   fieldData.password.length > 0 && (
                //     <Tooltip
                //       text={tooltipContent}
                //       className="tooltip-visible tooltip-right__white auth-invite-tooltip "
                //     />
                //   )
                // }
              />
              <button
                type="button"
                className="button-raw auth-invite-button"
                onClick={onNotRegisteredJoinWorkspaceClickHandler}
              >
                Join workspace
              </button>
            </div>
            <div className="auth-invite-bottom">
              <span>Already have an account? </span>
              <Link to="/sign-in" className="auth-invite-bottom-link">
                Login
              </Link>
            </div>
          </>
        );
      case views.cryptoSignIn:
      case views.cryptoSingUp:
        return (
          <>
            <div className="auth-invite-content">
              <div className="auth-invite-title">
                Welcome to workspace {workspaceName}
              </div>
              <div className="auth-invite-subtitle">
                To use this workspace, you need to connect a crypto wallet.
              </div>
              <div className="auth-invite-crypto-list">
                <CryptoButton cryptoType="metamask" onClick={activateMetamask}>
                  Metamask
                </CryptoButton>
              </div>
            </div>
            <div className="auth-invite-bottom">
              <span>Don’t have an account? </span>
              <Link to="/sign-up-crypto" className="auth-invite-bottom-link">
                Sign up
              </Link>
            </div>
            <CryptoCheckPluginModal
              onClose={closePluginModal}
              isOpen={showPluginModal}
              type={pluginType}
            />
          </>
        );
      case views.canceledInvitation:
        return (
          <div className="auth-invite-join-container">
            <p className={style.title}>Invitation has been canceled</p>
          </div>
        );
      default:
        return <Preloader />;
    }
  };

  if (loading)
    return (
      <Preloader text="Please wait, the transaction is in progress! After the successful completion of the transaction, you will get access to the workspace" />
    );

  return (
    <SharedLayout type="joinWorkspace">
      <main className="auth-invite-main">{renderContent()}</main>
      <NotificationBubble />
    </SharedLayout>
  );
};

const AcceptInviteComponent = (props) => (
  <MainContextWrapper>
    <AcceptInvite {...props} />
  </MainContextWrapper>
);

export default AcceptInviteComponent;
