export const extractShareRightsForUser = (userId, shares = []) => {
  const sharePermissions = shares
    .filter((share) => share.keyword === 'user')
    // TODO uncomment filtering by userId when API will be ready
    // .filter(share => share.share_right?.user?.id === userId)
    .map((share) => {
      if (share.share_right?.can_edit) {
        return 'edit'
      }

      if (share.share_right?.can_comment) {
        return 'comment'
      }

      if (share.share_right?.can_view) {
        return 'view'
      }

      return 'none'
    })

  return sharePermissions.length > 0
    ? sharePermissions[sharePermissions.length - 1]
    : null
}
