import { ReactElement } from 'react'
import * as actions from '../../actions/create-folder.actions';
import * as getFilesActions from '../../actions/files/get-files.actions';

import { API_FOLDERS } from 'constants/api-urls';
import authRequest from 'utils/request/auth-request';

export type ICreateFolderEffect = {
  name: string,
  folderId: string,
  addNotification: any,
  notificationMessage: string
}

export const createFolderEffect = (
  name: string,
  folderId: string,
  addNotification: any,
  notificationMessage: string | ReactElement,
) => {
  return async (dispatch:  (value: { payload?: any, type: string }) => void) => {
    dispatch(actions.createFolder());
    const reqName = name.trim().length <= 0 ? 'Default Folder' : name;
    const url = `${API_FOLDERS}/folder`;
    authRequest
      .post(url, { name: reqName, parent: folderId })
      .then((result: any) => {
        dispatch(
          getFilesActions.getFilesAdd({
            ...result.data?.data,
            folderId: folderId ?? 'main',
          })
        );
        addNotification &&
          addNotification(notificationMessage, 'success', false, '270px', true);
      })
      .catch((e: any) => {
        throw e;
      });
  };
};
