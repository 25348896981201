export const ENTITIES_SECURITY_CHECK = 'ENTITIES_SECURITY_CHECK'
export const ENTITIES_SECURITY_CHECK_RESET = 'FILE_SECURITY_RESET'
export const ENTITIES_SECURITY_SET_OPTION = 'ENTITIES_SECURITY_SET_OPTION'
export const WORKSPACE_SECURITY_CHECK = 'WORKSPACE_SECURITY_CHECK'

/**
 *
 * @param {object} data
 * @param {Array} data.entities
 * @param {string} data.actionType
 * @param {ISecurity[]} data.securitiesList
 * @param {object} data.addData
 *
 * @returns {{type: string, payload: {data: *}}}
 */
export function entitiesSecurityCheck(data) {
  return {
    type: ENTITIES_SECURITY_CHECK,
    payload: {
      data,
    },
  }
}

export function entitiesSecurityReset() {
  return {
    type: ENTITIES_SECURITY_CHECK_RESET,
  }
}

export const entitiesSecuritySetOption = (actionType) => ({
  type: ENTITIES_SECURITY_SET_OPTION,
  payload: {
    actionType,
  },
})

export function workspaceSecurityCheck(data) {
  return {
    type: WORKSPACE_SECURITY_CHECK,
    payload: {
      data,
    },
  }
}
