import * as actions from '../actions'

const initialState = {
  statusRecovery: false,
  user: {},
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.START_USER_RECOVERY:
      return {
        statusRecovery: true,
        user: action.payload,
      }
    case actions.SUCCESS_USER_RECOVERY:
    case actions.FAIL_USER_RECOVERY:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

// Selectors
export const selectRecoverUser = (state) => state.account.userRecover.user
export const selectStatusRecover = (state) =>
  state.account.userRecover.statusRecovery
