import { selectedEntityAccessPreferences } from '../../actions/selected-entity.actions';
import USER_ROLE_TYPE from 'config/user-role-types';
import authRequest from 'utils/request/auth-request';
import { API_FILE } from 'constants/api-urls';

export function getAccessPreferenceEffect(slug) {
  return authRequest.get(`${API_FILE}/access/${slug}`).then(({ data }) => ({
    actionId: data.actionId,
    ...data.entry,
    members:
      data.members
        ?.filter(
          (item) =>
            item.user &&
            (item.role === USER_ROLE_TYPE.MEMBER ||
              item.role === USER_ROLE_TYPE.GUEST)
        )
        .map(({ user }) => user) || [],
  }));
}

export function getAccessPreference(slug, file) {
  return async (dispatch) => {
    const url = API_FILE;

    const result = await authRequest.get(`${url}/access/${slug}`);
    const members =
      result.data.members
        ?.filter((item) => !!item.user && item.role === USER_ROLE_TYPE.MEMBER)
        .map(({ user }) => user) || [];
    dispatch(
      selectedEntityAccessPreferences({
        entity: {
          actionId: result.data.actionId,
          ...result.data.entry,
          members: members,
          file: { ...file, shares: result.data.entry.shares },
        },
      })
    );
  };
}
