//Refactoring №2
import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { detect } from 'detect-browser';

import { isMobile, isIphone } from 'utils/mobile';

import Button, { ButtonTheme } from 'components/Button';

import CloseIcon from 'components/svg/close';
import { ReactComponent as Phantom } from 'static/assets/svg/crypto/phantom.svg';
import { ReactComponent as Metamask } from 'static/assets/svg/crypto/metamask.svg';

const iphoneAppLink = 'https://itunes.apple.com/us/app/metamask/id313873980';
const androinAppLink =
  'https://play.google.com/store/apps/details?id=io.metamask';

const CryptoCheckPluginModal = ({ isOpen, onClose, type = 'metamask' }) => {
  const { t } = useTranslation('account');
  const baseCls = 'crypto-auth';
  const { name } = detect();

  const mobileAppUrl = isIphone ? iphoneAppLink : androinAppLink;

  const installLinks = {
    metamask: {
      chrome:
        'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en',
      firefox: 'https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/',
      edge: 'https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US',
    },
    phantom: {
      chrome:
        'https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa',
      firefox: 'https://addons.mozilla.org/en-US/firefox/addon/phantom-app/',
      edge: 'https://chrome.google.com/webstore/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa',
    },
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`Modal`}
      overlayClassName="Overlay"
      shouldCloseOnOverlayClick
    >
      <section className={`modal modal__contented  ${baseCls}-modal`}>
        <button
          type="button"
          className="modal__exit"
          onClick={onClose}
          data-test="modal_crypto_close_button"
        >
          <CloseIcon />
        </button>
        <div className="modal__content">
          {type === 'metamask' ? <Metamask /> : <Phantom />}
          <div className="modal__title modal__title-small">
            {type[0].toUpperCase() + type.slice(1)}
          </div>
          <div className="modal__text">
            {t('tokens.needToInstall')}
            <br />
            {isMobile
              ? t('tokens.installApp')
              : t('tokens.extensionFromGoogle')}
            .
          </div>
        </div>
        <div className="modal__buttons modal__buttons-centered">
          <div style={{ display: 'flex', flex: 1 }}>
            <Button
              onClick={onClose}
              htmlElement="button"
              type="button"
              value={t('Profile.cancel')}
              data-test="modal_crypto_cancel_button"
              theme={ButtonTheme.TRANSPARENT}
            />
          </div>
          <a
            target="_blank"
            rel="noreferrer"
            href={isMobile ? mobileAppUrl : installLinks[type][name]}
          >
            <Button
              htmlElement="button"
              type="button"
              value={`${t('tokens.install')} ${
                isMobile ? t('tokens.app') : t('tokens.extension')
              }`}
              data-test="modal_crypto_install_extension_button"
            />
          </a>
        </div>
      </section>
    </Modal>
  );
};

export default React.memo(CryptoCheckPluginModal);
