export const GET_TFA_DATA = 'GET_TFA_DATA'
export const GET_TFA_DATA_SUCCESS = 'GET_TFA_DATA_SUCCESS'
export const GET_TFA_DATA_FAIL = 'GET_TFA_DATA_FAIL'

export function getTfaData() {
  return {
    type: GET_TFA_DATA,
  }
}

export function getTfaDataSuccess(res) {
  return {
    type: GET_TFA_DATA_SUCCESS,
    payload: res,
  }
}

export function getTfaDataFail(err) {
  return {
    type: GET_TFA_DATA_FAIL,
    payload: err,
  }
}
