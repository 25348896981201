//Refactoring №3
import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Formik, Form } from 'formik';
import { object, string } from 'yup';

import { recoveryPasswordEffect } from 'store/auth/effects/recovery-password.effect';

import InputFieldFormik from 'components/InputFieldFormik';
import Header from './components/header';
import Button, { ButtonTheme } from 'components/Button';

import styles from './style.module.scss';

const initialValues = {
  email: '',
};

const validationSchema = object({
  email: string()
    .email('Enter valid email')
    .required('This field is required')
    .min(8, 'The minimum field length is 8 characters'),
});

const ForgotPassword = () => {
  const [requestIsSent, setRequestIsSent] = useState(false);
  const { t } = useTranslation('auth');
  const form = useRef(null);

  const onSendTheLinkHandler = ({ email }) => {
    recoveryPasswordEffect(email)
      .then(() => {
        setRequestIsSent(true);
      })
      .catch(({ errors, message }) => {
        if (errors) {
          form.current.setFieldError(
            'email',
            errors?.children?.email?.errors?.join() || ''
          );
        } else {
          form.current.setFieldError('email', message || '');
        }
      });
  };

  return (
    <section className={styles.wrapper}>
      <Header />
      <main className={styles.container}>
        {requestIsSent ? (
          <div className={styles.content}>
            <div className={styles.successTitle}>
              {t('common.recoveryLinkSent')}
            </div>
            <p className={styles.successText}>
              {`${t('common.recoveryInstructions')} `}
              <span
                className={styles.successResend}
                onClick={() => setRequestIsSent(false)}
              >
                {t('common.resendLink')}
              </span>
              .
            </p>
          </div>
        ) : (
          <div className={styles.content}>
            <div className={styles.title}>{t('common.passwordRecovery')}</div>
            <Formik
              innerRef={form}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSendTheLinkHandler}
            >
              {({ isValid }) => (
                <Form>
                  <InputFieldFormik
                    className={styles.field}
                    label={t('common.email')}
                    placeholder={t('common.yourEmail')}
                    name="email"
                    type="text"
                  />
                  <Button
                    theme={ButtonTheme.DARK}
                    disabled={!isValid}
                    className={styles.button}
                    type="submit"
                  >
                    {t('common.sendLink')}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )}
        <div className={styles.footer}>
          <span>{t('common.orBackto')}</span>
          <Link to="/sign-in" className={styles.link}>
            {t('common.login')}
          </Link>
        </div>
      </main>
    </section>
  );
};

export default ForgotPassword;
