//Refactoring №3
import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import CloseIcon from 'components/svg/close';

import style from './style.module.scss';

type ErrorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  error: string;
};

const ErrorModal = ({ isOpen, onClose, error }: ErrorModalProps) => {
  const { t } = useTranslation('popup');
  return (
    <Modal
      isOpen={isOpen}
      className={style.errorModal}
      overlayClassName={style.errorOverlay}
      onRequestClose={onClose}
    >
      <section className={style.errorContainer}>
        <button
          onClick={onClose}
          className={style.errorModal__exit}
          type="button"
        >
          <CloseIcon color="#4B576C" />
        </button>
        <h1 className={style.errorTitle}>{t('errorModal.error')}</h1>
        <p className={style.errorText}>{error}</p>
      </section>
    </Modal>
  );
};

export default ErrorModal;
