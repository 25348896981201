import authRequest from 'utils/request/auth-request';

export const saveEncryptedFileKeys = async (
  body: any
): Promise<{ data: string }> => {
  const retryRequest = async (retries = 2, delay = 1000) => {
    try {
      const response = await authRequest.post(
        `${process.env.REACT_APP_API_PATH}/keys/save_encrypted_file_keys`,
        body
      );
      //@ts-ignore
      return response?.data;
    } catch (error) {
      //@ts-ignore
      if (error?.response?.status === 404 && retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        return retryRequest(retries - 1, delay);
      }
      throw error;
    }
  };

  return retryRequest();
};
