import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import setToken from 'utils/auth/set-token';
import { ReactComponent as LogoIcon } from '../../assets/creatingProfile.svg';
import styles from './styles.module.scss';
import { loginEmail } from 'web3-auth/lib/es5';
import { NEYRA_AI_API } from 'constants/api-urls';

const CreatingProfile = ({ userAnswers }) => {
  const [progress, setProgress] = useState(0);
  const [tokensData, setTokensData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const createUser = async () => {
      try {
        const data = await loginEmail({
          name: userAnswers['name'].answer,
          password: userAnswers['password'].answer,
          email: userAnswers['email'].answer,
          NEYRA_AI_API,
        });
        setTokensData(data);
      } catch (error) {
        console.error(error);
      }
    };
    createUser();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (progress === 100 && tokensData) {
      setToken(tokensData);
      history.push('/chat');
    }
  }, [progress, tokensData]);

  return (
    <div className={styles.progressBar}>
      <div className={styles.logo}>
        <LogoIcon />
      </div>
      <div className={styles.fill} style={{ width: `${progress}%` }}></div>
      <div className={styles.percent}>{progress}%</div>
    </div>
  );
};

export default CreatingProfile;
