import React from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { handleConnectWalletModal } from 'features/modals/modal-slice';

import CloseIcon from 'components/svg/close';

import s from './style.module.scss';

export const ConnectWalletModal = ({ isOpen }: { isOpen: boolean }) => {
  const { t } = useTranslation('popup');
  const dispatch = useDispatch();

  const onClose = () => dispatch(handleConnectWalletModal(false));

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="ConnectWallet"
      className={s.modal}
      overlayClassName={s.overlay}
      shouldCloseOnOverlayClick
    >
      <div className={s.modal__content}>
        <button type="button" className={s.modal__exit} onClick={onClose}>
          <CloseIcon />
        </button>
        <h2 className={s.modal__title}>
          {t('connectWalletModal.walletIsntConnected')}
        </h2>
        <p className={s.modal__text}>
          {t('connectWalletModal.pleaseConnectWallet')}
          <a href="/account/profile" target="_blank">
            {t('connectWalletModal.profileSettings')}
          </a>
          {t('connectWalletModal.navigation')}
        </p>
        <button className={s.modal__button} onClick={onClose}>
          {t('membersLimit.ok')}
        </button>
      </div>
    </Modal>
  );
};
