import React, { useContext } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import MainContext from 'store/main/context/main-context';
import { removeNotification } from 'store/main/actions';

import NotificationItem from './notification-item';

import './notifications.scss';

const NotificationBubble = () => {
  const {
    state: { notifications },
    dispatch,
  } = useContext(MainContext);

  const removeItem = (notification) => {
    dispatch(removeNotification(notification));
  };

  const notificationItems = notifications.map((notification) => (
    <CSSTransition
      key={`notification-item-${notification.id}`}
      classNames="notification-item"
      timeout={0}
    >
      <NotificationItem notification={notification} removeItem={removeItem} />
    </CSSTransition>
  ));

  return (
    <TransitionGroup className="notifications-container">
      {notificationItems}
    </TransitionGroup>
  );
};

export default NotificationBubble;
