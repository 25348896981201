import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';
import useAnimatedMessage from 'utils/hooks/useAnimatedMessage';
import styles from './styles.module.scss';
import { BIOS_PRICE } from 'constants/bios-price';

const NeyraLaunchMessage = ({ hideButton, finishTyping, msgType }) => {
  const { t } = useTranslation('auth');
  const messages = {
    profilePlus: t('initialPage.neyraCommunityMessage'),
    registration: t('initialPage.neyraCommunityRegistration', { BIOS_PRICE }),
    successPayment: t('initialPage.congratulationsMessage'),
  };

  const { animatedMessage, isPrinting } = useAnimatedMessage(messages[msgType]);

  useEffect(() => {
    if (!isPrinting) {
      finishTyping();
    }
  }, [isPrinting]);

  return (
    <div
      className={CN(
        styles.launchMessageContainer,
        !hideButton && styles.fullHeight
      )}
    >
      <div
        className={styles.launchMessage}
        dangerouslySetInnerHTML={{
          __html: hideButton ? messages[msgType] : animatedMessage,
        }}
      />
    </div>
  );
};

export default NeyraLaunchMessage;
