type IAction = {
  type: string,
  name: string,
  permissions?: string[],
  disableOnSharedToMe?: boolean,
  className?: string,
  secondName?: string,
}

type IActionOptions = {
  [key: string]: IAction
}

//Refactoring №3
const actionsOptions = {
  access: {
    type: 'access',
    name: 'Access',
    permissions: ['edit'],
  },
  addUser: {
    type: 'addUser',
    name: 'Add User',
  },
  color: {
    type: 'color',
    name: 'Color',
    permissions: ['edit'],
  },
  comment: {
    type: 'comment',
    name: 'Comment',
    permissions: ['edit', 'comment'],
  },
  convert: {
    type: 'convert',
    name: 'Convert',
  },
  createSharedFolder: {
    type: 'createSharedFolder',
    name: 'Create New Shared Folder',
  },
  createTeam: {
    type: 'createTeam',
    name: 'Create New Team',
  },
  createWorkspace: {
    type: 'createWorkspace',
    name: '',
  },
  deleteTeam: {
    type: 'deleteTeam',
    name: 'Delete Team',
  },
  download: {
    type: 'download',
    name: 'Download',
    permissions: ['edit', 'comment', 'view'],
  },
  edit: {
    type: 'edit',
    name: 'Rename',
    permissions: ['edit'],
  },
  editTeam: {
    type: 'editTeam',
    name: 'Edit Team',
  },
  encrypt: {
    type: 'encrypt',
    name: 'Encrypt',
  },
  emoji: {
    type: 'emoji',
    name: 'Emoji',
    permissions: ['edit'],
  },
  ghostMode: {
    type: 'ghostMode',
    name: 'Ghost Mode',
  },
  goToFolder: {
    type: 'goToFolder',
  },
  goToSettings: {
    type: 'goToSettings',
    name: 'Show Settings',
  },
  goToSecuritySettings: {
    type: 'goToSecuritySettings',
    name: 'Security',
    permissions: ['edit'],
  },
  goToComments: {
    type: 'goToComments',
    name: 'Comment',
    permissions: ['edit', 'comment', 'view'],
  },
  goToTokenization: {
    type: 'goToTokenization',
    name: 'Tokenization',
    permissions: ['edit', 'comment', 'view'],
  },
  goToTiming: {
    type: 'goToTiming',
    name: 'Change Time',
  },
  history: {
    type: 'history',
    name: 'History',
  },
  inviteToTeam: {
    type: 'inviteToTeam',
    name: 'Invite to the team',
  },
  lock: {
    type: 'lock',
    name: 'Lock',
    permissions: ['edit'],
  },
  manageLink: {
    type: 'manageLink',
    name: 'Manage Link',
  },
  manageShare: {
    type: 'manageShare',
    name: 'Share',
    permissions: ['edit'],
  },
  accessPreferences: {
    type: 'accessPreferences',
    name: 'Sharing',
    permissions: ['edit'],
  },
  move: {
    type: 'move',
    name: 'Move',
    permissions: ['edit'],
  },
  showMove: {
    type: 'showMove',
    name: 'Move',
    permissions: ['edit'],
    disableOnSharedToMe: true,
  },
  print: {
    type: 'print',
    name: 'Print',
    permissions: ['edit', 'comment', 'view'],
    className: 'option-print',
  },
  remove: {
    type: 'remove',
    name: 'Delete',
    permissions: ['edit'],
  },
  removeFromTrash: {
    type: 'removeFromTrash',
    name: 'Delete permanently',
    permissions: ['edit'],
  },
  removeAllFromTrash: {
    type: 'removeAllFromTrash',
    name: 'Delete permanently',
    permissions: ['edit'],
  },
  restoreFromTrash: {
    type: 'restoreFromTrash',
    name: 'Restore',
    permissions: ['edit'],
  },
  removeFromShared: {
    type: 'removeFromShared',
    name: 'Delete',
    permissions: ['edit', 'comment', 'view'],
  },
  removeShared: {
    type: 'removeShared',
    name: 'Delete',
    permissions: ['edit'],
  },
  removeDocument: {
    type: 'removeDocument',
    name: 'Delete',
    permissions: ['edit'],
  },
  settingsSharedFolder: {
    type: 'settingsSharedFolder',
    name: 'Settings Folder',
  },
  removeUser: {
    type: 'removeUser',
    name: 'Remove User',
  },
  removeUserFromTeam: {
    type: 'removeUserFromTeam',
    name: 'Remove User',
  },
  tag: {
    type: 'tag',
    name: 'Tag',
    permissions: ['edit'],
  },
  teamInfo: {
    type: 'teamInfo',
    name: 'Team information',
  },
  timing: {
    type: 'timing',
    name: 'Timing',
  },
  toggleSecurity: {
    type: 'toggleSecurity',
  },
  viewFile: {
    type: 'viewFile',
    name: 'Preview',
    permissions: ['edit', 'comment', 'view'],
    className: 'viewItem',
  },
  unshare: {
    type: 'unshare',
    name: 'Unshare',
  },
  save: {
    type: 'save',
    name: 'Save',
    className: 'option-save',
  },
  downloadPdf: {
    type: 'downloadPdf',
    name: 'Download as PDF',
    className: 'option-download-pdf',
  },
  downloadDocx: {
    type: 'downloadDocx',
    name: 'Download as DOCX',
    className: 'option-download-docx',
  },
  cancelInvite: {
    type: 'cancelInvite',
    name: 'Cancel Invite',
    className: 'cancel-invite',
  },
  resendInvite: {
    type: 'resendInvite',
    name: 'Resend invitation',
    className: 'resend-invite',
  },
  copyLink: {
    type: 'copyLink',
    name: 'Copy invite link',
    className: 'copy-link',
  },
  removeInvite: {
    type: 'removeInvite',
    name: 'Remove',
    className: 'remove-invite',
  },
  blockUser: {
    type: 'blockUser',
    name: 'Block User',
    className: 'block-user',
  },
  unlockUser: {
    type: 'unlockUser',
    name: 'Unlock User',
    className: 'unlock-user',
  },
  clearAllNotification: {
    type: 'clearAllNotification',
    name: 'Clear all',
    className: 'clear-all',
  },
  goToNotificationSetting: {
    type: 'goToNotificationSetting',
    name: 'Settings',
    className: 'go-to-settings',
  },
  deletePassword: {
    type: 'deletePassword',
    name: 'Delete',
    className: 'option-delete',
  },
  editPassword: {
    type: 'editPassword',
    name: 'Edit',
    className: 'edit',
  },
  nft: {
    type: 'nft',
    name: 'NFT',
    className: 'nftItem',
  },
  geoSecurity: {
    type: 'geoSecurity',
    name: 'GeoSecurity',
    permissions: ['edit', 'comment', 'view'],
  },
  analytics: {
    type: 'analytics',
    name: 'Analytics',
    className: 'analyticsItem',
  },
  guardians: {
    type: 'guardians',
    name: 'Guardians',
    className: 'guardiansItem',
  },
  favorites: {
    type: 'favorites',
    name: 'Favorites',
    secondName: 'Remove from Favorites',
    className: 'favoritesItem',
  },
  myAI: {
    type: 'my-ai',
    name: 'Add to my AI',
    secondName: 'Remove from My AI',
  },
  aiGenerator: {
    type: 'aiGenerator',
    name: 'AI Generator',
  },
  aiPlayground: {
    type: 'aiPlayground',
    name: 'AI PlaygroundS',
  },
  aiUpscaller: {
    type: 'aiUpscaller',
    name: 'AI Upscale',
    className: 'aiUpscaller',
  },
  createFolder: {
    type: 'createFolder',
    name: 'Create Folder',
  },
  upload: {
    type: 'upload',
    name: 'Upload',
    className: 'uploadItem',
  },
  addRecord: {
    type: 'addRecord',
    name: 'Add record',
  },
  addPage: {
    type: 'addPage',
    name: 'Add page',
  },
  addPassword: {
    type: 'addPassword',
    name: 'Add password',
  },
  openPassword: {
    type: 'openPassword',
    name: 'Open password',
  },
  editFile: {
    type: 'editFile',
    name: 'Edit',
  },
  addLink: {
    type: 'addLink',
    name: 'Add link',
  },
  openLink: {
    type: 'openLink',
    name: 'Open Link',
  },
};

const actionsType = {
  CREATED: 1,
  RENAMED: 2,
  MOVED: 3,
  DELETED: 4,
  DOWNLOADED: 5,
  VIEWED: 6,
  SHARED: 7,
  UNSHARED: 8,
  SECURED: 9,
  UNSECURED: 10,
  RECOVERED: 11,
  DELETED_PERMANENTLY: 12,
  COMMENTED: 13,
  CHANGE_PERMISSIONS: 14,
  DENIED: 15,
  FILE_CNT: 16,
  FOLDER_CNT: 17,
  ENTRY_TOKENIZED: 18,
  AI_GENERATE: 19,
  SAFETY_NET_ACTIVATED: 20,
  SAFETY_NET_TRANSFERRED: 21,
  MULTI_SIGNATURE_REQUEST: 22,
  MULTI_SIGNATURE_REQUEST_CANCEL: 23,
  GEO_SECURITY: 24,
  OFFLINE_MODE: 25,
}

export { actionsType, type IActionOptions, type IAction }

export default actionsOptions;

