// utils
import authRequest from 'utils/request/auth-request';
import { API_NOTIFICATIONS } from 'constants/api-urls';

export default async (notificationsIds: number) =>
  authRequest
    .post(`${API_NOTIFICATIONS}/reviews`, {
      ids: notificationsIds,
    })
    .then(({ data }) => {
      return data;
    })
    .catch((response) => {
      throw response.message;
    });
