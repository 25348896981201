import { AxiosResponse } from 'axios';

import authRequest from 'utils/request/auth-request';

export const manageKeys = async (
  slug: string,
  encryptionKey: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${process.env.REACT_APP_API_PATH}/keys/manage-key`;
  // @ts-ignore
  return authRequest.post(url, { slug, encryptionKey });
};
