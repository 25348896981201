import * as actions from '../actions/activate-pin.actions'
import { merge } from 'ramda'
import getData from 'utils/request/get-data'

const initialState = {
  pending: false,
  data: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.ACTIVATE_PIN:
      return merge(state, {
        pending: true,
      })
    case actions.ACTIVATE_PIN_SUCCESS:
      return merge(state, {
        pending: false,
        data: getData(action),
      })
    case actions.ACTIVATE_PIN_FAILURE:
      return merge(state, {
        pending: false,
      })
    default:
      return state
  }
}
