//Refactoring №3
import { useEffect, useContext } from 'react';

import MainContext from 'store/main/context/main-context';
import { userWorkspaces } from 'store/workspace/effects';
import { setWorkspacesHandler } from './setWorkspacesHandler';

export const useLoadWorkspace = () => {
  const { dispatch } = useContext(MainContext);

  useEffect(() => {
    userWorkspaces().then((workspaces) => {
      setWorkspacesHandler(dispatch, workspaces);
    });
  }, []);
};
