import { useEffect } from 'react'

const useClickOutside = (reference, onClickOutside) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (Array.isArray(reference)) {
        const clickInside =
          reference.some((element) => {
            if (element.current?.contains(e.target)) {
              return true
            }
          }) || false

        !clickInside && onClickOutside && onClickOutside(e)
      } else if (reference.current && !reference.current.contains(e.target)) {
        onClickOutside && onClickOutside(e)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [reference, onClickOutside])
}

export default useClickOutside
