import { Entity } from 'containers/filtered-files';

export const UPDATE_FILTERED_FILES = 'UPDATE_FILTERED_FILES';
export const DELETE_FILTERED_FILE = 'DELETE_FILTERED_FILE';
export const RESET_FILTERED_FILES = 'RESET_FILTERED_FILES';
export const GET_FILTERED_FILES = 'GET_FILTERED_FILES ';

export const filteredFilesGetSuccess = (data: {
  count: number;
  files: [Entity];
}) => ({
  type: GET_FILTERED_FILES,
  payload: data,
});

export const updateFilteredFiles = ({ files }: { files: [Entity] }) => ({
  type: UPDATE_FILTERED_FILES,
  payload: files,
});

export const deleteFilteredFileSuccess = (slug: { slug: string }) => ({
  type: DELETE_FILTERED_FILE,
  payload: { slug },
});

export const resetFilteredFiles = () => ({ type: RESET_FILTERED_FILES });
