import React, { useEffect, useState } from 'react';
import ConversationHistoryTerminal from 'components/ConversationHistoryTerminal';
import NeyraAI from '../NeyraAI';
import NeyraBorder from 'components/NeyraBorder';
import getToken from 'utils/auth/get-token';
import NotificationBubble from 'containers/notifications/notification-bubble';
import { MainContextWrapper } from 'store/context';
import Sidebar from './Sidebar';

import styles from './styles.module.scss';

const Terminal = () => {
  const isAuth = !!getToken();
  const [notification, setNotification] = useState({ text: '' });

  useEffect(() => {
    if (notification.text) {
      const id = setTimeout(() => {
        setNotification({ icon: '', text: '' });
      }, 3000);

      return () => {
        clearTimeout(id);
      };
    }
  }, [notification]);

  const addNotification = (message) => {
    setNotification({ icon: '', text: message });
  };

  return (
    <>
      {isAuth ? (
        <MainContextWrapper>
          <div className={styles.terminalWrapper}>
            <NeyraBorder notification={notification}>
              <ConversationHistoryTerminal addNotification={addNotification} />
            </NeyraBorder>
            <Sidebar />
          </div>
          <NotificationBubble />
        </MainContextWrapper>
      ) : (
        <NeyraAI />
      )}
    </>
  );
};

export default Terminal;
