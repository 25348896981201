import React, { ChangeEvent, FC, useState } from 'react';
import Select from 'react-select';
import { sendReport } from '../api';

import s from '../styles.module.scss';
import CloseIcon from '../../svg/close';
import CN from 'classnames';
import Button from '../../Button';

interface Problem {
  type: number;
  label: string;
}

const problems: Problem[] = [
  { label: 'Copyright infringement', type: 1 },
  { label: 'Nudity or sexual content', type: 2 },
  { label: 'Harassment or bullying', type: 3 },
  { label: 'Hateful content', type: 4 },
  { label: 'Spam or misinformation', type: 5 },
  { label: 'Drug use or self-harm', type: 6 },
  { label: 'Suicide or self-injury', type: 7 },
  { label: 'Sale illegal or regulated goods', type: 8 },
  { label: 'Violence or dangerous organizations', type: 9 },
];

const selectStyles = {
  input: (baseStyles: any) => ({
    ...baseStyles,
    width: '100%',
  }),
  container: (baseStyles: any) => ({
    ...baseStyles,
    width: '100%',
  }),
  control: (baseStyles: any) => ({
    ...baseStyles,
    width: '100%',
  }),
};

interface ReportModalProps {
  slug: string;
  network: number;
  token_address: string;
  onClose: () => void;
}

export const ReportModal: FC<ReportModalProps> = ({
  slug = null,
  network = null,
  token_address = null,
  onClose,
}) => {
  const [problemType, setProblemType] = useState<Problem | null>(null);
  const [problemDescription, setProblemDescription] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChangeDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setProblemDescription(event.target.value);
  };

  const handleProblemTypeChange = (value: Problem) => {
    console.warn({ value });
    setProblemType(value);
  };

  const handleSendReport = async () => {
    try {
      setLoading(true);
      if (problemType && slug && token_address && network) {
        const [account]: string[] = await window.metamask.request({
          method: 'eth_requestAccounts',
        });
        const data = await sendReport({
          type: problemType?.type,
          comment: problemDescription,
          slug,
          networkId: network,
          token_address,
          public_address: account,
        });
        console.warn({ data });
        if (data) {
          onClose();
        }
      } else {
        setError(true);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className={s.report_modal_container}>
      <button type={'button'} className={s.exit} onClick={onClose}>
        <CloseIcon />
      </button>
      <h4 className={s.report_modal_title}>Report</h4>
      <h2 className={s.report_modal_subtitle}>
        Your report is anonymous, except if you’re reporting annintelectual
        property infringement.
      </h2>
      <label className={s.report_modal_select} htmlFor="select-action">
        <span className={s.report_modal_label}>Problem Type</span>
        <Select
          options={problems}
          id="select-action"
          className={'modal__select-error'}
          placeholder="Choose Action"
          onChange={handleProblemTypeChange}
          IndicatorsContainer={false}
          value={problemType}
          defaultValue={null}
          styles={selectStyles}
        />
      </label>
      <label>
        <p className={s.report_modal_label}>Other</p>
        <textarea
          className={s.report_modal_textarea}
          placeholder={'Describe the problem'}
          value={problemDescription}
          onChange={handleChangeDescription}
        />
      </label>
      <Button
        showPreloader={loading}
        type={'button'}
        onClick={handleSendReport}
        className={CN(
          s.report_modal_button,
          s.button_loader,
          error && s.report_modal_button_error
        )}
      >
        Report
      </Button>
    </div>
  );
};
