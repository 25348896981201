export const CREATE_FOLDER = 'CREATE_FOLDER'
export const CREATE_FOLDER_RESET = 'CREATE_FOLDER_RESET'
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS'
export const CREATE_FOLDER_FAILURE = 'CREATE_FOLDER_FAILURE'

export const createFolder = () => ({
  type: CREATE_FOLDER,
})

export const createFolderReset = () => ({
  type: CREATE_FOLDER_RESET,
})

export const createFolderSuccess = (res) => ({
  type: CREATE_FOLDER_SUCCESS,
  payload: res,
})

export const createFolderFailure = (err) => ({
  type: CREATE_FOLDER_FAILURE,
  payload: err,
})
