import React from "react";
import CN from 'classnames';

import { ReactComponent as InputCheckIcon } from 'containers/account/Space/assets/input-check.svg';
import { ReactComponent as InputAddIcon } from 'containers/account/Space/assets/input-plus.svg';
import Spinner from 'components/Spinners'

import styles from 'containers/main/NeyraDriveContainer/components/SidebarItem/styles.module.scss';
import localStyles from '../styles.module.scss';

const SidebarInput = ({
  loading,
  onKeyPress,
  doRedirect,
  value,
  onChnage,
  name,
  maxLength,
  onClick,
  placeholder
}) => {

  const renderIcon = () => {
    if (loading)
      return <Spinner />

    if (value)
      return <InputCheckIcon />

    return <InputAddIcon />
  }

  return (
    <div
      className={CN(styles.sidebarItem)}
      onClick={doRedirect}
    >
      <input
        className={localStyles.sidebarInput}
        name={name}
        value={value}
        onChange={onChnage}
        maxLength={maxLength}
        placeholder={placeholder}
        onKeyDown={onKeyPress}
      />
      <button onClick={onClick} className={localStyles.inpitIcon}>
        { renderIcon() }
      </button>
    </div>
  )
}

export default SidebarInput