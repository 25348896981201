export const ACTIVATE_TFA = 'ACTIVATE_TFA'
export const ACTIVATE_TFA_SUCCESS = 'ACTIVATE_TFA_SUCCESS'
export const ACTIVATE_TFA_FAIL = 'ACTIVATE_TFA_FAIL'
export const ClEAR_ACTIVATE_TFA = 'ClEAR_ACTIVATE_TFA'

export function activateTfa() {
  return {
    type: ACTIVATE_TFA,
  }
}

export function activateTfaSuccess(res) {
  return {
    type: ACTIVATE_TFA_SUCCESS,
    payload: res,
  }
}

export function activateTfaFail(err) {
  return {
    type: ACTIVATE_TFA_FAIL,
    payload: err,
  }
}
export function clearActivateTfaState() {
  return {
    type: ClEAR_ACTIVATE_TFA,
  }
}
