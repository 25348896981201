import checkViewType from 'utils/files-folders/check-view-type'
import * as actions from '../../actions/file-fiew.actions'
import getContentViewType from 'utils/files-folders/get-content-view-type'

export default () => {
  return (dispatch) => {
    const typeFromStorage = getContentViewType()
    const type = checkViewType(typeFromStorage)

    dispatch(actions.getFileView(type))
  }
}
