import React from 'react'

const ClockIcon = ({ color, className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C19.9953 7.58369 16.4163 4.00472 12 4ZM12 18.8571C8.21289 18.8571 5.14285 15.7871 5.14285 12C5.14285 8.21289 8.21289 5.14285 12 5.14285C15.7871 5.14285 18.8571 8.21289 18.8571 12C18.853 15.7854 15.7854 18.853 12 18.8571Z"
      fill={color}
    />
    <path
      d="M12.6985 7.55469C12.3829 7.55469 12.1271 7.81052 12.1271 8.12613V12.1261H8.1271C7.8115 12.1261 7.55566 12.382 7.55566 12.6976C7.55566 13.0132 7.8115 13.269 8.1271 13.269H12.6985C13.0141 13.269 13.27 13.0131 13.27 12.6975V8.12613C13.27 7.81052 13.0141 7.55469 12.6985 7.55469Z"
      fill={color}
    />
  </svg>
)

ClockIcon.defaultProps = {
  color: '#7E8A9F',
  className: '',
}

export default ClockIcon
