import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CloseIcon from 'components/svg/closeSmall';

type ModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onContinue: () => void;
  description?: string;
  needEncryptExistingFile: boolean;
};

const CancelUploadModal = ({
  isOpen,
  onCancel,
  onContinue,
  description = '',
  needEncryptExistingFile,
}: ModalProps) => {
  const { t } = useTranslation('popup');

  const action = needEncryptExistingFile
    ? t('uploadDetails.encryption')
    : t('uploadDetails.upload');

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Access preferences"
      className="Modal"
      overlayClassName="Overlay"
      shouldCloseOnOverlayClick
      onRequestClose={onContinue}
    >
      <section className="modal modal-upload">
        <div>
          <div className="download__modal__icon">
            <button onClick={onContinue}>
              <CloseIcon />
            </button>
          </div>
          <div className="download__modal__header">
            <span>{t('uploadDetails.cancelAll', { action })}</span>
          </div>
          <div className="download__modal__warning">
            <span>{t('uploadDetails.actionIsNotComplete', { action })}</span>
          </div>
          <div className="download__modal__button">
            <button
              className="download__modal__button__cancel"
              onClick={onCancel}
            >
              <span className="download__modal__button__text">
                {t('uploadDetails.cancelAction', { action })}
              </span>
            </button>
            <button
              className="download__modal__button__continue"
              onClick={onContinue}
            >
              <span className="download__modal__button__text">
                {description || t('uploadDetails.continueAction', { action })}
              </span>
            </button>
          </div>
        </div>
      </section>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  needEncryptExistingFile: state?.home?.encrypt?.needEncryptExistingFile,
});

export default connect(mapStateToProps)(CancelUploadModal);
