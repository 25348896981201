// Utils
import authRequest from 'utils/request/auth-request';
import { API_FILE } from 'constants/api-urls';

export default async (slug) => {
  const url = `${API_FILE}/${slug}/tokenization`;

  return authRequest
    .get(url)
    .then((response) => response.data)
    .catch((response) => {
      throw response;
    });
};
