// Refactoring №3
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoIcon } from '../assets/logo_light.svg';
import { ReactComponent as JoinToSpace } from '../assets/join_to_space.svg';
import { MOBILE_LINKING } from 'constants/api-urls';

import s from './style.module.scss';

type MobileModalProps = {
  closeMobileModal: () => void;
  workspaceName: string;
  link: string;
};

export const JoinToDriveMobileModal = ({
  closeMobileModal,
  workspaceName,
  link,
}: MobileModalProps) => {
  const { t } = useTranslation('popup');

  const getApp = () => {
    window.location.href = `${MOBILE_LINKING}join-member/${link}`;
  };

  return (
    <div className={s.container}>
      <div className={s.contentWrapper}>
        <LogoIcon />
        <h1 className={s.container__title}>GD</h1>
        <div className={s.container__logo}>
          <JoinToSpace />
          <p className={s.container__description}>
            <span className={s.container__span}>
              {t('joinToSpaceEntity.description')}
            </span>
            {workspaceName}
          </p>
        </div>
      </div>
      <div className={s.container__buttons}>
        <button onClick={getApp}>{t('joinToSpaceEntity.getApp')}</button>
        <button onClick={closeMobileModal}>
          {t('joinToSpaceEntity.continueWithWebsite')}
        </button>
      </div>
    </div>
  );
};
