//Refactoring №3
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import getAnalyticsEffect from 'store/home/effects/analytics/get-analytics.effect';

import Tooltip from 'components/shared/Tooltip/tooltip';
import CloseIcon from 'components/svg/close';
import { ReactComponent as ViewsIcon } from 'static/assets/svg/views.svg';
import { ReactComponent as FolderIcon } from 'static/assets/svg/folder-text.svg';
import { ReactComponent as FileIcon } from 'static/assets/svg/file-text.svg';
import { ReactComponent as DownloadIcon } from 'static/assets/svg/download-button.svg';
import { ReactComponent as CommentIcon } from 'static/assets/svg/comments-button.svg';
import { ReactComponent as ManIcon } from 'static/assets/svg/settingsSidebar/man.svg';

import s from './style.module.scss';

const AnalyticsModal = ({ isOpen, closeModal, slug }) => {
  const { t } = useTranslation('owner');
  const [data, setData] = useState(null);

  const ref = useRef();

  useEffect(() => {
    if (isOpen) getAnalyticsEffect(slug).then((res) => setData(res));
    if (!isOpen) setData(null);
  }, [isOpen]);

  const renderFileName = (fileName) => {
    const maxLength = 64;

    const sliceName = () => fileName?.slice(0, maxLength - 3).trim() + '...';
    return fileName?.length <= maxLength ? (
      fileName
    ) : (
      <>
        {sliceName()}
        <Tooltip text={fileName} className={s.folder__tooltip} ref={ref} />
      </>
    );
  };

  return (
    <Modal
      isOpen={Boolean(isOpen && data)}
      onRequestClose={closeModal}
      contentLabel="Analytics"
      className={s.modal}
      overlayClassName={s.overlay}
      shouldCloseOnOverlayClick
    >
      <section className={s.analytics}>
        <button
          type="button"
          className={s.analytics__exit}
          onClick={closeModal}
        >
          <CloseIcon color="#7C7E88" />
        </button>
        <div>
          <div className={s.analytics__header}>
            <span>{t('analytics.analytics')}</span>
          </div>
          <div className={s.analytics__warning}>
            <span>{t('analytics.viewDetails')}</span>
            <span>{renderFileName(data?.entry?.name)}</span>
          </div>
          {data?.entry?.type == 2 && (
            <div className={s.analytics__content}>
              <div className={s.analytics__content__file}>
                <div>
                  <FileIcon />
                </div>
                <div className={s.analytics__content__file__text}>
                  <span>{data?.file_cnt}</span>
                  <span>{t('common.files')}</span>
                </div>
              </div>
              <div className={s.verticalDivider} />
              <div className={s.analytics__content__folder}>
                <div>
                  <FolderIcon />
                </div>
                <div className={s.analytics__content__folder__text}>
                  <span>{data?.folder_cnt}</span>
                  <span>{t('common.folders')}</span>
                </div>
              </div>
            </div>
          )}
          <div className={s.analytics__info}>
            <div className={s.analytics__info__item}>
              <span>
                <ViewsIcon />
              </span>
              <span>{t('rightFileMenu.meta.views')}</span>
              <span>{data?.viewed}</span>
            </div>
            <div className={s.analytics__info__item}>
              <span>
                <DownloadIcon />
              </span>
              <span>{t('rightFileMenu.meta.downloaded')}</span>
              <span>{data?.downloaded}</span>
            </div>
            <div className={s.analytics__info__item}>
              <span>
                {' '}
                <CommentIcon />
              </span>
              <span>{t('rightFileMenu.comment.comments')}</span>
              <span>{data?.commented}</span>
            </div>
          </div>

          <div className={s.divider} />

          <div className={s.analytics__users}>
            <div className={s.analytics__users__item}>
              <span>
                <ManIcon />
              </span>
              <span>{t('analytics.members')}</span>
              <span>{data?.member_cnt}</span>
            </div>
            <div className={s.analytics__users__item}>
              <span>
                <ManIcon />
              </span>
              <span>{t('analytics.guests')}</span>
              <span>{data?.guest_cnt}</span>
            </div>
          </div>
        </div>
      </section>
    </Modal>
  );
};

export default AnalyticsModal;
