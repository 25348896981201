/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import i18next from 'i18next';

import CountrySelector from 'components/GeoSecurityModal/CountrySelector';
import GeoSecurityIcon from 'components/svg/geo-security';
import TrashIcon from 'components/svg/trash-2';

import useNotification from 'utils/hooks/use-notification';
import { useTranslation } from 'react-i18next';

import {
  getGeoSecurityBySlugEffect,
  addGeoSecurityEffect,
  deleteGeoLocationEffect,
} from 'store/home/effects/file/geo-security';
import {
  getGeoListWorkspace,
  addGeoLocationWorkspace,
  deleteGeoLocationWorkspace,
} from 'store/workspace/effects';
import { setCurrentWorkspace } from 'store/main/actions';
import MainContext from 'store/main/context/main-context';

import styles from './styles.module.scss';

const newStyles = {
  control: (styles) => {
    return {
      ...styles,
      height: 44,
      borderRadius: 8,
      borderColor: '#FFFFFF66',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      padding: '8px 15px 9px 15px',
      cursor: 'pointer',
      fontFamily: "'SF Pro Text', sans-serif",
      fontSize: '14px',
      lineHeight: '20px',
      '&:hover': {
        borderColor: '#fff',
      },
    };
  },
};

const LocationTab = ({ selectedEntity }) => {
  const {
    state: { workspace },
    dispatch: mainDispatch,
  } = useContext(MainContext);
  const { t } = useTranslation('contextMenu');
  const { t: ownerT } = useTranslation('owner');
  const workspaceView = selectedEntity.isWorkspace;
  const [locations, setLocations] = useState([]);
  const [locationsWithCountryCode, setLocationsWithCountryCode] = useState([]);
  const { addNotification } = useNotification();

  const getLocationsList = () => {
    if (workspaceView) {
      getGeoListWorkspace().then((data) => {
        const countries = data.reduce((acc, country) => {
          acc[country] = country;
          return acc;
        }, {});

        setLocationsWithCountryCode(countries);
        setLocations(data);
      });
    } else {
      getGeoSecurityBySlugEffect(selectedEntity.slug).then((data) => {
        const countries = data.reduce((acc, country) => {
          acc[country] = country;
          return acc;
        }, {});

        setLocationsWithCountryCode(countries);
        setLocations(data);
      });
    }
  };

  useEffect(() => {
    getLocationsList();
  }, []);

  const addLocation = (location) => {
    const newLocations = [...locations, location];
    if (workspaceView) {
      addGeoLocationWorkspace(location).then(() => {
        addNotification(t('files.geoSecurity.addNotification'), 'success');
        getLocationsList();
        mainDispatch(
          setCurrentWorkspace({ ...workspace, securities: newLocations })
        );
      });
      return;
    }
    addGeoSecurityEffect(location, selectedEntity.slug).then(() => {
      addNotification(t('files.geoSecurity.addNotification'), 'success');
      getLocationsList();
    });
  };

  const removeLocation = (label) => {
    const newLocations = locations.filter((location) => label !== location);

    if (workspaceView) {
      deleteGeoLocationWorkspace(locationsWithCountryCode[label]).then(() => {
        addNotification(t('files.geoSecurity.deleteNotification'), 'success');
        getLocationsList();
        mainDispatch(
          setCurrentWorkspace({ ...workspace, securities: newLocations })
        );
      });
      return;
    }
    deleteGeoLocationEffect(
      selectedEntity.slug,
      locationsWithCountryCode[label]
    ).then(() => {
      addNotification(t('files.geoSecurity.deleteNotification'), 'success');
      getLocationsList();
    });
  };

  const translateLocations = () => {
    const isEnglishUppercase = (str) => /^[A-Z]{2}$/.test(str);
    const locationNameInLocalLanguage = new Intl.DisplayNames(
      [i18next.language],
      {
        type: 'region',
      }
    );
    const updatedLocationsWithCountryCode = {};
    for (const location in locationsWithCountryCode) {
      const locationKey = isEnglishUppercase(location)
        ? locationNameInLocalLanguage.of(location)
        : location;

      updatedLocationsWithCountryCode[locationKey] =
        locationsWithCountryCode[location];
    }

    setLocationsWithCountryCode(updatedLocationsWithCountryCode);
    setLocations(Object.keys(updatedLocationsWithCountryCode));
  };

  useEffect(() => {
    translateLocations();
  }, [i18next.language, locations.length]);

  return (
    <div className={styles.locationTab}>
      <h2 className={styles.label}>
        {ownerT('rightFileMenu.security.gpsPin')}
      </h2>
      <CountrySelector
        label={''}
        newStyles={newStyles}
        className={styles.countrySelector}
        addNotification={addNotification}
        selectedOptions={locations}
        onChange={addLocation}
      />
      {!!locations.length && (
        <div className={styles.list}>
          {locations.map((item) => (
            <div className={styles.locationItem} key={item}>
              <div className={styles.locationInfo}>
                <GeoSecurityIcon />
                <span>{item}</span>
              </div>
              <button
                className={styles.deleteBtn}
                onClick={() => {
                  removeLocation(item);
                }}
              >
                <TrashIcon color="#fff" />
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LocationTab;
