//Refactoring №3
import React, { useState, useRef, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import TimePicker from 'rc-time-picker';
import { useTranslation } from 'react-i18next';

import CN from 'classnames';
import moment from 'moment';

import { updateShareEffect } from 'store/home/effects/file/share-file.effect';
import useClickOutside from 'utils/hooks/use-click-outside';

import ClockIcon from 'components/svg/clock';
import TrashIcon from 'components/svg/trash';
import TriangleIcon from 'components/svg/triangle';

import styles from './style.module.scss';
import 'rc-time-picker/assets/index.css';

const timeFormat = 'hh:mm:ss a';
const dateFormat = 'YYYY-MM-DD';

const InviteLink = ({ slug, shareId, expiredTime, updateInitialData }) => {
  const { t } = useTranslation('owner');
  const [isOpen, setIsOpen] = useState(false);
  const [time, setTime] = useState(null);
  const [date, setDate] = useState(null);
  const popupRef = useRef(null);
  const popupContentRef = useRef(null);

  useEffect(() => {
    if (date && time) {
      const nextMomnet = moment(
        `${moment(date).format(dateFormat)} ${moment(time).format(timeFormat)}`,
        `${dateFormat} ${timeFormat}`
      );
      updateShareMoment(nextMomnet.unix());
    }
  }, [time, date]);

  useEffect(() => {
    if (expiredTime) {
      setDate(moment.unix(expiredTime).toDate());
      setTime(moment.unix(expiredTime));
    }
  }, [expiredTime]);

  const updateShareMoment = (expiredMoment) => {
    updateShareEffect(slug, shareId, false, false, expiredMoment).then(
      (data) => {
        updateInitialData({ shares: data.shares });
      }
    );
  };

  const toglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = (e) => {
    if (e.target !== popupContentRef.current) {
      setIsOpen(false);
    }
  };

  const shareUnlimited = () => {
    updateShareMoment(0);
    setIsOpen(false);
    setDate(null);
    setTime(null);
  };

  useClickOutside(popupRef, handleClose);

  return (
    <div className={styles.shareDate}>
      <span
        className={styles.shareContent}
        ref={popupContentRef}
        onClick={toglePopup}
      >
        {expiredTime
          ? `${t('rightFileMenu.sharing.till')} ${moment
              .unix(expiredTime)
              .format('DD/MM, hh:mm')}`
          : t('rightFileMenu.sharing.unlimited')}
        <TriangleIcon rotate={isOpen ? 0 : 180} />
      </span>
      <div
        id="sharePopup"
        ref={popupRef}
        className={CN(styles.sharePopup, isOpen && styles.sharePopupOpen)}
      >
        <p className={styles.sharePopupTitle}>
          {t('rightFileMenu.sharing.setDateAndTime')}
        </p>
        <DatePicker
          wrapperClassName={styles.dataPikerWrapper}
          placeholderText={t('rightFileMenu.sharing.expirationDate')}
          minDate={new Date()}
          dateFormat="dd.MM.yy"
          selected={date}
          onChange={setDate}
        />
        <TimePicker
          className={styles.timePicker}
          popupClassName={styles.timePickerPopup}
          placeholder={t('rightFileMenu.sharing.expirationTime')}
          inputIcon={<ClockIcon />}
          onChange={setTime}
          value={time}
          format={timeFormat}
          clearIcon={null}
          getPopupContainer={() => document.getElementById('sharePopup')}
          use12Hours
        />
        <div>
          <button onClick={shareUnlimited} className={styles.sharePopupClear}>
            <TrashIcon color="#C1C9D9" />
            <span>{t('rightFileMenu.sharing.clear')}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default InviteLink;
