//Refactoring №3
import React, { useEffect, useState, useRef } from 'react';
import { Portal } from 'react-portal';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { cancelDownloadMixFiles } from 'store/home/effects/file/download-file.effect';

import { transformSize } from 'utils/storage';
import { renderFileIcon } from 'utils/file/renderFileIcon.jsx';
import { renderFileName } from 'utils/file/renderFileName.jsx';

import { ReactComponent as Preload } from './assets/preload.svg';
import { ReactComponent as CancelIcon } from './assets/cancel.svg';
import { ReactComponent as FolderIcon } from './assets/folder.svg';
import { ReactComponent as HideIcon } from './assets/hide.svg';

import CN from 'classnames';
import style from './style.module.scss';

export default function DownloadLoader({
  className = '',
  visible,
  size,
  files,
}) {
  const [visibleContainer, setVisibleContainer] = useState(visible);
  const isSharedContainer = window.location.pathname.includes('shared');
  const dispatch = useDispatch();
  const { t } = useTranslation('owner');
  const ref = useRef();

  useEffect(() => {
    setVisibleContainer(visible);
  }, [visible]);

  const handleCancelDownload = () => {
    dispatch(cancelDownloadMixFiles());
  };

  return (
    <Portal>
      <div
        className={CN(
          style.wrapper,
          className,
          visible && style.visible,
          isSharedContainer && style.sharedContainer
        )}
      >
        <div className={style.header}>
          <span className={style.title}>
            Download {files?.length} object{files?.length > 1 ? 's' : ''}
          </span>
          <div className={style.buttons}>
            <button
              className={CN(
                style.btnDownload,
                !visibleContainer && style.rotateBtn
              )}
              onClick={() => {
                setVisibleContainer(!visibleContainer);
              }}
            >
              <HideIcon />
            </button>
            <button
              className={style.btnDownload}
              onClick={handleCancelDownload}
            >
              <CancelIcon />
            </button>
          </div>
        </div>
        {files?.length && files?.length > 1 ? (
          <div
            className={CN(
              style.container,
              visibleContainer && style.visibleContainer
            )}
          >
            <div className={style.icon}>
              <FolderIcon />
            </div>
            <div className={style.text}>
              <span className={style.subTitle}>{t('files.createArchive')}</span>
              <span className={style.subTitleSize}>{transformSize(size)}</span>
            </div>
            <div className={style.preload}>
              <Preload />
            </div>
          </div>
        ) : (
          <>
            {files?.length && (
              <div
                className={CN(
                  style.container,
                  visibleContainer && style.visibleContainer
                )}
              >
                <div className={style.icon}>{renderFileIcon(files[0])}</div>
                <div className={style.text}>
                  <span className={style.subTitle}>
                    {renderFileName(files[0].name, ref)}
                  </span>
                  <span className={style.subTitleSize}>
                    {transformSize(files[0].size)}
                  </span>
                </div>
                <div className={style.preload}>
                  <Preload />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Portal>
  );
}
