/* eslint-disable no-unused-vars */
//Refactoring №3
import React from 'react';
import { useTranslation } from 'react-i18next';

import { trimLongText } from 'utils';
import { getRole } from '../../utils';

import StorageIcon from '../Title/assets/storage';

import s from './style.module.scss';

export const WorkspaceMenuItem = ({ workspace, handleItemClick, index }) => {
  const { t } = useTranslation('common');
  const { name, current, color, role } = workspace;

  return (
    <li
      onClick={!current ? handleItemClick : undefined}
      data-test={`main-sidebar_workspace_menu_item-button[${index}]`}
    >
      <div className={s.menuItem}>
        <StorageIcon className={s.icon} color={color} />
        <div className={s.workspaceTitle}>
          <span
            className={s.workspaceName}
            data-test={`main-sidebar_workspace_menu_item-name-span`}
          >
            {trimLongText(name, 13)}
          </span>
          <span className={s.workspaceRole}>{getRole(role, t)}</span>
        </div>
      </div>
    </li>
  );
};
