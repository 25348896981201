import { useMemo } from 'react';
import ENV_TYPES from 'config/env-types';

const useEnv = (env = ENV_TYPES.dev) => {
  return useMemo(
    () => process.env.REACT_APP_ENVIROMENT === env,
    []
  );
};

export default useEnv;
