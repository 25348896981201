//Refactoring №3
import React from 'react';

import PropTypes from 'prop-types';
import cn from 'classnames';

import Plus from 'components/svg/settings/plus';

import s from './style.module.scss';

export const AddButtonTheme = {
  PRIMARY: s.addButton,
  DARK: s.addButtonDark,
};

const AddButton = ({
  className,
  onClick,
  disabled,
  theme = AddButtonTheme.PRIMARY,
}) => (
  <button
    onClick={onClick}
    className={cn(
      s.addButton,
      disabled && s.addButtonDisabled,
      theme,
      className
    )}
    disabled={disabled}
  >
    <Plus />
  </button>
);

AddButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

AddButton.defaultProps = {
  className: '',
  onClick: () => {},
  disabled: false,
};

export default AddButton;
