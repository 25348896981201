//Refactoring №3
import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { trimLongText } from 'utils';

const EditableLabel = ({
  initialValue,
  save,
  disableKeys,
  inputClass,
  className,
  labelClass,
  labelMaxLength,
  inputName,
  inputId,
  placeholder = '',
  onChangeSaver = false,
  reSet,
}) => {
  const [view, setView] = useState('label');
  const [value, setValue] = useState(initialValue);
  const [previous, setPrevious] = useState(initialValue);
  const textInput = useRef(null);

  useEffect(() => {
    reSet && setValue(initialValue);
  }, [reSet]);

  useEffect(() => {
    if (initialValue !== value) {
      setValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (view === 'text') {
      textInput.current.focus();
    }
  }, [view, textInput]);

  const keyUp = (e) => {
    if (disableKeys === true) {
      return;
    }

    if (e.key === 'Escape') {
      setValue(previous);
      setView('label');
    } else if (e.key === 'Enter') {
      setValue(e.target.value);
      setPrevious(e.target.value);
      setView('label');

      save(e.target.value);
    }
  };

  const renderLabel = () => {
    const textContent = value || placeholder;
    return (
      <span
        className={labelClass}
        onClick={() => {
          setView('text');
        }}
      >
        {labelMaxLength
          ? trimLongText(textContent, labelMaxLength)
          : textContent}
      </span>
    );
  };

  const renderInput = () => {
    return (
      <div className={className}>
        <input
          type="text"
          value={value}
          ref={textInput}
          className={inputClass}
          id={inputId}
          name={inputName}
          onChange={(e) => {
            setValue(e.target.value);
            onChangeSaver && save(e.target.value);
          }}
          onBlur={(e) => {
            setView('label');
            setPrevious(e.target.value);

            save(e.target.value);
          }}
          onKeyUp={keyUp}
        />
      </div>
    );
  };

  return view === 'label' ? renderLabel() : renderInput();
};

EditableLabel.propTypes = {
  initialValue: PropTypes.string.isRequired,
  save: PropTypes.func.isRequired,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  inputName: PropTypes.string,
  inputId: PropTypes.string,
  disableKeys: PropTypes.bool,
};

export default EditableLabel;
