import * as actions from '../actions/create-folder.actions'
import getErrorData from 'utils/request/get-error-data'
import getData from 'utils/request/get-data'

export const initialState = {
  success: {},
  error: null,
  status: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CREATE_FOLDER:
      return {
        ...state,
        error: null,
        status: true,
      }

    case actions.CREATE_FOLDER_SUCCESS:
      return {
        ...state,
        success: getData(action).data,
        status: false,
      }

    case actions.CREATE_FOLDER_FAILURE:
      return {
        ...state,
        error: getErrorData(action),
        status: false,
      }

    case actions.CREATE_FOLDER_RESET:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
