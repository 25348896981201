import React from 'react';

import RegistrationStep from '../RegistrationStep';
import RegistrationStepNewFlow from '../RegistrationStepNewFlow';

import styles from './styles.module.scss';

const RegistrationChat = () => {
  const currentPath = window.location.pathname;
  const isRegistration = currentPath === '/registration';

  return (
    <div className={styles.registrationChatWrapper}>
      {isRegistration ? <RegistrationStepNewFlow /> : <RegistrationStep />}
    </div>
  );
};

export default RegistrationChat;
