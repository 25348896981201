import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { connect } from 'react-redux';

import { selectSorterState, updateSorter, SORT_DIR } from 'features/app'
import { updateEntrySorting } from 'store/general/effects/update-entry-sorting'
import { updateCurrentPage } from 'store/home/actions/files/get-files.actions';

import Checkbox, { checkboxStyle } from 'components/Checkbox';
import SortButton from '../SortButton'

import '../../filecontainerStyle.scss';

const FileContainerHeader = ({
  onSelectAllFileHandler,
  checked,
  viewType,
  hideCheckbox,
  borderTop,
  isPublic,
  sorter,
  dispatch
}) => {

  const onChangeSortOption = (name) => {
    if (sorter.option !== name) {
      updateEntrySorting({ orderBy: name, orderDirection: sorter.dir, page: sorter.page })
      .then(() => {
        dispatch(updateCurrentPage(1))
        dispatch(updateSorter({
          initialCommit: false,
          option: name
        }))
      })
    }
  }

  const onChangeSortDir = (event) => {
    event.stopPropagation()
    const newDirection = sorter.dir === SORT_DIR.asc ? SORT_DIR.desc : SORT_DIR.asc
    updateEntrySorting({ orderBy: sorter.option, orderDirection: newDirection, page: sorter.page })
    .then(() => {
      dispatch(updateCurrentPage(1))
      dispatch(updateSorter({
        initialCommit: false,
        dir: newDirection
      }))
    })
  }

  const { t } = useTranslation('owner');
  const fileViewType = localStorage.getItem('fileViewType');
  if (fileViewType === 'small-row')
    return (
      <div
        className={cn('file-container-header-wrapper', {
          [`file-container-header-wrapper-${viewType}`]: !!viewType,
          'border-top': borderTop,
          'public-container': isPublic,
        })}
      >
        <div className="folder__checker">
          {!hideCheckbox && (
            <Checkbox
              name="select-all"
              inputStyle={checkboxStyle.LIGHT}
              className={cn('select-item-checkbox', {
                ['select-all-checked']: checked,
              })}
              onClick={onSelectAllFileHandler}
              checked={checked}
              data-test="file-container-header_select_checkbox"
            />
          )}
          <button
            onClick={() => {onChangeSortOption('name')}}
            className={cn('title-name-wrapper sorted-cell', {
              ['title-name-wrapper-checked']: checked,
            })}
            data-test="file-container-header_select_button"
          >
            <p className={cn("header-title ml-49", sorter.option === 'name' && 'header-title_active')}>
              {t('common.fileName')}
            </p>
            {sorter.option === 'name' && (
              <SortButton
                dir={sorter.dir}
                changeDirection={onChangeSortDir}
              />
            )}
          </button>
        </div>
        <div
          onClick={() => {onChangeSortOption('createdAt')}}
          className={cn("folder__checker__create ml-49 sorted-cell", sorter.option === 'createdAt' && 'header-title_active')}
        >
          {t('common.created')}
          {sorter.option === 'createdAt' && (
            <SortButton
              dir={sorter.dir}
              changeDirection={onChangeSortDir}
            />
          )}
        </div>
        <div className="folder__checker__share ml-49">{t('common.share')}</div>
        <div className="folder__checker__security ml-49">
          {t('common.security')}
        </div>
        <div
          onClick={() => {onChangeSortOption('size')}}
          className={cn("folder__checker__size ml-49 sorted-cell", sorter.option === 'size' && 'header-title_active')}
        >
          {t('common.size')}
          {sorter.option === 'size' && (
            <SortButton
              dir={sorter.dir}
              changeDirection={onChangeSortDir}
            />
          )}
        </div>
      </div>
    );
  return null;
};

FileContainerHeader.propTypes = {
  isPublic: PropTypes.bool,
  borderTop: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
};

FileContainerHeader.defaultProps = {
  isPublic: false,
  borderTop: false,
  hideCheckbox: false,
};

const mapStateToProps = (state) => ({
  sorter: selectSorterState(state),
});

export default connect(mapStateToProps)(FileContainerHeader);
