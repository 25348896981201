import React from 'react';

const TrashIcon = ({ color, className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4H3.33333H14"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M5.33337 4.0026V2.66927C5.33337 2.31565 5.47385 1.97651 5.7239 1.72646C5.97395 1.47641 6.31309 1.33594 6.66671 1.33594H9.33337C9.687 1.33594 10.0261 1.47641 10.2762 1.72646C10.5262 1.97651 10.6667 2.31565 10.6667 2.66927V4.0026M12.6667 4.0026V13.3359C12.6667 13.6896 12.5262 14.0287 12.2762 14.2787C12.0261 14.5288 11.687 14.6693 11.3334 14.6693H4.66671C4.31309 14.6693 3.97395 14.5288 3.7239 14.2787C3.47385 14.0287 3.33337 13.6896 3.33337 13.3359V4.0026H12.6667Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M9.33337 7.33594V11.3359"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
    />
    <path
      d="M6.66663 7.33594V11.3359"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </svg>
);

TrashIcon.defaultProps = {
  color: 'currentColor',
};

export default TrashIcon;
