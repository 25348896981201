// Refactoring №3
import React, { useContext, useMemo } from 'react';

import classNames from 'classnames';

import { ThemeContext, LIGHT_THEME } from 'store/context/theme';

import { ReactComponent as FolderFrontLight } from './assets/folder_front_light.svg';
import { ReactComponent as FolderBackLight } from './assets/folder_back_light.svg';
import { ReactComponent as FolderFrontDark } from './assets/folder_front_dark.svg';
import { ReactComponent as FolderBackDark } from './assets/folder_back_dark.svg';
import { ReactComponent as Mark } from './assets/folder_mark.svg';

import defaultIcon from './assets/default.png';
import default_multy from './assets/default_multy.png';
import default_big from './assets/default_big.png';

import style from './style.module.scss';

type customFolderIconPropTypes = {
  color?: string;
  viewType?: string;
  folderEntities?: number;
  folderImages?: string[];
};

const defaultImages = [defaultIcon, defaultIcon, defaultIcon];

const CustomFolderIcon = ({
  color = '',
  viewType,
  folderEntities = 0,
  folderImages = [],
}: customFolderIconPropTypes) => {
  const themeContext = useContext(ThemeContext);
  const theme = themeContext?.theme || LIGHT_THEME

  const images = useMemo(() => {
    if (folderEntities === 1 && folderImages.length === 0) {
      return [default_big];
    }
    if (folderEntities > 1 && folderImages.length === 0) {
      return [default_multy];
    }
    return [...folderImages, ...defaultImages].slice(0, 3);
  }, [folderImages.length, folderEntities]);

  const renderFolderContent = () => {
    if (folderEntities > 1 && folderImages.length === 0) {
      return (
        <div
          className={classNames(
            style.folderContent,
            viewType === 'small-row' && style.smallContent
          )}
        >
          <img src={default_multy} alt="folder content" />
        </div>
      );
    }

    switch (folderEntities) {
      case 0:
        return <div></div>;
      case 1:
      case 2:
        return (
          <div
            className={classNames(
              style.folderContent,
              viewType === 'small-row' && style.smallContent
            )}
          >
            <img src={images[0]} alt="folder content" />
          </div>
        );
      default:
        return (
          <div
            className={classNames(
              style.folderContentGroup,
              viewType === 'small-row' && style.smallContent
            )}
          >
            {images.map((image, i) => (
              <img src={image} alt="folder content" key={image + i} />
            ))}
          </div>
        );
    }
  };
  return (
    <div
      className={classNames(
        style.folderWrapper,
        viewType === 'small-row' && style.smallIcon,
        viewType === 'context-menu' && style.contextMenuIcon
      )}
    >
      {theme === LIGHT_THEME ? (
        <>
          <FolderBackLight />
          {renderFolderContent()}
          <FolderFrontLight />
        </>
      ) : (
        <>
          <FolderBackDark />
          {renderFolderContent()}
          <FolderFrontDark />
        </>
      )}

      {color && <Mark style={{ fill: color }} />}
    </div>
  );
};

export default CustomFolderIcon;
