//Refactoring №3
import React from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import CloseIcon from 'components/svg/close';
import Button, { ButtonTheme } from 'components/Button';

import style from './style.module.scss';

const ErrorModal = ({ isOpen, onClose, ...props }) => {
  const history = useHistory();
  const { t: authT } = useTranslation('auth');
  const { t: commonT } = useTranslation('common');

  const goSignUp = () => {
    history.push('/sign-up');
  };

  const handleOnClose = () => {
    onClose(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      className={style.modal}
      overlayClassName={style.overlay}
      onRequestClose={handleOnClose}
      {...props}
    >
      <section className={style.modal__content}>
        <button
          onClick={handleOnClose}
          className={style.modal__exit}
          type="button"
        >
          <CloseIcon color="#4B576C" />
        </button>
        <h1 className={style.modal__title}>{authT('signup.oops')}</h1>
        <p className={style.modal__subTitle}>
          {authT('signup.accountNotRegistered')}
        </p>
        <div className={style.modal__buttons}>
          <Button
            className={style.backBtn}
            theme={ButtonTheme.TRANSPARENT}
            onClick={handleOnClose}
          >
            {commonT('common.tryAgain')}
          </Button>
          <Button
            className={style.signUpBtn}
            theme={ButtonTheme.DARK}
            onClick={goSignUp}
          >
            {authT('signup.signup')}
          </Button>
        </div>
      </section>
    </Modal>
  );
};

ErrorModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

ErrorModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

export default ErrorModal;
