//Refactoring №3
import React from 'react';
import { useSelector } from 'react-redux';

import StorageIcon from './assets/storage'

import { cutByMaxLength } from 'utils/string'

import CN from 'classnames';
import style from './style.module.scss';

export function Title({
  className = '',
  title,
  name,
  onClick,
  color,
  owner
}) {
  const collapsed = !!useSelector((store) => store.home?.selectedEntity?.entity);

  return (
    <div
      className={CN(
        style.container,
        !title && style.noTitle,
        className,
        collapsed && style.collapsed
      )}
      onClick={onClick}
      data-test="main-sidebar_workspace_menu_open-button"
    >
      <div className={style.infoContent}>
        <div className={style.infoIcon}>
          <StorageIcon color={color} />
        </div>
        <div className={style.infoWrapper}>
          <div className={style.titleWrapper}>
            {title && (
              <span className={style.title}>
                {collapsed ? `${title[0]?.toUpperCase()}W` : title || name}
              </span>
            )}
            <span className={style.subtitle}>
              {cutByMaxLength(owner?.displayed_name || owner?.username || '', 40, 3)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
