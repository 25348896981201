import { React } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import ShareContent from 'containers/main/EntitySettingsContainer/components/InfoTab/ShareTab';

const ShareTab = ({ selectedEntity, tokenizationData }) => {
  const { t } = useTranslation('owner');

  return (
    <div className={styles.accessTab}>
      <h2 className={styles.title}>
        {t('rightFileMenu.sharing.accessPreferences')}
      </h2>
      <ShareContent
        entity={selectedEntity}
        tokenizationData={tokenizationData}
      />
    </div>
  );
};

export default ShareTab;
