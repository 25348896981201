import { useContext } from 'react';

import { addNotification } from 'store/main/actions';
import MainContext from 'store/main/context/main-context';

const useNotification = () => {
  const { dispatch: mainDispatch } = useContext(MainContext);

  const addNotificationHandler = (
    message: string,
    type: 'alert' | 'warning' | 'success' | 'default',
    hideIcon = false,
    width: string | boolean,
    merged: boolean,
    duration: number
  ) => {
    mainDispatch(
      addNotification({
        id: new Date().getTime(),
        message,
        type,
        hideIcon,
        width,
        merged,
        duration,
      })
    );
  };

  return {
    addNotification: addNotificationHandler,
  };
};

export default useNotification;
