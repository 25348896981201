//Refactoring №3
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import CN from 'classnames';

import { switchWorkspace } from 'store/workspace/effects';
import MainContext from 'store/main/context/main-context';

import { ReactComponent as AccessDenyLogo } from './assets/forbiden-logo.svg';
import { ReactComponent as ErrorWarningLogo } from './assets/error-warning-image.svg';
import CloseIcon from 'components/svg/close';
import Button from 'components/Button';

import styles from './style.module.scss';

const errorMessages = {
  DELETE_TOKENIZED_FILE: `You can't delete tokenized object`,
  CREATE_FOLDER_TOKENIZED:
    'Sorry, you can not create folder because parent folder is tokenized',
  UPLOAD_TOKENIZED:
    'Sorry, you can not upload files because parent folder is tokenized',
};

const ErrorModal = ({ onClose, errors }) => {
  const { t: commonT } = useTranslation('common');
  const { t: popupT } = useTranslation('popup');
  const { t: accountT } = useTranslation('account');
  const [nextWorkspaceData, setNextWorkspaceData] = useState({ name: 0 });
  const { code } = errors;
  const isDenyError = code === 403;
  const isDeleteFolderWithSecureFileWarning = errors.errors?.message
    ? errors.errors.message.startsWith('You cannot delete')
    : '';
  const canNotDoActionModal =
    errors?.errors?.message === errorMessages.DELETE_TOKENIZED_FILE ||
    errors?.errors?.message === errorMessages.CREATE_FOLDER_TOKENIZED ||
    errors?.errors?.message === errorMessages.UPLOAD_TOKENIZED;
  const errorText =
    errors?.errors?.errors ||
    errors?.errors?.message ||
    popupT('errorModal.accessNotAllowed');
  const addressAttachError =
    errors?.errors?.message ===
      'Public address already has been attached to you' ||
    errors?.errors?.message ===
      'Public address already has been attached to another user';

  const { state } = useContext(MainContext);

  const handleOnClose = () => {
    onClose();
    if (code === 412 && !addressAttachError) {
      window.location = '/main';
    }
  };
  useEffect(() => {
    if (code === 412) {
      setNextWorkspaceData(
        state.workspaces.find(
          (w) => w.id === errors?.errors?.data?.workspace_id
        )
      );
    }
  }, [errors]);
  const handleGoToWorkspace = (nextWorkspace) => {
    switchWorkspace(nextWorkspace.id).then((data) => {
      if (data.token) {
        localStorage.setItem('workspace', JSON.stringify(nextWorkspace));
        window.location = '/main';
      }
    });
  };

  const renderContent = () => {
    switch (errors?.errors?.message) {
      case errorMessages.DELETE_TOKENIZED_FILE:
      case errorMessages.CREATE_FOLDER_TOKENIZED:
      case errorMessages.UPLOAD_TOKENIZED:
        return (
          <div className={styles.modal}>
            <div className={styles.modalHeader}>
              {popupT('errorModal.cantDoAction')}
            </div>
            <div className={styles.modalBody}>{errors?.errors?.message}</div>
            <div className={styles.modalFooter}>
              <Button onClick={onClose}>{popupT('errorModal.continue')}</Button>
            </div>
          </div>
        );

      default:
        return (
          <div className={styles.modal__contentWrapper}>
            {isDenyError && !isDeleteFolderWithSecureFileWarning && (
              <>
                <AccessDenyLogo />
                <div className={styles.modal__title}>
                  {commonT('common.noAccess')}
                </div>
              </>
            )}
            {code === 412 ? (
              <h2 className={styles.modal__subTitle}>
                <div>{errors?.errors?.message}</div>
              </h2>
            ) : code === 412 ? (
              <div>
                <ErrorWarningLogo className={styles.warning__image} />
                <div className={styles.warning__title}>
                  {popupT('errorModal.warning')}
                </div>
                <h2 className={styles.warning__subTitle}>
                  {`${popupT('errorModal.objectInWorkspace')} ${
                    nextWorkspaceData.name
                  }. ${popupT('errorModal.switchWorkspace')}`}
                </h2>
                <div className={styles.warning__buttonsWrapper}>
                  <div
                    className={CN(
                      styles.warning__button__cancel,
                      styles.warning__button
                    )}
                    onClick={handleOnClose}
                  >
                    {commonT('common.cancel')}
                  </div>
                  <div
                    className={CN(
                      styles.warning__button__switch,
                      styles.warning__button
                    )}
                    onClick={() => handleGoToWorkspace(nextWorkspaceData)}
                  >
                    {popupT('errorModal.switch')}
                  </div>
                </div>
              </div>
            ) : isDeleteFolderWithSecureFileWarning ? (
              <div>
                <ErrorWarningLogo className={styles.warning__image} />
                <div className={styles.warning__title}>
                  {popupT('errorModal.warning')}
                </div>
                <h2 className={styles.warning__subTitle}>
                  {accountT(
                    'SafetyNet.cannotDeleteTokenizeObjectClarification'
                  )}
                </h2>
              </div>
            ) : (
              <h2 className={styles.modal__subTitle}>{errorText}</h2>
            )}
          </div>
        );
    }
  };

  return (
    <Modal
      isOpen={
        errors?.errors?.message?.length > 0 &&
        errors?.errors?.errors !==
          'You have already created the maximum number of workspaces for this type of subscription' &&
        errors?.errors?.message &&
        errors?.errors?.message !== 'Price does not match' &&
        !errors?.errors?.message?.includes(
          'You have already access to workspace'
        )
      }
      onRequestClose={handleOnClose}
      className={styles.modalWrapper}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick
    >
      <section
        className={CN(
          styles.modal__content,
          !canNotDoActionModal && styles.accessDenied
        )}
      >
        <button className={styles.modal__exit} onClick={handleOnClose}>
          <CloseIcon />
        </button>
        {renderContent()}
      </section>
    </Modal>
  );
};

ErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string,
  errors: PropTypes.object,
};

ErrorModal.defaultProps = {
  errors: {},
  redirectUrl: '',
};

export default ErrorModal;
