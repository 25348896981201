// Refactoring №3
import React, { useMemo, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { sendBugReportEffect } from 'store/home/effects/bug-report/effects';
import { handleBugReportModal } from 'features/modals/modal-slice'

import Button, { ButtonTheme } from 'components/Button';
import TextareaField from 'components/InputField/TextareaField';
import { BugReportMessageModal } from './BugReportMessageModal';
import CloseIcon from 'components/svg/close';

import style from './style.module.scss';

type BugReportModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
// type ResponceDataProps = {
//   location: string;
// };
type BugReportMessageModalProps = {
  isOpen: boolean;
  message: string;
  type?: string;
};
const maxFilesSize = 10485760; //bytes = 10MB
const maxCountOfFiles = 10;

export default function BugReportModal({
  isOpen,
}: BugReportModalProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation('sidebar');
  const [bugDescription, setBugDescription] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [isBugReportModalMessage, setIsBugReportModalMessage] =
    useState<BugReportMessageModalProps>({
      isOpen: false,
      message: '',
    });
  const [isLoading, setLoading] = useState(false);

  const DEFAULT_MODAL_MESSAGES = useMemo(
    () => ({
      bigFile: t('notifications.bigFile'),
      wrongExtension: t('notifications.wrongExtension'),
      countOfFiles: t('notifications.tooManyFiles', { maxCountOfFiles }),
      success: t('notifications.resolveProblem'),
    }),
    []
  );

  const onClose = () => {
    dispatch(handleBugReportModal(false))
  }

  const canselHandler = () => {
    onClose();
    setBugDescription('');
    setFiles([]);
    setLoading(false);
    setIsBugReportModalMessage({
      isOpen: false,
      message: '',
    });
  };
  const closeMessageModal = () => {
    if (isBugReportModalMessage.type === 'success') canselHandler();
    else
      setIsBugReportModalMessage({
        isOpen: false,
        message: '',
      });
  };
  const input = useRef(null);

  const addFilesHandler = (e: any) => {
    const filesArray: File[] = [...e.target.files];

    const arrayHasWrongType = (file: File) =>
      file.type !== 'image/jpg' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png';

    if (filesArray.reduce((acc, cur) => acc + cur.size, 0) > maxFilesSize) {
      setIsBugReportModalMessage({
        isOpen: true,
        message: DEFAULT_MODAL_MESSAGES.bigFile,
      });
      e.target.value = null;
    } else if (filesArray.some(arrayHasWrongType)) {
      setIsBugReportModalMessage({
        isOpen: true,
        message: DEFAULT_MODAL_MESSAGES.wrongExtension,
      });
      e.target.value = null;
    } else if (filesArray.length > maxCountOfFiles) {
      setIsBugReportModalMessage({
        isOpen: true,
        message: DEFAULT_MODAL_MESSAGES.countOfFiles,
      });
      e.target.value = null;
    } else setFiles(filesArray);
  };

  const addBugDescriptionHandler = (e: any) => {
    setBugDescription(e.target.value);
  };

  const sendReportHandler = async () => {
    setLoading(true);

    if (!files.length) {
      try {
        const form = new FormData();
        form.append('text', bugDescription);

        await sendBugReportEffect(form);
        setIsBugReportModalMessage({
          isOpen: true,
          message: DEFAULT_MODAL_MESSAGES.success,
          type: 'success',
        });
        onClose();
      } catch (e: any) {
        setLoading(false);
        setIsBugReportModalMessage({ isOpen: true, message: e.message });
      }
    } else {
      try {
        const form = new FormData();
        form.append('text', bugDescription);
        files.forEach((file) => {
          form.append('media', file);
        });
        await sendBugReportEffect(form);
        setLoading(false);
        setIsBugReportModalMessage({
          isOpen: true,
          message: DEFAULT_MODAL_MESSAGES.success,
          type: 'success',
        });
        onClose();
      } catch (e: any) {
        setLoading(false);
        setIsBugReportModalMessage({ isOpen: true, message: e.message });
      }
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        overlayClassName={style.overlay}
        className={style.modal}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick
        onRequestClose={canselHandler}
      >
        <div className={style.modal__wrapper}>
          <button
            type="button"
            className={style.modal__exit}
            onClick={canselHandler}
          >
            <CloseIcon color="#4B576C" />
          </button>
          <div className={style.modal__bugReportContent}>
            <h2 className={style.modal__title}>{t('footer.report')}</h2>
            <TextareaField
              name="bugDescription"
              placeholder={t('footer.reportText')}
              className={style.modal__textArea}
              onChange={addBugDescriptionHandler}
            />
            <div className={style.modal__buttons}>
              <div className={style.modal__attachFilesInput}>
                <label htmlFor="files">{t('footer.chooseFile')}</label>
                <span>
                  {!files.length ? t('footer.noFile') : files.length}{' '}
                  {t('footer.fileChoosen')}
                </span>
                <input
                  id="files"
                  ref={input}
                  type="file"
                  multiple
                  onChange={addFilesHandler}
                />
              </div>
              <Button
                className={style.modal__button}
                theme={ButtonTheme.DARK}
                onClick={sendReportHandler}
                disabled={!bugDescription || isLoading}
                showPreloader={isLoading}
              >
                {t('footer.send')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <BugReportMessageModal
        isOpen={isBugReportModalMessage.isOpen}
        message={isBugReportModalMessage.message}
        type={isBugReportModalMessage.type}
        onClose={closeMessageModal}
      />
    </>
  );
}
