/* eslint-disable no-debugger */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';
import axios from 'axios';

import { commentAddEffect, commentGetEffect } from 'store/comments/effects';
import {
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
} from 'features/app';
import useClickOutside from 'utils/hooks/use-click-outside';

import CommentItem from './comment-item';
import Button, { ButtonTheme } from 'components/Button';
import MainContext from 'store/main/context/main-context';

import styles from './style.module.css';

const CommentsTab = ({ entity, hideTitle }) => {
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);
  const { t } = useTranslation('owner');
  const {
    state: { isDeletedPage },
  } = useContext(MainContext);
  const [showInputButtons, setShowInputButtons] = useState(false);
  const [comments, setComments] = useState([]);
  const [loadingComment, setLoadingComment] = useState(false);
  const inputRef = useRef(null);
  const inputWrapperRef = useRef(null);

  useEffect(() => {
    const signal = axios.CancelToken.source();
    setComments([]);
    if (entity.slug) {
      commentGetEffect(entity.slug, entity.securities, signal.token).then(
        (data) => {
          if (data) {
            setComments(data);
          }
        }
      );
    }

    return () => signal.cancel('commentGetEffect is being canceled');
  }, [entity.securities, entity.slug]);

  const onInputFocusHandler = () => {
    setShowInputButtons(true);
  };

  const onCancelHandler = () => {
    inputRef.current.value = '';
    setShowInputButtons(false);
  };

  useClickOutside(inputWrapperRef, onCancelHandler);

  const saveComment = (comment, parentId = '') => {
    if (comment) {
      return commentAddEffect(
        entity.slug,
        comment,
        parentId,
        entity.securities
      ).then((data) => {
        if (data && Array.isArray(data)) {
          setComments(data);
        }
      });
    }
  };

  const addCommentHandler = () => {
    if (inputRef && inputRef.current) {
      const comment = inputRef.current.value.trim();
      setLoadingComment(true);
      saveComment(comment).then(() => {
        inputRef.current.value = '';
        setLoadingComment(false);
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addCommentHandler();
    }
  };

  const inputButtons = showInputButtons ? (
    <div className="entity-settings__message-input-buttons">
      <button
        type="button"
        className="link link--shrink cancel"
        onClick={onCancelHandler}
        data-test={`comment-tab_cancel`}
      >
        {t('common.cancel')}
      </button>
      <Button
        theme={ButtonTheme.DARK}
        className={styles.commentBtn}
        onClick={addCommentHandler}
        disabled={loadingComment}
        data-test={`comment-tab_comment`}
      >
        {t('rightFileMenu.comment.comment')}
      </Button>
    </div>
  ) : null;

  const commentContent =
    comments?.length > 0
      ? comments.map((comment) => (
          <CommentItem
            comment={comment}
            key={`comment-item-${comment.id}`}
            saveComment={saveComment}
          />
        ))
      : null;

  return (
    <>
      <div className="entity-settings__content">
        <section
          className={CN(
            'entity-settings__message',
            showInputButtons && 'active'
          )}
        >
          { !hideTitle && (
            <div className="entity-settings__message-name">
              <div
                className={CN('entity-settings__message-name-item', {
                  'entity-settings__message-name-active': false,
                })}
              >
                {t('rightFileMenu.comment.comments')}
                {comments && comments.length ? (
                  <div className="entity-settings__message-size">
                    {`${comments.length} 
                    ${t('rightFileMenu.comment.commentsLowerCase')}`}
                  </div>
                ) : null}
              </div>
            </div>
          )}
          <>
            {!isDeletedPage && (
              <div
                className="entity-settings__message-input-wrapper"
                ref={inputWrapperRef}
              >
                <input
                  disabled={isMultisigActivated && !isMultisigPartisipant}
                  type="text"
                  className="entity-settings__message-input"
                  placeholder={t('rightFileMenu.comment.typeComment')}
                  onFocus={onInputFocusHandler}
                  onKeyPress={handleKeyPress}
                  ref={inputRef}
                  data-test={`comment-tab_input`}
                />
                {inputButtons}
              </div>
            )}
            <div className={CN(
                hideTitle
                ? "entity-settings__message-comments-wrapper-short"
                : "entity-settings__message-comments-wrapper")}
            >
              {commentContent}
            </div>
          </>
        </section>
      </div>
    </>
  );
};

export default CommentsTab;
