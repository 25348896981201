//Refactoring №3
import React, {
  ChangeEvent,
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Contract, ethers } from 'ethers';

import { getAbi, switchNetwork } from 'store/web3';
import { selectUserInfo } from 'store/home/reducers/get-user-info.reducer';
import { getSigner } from 'store/auth/effects';

import { getCurrentChainId } from 'utils/crypto';
import useNotification from 'utils/hooks/use-notification';
import { ContractInfo } from './helper';

import Tooltip from 'components/Tooltip'
import Button, { ButtonTheme } from 'components/Button';
import InputField from 'components/InputField';
import TextareaField from 'components/InputField/TextareaField';
import InputFieldFormik from 'components/InputFieldFormik';
import {
  createMonetize,
  getMonetize,
  updateMonetize,
} from 'components/PreviewMonetize/api';
import { PreviewMonetizeModal } from 'components/PreviewMonetize';

import { ReactComponent as UploadIcon } from 'static/assets/svg/monetization-upload.svg';

import style from './style.module.scss';

type INetwork = {
  chain_id: number;
};

interface MonetizationProps {
  tokenAddress: string;
  tokenId: string;
  tokenName: string;
  chainId: number;
  networks: INetwork[];
  slug: string;
  contractInfo: ContractInfo;
}

export interface GetSigner {
  data: {
    reqCooked: {
      from: string;
      interactionId: string;
      royalties: string | number;
      tokenAddress: string;
      tokenId: string | number;
    };
    signature: string;
  };
}

const initialValues = {
  price: '',
  royalties: '',
  previewImage: { value: '' as string | any, label: undefined },
  description: '',
};

export type InitialValues = typeof initialValues;

const MonetizationNotMemo: FC<MonetizationProps> = ({
  tokenAddress,
  networks,
  tokenId,
  chainId,
  slug,
  contractInfo,
  tokenName,
}) => {
  const { t } = useTranslation('owner');
  const { addNotification } = useNotification();
  const [isExist, setExist] = useState<boolean>(false);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const formikRef = useRef<null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<InitialValues>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [startEdit, setStartEdit] = useState<boolean>(false);
  const myProfile = useSelector(selectUserInfo);
  const useMetamask = myProfile.user_public_addresses.reduce(
    (accumulator: any, currentValue: { active: any; is_coinbase: any }) => {
      if (currentValue.active && !currentValue.is_coinbase && !accumulator)
        return true;
    },
    false
  );

  const validationSchema = useMemo(
    () =>
      yup.object({
        price: yup
          .number()
          .min(1, `${t('rightFileMenu.tokenization.minAmount')}`)
          .required(),
        royalties: yup
          .number()
          .min(0)
          .max(90, `${t('rightFileMenu.tokenization.maxAmount')}`)
          .required(),
        description: yup.string().min(5).required(),
        previewImage: yup.mixed(),
      }),
    []
  );

  useEffect(() => {
    const syncFile = async () => {
      try {
        const {
          data: { data, json },
        } = await getMonetize(slug, tokenAddress, tokenId);
        initialValues.description = data.description;
        initialValues.price = data.price.toString();
        // @ts-ignore
        initialValues.previewImage.label = json.image;
        initialValues.previewImage.value = json.image;
        initialValues.royalties = contractInfo.royalty/100;
        setIsEditMode(data.price.toString() !== '0')
        setExist(true);
      } catch (e) {
        setExist(false);
        console.warn({ e });
      }
    };
    slug.length && void syncFile();
    return () => {
      setExist(false);
      for (const key in initialValues) {
        if (key === 'previewImage') {
          initialValues.previewImage.label = undefined;
          initialValues.previewImage.value = null as unknown as string;
        } else {
          // @ts-ignore
          initialValues[key] = '';
        }
      }
    };
  }, [slug, contractInfo.royalty]);

  const onFileUploadClickHandler = () => {
    if (isEditMode && !startEdit)
      return ;
    if (inputFileRef && inputFileRef.current) {
      inputFileRef.current?.click();
    }
  };

  const submitHandler = async (values: InitialValues) => {
    if (isEditMode && !startEdit) {
      setStartEdit(true)
      return
    }
    const currentChainId = await getCurrentChainId({ useMetamask });
    if (chainId !== currentChainId) {
      const res = await switchNetwork(
        networks.find((item) => item.chain_id === chainId)
      );
      if (!res) return;
    }
    setLoading(true);
    const { abi, address } = await getAbi(chainId, 'listHelper');
    const provider = new ethers.providers.Web3Provider(window.metamask, 'any');
    const signer = provider.getSigner();
    try {
      const { data } = (await getSigner({
        type: 'SListTokenCall',
        contract: 'ListHelperUpgradeable',
        contractAddress: address,
        chainId,
        req: {
          tokenAddress,
          tokenId,
          royalties: values.royalties,
        },
      }).catch((error) => {
        addNotification(error.response.data, 'alert');
      })) as GetSigner;
      const newContract = new Contract(address, abi, signer);
      const listFileToken = await newContract.listFileToken(
        data.reqCooked,
        data.signature
      );
      const confirmed = await listFileToken.wait();
      if (confirmed) {
        const body = {
          collection: tokenAddress,
          token: +tokenId,
          price: values.price,
          description: values.description,
          group: slug,
          file: values.previewImage.value,
        };
        const { data } = isExist
          ? await updateMonetize(tokenId, body)
          : await createMonetize(body);
        console.warn({ data });
        setLoading(false);
      }
      addNotification(
        t('rightFileMenu.tokenization.monetizeSuccessful'),
        'success'
      );
      setIsEditMode(true)
      setStartEdit(false)
    } catch (e) {
      setLoading(false);
      console.warn({ e });
    }
  };

  const handleChangePreview = (
    event: ChangeEvent<HTMLInputElement>,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    if (!event.target.files?.length) return;
    else {
      setFieldValue(
        'previewImage.label',
        URL.createObjectURL(event.target.files[0])
      );
      setFieldValue('previewImage.value', event.target.files[0]);
    }
  };

  const transformFileName = (name: string) => {
    if (!name) return '';
    if (name?.length < 21) return name;

    const lastDotIndex = name.lastIndexOf('.');
    const fileName = name.substring(0, lastDotIndex).trim().slice(0, 14);
    const extension = name.substring(lastDotIndex + 1).toLowerCase();

    return fileName + '...' + extension;
  };

  return (
    <>
      <PreviewMonetizeModal
        fileName={tokenName}
        contactInfo={contractInfo}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        formData={data}
        isCorrectNetwork
        isPreviewMode
      />
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={submitHandler}
        enableReinitialize
        validateOnMount={true}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div className={style.inputs__container}>
              <InputField
                className={style.inputs__input}
                label={`${t('rightFileMenu.tokenization.price')}, ($)`}
                name="price"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.price}
                placeholder="0.00001"
                ref={undefined}
                calloutText={undefined}
                inputClassName={undefined}
                errorClassName={style.error}
                error={touched.price && !!errors.price}
                onKeyPress={undefined}
                tooltip={undefined}
                step={undefined}
                min={undefined}
                customIcon={undefined}
                customEye={undefined}
                hideErrorMessage={false}
                labelClassName={undefined}
                errorIcon={undefined}
                disabled={isEditMode && !startEdit}
              />
              <InputField
                className={style.inputs__input}
                label={`${t('rightFileMenu.item.royalties')}, (%)`}
                name="royalties"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.royalties}
                placeholder="10%"
                errorClassName={style.error}
                error={touched.royalties && !!errors.royalties}
                ref={undefined}
                calloutText={undefined}
                inputClassName={undefined}
                onKeyPress={undefined}
                tooltip={undefined}
                step={undefined}
                min={undefined}
                customIcon={undefined}
                customEye={undefined}
                labelClassName={undefined}
                errorIcon={undefined}
                disabled={isEditMode && !startEdit}
              />
            </div>
            {(touched.price || touched.royalties) &&
              (errors.price || errors.royalties) && (
                <div className={style.error_message}>
                  {errors.price || errors.royalties}
                </div>
              )}
            <div className={style.imageContainer}>
              <input
                type="file"
                name="previewImage"
                accept="image/*"
                onChange={(e) => handleChangePreview(e, setFieldValue)}
                ref={inputFileRef}
                disabled={isEditMode && !startEdit}
              />
              <span>{t('rightFileMenu.tokenization.preview')}</span>
              <div
                className={style.imageContainer__image}
                onClick={onFileUploadClickHandler}
              >
                {values.previewImage?.label ? (
                  <>
                    <UploadIcon className={style.reUpload} />
                    <img
                      className={style.imageContainer__previewImage}
                      alt={`preview img`}
                      src={
                        values?.previewImage?.label
                          ? values.previewImage.label
                          : undefined
                      }
                    />
                    {/* <span className={style.fileName}>
                      {transformFileName(values?.previewImage?.value?.name)}
                    </span> */}
                  </>
                ) : (
                  <>
                    <UploadIcon className={style.uploadIcon} />
                    {/* <span>{t('rightFileMenu.tokenization.upload')}</span> */}
                  </>
                )}
              </div>
            </div>
            <div className={style.textareaContainer}>
              <span>{t('rightFileMenu.item.description')}</span>
              <InputFieldFormik
                className={style.description}
                component={TextareaField}
                name="description"
                placeholder={t('rightFileMenu.tokenization.tokenizationText')}
                withValidation={false}
                handleChange={handleChange}
                disabled={isEditMode && !startEdit}
              />
            </div>
            <div className={style.buttons}>
              <Tooltip
                overlay={<span className={style.hintText}>Price and royalties a required</span>}
                trigger="hover"
                placement='top'
                showArrow={false}
                visible={(isEditMode && !startEdit ? false : !isValid) ? undefined : false }
              >
                <div>
                  <Button
                    disabled={(!isEditMode && !isValid) || (isEditMode && !startEdit)}
                    theme={ButtonTheme.DARK}
                    name={undefined}
                    className={undefined}
                    value={undefined}
                    onClick={() => {
                      setData(values);
                      setIsOpen(true);
                    }}
                  >
                    {t('rightFileMenu.tokenization.livePreview')}
                  </Button>
                </div>
              </Tooltip>
              <Tooltip
                overlay={<span className={style.hintText}>Price and royalties a required</span>}
                trigger="hover"
                placement='top'
                showArrow={false}
                visible={(isEditMode && !startEdit ? false : !isValid) ? undefined : false }
                align={{ offset: [-43, -4] }}
              >
                <div>
                  <Button
                    type={'submit'}
                    theme={ButtonTheme.DARK}
                    name={undefined}
                    className={style.button_loader}
                    value={undefined}
                    onClick={undefined}
                    disabled={isEditMode && !startEdit ? undefined : !isValid}
                    showPreloader={loading}
                  >
                    { isEditMode && !startEdit ?  'Edit' : t('rightFileMenu.tokenization.publish') }
                  </Button>
                </div>
              </Tooltip>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export const Monetization = React.memo(MonetizationNotMemo);
