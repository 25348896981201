//Refactoring №3
import axios from 'axios';

import { API_AUTH, API_USER } from 'constants/api-urls';
import removeToken from 'utils/auth/remove-token';
import authRequest from 'utils/request/auth-request';

export const getUserInvitesEffect = async (token, workspaceid) =>
  authRequest
    .get(`${API_USER}/invites?token=${token}&id=${workspaceid}`)
    .then((response) => {
      return response?.data;
    })
    .catch((req) => {
      if (req.response.data.message === 'You can not join this workspace') {
        removeToken();
        window.location.reload();
      }
      throw req.response.data.errors;
    });

export const acceptInviteEffect = async (token) =>
  axios
    .post(`${API_AUTH}/invite/accept`, {
      token,
    })
    .then((response) => {
      return response?.data;
    })
    .catch((err) => {
      throw err;
    });
