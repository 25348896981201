/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';

import Input from '../Input';
import NeyraLaunchMessage from '../NeyraLaunchMessage';
import useAnimatedMessage from 'utils/hooks/useAnimatedMessage';

import { ReactComponent as EditIcon } from '../../assets/edit.svg';
// import { ReactComponent as LogoIcon } from '../../assets/logo-success-payment.svg';
import styles from './styles.module.scss';
import { createAvatar, createBios, getRefreshToken } from 'client-neyra';
import {
  getAccessToken,
  getRefreshToken as refreshToken,
} from 'utils/auth/get-token';
import { setToken } from 'web3-auth/lib/es5';
import uuid from 'react-uuid';
import BiosSetupInProgress from '../BiosSetupInProgress';
import { isMobile } from 'utils/mobile';

const stepsSequence = ['name', 'dateAndTime', 'city', 'activation'];

function isLatinLetters(str) {
  return /^[a-zA-Z]+$/.test(str);
}

function isValidDate(dateString) {
  if (!/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(dateString)) {
    return false;
  }

  const parts = dateString.split(' ');
  const datePart = parts[0].split('-');
  const timePart = parts[1].split(':');

  const year = parseInt(datePart[0], 10);
  const month = parseInt(datePart[1], 10) - 1;
  const day = parseInt(datePart[2], 10);
  const hour = parseInt(timePart[0], 10);
  const minute = parseInt(timePart[1], 10);

  const date = new Date(year, month, day, hour, minute);

  const minDate = new Date(1920, 0, 1);
  const maxDate = new Date();
  return date >= minDate && date <= maxDate;
}

function isValidDateTime(dateTimeString) {
  if (!/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(dateTimeString)) {
    return false;
  }

  const parts = dateTimeString.split(' ');
  const datePart = parts[0].split('-');
  const timePart = parts[1].split(':');

  const year = parseInt(datePart[0], 10);
  const month = parseInt(datePart[1], 10);
  const day = parseInt(datePart[2], 10);
  const hour = parseInt(timePart[0], 10);
  const minute = parseInt(timePart[1], 10);

  if (month < 1 || month > 12) {
    return false;
  }
  if (day < 1 || day > 31) {
    return false;
  }
  if (hour < 0 || hour > 23) {
    return false;
  }
  if (minute < 0 || minute > 59) {
    return false;
  }

  const date = new Date(year, month - 1, day, hour, minute);
  return (
    date &&
    date.getMonth() + 1 == month &&
    date.getDate() == day &&
    date.getHours() == hour &&
    date.getMinutes() == minute
  );
}

const RegistrationAfterSuccessPayment = () => {
  const { t } = useTranslation('auth');
  const { t: accountT } = useTranslation('account');
  const scrollRef = useRef(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [currentStep, setCurrentStep] = useState('initial');
  const [continueRegistration, setContinueRegistration] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [error, setError] = useState('');
  const [isSetup, setIsSetup] = useState(false);
  const [openKeyboard, setOpenKeyboard] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [previousStep, setPreviousStep] = useState('');
  const [successActive, setSuccessActive] = useState(false);

  const steps = useMemo(
    () => ({
      initial: { question: '', placeholder: '' },
      name: { question: t('initialPage.whatsYourName'), placeholder: '' },
      dateAndTime: {
        question: t('initialPage.whatsYourDateOfBirth'),
        placeholder: 'YYYY-MM-DD hh:mm',
      },
      city: {
        question: t('initialPage.whatsYourCity'),
        placeholder: '',
      },
      activation: {
        question: t('initialPage.biosActivation'),
        placeholder: '',
      },
    }),
    []
  );

  const { animatedMessage, isPrinting } = useAnimatedMessage(
    steps[currentStep].question
  );

  useEffect(() => {
    if (continueRegistration && !isPrinting) {
      setAutoScroll(false);
      scrollToBottom();
    }
  }, [isPrinting, continueRegistration]);

  useEffect(() => {
    let interval;
    if (autoScroll) {
      interval = setInterval(scrollToBottom, 500);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [autoScroll]);

  useEffect(() => {
    scrollToBottom();
  }, [currentStep]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const addAnswer = async (value) => {
    if (!value) return;
    if (currentStep === 'name' && !isLatinLetters(value)) {
      setError(t('initialPage.invalidName'));
      return;
    }
    if (
      currentStep === 'dateAndTime' &&
      (!isValidDate(value) || !isValidDateTime(value))
    ) {
      setError(t('initialPage.invalidDate'));
      return;
    }

    try {
      const updatedAnswers = { ...userAnswers };
      updatedAnswers[`${currentStep}${editMode ? '-edit' : ''}`] = {
        ...steps[currentStep],
        answer: value,
      };
      setUserAnswers(updatedAnswers);

      handleNextStep(value);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextStep = () => {
    setError('');
    if (editMode) {
      setEditMode(false);
      setPreviousStep('');
      setCurrentStep(previousStep);
    } else {
      const currentIndex = stepsSequence.indexOf(currentStep);
      if (currentIndex < stepsSequence.length - 1) {
        setCurrentStep(stepsSequence[currentIndex + 1]);
      }
    }
    setAutoScroll(true);
  };

  const handleActivate = async () => {
    const name = userAnswers['name-edit'] || userAnswers.name;
    const dateAndTime =
      userAnswers['dateAndTime-edit'] || userAnswers.dateAndTime;
    const city = userAnswers['city-edit'] || userAnswers.city;

    setIsSetup(true);
    try {
      await createAvatar({
        body: {
          name: name.answer,
          mission: ' ',
          public: true,
          code: '',
          slug: uuid(),
          llm: 1,
          image: '',
        },
      });
    } catch (error) {
      if (error?.status === 403) {
        const refresh_token = refreshToken();
        const res = await getRefreshToken({ body: { refresh_token } });
        setToken(res);
        try {
          await createAvatar({
            body: {
              name: name.answer,
              mission: ' ',
              public: true,
              code: '',
              slug: uuid(),
              llm: 1,
              image: '',
            },
          });
        } catch (error) {
          console.error(error);
        }
      }
    }

    try {
      await createBios({
        body: {
          gender: 'M',
          place_of_birth: city.answer.split('/')[0].trim(),
          date_of_birth: dateAndTime.answer,
          place_of_birth_timezone: city.answer
            .split('/')
            [city.answer.split.length - 1].trim(),
        },
      });
      setSuccessActive(true);
    } catch (error) {
      console.warn(error);
    }
  };

  const onFocus = () => {
    isMobile && setOpenKeyboard(true);
  };

  const onBlur = () => {
    isMobile && setOpenKeyboard(false);
  };

  const handleEdit = (key) => {
    setEditMode(true);
    !previousStep && setPreviousStep(currentStep);
    if (currentStep === 'activation') {
      const updatedAnswers = { ...userAnswers };
      updatedAnswers[currentStep] = {
        ...steps[currentStep],
        answer: '',
      };
      setUserAnswers(updatedAnswers);
    }
    setCurrentStep(key.replace(/-edit/g, ''));
  };

  return (
    <>
      {isSetup ? (
        <BiosSetupInProgress successActive={successActive} />
      ) : (
        <div
          className={CN(
            styles.registrationStepContainer,
            openKeyboard && styles.omitContent
          )}
        >
          <div ref={scrollRef} className={styles.scrollContent}>
            <div className={styles.shadowBlock} />
            {/* <LogoIcon /> */}
            <div>
              <NeyraLaunchMessage
                finishTyping={() => {
                  setAutoScroll(false);
                  setContinueRegistration(true);
                  setCurrentStep('name');
                }}
                hideButton={continueRegistration}
                msgType={'successPayment'}
              />
              {continueRegistration && (
                <>
                  {Object.entries(userAnswers).map(([key, value]) => (
                    <div key={key}>
                      <div
                        className={styles.question}
                        dangerouslySetInnerHTML={{
                          __html: value.question,
                        }}
                      ></div>
                      <div className={styles.answerWrapper}>
                        {value.answer && (
                          <div className={styles.answer}>
                            <button
                              className={styles.editButton}
                              onClick={() => {
                                handleEdit(key);
                              }}
                            >
                              <EditIcon />
                            </button>
                            {value.answer}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  <div
                    className={styles.question}
                    dangerouslySetInnerHTML={{
                      __html: animatedMessage,
                    }}
                  />
                </>
              )}
            </div>
          </div>
          {continueRegistration && (
            <div className={styles.footerBlock}>
              {currentStep !== 'activation' && (
                <Input
                  error={error}
                  addAnswer={addAnswer}
                  step={currentStep}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus={continueRegistration && !isMobile}
                  placeholder={steps[currentStep].placeholder}
                  onFocus={onFocus}
                  onBlur={onBlur}
                />
              )}
              {!isPrinting && (
                <div
                  className={CN(
                    styles.continueButtonWrapper,
                    currentStep !== 'activation' && styles.displayNone
                  )}
                >
                  <button
                    onClick={handleActivate}
                    className={styles.continueButton}
                  >
                    {accountT('security.activate')}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RegistrationAfterSuccessPayment;
