//Refactoring №3
import React from 'react';

import CN from 'classnames';

import { isMobile } from 'utils/mobile';

import { ReactComponent as DeleteIcon } from 'components/VaultModal/assets/delete.svg';
import InputFieldFormik from 'components/InputFieldFormik';
import EditableLabel from 'components/EditableLabel';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './style.module.scss';

const VaultTitle = ({ className, label, deleteField, name, onLabelChange }) => {
  const handleDeleteField = () => {
    deleteField(name);
  };

  const handleLabelChange = (newLabel) => {
    onLabelChange(newLabel, name);
  };

  return (
    <div className={CN(styles.wrapper, className)}>
      <EditableLabel
        className={styles.editableLabel}
        inputClass={styles.editableInpu}
        labelClass={styles.labelClass}
        labelMaxLength={isMobile ? null : 11}
        initialValue={label}
        save={handleLabelChange}
      />
      <InputFieldFormik
        name={name}
        className={styles.textInput}
        placeholder="https://"
      />
      <button
        type="button"
        className={styles.deleteBtn}
        onClick={handleDeleteField}
      >
        <DeleteIcon />
      </button>
    </div>
  );
};

export default VaultTitle;
