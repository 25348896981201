import React from 'react';
import { useHistory } from 'react-router';

import NeyraBorder from 'components/NeyraBorder';
import RegistrationChat from '../NeyraWelcome/component/RegistrationChat';
import { isMobile } from 'utils/mobile';

import styles from './styles.module.scss';

const NeyraRegistration = () => {
  const history = useHistory();
  const isRegistration = history.location.pathname === '/registration';

  return (
    <NeyraBorder
      contentClass={isMobile && isRegistration && styles.contentClass}
      containerClass={isMobile && isRegistration && styles.containerClass}
      showCircles={false}
      showBorder={false}
    >
      <RegistrationChat />
    </NeyraBorder>
  );
};

export default NeyraRegistration;
