/* eslint-disable no-unused-vars */
import React, { useMemo } from "react";

import Checkbox, { checkboxStyle } from 'components/Checkbox';
import { ReactComponent as ArchiveIcon } from 'static/assets/svg/archive.svg';
import NeyraTooltip from 'components/shared/NeyraTooltip/NeyraTooltip';
import Spinner from 'components/Spinners';

import styles from './styles.module.scss';

const PEER_TYPES = {
  channel: "PeerChannel",
  chat: "PeerChat",
  user: "PeerUser",
}

export const CHANNEL_TYPE = {
  channel: "Channel",
  user: "User",
  chat: "Chat",
}

const SidebarCheck = ({
  chat,
  ckecked,
  id,
  onChange,
  tgState,
  archiveChat,
  loading,
}) => {
  const entity = useMemo(() => {
    if (chat.peer.className === PEER_TYPES.user) {
      return tgState.users.find((user) => user.id.toString() === chat.peer.userId.toString())
    } else if (chat.peer.className === PEER_TYPES.channel) {
      return tgState.chats.find((user) => user.id.toString() === chat.peer.channelId.toString())
    } else if (chat.peer.className === PEER_TYPES.chat) {
      return tgState.chats.find((user) => user.id.toString() === chat.peer.chatId.toString())
    }
    return chat
  }, [chat])

  const renderName = () => {
    if (entity.className === CHANNEL_TYPE.channel ||
      entity.className === CHANNEL_TYPE.chat) { 
      return entity.title
    }

    if (entity?.firstName || entity?.lastName) {
      return `${entity?.firstName || ''} ${entity?.lastName || ''}`.trim();
    } else if (entity?.username) {
      return `${entity?.username}`;
    }
  }

  const handleArchiveChat = (e) => {
    e.stopPropagation();
    archiveChat(chat, entity);
  }

  return (
    <div className={styles.sidebar}>
      <div className={styles.sidebarBody}>
        <Checkbox
          inputStyle={checkboxStyle.PRIME}
          checked={ckecked}
          onClick={() => {onChange(entity, chat)}}
        />
        <p className={styles.chatName}>{ renderName() }</p>
      </div>
      { loading ? (
        <NeyraTooltip text="Loading" position="bottom">
          <button onClick={handleArchiveChat} className={styles.loadingBtn}>
            <Spinner />
          </button>
        </NeyraTooltip>
      ) : (
        <NeyraTooltip text="Archive" position="bottom">
          <button onClick={handleArchiveChat} className={styles.archiveBtn}>
            <ArchiveIcon />
          </button>
        </NeyraTooltip>

      ) }

    </div>
  )
}

export default SidebarCheck;