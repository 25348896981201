import React, { useContext } from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import renderRoutes from 'utils/routing/renderRoutes';
import routes, {
  PageLayoutRoutes,
  memberPageLayoutRoutes,
  GuestLayoutRoutes,
} from 'main-routes';
import { selectedEntityClearEffect } from 'store/home/effects/selected-entity/selected-entity-clear-effect';
import { isContainInArray } from 'utils/array/is-contain';
import MainContext from 'store/main/context/main-context';

const onCloseClassName = [
  'entry-list',
  'files-container__content-square',
  'files-container__content-square isSelectedOnLaptop',
];

import s from './style.module.scss';

const RenderedRoutes = ({
  isGuest,
  isMember,
  currentState,
  showLayout,
  hideHeader,
  middlewareFileFolderActionHandlerEffect,
}) => {
  const dispatch = useDispatch();
  const {
    state: { isAiGeneratorPage },
  } = useContext(MainContext);

  const onClick = (event) => {
    if (isContainInArray(onCloseClassName, event.target.className))
      dispatch(selectedEntityClearEffect());
  };
  const isPageRoute = location.pathname.includes('/memo/') ||
    location.pathname.includes('/memos') ||
    location.pathname.includes('/space');

  return (
    <>
      {!isGuest &&
        renderRoutes(isMember ? memberPageLayoutRoutes : PageLayoutRoutes)}
      {!hideHeader && (
        <div
          className={cn({
            'main-container': showLayout && !isAiGeneratorPage,
            'full-container': !showLayout,
            'full-height-container': hideHeader,
            [s.fullHeightContainer]: isAiGeneratorPage || isPageRoute,
          })}
          data-test={`drive_container`}
          onClick={onClick}
        >
          {!isGuest &&
            currentState &&
            renderRoutes(routes, {
              fileFolderActionHandlerEffect:
                middlewareFileFolderActionHandlerEffect,
            })}
          {isGuest &&
            renderRoutes(GuestLayoutRoutes, {
              fileFolderActionHandlerEffect:
                middlewareFileFolderActionHandlerEffect,
            })}
        </div>
      )}
    </>
  );
};

function isEqual(prev, next) {
  return (
    prev.currentState === next.currentState && prev.isGuest === next.isGuest
  );
}

export default React.memo(RenderedRoutes, isEqual);
