// Refactoring №5
export const INITIAL_FIELDS = [
  {
    label: 'Username',
    type: 'text',
    name: 'username',
    value: '',
  },
  {
    label: 'Password',
    type: 'password',
    name: 'password',
    value: '',
  },
  {
    label: 'Website',
    type: 'website',
    name: 'website',
    value: '',
  },
];

export const DEFAULT_VIEW = {
  passwordName: '',
  fields: [],
  tags: [],
};

export const DEFAULT_PASSWORD_NAME = 'NEW PASSWORD';
export const VAULT_DATA_FORMAT = 'DD.MM.YYYY';
export const MAX_TITLE_LENGTH = 32;


export type VaultItemType = {
  passwordName: string,
  tags: string[],
  fields: FieldItemType[]
}

export type FieldItemType = {
  label: string,
  name: string,
  type: string,
  value: string
}