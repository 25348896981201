import React from 'react';
import Progress from 'containers/auth/NeyraChat/components/ConnectRingProgress';
import { ReactComponent as FigureIcon } from '../../assets/figure.svg';
import styles from './styles.module.scss';

import CN from 'classnames';

const BiosFigure = ({ text, revers }) => {
  return (
    <div className={CN(styles.container, revers && styles.revers)}>
      <FigureIcon />
      <div className={styles.block}>
        <Progress />
        <div className={styles.text}>{text}</div>
        <Progress />
      </div>
    </div>
  );
};

export default BiosFigure;
