//Refactoring №3
import React from 'react';

import { ReactComponent as ActionDotsDark } from 'static/assets/svg/action-dots.svg';
import { ReactComponent as ActionDotsLight } from 'static/assets/svg/action-dots-light.svg';

import cn from 'classnames';
import s from './style.module.css';

export const contextMenuButtonStyle = {
  LIGHT: 'light',
  DARK: 'dark',
};

export default function ContextMenuButton({
  onClick,
  buttonStyle = 'dark',
  className,
}) {
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      className={cn(s.wrapper, className)}
      type="button"
      data-test={`context_menu_button`}
    >
      {buttonStyle === 'dark' ? <ActionDotsDark /> : <ActionDotsLight />}
    </button>
  );
}
