//Refactoring №3
import React, { FC, memo } from 'react';
import CN from 'classnames';

import { ReactComponent as Corner } from 'components/Button/assets/corner.svg';
import { ReactComponent as ObtuseCorner } from 'components/Button/assets/obtuseCorner.svg';
import Spinner from 'components/Spinner';

import s from '../styles.module.scss';

interface BorderedButtonProps {
  title: string;
  isDarkMode?: boolean;
  cryptoPrice?: string | number;
  onClick?: () => void;
  showLoader?: boolean;
}

const BorderedButton: FC<BorderedButtonProps> = ({
  title,
  cryptoPrice,
  onClick,
  showLoader,
  isDarkMode,
}) => {
  return (
    <div className={CN(s.bordered, isDarkMode && s.bordered_dark)}>
      <ObtuseCorner className={s.bordered_left_top} />
      <Corner className={s.bordered_right_top} />
      <button
        onClick={onClick}
        className={s.bordered_typography}
        disabled={showLoader}
      >
        {showLoader ? (
          <Spinner className={s.spinner} />
        ) : cryptoPrice ? (
          <>
            {title}
            <span className={s.bordered_crypto_price}>
              {` (${cryptoPrice})`}
            </span>
          </>
        ) : (
          title
        )}
      </button>
      <Corner className={s.bordered_right_bottom} />
      <Corner className={s.bordered_left_bottom} />
    </div>
  );
};

export const MemoBorderedButton = memo(BorderedButton);
