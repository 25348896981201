import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';

import { useHistory } from 'react-router';

import styles from './styles.module.scss';
import { ReactComponent as HumanIcon } from './assets/human.svg';
import { ReactComponent as HeartIcon } from './assets/ecg_heart.svg';
import { ReactComponent as BatteryIcon } from './assets/battery_change.svg';
import { ReactComponent as ThermometerIcon } from './assets/thermometer_gain.svg';
import { ReactComponent as CognitionIcon } from './assets/cognition.svg';

export const actionsIcons = [
  { Icon: HumanIcon, disabled: false },
  { Icon: HeartIcon, disabled: false },
  { Icon: ThermometerIcon, disabled: true },
  { Icon: BatteryIcon, disabled: true },
  { Icon: CognitionIcon, disabled: true },
];

const textContent = (t) => {
  return {
    chat: {
      title: t('chat.title'),
      subTitle: t('chat.subTitle'),
      button: t('chat.button'),
      description: [
        {
          title: t('chat.neyraLMM'),
          text: t('chat.neyraLMMText'),
        },
        {
          title: t('chat.chat'),
          text: t('chat.chatText'),
        },
        {
          title: t('chat.creativity'),
          text: t('chat.creativityText'),
        },
        {
          title: t('chat.publishAi'),
          text: t('chat.publishAiText'),
        },
      ],
    },
    drive: {
      title: t('drive.title'),
      subTitle: t('drive.subTitle'),
      button: t('drive.button'),
      description: [
        {
          title: t('drive.privacy'),
          text: t('drive.privacyText'),
        },
        {
          title: t('drive.tokenization'),
          text: t('drive.tokenizationText'),
        },
        {
          title: t('drive.multisig'),
          text: t('drive.multisigText'),
        },
      ],
    },
    bios: {
      title: t('bios.title'),
      subTitle: t('bios.subTitle'),
      button: t('bios.button'),
      description: [
        {
          title: t('bios.consciousness'),
          text: t('bios.consciousnessText'),
        },
        {
          title: t('bios.harmony'),
          text: t('bios.harmonyText'),
        },
        {
          title: t('bios.insights'),
          text: t('bios.insightsText'),
        },
        {
          title: t('bios.growth'),
          text: t('bios.growthText'),
        },
      ],
    },
    token: {
      title: t('token.title'),
      subTitle: t('token.subTitle'),
      button: t('token.button'),
      description: [
        {
          title: t('token.utility'),
          text: t('token.utilityText'),
        },
        {
          title: t('token.governance'),
          text: t('token.governanceText'),
        },
        {
          title: t('token.staking'),
          text: t('token.stakingText'),
        },
      ],
    },
  };
};

const NeyraWelcomePageSidebar = ({ type }) => {
  const { t } = useTranslation('auth');
  const content = textContent(t);
  const history = useHistory();

  const isBios = useMemo(() => type === 'bios', []);

  const redirectToRegistrationPage = () => history.push('/launch-neyra');

  return (
    <div className={CN(styles.sidebar, isBios && styles.noBackground)}>
      <h1 className={styles.blockTitle}>{content[type]?.title}</h1>
      <h2 className={styles.blockSubTitle}>{content[type]?.subTitle}</h2>
      <div className={styles.blocks}>
        {content[type]?.description?.map((item, index) => (
          <div key={index} className={styles.block}>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.text}>{item.text}</div>
          </div>
        ))}
      </div>
      {isBios && (
        <div className={styles.additionalBlock}>
          <div className={styles.actionBlock}>
            {actionsIcons.map(({ Icon, disabled }) => (
              <Icon
                key={Math.random()}
                className={disabled ? styles.disabledIcon : ''}
              />
            ))}
          </div>
          <div
            className={styles.setupBios}
            onClick={redirectToRegistrationPage}
          >
            {t('bios.setupBios')}
          </div>
        </div>
      )}
      <div
        className={styles.buttonBlock}
        onClick={() => {
          isBios ? history.push('ring') : redirectToRegistrationPage();
        }}
      >
        <div className={styles.button}>{content[type]?.button}</div>
        {type === 'token' && (
          <div className={styles.butButton}> {t('token.buy')}</div>
        )}
      </div>
    </div>
  );
};

export default NeyraWelcomePageSidebar;
