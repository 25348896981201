import * as actions from '../../actions/file/remove-file-from-trash.actions'
import getErrData from 'utils/request/get-error-data'

const initialState = {
  status: false,
  err: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FILE_REMOVE_FROM_TRASH:
      return {
        ...state,
        pending: true,
      }

    case actions.FILE_REMOVE_FROM_TRASH_SUCCESS:
      return {
        ...state,
        status: false,
        err: {},
      }

    case actions.FILE_REMOVE_FROM_TRASH_FAILURE:
      return {
        ...state,
        status: false,
        err: getErrData(action),
      }

    default:
      return state
  }
}
