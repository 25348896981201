// Refactoring №3
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectUserInfo } from 'store/home/reducers/get-user-info.reducer';
import { resetUploadingFiles } from 'store/home/actions';

import removeToken from 'utils/auth/remove-token';
import { cleanTheme } from 'utils/browser';
import { isOwnerChecker } from 'utils/roles';
import {
  cancelAllOngoingUploading,
  isUploadingInProgress,
} from 'utils/files-folders/file-upload';

import {
  handleCancelUploadModal,
  selectCancelUploadModal,
} from 'features/modals/modal-slice';
import { profileLogout } from 'features/profile/profileSlice';
import { cleanAppState } from 'features/app';

import CancelUploadModal from 'components/home/multy-upload-details/CancelUploadModal';
import PopupMenuItem from 'components/PopupMenuItem';
import ThemeSwitch from './components/ThemeToggle';
import Rule from 'components/Rule';
import { ReactComponent as LogoutIcon } from 'static/assets/svg/logout.svg';

import cn from 'classnames';
import style from './style.module.scss';

const AccountSettingsMenu = ({
  className,
  onClose,
  hideThemeSwitch,
  isOpenCancelUploadModal,
  handleCancelUploadModal,
}) => {
  const dispatch = useDispatch();
  const user = useSelector(selectUserInfo);
  const { t } = useTranslation('account');
  const { t: tWorkspace } = useTranslation('workspace');
  const isOwner = isOwnerChecker(user);

  const logOut = () => {
    dispatch(resetUploadingFiles());
    dispatch(profileLogout());
    dispatch(cleanAppState());
    cleanTheme();
    removeToken();
    window.location.href = `${window.location.origin}/connect`;
  };

  const handleCancelUpload = () => {
    handleCancelUploadModal(false);
    cancelAllOngoingUploading(dispatch);
    dispatch(resetUploadingFiles());
    logOut();
  };

  const handleContinueUpload = () => {
    handleCancelUploadModal(false);
  };

  const onLogoutClick = (e) => {
    e.stopPropagation && e.stopPropagation();

    if (isUploadingInProgress()) {
      handleCancelUploadModal(true);
    } else {
      logOut();
    }
  };

  return (
    <div className={cn(style.container, className)}>
      <ul>
        <li onClick={onClose}>
          <PopupMenuItem
            data-test={`main-sidebar_account-settings-menu_member_profile_link`}
            link="/account/profile"
          >
            {t('AIAvatar.profile')}
          </PopupMenuItem>
        </li>
        {isOwner && (
          <li onClick={onClose}>
            <PopupMenuItem
              data-test={`main-sidebar_account-settings-menu_member_workspace_link`}
              link="/workspace/settings"
            >
              {tWorkspace('common.workspace')}
            </PopupMenuItem>
          </li>
        )}
        <li onClick={onClose}>
          <PopupMenuItem
            data-test={`main-sidebar_account-settings-menu_member_settings_link`}
            link="/account/settings"
          >
            {t('AIAvatar.settings')}
          </PopupMenuItem>
        </li>
        {!hideThemeSwitch && (
          <li>
            <div className={style.themeLink}>
              <p className={style.themeLinkText}>{t('AIAvatar.theme')}</p>
              <ThemeSwitch />
            </div>
          </li>
        )}
        <Rule className={style.rule} />
        <li onClick={onLogoutClick} className={style.logOutMenuItem}>
          <PopupMenuItem
            data-test={`main-sidebar_account-settings-menu_member_logout_link`}
          >
            {t('AIAvatar.logOut')}
          </PopupMenuItem>
          <LogoutIcon className={style.logoutIcon} />
        </li>
      </ul>
      {isOpenCancelUploadModal && (
        <CancelUploadModal
          isOpen={isOpenCancelUploadModal}
          onCancel={handleCancelUpload}
          onContinue={handleContinueUpload}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isOpenCancelUploadModal: selectCancelUploadModal(state),
});

const mapDispatchToProps = {
  handleCancelUploadModal: handleCancelUploadModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettingsMenu);
