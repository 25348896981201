import { useEffect } from 'react';
import { useLocation } from 'react-router';

const getTitleAndMeta = (path) => {
  let title = '';
  let description = '';

  switch (path) {
    case '/':
      break;

    case '/chat':
      title = 'Neyra AI: Human-Powered Private AI & Legacy Creation';
      description =
        'Dive into Neyra AI, where human intellect powers private AI creation. Build your AI legacy and engage in a profit-sharing revenue project that rewards your contributions. Join us in redefining the future of personalized AI.';
      break;

    case '/bios':
      title = 'Neyra Bios: Personal AI with Biometric Data Mining';
      description =
        'Meet Neyra Bios, the forefront of personal AI systems utilizing biometric data mining. From body graphs to emotional states, create a comprehensive digital profile that truly represents you, all while ensuring the utmost privacy and security.';
      break;

    case '/ring':
    case '/order-ring':
      title = 'Neyra Ring: The Smart Digital Ring for Biometric AI Data Mining';
      description =
        'Introducing the Neyra Ring, a cutting-edge smart digital ring designed for seamless biometric AI data mining. Unlock a new dimension of personal analytics through advanced biometric tracking, including emotional and physical state monitoring, all encrypted for your privacy. Step into the future with Neyra Ring, where technology meets personal well-being.';
      break;

    default:
      title = 'Neyra Drive: Secure File Storage on Filecoin Network';
      description =
        'Explore Neyra Drive, your secure gateway to file storage, leveraging the decentralized Filecoin network. Experience unparalleled security and accessibility for your digital assets, all within an ecosystem that values privacy and control.';
  }

  return {
    title,
    description,
  };
};

const usePageTitleAndMeta = () => {
  const location = useLocation();

  useEffect(() => {
    const { title, description } = getTitleAndMeta(location.pathname);
    if (title && description && document.title !== title) {
      document.title = title;

      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        document.getElementsByTagName('head')[0].appendChild(metaDescription);
      } else {
        metaDescription.setAttribute('content', description);
      }
    }
  }, [location]);
};

export default usePageTitleAndMeta;
