// Refactoring №3
import React from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { updateDataFoutEffect } from 'store/entityMetaInformation';
import { getFilesUpdate } from 'store/home/actions/files/get-files.actions';

import { DataFourForm } from './components/DataFourForm';

import style from './style.module.scss';

export const DataFour = ({ entity }) => {
  const dispatch = useDispatch();
  const onUpdate = (option) => {
    const data = { ...option };

    updateDataFoutEffect(entity.slug, { [data.name]: data.value }).then(
      ({ data }) => {
        dispatch(
          getFilesUpdate({
            entity: { ...entity, entry_meta: data.entry_meta },
            updatedPropsList: ['entry_meta'],
          })
        );
      }
    );
  };

  return (
    <div className={style.container}>
      <DataFourForm entity={entity} onUpdate={onUpdate} />
    </div>
  );
};

DataFour.propTypes = {
  entity: PropTypes.object.isRequired,
};

DataFour.defaultProps = {};
