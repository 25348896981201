import * as actions from '../actions/save-upload-file.actions'
import getErrorData from 'utils/request/get-error-data'

export const initialState = {
  saveFilesSuccess: null,
  saveFilesError: null,
  status: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SAVE_UPLOAD_FILE:
      return {
        ...state,
        saveFilesSuccess: null,
        saveFilesError: null,
        status: true,
      }

    case actions.SAVE_UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        saveFilesSuccess: true,
        status: false,
      }

    case actions.SAVE_UPLOAD_FILE_FAIL:
      return {
        ...state,
        saveFilesError: getErrorData(action),
        status: false,
      }

    default:
      return state
  }
}
