import React from 'react';

const PreviewIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <mask
      id="a"
      width="20"
      height="20"
      x="0"
      y="0"
      maskUnits="userSpaceOnUse"
      style={{ maskType: 'alpha' }}
    >
      <path fill="currentColor" d="M0 0h20v20H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fill="currentColor"
        d="M5.021 16.167c-.333 0-.615-.115-.844-.344-.23-.23-.344-.51-.344-.844V5.021c0-.333.115-.615.344-.844.23-.23.51-.344.844-.344h9.958c.333 0 .615.115.844.344.23.23.344.51.344.844v9.958c0 .333-.115.615-.344.844-.23.23-.51.344-.844.344H5.021Zm0-.667h9.958a.508.508 0 0 0 .375-.146.508.508 0 0 0 .146-.375V6h-11v8.979c0 .153.049.278.146.375a.508.508 0 0 0 .375.146ZM10 12.917a3.834 3.834 0 0 1-2.135-.615 4.174 4.174 0 0 1-1.448-1.552 4.174 4.174 0 0 1 1.448-1.552A3.834 3.834 0 0 1 10 8.583c.792 0 1.504.205 2.135.615.632.41 1.115.927 1.448 1.552a4.174 4.174 0 0 1-1.448 1.552 3.834 3.834 0 0 1-2.135.615Zm0-.479c.625 0 1.198-.15 1.719-.448.52-.299.968-.712 1.343-1.24a4.211 4.211 0 0 0-1.343-1.24A3.394 3.394 0 0 0 10 9.062c-.625 0-1.198.15-1.719.448-.52.299-.968.712-1.343 1.24.375.528.822.941 1.343 1.24.52.299 1.094.448 1.719.448Zm0-.75a.889.889 0 0 1-.667-.282.915.915 0 0 1-.271-.656c0-.264.094-.486.282-.667A.915.915 0 0 1 10 9.812c.264 0 .486.094.667.282.18.187.271.406.271.656a.889.889 0 0 1-.282.667.915.915 0 0 1-.656.271Z"
      />
    </g>
  </svg>
);

PreviewIcon.defaultProps = {};

export default PreviewIcon;
