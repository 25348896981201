import React from "react";

import ProgressWidget from "./ProgressWidget";
import ValueWidget from './ValueWidget';
import RemixWidget from './RemixWidget';
import MetaWidget from './MetaWidget';
import ColorWidget from './ColorWidget';

import styles from './styles.module.scss';

const Meta = ({ selectedEntity }) => {

  return (
    <div className={styles.metaContainer} >
      <ProgressWidget selectedEntity={selectedEntity} />
      <ValueWidget />
      <RemixWidget />
      <MetaWidget selectedEntity={selectedEntity} />
      <ColorWidget selectedEntity={selectedEntity} />
    </div>
  )
}

export default Meta;