import USER_TYPES from 'config/user-role-types'

export const getStorageSize = (user) => {
  return !user.subscription
    ? Number(user.storage) + Number(user.space_available)
    : user.subscription.subscription.storage_size
}

export const getSubscription = (user) => user?.subscription?.subscription

export const getTariff = (user) => user?.subscription?.sub_tariff

export const isFreeSpiritPlan = (user) =>
  getSubscription(user)?.name === 'Free Spirit'

export const isFreeActive = (user) => {
  const tariff = getTariff(user)
  return tariff && tariff?.price === null
}

export const isMember = (user) => user?.role === USER_TYPES.MEMBER
