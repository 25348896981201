import React, { useState } from 'react';
import cn from 'classnames';

const CreateSharedFolder = ({ onCancel }) => {
  const [securityField, setSecurityField] = useState('password');
  const [canView, setCanView] = useState('true');
  const [canEdit, setCanEdit] = useState('true');
  const [canInvite, setCanInvite] = useState('true');

  return (
    <div className="right-menu__container create-shared-folder">
      <label className="right-menu__label">
        Folder Name
        <input
          type="text"
          name="name"
          className="right-menu__input"
          placeholder="Name your shared folder"
        />
      </label>
      <label className="right-menu__label">
        Invite Users
        <div className="right-menu__input-container">
          <input
            type="text"
            name="users"
            className="right-menu__input"
            placeholder="Type E-mail Addresses"
          />
          <button type="button" className="add-button" />
        </div>
      </label>
      <label className="right-menu__label">
        <div className="create-shared-folder__action-links">
          <button
            className={cn({
              active: securityField === 'password',
              link: true,
            })}
            type="button"
            onClick={() => setSecurityField('password')}
          >
            Add Password
          </button>
          <span className="splitter">|</span>
          <button
            className={cn({
              active: securityField === 'pin',
              link: true,
            })}
            type="button"
            onClick={() => setSecurityField('pin')}
          >
            Add Pin
          </button>
        </div>
        {securityField === 'password' && (
          <input
            type="password"
            name="password"
            className="right-menu__input"
            placeholder="Type Password"
          />
        )}
        {securityField === 'pin' && (
          <input
            type="password"
            name="pin"
            className="right-menu__input"
            placeholder="Type Pin"
          />
        )}
      </label>

      <label className="right-menu__label">
        Access Only From This IP
        <input
          type="text"
          name="access-ip"
          className="right-menu__input"
          placeholder="Type Ip Address"
        />
      </label>
      <p>
        <a href="/plan">Upgrade your account</a> to set Geo Location.
      </p>

      <label className="right-menu__label">
        Permissions
        <div className="checkbox__container">
          <div
            className={cn({
              'file__checkbox checkbox': true,
              active: canView,
            })}
            onClick={() => setCanView(!canView)}
          />
          <div className="checkbox__label">Can view</div>
          <div
            className={cn({
              'file__checkbox checkbox': true,
              active: canEdit,
            })}
            onClick={() => setCanEdit(!canEdit)}
          />
          <div className="checkbox__label">Can edit</div>
          <div
            className={cn({
              'file__checkbox checkbox': true,
              active: canInvite,
            })}
            onClick={() => setCanInvite(!canInvite)}
          />
          <div className="checkbox__label">Can invite</div>
        </div>
      </label>

      <button
        className="button button--primary text--medium text--12 text--uppercase bottom-button"
        type="button"
      >
        CREATE
      </button>
      <button className="link" type="button" onClick={onCancel}>
        Cancel
      </button>
    </div>
  );
};

export default CreateSharedFolder;
