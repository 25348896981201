export const GHOST_FILES_GET = 'GHOST_FILES_GET'
export const GHOST_FILES_REMOVE = 'GHOST_FILES_REMOVE'
export const GHOST_FILES_ADD = 'GHOST_FILES_ADD'
export const GHOST_FILES_UPDATE = 'GHOST_FILES_UPDATE'
export const GHOST_FILES_CLEAR = 'GHOST_FILES_CLEAR'
export const GHOST_FILES_GET_SUCCESS = 'GHOST_FILES_GET_SUCCESS'
export const GHOST_FILES_GET_FAILURE = 'GHOST_FILES_GET_FAILURE'
export const GHOST_FILES_LOAD_MORE_SUCCESS = 'GHOST_FILES_LOAD_MORE'

export function ghostFileGet() {
  return {
    type: GHOST_FILES_GET,
  }
}

export function ghostFileLoadMoreSuccess(data) {
  return {
    type: GHOST_FILES_LOAD_MORE_SUCCESS,
    payload: {
      data,
    },
  }
}

export function ghostFilesAdd(data) {
  return {
    type: GHOST_FILES_ADD,
    payload: {
      data,
    },
  }
}

export function ghostFilesUpdate(data) {
  return {
    type: GHOST_FILES_UPDATE,
    payload: data,
  }
}

export function ghostFileRemove(data) {
  return {
    type: GHOST_FILES_REMOVE,
    payload: {
      data,
    },
  }
}

export function ghostFileClear() {
  return {
    type: GHOST_FILES_CLEAR,
  }
}

export function ghostFileGetSuccess(data) {
  return {
    type: GHOST_FILES_GET_SUCCESS,
    payload: data,
  }
}

export function ghostFileGetFailure() {
  return {
    type: GHOST_FILES_GET_FAILURE,
  }
}
