import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

export const ResendLink = ({
  requestIsResent,
  onChangeEmailClick,
  sendRecoveryLink,
  userEmail,
}) => {
  const { t } = useTranslation('auth');
  return (
    <div className={styles.resetLinkWrapper}>
      <h2>
        {requestIsResent
          ? t('emailLogin.linkResent')
          : t('emailLogin.resetLinkSent')}
      </h2>
      <h2>{t('emailLogin.checkInbox')}</h2>
      {requestIsResent ? (
        <p>
          {t('emailLogin.resetInstructions')}{' '}
          <span onClick={onChangeEmailClick}>
            {t('emailLogin.changeEmail')}
          </span>
          .
        </p>
      ) : (
        <p>
          {t('emailLogin.resendLinkDescription')}{' '}
          <span onClick={() => sendRecoveryLink(userEmail, true)}>
            {t('emailLogin.resendLink')}
          </span>
          .
        </p>
      )}
    </div>
  );
};
