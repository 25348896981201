import * as tfaActions from '../actions/get-tfa.actions'
import getErrorData from 'utils/request/get-error-data'
import getData from 'utils/request/get-data'
import { merge } from 'ramda'

export const initialState = {
  data: null,
  error: null,
  status: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case tfaActions.GET_TFA_DATA:
      return merge(state, {
        data: null,
        error: null,
        status: true,
      })

    case tfaActions.GET_TFA_DATA_SUCCESS:
      return merge(state, {
        data: getData(action),
        status: false,
      })

    case tfaActions.GET_TFA_DATA_FAIL:
      return merge(state, {
        error: getErrorData(action),
        status: false,
      })

    default:
      return state
  }
}
