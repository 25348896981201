export const SEARCH_ADD_DATA = 'SEARCH_ADD_DATA'
export const SEARCH_CLEAR_DATA = 'SEARCH_CLEAR_DATA'

export function searchAddData(data) {
  return {
    type: SEARCH_ADD_DATA,
    payload: data,
  }
}
export function searchClearData() {
  return {
    type: SEARCH_CLEAR_DATA,
  }
}
