// New
import React, { memo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import CN from 'classnames';

import authRequest from 'utils/request/auth-request';
import { API_WORKSPACE } from 'constants/api-urls';

import Modal from 'containers/modal/Container';
import Button, { ButtonTheme } from 'components/Button';
import styles from './style.module.scss';
import { AxiosError, AxiosResponse } from 'axios';

export default memo(function RequestToJoinBlock({ slug }: { slug: string }) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [inputError, setInputError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [successfullMessage, setSuccessfullMessage] = useState('');
  const getSignText = () => {
    if (error.includes('sign up')) return 'Sign Up';
    if (error.includes('sign in')) return 'Sign In';
    return '';
  };

  const history = useHistory();
  const location = useLocation();

  const handleCloseModal = () => {
    if (successfullMessage) history.push('/');
    else setError('');
  };

  const openLink = () => {
    if (getSignText() == 'Sign Up') history.push('/sign-up');
    else history.push(location.pathname + '/login');
  };

  const sendRequest = () => {
    if (name === '') {
      setNameError(true);
    }
    if (email === '') {
      setInputError(true);
    }
    if (email && name) {
      setInputError(false);
      setNameError(false);
      //@ts-ignore
      authRequest
        .post(`${API_WORKSPACE}/request/${slug}`, {
          input: email,
          text: message,
          name: name,
        })
        .then((response: AxiosResponse) => {
          setSuccessfullMessage(response.data.message);
          return response.data;
        })
        .catch((response: AxiosError) => {
          setError(response.response?.data.message);
          throw response;
        });
    }
  };

  return (
    <div className={styles.wrapper}>
      <Modal
        isOpen={!!error || !!successfullMessage}
        title={successfullMessage ? 'Successfully' : 'Oops...'}
        className={
          successfullMessage ? styles.successfullMessage : styles.error
        }
        onClose={handleCloseModal}
        cancelButtonProps={
          getSignText() ? { onClick: handleCloseModal } : undefined
        }
        primaryButtonProps={
          getSignText()
            ? { onClick: openLink, text: getSignText() }
            : { onClick: handleCloseModal, text: 'ok' }
        }
      >
        {error || successfullMessage}
      </Modal>
      <h2 className={styles.requestTitle}>Request to join</h2>
      <div className={styles.requestContent}>
        <input
          className={CN(styles.requestContent__input, {
            [styles.requestContent__input__error]: inputError,
          })}
          placeholder="Enter email or crypto wallet"
          onChange={(el) => setEmail(el.target.value)}
        />
        {inputError && (
          <p className={styles.error}>Email or wallet is required</p>
        )}
        <input
          className={CN(styles.requestContent__input, {
            [styles.requestContent__input__error]: nameError,
          })}
          placeholder="Name"
          onChange={(el) => setName(el.target.value)}
        />
        {nameError && <p className={styles.error}>Name is required</p>}
        <textarea
          className={styles.requestContent__textfield}
          placeholder="Message"
          onChange={(el) => setMessage(el.target.value)}
        />
        <Button
          theme={ButtonTheme.DARK}
          className={styles.button__request}
          onClick={sendRequest}
        >
          Send Request
        </Button>
      </div>
    </div>
  );
});
