// Refactoring №3
import React, { useContext } from 'react';
import CN from 'classnames';

import { ThemeContext, LIGHT_THEME, DARK_THEME } from 'store/context/theme';

import { ReactComponent as SunIcon } from './assets/sun.svg';
import { ReactComponent as MoonIcon } from './assets/moon.svg';

import style from './style.module.scss';

const ThemeToggle = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const light = theme === LIGHT_THEME;

  const changeTheme = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const nextTheme = theme === LIGHT_THEME ? DARK_THEME : LIGHT_THEME;
    setTheme(nextTheme);
  };

  return (
    <div className={style.container} onClick={changeTheme}>
      {light ? (
        <SunIcon width={18} height={18} viewBox='0 0 13 13' className={CN(style.lightIcon)} />
      ) : (
        <MoonIcon width={18} height={18} viewBox='0 0 13 13' className={CN(style.darkIcon)} />
      )}
    </div>
  );
};

export default ThemeToggle;
