import React from "react";
import SidebarItem from 'containers/main/NeyraDriveContainer/components/SidebarItem';

import { ReactComponent as PlusIcon } from 'containers/auth/NeyraChat/assets/plus.svg';
import { ReactComponent as SpaceIcon } from 'containers/account/Space/assets/space.svg';

import { MODES } from 'containers/account/Space/SpaceSidebar'

const MainContent = ({ avatarArray, selectSpace, setMode, setPublishValues, INITIAL_PUBLISH_VALUES }) => {

  return (
    <>
        <SidebarItem item={{
          icon: <SpaceIcon />,
          isMain: true,
          name: 'SPACES',
          action: () => {},
          backButton: true,
          backIcon: <PlusIcon />,
          backOnClick: () => {
            setMode(MODES.edit)
            setPublishValues(INITIAL_PUBLISH_VALUES)
          }
        }}
        />
        { avatarArray.map((space) => (
          <SidebarItem
            key={space.id}
            item={{
              icon: <SpaceIcon />,
              isMain: false,
              name: space?.profile?.name,
              action: () => {
                selectSpace(space.id)
                setMode(MODES.menu)
              },
            }}
          />
        )) }
   </>
  )
}

export default MainContent;