import React, { useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import Tooltip from 'components/shared/Tooltip/tooltip';
import { ReactComponent as Diliver } from './assets/diliver.svg';
import { checkDeviceAndScroll } from 'utils/browser';

const BreadcrumbItem = ({
  crumb,
  activeCrumbId,
  pub,
  isGuest,
  showDiliver = true,
}) => {
  const ref = useRef();
  const history = useHistory();
  const children = crumb?.length
    ? crumb.map((child, index) => (
        <React.Fragment key={index}>
          {(index !== 0 || !isGuest) && showDiliver && (
            <div className="breadcrumb-divider">
              <Diliver />
            </div>
          )}
          <BreadcrumbItem
            crumb={child}
            activeCrumbId={activeCrumbId}
            key={`breadcrumb-item-${child.slug}`}
            pub={pub}
          />
        </React.Fragment>
      ))
    : null;

  const renderFileName = (fileName) => {
    const maxLength = 35;

    const sliceName = () =>
      !fileName || fileName.length < maxLength
        ? fileName
        : `${fileName.slice(0, maxLength).trim()}...`;
    return (
      <>
        {sliceName()}
        <Tooltip text={fileName} className="breadcrumb-tooltip" ref={ref} />
      </>
    );
  };

  return (
    <>
      <div
        className={`breadcrumb-item ${
          crumb.slug === activeCrumbId ? 'active' : ''
        }`}
        onMouseEnter={(el) => {
          if (ref && ref.current) {
            ref.current.style.top = el.currentTarget.offsetTop + 30 + 'px';
            ref.current.style.left = el.currentTarget.offsetLeft + 'px';
          }
        }}
        data-test={`breadcrumb-item_link[${crumb.slug}]`}
      >
        <NavLink
          onClick={checkDeviceAndScroll}
          to={`/folder/${
            history.location.pathname.includes('shared') ? 'shared/' : ''
          }${crumb.slug}`}
        >
          {renderFileName(crumb.name)}
        </NavLink>
      </div>
      {children}
    </>
  );
};

export default BreadcrumbItem;
