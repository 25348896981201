/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
// Refactoring №3
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Formik, Form } from 'formik';
import moment from 'moment';
import { isEqual } from 'lodash';

import { validationRegExp } from 'utils/validation/regexp';
import { MAX_TITLE_LENGTH } from 'components/LinkModal/constants';

import TitleInput from 'components/LinkModal/components/Title';
import Input from 'components/LinkModal/components/Input';
import AddField from 'components/LinkModal/components/AddField';
import Tags from 'components/LinkModal/components/Tags';
import Footer from 'components/LinkModal/components/Footer';
import DeleteModal from 'components/LinkModal/components/DeleteModal';

import styles from './style.module.scss';

const EditForm = ({ onCreateLinkDocument, onClose, vault, slug, loading }) => {
  const { t: pageT } = useTranslation('pages');
  const { t: contextMenuT } = useTranslation('contextMenu');
  const formRef = useRef(null);
  const scrollRef = useRef(null);
  const [title, setTitle] = useState('');
  const [fields, setFields] = useState([
    {
      label: pageT('text'),
      name: 'link',
    },
  ]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [tags, setTags] = useState([]);

  const DEFAULT_LINK_NAME = contextMenuT(
    'main.addPassword.newLink'
  ).toUpperCase();

  useEffect(() => {
    if (slug) {
      const initialValue = vault.fields.reduce((accumulator, currentValue) => {
        const value = currentValue.value;

        if (currentValue.type === 'date')
          return {
            ...accumulator,
            [currentValue.name]: currentValue.value
              ? moment(value, 'DD-MM-YYYY')
              : null,
          };

        return {
          ...accumulator,
          [currentValue.name]: value,
        };
      }, {});

      formRef.current.setValues(initialValue);
      setFields(vault.fields);
      setTitle(vault.title);
      setTags(vault.tags);
    }
  }, [slug, vault]);

  const onDeleteField = (selectedName) => {
    setIsDeleteOpen(selectedName);
  };

  const deleteField = (selectedName) => {
    const { [selectedName]: value, ...restValues } =
      formRef.current.values || {};
    setFields(fields.filter(({ name }) => selectedName !== name));
    formRef.current.setFieldValue(selectedName, '');
  };

  const isNameNusy = (name) => {
    return fields.some((field) => field.name === name);
  };

  const getFieldName = (name) => {
    let index = 0;

    while (true) {
      if (isNameNusy(index === 0 ? name : `${name}${index}`)) {
        ++index;
      } else {
        break;
      }
    }

    return `${name}${index !== 0 ? index : ''}`;
  };

  const onAddField = () => {
    if (fields.length === 20) return;

    const nextField = {
      label: pageT('text'),
      name: getFieldName('link'),
    };

    setFields([...fields, nextField]);

    setTimeout(() => {
      scrollRef?.current?.scrollTo(0, scrollRef?.current?.scrollHeight);
    }, 100);
  };

  const handleSubmit = () => {
    const formValues = formRef.current.values;

    onCreateLinkDocument({
      title: title || DEFAULT_LINK_NAME,
      fields: fields.map((field) => {
        return { ...field, value: formValues[field.name] || '' };
      }),
      tags,
    });
  };

  const checkTitleValid = (title = '') => {
    if (title.length >= MAX_TITLE_LENGTH && title.length !== 0) {
      return false;
    } else {
      return true;
    }
  };

  const changeFileName = (newName = '') => {
    newName.length < MAX_TITLE_LENGTH && setTitle(newName);
  };

  const handleCloseModal = () => {
    setIsDeleteOpen(false);
  };

  const validate = (values) => {
    const errors = {};
    fields.forEach((field) => {
      const value = values[field.name];
      if (value?.length && !validationRegExp.isLink.test(value))
        errors[field.name] = contextMenuT('main.addPassword.invalidWebsite');
    });

    return errors;
  };

  const onLabelChange = (label, id) => {
    setFields(
      fields.map((item) => (item.name === id ? { ...item, label } : item))
    );
  };

  const isLabelChnage = (isValid, currentValues) => {
    const currnetLabels = fields.map((item) => item.label);
    const initialLabels = vault.fields.map((item) => item.label);
    const initialValues = vault.fields.map((item) => item.value);
    const isCurrnetLabelsEmepy = !Object.values(currnetLabels).some(
      (value) => value
    );
    const currnetNames = fields
      .filter((field) => currnetLabels.includes(field.label))
      .map((filed) => filed.name);

    return (
      (isValid && isCurrnetLabelsEmepy) ||
      (isValid &&
        !isCurrnetLabelsEmepy &&
        !Object.values(currnetNames).some((label) => currentValues[label])) ||
      (isValid &&
        !isCurrnetLabelsEmepy &&
        isEqual(currnetLabels, initialLabels) &&
        isEqual(currentValues, initialValues))
    );
  };

  return (
    <div className={styles.body}>
      <TitleInput
        fields={fields}
        title={title}
        onChange={changeFileName}
        placeholder={pageT('title').toUpperCase()}
      />
      <Formik
        innerRef={formRef}
        initialValues={{}}
        validate={validate}
        onSubmit={handleSubmit}
      >
        {({ dirty, isValid, values }) => {
          const isDisabled =
            !checkTitleValid(title) ||
            !dirty ||
            (dirty && !isValid) ||
            isLabelChnage(isValid, values);

          return (
            <Form className={styles.form}>
              <div ref={scrollRef} className={styles.formContainer}>
                {fields.map(({ label, type, name }) => (
                  <Input
                    key={name}
                    className={styles.fieldItem}
                    label={label}
                    deleteField={onDeleteField}
                    type={type}
                    name={name}
                    onLabelChange={onLabelChange}
                  />
                ))}
              </div>
              <AddField onAddField={onAddField} />
              <Tags tags={tags} setTags={setTags} />
              <Footer
                loading={loading}
                isDisabled={isDisabled}
                onClose={onClose}
              />
            </Form>
          );
        }}
      </Formik>
      <div id="vaultForm"></div>
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={handleCloseModal}
        deleteField={deleteField}
      />
    </div>
  );
};

export default EditForm;
