export const CHANGE_USER_INFO = 'CHANGE_USER_INFO'
export const RESET_USER_INFO = 'RESET_USER_INFO'

export function changeUserInfo(data) {
  return {
    type: CHANGE_USER_INFO,
    payload: {
      ...data,
    },
  }
}

export function resetUserInfo() {
  return {
    type: RESET_USER_INFO,
  }
}
