export const FolderActionTypes = {
  setCurrentFolder: 'setCurrentFolder',
}

export const setCurrentFolder = (folder, breadcrumbs) => ({
  payload: {
    folder,
    breadcrumbs,
  },
  type: FolderActionTypes.setCurrentFolder,
})
