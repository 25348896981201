import React from 'react'

const FileIconDrag = ({ className, color }) => {
  return (
    <svg
      width="33"
      height="38"
      viewBox="0 0 33 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g filter="url(#file-icon-drag-filter0_d)">
        <mask id="file-icon-drag-path-1-inside-1" fill="white">
          <path d="M4.41638 1.75781H18.2601L27.5844 11.0635V32.242H4.41638V1.75781Z" />
        </mask>
        <path
          d="M4.41638 1.75781H18.2601L27.5844 11.0635V32.242H4.41638V1.75781Z"
          fill="url(#file-icon-drag-paint0_linear)"
        />
        <path
          d="M4.41638 1.75781V0.757812H3.41638V1.75781H4.41638ZM27.5844 32.242V33.242H28.5844V32.242H27.5844ZM4.41638 32.242H3.41638V33.242H4.41638V32.242ZM27.5844 11.0635H28.5844V10.6487L28.2908 10.3557L27.5844 11.0635ZM18.2601 1.75781L18.9665 1.05L18.6737 0.757812H18.2601V1.75781ZM27.5844 31.242H4.41638V33.242H27.5844V31.242ZM5.41638 32.242V1.75781H3.41638V32.242H5.41638ZM26.5844 11.0635V32.242H28.5844V11.0635H26.5844ZM4.41638 2.75781H18.2601V0.757812H4.41638V2.75781ZM17.5537 2.46563L26.878 11.7713L28.2908 10.3557L18.9665 1.05L17.5537 2.46563Z"
          fill="white"
          mask="url(#file-icon-drag-path-1-inside-1)"
        />
      </g>
      <path
        d="M25.9983 11.7462L17.6261 3.37402V11.7462H25.9983Z"
        fill={color}
      />
      <defs>
        <filter
          id="file-icon-drag-filter0_d"
          x="3.41638"
          y="0.757812"
          width="29.168"
          height="36.4842"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="2" dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <linearGradient
          id="file-icon-drag-paint0_linear"
          x1="16.0004"
          y1="1.75781"
          x2="16.0004"
          y2="32.242"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9DCE3" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  )
}

FileIconDrag.defaultProps = {
  color: '#7E8A9F',
}

export default FileIconDrag
