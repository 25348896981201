// utils
import authRequest from 'utils/request/auth-request'

// store
export const getTokenCostEffect = async () => {
  return authRequest
    .get(`${process.env.REACT_APP_API_PATH}/point/spent/list`)
    .then((response) => {
      return response.data
    })
    .catch((response) => {
      return response.message
    })
}