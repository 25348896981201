import React from 'react'

const Eye = ({ color }) => (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"><mask id="a" width="24" height="24" x="0" y="0" maskUnits="userSpaceOnUse" style={{maskType:'alpha'}}><path fill={color} d="M0 0h24v24H0z"/></mask><g mask="url(#a)"><path fill={color} d="M12 15.15c1.017 0 1.88-.354 2.588-1.063.708-.708 1.062-1.57 1.062-2.587 0-1.017-.354-1.88-1.062-2.588C13.879 8.204 13.017 7.85 12 7.85c-1.017 0-1.879.354-2.587 1.062-.709.709-1.063 1.571-1.063 2.588 0 1.017.354 1.879 1.063 2.587.708.709 1.57 1.063 2.587 1.063Zm0-.95c-.75 0-1.387-.263-1.912-.788A2.601 2.601 0 0 1 9.3 11.5c0-.75.263-1.388.788-1.913A2.603 2.603 0 0 1 12 8.8c.75 0 1.388.262 1.913.787.525.525.787 1.163.787 1.913s-.262 1.387-.787 1.912A2.605 2.605 0 0 1 12 14.2Zm0 3.8c-2.167 0-4.142-.587-5.925-1.762-1.783-1.175-3.117-2.755-4-4.738.883-1.983 2.217-3.563 4-4.738C7.858 5.587 9.833 5 12 5s4.142.587 5.925 1.762c1.783 1.175 3.117 2.755 4 4.738-.883 1.983-2.217 3.563-4 4.738C16.142 17.413 14.167 18 12 18Zm0-1a9.545 9.545 0 0 0 5.188-1.488A9.77 9.77 0 0 0 20.8 11.5a9.777 9.777 0 0 0-3.612-4.013A9.55 9.55 0 0 0 12 6a9.55 9.55 0 0 0-5.188 1.487A9.777 9.777 0 0 0 3.2 11.5a9.77 9.77 0 0 0 3.612 4.012A9.545 9.545 0 0 0 12 17Z"/></g></svg>
)

Eye.defaultProps = {
  color: 'currentColor',
}

export default Eye
