/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useRef, useState, useEffect } from "react";
import TimeField from 'react-simple-timefield'
import CN from 'classnames';

import { ReactComponent as DateInput } from './assets/date.svg'
import { ReactComponent as CityInput } from './assets/city.svg'
import { ReactComponent as TimeInput } from './assets/time.svg'

import { enterKeyPress } from 'utils/actions/enter-key-press';

import styles from './styles.module.scss';

const SidebarInput = ({
  isCurrentStep,
  editStep,
  setValues,
  valueForm,
  onEnterPress,
  isEditMode,
  onFieledFocus,
  name,
}) => {
  const dateRef = useRef(null);
  const cityRef = useRef(null);

  const [activeField, setActiveField] = useState('')

  const onDateChange = ({ target: { value } }) => {
    const formattedDate = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .slice(0, 10); 

      setValues((values) => ({...values, date: formattedDate }))
    }

  useEffect(() => {
    if ([1, 2, 3].includes(editStep)) {
      if (editStep === 1) {
        dateRef.current?.focus()
      } else if (editStep === 2) {
        cityRef.current?.focus()
      } else {
        document.getElementById('time-input').focus();
      }
    }
  }, [editStep])

  const handleTimeChange = ({ target: { value } }) => {
    setValues((values) => ({...values, time: value }))
  };

  const onCityChange = ({ target: { value } }) => {
    setValues((values) => ({...values, city: value }))
  }

  const onKeyDown = (e ,name) => {
    if (enterKeyPress(e)) {
      onEnterPress(name)
    }
  }

  const onFocus = (name) => {
    setActiveField(name);
    onFieledFocus(editStep, name);
  }

  return (
    <div
      className={CN(
        styles.biosInput,
        isCurrentStep && styles.biosInputCurrent,
        (isEditMode || valueForm.date || valueForm.time || valueForm.city) && styles.biosInputFinish
      )}

    >
     <div className={CN(styles.biosInputItem, activeField === 'date' && styles.biosInputItemActive)}>
        <div className={styles.biosIcon}>
          <DateInput />
        </div>
        <input
          ref={dateRef}
          placeholder="MM.DD.YYYY"
          className={styles.dateInput}
          type="text"
          onFocus={()=> {onFocus('date')}}
          onBlur={()=> {setActiveField('')}}
          onChange={onDateChange}
          onKeyDown={(e) => {onKeyDown(e, 'date')}}
          value={valueForm.date}
        />
     </div>
     <div className={CN(styles.biosInputItem, activeField === 'city' && styles.biosInputItemActive)}>
        <div className={styles.biosIcon}>
          <CityInput />
        </div>
        <input
          ref={cityRef}
          placeholder="City"
          className={styles.dateInput}
          onFocus={()=> {onFocus('city')}}
          onBlur={()=> {setActiveField('')}}
          type="text"
          onChange={onCityChange}
          onKeyDown={(e) => {onKeyDown(e, 'city')}}
          value={valueForm.city}
        />
     </div>
     <div className={CN(styles.biosInputItem, activeField === 'time' && styles.biosInputItemActive)}>
        <div className={styles.biosIcon}>
          <TimeInput />
        </div>
        <TimeField
          id="time-input"
          placeholder="00:00AM"
          onFocus={()=> {onFocus('time')}}
          onBlur={()=> {setActiveField('')}}
          onKeyDown={(e) => {onKeyDown(e, 'time')}}
          className={styles.dateInput}
          value={valueForm.time}
          onChange={handleTimeChange}
        />
     </div>
    </div>
  )
}

export default SidebarInput;