/* eslint-disable no-debugger */
import React, { useContext } from "react";
import Tooltip from 'rc-tooltip';
import { useHistory } from "react-router";
import { DARK_THEME, ThemeContext } from 'store/context/theme';

import NeyraTooltip from "components/shared/NeyraTooltip/NeyraTooltip";
import { ReactComponent as ChatIcon } from "./assets/neyra.svg";
import { ReactComponent as StudioIcon } from "./assets/studio.svg";
import { ReactComponent as DriveIcon } from "./assets/drive.svg";
import { ReactComponent as SpaceIcon } from "./assets/space.svg";
import { ReactComponent as BiosIcon } from "./assets/bios.svg";

import { validationRegExp } from 'utils/validation/regexp';

import styles from './styles.module.scss'

const START_OPACITY = 0.3;
const DELTA_OPACITY = 0.1;

const white = '245, 248, 252';
const dark = '32, 33, 36';

const ProductSelector = () => {
  const history = useHistory();
  const { theme } = useContext(ThemeContext);

  const productList = [
    {
      text: 'Spaces',
      ref: '/space',
      icon: <SpaceIcon />,
    },
    // {
    //   text: 'Bios',
    //   ref: '/bios',
    //   icon: <BiosIcon />,
    // },
    {
      text: 'Neyra',
      ref: '/chat',
      icon: <ChatIcon />,
    },
  
    {
      text: 'Studio',
      ref: '/studio',
      icon: <StudioIcon />,
    },
    {
      text: 'Drive',
      ref: '/main',
      icon: <DriveIcon />,
    },
  ]

  const menuActionClick = (link: string) => {
    if (validationRegExp.isLink.test(link)) {
      window.location.replace(link)
    } else {
      history.push(link)
    }
  }

  const renderNavigateBtn  = () => {
    switch (history.location.pathname) {
      case '/chat':
        return  <ChatIcon />;

      case '/studio':
        return  <StudioIcon />;

      case '/bios':
        return  <BiosIcon />;
  
      default:
        return  <DriveIcon />;
    }
  }

  const productListElement = () => {
    const list = productList.map((item) => item.ref).includes(history.location.pathname) ?
      productList.filter((item) => item.ref !== history.location.pathname) :
      productList.filter((item) => item.ref !== '/main') 

    return (
      <div className={styles.productList}>
        { list
          .map((product, index) => (
            <div
              style={{ backgroundColor: `rgb(${theme === DARK_THEME ? dark : white }, ${START_OPACITY + ( DELTA_OPACITY * index )})`}}
              className={styles.productItem}
              key={product.text}
              onClick={() => {menuActionClick(product.ref)}}
            >
              <div className={styles.productItemIcon}>
                { product.icon }
              </div>
              <p className={styles.productItemText}>
                { product.text }
              </p>
            </div>
          ))
        }
      </div>
    )
  }

  return (
    <Tooltip
      placement="top"
      trigger={['click']}
      overlay={productListElement()}
      showArrow={false}
      align={{ offset: [62, -32] }}
      overlayClassName={styles.tooltipOverlay}
    >
      <button className={styles.navBtn}>
        <NeyraTooltip position="top" text="Applications">
          {renderNavigateBtn()}
        </NeyraTooltip>
      </button>
    </Tooltip>
  )
}

export default ProductSelector;