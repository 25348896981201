import React from 'react'

const CloudIcon = ({ color }) => (
  <svg
    className="default"
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9992 6.72676C17.5773 3.58845 15.1479 0.713867 11.9697 0.713867C10.1331 0.713867 8.48966 1.68351 7.58402 3.18439C6.34871 2.78713 5.07856 2.95643 4.03212 3.67338C2.98625 4.38991 2.36163 5.52441 2.31106 6.76525C0.913561 7.42049 0 8.80538 0 10.327C0 10.5876 0.0256818 10.8483 0.0762879 11.1019C0.444432 12.9464 2.085 14.2853 3.97727 14.2853H15.3788C17.5927 14.2853 19.3939 12.5096 19.3939 10.327C19.3939 8.76949 18.4445 7.3636 16.9992 6.72676Z"
      fill={color}
    />
  </svg>
)

CloudIcon.defaultProps = {
  color: '#CCCEDA',
}

export default CloudIcon
