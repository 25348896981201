// Refactoring №3
import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import CN from 'classnames';

import InputFieldFormik from 'components/InputFieldFormik';
import SelectFieldFormik from 'components/SelectFieldFormik';

import style from './style.module.scss';

export const FormItem = ({
  className,
  label,
  name,
  prefix,
  postfix,
  type,
  options,
  onChnage,
  icon,
  ...rest
}) => {
  const { t } = useTranslation('owner');
  const newStyles = {
    control: (styles) => {
      return {
        ...styles,
        height: 24,
        minHeight: 24,
        borderRadius: 0,
        borderColor: 'transparent',
        boxShadow: 'none',
        padding: '0',
        cursor: 'pointer',
        fontFamily: "'SF Pro Text', sans-serif",
        fontSize: '12px',
        lineHeight: '24px',
        '&:hover': {
          borderColor: 'transparent',
        },
      };
    },
    valueContainer: (styles) => ({
      ...styles,
      justifyContent: 'end',
      fontSize: '12px',
      lineHeight: '24px',
    }),
    singleValue: (styles) => ({
      ...styles,
      fontSize: '12px',
      lineHeight: '24px',
    }),
  };

  const selectOnChange = (option) => {
    onChnage({ name, value: option.value });
  };

  return (
    <div className={CN(style.container, className)}>
      <div className={style.info}>
        { icon && icon }
        <label className={style.label}>{label}</label>
      </div>
      {type === 'select' ? (
        <SelectFieldFormik
          placeholder={t('rightFileMenu.meta.notSelected')}
          name={name}
          isSearchable={false}
          className={style.selectInput}
          options={options}
          handChange={selectOnChange}
          newStyles={newStyles}
          hideLabel
        />
      ) : (
        <div
          className={CN(
            style.inputWrapper,
            prefix && style.prefix,
            postfix && style.postfix
          )}
        >
          {prefix && <span>{prefix}</span>}
          <InputFieldFormik
            name={name}
            onBlur={onChnage}
            withValidation={false}
            className={style.input}
            type="number"
            min="0"
            {...rest}
          />
          {postfix && <span>{postfix}</span>}
        </div>
      )}
    </div>
  );
};

FormItem.propTypes = {
  label: PropTypes.string,
  prefix: PropTypes.string,
  postfix: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  options: PropTypes.array,
  onChnage: PropTypes.func,
};

FormItem.defaultProps = {
  label: '',
  prefix: '',
  postfix: '',
  className: '',
  type: 'input',
  options: [],
  onChnage: () => {},
};
