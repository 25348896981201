import moment from 'moment';

const format = 'MMM DD, YYYY';

export const extendEventsByDate = (
  prevBlocks = {},
  items = [],
  date = null
) => {
  const nextData = { ...prevBlocks };
  const momentDate = date ? moment(date) : moment();

  items.forEach((item) => {
    const created = item.created_at ? moment.unix(item.created_at) : moment();
    if (created.isSameOrBefore(momentDate)) {
      const dayKey = created.format(format);

      if (nextData[dayKey]) {
        nextData[dayKey].push(item);
      } else {
        nextData[dayKey] = [item];
      }
    }
  });
  return nextData;
};

export const filterEventsByDate = (events = [], date = null) => {
  const initialDate = new Date(date);
  const filered = events.filter((event) => {
    const created = new Date(Number(event.created_at) * 1000);
    return initialDate.toDateString() === created.toDateString();
  });
  return filered;
};
