import React from 'react';
import styles from './styles.module.scss';

const ConnectRingProgress = () => {
  return (
    <div className={styles.container}>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
      <div className={styles.circle}></div>
    </div>
  );
};

export default ConnectRingProgress;
