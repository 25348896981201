import * as actions from '../actions/pending-files-folders.actions'
import removeIfEqual from 'utils/array/remove-if-equal'
import { merge } from 'ramda'
import getArrayFromData from 'utils/array/get-array-from-data'

const initialState = {
  files: [],
  folders: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.PENDING_FILES_ADD:
      return merge(state, {
        files: [...state.files, ...getArrayFromData(action.payload)],
      })

    case actions.PENDING_FILES_REMOVE:
      return merge(state, {
        files: removeIfEqual(
          state.files,
          getArrayFromData(action.payload),
          'id'
        ),
      })

    case actions.PENDING_FOLDERS_ADD:
      return merge(state, {
        folders: [...state.folders, ...getArrayFromData(action.payload)],
      })

    case actions.PENDING_FOLDERS_REMOVE:
      return merge(state, {
        folders: removeIfEqual(
          state.folders,
          getArrayFromData(action.payload),
          'id'
        ),
      })

    case actions.PENDING_CLEAR:
      return merge(state, {
        files: [],
        folders: [],
      })

    default:
      return state
  }
}
