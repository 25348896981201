/* eslint-disable no-debugger */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { commentAddEffect, commentGetEffect } from 'store/comments/effects';
import {
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
} from 'features/app';

import CommentItem from './comment-item';

import styles from './styles.module.scss';

const CommentsTab = ({ entity }) => {
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);
  const { t } = useTranslation('owner');

  const [comments, setComments] = useState([]);
  const [loadingComment, setLoadingComment] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    const signal = axios.CancelToken.source();
    setComments([]);
    if (entity.slug) {
      commentGetEffect(entity.slug, entity.securities, signal.token).then(
        (data) => {
          if (data) {
            setComments(data);
          }
        }
      );
    }

    return () => signal.cancel('commentGetEffect is being canceled');
  }, [entity.securities, entity.slug]);

  const saveComment = (comment, parentId = '') => {
    if (comment) {
      return commentAddEffect(
        entity.slug,
        comment,
        parentId,
        entity.securities
      ).then((data) => {
        if (data && Array.isArray(data)) {
          setComments(data);
        }
      });
    }
  };

  const addCommentHandler = () => {
    if (inputRef && inputRef.current) {
      const comment = inputRef.current.value.trim();
      setLoadingComment(true);
      saveComment(comment).then(() => {
        inputRef.current.value = '';
        setLoadingComment(false);
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addCommentHandler();
    }
  };

  const commentContent =
    comments?.length > 0
      ? comments.map((comment) => (
          <CommentItem
            comment={comment}
            key={`comment-item-${comment.id}`}
            saveComment={saveComment}
          />
        ))
      : null;

  return (
    <section className={styles.commentsWrapper}>
      <h2 className={styles.commentsWrapper__title}>
        {t('rightFileMenu.comment.comments')}
      </h2>

      <input
        disabled={
          isMultisigActivated && !isMultisigPartisipant && loadingComment
        }
        type="text"
        className={styles.commentsWrapper__input}
        placeholder={t('rightFileMenu.comment.typeComment')}
        onKeyPress={handleKeyPress}
        onSubmit={addCommentHandler}
        ref={inputRef}
        data-test={`comment-tab_input`}
      />
      <div className={styles.commentsWrapper__list}>{commentContent}</div>
    </section>
  );
};

export default CommentsTab;
