import authRequest from '../request/auth-request';
import web3 from 'web3';
import { getCurrentChainId } from './index';

interface NetworkCheckerProps {
  neededChainId: string;
  withRequest?: boolean;
  isNetworkCorrectLocal?:(data:NetworkCheckerProps)=>Promise<{
    isCorrect: boolean;
    networkList: Network[];
  }>;
}

interface Network {
  id: number;
  name: string;
  chain_id: number;
  symbol: string;
  rpc_url: string;
  file_tokenization_factory: null;
  note_tokenization_factory: null;
  workspace_access_factory: null;
  nft_subscription_v1: null;
  nft_astro: null;
  shop_simple: null;
  nft_metadata_url_file: null;
  smart_contracts: any[];
}

interface MetaMaskError {
  code: number;
  message: string;
}

interface NetworkResponse {
  data: {
    data: Array<Network>;
  };
}

const cachedList: Map<string, Network[]> = new Map(null);

export const isNetworkCorrect = async ({
  neededChainId,
  withRequest = true,
  isNetworkCorrectLocal,
}: NetworkCheckerProps): Promise<{
  isCorrect: boolean;
  networkList: Network[];
}> => {
  const parsedCurrentChainId = await getCurrentChainId();

  if (!withRequest) {
    return {
      isCorrect: parsedCurrentChainId === parseInt(neededChainId),
      networkList: cachedList.get('networkList') ?? [],
    };
  }

  if (!cachedList.has('networkList')) {
    if(isNetworkCorrectLocal){
      const { networkList } = await isNetworkCorrectLocal({neededChainId});
      cachedList.set('networkList', networkList);
    }
    else{
      const {
        data: { data: networkList },
      }: NetworkResponse =
        // @ts-ignore
        await authRequest.get(`${process.env.REACT_APP_API_PATH}/net/list`);
      cachedList.set('networkList', networkList);
    }
  }
  const networkList = cachedList.get('networkList') ?? [];

  if (!neededChainId) {
    return {
      isCorrect: !!networkList?.find(
        (network) => network.chain_id === parsedCurrentChainId
      ),
      networkList,
    };
  }

  return {
    isCorrect: parsedCurrentChainId === parseInt(neededChainId),
    networkList,
  };
};

export const connectNetwork = async ({
  neededChainId,
  isNetworkCorrectLocal
}: NetworkCheckerProps) => {
  const { isCorrect, networkList } = await isNetworkCorrect({
            neededChainId,
            isNetworkCorrectLocal
          });

  if (isCorrect) return isCorrect;

  const neededNetwork = networkList.find(
    (network) => network.chain_id === parseInt(neededChainId)
  );

  if (!neededNetwork) return false;

  const hexChainId = web3.utils.toHex(neededNetwork.chain_id);
  try {
    await window.metamask.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: hexChainId }],
    });
    return true;
  } catch (err) {
    // @ts-ignore
    const error: MetaMaskError = err;
        if (error.code === 4902 || error.code === -32603) {
      try {
        await window.metamask.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: hexChainId,
              chainName: neededNetwork.name,
              rpcUrls: [neededNetwork.rpc_url],
              nativeCurrency: {
                name: neededNetwork.name,
                symbol: neededNetwork.symbol,
                decimals: 18,
              },
            },
          ],
        });
        await window.metamask.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: hexChainId }],
        });
        return true;
      } catch (err) {
        //@ts-ignore
        const error: MetaMaskError = err;
        console.warn(error.message);
        return false;
      }
    } else {
      return false;
    }
  }
};
