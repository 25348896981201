import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CarReader } from '@ipld/car';
import { downloadFile, getCidLevelByFileSize } from 'gdgateway-client/lib/es5';

import { getDownloadOTT } from 'store/home/effects';
import { getFileCids } from 'store/home/effects/file/get-file-cid';

import { sendFileViewStatistic } from 'store/home/effects/statistic/file-statistic-effect';
import { actionsType } from 'config/actions-options';

const TxtViewer = ({ file }: any) => {
  useEffect(() => {
    getPreview();
  }, []);

  const getPreview = async () => {
    let cidData;

    const {
      data: {
        jwt_ott,
        //@ts-ignore
        user_tokens: { token: oneTimeToken },
        gateway,
        upload_chunk_size,
      },
    } = await getDownloadOTT([{ slug: file.slug }], actionsType.VIEWED);

    if (file.is_on_storage_provider) {
      const level = getCidLevelByFileSize(file);
      cidData = await getFileCids({ slug: file.slug, level });
    }
    await sendFileViewStatistic(file.slug);
    //@ts-ignore
    const blob = await downloadFile({
      file,
      oneTimeToken,
      jwtOneTimeToken: jwt_ott,
      endpoint: gateway.url,
      isEncrypted: false,
      carReader: CarReader,
      uploadChunkSize:
        upload_chunk_size[file.slug] || gateway.upload_chunk_size,
      cidData,
    });
    if (blob) {
      const realBlob = new Blob([blob]);
      const text = await realBlob.text();
      displayPreview(text);
    }
  };

  const displayPreview = (text: any) => {
    const previewContainer = document.getElementById('preview-container');
    const lines = text.split('\n').slice(0, 10);
    const previewText = lines.join('<br>');
    //@ts-ignore
    previewContainer.innerHTML = previewText;
  };

  return (
    <div className="file-view__txt">
      <div id="preview-container"></div>
    </div>
  );
};

export default TxtViewer;
