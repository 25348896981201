//Refactoring №3
import React, { useState, useRef, useCallback } from 'react';
import cn from 'classnames';

import InputField from 'components/InputField';
import Button, { ButtonTheme } from 'components/Button';
import { ReactComponent as KeyIcon } from 'static/assets/svg/key.svg';

import { loadFileAsText } from 'utils/download';
import styles from './styles.module.scss';

const KeySelector = ({
  setKey,
  hasError,
  className = '',
  placeholder = '',
}) => {
  const [value, setValue] = useState('');
  const selectRef = useRef(null);

  const onSelect = useCallback(() => {
    selectRef.current.click();
  }, [selectRef]);

  const handleActivate = useCallback(
    (data) => {
      setValue(data.target.files[0].name);
      loadFileAsText(data).then((data) => {
        setKey(data.target.result);
        selectRef.current.value = '';
      });
    },
    [setKey]
  );

  return (
    <div className={styles.wrapper}>
      <KeyIcon className={styles.keyIcon} />
      <InputField
        className={cn(styles.inputWrapper, className)}
        value={value}
        placeholder={placeholder}
        disabled
        withValidation={true}
        error={hasError && 'You uploaded the wrong private key certificate'}
      />
      <input
        type="file"
        accept=".gdkey"
        ref={selectRef}
        onChange={handleActivate}
        style={{ display: 'none' }}
      />
      <Button
        theme={ButtonTheme.DARK}
        className={styles.uploadBtn}
        onClick={onSelect}
      >
        Upload
      </Button>
    </div>
  );
};

export default KeySelector;
