//Refactoring №2
import React from 'react';

class AppItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const logoSrc = this.props.logo;
    const text = this.props.name;
    return (
      <div className="more-apps-item__wrapper">
        <img className="more-apps-item__logo" src={logoSrc} alt={text} />
        <span className="more-apps-item__name">{text}</span>
        <span className="more-apps-item__button button button--primary text--medium text--12">
          Run
        </span>
      </div>
    );
  }
}

export default AppItem;
