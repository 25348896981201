// Refactoring №3
import React from 'react';

import CN from 'classnames';
import styles from './styles.module.scss';

const circles = [1, 2, 3, 4];

const BorderWithDrons = ({ active = true, children, containerClass }) => {
  return (
    <div
      className={CN(styles.content, !active && styles.opacity, containerClass)}
    >
      {active &&
        circles.map((circle) => (
          <div
            key={circle}
            className={`${styles.cube} ${styles[`circle${circle}`]}`}
          >
            <div className={`${styles.circle}`}></div>
          </div>
        ))}
      {children}
    </div>
  );
};

export default BorderWithDrons;
