//New
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSetChain } from '@web3-onboard/react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserInfoEffect } from 'store/home/effects';
import { getPubNftFileEffect } from 'store/home/effects/shared-by-link/effects';
import { ThemeProvider } from 'store/context/theme';

import Preloader from 'components/shared/Preloader';
import { PreviewMonetizeModal } from 'components/PreviewMonetize';

import { sharedByLinkCheckUser } from 'containers/auth/login-crypto';
import ErrorModal from 'containers/auth/SignIn/ErrorModal';
import SharedFolder from 'containers/folder/shared-folder';
import { getContractInfo } from 'containers/main/EntitySettingsContainer/components/Web3Tab/components/Tokenization/components/Monetization/helper';
import NotificationBubble from 'containers/notifications/notification-bubble';

import { selectProfileLogin } from 'features/profile/profileSlice';

import { connectNetwork } from 'utils/crypto/networkSetter';
import AccessDenied from './access-denied';
import { AuthWalletsPage } from './wallets-auth-page';
import SharedNftFile from '../file/shared-by-link/shared-nft-file';
import { MainContextWrapper } from 'store/context';

type SharedByLinkFlow = {
  match: any;
  history: any;
};

export type File = {
  type: number;
  extension: string;
  slug: string;
  name: string;
  size: number;
  is_on_storage_provider?: boolean;
};

export type ContactDetailsByURi = {
  name: string;
  description: string;
  image: string;
  tokenId: string | number;
};

export default function SharedByLinkNftFlow({
  match,
  history,
}: SharedByLinkFlow) {
  const [{ connectedChain }] = useSetChain();
  const [contactInfo, setContactInfo] = useState<ContactDetailsByURi>();
  const [previewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const [isUserHolder, setIsUserHolder] = useState(false);
  const [selectedPublicAddress, setSelectedPublicAddress] = useState('');
  const [isCorrectNetwork, setIsCorrectNetwork] = useState<boolean>(false);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [file, setFile] = useState<null | File>(null);
  const [isError, setError] = useState(false);
  const [isAccessDenied, setAccessDenied] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState<number>();
  const isLogin = useSelector(selectProfileLogin);
  const dispatch = useDispatch();
  const closeModal = useCallback(() => {
    if (modalIsOpen) setModalIsOpen(false);
    if (isError) {
      setError(false);
      history.push('/sign-up');
    }
  }, [modalIsOpen, isError]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const {
    params: { slug, networkId, contract_address },
  } = match;

  const nextFlowCallback = (
    isUserHolder: number,
    selectedPublicAddress: string,
    chaindId: string
  ) => {
    setSelectedPublicAddress(selectedPublicAddress);
    setCurrentNetwork(parseInt(chaindId));
    isUserHolder ? setIsUserHolder(true) : setPreviewModalOpen(true);
  };
  // const postMetadata = async (
  //   fileId: string,
  //   tokenId: string | number,
  //   contract_address: string
  // ) => {
  //   return authRequest
  //     .post(process.env.REACT_APP_API_PATH + `/files/file/${fileId}/metadata`, {
  //       tokenAddress: contract_address,
  //       tokenId,
  //     })
  //     .then((response: any) => {
  //       return response.data;
  //     })
  //     .catch((response) => {
  //       throw response;
  //     });
  // };

  useEffect(() => {
    const getAbiAsync = async () => {
      const isConnectedCorrectNetwork = await connectNetwork({
        neededChainId: networkId,
      });
      const checkNetwork = parseInt(connectedChain?.id ?? '0'); //await getCurrentChainId();
      if (
        isConnectedCorrectNetwork &&
        checkNetwork === parseInt(networkId) &&
        previewModalOpen
      ) {
        const info = await getContractInfo({ contract_address, slug });
        setContactInfo(info);
        setIsCorrectNetwork(isConnectedCorrectNetwork);
        // const data = postMetadata(slug, info.tokenId, contract_address);
      }
    };
    void getAbiAsync();
  }, [networkId, currentNetwork, previewModalOpen]);

  const getNftFileFlow = async () => {
    try {
      const { address, signature }: any = await sharedByLinkCheckUser();
      getPubNftFileEffect(slug, address, signature, networkId, contract_address)
        .then(async (data) => {
          if (!data.data.length) {
            setAccessDenied(true);
          } else {
            setFile({ ...data.data[0] });
          }
        })
        .catch(() => {
          setAccessDenied(true);
        });
    } catch (err) {
      // @ts-ignore
      if (err.message.startsWith('Public address')) setError(true);
    }
  };
  const content = useMemo(() => {
    if (file?.type === 1) {
      return (
        <SharedNftFile
          file={file}
          // @ts-ignore
          isAccessDenied={isAccessDenied}
          modalIsOpen={modalIsOpen}
          openModal={openModal}
          closeModal={closeModal}
        />
      );
    } else if (file?.type === 2) return <SharedFolder folderId={file?.slug} />;
    else if (isAccessDenied) return <AccessDenied />;
    else
      return (
        <AuthWalletsPage isLogin={isLogin} match={match} nextFlowCallback={nextFlowCallback} />
      );
  }, [file, modalIsOpen]);

  useEffect(() => {
    if (!previewModalOpen && isUserHolder) void getNftFileFlow();
    else setAccessDenied(true);
    if (isLogin) {
      dispatch(getUserInfoEffect());
    }
  }, [previewModalOpen, isUserHolder]);

  return file || isAccessDenied ? (
    <MainContextWrapper>
      {content}
      {previewModalOpen && (
        <PreviewMonetizeModal
          fileName={file?.name ?? ''}
          selectedPublicAddress={selectedPublicAddress}
          currentNetwork={currentNetwork}
          contactInfo={contactInfo}
          isPreviewMode={false}
          isOpen={previewModalOpen}
          setIsOpen={setPreviewModalOpen}
          isCorrectNetwork={isCorrectNetwork}
          showOnClose
        />
      )}
      <NotificationBubble />
    </MainContextWrapper>
  ) : (
    <ThemeProvider>
      <ErrorModal isOpen={isError} onClose={closeModal} />
      {!isError && <Preloader />}
    </ThemeProvider>
  );
}
