// New
import React, { memo, useEffect, useState } from 'react';
// @ts-ignore
import Modal from 'react-modal';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router';
import CN from 'classnames';

import authRequest from 'utils/request/auth-request';

import CloseIcon from 'components/svg/close';
import Preloader from 'components/shared/Preloader';
import { API_WORKSPACE } from 'constants/api-urls';

import styles from './styles.module.scss';

export default memo(function RequestToJoinBlock({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState({
    slug: '',
    user: '',
    workspace_name: '',
    message: '',
    status: 1,
  });
  const token = new URLSearchParams(history.location.search).get('token');
  const slug = new URLSearchParams(history.location.search).get('workspace');
  const input = new URLSearchParams(history.location.search).get('input');
  useEffect(() => {
    if (open) {
      // @ts-ignore
      authRequest
        .get(API_WORKSPACE + '/request/info' + history.location.search)
        .then((response: AxiosResponse) => {
          setLoading(true);
          setState(response.data);
        })
        .catch(() => {
          setLoading(true);
          onClose();
        });
    }
  }, [open]);

  const closeModal = () => {
    onClose();
  };

  const onCancelClickHandler = () => {
    // @ts-ignore
    authRequest
      .post(API_WORKSPACE + '/request/cancel', {
        token,
        workspace: slug,
        input,
      })
      .then(onClose);
  };

  const onAcceptClickHandler = () => {
    // @ts-ignore
    authRequest
      .post(API_WORKSPACE + '/request/accept', {
        token,
        workspace: slug,
        input,
      })
      .then(onClose);
  };

  if (!isLoading) return <Preloader />;

  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      className="Modal"
      overlayClassName="Overlay"
      shouldCloseOnOverlayClick
    >
      <section className="modal modal__contented">
        <button
          type="button"
          className="modal__exit"
          onClick={closeModal}
          data-test="create-entity-modal_close_button"
        >
          <CloseIcon color="#4B576C" />
        </button>
        <div className={CN('modal__title', styles.title)}>
          {state.status == 1 ? 'New User' : 'Oops...'}
        </div>
        <div className="modal__content">
          <div className={styles.container}>
            {state.status == 1 && (
              <>
                The user <span className={styles.name}>{state.user}</span> and
                the message ({state.message}) want to join your workspace{' '}
                <span className={styles.workspace}>{state.workspace_name}</span>
              </>
            )}
            {state.status == 2 && (
              <>
                User <span className={styles.name}>{state.user}</span> is
                already a member of workspace:{' '}
                <span className={styles.workspace}>{state.workspace_name}</span>
              </>
            )}
            {state.status == 3 && (
              <>
                User <span className={styles.name}>{state.user}</span> denied
                access to workspace:{' '}
                <span className={styles.workspace}>{state.workspace_name}</span>
              </>
            )}
          </div>
        </div>
        {state.status == 1 ? (
          <div className="modal__buttons">
            <button
              className={CN(styles.button, 'link')}
              type="button"
              onClick={onCancelClickHandler}
              data-test="create-entity-modal_cancel_button"
            >
              Cancel
            </button>
            <button
              className={CN(styles.button, 'button-raw')}
              type="button"
              onClick={onAcceptClickHandler}
              data-test="create-entity-modal_accept_button"
            >
              accept
            </button>
          </div>
        ) : (
          <div className={styles.buttonWrapper}>
            <button
              className={CN(styles.button, 'button-raw')}
              type="button"
              onClick={closeModal}
              data-test="create-entity-modal_accept_button"
            >
              ok
            </button>
          </div>
        )}
      </section>
    </Modal>
  );
});
