/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';

import { ReactComponent as LogoIcon } from 'containers/main/NeyraDriveContainer/components/assets/logo.svg';
import { ReactComponent as TriangleIcon } from 'containers/main/NeyraDriveContainer/components/assets/triangle.svg';

import styles from './styles.module.scss';

const isProfit = true;

const ValueWidget = () => {
  const { t } = useTranslation('owner');

  return (
    <div className={styles.value}>
      <div className={styles.valueHeader}>
        <div className={styles.valueLogo}>
          <LogoIcon />
          <h1 className={styles.progressHeaderTitle}>
            {t('rightFileMenu.meta.value')}
          </h1>
        </div>
        <div
          className={CN(
            styles.valueChange,
            isProfit ? styles.valueChangeProfit : styles.valueChangeLosses
          )}
        >
          <TriangleIcon />
          <span>0.0%</span>
        </div>
      </div>
      <div className={styles.valueContent}>
        <div className={styles.valueContentText}>$0.00</div>
        <div className={styles.valueContentGraphic}>
          {/* <div className={styles.grafChart} /> */}
        </div>
      </div>
      <p className={styles.valueContentFooter}>
        {t('rightFileMenu.meta.infoDescription')}
      </p>
    </div>
  );
};

export default ValueWidget;
