//Refactoring №5
import React, { useEffect, useState } from 'react';

import Header from 'components/VaultModal/components/PreviewHeader';
import PreviewItem from 'components/VaultModal/components/PreviewItem'

import { trimLongText } from 'utils';
import { doesFileExist } from 'utils/request/does-file-exist';
import { validationRegExp } from 'utils/validation/regexp';
import { VaultItemType } from './constants'

import styles from './styles.module.scss';

type IProps = {
  toggleView: () => void,
  vault: VaultItemType,
  ownerId?: number,
}

const Preview = ({ toggleView, vault, ownerId }: IProps) => {
  const [iconUrl, setIconUrl] = useState<string>('');
  const { passwordName, fields, tags } = vault;

  useEffect(() => {
    const websiteLink = fields.find((item) => item.type === 'website');
    const websiteValue = websiteLink?.value || '';
    if (websiteValue && validationRegExp.isLink.test(websiteValue)) {
      doesFileExist(websiteValue).then((url) => {
        setIconUrl(url);
      });
    }

    return () => {
      setIconUrl('');
    };
  }, [fields, vault]);

  return (
    <div className={styles.previewBody}>
      <Header
        toggleView={toggleView}
        logo={iconUrl}
        name={passwordName}
        ownerId={ownerId}
      />
      <div className={styles.fieldList}>
        {fields.map((props, index) => 
          (<PreviewItem key={`${props.type}-${index}`} {...props} />))
        }
      </div>
      <div className={styles.taglist}>
        {tags.map((tag, index) => (
          <p
            key={`${tag}-${index}`}
            className={styles.tagItem}
          >{`#${trimLongText(tag, 20)}`}</p>
        ))}
      </div>
    </div>
  );
};

export default Preview;
