export const DOCUMENTS_GET = 'DOCUMENTS_GET'
export const DOCUMENTS_GET_SUCCESS = 'DOCUMENTS_GET_SUCCESS'
export const DOCUMENTS_GET_NEXT_SUCCESS = 'DOCUMENTS_GET_NEXT_SUCCESS'
export const DOCUMENTS_GET_FAILURE = 'DOCUMENTS_GET_FAILURE'
export const GET_DOCUMENT_REMOVE = 'GET_DOCUMENT_REMOVE'
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS'
export const RESET_STATE = 'DOCUMENTS_RESET_STATE'
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS'
export const SET_DOCUMENTS = 'SET_DOCUMENTS'

export const documentsGet = (payload) => ({
  type: DOCUMENTS_GET,
  payload: payload,
})

export const documentsGetSuccess = (data) => ({
  type: DOCUMENTS_GET_SUCCESS,
  payload: data,
})

export const documentsGetNextSuccess = (data) => ({
  type: DOCUMENTS_GET_NEXT_SUCCESS,
  payload: data,
})

export const documentsGetFailure = (err) => ({
  type: DOCUMENTS_GET_FAILURE,
  payload: err,
})

export const removeDocumentSuccess = (data) => ({
  type: GET_DOCUMENT_REMOVE,
  payload: { data },
})

export const createDocumentSuccess = (data) => ({
  type: CREATE_DOCUMENT_SUCCESS,
  payload: { data },
})

export const resetState = () => ({
  type: RESET_STATE,
})

export const updateDocumentSuccess = (document) => ({
  type: UPDATE_DOCUMENT_SUCCESS,
  payload: document,
})

export const setDocuments = (documents) => ({
  type: SET_DOCUMENTS,
  payload: documents,
})
