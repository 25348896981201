import { createContext } from 'react'

export type ContexType = {
  state: any,
  dispatch: (action: { payload?: any, type: string }) => void,
  dropUploaderRef: any | null
}

const MainContext = createContext<ContexType>({
  state: {},
  dispatch: () => ({}),
  dropUploaderRef: null,
})

export default MainContext
