//Refactoring №3
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectProfileLogin } from 'features/profile/profileSlice';
import { selectUserInfo } from 'store/home/reducers/get-user-info.reducer';
import { trimLongText } from 'utils';

import { ReactComponent as LinkIcon } from 'static/assets/svg/link.svg';

import styles from './style.module.scss';

const PreviewHeader = ({ toggleView, logo, name, ownerId }) => {
  const isLogin = useSelector(selectProfileLogin);
  const myProfile = useSelector(selectUserInfo);
  const { t } = useTranslation('contextMenu');
  const isMyFile = ownerId === myProfile?.id;

  return (
    <div className={styles.header}>
      {logo ? (
        <div className={styles.logoWrapper}>
          <img className={styles.logo} src={logo} alt="Logo" />
        </div>
      ) : (
        <div className={styles.defaultLogo}>
          <LinkIcon />
        </div>
      )}
      {name && <h2 className={styles.name}>{trimLongText(name, 34)}</h2>}
      {isLogin && isMyFile && (
        <button onClick={toggleView} className={styles.editBtn}>
          {t('main.addPassword.edit')}
        </button>
      )}
    </div>
  );
};

export default PreviewHeader;
