//Refactoring №2
import React from 'react';

import ActionPopup from 'components/popups/action-popup';

const UserRole = ({
  icon,
  name,
  username,
  access,
  active,
  role,
  email,
  showActions,
  actionHandler,
  options,
  invite,
}) => {
  const iconImage = icon ? (
    <img src={icon} className="user-icon" alt={name || username} />
  ) : (
    <span className="icon-default" />
  );
  const accessClassName =
    access && access.toLowerCase() === 'restricted'
      ? `user-roles-item__access-restricted`
      : '';
  const statusClassName = active
    ? 'user-roles-item__status-active'
    : 'user-roles-item__status-inactive';

  return (
    <div className="user-roles-item">
      <div className="user-roles-item__icon">
        {iconImage}
        <div className={`user-roles-item__status ${statusClassName}`}>
          {active}
        </div>
      </div>
      <div className="user-roles-item__name-role">
        <div className="user-roles-item__name">{name || username}</div>
        {role && <div className="user-roles-item__role">{role}</div>}
        {(email || invite) && (
          <div className="user-roles-item__role">{email || invite.email}</div>
        )}
      </div>
      {access && (
        <div className={`user-roles-item__access ${accessClassName}`}>
          {access}
        </div>
      )}
      {showActions && (
        <ActionPopup onAction={actionHandler} options={options} />
      )}
    </div>
  );
};

UserRole.defaultProps = {
  showActions: false,
};

export default UserRole;
