import checkViewType from 'utils/files-folders/check-view-type'
import * as actions from '../../actions/file-fiew.actions'
import setContentViewType from 'utils/files-folders/set-content-view-type'

export default (newType) => {
  return (dispatch) => {
    const type = checkViewType(newType)
    setContentViewType(type)

    dispatch(actions.setFileView(type))
  }
}
