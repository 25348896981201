// Libs

// Store
import * as actions from '../actions/file/entities-security-check.actions'
import getData from 'utils/request/get-data'

const initialState = {
  entities: [],
  securitiesList: [],
  addData: {},
  actionType: '',
  workspace_securities: [],
}

export default (state = initialState, action) => {
  let data
  let actionType

  switch (action.type) {
    case actions.ENTITIES_SECURITY_CHECK:
      data = getData(action)
      return {
        ...state,
        entities: data.entities,
        securitiesList: data.securitiesList,
        addData: data.addData,
        actionType: data.actionType,
      }

    case actions.ENTITIES_SECURITY_CHECK_RESET:
      return {
        ...state,
        entities: [],
        securitiesList: [],
        addData: {},
        actionType: '',
        workspace_securities: [],
      }

    case actions.ENTITIES_SECURITY_SET_OPTION:
      actionType = action.payload.actionType
      return {
        ...state,
        actionType,
      }

    case actions.WORKSPACE_SECURITY_CHECK:
      data = getData(action)
      return {
        ...state,
        workspace_securities: data,
      }

    default:
      return state
  }
}
