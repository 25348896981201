//Refactoring №3
import React from 'react';

const AiGenerator = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 5.00006H4.5V6.50006M6 5.00006V3.00006M6 5.00006H9M9 5.00006V3.00006M9 5.00006H11.5M11.5 5.00006V3.00006M11.5 5.00006H14M14 5.00006H16.5V8.00006M14 5.00006V3.00006M4.5 6.50006H3M4.5 6.50006V9.00006M4.5 9.00006H3M4.5 9.00006V11.5001M4.5 11.5001H3M4.5 11.5001V13.7501M4.5 13.7501V16.0001H6M4.5 13.7501H3M16.5 8.00006H18M16.5 8.00006V10.5001M16.5 10.5001H18M16.5 10.5001V12.5001M16.5 12.5001H18M16.5 12.5001V14.5001M16.5 14.5001V16.0001H14.5M16.5 14.5001H18M14.5 16.0001H11.5M14.5 16.0001V17.5001M6 16.0001V17.5001M6 16.0001H9M9 16.0001V17.5001M9 16.0001H11.5M11.5 16.0001V17.5001"
      stroke="currentColor"
      strokeWidth="0.5"
    />
  </svg>
);

AiGenerator.defaultProps = {};

export default AiGenerator;
