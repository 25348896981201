//New
import {
  API_ADD_AI_PROMT_URL,
  API_AI_GENERATOR_BLOCKADELABS,
  API_AI_GENERATOR_REALTIME,
  API_AI_GENERATOR_STABLE_DIFFUSION,
  API_FOLDERS,
  API_GET_AI_PROMT_LIST_URL,
  API_TOKEN_UTILIZATION,
} from 'constants/api-urls';
import axios from 'axios';
import authRequest from 'utils/request/auth-request';

interface IRequestBody {
  text_prompts: { text: string }[];
  cfg_scale: number;
  clip_guidance_preset: string;
  height: number;
  width: number;
  samples: number;
  steps: number;
  style_preset?: string;
}

export const generateAiEffectFromStableDiffusion = async (
  text: string,
  width: number,
  height: number,
  count: number,
  imageFilter: string
) => {
  const requestBody: IRequestBody = {
    text_prompts: [
      {
        text: text,
      },
    ],
    cfg_scale: 7,
    clip_guidance_preset: 'FAST_BLUE',
    height: height,
    width: width,
    samples: count,
    steps: 30,
  };

  if (imageFilter) {
    requestBody.style_preset = imageFilter;
  }
  return await fetch(API_AI_GENERATOR_STABLE_DIFFUSION, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_API_KEY_FOR_GENERATING}`,
    },
    body: JSON.stringify(requestBody),
  })
    .then((response) => {
      if (!response.ok) {
        return response.json().then((errorData) => {
          if (errorData.name === 'invalid_prompts') {
            throw new Error('invalid promt');
          } else {
            throw new Error(errorData.message);
          }
        });
      } else {
        return response.json();
      }
    })
    .then((data) => {
      const base64Urls = data.artifacts.map((el) => el.base64);
      const paddingIndex = base64Urls[0].indexOf('=');
      const base64Length = base64Urls[0].length;
      const fileSize =
        (base64Length * 0.75 -
          (paddingIndex > -1 ? base64Length - paddingIndex : 0)) /
        1024 /
        1024;
      const imageUrls = data.artifacts.map(
        (el) => `data:image/png;base64,${el.base64}`
      );
      return { imageUrls, fileSize, base64Urls };
    })
    .catch((error) => {
      throw new Error(error.message);
    });
};

export const getGeneratedImageAsBlob = async (url: string) =>
  await fetch(url).then((response) => response.blob());

export const generateImageToImageEffect = async (form: FormData) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_AI_GENERATOR_IMAGE_TO_IMAGE_URL}`,
    form,
    {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_KEY_FOR_GENERATING}`,
      },
    }
  );

  const base64Image = data.artifacts[0].base64;
  const imageUrl = `data:image/png;base64,${base64Image}`;
  return { imageUrl, base64Image };
};

export const upscaleImageEffect = async (form: FormData) =>
  fetch(`${process.env.REACT_APP_AI_GENERATOR_UPSCALE_URL}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_API_KEY_FOR_GENERATING}`,
    },
    body: form,
  })
    .then((response) => response.json())
    .then((data) => {
      const base64Image = data.artifacts[0].base64;
      const imageUrl = `data:image/png;base64,${base64Image}`;
      return { imageUrl, base64Image };
    })
    .catch((err) => err);

export const generateImageFromCanvasEffect = async (
  image: string,
  prompt: string,
  filter: string,
  width = 512,
  height = 512,
  strength = 0.7
) => {
  const requestData = {
    width,
    height,
    imageDataUrl: image,
    prompt,
    style: filter ? filter : 'NONE',
    strength,
  };
  return await axios
    .post(API_AI_GENERATOR_REALTIME, requestData, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_LEONARDO_API_KEY}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data.lcmGenerationJob.imageDataUrl[0];
    })
    .catch((error) => {
      return error;
    });
};

export const generateSkybox360Effect = async (
  prompt: string,
  style: string | number
) => {
  const styleId = style ? Number(style) : 13;
  const body = { skybox_style_id: styleId, prompt, enhance_prompt: true };
  const data = await axios
    .post(API_AI_GENERATOR_BLOCKADELABS, body, {
      headers: {
        Accept: 'application/json',
        'x-api-key': process.env.REACT_APP_BLOCKADELABS_API_KEY,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    });
  return data;
};

export const exportSkybox360Effect = async (skybox_id: number, type_id = 4) => {
  const body = { skybox_id, type_id };
  const url = `${API_AI_GENERATOR_BLOCKADELABS}/export`;
  const data = await axios
    .post(url, body, {
      headers: {
        Accept: 'application/json',
        'x-api-key': process.env.REACT_APP_BLOCKADELABS_API_KEY,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    });
  return data;
};

export const getSkybox360GenerationStylesEffect = async () => {
  const url = `${API_AI_GENERATOR_BLOCKADELABS}/styles`;
  const data = await axios
    .get(url, {
      headers: {
        Accept: 'application/json',
        'x-api-key': process.env.REACT_APP_BLOCKADELABS_API_KEY,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      return response.data;
    });
  return data;
};

export const addTypeGeneratedByAiToFile = async (slug: string) =>
  authRequest
    .post(`${process.env.REACT_APP_API_PATH}/file/${slug}/history/19`)
    .then((response: any) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });

export const tokenUtilization = async (type: number, count?: number) =>
  authRequest
    .post(`${API_TOKEN_UTILIZATION}`, {
      type,
      ...(count && { count }),
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });

export const addPromtToHistory = async (text: string) =>
  authRequest
    .post(`${API_ADD_AI_PROMT_URL}`, {
      text,
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });

export const getPromtHistory = async () =>
  authRequest
    .get(`${API_GET_AI_PROMT_LIST_URL}`)
    .then((response: any) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });

export const createStudioFolderEffect = async (name: string) =>
  authRequest
    .post(`${API_FOLDERS}/folder`, { name, parent: null })
    .then((response: any) => {
      return response.data.data;
    })
    .catch((err) => {
      return err;
    });
