import authRequest from 'utils/request/auth-request'

// Store
import * as searchFilesActions from '../../actions/files/search.action'
import { getFilesDispatchEffect } from './get-files.effect'

/**
 * @param {object} data - data for request
 *      @param {string} data.terms
 *
 * @returns {Function}
 */
export function searchEffect(folderId, data = {}) {
  return (dispatch) => {
    dispatch(searchFilesActions.searchAddData(data))
    dispatch(getFilesDispatchEffect(folderId, {}, true))
  }
}

/**
 * @param {string} term
 * @returns {Promise}
 */
export async function autoCompleteSearchEffect(dispatch, term = '') {
  const url = `${process.env.REACT_APP_API_PATH}/search/autocomplete?term=${term}`
  return authRequest
    .get(url)
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      
      console.error(error)
    })
}

/**
 * @param {string} term
 * @returns {Promise}
 */
export async function searchResultEffect(dispatch, term = '') {
  const url = `${process.env.REACT_APP_API_PATH}/search/result?term=${term}`
  return authRequest
    .get(url)
    .then(({ data }) => data)
    .catch((error) => {
      
      console.error(error)
    })
}
