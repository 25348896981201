import actionsOptions from 'config/actions-options';
const CONTRACT_MULTYSIG_TYPE = 3
const FACTORY_ALICE = 'multisigFactory';
const CONTRACT_ALICE = 'workspaceMultisig'


const EMBARGO_MULTISIG_OPTIONS = [
  actionsOptions.remove,
  actionsOptions.accessPreferences,
  actionsOptions.nft,
  actionsOptions.guardians,
  actionsOptions.download,
  actionsOptions.edit,
  actionsOptions.showMove,
]

export { CONTRACT_MULTYSIG_TYPE, CONTRACT_ALICE, FACTORY_ALICE, EMBARGO_MULTISIG_OPTIONS }
