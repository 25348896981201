/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { copyToClipboard } from 'utils/string';
import WaveSafer from 'containers/main/EntitySettingsContainer/components/NeiroTab/waveSafer';

import { ReactComponent as CopyIcon } from 'containers/auth/NeyraChat/assets/copy.svg';
import { ReactComponent as EditIcon } from 'containers/auth/NeyraChat/assets/edit.svg';
import { ReactComponent as FavoriteIcon } from 'containers/auth/NeyraChat/assets/favorite.svg';
import { ReactComponent as SuccessCopyIcon } from 'containers/auth/NeyraChat/assets//successCopy.svg';

import styles from './styles.module.scss'

const Message = ({ message }) => {
  const [isCopy, setIsCopy] = useState(false);
  const { id, isNeyro, text, timeStamp, voice, blob } = message;

  useEffect(() => {
    if (isCopy) {
      const timer = setTimeout(() => {
        setIsCopy(false);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isCopy]);

  const copyText = () => {
    setIsCopy(true);
    copyToClipboard(text);
  };

  return (
    <div
      id={id}
      className={`${styles.wrapper} ${!isNeyro ? styles.userMessage : ''}`}
    >
      <div className={styles.message}>
          {voice && blob ? (
              <WaveSafer className={styles.voiceBar} key={`message-${id}`} url={blob} volume={0.7} />
            ) : (
              <p className={styles.text} >{text}</p>
            )}
        <div className={styles.footer}>
          <div>
            {isNeyro && (
              <div className={styles.blockButtons}>
                <button className={styles.button} onClick={copyText}>
                  {isCopy ? <SuccessCopyIcon /> : <CopyIcon />}
                </button>
                <button className={styles.button}>
                  <EditIcon />
                </button>
                <button className={styles.button}>
                  <FavoriteIcon />
                </button>
                {/* <button className={styles.button}>
                  <VolumeIcon />
                </button> */}
              </div>
            )}
          </div>
          <div className={styles.data}>{moment(timeStamp).format('LT')}</div>
        </div>
      </div>
    </div>
  )
}

export default Message;