//Refactoring №2
import React from 'react';
import cn from 'classnames';

const InviteWorkspaceList = ({ list, onJoinClick, current }) => {
  return (
    <div className="auth-invite-content auth-invite-content-pending">
      <div className="auth-invite-title">Pending invitations</div>
      <div className="auth-invite-invitation">
        Invitations for {current?.email}
      </div>
      {list.map((item, index) => (
        <div
          className="auth-invite-join-item"
          key={`workspace-invite-${index}`}
        >
          <div
            className={cn('user-icon', 'auth-invite-join-item-logo')}
            style={{ backgroundColor: item.workspace?.color || '' }}
          >
            {item.workspace?.name?.charAt(0).toUpperCase()}
          </div>
          <div className="auth-invite-join-item-name">
            {item.workspace?.name}
          </div>
          <button
            type="button"
            className="button-light"
            onClick={onJoinClick(item.token)}
          >
            JOIN
          </button>
        </div>
      ))}
    </div>
  );
};

export default InviteWorkspaceList;
