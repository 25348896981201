import React from 'react'

const ErrorTriangle = ({ color }) => (
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.345 13.241L13.29 1.844a3.281 3.281 0 00-5.58 0L.654 13.241a3.281 3.281 0 002.79 5.009h14.111a3.282 3.282 0 002.79-5.009zm-1.452 2.472a1.512 1.512 0 01-1.338.787H3.445a1.53 1.53 0 01-1.302-2.338L9.198 2.765a1.531 1.531 0 012.604 0l7.056 11.397a1.512 1.512 0 01.035 1.551z"
      fill={color}
    />
    <path
      d="M9.625 6h1.75v5.25h-1.75V6zM9.625 12.125h1.75v1.75h-1.75v-1.75z"
      fill={color}
    />
  </svg>
)

ErrorTriangle.defaultProps = {
  color: '#FFA943',
}

export default ErrorTriangle
