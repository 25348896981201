import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const api = createApi({
  reducerPath: 'crypto-api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'http://stage03.ghostdrive.com:1437/api/auth/',
  }),
  endpoints: (build) => ({
    register: build.mutation({
      query: (data) => ({
        url: `/registration`,
        method: 'POST',
        body: {
          email: data.email,
          code: data.code,
          workspace: data.workspace,
          publicAddress: data.publicAddress,
        },
      }),
    }),
  }),
})
