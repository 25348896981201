import React, { useContext, useEffect, useMemo, useState } from 'react';
import styles from './PlusAction.module.scss';
import { DARK_THEME, ThemeContext } from 'store/context/theme';

// TODO: light theme

interface PlusAction {
  active: boolean;
  loading?: boolean;
  activateCb?: (status: boolean) => void;
  size?: string;
}

const PlusAction = (props: PlusAction) => {
  const themeContext = useContext(ThemeContext);
  const isDarkTheme = useMemo(
    () => themeContext?.theme === DARK_THEME,
    [themeContext]
  );

  /** Initial State is needed for not running animation at the initialization */
  const [isInitialState, setIsInitialState] = useState<boolean | null>(null);
  const size = props.size || '24px';

  useEffect(() => {
    /** This condition helps isInitialState to work properly.
     * After mounting I need initialState to be true.
     * But if I write setIsInitialState(false); based on props.active,
     * the state is always will be true without the effect I need
     */
    if (isInitialState === null) {
      setIsInitialState(true);
    } else if (isInitialState === true) {
      setIsInitialState(false);
    }
  }, [props.active]);

  const toggleActive = () => {
    setIsInitialState(false);
    props.activateCb?.(!props.active);
  };

  const getButtonClass = () => {
    let className = styles.button;

    if (isInitialState) {
      if (props.active && !props.loading) {
        className += ' ' + styles['active-no-animation'];
      }
    } else {
      if (props.active && !props.loading) {
        className += ' ' + styles.active;
      } else if (!props.loading) {
        className += ' ' + styles.inactive;
      }
    }

    if (props.loading) {
      className += ' ' + styles.loading;
    }

    return className;
  };

  return (
    <button className={getButtonClass()} onClick={toggleActive}>
      <svg width={size} height={size} viewBox="0 0 100 100">
        <rect
          className={styles.line1}
          x="50"
          y="5"
          width="4"
          height="90"
          fill={isDarkTheme ? "white" : "black"}
        />
        <rect
          className={styles.line2}
          x="5"
          y="50"
          width="90"
          height="4"
          fill={isDarkTheme ? "white" : "black"}
        />
      </svg>
    </button>
  );
};

export default PlusAction;
