// Frameworks
import React, { useState, useEffect } from 'react';

// libs
import * as R from 'ramda';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PrivateRoute from 'resolvers/private-route';

// Store
// import { checkAuthEffect } from 'store/auth/effects';
import { getUserInfoEffect } from 'store/home/effects';
import getFileViewTypeEffect from 'store/home/effects/file-view-type/get-file-view-type-effect';
import { resetUserHomeInfo } from 'store/home/actions/get-user-info.actions';
import { resetMultisigUsers } from 'features/workspaceSettings/workspaceSettingsSlice';
import { getQueueFiles } from 'store/neyra/effects';
import { resetUserInfo } from 'store/account/actions';
import { workspacePlanClear } from 'store/plan/actions';

// Utils
import getToken from 'utils/auth/get-token';
import { isNeyraAvatarLink } from 'utils/routing/is-neyra-avatar-link';

// Components
import Home from 'containers/main/Main/index';
import Preloader from 'components/shared/Preloader/index';
import Avatar from 'containers/neyra/Avatar';

const HomeResolver = ({
  // checkAuthEffect,
  getUserInfoEffect,
  resetMultisigUsers,
  getFileViewTypeEffect,
  workspacePlanClear,
  neyraUser,
  resetUserInfo,
  user,
  history,
  location,
}) => {
  const [isAuthUser, setIsAuthUser] = useState(false);
  const isAuthenticated = !!getToken();
  const isFolderPage = window.location.pathname.startsWith('/folder/');
  const url = new URL(window.location.href);
  const queryParams = url.searchParams;
  const isReferralCode = queryParams.get('code');
  const isAvatarLink = isNeyraAvatarLink();

  useEffect(() => {
    const fallbackURL = localStorage.getItem('fallbackUrl');
    if (fallbackURL) {
      history.push(fallbackURL);
      localStorage.removeItem('fallbackUrl');
    }
  }, [history]);

  useEffect(() => {
    if (neyraUser.id) {
      getQueueFiles(neyraUser.id)
    }
  }, [neyraUser])

  useEffect(() => {
    if (
      user &&
      location.pathname === '/' &&
      !location.search.includes('token')
    ) {
      history.push(user.isGuest ? 'sharing' : '/main');
    } else {
      if (user?.isGuest && location.pathname === '/main')
        history.push('sharing');
    }
  }, [user]);

  // const authCheck = () => {
  //   return checkAuthEffect();
  // };

  const loadInfo = () => {
    getUserInfoEffect();
    getFileViewTypeEffect();
  };

  useEffect(() => {
    const isAuthUserNow = !!getToken();

    if (isReferralCode) {
      history.push(`/sign-up-email?referral=${isReferralCode}`);
      return;
    }

    if (isAuthUserNow) {
      loadInfo();
      setIsAuthUser(isAuthUserNow);
    } else {
      localStorage.setItem('fallbackUrl', location.pathname + location.search);
      if (!isFolderPage) {
        history.push('/');
      }
    }

    return () => {
      resetUserInfo();
      workspacePlanClear();
      resetUserHomeInfo();
      resetMultisigUsers();
    };
  }, []);

  const canRenderHome = () => isAuthUser && !R.isEmpty(user) && !R.isNil(user);

  if (isAvatarLink && canRenderHome()) {
    return <Avatar />
  }

  if (canRenderHome()) {
    if (user.isGuest) return <PrivateRoute path="/**" component={Home} />;

    return <PrivateRoute path="/**" component={Home} />;
  } else {
    if (!isAuthenticated && isFolderPage) {
      return <PrivateRoute path="/**" component={Home} />;
    }
    return <Preloader />;
  }
};

const mapDispatchToProps = {
  // checkAuthEffect,
  getUserInfoEffect,
  getFileViewTypeEffect,
  resetUserInfo,
  workspacePlanClear,
  resetMultisigUsers,
};

const mapStateToProps = (state) => ({
  user: state.home?.userInfo?.user,
  neyraUser: state?.neyra?.profile,
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  HomeResolver
);
