import * as actions from '../actions';

const initialState = {
  isChecked: false,
  startCheck: false,
  entities: [],
  additionalData: null,
  currentChainId: null,
  callback: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.UPDATE_WEB3_CHECKER:
      return {
        ...state,
        isChecked: action.payload,
      };
    case actions.UPDATE_START_WEB3_CHECKER:
      return {
        ...state,
        startCheck: action.payload.status,
        entities: action.payload.entities || state.entities,
        additionalData: action.payload.additionalData,
      };
    case actions.UPDATE_CURRENT_CHAIN_ID:
      return { ...state, currentChainId: action.payload.chainId };
    case actions.START_CHECK_WITH_CALLBACK:
      return {
        ...state,
        startCheck: action.payload.status,
        callback: action.payload.callback,
        currentChainId: action.payload?.neededChainId,
      };
    case actions.ADD_CALLBACK:
      return {
        ...state,
        callback: action.payload.callback,
      };
    default:
      return state;
  }
}

// Selectors
export const selectChecker = (state) => state.account.web3Checker.isChecked;
export const selectStart = (state) => state.account.web3Checker.startCheck;
export const selectEntities = (state) => state.account.web3Checker.entities;
export const selectAdditionalData = (state) =>
  state.account.web3Checker.additionalData;
export const selectCurrentChainId = (state) =>
  state.account.web3Checker.currentChainId;
export const selectCallback = (state) => state.account.web3Checker.callback;
