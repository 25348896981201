// Refactoring №3
import React, { FC, useContext, useMemo, useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectUserFreeActions,
  selectUserInfo,
} from 'store/home/reducers/get-user-info.reducer';
import { needEncryptFile } from 'store/home/actions';
import MainContext from 'store/main/context/main-context';
import { updateWorkspaceSettings } from 'features/workspaceSettings/workspaceSettingsSlice';
import { setCurrentWorkspace } from 'store/main/actions';
import { FILE_ENCRYPTION_PRICE, MAX_FREE_ENCRYPTIONS } from './consts';

import Checkbox, { checkboxStyle } from 'components/Checkbox';
import { MemoBorderedButton } from 'components/PreviewMonetize/components/BorderedButton';
import CloseIcon from 'components/svg/close';
// import { ReactComponent as WarningIcon } from './assets/encrypt_warning.svg';

// import cn from 'classnames';
import styles from './styles.module.scss';

export interface EncryptModalProps {
  isOpen: boolean;
  onClose: () => void;
  callback: (arg?: boolean) => void;
  callPlace?: string;
}

export const EncryptModal: FC<EncryptModalProps> = ({
  isOpen,
  onClose,
  callback,
  callPlace,
}) => {
  const { t } = useTranslation('owner');
  // const { t: accountT } = useTranslation('account');
  const [checked, setChecked] = useState<boolean>(false);
  const dispatch = useDispatch();
  const freeActions = useSelector(selectUserFreeActions);
  const user = useSelector(selectUserInfo);
  const { dispatch: mainDispatch } = useContext(MainContext);

  const freeEncryptionsCount = useMemo(() => {
    const freeEncryptionsAction =
      freeActions &&
      freeActions.find(
        (el: { action: string }) => el.action === 'upload_encrypted_files'
      );

    return freeEncryptionsAction?.count ?? 0;
  }, [freeActions]);
  const userHasFreeEncryptions = freeEncryptionsCount < MAX_FREE_ENCRYPTIONS;
  const showNotificationBar =
    userHasFreeEncryptions || user.point < FILE_ENCRYPTION_PRICE;

  const toggleChecked = () => {
    setChecked((prevState) => !prevState);
  };

  const updateWorkspace = (props) => {
    return dispatch(updateWorkspaceSettings(props)).then((ws) => {
      mainDispatch(setCurrentWorkspace(ws));
    });
  };

  const handleYesButton = async () => {
    if (callPlace === 'settings') {
      onClose();
      callback(true);
      return;
    }
    if (checked) {
      updateWorkspace({
        showEncryptModal: false,
        encryptFilesOnUpload: true,
      });
    }
    dispatch(needEncryptFile(true));
    onClose();
    callback();
  };

  const handleNoButton = () => {
    if (callPlace === 'settings') {
      onClose();
      callback(false);
      return;
    }
    if (checked) {
      updateWorkspace({
        showEncryptModal: false,
        encryptFilesOnUpload: false,
      });
    }
    dispatch(needEncryptFile(false));
    onClose();
    callback();
  };

  return (
    <ReactModal
      overlayClassName={styles.overlay}
      className={styles.container}
      isOpen={isOpen}
    >
      <button type={'button'} className={styles.close} onClick={onClose}>
        <CloseIcon />
      </button>
      <h2 className={styles.title}>{t('encryptModal.title')}</h2>
      <h4 className={styles.description}>{t('encryptModal.description')}</h4>
      {callPlace !== 'settings' && user.role === 'ROLE_OWNER' && (
        <div className={styles.checker_container}>
          <p className={styles.checker}>{t('encryptModal.rememberChoice')}</p>
          <Checkbox
            checked={checked}
            name={'encrypt-checkbox'}
            inputStyle={checkboxStyle.DARK}
            className={styles.checker}
            onClick={toggleChecked}
          />
        </div>
      )}
      <div className={styles.button_container}>
        <MemoBorderedButton
          onClick={handleNoButton}
          title={t('encryptModal.no')}
          isDarkMode={false}
        />
        <MemoBorderedButton
          onClick={handleYesButton}
          title={t('encryptModal.yes')}
          isDarkMode={false}
        />
      </div>
      {/* {showNotificationBar && (
        <div
          className={cn(
            styles.notificationBar,
            userHasFreeEncryptions ? styles.green : styles.yellow
          )}
        >
          <WarningIcon />
          {userHasFreeEncryptions ? (
            <span>
              {t('encryptModal.availableFreeEncryptions', {
                freeEncryptionsCount,
              })}
            </span>
          ) : (
            <span>
              {t('encryptModal.tokensHaveBeenUsed')}{' '}
              <a
                href="/account/credits"
                className={styles.notificationBar__link}
              >
                {accountT('tokens.buyTokens')}
              </a>
            </span>
          )}
        </div>
      )} */}
    </ReactModal>
  );
};
