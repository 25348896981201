import React from 'react'

const SecuredIcon = ({ color }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip-secured-0)">
      <path
        d="M10.839 6.61714C10.5877 6.61714 10.3837 6.41319 10.3837 6.16189V3.88564C10.3837 2.37968 9.15821 1.15415 7.65225 1.15415C6.14628 1.15415 4.92075 2.37968 4.92075 3.88564V6.16189C4.92075 6.41319 4.7168 6.61714 4.4655 6.61714C4.21421 6.61714 4.01025 6.41319 4.01025 6.16189V3.88564C4.01025 1.87709 5.64369 0.243652 7.65225 0.243652C9.66081 0.243652 11.2942 1.87709 11.2942 3.88564V6.16189C11.2942 6.41319 11.0903 6.61714 10.839 6.61714Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7492 14.8115H3.55471C2.80203 14.8115 2.18896 14.1991 2.18896 13.4458V7.07229C2.18896 6.319 2.80203 5.70654 3.55471 5.70654H11.7492C12.5019 5.70654 13.1149 6.319 13.1149 7.07229V13.4458C13.1149 14.1991 12.5019 14.8115 11.7492 14.8115ZM7.19693 10.4746C6.75199 10.2943 6.43743 9.85769 6.43743 9.3488C6.43743 8.67928 6.9819 8.1348 7.65142 8.1348C8.32094 8.1348 8.86542 8.67928 8.86542 9.3488C8.86542 9.85712 8.55157 10.2934 8.10742 10.474V11.9284C8.10742 12.1797 7.90347 12.3837 7.65218 12.3837C7.40088 12.3837 7.19693 12.1797 7.19693 11.9284V10.4746ZM7.65142 9.0453C7.4845 9.0453 7.34792 9.18127 7.34792 9.3488C7.34792 9.51633 7.4845 9.6523 7.65142 9.6523C7.81835 9.6523 7.95492 9.51633 7.95492 9.3488C7.95492 9.18127 7.81835 9.0453 7.65142 9.0453Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip-secured-0">
        <rect
          width="14.568"
          height="14.568"
          fill="white"
          transform="translate(0.368164 0.243652)"
        />
      </clipPath>
    </defs>
  </svg>
)

SecuredIcon.defaultProps = {
  color: '#202124',
}

export default SecuredIcon
