/* eslint-disable no-unused-vars */
//Refactoring №2
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import UserContext from 'store/home/contexts/user-context';
import { ReactComponent as SuccessCycle } from 'static/assets/svg/success-cycle.svg';
import { ReactComponent as CopyButton } from 'static/assets/svg/copy-button.svg';
import { DataFour } from './DataFour';
import { transformSize } from 'utils/storage';
import { entitiesTypes, entitiesTypesByKey } from 'config/entities-types';

import { ReactComponent as NameIcon } from './assets/name.svg';
import { ReactComponent as SizeIcon } from './assets/size.svg';
import { ReactComponent as TypeIcon } from './assets/type.svg';
import { ReactComponent as FilecoinIcon } from './assets/filecoin.svg';
import { ReactComponent as GdIcon } from './assets/gd.svg';
import { ReactComponent as AuthorIcon } from './assets/author.svg';
import { ReactComponent as CreatedIcon } from './assets/created.svg';

const MetaTab = ({ entity }) => {
  const { t } = useTranslation('owner');
  const { user: me } = useContext(UserContext);
  const [isCopy, setCopy] = useState({ hash: false, link: false });
  const { user: owner, ipfs_hash } = entity;

  const created = entity.created_at ? moment.unix(entity.created_at) : moment();
  const updated = entity.updated_at ? moment.unix(entity.updated_at) : moment();

  const copy = (type) => {
    if (type === 'hash') setCopy({ ...isCopy, hash: true });
    if (type === 'link') setCopy({ ...isCopy, link: true });

    setTimeout(() => {
      setCopy({ hash: false, link: false });
    }, 2000);
  };
  const visibleHash = ipfs_hash?.substr(ipfs_hash.length - 6);
  const hashStatus = visibleHash ? `${visibleHash}` : t('common.blockPending');
  const copyHash = () => {
    copy('hash');
    navigator.clipboard.writeText(ipfs_hash);
  };

  return (
    <section className="entity-settings__info">
      <div className="entity-settings__info-item">
        <div className="entity-settings__info-item-title">
          <NameIcon />
          {t('rightFileMenu.meta.name')}
        </div>
        <div className="entity-settings__info-item-fileName">
          {entity?.name}
        </div>
      </div>
      <div className="entity-settings__info-item">
        <div className="entity-settings__info-item-title">
          <SizeIcon />
          {t('rightFileMenu.meta.size')}
        </div>
        <div className="entity-settings__info-item-value">
          {transformSize(entity?.size)}
        </div>
      </div>
      <div className="entity-settings__info-item">
        <div className="entity-settings__info-item-title">
          <TypeIcon />
          {t('rightFileMenu.meta.type')}
        </div>
        <div className="entity-settings__info-item-value">
          {entitiesTypesByKey[entity?.type] === entitiesTypes.folder.type
            ? t('rightFileMenu.meta.folder')
            : entity?.extension}
        </div>
      </div>
      <div className="entity-settings__info-item">
        <div className="entity-settings__info-item-title">
          <FilecoinIcon />
          {t('rightFileMenu.meta.cid')}
        </div>
        <div className="entity-settings__info-item__hash-value">
          <span>{hashStatus}</span>
          {visibleHash && (
            <button
              className="entity-settings__info-item__hash-copy"
              onClick={copyHash}
            >
              {!isCopy.hash ? <CopyButton /> : <SuccessCycle />}
            </button>
          )}
        </div>
      </div>
      <div className="entity-settings__info-item">
        <div className="entity-settings__info-item-title">
          <GdIcon />
          {t('rightFileMenu.meta.encrypted')}
        </div>
        <div className="entity-settings__info-item-value">
          {entity?.is_clientside_encrypted
            ? t('rightFileMenu.meta.yes')
            : t('rightFileMenu.meta.no')}
        </div>
      </div>
      <div className="entity-settings__info-item">
        <div className="entity-settings__info-item-title">
          <AuthorIcon />
          {t('rightFileMenu.meta.authors')}
        </div>
        <div className="entity-settings__info-item-value">
          {owner?.id === me?.id
            ? t('rightFileMenu.meta.you')
            : owner?.displayed_name || owner?.username}
        </div>
      </div>
      <DataFour entity={entity} />
      <div className="entity-settings__info-item">
        <div className="entity-settings__info-item-title">
          <CreatedIcon />
          {t('rightFileMenu.meta.date')}
        </div>
        <div className="entity-settings__info-item-value">
          {entity.created_at ? created.format('MM.DD.YYYY') : ''}
        </div>
      </div>
    </section>
  );
};

export default MetaTab;
