//Refactoring №3
import React from 'react';

class ClickOutside extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick = (ev) => {
    !this.node.contains(ev.target) &&
      !ev.target.closest('.ReactModalPortal') &&
      this.handleClickOutside(ev);
  };

  handleClickOutside = (ev) => {
    this.props.onClickOutside(ev);
  };

  render() {
    return (
      <div className={this.props.className} ref={(node) => (this.node = node)}>
        {this.props.children}
      </div>
    );
  }
}

export default ClickOutside;
