import useCancellablePromises from './use-cancellable-promises'
import { cancellablePromise, delay } from '../cancellable-promise'

const useClickPreventionOnDoubleClick = (onClick, onDoubleClick) => {
  const api = useCancellablePromises()

  const handleClick = (e) => {
    e.persist()
    api.clearPendingPromises()
    const waitForClick = cancellablePromise(delay(300))
    api.appendPendingPromise(waitForClick)

    return waitForClick.promise
      .then(() => {
        api.removePendingPromise(waitForClick)
        onClick(e)
      })
      .catch((errorInfo) => {
        api.removePendingPromise(waitForClick)
        if (!errorInfo.isCanceled) {
          throw errorInfo.error
        }
      })
  }

  const handleDoubleClick = (e) => {
    e.persist()
    api.clearPendingPromises()
    onDoubleClick(e)
  }

  return [handleClick, handleDoubleClick]
}

export default useClickPreventionOnDoubleClick
