/* eslint-disable no-debugger */
import { utils } from 'ethers';
import CryptoJS from 'crypto-js';

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function copyToClipboard(string) {
  return navigator.clipboard.writeText(string);
}

function getTypeByEntityType(entityType, capitalizeText = true) {
  const type = entityType === 2 ? 'folder' : 'file';
  return capitalizeText ? capitalize(type) : type;
}

function cutByMaxLength(text = '', maxLength, delta = 6) {
  if (text.length <= maxLength) return text;
  const overflowCount = text.length - maxLength;

  return `${text.slice(
    0,
    text.length - (delta + overflowCount + 4)
  )}...${text.slice(text.length - delta, text.length)}`;
}

function numberWithCommas(str = '') {
  return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function convertStringToBytes32(str = '') {
  const uuidWithoutDashes = str.replace(/-/g, '');
  const bytes16 = utils.arrayify('0x' + uuidWithoutDashes);
  return utils.zeroPad(bytes16, 32);
}

function stringToBytes32(str) {
  const utf8Bytes = Buffer.from(str, 'utf8');
  let bytes32 = Buffer.allocUnsafe(32);
  utf8Bytes.copy(bytes32);
  return bytes32;
}

function stringToHashBytes32(string) {
  const hashString = CryptoJS.MD5(string).toString();

  return { hashString: hashString, hashBytes32: stringToBytes32(hashString) };
}

function formatNumberToTokens(number, fix = 1) {
  if (number >= 1000000000) {
    return (number / 1000000000).toFixed(fix) + ' B';
  } else if (number >= 1000000) {
    return (number / 1000000).toFixed(fix) + ' M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(fix) + ' K';
  } else {
    return (number / 1).toFixed(fix).toString();
  }
}

function lengthAfterDot(string) {
  const numberAsString = string.toString();
  if (numberAsString.includes('.')) {
    return numberAsString.split('.')[1].length;
  }
  return 0;
}

function getFirstAndLastParts(str, start, end) {
  const firstPart = str.slice(0, start);
  const lastPart = str.slice(-end);

  return `${firstPart}...${lastPart}`
}

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}|;:,.<>?';
  let result = '';
  for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
  }
  return result;
}

function cutExtention(value = '') {
  if (value.includes('.')) {
    return value.split('.').slice(0, -1).join('.');
  }
  
  return value
}

export {
  capitalize,
  cutExtention,
  getTypeByEntityType,
  copyToClipboard,
  cutByMaxLength,
  numberWithCommas,
  convertStringToBytes32,
  stringToBytes32,
  stringToHashBytes32,
  formatNumberToTokens,
  lengthAfterDot,
  getFirstAndLastParts,
  generateRandomString,
};
