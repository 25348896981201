/* eslint-disable no-debugger */
// Refactoring №4
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import EmptyForbidden from 'components/shared/Empty/EmptyForbidden';
import MainFolderFileContainer from 'containers/main/MainFolderFileContainer';
import OnboardingModal from 'components/popups/OnboardingModal';

import getFilesClearEffect from 'store/home/effects/files/get-files-clear.effect';
import ghostFilesClearEffect from 'store/home/effects/ghost-files/ghost-files-clear.effect';
import ghostFilesEffect from 'store/home/effects/ghost-files/ghost-files.effect';
import { selectedFilesFoldersClear } from 'store/home/actions/selected-files-folders.actions';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import updateAnalyticsEffect from 'store/home/effects/analytics/update-analytics.effects';
import { getUserSessionsEffect } from 'store/account/effects/session.effect';
import { createFolderReset } from 'store/home/actions/create-folder.actions';
import { getFilesDispatchEffect } from 'store/home/effects';
import getFolderEffect, {
  getFolderInfoEffect,
} from 'store/home/effects/folder/get-folder.effect';
import MainContext from 'store/main/context/main-context';
import UserContext from 'store/home/contexts/user-context';
import { setCurrentFolder, setCurrentPage } from 'store/main/actions';
import { getFilesClear } from 'store/home/actions/files/get-files.actions';

import { selectSorterState, selectFilterState } from 'features/app';
import { handleNftModal } from 'features/modals/modal-slice';

import { getTypeByEntityType } from 'utils/string';
import { checkDeviceAndScroll } from 'utils/browser';
import useNotification from 'utils/hooks/use-notification';
import { isIpfsService } from 'utils/ipfs/link';
import authRequest from 'utils/request/auth-request';

import actionsOptions from 'config/actions-options';
import { analyticsActionTypes } from 'config/analytics-action-types';
import RESPONSE_STATUS_CODE from 'config/response-status-code';

import { API_GET_FILE } from 'constants/api-urls';
import styles from './styles.module.scss'

type HomeContainerProps = {
  match: { params: { folderId: string }; url: string };
  hsitory: any;
  ghostFilesClearEffect: () => void;
  getFilesClearEffect: () => void;
  selectedFilesFoldersClear: () => void;
  ghostFilesEffect: () => void;
  getFilesDispatchEffect: (
    folderId: string,
    userParams: { page: number },
    clearBeforeGet: boolean,
    canselToken: any,
    tokenized: boolean
  ) => void;
  fileFolderActionHandlerEffect: (
    selectedEntities: [File] | null,
    actionType: string,
    addData?: any
  ) => void;
  selectedFiles: [File] | null;
  errorCode: number;
  createFolderReset: () => void;
  filter: {
    filterName: null | string;
    color: null | string;
    fileType: null | string;
    isTokenized: null | boolean;
  };
  sorter: {
    option: string;
    dir: string;
    page: string;
    initialCommit: boolean;
  };
};

const HomeContainer = ({
  match,
  ghostFilesClearEffect,
  getFilesClearEffect,
  selectedFilesFoldersClear,
  ghostFilesEffect,
  getFilesDispatchEffect,
  fileFolderActionHandlerEffect,
  getFilesClear,
  selectedFiles,
  errorCode,
  createFolderReset,
  sorter,
  filter,
}: HomeContainerProps) => {
  const { t } = useTranslation('owner');
  const { dispatch: mainDispatch }: any = useContext(MainContext);
  const { user } = useContext(UserContext);
  const [isSharedFolder, setIsSharedFolder] = useState(false);
  const [welcomeModal, setWelcomeModal] = useState(false);
  const { addNotification } = useNotification();
  const folderId = match.params.folderId;

  useEffect(() => {
    if (match.url === '/tokenized-files') {
      mainDispatch(
        setCurrentPage({
          name: t('tokenized.tokenized'),
          url: '/tokenized-files',
        })
      );
      return () => {
        mainDispatch(setCurrentPage({ name: '', url: '/main' }));
      };
    }
  }, [match]);

  useEffect(() => {
    checkDeviceAndScroll();
    getUserSessionsEffect().then((data) => {
      const registrationDate = new Date(data.data[0].logged_at * 1000);
      const currentTime = new Date();
      const minSinceRegistration = Math.floor(
        (+currentTime - +registrationDate) / (1000 * 60)
      );
      if (data.count === 1 && minSinceRegistration < 1) setWelcomeModal(true);
    });
  }, []);

  useEffect(() => {
    const fileSignal = axios.CancelToken.source();
    getFilesDispatchEffect(
      folderId,
      {
        page: 1,
        order_by: sorter.option,
        order: sorter.dir,
        extension: filter.fileType,
        isTokenized: filter.isTokenized,
        color: filter.color,
      },
      false,
      fileSignal.token,
      match.url === '/tokenized-files'
    );
  }, [filter, sorter, folderId]);

  useEffect(() => {
    const fileSignal = axios.CancelToken.source();

    if (folderId) {
      createFolderReset();
      getFolderInfoEffect(folderId)
        .then(async (data) => {
          const entry = data?.entry;
          if (entry.slug) {
            mainDispatch(setCurrentFolder(entry, entry.breadcrumbs));
            const isShared = entry.user.username !== user.username;
            const isMember =
              entry.shares.find(
                (data: any) => data?.share_right?.user?.id === user.id
              ) || user.role === 'ROLE_MEMBER';
            const isFolderOwnerMember = entry.user.role === 'ROLE_MEMBER';
            let isUsersWorkspaceFolder;

            if (user.id && isShared) {
              const {
                data: { data },
              }: any = await authRequest.get(API_GET_FILE);
              const folders = data.filter((file: any) => file.type === 2);
              isUsersWorkspaceFolder = folders.find(
                (folder: any) => folder.slug === entry.slug
              )
                ? true
                : false;
            }

            setIsSharedFolder(
              isShared &&
                !isMember &&
                !isFolderOwnerMember &&
                !isUsersWorkspaceFolder
            );
            isIpfsService(entry.service) &&
              updateAnalyticsEffect(entry.slug, analyticsActionTypes.viewed);
          }
        })
        .catch((err) => {
          if (err.message === 'Request failed with status code 403') {
            setIsSharedFolder(true);
          }
          console.warn(err);
        });
    }

    return () => {
      fileSignal.cancel('getFilesDispatchEffect is being canceled');
      mainDispatch(setCurrentFolder(null, undefined));
      selectedFilesFoldersClear()
      getFilesClear()
    };
  }, [
    createFolderReset,
    folderId,
    getFilesClearEffect,
    getFilesDispatchEffect,
    ghostFilesClearEffect,
    ghostFilesEffect,
    getFolderEffect,
    mainDispatch,
    selectedFilesFoldersClear,
    user.username,
  ]);
  const showErrorNotification = (message) => {
    addNotification(message, 'warning');
  };
  const showSuccessNotification = useCallback(
    (entity) => () => {
      const type =
        getTypeByEntityType(entity.type) === 'File'
          ? t('rightFileMenu.meta.file')
          : t('rightFileMenu.meta.folder');
      addNotification(t('deleted.wasDeleted', { type }));
    },
    [getTypeByEntityType, addNotification]
  );

  const successFavoriteNotification = useCallback(
    (entity) => {
      const type =
        getTypeByEntityType(entity.type) === 'File'
          ? t('rightFileMenu.meta.file')
          : t('rightFileMenu.meta.folder');
      addNotification(
        entity.user_favorites.length
          ? t('favourites.successfullyRemoved', { type })
          : t('favourites.successfullyAdded', { type })
      );
    },
    [getTypeByEntityType, addNotification]
  );

  const optionHandler = useCallback(
    (entity, option) => {
      switch (option.type) {
        case actionsOptions.showMove.type:
        case actionsOptions.openPassword.type:
          if (selectedFiles?.length) {
            fileFolderActionHandlerEffect(selectedFiles, option);
          } else {
            fileFolderActionHandlerEffect(entity, option);
          }
          break;

        case actionsOptions.remove.type:
          fileFolderActionHandlerEffect(entity, option, {
            showSuccessNotification: showSuccessNotification(entity),
            showErrorNotification: showErrorNotification,
          });
          break;

        case actionsOptions.myAI.type:
          fileFolderActionHandlerEffect(entity, option, {
            addNotification,
            showSuccessNotification: () => {successFavoriteNotification(entity)},
          });
          break;
      }
    },
    [
      selectedFiles,
      fileFolderActionHandlerEffect,
      showSuccessNotification,
      successFavoriteNotification,
    ]
  );

  return (
    <>
      <section className={styles.homeContainer}>
        <MainFolderFileContainer
          folderId={folderId}
          isSharedFolder={isSharedFolder}
          optionHandler={optionHandler}
          emptyView={ errorCode === RESPONSE_STATUS_CODE.FORBIDDEN && (<EmptyForbidden />)}
        />
        {welcomeModal && (
          <OnboardingModal
            isOpen={welcomeModal}
            handleClose={setWelcomeModal}
          />
        )}
      </section>
    </>
  );
};

const mapDispatchToProps = {
  getFilesClearEffect,
  getFilesDispatchEffect,
  ghostFilesClearEffect,
  ghostFilesEffect,
  selectedFilesFoldersClear,
  fileFolderActionHandlerEffect,
  createFolderReset,
  handleNftModal,
  getFilesClear,
};

const mapStateToProps = (state) => ({
  ghostFiles: state.home?.ghostFiles?.entities,
  selectedFiles: state.home.selectedFilesFolders?.files,
  errorCode: state.home?.getFiles?.getFilesError?.code,
  sorter: selectSorterState(state),
  filter: selectFilterState(state),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
)(React.memo(HomeContainer));
