import React from 'react';

const UploadFile = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.6665 14.7915H10.375V10.6665L12.229 12.5415L12.729 12.021L10 9.3125L7.2915 12.0415L7.771 12.5415L9.6665 10.6665V14.7915ZM5.375 17.5C5.04167 17.5 4.757 17.382 4.521 17.146C4.28467 16.9097 4.1665 16.6248 4.1665 16.2915V3.7085C4.1665 3.37517 4.28467 3.09033 4.521 2.854C4.757 2.618 5.04167 2.5 5.375 2.5H12.2085L15.8335 6.125V16.2915C15.8335 16.6248 15.7153 16.9097 15.479 17.146C15.243 17.382 14.9583 17.5 14.625 17.5H5.375ZM11.854 6.4585V3.2085H5.375C5.25 3.2085 5.13534 3.2605 5.031 3.3645C4.927 3.46883 4.875 3.5835 4.875 3.7085V16.2915C4.875 16.4165 4.927 16.5312 5.031 16.6355C5.13534 16.7395 5.25 16.7915 5.375 16.7915H14.625C14.75 16.7915 14.8647 16.7395 14.969 16.6355C15.073 16.5312 15.125 16.4165 15.125 16.2915V6.4585H11.854Z"
      fill="currentColor"
    />
  </svg>
);

UploadFile.defaultProps = {};
export default UploadFile;
