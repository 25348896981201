import React, { useEffect, useState, useRef } from 'react';
import CN from 'classnames';
import uuid from 'react-uuid';
import { useHistory } from 'react-router';
import audioBufferToWav from 'audiobuffer-to-wav';

import Header from 'containers/mobile/components/header';
import { ReactComponent as RecordInput } from 'containers/mobile/assets/record.svg';
import { ReactComponent as StopRecordInput } from 'containers/mobile/assets/stopRecord.svg';
import { ReactComponent as SendInput } from 'containers/mobile/assets/send.svg';
import { ReactComponent as LotusInput } from 'containers/mobile/assets/lotus.svg';
import { ReactComponent as IdeaInput } from 'containers/mobile/assets/idea.svg';
import { ReactComponent as DriveInput } from 'containers/mobile/assets/drive.svg';
import { ReactComponent as ElipsInput } from 'containers/mobile/assets/elips.svg';

import { sendMessage, sendVoiceMessageEffect } from 'store/neyra/effects';
import { createConversation } from 'client-neyra';
import MessageItem from './Message';

import { enterKeyPress } from 'utils/actions/enter-key-press';
import { formatSecondsToString } from 'utils/date/formatSecondsToString';

import styles from './styles.module.scss';

const list = [
  {
    name: 'Start Interview ',
    icon: <LotusInput />,
    active: true,
  },
  {
    name: 'Features',
    icon: <IdeaInput />,
  },
  {
    name: 'Benefits',
    icon: <DriveInput />,
  },
  {
    name: 'Token',
    icon: <ElipsInput />,
  },
];

const Chat = ({ token }) => {
  const history = useHistory();
  const [audioStream, setAudioStream] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const audiocontext = useRef();
  const [messages, setMessages] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [message, setMessage] = useState('');
  const [startRecord, startStartRecord] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const initialize = async () => {
      try {
        const {
          data: { conversation_uid },
        } = await createConversation({ body: { avatarId: 1, themeId: 1 } });
        setUserInfo({ token: token, conversation: conversation_uid });
      } catch (error) {
        console.log(error);
      }
    };
    initialize();
  }, []);

  useEffect(() => {
    let interval = null;
    if (startRecord) {
      interval = setInterval(() => {
        setTimer((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else if (!startRecord && timer !== 0) {
      clearInterval(interval);
      setTimer(0);
    }
    return () => clearInterval(interval);
  }, [startRecord, timer]);

  useEffect(() => {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    audiocontext.current = new AudioContext();
  }, []);

  function changeSampleRate(blob, newSampleRate, callback) {
    const reader = new FileReader();
    reader.onload = function (event) {
      const arrayBuffer = event.target.result;

      audiocontext.current.decodeAudioData(arrayBuffer, function (audioBuffer) {
        const newAudioBuffer = resampleAudioBuffer(audioBuffer, newSampleRate);
        const wavData = audioBufferToWav(newAudioBuffer);
        const wavBlob = new Blob([wavData], { type: 'audio/wav' });

        callback(wavBlob);
      });
    };

    reader.readAsArrayBuffer(blob);
  }

  function resampleAudioBuffer(audioBuffer, newSampleRate) {
    const context = audiocontext.current;
    const source = context.createBufferSource();
    source.buffer = audioBuffer;
    const targetSampleRate = newSampleRate;
    const numChannels = source.buffer.numberOfChannels;

    const result = context.createBuffer(
      numChannels,
      Math.round(
        source.buffer.length * (targetSampleRate / source.buffer.sampleRate)
      ),
      targetSampleRate
    );

    for (let i = 0; i < numChannels; i++) {
      const channelData = source.buffer.getChannelData(i);
      const resultData = result.getChannelData(i);
      for (let j = 0; j < result.length; j++) {
        resultData[j] =
          channelData[
            Math.round((j * source.buffer.sampleRate) / targetSampleRate)
          ];
      }
    }
    return result;
  }

  useEffect(() => {
    return () => {
      if (audioStream) {
        audioStream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [audioStream]);

  const startRecording = async () => {
    try {
      const steam = await navigator.mediaDevices.getUserMedia({ audio: true });
      startStartRecord(true);
      setAudioStream(steam);

      if (steam) {
        const newMediaRecorder = new MediaRecorder(steam);
        const chunks = [];

        newMediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            chunks.push(event.data);
          }
        };

        newMediaRecorder.onstop = () => {
          const audioBlob = new Blob(chunks, { type: 'audio/aac' });
          const audioURL = URL.createObjectURL(audioBlob);
          startStartRecord(false);
          changeSampleRate(audioBlob, 16000, function (newWavBlob) {
            const formData = new FormData();
            formData.append('file', newWavBlob);

            handleSendVoiceMessage(formData, audioURL);
          });
        };

        newMediaRecorder.start();
        setMediaRecorder(newMediaRecorder);
      }
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    startStartRecord(false);
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
      audioStream.getTracks().forEach((track) => track.stop());
    }
  };

  const handleLogoClick = () => {
    history.push('/profile');
  };

  const handleOnMenuClick = () => {
    history.push('/profile');
  };

  const handleInputChange = ({ target }) => {
    setMessage(target.value);
  };

  const sendMessageHandler = (message, isWhisper) => {
    const { token, conversation } = userInfo;
    if (!isWhisper) {
      setMessages((list) => {
        return [
          ...list,
          {
            id: uuid(),
            text: message,
            voice: false,
            timeStamp: new Date(),
            isNeyro: false,
          },
        ];
      });
    }

    sendMessage(token, conversation, message, setMessages);
  };

  const onInputKeyPress = (e) => {
    if (enterKeyPress(e) && message) {
      sendMessageHandler(message);
      setMessage('');
    }
  };

  const handleSendVoiceMessage = async (file, blob) => {
    try {
      setMessages((prev) => [
        ...prev,
        {
          id: uuid(),
          text: '',
          voice: true,
          timeStamp: new Date(),
          isNeyro: false,
          blob: blob,
        },
      ]);

      const data = await sendVoiceMessageEffect(file);
      const userMessage = data.data.message;
      sendMessageHandler(userMessage, true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAction = () => {
    if (message) {
      sendMessageHandler(message);
      setMessage('');
    } else if (startRecord) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const sendPrompt = (prompt) => {
    sendMessageHandler(prompt);
  };

  return (
    <div className={styles.chatWrapper}>
      <Header onMenuClick={handleOnMenuClick} onLogoClick={handleLogoClick} />
      <div
        className={CN(styles.content, messages.length && styles.contentFull)}
      >
        {messages.length ? (
          <div className={styles.messenger}>
            {messages.map((item) => (
              <MessageItem message={item} key={item.id} />
            ))}
          </div>
        ) : (
          <>
            <h1 className={styles.contentHeader}>Chat</h1>
            <div className={styles.tagList}>
              {list.map(({ name, icon, active }, index) => (
                <div
                  key={`tag-${index}`}
                  className={CN(styles.tagItem, active && styles.tagItemActive)}
                  onClick={() => {
                    sendPrompt(name);
                  }}
                >
                  <p className={styles.tagName}>{name}</p>
                  <span className={styles.tagIcon}>{icon}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className={styles.chatInput}>
        <div className={styles.input}>
          {startRecord ? (
            <p className={styles.timer}>{formatSecondsToString(timer)}</p>
          ) : (
            <input
              value={message}
              onChange={handleInputChange}
              onKeyDown={onInputKeyPress}
              placeholder="Ask Neyra ..."
              type="text"
              maxLength={256}
            />
          )}
          <div className={styles.inputBorder}></div>
        </div>
        <div className={styles.inputAction}>
          <button onClick={handleAction}>
            {message ? (
              <SendInput />
            ) : startRecord ? (
              <StopRecordInput />
            ) : (
              <RecordInput />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chat;
