import * as actions from '../actions/documents.actions'
import getData from 'utils/request/get-data'
import removeIfEqual from 'utils/array/remove-if-equal'
import getErrorData from 'utils/request/get-error-data'

export const initialState = {
  documents: [],
  count: 0,
  status: false,
  currentPage: 1,
  getFilesError: null,
}

export default (state = initialState, action) => {
  const { type, payload } = action
  let file

  switch (type) {
    case actions.DOCUMENTS_GET:
      return {
        ...state,
        status: true,
      }

    case actions.DOCUMENTS_GET_SUCCESS:
      return {
        ...state,
        documents: [
          ...state.documents,
          ...payload.data.map((item) => ({ ...item })),
        ],
        count: payload.count,
        currentPage: payload.page,
        status: false,
      }

    case actions.SET_DOCUMENTS:
      return {
        ...state,
        documents: payload,
      }

    case actions.DOCUMENTS_GET_FAILURE:
      return {
        ...state,
        status: false,
        getFilesError: getErrorData(action),
      }

    case actions.GET_DOCUMENT_REMOVE:
      file = getData(action)
      return {
        ...state,
        documents: removeIfEqual(state.documents, file, 'slug'),
        count: state.count - 1,
      }

    case actions.CREATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: [getData(action), ...state.documents],
        count: state.count - 1,
      }

    case actions.RESET_STATE:
      return {
        ...initialState,
      }

    case actions.UPDATE_DOCUMENT_SUCCESS:
      return {
        ...state,
        documents: state.documents.map((item) =>
          item.slug === payload.slug ? payload : item
        ),
      }

    default:
      return state
  }
}
