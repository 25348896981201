import React, { useEffect, useState } from 'react';

import { copyToClipboard } from 'utils/string';
import { saveMemoEffect } from 'store/document/effects';
import TriangleAction from 'components/actions/TriangleAction/TriangleAction';
import PlusAction from 'components/actions/PlusAction/PlusAction';
import CircleAction from 'components/actions/CircleAction/CircleAction';

import styles from './MessageActions.module.scss';
import NeyraTooltip from 'components/shared/NeyraTooltip/NeyraTooltip';

interface MessageActionsProps {
  isLiked: boolean;
  isAddedToMemo: boolean;
  textToCopy: string;
}

export default function MessageActions(props: MessageActionsProps) {
  const [isLiked, setIsLiked] = useState(props.isLiked);
  const [isAddedToMemo, setIsAddedToMemo] = useState(props.isAddedToMemo);
  const [isAddToMemoLoading, setIsAddToMemoLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isCopied]);

  const like = (status: boolean) => {
    setIsLiked(status);
  };

  const addToMemo = async (status: boolean) => {
    if (!status) return;
    setIsAddToMemoLoading(true);

    try {
      const content: any = {
        time: new Date().getTime(),
        blocks: [
          {
            id: Math.random().toString(16).slice(2),
            type: 'paragraph',
            data: { text: props.textToCopy },
          },
        ],
        version: '2.26.0',
        name: 'Neyro',
      };
      await saveMemoEffect({ name: 'neyro', content });
      setIsAddedToMemo(true);
      setIsAddToMemoLoading(false);
    } catch (error) {
      console.warn(error);
      setIsAddedToMemo(false);
      setIsAddToMemoLoading(false);
    }
  };

  const copyText = () => {
    setIsCopied(true);
    copyToClipboard(props.textToCopy);
  };

  return (
    <div className={styles.container}>
      <NeyraTooltip
        text={isLiked ? 'Remove Like' : 'Like Response'}
        position="bottom"
      >
        <TriangleAction active={isLiked} activateCb={like} size={'20px'} />
      </NeyraTooltip>

      <NeyraTooltip
        text={isAddedToMemo ? 'Added to Memo' : 'Add to Memo'}
        position="bottom"
      >
        <PlusAction
          active={isAddedToMemo}
          activateCb={addToMemo}
          loading={isAddToMemoLoading}
          size={'20px'}
        />
      </NeyraTooltip>

      <NeyraTooltip text={'Copy'} position="bottom">
        <CircleAction active={isCopied} activateCb={copyText} size={'20px'} />
      </NeyraTooltip>

      {isCopied && <span className={styles.notification}>Copied</span>}
    </div>
  );
}
