import { useCallback } from 'react'

const useColorConverter = () => {
  const hexToRGB = useCallback((color) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : { r: '', b: '', g: '' }
  }, [])

  const colorToHex = useCallback((color) => {
    const hex = color.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }, [])

  const rgbToHex = useCallback((r, g, b) => {
    return '#' + colorToHex(r) + colorToHex(g) + colorToHex(b)
  }, [])

  return { hexToRGB, rgbToHex }
}

export default useColorConverter
