// Refactoring №2
/* eslint-disable no-unused-vars */
import i18next from 'i18next';

import axios from 'axios';
import * as forge from 'node-forge';
import {
  uploadFile,
  LocalFileBuffer,
  getThumbnailImage,
  getThumbnailVideo,
} from 'gdgateway-client/lib/es5';
import { create } from 'ipfs-http-client';

import { saveUploadFileEffectGhostIpfs } from './save-upload-file.effect';
import * as getFilesActions from 'store/home/actions/files/get-files.actions';
import * as uploadActions from 'store/home/actions/upload-file.actions';
import { addTypeGeneratedByAiToFile } from 'store/aiGenerator/effects';
import { setErrors as setGlobalErrors } from 'store/errors/actions/index';
import { cancelFolderUploading } from 'store/home/actions/file/cancel-folder-uploading.action';
import { store } from 'store/root-store';
import { getTotalDownloadFileSize } from 'store/home/effects/entity-actions/entity-make-action.effect';

import RESPONSE_STATUS_CODE from 'config/response-status-code';
import { imagesWithoutPreview } from 'config/image-file-extensions';
import { API_UPLOAD_FILES_IPFS } from 'constants/api-urls';

import { checkDeviceAndScroll } from 'utils/browser';
import getMimeType from 'utils/files-folders/get-mime-type';
import getErrorArray from 'utils/request/get-error-array';
import getToken, { getAccessToken } from 'utils/auth/get-token';
import authRequest from 'utils/request/auth-request';
import { getKeysByWorkspace } from 'utils/crypto/getKeysByWorkspace';
import { saveEncryptedFileKeys } from 'utils/crypto/saveEncryptedFileKeys';
import { encodeFileData } from 'utils/file/library-callbacks';
import { convertArrayBufferToBase64 } from 'utils/file/convertArrayBufferToBase64';
import { setKeyToLS } from 'utils/file/setKeyToLS';
import { canUserEncryptFile } from 'utils/canUserEncryptFile.js';
import { handleConnectWalletModal } from 'features/modals/modal-slice';
import { videoWithoutThumbnail } from 'config/video-file-extensions';
import ERROR from 'config/errors/credits';
import { getFilesBySlugs } from './getFilesBySlugs';

export const ipfs = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
  headers: {
    authorization: getToken(),
  },
});

export function uploadMultiFileEffectRedux({
  files,
  folderId = null,
  addNotification = () => {},
  setErrors,
  isGeneratedByAi = false,
  afterCb = () => {},
  resultCb = () => {},
  userTokens,
  endUpload,
  user,
  freeEncryptionsCount,
  memoSlug = null,
}) {
  const { plan } = store.getState();
  const oneTimeTokenProps = isGeneratedByAi ? { isAiGenerated: true } : {};
  const localNeedEncryption =
    plan?.workspacePlan?.currentWs?.encrypt_files_on_upload;

  let progresses = {};

  for (let i = 0; i < files.length; i++) {
    progresses[files[i]?.folderData?.uploadId] = 0;
  }

  return async (dispatch) => {
    let gateway;
    let oneTimeTokens = [];
    let jwtOneTimeTokens = [];
    if (files.length > 1) {
      let data;
      try {
        const filesData = files.map((file) => ({
          filesize: file.size,
          filename: file.name,
          isPublic: true,
          ...oneTimeTokenProps,
        }));
        data = await getOneTimeToken(filesData);
      } catch (e) {
        const message = e?.response?.data?.errors;
        if (message) {
          for (let i = 0; i < files.length; i++) {
            dispatch(uploadActions.uploadFileFailure(true, files[i].uploadId));
          }
          addNotification(message, 'alert');
          endUpload && endUpload();
          return;
        }
      }

      gateway = data.data.gateway;
      oneTimeTokens = data.data.user_token.map((tokenObj) => tokenObj.token);
      jwtOneTimeTokens = data.data.jwt_ott;
    }
    if (files.length) {
      let error = true;
      const multiUploadFile = async (index) => {
        const file = files[index];
        let oneTimeToken = oneTimeTokens[index];
        let jwtOneTimeToken = jwtOneTimeTokens[index];
        index++;

        if (
          files.length > userTokens &&
          (file.needEncryption || localNeedEncryption)
        ) {
          addNotification(ERROR.INSUFFICIENT_CREDITS, 'alert');
          file.error = true;
          files.forEach((currentFile) => {
            if (currentFile.needEncryption) {
              dispatch(
                uploadActions.uploadFileFailure(true, currentFile.uploadId)
              );
            }
          });
          endUpload && endUpload();
          return;
        }

        if (file.needEncryption) {
          const canEncrypt = await canUserEncryptFile(
            user?.user_public_addresses,
            freeEncryptionsCount
          );
          if (canEncrypt.error) {
            canEncrypt?.message === 'You should have metamask installed'
              ? dispatch(handleConnectWalletModal(true))
              : addNotification(canEncrypt?.message, 'alert');
            file.error = true;
            files.forEach((currentFile) => {
              if (currentFile.needEncryption) {
                dispatch(
                  uploadActions.uploadFileFailure(true, currentFile.uploadId)
                );
              }
            });
            endUpload && endUpload();
            return;
          }
        }

        try {
          if (!gateway && !oneTimeToken) {
            let data;
            try {
              data = await getOneTimeToken([
                {
                  filesize: file.size,
                  filename: file.name,
                  isPublic: true,
                  ...oneTimeTokenProps,
                  ...(memoSlug ? { slug: memoSlug } : {}),
                },
              ]);
            } catch (e) {
              const message = e?.response?.data?.errors;
              if (message) {
                dispatch(uploadActions.uploadFileFailure(true, file.uploadId));

                addNotification(message, 'alert');
                endUpload && endUpload();
                return;
              }
            }
            gateway = data.data.gateway;
            oneTimeTokens = data.data.user_token.map(
              (tokenObj) => tokenObj.token
            );
            jwtOneTimeTokens = data.data.jwt_ott;
            oneTimeToken = oneTimeTokens[0];
            jwtOneTimeToken = jwtOneTimeTokens[0];
          }
          let result;
          let thumbnail;
          const { handlers, callbacks } = encodeFileData;

          const callback = ({ type, params }) => {
            if (handlers.includes(type)) {
              callbacks[type]({ ...params, dispatch });
            } else {
              console.error(`Handler "${type}" isn't provided`);
            }
          };
          const localFileBuffer = new LocalFileBuffer(
            file.size,
            file.name,
            file.mime,
            file.folderId,
            file.uploadId,
            async () => file.arrayBuffer(),
            () => file.stream()
          );
          const { home } = store.getState();
          if (home?.cancelFolderUploading?.isFolderUploadingCanceled) {
            dispatch(cancelFolderUploading(false));
            endUpload();
            return;
          }
          if (file.needEncryption) {
            const key = await crypto.subtle.generateKey(
              { name: 'AES-GCM', length: 256 },
              true,
              ['encrypt', 'decrypt']
            );

            const {
              data: { keys },
            } = await getKeysByWorkspace();
            result = await uploadFile({
              file: localFileBuffer,
              oneTimeToken,
              jwtOneTimeToken,
              gateway,
              callback,
              handlers,
              key,
              progress: progresses[file?.folderData?.uploadId],
              totalSize: file?.folderSize,
              startedAt: file?.startedAt,
            });

            if (result && !result?.failed) {
              const slug = result?.gdGateway
                ? result?.fileInfo?.slug
                : result?.data?.data?.slug;
              const bufferKey = await crypto.subtle.exportKey('raw', key);
              const base64Key = convertArrayBufferToBase64(bufferKey);

              setKeyToLS({ slug, base64Key });

              let encryptedKeys = [];

              for (let i = 0; i < keys.length; i++) {
                const publicKey = forge.pki.publicKeyFromPem(keys[i]);
                const encryptedKey = await publicKey.encrypt(base64Key);
                const encryptedHexKey = forge.util.bytesToHex(encryptedKey);
                encryptedKeys = [
                  ...encryptedKeys,
                  { publicKey: keys[i], encryptedFileKey: encryptedHexKey },
                ];
              }

              saveEncryptedFileKeys({
                slug,
                encryptedKeys: encryptedKeys,
              });
            }
          } else {
            result = await uploadFile({
              file: localFileBuffer,
              oneTimeToken,
              jwtOneTimeToken,
              gateway,
              callback,
              handlers,
              progress: progresses[file?.folderData?.uploadId],
              totalSize: file?.folderSize,
              startedAt: file?.startedAt,
            });
          }
          if (!result || result?.failed) {
            file.error = true;
            dispatch(uploadActions.uploadFileFailure(true, file.uploadId));
            if (
              file.needEncryption &&
              result?.status &&
              result?.status === 412
            ) {
              addNotification(ERROR.INSUFFICIENT_CREDITS, 'alert');
            }
          } else {
            const fileInfo = result?.gdGateway
              ? result?.fileInfo
              : result?.data?.data;

            const slug = fileInfo?.slug;
            if (file?.folderData) {
              progresses[file?.folderData?.uploadId] += file.size;
            }
            try {
              if (
                file.type.startsWith('image') &&
                !imagesWithoutPreview.includes(`.${fileInfo.extension}`)
              ) {
                thumbnail = await getThumbnailImage({
                  file,
                  quality: 3,
                  oneTimeToken,
                  jwtOneTimeToken,
                  endpoint: gateway.url,
                  slug,
                });
              } else if (
                fileInfo?.mime.startsWith('video') &&
                !videoWithoutThumbnail.includes(fileInfo.extension)
              ) {
                thumbnail = await getThumbnailVideo({
                  file,
                  quality: 3,
                  oneTimeToken,
                  jwtOneTimeToken,
                  endpoint: gateway.url,
                  slug,
                });
              }
            } catch (error) {
              console.error(error);
            }
            afterCb && afterCb();
            if (result?.gdGateway) {
              const uploadedFiles = await getFilesBySlugs([fileInfo?.slug]);
              result = {
                data: {
                  data: uploadedFiles[0],
                },
              };
            }
            resultCb && resultCb(result);
            dispatch(uploadActions.uploadFileSuccess(file.uploadId));
            if (!memoSlug) {
              dispatch(
                saveUploadFileEffectGhostIpfs(
                  result,
                  file.folderId || folderId,
                  thumbnail
                )
              );
            }
            if (isGeneratedByAi && result) {
              addTypeGeneratedByAiToFile(fileInfo?.slug);
            }
          }
          if (index < files.length) multiUploadFile(index);
          checkDeviceAndScroll();
          if (index === files.length) {
            endUpload && endUpload();
          }
        } catch (e) {
          const errorTextToken = getErrorArray(e).join('. ');
          file.error = true;
          if (error) {
            if (!axios.isCancel(e))
              addNotification(i18next.t(errorTextToken), 'alert');
            error = false;

            setTimeout(function setError() {
              error = true;
            }, 10000);
          }

          dispatch(uploadActions.uploadFileFailure(e, file.uploadId));
          if (index < files.length) multiUploadFile(index);
          if (e?.response?.data?.code === RESPONSE_STATUS_CODE.ACCESS_DENIED) {
            dispatch(setGlobalErrors(e?.response?.data));
          }
          const errorMessage = getErrorArray(e);
          errorMessage.length &&
            setErrors &&
            setErrors(errorMessage.join(', '));
          throw e;
        }
      };

      multiUploadFile(0);
    }
  };
}

export const uploadFileEffect = async (file) => {
  const url = process.env.REACT_APP_UPLOAD_FILES;
  const params = new FormData();
  params.append('file', file);
  const token = await getAccessToken();
  return axios
    .create({
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Token': token,
      },
    })
    .post(url, params)
    .then((response) => response.data)
    .catch((e) => {
      throw e;
    });
};

export const uploadAvatarFileEffect = async (file, type) => {
  const params = new FormData();
  params.append('file', file);
  const {
    data: { jwt_ott, user_token },
  } = await getOneTimeToken([
    {
      filesize: file.size,
      filename: file.name,
      isPublic: true,
    },
  ]);
  const oneTimeToken = user_token[0].token;
  const url = `${process.env.REACT_APP_API_PATH}/update/logo/${type}`;
  const token = await getAccessToken();
  return axios
    .create({
      headers: {
        'Content-Type': 'multipart/form-data',
        'one-time-token': oneTimeToken,
        'X-Upload-OTT-JWT': jwt_ott[0],
        'X-Token': token,
      },
    })
    .post(url, params)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
};

export const getAvatarFileEffect = async (id, type) => {
  const url = `${process.env.REACT_APP_API_PATH}/get/logo?type=${type}&id=${id}`;
  return axios
    .create({ responseType: 'blob' })
    .get(url)
    .then(async (response) => {
      if ((await response.data.text()) == '{"message":"User logo is null"}')
        throw response.data.text();
      return window.URL.createObjectURL(response.data);
    })
    .catch((e) => {
      throw e;
    });
};

export const uploadWorkspaceAvatarFileEffect = async (file) => {
  const params = new FormData();
  params.append('file', file);
  const {
    data: { jwt_ott, user_token, gateway },
  } = await getOneTimeToken([
    {
      filename: file.name,
      filesize: file.size,
      isPublic: true,
    },
  ]);
  const token = user_token[0].token;
  const url = `${gateway.url}/workspace_logo`;
  return axios
    .create({
      headers: {
        'Content-Type': 'multipart/form-data',
        'one-time-token': token,
        'X-Upload-OTT-JWT': jwt_ott[0],
      },
    })
    .post(url, params)
    .then((response) => response.data.data)
    .catch((e) => {
      throw e;
    });
};

export const getWorkspaceAvatarFileEffect = async (workspaceId) => {
  const url = `${process.env.REACT_APP_IPFS}/workspace_logo`;
  return axios
    .create({
      headers: {
        'x-id': workspaceId,
      },
      responseType: 'blob',
    })
    .get(url)
    .then((response) => {
      return window.URL.createObjectURL(response.data);
    })
    .catch((e) => {
      throw e;
    });
};

export const uploadFileIpfs = async (
  files,
  folderId = null,
  dispatch,
  setErrors
) => {
  const controller = new AbortController();
  try {
    const url = API_UPLOAD_FILES_IPFS;
    const chunk = (arr = files, chunkMaxSize) => {
      let chunkSizes = [0];
      return arr.reduce(
        (acc, item) => {
          item.uploadId = `${item.name}_${item.size}_${item.folderId}`;
          item.folderId = folderId;
          item.controller = controller;
          item.fileType = item?.path?.split('.')?.pop();
          item.mime = getMimeType(item);
          dispatch(uploadActions.uploadFile(item));
          delete item.content;
          const newFile = {
            content: item,
            path: item.uploadId,
            controller: controller,
            uploadId: `${item?.name}_${item?.size}_${item?.folderId}`,
            folderId: folderId,
          };
          let pushed = false;
          for (let index = 0; index < chunkSizes.length; index++) {
            const size = chunkSizes[index];
            if (chunkMaxSize - size > 0) {
              acc[index].push(newFile);
              chunkSizes[index] += item.size;
              pushed = true;
              break;
            }
          }
          if (!pushed) {
            acc.push([newFile]);
            chunkSizes.push(item.size);
          }
          return acc;
        },
        [[]]
      );
    };

    const fileContent = chunk(files, 80000000);

    const apiCall = async (index = 0) => {
      for await (const result of ipfs.addAll(fileContent[index], {
        signal: controller.signal,
        progress: (progress, uploadId) => {
          dispatch(
            uploadActions.uploadChangeProgress({
              progress,
              id: uploadId,
            })
          );
        },
      })) {
        const resultFile = fileContent[index].find(
          (item) => item?.path == result.path
        )?.content;
        const data = {
          key: result.cid.toString(),
          service: 'ipfs',
          name: resultFile?.path,
          size: resultFile?.size,
          mime: getMimeType(resultFile),
          extension: `.${resultFile?.fileType}`,
          folder: folderId,
        };
        const token = await getAccessToken();

        await axios
          .post(url, data, {
            headers: {
              'Content-Type': 'application/json',
              'X-Token': token,
            },
          })
          .then((response) => {
            const file = response.data.data;
            dispatch(
              uploadActions.addSlug(
                `${file.name}_${file.size}_${folderId}`,
                response.data.data
              )
            );
            dispatch(uploadActions.uploadFileSuccess(file.uploadId));
            dispatch(
              getFilesActions.getFilesAdd({
                ...response.data.data,
                folderId: folderId ?? 'main',
              })
            );
          })
          .catch((errors) => {
            const error = getErrorArray(errors);
            setErrors && dispatch(setErrors(error));
          });
      }
      if (index <= fileContent.length) {
        index++;
        apiCall(index);
      }
    };
    if (fileContent) await apiCall();
  } catch (errors) {
    const error = getErrorArray(errors);
    setErrors && dispatch(setErrors(error));
  }
};

/**
 *@param {Array<{filesize?: string, filename?: string, isPublic?: boolean}>} filesData
 *@return {Promise<{data:{jwt_ott:string[],user_token:{token:string}[],gateway:{url:string}}}>}
 *  **/
export const getOneTimeToken = (filesData) => {
  const url = `${process.env.REACT_APP_API_PATH}/user/generate/token`;
  return authRequest.post(url, filesData);
};

/**
 *@return {Promise<{data:{jwt_ott:string,user_tokens:{token:string},gateway:{url:string}}}>}
 *  **/
export const getOneTimeTokenForDeleting = (dataRaw) => {
  const url = `${process.env.REACT_APP_API_PATH}/delete/generate/token`;
  return authRequest.post(url, dataRaw);
};

/**
 *@return {Promise<{data:{jwt_ott:string,user_tokens:{token:string},gateway:{url:string, upload_chunk_size: number}, upload_chunk_size: any}}>}
 *  **/
export const getDownloadOTT = (body, action) => {
  const headers = {};

  if (action) {
    headers['X-Action'] = action.toString();
  }
  const url = `${process.env.REACT_APP_API_PATH}/download/generate/token`;
  return authRequest.post(url, body, {
    headers,
  });
};
export const getTokenizedDownloadOTT = (body) => {
  const url = `${process.env.REACT_APP_API_PATH}/signature/download/generate/token`;
  return authRequest.post(url, body);
};
