// Refactoring №3
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'components/Modal';
import Spinner from 'components/Spinner';

import style from './style.module.scss';

export const ExitUntitledFilePopup = ({ close }) => {
  const { t } = useTranslation('pages');
  return (
    <div className={style.exitPopup}>
      <Modal isOpen={true} onClose={close}>
        <div className={style.exitQuestion}>{t('emptyTitle')}</div>
        <div className={style.exitMessage}>{t('enterTitle')}</div>
        <div className={style.exitButtons}>
          <button className={style.exitClose} onClick={close}>
            {t('ok')}
          </button>
        </div>
      </Modal>
    </div>
  );
};

const ExitPopup = ({ isSaving, save, close, leavePage }) => {
  const { t } = useTranslation('pages');
  return (
    <div className={style.exitPopup}>
      <Modal isOpen={true} onClose={close}>
        <div className={style.exitQuestion}>{t('wantToExit')}</div>
        <div className={style.exitMessage}>{t('changesWillNotBeSaved')}</div>
        <div className={style.exitButtons}>
          <button className={style.exitLeave} onClick={leavePage}>
            {t('closeWithoutSaving')}
          </button>
          <button className={style.exitClose} onClick={save}>
            {isSaving ? <Spinner /> : t('saveChanges')}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ExitPopup;
