import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDisplayed } from 'store/home/actions/upload-file.actions'
import { ReactComponent as PiramidIcon } from '../assets/pyramid.svg';

import styles from './styles.module.scss';

const Uploader = () => {
  const uploadFiles = useSelector((store) => store.home?.uploadFiles?.entities);
  const totalProgress = useSelector((store) => store.home?.uploadFiles?.totalProgress);
  const totalSize = useSelector((store) => store.home?.uploadFiles?.totalSize);
  const displayed = useSelector((store) => store.home?.uploadFiles?.displayed);

  const dispatch = useDispatch();
  const progress = `${Number((totalProgress / totalSize) * 100).toFixed()}%`;

  const onDisplay = () => {
    dispatch(setDisplayed(!displayed))
  }

  return (
    <div className={styles.uploader}>
      <span className={styles.progress}>{progress}</span>
      <span className={styles.countInfo}>{`Uploading (${uploadFiles.length})`}</span>
      <button className={styles.action} onClick={onDisplay} >
        <PiramidIcon transform={displayed ? "rotate(180)" : 'rotate(0)'} />
      </button>
    </div>
  )
}

export default Uploader;