//Refactoring №3
// @flow
import React from 'react';
import { ReactComponent as SkeletIcon } from './assets/skelet.svg';

import classNames from 'classnames';
import s from './style.module.scss';

type Props = {
  count: number,
  viewType?: string,
};

const FilesSkeleton = (props: Props) => {
  const { viewType = '' } = props;
  const countArray: number[] = new Array(props.count).fill(1).map(() => 2);
  const skeletonClasses = classNames({
    [s.filesSkeleton]: true,
    [s.filesSkeleton_sqr]: viewType === 'square',
    [s.filesSkeleton_smallRow]: viewType === 'small-row',
  });

  return (
    <div className={skeletonClasses}>
      <div className={s.filesSkeleton__gradientCover}>
        <SkeletIcon />
      </div>
      <div className={s.filesSkeleton__group}>
        {countArray.map((val, index) => {
          return (
            <div
              key={index}
              className={classNames(
                s.filesSkeleton__desc,
                s.filesSkeleton__element
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FilesSkeleton;
