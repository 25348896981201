//Refactoring №3
import React, { useState, useRef } from 'react';

import PropTypes from 'prop-types';
import CN from 'classnames';

import styles from './style.module.scss';

const HintPopup = ({
  poupClassName,
  className,
  children,
  disabled,
  content,
  width = 'auto',
  orientation,
  horizontal,
  hideArrow,
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const popoverRef = useRef(null);
  const triggerWrapperRef = useRef(null);

  const closePopover = () => {
    setShow(false);
  };

  const openPopover = () => {
    setShow(true);
  };

  return (
    <div
      onMouseLeave={closePopover}
      className={CN(
        styles.wrapper,
        styles[`orientation_${orientation}`],
        styles[`horizontal_${horizontal}`],
        hideArrow && styles.hideArrow,
        className
      )}
    >
      {!disabled && show && (
        <div
          className={CN(styles.popup, poupClassName)}
          style={{ width }}
          ref={popoverRef}
        >
          {content}
        </div>
      )}
      <div
        className={styles.content}
        onMouseEnter={openPopover}
        ref={triggerWrapperRef}
        {...rest}
      >
        {children}
      </div>
    </div>
  );
};

HintPopup.propTypes = {
  poupClassName: PropTypes.string,
  children: PropTypes.any,
  open: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  displayArrow: PropTypes.bool,
};

HintPopup.defaultProps = {
  open: false,
  disabled: false,
  displayArrow: true,
  className: '',
  poupClassName: '',
};

export default HintPopup;
