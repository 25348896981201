import { selectNeyraProfile } from 'features/neyra';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { saveSettings } from 'client-neyra';
import getToken from 'utils/auth/get-token';

const isRecentNewUser = (userInfo) => {
  const startOfInspection = 1717362000000;

  if (userInfo?.settings === null) {
    const createdAt = new Date(userInfo.created_at);
    if (createdAt > startOfInspection) {
      return true;
    }
  }
  return false;
};

export const neyraUserSettings = {
  chat_settings: {
    chat: true,
    wiki: true,
    form: true,
    bios: false,
    idea: true,
    vote: true,
    find: false,
  },
  ui_variants: {
    dark_theme: false,
    language: false,
    size_text: true,
    fast_typing: true,
  },
};

const useNewlyRegisteredUser = () => {
  const history = useHistory();
  const token = getToken();
  const user = useSelector(selectNeyraProfile);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    const checkUser = async () => {
      if (isRecentNewUser(user)) {
        history.push('/welcome');
      } else if (user.settings === null) {
        try {
          await saveSettings({ body: { settings: neyraUserSettings } });
        } catch (error) {
          console.error(error);
        }
        setCheck(true);
      }
    };

    token && !check && checkUser();
  }, [user, check]);
};

export default useNewlyRegisteredUser;
