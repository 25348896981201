import authRequest from 'utils/request/auth-request';
import { createSecurityReqObj } from 'utils/request/create-security-req-obj';
import { API_GET_FILE } from 'constants/api-urls';
import * as actions from '../../actions/file/delete-file.actions';
import * as getFilesActions from '../../actions/files/get-files.actions';
import * as ghostFilesActions from '../../actions/ghost-files.actions';

export default async (
  fileId,
  user,
  shareType,
  canComment = false,
  canEdit = false,
  message,
  expiredAt = 0
) => {
  const url = `${API_GET_FILE}/${fileId}/share/${shareType}`;

  return authRequest
    .post(url, {
      input: user,
      canComment: canEdit || canComment,
      canEdit,
      message,
      expiredAt,
    })
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};

export const updateShareEffect = async (
  fileId,
  shareId,
  canComment = false,
  canEdit = false,
  expiredAt = 0
) => {
  const url = `${API_GET_FILE}/${fileId}/share/${shareId}`;

  return authRequest
    .put(url, {
      canComment: canEdit || canComment,
      canEdit,
      expiredAt,
    })
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};

export const unshareFile = async (fileId, shareId) => {
  const url = `${API_GET_FILE}/${fileId}/share/${shareId}`;

  return authRequest
    .delete(url)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};

export const removeFileFromSharedEffect =
  (file, afterCb, securityData) => async (dispatch) => {
    dispatch(actions.fileDelete());

    const shareId = file?.shares?.filter((share) => share?.type === 3)[0]?.id;
    const url = `${API_GET_FILE}/${file?.slug}/share/${shareId}`;
    await authRequest
      .delete(url, {
        params: {
          ...createSecurityReqObj(securityData),
        },
      })
      .then((response) => {
        dispatch(actions.fileDeleteSuccess());
        dispatch(getFilesActions.getFilesRemove(file));
        file.ghost_mode && dispatch(ghostFilesActions.ghostFileRemove(file));
        return response.data;
      })
      .catch((e) => {
        dispatch(actions.fileDeleteFailure(e));
        throw e;
      });

    afterCb(file);
  };
