import authRequest from 'utils/request/auth-request';

export const getFilesBySlugs = async (slugs) => {
  const queryString = slugs
    .map((slug) => `slugs=${encodeURIComponent(slug)}`)
    .join('&');
  const url = `${process.env.REACT_APP_API_PATH}/files/file/by-slugs?${queryString}`;

  const retryRequest = async (retries = 2, delay = 1000) => {
    try {
      const response = await authRequest.get(url);

      return response.data.data;
    } catch (error) {
      if (error.response?.status === 404 && retries > 0) {
        await new Promise((resolve) => setTimeout(resolve, delay));
        return retryRequest(retries - 1, delay);
      }
      throw error;
    }
  };

  return retryRequest();
};
