// Refactoring №3
import { createSlice } from '@reduxjs/toolkit';

const defaultSidebarWidth = 240;

const leftMenu = createSlice({
  name: 'leftMenu',
  initialState: {
    sidebarIsOpen: true,
    sidebarVisibility: true,
    aiSidebarMenu: true,
    workspaceMenuIsOpen: false,
    nftIsOpen: false,
    collapsed: false,
    width: window.screen.width > 480 ? defaultSidebarWidth : 76,
    workspaces: {
      loading: false,
      error: false,
      data: [],
    },
  },
  reducers: {
    toggleSidebar: (state) => {
      state.sidebarIsOpen = !state.sidebarIsOpen;
    },
    setSidebarVisibility: (state, action) => {
      state.sidebarVisibility = action.payload;
    },
    setAiSidebarMenu: (state, action) => {
      state.aiSidebarMenu = action.payload;
    },
    toggleWorkspaceMenu: (state, action) => {
      if (action.payload || typeof action.payload === 'boolean') {
        state.workspaceMenuIsOpen = action.payload;
      } else {
        state.workspaceMenuIsOpen = !state.workspaceMenuIsOpen;
      }
    },
    toggleNftMenu: (state) => {
      state.nftIsOpen = !state.nftIsOpen;
    },
    toggleCollapsed: (state, action) => {
      state.collapsed = action.payload;
    },
    onChangeWidth: (state, action) => {
      state.width = action.payload;
    },
    loadWorkspacesStarted: (state) => {
      state.workspaces.loading = true;
      state.workspaces.error = false;
    },
    loadWorkspacesSuccess: (state, action) => {
      state.workspaces.data = action.payload;
      state.workspaces.loading = false;
      state.workspaces.error = false;
    },
    loadWorkspacesError: (state) => {
      state.workspaces.loading = false;
      state.workspaces.error = true;
    },
  },
});

export default leftMenu.reducer;

export const toggleLeftMenu = () => {};
export const toggleSidebar = leftMenu.actions.toggleSidebar;
export const setSidebarVisibility = leftMenu.actions.setSidebarVisibility;
export const toggleWorkspaceMenu = leftMenu.actions.toggleWorkspaceMenu;
export const toggleNftMenu = leftMenu.actions.toggleNftMenu;
export const setAiSidebarMenu = leftMenu.actions.setAiSidebarMenu;
export const toggleCollapsed =
  window.innerWidth < 480 ? false : leftMenu.actions.toggleCollapsed;
export const onChangeWidth = leftMenu.actions.onChangeWidth;

export const loadWorkspacesStarted = leftMenu.actions.loadWorkspacesStarted;
export const loadWorkspacesSuccess = leftMenu.actions.loadWorkspacesSuccess;
export const loadWorkspacesError = leftMenu.actions.loadWorkspacesError;
export const loadWorkspaces = () => async (dispatch) => {
  try {
    dispatch(loadWorkspacesStarted());

    dispatch(loadWorkspacesSuccess());
  } catch (e) {
    dispatch(loadWorkspacesError());
  }
};

export const selectUserStorage = (state) => ({
  storage: state.account.userInfo.entities.storage,
  available: state.account.userInfo.entities.space_available,
});
export const selectSidebarIsOpen = (state) => state.leftMenu.sidebarIsOpen;
export const selectNftMenuIsOpen = (state) => state.leftMenu.sidebarIsOpen;
export const selectCollapsed = (state) => state.leftMenu.collapsed;
export const selectWorkspaceMenuIsOpen = (state) =>
  state.leftMenu.workspaceMenuIsOpen;
export const selectSidebarWidth = (state) => state.leftMenu.width;
export const selectSidebarVisibility = (state) =>
  state.leftMenu.sidebarVisibility;
