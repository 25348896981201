import React from "react";

import NeyraSidebar from 'components/NeyraSidebar';
import ChatSidebar from 'containers/auth/Terminal/ChatSidebar';
import SidebarFooter from 'containers/main/NeyraDriveContainer/components/SidebarFooter';

import BiosSidebar from 'containers/auth/NeyraChat/components/BiosSidebar';
import TgAnalyzeSidebar from 'containers/auth/Terminal/TgAnalyzeSidebar';

import { isBiosPage, isTGAnalyzePage } from "utils/url/is-page";

import styles from './styles.module.scss'

const Sidebar = () => {
  const isBios = isBiosPage();
  const isTgAnalyze = isTGAnalyzePage();

  const renderSidebar = () => {
    if (isBios)
      return <BiosSidebar />
    if (isTgAnalyze)
      return <TgAnalyzeSidebar />

    return <ChatSidebar />
  }

  return (
    <NeyraSidebar>
      <div className={styles.sidebar}>
        <div className={styles.sidebarList} >
          { renderSidebar() }
        </div>
        <SidebarFooter />
      </div>
    </NeyraSidebar>
  )
}

export default Sidebar;