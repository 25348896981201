import React, { useState, useRef, useLayoutEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import useClickOutside from 'utils/hooks/use-click-outside';
import EntitySettings from 'store/home/contexts/entity-settings';
import UserIcon from 'components/users/user-icon';
import Button, { ButtonTheme } from 'components/Button';

const CommentItem = ({
  comment: { id, author, children, text, datetime },
  saveComment,
  showActive,
}) => {
  const { t } = useTranslation('owner');
  const {
    props: { activeComment },
  } = useContext(EntitySettings);
  const [showReplyInput, setShowReplyInput] = useState(
    () => showActive && id === activeComment?.id * 1
  );

  const [loadingComment, setLoadingComment] = useState(false);
  const inputRef = useRef(null);
  const inputWrapperRef = useRef(null);

  useLayoutEffect(() => {
    if (showReplyInput && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showReplyInput, inputRef]);

  const onCancelComment = () => setShowReplyInput(false);

  useClickOutside(inputWrapperRef, onCancelComment);

  const repliesContent =
    children && children.length > 0 ? (
      <div className="entity-settings__message-comment-replies">
        {children.map((reply) => (
          <CommentItem
            comment={reply}
            key={`reply-item-${reply.id}`}
            showActive={false}
          />
        ))}
      </div>
    ) : null;

  const addCommentHandler = () => {
    if (inputRef && inputRef.current) {
      const comment = inputRef.current.value.trim();
      setLoadingComment(true);
      saveComment(comment, id).then(() => {
        inputRef.current.value = '';
        setShowReplyInput(false);
        setLoadingComment(false);
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addCommentHandler();
    }
  };

  const replyClickHandler = () => {
    setShowReplyInput(true);
  };

  const inputContent = showReplyInput ? (
    <div
      className="entity-settings__message-input__reply-block"
      ref={inputWrapperRef}
    >
      <input
        type="text"
        className="entity-settings__message-input"
        placeholder={t('rightFileMenu.comment.typeComment')}
        ref={inputRef}
        onKeyPress={handleKeyPress}
      />
      <div className="entity-settings__message-input-buttons">
        <button
          type="button"
          className="link link--shrink cancel"
          onClick={onCancelComment}
        >
          {t('common.cancel')}
        </button>
        <Button
          theme={ButtonTheme.DARK}
          className="save"
          onClick={addCommentHandler}
          disabled={loadingComment}
        >
          {t('rightFileMenu.comment.comment')}
        </Button>
      </div>
    </div>
  ) : null;

  const replyButton =
    saveComment && !showReplyInput ? (
      <button
        type="button"
        className="link link--shrink"
        onClick={replyClickHandler}
      >
        {t('rightFileMenu.comment.reply')}
      </button>
    ) : showReplyInput ? (
      <>
        {/*<button type="button" className="link link--shrink">EDIT</button>*/}
        {/*<button type="button" className="link link--shrink">DELETE</button>*/}
      </>
    ) : null;

  return (
    <section className="entity-settings__message-item">
      <div
        className={cn(
          'entity-settings__message-comment-block',
          showReplyInput && 'active'
        )}
      >
        <div className="entity-settings__message-user-icon">
          <UserIcon profile user={author} />
        </div>
        <div className="entity-settings__message-item-content">
          <div className="entity-settings__message-user-name">
            {author?.displayed_name || author?.username}
            {datetime && (
              <div className="entity-settings__message-date">{datetime}</div>
            )}
          </div>
          <div className="entity-settings__message-comment">
            {text}
            {replyButton}
          </div>
          {inputContent}
        </div>
      </div>
      {repliesContent}
    </section>
  );
};

CommentItem.defaultProps = {
  showActive: true,
};

export default CommentItem;
