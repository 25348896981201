import React from "react";

import SidebarButton from './SidebatButton';
import SidebarInput from './SidebarInput';


import styles from '../styles.module.scss';

export const PublishInfo = ({ onUpdateAvatar, publishValues, onChnage, isActivated }) => {

  const handlePublist = () => {
    onUpdateAvatar(true)
  }

  return (
    <div className={styles.publishInfo}>
      <div className={styles.publishInfoText}>
        <h3>INCLUDE</h3>
        <p>Share revenue Program (70%)</p>
        <p>1 year membership</p>
        <p>Prime features</p>
        <p>Domain Name</p>
        <p>100GB Storage</p>
        <p>API KEY</p>
      </div>
      <SidebarInput
        value={publishValues.domain}
        name="domain"
        onChnage={onChnage}
        maxLength={256}
        placeholder="Domain"
      />
      <SidebarButton
        className={styles.create}
        onClick={handlePublist}
      >
        { isActivated ? ' Update Domain ' : 'Publish' }
      </SidebarButton>
    </div>
  )
}

export default PublishInfo