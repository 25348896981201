export const titles = {
  64: 'Imagination',
  63: 'Efficiency Analysis',
  62: 'Attention to Details',
  61: 'Inner truth',
  60: 'Preparation for Innovations',
  59: 'Convergence',
  58: 'Viability',
  57: 'Intuition',
  56: 'Speaker',
  55: 'Spirit Awakening',
  54: 'Ambitions',
  53: 'Endeavors',
  52: 'Concentration',
  51: 'Shock',
  50: 'True values',
  49: 'Reform',
  48: 'Deep Solutions',
  47: 'Comprehension',
  46: 'Success through Health',
  45: 'Wealth management',
  44: 'Vigilance',
  43: 'Inner voice',
  42: 'Growth and expansion',
  41: 'Premonition',
  40: 'Willpower',
  39: 'Provocation of Spirit',
  38: 'Warrior',
  37: 'Friendship',
  36: 'Compassion',
  35: 'Passion for Changes',
  34: 'Power',
  33: 'Self awareness',
  32: 'Adaptation',
  31: 'Leader',
  30: 'Desires',
  29: 'Commitment',
  28: 'Life is a Game',
  27: 'Care',
  26: 'Sales',
  25: 'Love of Life',
  24: 'Analytics',
  23: 'Simplicity of expression',
  22: 'Emotional openness',
  21: 'Power',
  20: 'Awareness of a Moment',
  19: "Sensitivity to People's Demands",
  18: 'Correction',
  17: 'Opinions',
  16: 'Skills',
  15: 'Love and Acceptance of Extremes',
  14: 'Fruitful Power',
  13: 'Art of listening',
  12: 'Power of Expressed Emotions',
  11: 'Ideas',
  10: 'Self love',
  9: 'Focus on details',
  8: 'Contribution to a Common',
  7: 'Guide',
  6: 'Diplomacy',
  5: 'Paces of Life',
  4: 'Answers and solutions',
  3: 'Arrangement and Innovation',
  2: 'Sense of direction',
  1: 'Creativity',
};