import authRequest from 'utils/request/auth-request';
import { API_FILE } from 'constants/api-urls';

export const updateDataFoutEffect = async (slug, data) => {
  const url = `${API_FILE}/${slug}/meta`;
  return authRequest
    .post(url, data)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};
export const getMetaEntityInformationEffect = async (slug) =>
  authRequest
    .get(`${API_FILE}/${slug}/meta`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });
