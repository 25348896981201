// Refactoring №3
import React, { useState, useEffect } from 'react';

import { read, utils } from 'xlsx';
import { getPreviewFileBlob } from 'store/home/effects/file/get-preview-file-blob';

import s from './style.module.scss';

const ExcelViewer = ({ file }) => {
  const [htmlString, setHtmlString] = useState('');

  useEffect(() => {
    getPreviewFileBlob({ file }).then((blobData) => {
      if (blobData) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const htmlString = utils.sheet_to_html(worksheet);

          setHtmlString(htmlString);
        };

        reader.readAsArrayBuffer(blobData);
      }
    });
  }, [file]);

  return (
    <div className={s.container}>
      <div className={s.excelViewer}>
        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
      </div>
    </div>
  );
};

export default ExcelViewer;
