//Refactoring №2
// @ts-nocheck
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';

import { useReactive, useUpdateEffect } from 'ahooks';
import { ethers } from 'ethers';
import cn from 'classnames';

import { injected } from 'utils/crypto/wallet/connectors';
import { loginMetamask, getNonceEffect } from 'store/auth/effects/index';

import Button from 'components/Button';
import CryptoCheckPluginModal from './crypto-check-plugin-modal';

const cryptoData = [
  {
    icon: require('static/assets/img/crypto/coinbase.png').default,
    name: 'CoinBase',
  },
  {
    icon: require('static/assets/img/crypto/metamask.png').default,
    name: 'Metamask',
  },
  {
    icon: require('static/assets/img/crypto/avalanch.png').default,
    name: 'Avalanch',
  },
  {
    icon: require('static/assets/img/crypto/argent.png').default,
    name: 'Argent',
  },
];

export const NONCE_LIFE_IN_MILLISECONDS = 60 * 9 * 1000;

export const signTokenizedMessage = async (
  setErrors,
  incomingMessage = '',
  action = undefined
) => {
  const message =
    incomingMessage ||
    'Welcome to Neyra Network. Your ID for this signature request is';
    // 'Welcome to Neyra Network. Your ID for this signature request is: ';
  try {
    const getSignature = JSON.parse(localStorage.getItem('localSignature'));
    const timestamp = Date.now();
    if (
      getSignature &&
      timestamp - getSignature.timestamp < NONCE_LIFE_IN_MILLISECONDS
    ) {
      return getSignature;
    }

    await window.metamask.send('eth_requestAccounts');
    const provider = new ethers.providers.Web3Provider(window.metamask);
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    const nonce = await getNonceEffect(address, action);
    const signature = await signer.signMessage(message + nonce);
    const localSignature = { signature, timestamp, address };
    localStorage.setItem('localSignature', JSON.stringify(localSignature));

    return {
      timestamp,
      signature,
      address,
    };
  } catch (err) {
    setErrors && setErrors(err.message, err?.code);
    throw err;
  }
};

export const signMessage = async (
  setErrors,
  currentProvider,
  withNonce = true,
  msg = null
) => {
  const message =
    msg ||
    process.env.METAMASK_MESSAGE ||
    'Welcome to Neyra Network. Your ID for this signature request is';
  try {
    const provider = new ethers.providers.Web3Provider(
      currentProvider ?? window.metamask
    );
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    const nonce = withNonce ? await getNonceEffect(address) : '';

    const normalizedMessage = withNonce ? `${message}: ${nonce}` : message;
    const signature = await signer.signMessage(normalizedMessage);

    return {
      message,
      signature,
      address,
    };
  } catch (err) {
    setErrors && setErrors(err.message, err?.code);
    throw err;
  }
};
export const addWalletMessage = async (email, currentProvider) => {
  const message =
    process.env.METAMASK_MESSAGE ||
    'Do you want to connect your wallet to your Account: ';
  const provider = new ethers.providers.Web3Provider(currentProvider);
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  const signature = await signer.signMessage(message + email);

  return {
    signature,
    address,
  };
};
export const addEmailMessage = async (email) => {
  const message = 'GhostDrive Account Email: ';
  await window.metamask.send('eth_requestAccounts');
  const provider = new ethers.providers.Web3Provider(window.metamask);
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  const signature = await signer.signMessage(message + email);
  return {
    message,
    signature,
    address,
  };
};
export const sharedByLinkCheckUser = async () => {
  try {
    const message =
    'Welcome to Neyra Network. Your ID for this signature request is';
      // 'Welcome to Neyra Network. Your ID for this signature request is: ';
    await window.metamask.send('eth_requestAccounts');
    const provider = new ethers.providers.Web3Provider(window.metamask);
    const signer = provider.getSigner();
    const address = await signer.getAddress();
    const nonce = await getNonceEffect(address);
    const signature = await signer.signMessage(message + nonce);
    return {
      signature,
      address,
    };
  } catch (err) {
    return err;
  }
};

function SignUpCrypto() {
  const history = useHistory();
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = React.useState();
  const state = useReactive({
    checked: '',
    modalOpen: false,
    signature: '',
  });
  const { error, activate, account } = useWeb3React();

  const onCryptoSelect = (value) => {
    state.checked = value;
  };

  useEffect(() => {
    if (
      error?.message === 'The user rejected the request.' ||
      error?.code === -32002
    )
      return;
    if (error) state.modalOpen = true;
  }, [error]);

  useUpdateEffect(() => {
    loginMetamask(account, state.signature, history, dispatch);
  }, [account?.length < 4, state.signature?.length < 4]);

  const connect = async () => {
    try {
      await activate(injected);
    } catch (error) {
      state.modalOpen = true;
    }
  };

  const handleSign = async (e) => {
    e.preventDefault();
    await connect().then(async () => {
      setErrors();
      const sig = await signMessage(setErrors).then((res) => res.signature);
      if (sig) {
        state.signature = sig;
      }
    });
  };

  const baseCls = 'crypto-auth-sign-up';
  return (
    <>
      <CryptoCheckPluginModal
        onClose={() => {
          state.modalOpen = false;
        }}
        isOpen={state.modalOpen}
      />
      <section className={`auth-invite ${baseCls} crypto-auth-sign-up`}>
        <header className="head__content">
          <Link to="/" className="header__logo">
            <img
              src={require('static/assets/img/ghost-logo-blue.svg').default}
              alt=""
            />
          </Link>
          <div className={`${baseCls}-wrapper`}>
            <div className={`${baseCls}-header`}>
              <p className={`${baseCls}-header-title`}>
                Log in with Crypto Wallet
              </p>
              <p className={`${baseCls}-header-description`}>
                Choose Crypto Wallet
              </p>
            </div>
            <div className={`${baseCls}-icons-wrapper`}>
              {cryptoData.map((item, index) => (
                <button
                  key={index}
                  className={cn(`${baseCls}-select-item`, {
                    [`${baseCls}-select-item-selected`]:
                      state.checked === item.name,
                  })}
                  onClick={() => {
                    onCryptoSelect(item.name);
                  }}
                >
                  <div className={`${baseCls}-select-item-img-wrapper`}>
                    <img alt="metamask" src={item.icon} />
                  </div>
                  <p className={`${baseCls}-select-item-text`}>{item.name}</p>
                </button>
              ))}
            </div>

            <Button
              className={`${baseCls}-select-item-button`}
              htmlElement="button"
              type="button"
              value="Confirm selection"
              onClick={handleSign}
            />
            <div
              onClick={() => history.goBack()}
              className={`${baseCls}-select-item-go-back`}
            >
              Back
            </div>
          </div>
        </header>
      </section>
    </>
  );
}

export default React.memo(SignUpCrypto);
