export const validationRegExp = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  username: /^([a-zA-Z0-9 _]+)$/,
  hasLowCaseCharacter: /[a-z]/,
  hasUpperCaseCharacter: /[A-Z]/,
  // prettier-ignore
  isLink: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/, // eslint-disable-line no-useless-escape
  // eslint-disable-next-line no-useless-escape
  isContainSpecialCharacters: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,

  hasDigits: /\d/,
  hasOnlyNumbers: /^\d+$/,
  isValidPhone: /^\d{10,16}$/,
  URLHasProtocol: new RegExp('^(https?:\\/\\/)', 'i'),
  ip: /^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/,
  web3Text: new RegExp('[A-Za-z0-9][A-Za-z0-9 \\-]*[A-Za-z0-9]|[A-Za-z0-9]'),
  collection: /^[a-zA-Z0-9\s]+$/,
  uuidRegex: /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i,
};
