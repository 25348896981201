//Refactoring №4
import React, { useCallback, useEffect, useState } from 'react';

import { useField } from 'formik';

import AppSelect from 'components/AppSelect';

type SelectFieldFormikProps = {
  name: string;
  className: string;
  label?: string;
  options: { label: string; value: number }[];
  newStyles?: string;
  defaultValue: unknown;
  hideLabel?: boolean;
  placeholder?: string;
};

export default function SelectFieldFormik(props: SelectFieldFormikProps) {
  const { name, className, label, options, newStyles, defaultValue } = props;
  const [ field, , { setValue }] = useField(name);
  const [currentValue, setCurrentValue] = useState(field.value ? options.find((option) => option.value === field.value) : defaultValue);

  useEffect(() => {
    setCurrentValue(options.find((option) => (option.value === field.value)))
  }, [field.value])

  const onChange = useCallback(
    (selectedOption: any) => {
      setCurrentValue(selectedOption);
      setValue(selectedOption.value);
    },
    [setValue]
  );

  return (
    <AppSelect
      isDisabled={false}
      clearable={false}
      {...props}
      value={currentValue || defaultValue}
      className={className}
      newStyles={newStyles}
      isSearchable={false}
      options={options}
      name={name}
      label={label}
      onChange={onChange}
    />
  );
}
