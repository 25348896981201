import { SET_ERRORS } from '../actions'

const initialsState = {
  errors: null,
}

export default (state = initialsState, action) => {
  const { type, payload } = action
  switch (type) {
    case SET_ERRORS:
      return {
        errors: payload.errors,
        code: payload.code,
      }

    default:
      return state
  }
}
