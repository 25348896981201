//Refactoring №4
import React from 'react';

import cn from 'classnames';
import s from './style.module.css';

export default function Spinner({ className }: { className?: string }) {
  return (
    <div className={cn(s.ldsEllipsis, className)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
