export const NotificationActionTypes = {
  setNotifications: 'SET_NOTIFICATIONS',
  reviewsNotifications: 'REVIEWS_NOTIFICATIONS',
  setNextNotificationsByType: 'SET_NEXT_NOTIFICATION_BY_TYPE',
  getNextUnreadNotifications: 'GET_NEXT_UNREAD_NOTIFICATIONS',
  updateNotification: 'UPDATE_NOTOFOCATION',
  reset: 'reset',
}

export const NOTIFICATION_TYPES = {
  read: 1,
  unread: 0,
}

export const setNotifications = (notifications) => ({
  payload: notifications,
  type: NotificationActionTypes.setNotifications,
})

export const reviewsNotifications = (id) => ({
  payload: { id },
  type: NotificationActionTypes.reviewsNotifications,
})

export const setNextNotificationsByType = (page, notifications, type) => ({
  payload: { page, notifications, type },
  type: NotificationActionTypes.setNextNotificationsByType,
})

export const updateNotification = (id, notification) => ({
  payload: { id, notification },
  type: NotificationActionTypes.updateNotification,
})
