//Refactoring №4
import React, { useState } from 'react';

import EyeIcon from 'components/svg/eye';

import CN from 'classnames';
import s from './style.module.scss';

type AccountInputProps = {
  className: string;
  errorClassName: string;
  name: string;
  label: string;
  value: string;
  placeholder: string;
  description: string;
  type: string;
  tabIndex?: number;
  isPassword: boolean;
  disabled: boolean;
  onKeyDown: () => void;
  onChange: () => void;
  onBlur: () => void;
  hidden?: boolean;
  hasError: boolean;
  errorMsg: string;
  autoComplete: string;
  labelClass: undefined | string;
  tooltip?: any;
};

const AccountInput = ({
  className,
  errorClassName,
  label,
  value,
  onChange,
  onBlur,
  placeholder,
  name,
  description,
  hidden,
  type,
  hasError,
  errorMsg,
  isPassword,
  autoComplete,
  disabled,
  tooltip,
  tabIndex,
  onKeyDown,
  labelClass = undefined,
}: AccountInputProps) => {
  const [inputType, setInputType] = useState(type);

  if (hidden) return null;

  const changeType = () => {
    if (inputType === 'text') {
      setInputType(type);
    } else {
      setInputType('text');
    }
  };

  return (
    <label className={CN(labelClass || s.label, className)}>
      {label}
      <input
        name={name}
        type={inputType}
        className={CN(s.input, {
          error: hasError,
          [errorClassName]: hasError,
        })}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        autoComplete={autoComplete}
        tabIndex={tabIndex}
        onKeyDown={onKeyDown}
      />
      {isPassword && (
        <button type="button" className={s.input__button} onClick={changeType}>
          <EyeIcon color="#ffffff" />
        </button>
      )}
      {hasError && errorMsg && (
        <p className={CN(s.input__description, s.input__error)}>{errorMsg}</p>
      )}
      {description && <p className={s.input__description}>{description}</p>}
      {tooltip}
    </label>
  );
};

export default AccountInput;
