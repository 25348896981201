//Refactoring №5
import React from 'react';
import { Link } from 'react-router-dom';

import style from './style.module.scss';

const Footer = () => {

  return (
    <div className={style.footer}>
      <p className={style.signUp}>Don’t have a GhostDrive account? <Link className={style.internalLink} to='/sign-up'>Sign up</Link></p>
      <p className={style.links}>
        {`By continuing, you're agreeing to our `}
        <a className={style.link} target='_blank' href='https://www.ghostdrive.com/terms-of-service' rel="noreferrer">Customer Terms of Service , </a>
        <a className={style.link} target='_blank' href='https://www.ghostdrive.com/privacy' rel="noreferrer">Privacy Policy </a>
        and
        <a className={style.link} target='_blank' href='https://www.ghostdrive.com/privacy' rel="noreferrer">Cookie Policy</a>
      </p>
    </div>
  );
};

export default Footer;
