//Refactoring №3
import React, { useContext } from 'react';

import cn from 'classnames';
import { ThemeContext, LIGHT_THEME } from 'store/context/theme';

import s from './style.module.scss';

export const checkboxStyle = {
  LIGHT: s.styleLight,
  DARK: s.styleDark,
  GRAY: s.styleGray,
  PRIME: s.stylesPrime,
};

export default function Checkbox({
  id,
  name,
  className,
  onClick,
  inputStyle = checkboxStyle.LIGHT,
  checked = false,
  stopPropagation = true,
}) {
  const themeContext = useContext(ThemeContext);
  const themeClass = themeContext?.theme === LIGHT_THEME ? s.lightClass : s.darkClass 

  return (
    <label
      className={cn(s.wrapper, inputStyle, themeClass, className)}
      onDoubleClick={(e) => e.stopPropagation()}
      data-test="checkbox"
    >
      <input
        id={id}
        checked={checked}
        onChange={(e) => {
          if (stopPropagation) {
            e.stopPropagation();
          }
          if (onClick) {
            onClick(e);
          }
        }}
        name={name}
        onClick={(e) => {
          e.stopPropagation();
        }}
        type="checkbox"
      />
      <span className={s.checkmark} />
    </label>
  );
}
