// @ts-nocheck
// libs
import { combineReducers } from 'redux';

// libs reducers
import { reducer as formReducer } from 'redux-form';

// reducers
import { accountSettingsReducer as account } from './account/reducers';
import { homeReducer as home } from './home/reducers';
import notifications from './notifications/reducers';
import errors from './errors/reducers';
import { paymentReducer as payment } from './payment/reducers';
import { planReducer as plan } from './plan/reducers';
import leftMenu from '../features/leftMenu/leftMenuSlice';
import app from '../features/app';
import neyra from '../features/neyra';
import studio from '../features/studio';
import getInvite from 'features/getInvite/getInviteSlice';
import profile from 'features/profile/profileSlice';
import speaker from 'features/speaker/speakerSlice';
import modals from 'features/modals/modal-slice';
import editForm from 'features/form';
import workspaceSettings from 'features/workspaceSettings/workspaceSettingsSlice';
import taskManager from 'features/tasksManager/taskManagerSlice';
import { api as CryptoApi } from './auth/crypto-api';
import unsubscribe, { api as unsubscribeApi } from './unsubscribe';

const rootReducer = combineReducers({
  form: formReducer,
  app,
  home,
  account,
  payment,
  plan,
  leftMenu,
  getInvite,
  profile,
  workspaceSettings,
  taskManager,
  notifications,
  unsubscribe,
  modals,
  errors,
  speaker,
  neyra,
  studio,
  editForm,
  [unsubscribeApi.reducerPath]: unsubscribeApi.reducer,
  [CryptoApi.reducerPath]: CryptoApi.reducer,
});

export default rootReducer;
