import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Form, Formik } from 'formik';

import InputFieldFormik from 'components/InputFieldFormik';

import styles from './styles.module.scss';

const initialValues = {
  email: '',
};

export const ForgotPassword = ({ formRef, submitHandler, onFocus, errors }) => {
  const { t } = useTranslation('auth');
  return (
    <>
      <div className={styles.forgotPasswordTitle}>
        <h2>{t('emailLogin.resetPassword')}</h2>
        <p>{t('emailLogin.provideEmail')}</p>
      </div>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={submitHandler}
      >
        <Form>
          <div className={styles.fields}>
            <div className={styles.relative}>
              <InputFieldFormik
                className={classNames(
                  styles.input,
                  errors.email && styles.inputError
                )}
                name="email"
                placeholder={t('common.email')}
                autoComplete="off"
                data-test="sign-in-container_email_field"
                onFocus={onFocus}
              />
              <div className={styles.errorMessage}>{errors.email}</div>
            </div>
          </div>
          <button
            type="submit"
            className={styles.resetBtn}
            data-test="sign-in-container_log_email_button"
          >
            {t('emailLogin.reset')}
          </button>
        </Form>
      </Formik>
    </>
  );
};
