// utils
import authRequest from 'utils/request/auth-request';
import { API_USERS } from 'constants/api-urls';

// actions

export function getGhostTimeEffect() {
  const url = `${process.env.REACT_APP_API_PATH}/password-access/ghost-time`;
  return authRequest
    .get(url)
    .then(({ data: { data } }) => data)
    .catch((e) => {
      throw e.response.data;
    });
}

export function changeGhostTimeEffect(status) {
  const url = `${API_USERS}/ghost-time`;
  return authRequest
    .post(url, { status })
    .then(({ data }) => data)
    .catch((e) => {
      throw e.response.data;
    });
}

export function addGhostPassEffect(password) {
  const url = `${process.env.REACT_APP_API_PATH}/password-access`;
  return authRequest
    .post(url, { password })
    .then(({ data }) => data)
    .catch((e) => {
      throw e.response.data;
    });
}

export function removeGhostPassEffect() {
  const url = `${process.env.REACT_APP_API_PATH}/password-access-delete`;
  return authRequest
    .delete(url)
    .then(({ data }) => data)
    .catch((e) => {
      throw e.response.data;
    });
}

export function changeGhostPassEffect(
  oldPassword,
  newPassword,
  repeateNewPassword
) {
  const url = `${process.env.REACT_APP_API_PATH}/password-access-update`;
  const body = {
    currentPassword: oldPassword,
    plainPassword: {
      first: newPassword,
      second: repeateNewPassword,
    },
  };
  return authRequest
    .post(url, body)
    .then(({ data }) => data)
    .catch((e) => {
      throw e.response.data?.errors;
    });
}

export function changeGPTimeEffect(timeCount) {
  const url = `${process.env.REACT_APP_API_PATH}/password-access/ghost-time`;
  return authRequest
    .post(url, timeCount)
    .then(({ data }) => data)
    .catch((e) => {
      throw e.response.data;
    });
}

export function checkGhostPassEffect(password) {
  const url = `${process.env.REACT_APP_API_PATH}/check-password-access`;
  return authRequest
    .post(url, password)
    .then(({ data }) => data)
    .catch((e) => {
      throw e.response.data;
    });
}
