import React from 'react';

const Access = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip-access-0)">
      <path
        d="M11.0125 6.02539C9.21297 6.02539 7.63309 6.98345 6.75584 8.4162C6.45203 8.36798 6.14325 8.3433 5.83391 8.3433C2.61709 8.3433 0 10.9604 0 14.1772C0 14.4361 0.209875 14.646 0.46875 14.646H7.59931C8.49219 15.4853 9.69325 16.0004 11.0125 16.0004C13.7626 16.0004 16 13.763 16 11.0129C16 8.26277 13.7626 6.02539 11.0125 6.02539ZM0.95975 13.7085C1.19638 11.2275 3.29206 9.2808 5.83391 9.2808C5.99853 9.2808 6.16284 9.28939 6.32603 9.30567C6.13134 9.83848 6.02497 10.4135 6.02497 11.0129C6.02497 12.0052 6.31637 12.9307 6.81794 13.7085H0.95975ZM11.0125 15.0629C8.77931 15.0629 6.96247 13.246 6.96247 11.0129C6.96247 8.77967 8.77931 6.96289 11.0125 6.96289C13.2457 6.96289 15.0625 8.7797 15.0625 11.0129C15.0625 13.2461 13.2457 15.0629 11.0125 15.0629Z"
        fill="currentColor"
      />
      <path
        d="M5.83379 0C4.16272 0 2.80322 1.35953 2.80322 3.03059C2.80322 4.70172 4.16272 6.06125 5.83379 6.06125C7.50488 6.06125 8.86441 4.70169 8.86441 3.03059C8.86441 1.3595 7.50488 0 5.83379 0ZM5.83379 5.12375C4.67966 5.12375 3.74072 4.18475 3.74072 3.03059C3.74072 1.87644 4.67966 0.9375 5.83379 0.9375C6.98794 0.9375 7.92691 1.87647 7.92691 3.03059C7.92691 4.18478 6.98794 5.12375 5.83379 5.12375Z"
        fill="currentColor"
      />
      <path
        d="M12.5184 10.5434H11.4809V9.50586C11.4809 9.24698 11.271 9.03711 11.0121 9.03711C10.7532 9.03711 10.5434 9.24698 10.5434 9.50586V10.5434H9.50586C9.24698 10.5434 9.03711 10.7532 9.03711 11.0121C9.03711 11.271 9.24698 11.4809 9.50586 11.4809H10.5434V12.5184C10.5434 12.7772 10.7532 12.9871 11.0121 12.9871C11.271 12.9871 11.4809 12.7772 11.4809 12.5184V11.4809H12.5184C12.7772 11.4809 12.9871 11.271 12.9871 11.0121C12.9871 10.7532 12.7772 10.5434 12.5184 10.5434Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip-access-0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Access.defaultProps = {};

export default Access;
