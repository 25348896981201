import React, { useRef } from 'react'

import { validationRegExp } from 'utils/validation/regexp'

const CreateWorkspace = ({
  newWorkspaceUsers,
  onCancel,
  onAddWorkspace,
  onAddUserToNewWorkspace,
}) => {
  const inputRef = useRef(null)
  const inputUserRef = useRef(null)

  const existUsers =
    newWorkspaceUsers &&
    newWorkspaceUsers.map((item) => (
      <button
        className="button button--primary button--primary_outline text--medium text--12 text--uppercase remove-button"
        type="button"
        key={`exist-user-${item}`}
      >
        {item}
      </button>
    ))

  const addWorkspaceHandler = () => {
    if (inputRef.current && inputRef.current.value) {
      onAddWorkspace({ name: inputRef.current.value })
    }
  }

  const addUserHandler = () => {
    if (
      inputUserRef.current &&
      inputUserRef.current.value &&
      validationRegExp.email.test(inputUserRef.current.value)
    ) {
      onAddUserToNewWorkspace({ email: inputUserRef.current.value })
      inputUserRef.current.value = ''
    }
  }

  return (
    <div className="right-menu__container create-workspace">
      <label className="right-menu__label">
        Workspace name
        <input
          type="text"
          name="name"
          className="right-menu__input"
          placeholder="Ex.Acme or Acme Marketing"
          ref={inputRef}
        />
      </label>

      <label className="right-menu__label">
        Add Users
        <div className="right-menu__input-container">
          <input
            type="email"
            name="users"
            className="right-menu__input"
            placeholder="Type E-mail Addresses"
            ref={inputUserRef}
          />
          <button
            type="button"
            className="add-button"
            onClick={addUserHandler}
          />
        </div>
      </label>

      <div className="add-user__user-button">{existUsers}</div>

      <button
        className="button button--primary text--medium text--12 text--uppercase create-button"
        type="button"
        onClick={addWorkspaceHandler}
      >
        Create workspace
      </button>
      <button className="link cancel-button" type="button" onClick={onCancel}>
        Cancel
      </button>
    </div>
  )
}

export default CreateWorkspace
