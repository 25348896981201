import React from 'react';

const DiamondIcon = ({
  color = 'currentColor',
  width = 23,
  height = 20,
  viewBoxWidth = 23,
  viewBoxHeight = 20,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.305 19.8121L0.308044 6.60929L3.43726 0.350861H19.1728L22.302 6.60929L11.305 19.8121ZM7.13296 6.37112H15.4771L12.855 1.06611H9.75506L7.13296 6.37112ZM10.9474 18.2922V7.08637H1.64914L10.9474 18.2922ZM11.6626 18.2922L20.9609 7.08637H11.6626V18.2922ZM16.2817 6.37112H21.3485L18.6957 1.06611H13.6296L16.2817 6.37112ZM1.26147 6.37112H6.3283L8.98045 1.06611H3.91433L1.26147 6.37112Z"
      fill={color}
    />
  </svg>
);

DiamondIcon.defaultProps = {
  width: 14,
  height: 14,
};

export default DiamondIcon;
