import authRequest from 'utils/request/auth-request';
import { API_SAVE_UPLOAD_FILES, API_TRASH } from 'constants/api-urls';
import { AxiosError, AxiosResponse } from 'axios';
import { IFile } from 'interfaces/file-folder';

interface FilesResponse {
  count: number;
  data: IFile[];
}

type Entry = {
  files_count: number;
  folders_count: number;
};

type TrashFilesResponse = {
  entry: Entry;
  key: null | string;
  workspace_id: number;
};

export const entityCheckIsEmptyEffect = (
  slug: string,
  page: number | string = 1
): FilesResponse => {
  const url = `${API_SAVE_UPLOAD_FILES}/${slug}?page=${page}`;

  return (
    authRequest
      // @ts-ignore
      .get(url)
      .then((response: AxiosResponse<FilesResponse>) => {
        const { data } = response;
        return data;
      })
      .catch((response: AxiosError) => {
        throw response.message;
      })
  );
};

export const entityCheckIsEmptyInTrashEffect = (
  slug: string
): TrashFilesResponse => {
  const url = `${API_TRASH}/entry/${slug}`;

  return (
    authRequest
      // @ts-ignore
      .get(url)
      .then((response: AxiosResponse<TrashFilesResponse>) => {
        const {
          data: { entry },
        } = response;
        return entry?.files_count + entry?.folders_count;
      })
      .catch((response: AxiosError) => {
        throw response.message;
      })
  );
};
