/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import CN from 'classnames';
import { InputMask } from '@react-input/mask';

import styles from './styles.module.scss';

export const CONNECTION_MODE = {
  phone: 'phone',
  code: 'code',
}

const initialValuesState = {
  phone: '', code: '' 
}

const ConnectTgModal = ({ isOpen, onRequestClose, startConnect }) => {
  const [values, setValues] = useState(initialValuesState);
  const [mode, setMode] = useState(CONNECTION_MODE.phone);

  useEffect(() => {
    const initial = () => {
      setTimeout(() => {
        startConnect(setMode)      
      }, 2000);
    }

  isOpen && initial()
  }, [isOpen])

  const track = ({ inputType, value, data, selectionStart, selectionEnd }) => {
    if (inputType === 'insert' && !/^\D*1/.test(data) && selectionStart <= 1) {
      return `1${data}`;
    }

    if (inputType !== 'insert' && selectionStart <= 1 && selectionEnd < value.length) {
      if (selectionEnd > 2) {
        return '1';
      }
      if (selectionEnd === 2) {
        return false;
      }
    }
    return data;
  };


  const onInputChnge = ({ target: { value } }) => {
    setValues((valueState) => ({...valueState, phone: value}))
  }

  const onInputCodeChnge = ({ target: { value } }) => {
    setValues((valueState) => ({...valueState, code: value}))
  }

  const handleClose = () => {
    if (values.code || values.phone) {
      setValues(initialValuesState);
    } else {
      onRequestClose();
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      overlayClassName={styles.overlay}
      className={styles.modal}
    >
      <div className={styles.connectModal}>
        <h1 className={styles.title}>Connect Telegram</h1>
        <div className={styles.connectInput}>
          <InputMask
            className={CN(
              styles.input,
              mode === CONNECTION_MODE.phone && styles.connectBtnShow
            )}
            id="tg-connector-input-phone"
            mask="+____________"
            replacement={{ _: /\d/ }}
            track={track}
            onChange={onInputChnge}
            value={values.phone}
            placeholder="Please enter your number"
          />
          <InputMask
            className={CN(
              styles.input,
              mode === CONNECTION_MODE.code && styles.connectBtnShow
            )}
            id="tg-connector-input-code"
            mask="_____"
            replacement={{ _: /\d/ }}
            onChange={onInputCodeChnge}
            value={values.code}
            placeholder="Please enter the code you received"
          />
          <button
            className={CN(
              styles.connectBtn,
              mode === CONNECTION_MODE.phone && styles.connectBtnShow
            )}
            id="tg-connector-btn-phone"
          >
           send code
          </button>
          <button
            className={CN(
              styles.connectBtn,
              mode === CONNECTION_MODE.code && styles.connectBtnShow
            )}
            id="tg-connector-btn-code"
          >
            connect
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConnectTgModal;