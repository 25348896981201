//Refactoring №3
import React from 'react';

import CN from 'classnames';
import s from './style.module.scss';

export const SwitchTheme = {
  DARK: s.themeDark,
  PRIMARY: s.themePrimary,
};

export default function Switch({
  className,
  classNameContainer,
  classNameButton,
  name,
  checked,
  forceChange,
  onClick,
  size = 'big',
  disabled,
  onMouseMove,
  onMouseLeave,
  theme = SwitchTheme.PRIMARY,
}) {
  return (
    <label
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      className={CN(
        s.wrapper,
        size === 'small' && s.small,
        size === 'big' && s.big,
        disabled && s.disabled,
        className
      )}
    >
      <input
        className={s.input}
        disabled={disabled}
        onChange={(e) => {
          if (forceChange) {
            onClick && onClick(e);
            return;
          }
          e.preventDefault();
          e.stopPropagation();
          onClick && onClick(e);
        }}
        name={name}
        type="checkbox"
        checked={checked}
      />
      <span className={CN(theme, classNameContainer)}>
        <span className={CN(s.button, classNameButton)}></span>
      </span>
    </label>
  );
}
