import React from 'react'

const MyAI = ({ width = 18, height = 18 }) => (
  <svg width={width} height={height} viewBox="-3 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 0.875C11.8611 1.90973 12.6614 2.96181 13.401 4.03125C14.1406 5.10069 14.8125 6.17014 15.4167 7.23959L21.875 2.375V11C21.875 12.5 21.5885 13.9115 21.0156 15.2344C20.4427 16.5573 19.6667 17.7083 18.6875 18.6875C17.7083 19.6667 16.5573 20.4427 15.2344 21.0156C13.9115 21.5885 12.5 21.875 11 21.875C9.5 21.875 8.08854 21.5885 6.76562 21.0156C5.44271 20.4427 4.29167 19.6667 3.3125 18.6875C2.33333 17.7083 1.55729 16.5573 0.984375 15.2344C0.411458 13.9115 0.125 12.5 0.125 11V2.375L6.58334 7.23959C7.18751 6.19097 7.85939 5.1302 8.59897 4.05728C9.33855 2.98436 10.1389 1.9236 11 0.875ZM0.875 3.875V11C0.875 12.8611 1.31771 14.5625 2.20312 16.1042C3.08854 17.6459 4.26736 18.8785 5.73959 19.8021C5.17014 19.1215 4.71701 18.3524 4.38022 17.4948C4.04341 16.6372 3.875 15.7222 3.875 14.75C3.875 13.8889 4.08159 12.8663 4.49478 11.6823C4.90799 10.4983 5.47918 9.2257 6.20834 7.86459L0.875 3.875ZM11 2.0625C9.15973 4.27777 7.63716 6.5434 6.43228 8.85938C5.22743 11.1754 4.625 13.1389 4.625 14.75C4.625 16.5069 5.24826 18.0087 6.49478 19.2552C7.74132 20.5017 9.24306 21.125 11 21.125C12.7569 21.125 14.2587 20.5017 15.5052 19.2552C16.7517 18.0087 17.375 16.5069 17.375 14.75C17.375 13.1389 16.7726 11.1754 15.5677 8.85938C14.3628 6.5434 12.8403 4.27777 11 2.0625ZM21.125 3.875L15.7917 7.88541C16.5208 9.23264 17.092 10.4983 17.5052 11.6823C17.9184 12.8663 18.125 13.8889 18.125 14.75C18.125 15.7222 17.9566 16.6372 17.6198 17.4948C17.283 18.3524 16.8299 19.1215 16.2604 19.8021C17.7326 18.8785 18.9115 17.6459 19.7969 16.1042C20.6823 14.5625 21.125 12.8611 21.125 11V3.875Z" fill="currentColor"/>
  </svg>
)

MyAI.defaultProps = {
}

export default MyAI;


