import React from 'react'

const CloseSmall = ({ color, className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3.5L4 11.5M11.9931 11.4931L4.00691 3.50693"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
    />
  </svg>
)

CloseSmall.defaultProps = {
  className: '',
  color: '#7C7E88',
}

export default CloseSmall
