//Refactoring №3
import React, { useEffect } from 'react';

import { useField } from 'formik';

import InputField from 'components/InputField';

export default function InputFieldFormik({
  handleChange,
  component,
  ...props
}) {
  const Component = component || InputField;
  const [field, meta] = useField(props);

  useEffect(() => {
    handleChange && handleChange(field.value);
  }, [field.value]);

  const handleOnBlur = (e) => {
    props.onBlur && props.onBlur({ name: field.name, value: field.value });
    field.onBlur(e);
  };

  return <Component {...field} {...props} {...meta} onBlur={handleOnBlur} />;
}
