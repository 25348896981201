export const START_DOWNLOAD = 'START_DOWNLOAD'
export const END_DOWNLOAD = 'END_DOWNLOAD'
export const CANCEL_DOWNLOAD = 'CANCEL_DOWNLOAD';
export const ADD_TOKEN_DOWNLOAD = 'ADD_TOKEN_DOWNLOAD';

export function startDownload(sizeFile, fileSlug, files) {
  return {
    type: START_DOWNLOAD,
    size: sizeFile,
    slug: fileSlug,
    files: files
  }
}

export function endDownload() {
  return {
    type: END_DOWNLOAD,
  }
}

export function cancelDownload() {
  return {
    type: CANCEL_DOWNLOAD,
  };
}

export function addTokenDownload(cancelTokenSource) {
  return {
    type: ADD_TOKEN_DOWNLOAD,
    cancelTokenSource,
  };
}