import React from 'react';

const SquareIcon = ({ color, className }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.000488281 16H4.95249V11.048H0.000488281V16ZM0.000488281 4.952H4.95249V0H0.000488281V4.952ZM11.0485 16H16.0005V11.048H11.0485V16ZM11.0485 4.952H16.0005V0H11.0485V4.952Z" fill={color} />
  </svg>
);

SquareIcon.defaultProps = {
  color: 'currentColor',
};

export default SquareIcon;
