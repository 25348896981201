// New
import * as actions from '../actions/vaults.actions';
import getData from 'utils/request/get-data';
import removeIfEqual from 'utils/array/remove-if-equal';
import getErrorData from 'utils/request/get-error-data';

export const initialState = {
  vaults: [],
  count: 0,
  status: false,
  currentPage: 1,
  getFilesError: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  let file;

  switch (type) {
    case actions.VAULTS_GET:
      return {
        ...state,
        status: true,
      };

    case actions.VAULTS_GET_SUCCESS:
      return {
        ...state,
        vaults: [...state.vaults, ...payload.data.map((item) => ({ ...item }))],
        count: payload.count,
        currentPage: payload.page,
        status: false,
      };
    case actions.VAULTS_GET_FAILURE:
      return {
        ...state,
        status: false,
        getFilesError: getErrorData(action),
      };
    case actions.GET_VAULT_REMOVE:
      file = getData(action);
      return {
        ...state,
        vaults: removeIfEqual(state.vaults, file, 'slug'),
        count: state.count - 1,
      };

    case actions.SET_VAULTS:
      return {
        ...state,
        vaults: payload,
      };

    case actions.RESET_STATE:
      return {
        ...initialState,
      };
    case actions.UPDATE_VAULT_SUCCESS:
      return {
        ...state,
        vaults: state.vaults.map((item) =>
          item.slug === payload.slug ? payload : item
        ),
      };

    case actions.DELETE_VAULT_SUCCESS:
      return {
        ...state,
        vaults: state.vaults.filter((item) =>
          item.slug !== payload
        ),
      };
    default:
      return state;
  }
};
