export const trimLongText = (text, maxLength, appendText = '...') =>
  text.length < maxLength
    ? text
    : text.slice(0, maxLength).concat(appendText || '')

export const getInitials = (userName, isProfile) => {
  if (!userName || userName.trim() === '') {
    return ''
  } else if (userName.length === 1) {
    return userName.toUpperCase()
  } else if (isProfile) {
    return userName[0].toUpperCase()
  } else if (userName.trim().includes(' ')) {
    const userNameArray = userName.split(' ')
    return `${userNameArray[0][0]}${userNameArray[1][0]}`.toUpperCase()
  } else {
    return `${userName[0]}${userName[1]}`.toUpperCase()
  }
}
