// Refactoring №3
import React, { useMemo, useRef } from 'react';

import { GENERATION_OPTIONS } from 'containers/ai-generator/utils/filter_options';

import styles from './filter-styles-slider.module.scss';

type FilterStylesSliderProps = {
  imageFilter: string;
  setImageFilter: (filterName: string) => void;
};

export const FilterStylesSlider = ({
  imageFilter,
  setImageFilter,
}: FilterStylesSliderProps) => {
  const scrollContainerRef = useRef(null);

  const handleScroll: React.WheelEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    const container = scrollContainerRef.current;
    const delta = Math.sign(event.deltaY);
    const scrollAmount = 100;
    container.scrollLeft += delta * scrollAmount;
  };

  const optionSelectionHandler = (
    e: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLImageElement>
  ) => {
    const target = e.target as HTMLDivElement | HTMLImageElement;
    if ('alt' in target && target.alt) {
      setImageFilter(target.alt);
      return;
    }
    if (target.id) {
      setImageFilter('');
      return;
    }
  };

  const imageFilters = useMemo(
    () => [
      {
        title: 'Clear',
        label: 'clear',
        icon: 'clear',
      },
      ...GENERATION_OPTIONS,
    ],
    []
  );

  return (
    <div
      onWheel={handleScroll}
      ref={scrollContainerRef}
      className={styles.wrapper}
      onClick={optionSelectionHandler}
    >
      {imageFilters.map(({ label, icon }) => (
        <div
          key={label}
          id={label}
          className={`${styles.filterCard} ${
            label === 'clear' ? styles.filterCard_clearFilter : ''
          } ${label === imageFilter ? styles.filterCard_selected : ''}`}
        >
          {label !== 'clear' && <img src={icon} alt={label} />}
        </div>
      ))}
    </div>
  );
};
