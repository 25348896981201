import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getGenerationFilterOptions } from 'containers/ai-generator/utils/filter_options';
import { getSkybox360GenerationStylesEffect } from 'store/aiGenerator/effects';

import { ReactComponent as CheckIcon } from 'static/assets/svg/checkmark-2.svg';

import style from './style.module.scss';

const FiltersList = ({
  isDrawTab,
  setImageFilter,
  optionSelectionHandler,
  imageFilter,
  is3DTab,
  hideShadowOnScrollToEnd,
  generationModel,
}) => {
  const [imageFilters, setImageFilters] = useState([]);
  const [panoramaFilters, setPanoramaFilters] = useState({ 2: [], 3: [] });
  const [hoveredFilter, setHoveredFilter] = useState({});
  const [position, setPosition] = useState({});
  const { t } = useTranslation('owner');

  const getSkyboxFilters = async () => {
    const data = await getSkybox360GenerationStylesEffect();
    const filters = data
      .filter(
        (el) => !el.name.includes('no style') && !el.name.includes('(Featured)')
      )
      .map((el) => {
        return { ...el, label: el.name, icon: el.image_jpg };
      })
      .reduce(
        (acc, el) => {
          acc[el.model_version].push(el);
          return acc;
        },
        { 2: [], 3: [] }
      );
    setPanoramaFilters(filters);
    setImageFilters(filters[generationModel]);
  };

  useEffect(() => {
    setImageFilters(panoramaFilters[generationModel]);
  }, [generationModel]);

  const requestSkyboxFilters = () => {
    const shouldRequestFilters = panoramaFilters[generationModel]?.length === 0;
    if (shouldRequestFilters) {
      getSkyboxFilters();
    } else {
      setImageFilters(panoramaFilters[generationModel]);
    }
  };

  useEffect(() => {
    if (is3DTab) {
      setImageFilters([]);
      requestSkyboxFilters();
    } else {
      const filters = getGenerationFilterOptions(isDrawTab);
      setImageFilters(filters);
    }
    setImageFilter('');
  }, [isDrawTab, is3DTab]);

  const regularFilters = imageFilters.map(({ label, icon }) => (
    <div
      key={label}
      id={label}
      className={`${style.filterCard}  ${
        label === imageFilter ? style.filterCard_selected : ''
      }`}
    >
      <img src={icon} alt={label} />
      <p className={style.filterCard__label}>
        {is3DTab ? label : t(`studio.${label}`.toLocaleLowerCase())}
      </p>
      {label === imageFilter && (
        <div className={style.filterCard__checked}>
          <CheckIcon />
        </div>
      )}
    </div>
  ));

  const handleMouseMove = (e, id) => {
    if (e.target.nodeName !== 'P') {
      setHoveredFilter(0);
      return;
    }
    if (hoveredFilter !== id) {
      setHoveredFilter(id);
    }

    const x = window.innerWidth - e.clientX < 270 ? e.clientX - 255 : e.clientX;
    setPosition({ x, y: e.clientY });
  };

  const skyboxFilters = imageFilters.map(({ label, icon, id, description }) => (
    <div
      key={label}
      id={id}
      onMouseMove={(e) => handleMouseMove(e, id)}
      onMouseEnter={(e) => handleMouseMove(e, id)}
      onMouseLeave={() => setHoveredFilter(0)}
      className={`${style.filterCard}  ${
        id === +imageFilter ? style.filterCard_selected : ''
      }`}
    >
      <img src={icon} alt={label} />

      <p className={style.filterCard__label}>
        {is3DTab ? label : t(`studio.${label}`.toLocaleLowerCase())}
      </p>

      {id === +imageFilter && (
        <div className={style.filterCard__checked}>
          <CheckIcon />
        </div>
      )}
      {hoveredFilter === id && (
        <div
          className={style.filterHint}
          style={{ top: `${position.y - 250}px`, left: `${position.x}px` }}
        >
          <img src={icon} alt={description} />
          <h3>{label}</h3>
          <p>{description}</p>
        </div>
      )}
    </div>
  ));
  return (
    <div
      className={style.filterWrapper}
      onClick={optionSelectionHandler}
      id="filters"
      onScroll={hideShadowOnScrollToEnd}
    >
      {is3DTab ? skyboxFilters : regularFilters}
      {imageFilters?.length === 0 && (
        <h2 className={style.filtersTitle}>{t('studio.noFilterFound')}</h2>
      )}
    </div>
  );
};

export default memo(FiltersList);
