export const FILE_DELETE = 'FILE_DELETE'
export const FILE_DELETE_SUCCESS = 'FILE_DELETE_SUCCESS'
export const FILE_DELETE_FAILURE = 'FILE_DELETE_FAILURE'

export function fileDelete() {
  return {
    type: FILE_DELETE,
  }
}

export function fileDeleteSuccess() {
  return {
    type: FILE_DELETE_SUCCESS,
  }
}

export function fileDeleteFailure() {
  return {
    type: FILE_DELETE_FAILURE,
  }
}
