// Refactoring №5
import { createSlice } from '@reduxjs/toolkit';

const defaultAvatar = {
  id: 1,
}

const initialState = {
  profile: {},
  avatar: [defaultAvatar],
  bios: {},
  link: {},
  chats: [],
  aiFiles: [],
  chatModifiers: {},
  avatarLoading: true,
  conversationUid: '',
  biosMessage: '',
  memoMessage: '',
  conversationHistory: [],
  knowledges: [],
  neyraWrites: false,
  showChatModal: false,
};

const slice = createSlice({
  name: 'neyra',
  initialState,
  reducers: {
    setProfile(state, { payload }) {
      state.profile = payload;
    },
    setAvatar(state, { payload }) {
      state.avatar = payload;
      state.avatarLoading = false;
    },
    setBios(state, { payload }) {
      state.bios = payload;
    },
    setChats(state, { payload }) {
      state.chats = payload;
    },
    startFetch(state) {
      state.avatarLoading = true;
    },
    finishFetch(state) {
      state.avatarLoading = false;
    },
    setAiFiles(state, { payload }) {
      state.aiFiles = payload;
    },
    setLink(state, { payload }) {
      state.link = payload;
    },
    setConversationUid(state, actions) {
      state.conversationUid = actions.payload;
    },
    clearConversationHistory(state) {
      state.conversationHistory = [];
    },
    setChatHistory(state, actions) {
      state.conversationHistory = actions.payload;
    },
    setConversationHistory(state, action) {
      const newMessage = action.payload;
      state.conversationHistory = state.conversationHistory.map((item) =>
        item.id === newMessage.id
          ? {
              ...item,
              text: item.text + newMessage.text,
              response: item.text + newMessage.response,
            }
          : item
      );
    },
    createMessage(state, action) {
      state.conversationHistory = [
        ...state.conversationHistory,
        action.payload,
      ];
    },
    setNeyraWrites(state, action) {
      state.neyraWrites = action.payload;
    },
    setChatModifiers(state, action) {
      state.chatModifiers = action.payload;
    },
    setBiosMessage(state, action) {
      state.biosMessage = action.payload;
    },
    setMemoMessage(state, action) {
      state.memoMessage = action.payload;
    },
    setShowChatModal(state, action) {
      state.showChatModal = action.payload;
    },
    setKnowledges(state, action) {
      state.knowledges = action.payload;
    },
    removeMessage(state, action) {
      state.conversationHistory = state.conversationHistory
        .filter((item) => item.id  !== action.payload);
    },
    cleanState() {
      return { ...initialState };
    },
  },
});

const { reducer, actions } = slice;

export const {
  setProfile,
  setAvatar,
  setBios,
  cleanState,
  setChats,
  startFetch,
  finishFetch,
  setAiFiles,
  setLink,
  setConversationUid,
  clearConversationHistory,
  setConversationHistory,
  createMessage,
  setNeyraWrites,
  setChatModifiers,
  setBiosMessage,
  setMemoMessage,
  setShowChatModal,
  setChatHistory,
  setKnowledges,
  removeMessage,
} = actions;

export default reducer;

export const selectNeyraAvatar = (state) => state.neyra.avatar;
export const selectNeyraAvatarLoading = (state) => state.neyra.avatarLoading;
export const selectNeyraBiosShape = (state) => state.neyra?.bios?.spheres || [];
export const selectNeyraChats = (state) => state.neyra?.chats || [];
export const selectNeyraProfile = (state) => state.neyra?.profile;
export const selectNeyrAIFiles = (state) => state.neyra?.aiFiles;
export const selectChatModifiers = (state) => state.neyra?.chatModifiers;
export const selectKnowledges = (state) => state.neyra?.knowledges;
export const selectMemoMessage = (state) => state.neyra?.memoMessage;
