/* eslint-disable no-debugger */
// Refactoring №3
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import cn from 'classnames';
import { useClickAway } from 'ahooks';
import { isEmpty } from 'lodash';

import { getNextNotificationByTypeEffect } from 'store/notifications/effects/notifications-get.effect';
import setReviewsNotificationEffect from 'store/notifications/effects/notifications-set-reviews.effect';
import {
  reviewsNotifications,
  setNextNotificationsByType,
  NOTIFICATION_TYPES,
} from 'store/notifications/actions';
import { getUserInfoEffect } from 'store/home/effects';
import { IRootState } from 'store/root-store';

import { selectIsMultisigActivated } from 'features/app';
import { INotification } from 'interfaces/notification';

import NotificationItem, { isMultisigMessage } from './notification-item';
import EmptyView from './noNotifications';
import CloseIcon from 'components/svg/close';
import NotificationBtn from 'containers/main/Main/components/MainSidebar/components/Container/components/NotificationBtn'

import s from './style.module.scss';

const getNotificationsParent = () =>
  document.getElementById('notificationPopup');



export default function NotificationsPopup({ align }) {
  const { t } = useTranslation('popup');
  const { t: hintTranslation } = useTranslation('hint');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const notificationReducer = useSelector(
    (state: IRootState) => state.notifications
  );
  const [defaultTab, setDefaultTab] = useState(true);
  const popup = useRef(null);
  const actionBtn = useRef(null);
  const {
    unreadNotifications,
    readNotifications,
    readCount,
    unreadCount,
    unreadPage,
    readPage,
  } = notificationReducer;
  const notificationList = useMemo(
    () => (defaultTab ? unreadNotifications : readNotifications),
    [defaultTab, unreadNotifications, readNotifications]
  );
  const hasMore = useMemo(
    () =>
      defaultTab
        ? unreadNotifications.length < unreadCount
        : readNotifications.length < readCount,
    [defaultTab, unreadNotifications, unreadCount, readNotifications, readCount]
  );

  useEffect(() => {
    if (!isMultisigActivated) {
      const hasUnreadMultisigNotification = unreadNotifications.some(
        (ntf: INotification) => isMultisigMessage(ntf.message)
      );
      const hasReadMultisigNotification = readNotifications.some(
        (ntf: INotification) => isMultisigMessage(ntf.message)
      );

      if (hasUnreadMultisigNotification || hasReadMultisigNotification) {
        dispatch(getUserInfoEffect());
      }
    }
  }, [unreadNotifications, readNotifications, isMultisigActivated]);

  const loadMore = useCallback(() => {
    const currentPage = defaultTab ? unreadPage : readPage;
    const notificationCount = defaultTab ? unreadCount : readCount;
    const notificationType = defaultTab
      ? NOTIFICATION_TYPES.unread
      : NOTIFICATION_TYPES.read;
    if (currentPage * 15 < notificationCount) {
      const nextPage = currentPage + 1;
      getNextNotificationByTypeEffect(notificationType, nextPage).then(
        (data) => {
          dispatch(
            setNextNotificationsByType(nextPage, data, notificationType)
          );
        }
      );
    }
  }, [defaultTab, unreadPage, readPage, unreadCount, readCount]);

  useClickAway((props: Event) => {
    const path = props.composedPath && props.composedPath();
    const hidePopup = !path.find((item) => item === actionBtn.current);
    if (hidePopup && open) {
      setOpen(false);
    }
  }, popup.current);

  const openPopup = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const reviewNotification = useCallback((id: number) => {
    setReviewsNotificationEffect(id).then(() => {
      dispatch(reviewsNotifications(id));
    });
  }, []);

  return (
    <div className={s.wrapper}>
      <NotificationBtn
        ref={actionBtn}
        onClick={openPopup}
        unreadCount={unreadCount}
        data-test={`main-sidebar_notification_popup_button`}
        title={name || undefined}
      />
      <div
        ref={popup}
        className={cn(
          s.notificationPopup,
          align === 'bottom' && s.notificationPopupBottom,
          !open && s.notificationPopup_hide)
        }
      >
        {open && (
          <>
            <header className={s.notificationPopup_header}>
              <div className={s.title}>
                <h2>{t('notification.notifications')}</h2>
                {unreadCount !== 0 && (
                  <span className={s.title__unread}>{unreadCount}</span>
                )}
              </div>
              <button
                className={s.notificationPopup_header_closeBtn}
                onClick={() => setOpen(false)}
                type="button"
                data-test={`main-sidebar_notification_popup_close_button`}
              >
                <CloseIcon size={10} />
              </button>
            </header>
            <div className={s.wrapper}>
              <div className={s.wrapper_tabs}>
                <div
                  className={cn(s.tabItem, defaultTab && s.tabItem__active)}
                  onClick={() => setDefaultTab(true)}
                  data-test={`main-sidebar_notification_popup_unread_button`}
                >
                  {t('notification.unread')}
                </div>
                <div
                  className={cn(s.tabItem, !defaultTab && s.tabItem__active)}
                  onClick={() => setDefaultTab(false)}
                  data-test={`main-sidebar_notification_popup_read_button`}
                >
                  {t('notification.read')}
                </div>
              </div>
              <ul
                className={cn(
                  s.wrapper__list,
                  defaultTab && s.wrapper__list_unread
                )}
              >
                {isEmpty(notificationList) ? (
                  <EmptyView />
                ) : (
                  <InfiniteScroll
                    className={s.wrapper__list_scrolled}
                    id="notificationPopup"
                    initialLoad={false}
                    pageStart={2}
                    useWindow={false}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    loader={
                      <div className={s.notificationPopup_loader}>
                        {t('notification.loading')}
                      </div>
                    }
                    threshold={50}
                    getScrollParent={getNotificationsParent}
                  >
                    {notificationList.map(
                      (item: INotification, index: number) => (
                        <NotificationItem
                          key={item.id}
                          {...item}
                          created_at={parseInt(item.created_at)}
                          index={index}
                          onReview={reviewNotification}
                        />
                      )
                    )}
                  </InfiniteScroll>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
