import * as actions from '../actions/selected-files-folders.actions'
import getArrayFromData from 'utils/array/get-array-from-data'
import removeIfEqual from 'utils/array/remove-if-equal'

const initialState = {
  files: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SELECTED_FILES_ADD:
      return {
        ...state,
        files: [
          ...state.files,
          ...getArrayFromData(action.payload.entities).filter(
            (item) =>
              state.files.findIndex((file) => file.slug === item.slug) !== 0
          ),
        ],
      }
    case actions.SELECTED_FILES_REMOVE:
      return {
        ...state,
        files: removeIfEqual(
          state.files,
          getArrayFromData(action.payload),
          'slug'
        ),
      }

    case actions.SELECTED_FILES_FOLDERS_CLEAR:
      return {
        ...state,
        files: [],
      }

    case actions.SHOW_FILE:
      return {
        ...state,
        show: action.payload,
      }
    case actions.SHOW_FILE_CLEAR:
      return {
        ...state,
        show: null,
      }
    default:
      return state
  }
}
