import React from "react";

import SidebarItem from 'containers/main/NeyraDriveContainer/components/SidebarItem';
import { ReactComponent as SpaceIcon } from 'containers/account/Space/assets/space.svg';

import { MODES } from 'containers/account/Space/SpaceSidebar';

const MenuContent = ({ selectedSpace, spaceSidebar, setMode, setSelectedSpace }) => {

  return (
    <>
      <SidebarItem item={{
          icon: <SpaceIcon />,
          isMain: true,
          name: selectedSpace?.profile?.name,
          action: () => {},
          backButton: true,
          backOnClick: () => {
            setMode(MODES.main);
            setSelectedSpace(null);
          }
        }}
      />
        { spaceSidebar.map((spaceItem) => (
          <SidebarItem
            key={ spaceItem.name.toLowerCase() }
            item={{
              icon: spaceItem.icon,
              name: spaceItem.name,
              url: spaceItem?.ref,
              action: spaceItem?.ref ? undefined :spaceItem.action,
            }}
          />
        )) }
    </>
  )
}

export default MenuContent;