export const GET_USER = 'GET_USER';
export const GET_USER_UPDATE = 'GET_USER_UPDATE';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const RESET_USER_INFO = 'RESET_USER_INFO';
export const GET_USER_FREE_ACTIONS = 'GET_USER_FREE_ACTIONS';

export function getUser() {
  return {
    type: GET_USER,
  };
}

export function getUserUpdate(data) {
  return {
    type: GET_USER_UPDATE,
    payload: data,
  };
}

export function getUserSuccess(res) {
  return {
    type: GET_USER_SUCCESS,
    payload: res,
  };
}

export function getUserFail(err) {
  return {
    type: GET_USER_FAIL,
    payload: err,
  };
}

export function updateUserInfo(data) {
  return {
    type: UPDATE_USER_INFO,
    payload: data,
  };
}

export function resetUserHomeInfo() {
  return {
    type: RESET_USER_INFO,
  };
}

export function getUserFreeActions(data) {
  return {
    type: GET_USER_FREE_ACTIONS,
    payload: data,
  };
}
