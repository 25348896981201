import React from 'react'

const HistoryFile = ({ color }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="12" height="12" rx="1" fill={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 9.42857L6.98214 5L5.28571 7.71429L4.21429 6.10714L2 9.42857H4.21429H6.42857H9.75Z"
      fill="white"
    />
    <circle cx="9" cy="3" r="1" fill="white" />
  </svg>
)

HistoryFile.defaultProps = {
  color: '#0F73EF',
}

export default HistoryFile
