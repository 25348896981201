//Refactoring №5
import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { useKeyPress } from 'ahooks';
import { isEmpty, isObject } from 'lodash';

import { setVaultFileContent } from 'store/file/effects';
import { getPreviewObject } from 'store/home/effects/file/get-preview-file-blob';
import MainContext from 'store/main/context/main-context';
import { getFilesAdd } from 'store/home/actions/files/get-files.actions';
import { vaultsGetSuccess } from 'store/home/actions/vaults.actions';
import {
  handleVaultModal,
  selectVaultModal,
  selectAdditionalData,
  handleAdditionalData,
} from 'features/modals/modal-slice';

import CloseIcon from 'components/svg/close';
import EditFrom from 'components/VaultModal/EditForm';
import Preview from 'components/VaultModal/Preview';
import { DEFAULT_VIEW } from 'components/VaultModal/constants';

import { IFile } from 'interfaces/file-folder';
import { VaultItemType } from './constants';

import styles from './styles.module.scss';

type IProps = {
  files: IFile[];
  vaults: IFile[];
};

const VaultModal = ({ files = [], vaults = [] }: IProps) => {
  const dispatch = useDispatch();
  const {
    state,
    state: { folder: mainFolder },
  } = useContext(MainContext);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const isOpen = useSelector(selectVaultModal);
  const additionalData = useSelector(selectAdditionalData);
  const [myVault, setMyVault] = useState<VaultItemType>(DEFAULT_VIEW);
  const [slug, setSlug] = useState('');
  const [file, setFile] = useState<IFile | undefined>();
  const folderSlug = useMemo(() => mainFolder?.slug || null, [mainFolder]);

  useEffect(() => {
    const controller = new AbortController();
    if (!slug) {
      if (additionalData?.slug) {
        setSlug(additionalData?.slug);
        const item = files.find((item) => item.slug === additionalData.slug);
        if (!isEmpty(item)) {
          getPreviewObject({ file: item, controller }).then(
            (data: VaultItemType) => {
              setMyVault(isObject(data) ? data : DEFAULT_VIEW);
              additionalData.isEdit ? setIsEdit(true) : setIsEdit(false);
            }
          );
        }
      } else {
        setMyVault(DEFAULT_VIEW);
        setIsEdit(true);
      }
    }
    return () => {
      controller.abort();
    };
  }, [additionalData, files]);

  useEffect(() => {
    if (slug) {
      if (state.pageUrl === '/vault') {
        setFile(vaults.find((item) => item.slug === slug));
      } else {
        setFile(files.find((item) => item.slug === slug));
      }
    }
  }, [slug, files, vaults, state.pageUrl]);

  useKeyPress(['escape'], () => {
    onClose();
  });

  const onClose = useCallback(() => {
    dispatch(handleVaultModal({ isOpen: false }));
    dispatch(handleAdditionalData(null));
    setIsEdit(true);
    setMyVault(DEFAULT_VIEW);
    setSlug('');
  }, []);

  const toggleView = useCallback(() => {
    setIsEdit(!isEdit);
  }, [isEdit]);

  const onCreateVaultDocument = useCallback(
    (data: VaultItemType) => {
      setLoading(true);
      setVaultFileContent(data, slug, file, dispatch, folderSlug).then(
        (createdFile) => {
          setLoading(false);
          setSlug(createdFile.data?.slug);
          setMyVault(data);
          setIsEdit(false);
          if (state.pageUrl === '/main' && !slug) {
            dispatch(
              getFilesAdd({
                ...createdFile.data,
                folderId: folderSlug || 'main',
              })
            );
          } else if (state.pageUrl === '/vault' && !slug) {
            dispatch(
              vaultsGetSuccess({
                data: [createdFile.data],
              })
            );
          }
        }
      );
    },
    [slug, file, dispatch, folderSlug, state.pageUrl]
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="AddedModal"
      overlayClassName={styles.overlay}
      className={styles.wrapper}
      shouldCloseOnOverlayClick
    >
      <section>
        <header className={styles.header}>
          <button onClick={onClose} className={styles.closeBtn}>
            <CloseIcon />
          </button>
        </header>
        {isEdit ? (
          <EditFrom
            slug={slug}
            vault={myVault}
            onCreateVaultDocument={onCreateVaultDocument}
            onClose={onClose}
            loading={loading}
          />
        ) : (
          <Preview
            toggleView={toggleView}
            vault={myVault}
            ownerId={file?.user?.id}
          />
        )}
      </section>
    </Modal>
  );
};

export default VaultModal;
