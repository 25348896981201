//Refactoring №2
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';

import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import actionsOptions from 'config/actions-options';
import { handleMultisigStatusModal } from 'features/modals/modal-slice';
import { selectIsMultisigPartisipant } from 'features/app';

import UserIcon from 'components/users/user-icon';
import HistoryFolder from 'components/svg/history-folder';
import HistoryFile from 'components/svg/history-file';
import { ReactComponent as MailIcon } from './assets/mail.svg';

import styles from './style.module.scss';

const HistoryItem = ({ historyItem, color, entityType, entityName }) => {
  const { t } = useTranslation('owner');
  const dispatch = useDispatch();
  const entity = useSelector((state) => state.home?.selectedEntity?.entity);
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);

  const EVENT_LABELS_BY_ID = useMemo(
    () => ({
      1: `${t('rightFileMenu.events.created')} %type%`,
      2: `${t('rightFileMenu.events.renamed')} %type%`,
      3: `${t('rightFileMenu.events.moved')} %type%`,
      4: `${t('rightFileMenu.events.movedToTrash')}`,
      5: `${t('rightFileMenu.events.downloaded')} %type%`,
      6: `${t('rightFileMenu.events.opened')} %type%`,
      7: `${t('rightFileMenu.events.shared')} %type%`,
      8: `${t('rightFileMenu.events.unshared')} %type%`,
      9: `${t('rightFileMenu.events.addedSecurity')} %type%`,
      10: `${t('rightFileMenu.events.removedSecurity')} %type%`,
      11: `${t('rightFileMenu.events.restored')} %type%`,
      12: `${t('rightFileMenu.events.deleted')}`,
      13: `${t('rightFileMenu.events.leftNewComment')}`,
      18: `${t('rightFileMenu.events.tokenized')}`,
      19: `${t('rightFileMenu.events.generatedByAI')}`,
    }),
    []
  );

  const EVENT_TYPE_BY_ID = useMemo(
    () => ({
      1: t('rightFileMenu.events.file'),
      2: t('rightFileMenu.events.folder'),
    }),
    []
  );

  const created = historyItem.created_at
    ? moment.unix(historyItem.created_at)
    : moment();
  const { user } = historyItem;
  const entityIcon =
    entityType === 'file' ? (
      <HistoryFile color={color?.hex} />
    ) : (
      <HistoryFolder color={color?.hex} />
    );

  const openSafetyNetModal = () => {
    dispatch(
      fileFolderActionHandlerEffect(entity, actionsOptions.guardians, {
        skipGetData: true,
      })
    );
  };

  const openMultisigStatusModal = () => {
    isMultisigPartisipant &&
      dispatch(
        handleMultisigStatusModal({
          isOpen: true,
          payload: {
            actionId: JSON.parse(historyItem.additional).actionId,
            actionType: historyItem.event,
          },
        })
      );
  };

  if (
    historyItem.additional &&
    JSON.parse(historyItem.additional || '')?.actionId
  )
    return (
      <section className={styles.item}>
        <header className={styles.header}>
          <div className={styles.icon}>
            <UserIcon profile user={user} color={color} />
          </div>
          <div className={styles.details}>
            <div className={styles.action}>
              {t('rightFileMenu.events.multisigRequest')}
            </div>
            <div className={styles.about}>
              <div className={styles.time}>{created.format('H:mma')}</div>
            </div>
          </div>
        </header>
        <div className={styles.info}>
          {JSON.parse(historyItem.additional)?.status === 'cancel' ? (
            <p className={styles.canceled}>
              {t('rightFileMenu.events.canceled')}
            </p>
          ) : (
            <button
              className={styles.viewStatus}
              onClick={openMultisigStatusModal}
            >
              {t('rightFileMenu.events.viewStatus')}
            </button>
          )}
        </div>
      </section>
    );

  if (historyItem.event === 20)
    return (
      <section className={styles.item}>
        <header className={styles.header}>
          <div className={styles.icon}>
            <UserIcon profile user={user} color={color} />
          </div>
          <div className={styles.details}>
            <div className={styles.action}>
              {t('rightFileMenu.events.safetynetActivated')}
            </div>
            <div className={styles.about}>
              <div className={styles.time}>{created.format('H:mma')}</div>
              <button onClick={openSafetyNetModal} className={styles.edit}>
                {t('rightFileMenu.events.edit')}
              </button>
            </div>
          </div>
        </header>
        <div className={styles.info}>
          <div className={styles.infoItem}>
            <div className={styles.infoIcon}>{entityIcon}</div>
            <div className={styles.infoName}>
              {historyItem.file_name || entityName}
            </div>
          </div>
          <div className={styles.infoItem}>
            <div className={styles.infoIcon}>
              <MailIcon />
            </div>
            <div className={styles.infoName}>
              {JSON.parse(historyItem.additional).newMember}
            </div>
          </div>
        </div>
      </section>
    );

  return (
    <section className="entity-settings__history-item">
      <header className="entity-settings__history-item-header">
        <div className="entity-settings__history-user-icon">
          <UserIcon profile user={user} color={color} />
        </div>
        <div className="entity-settings__history-user-info">
          <div className="entity-settings__history-user-name">
            {user.username}
          </div>
          <div className="entity-settings__history-user-email">
            {user.email}
          </div>
        </div>
        <div className="entity-settings__history-time">
          {created.format('H:mma')}
        </div>
      </header>

      <div className="entity-settings__history-action">
        {EVENT_LABELS_BY_ID[historyItem.event]?.replace(
          '%type%',
          EVENT_TYPE_BY_ID[historyItem.type]
        )}
      </div>

      <div className="entity-settings__history-item-info">
        <div className="entity-settings__history-item-info-icon">
          {entityIcon}
        </div>
        <div className="entity-settings__history-item-name">
          {historyItem.file_name || entityName}
        </div>
      </div>
    </section>
  );
};

export default HistoryItem;
