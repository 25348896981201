/* eslint-disable no-unused-vars */
import React from 'react';

import SidebarInput from './input-item';
import ProgressInput from './input-progress';

// import BiosInput from './input-bios';
import Progress from 'containers/auth/NeyraChat/components/ConnectRingProgress';
// import InputButton from './input-button';

import { ReactComponent as PlusIcon } from './assets/plus.svg';
// import { ReactComponent as SkillsIcon } from './assets/skills.svg';
import { ReactComponent as DescIcon } from './assets/desc.svg';
// import { ReactComponent as ImgIcon } from './assets/img.svg';
// import { ReactComponent as DomainIcon } from './assets/domain.svg';
// import { ReactComponent as VoiceIcon } from './assets/voice.svg';
// import { ReactComponent as DriveIcon } from './assets/drive.svg';
// import { ReactComponent as TokensIcon } from './assets/tokens.svg';
// import { ReactComponent as ProfitIcon } from './assets/profit.svg';
// import { ReactComponent as SetupLogoIcon } from './assets/setupLogo.svg';

// import { componentStateList } from 'containers/auth/NeyraBios';

import styles from './styles.module.scss';

const list = [
  {
    icon: <PlusIcon />,
    placeholder: 'Domain',
    name: 'domain',
    inputType: 'input',
    step: [0],
  },
  {
    icon: <DescIcon />,
    placeholder: 'Verification code',
    name: 'code',
    inputType: 'input',
    type: 'number',
    step: [1],
  },
];

const AuthSidebar = ({
  logo,
  timeRef,
  setInputValue,
  values,
  setValues,
  setComponentState,
  cityList,
  onPublish,
  setCityList,
  setupState,
  isEditMode,
  onEnterPress,
  editStep,
  onFieledFocus,
  isFormValid,
}) => {

  const inputRender = (item, index) => {
    switch (item.inputType) {
      case 'progress':
        return (
          <ProgressInput
            setInputValue={setInputValue}
            setValues={setValues}
            valueForm={values[item.name]}
            isCurrentStep={editStep === index}
            editStep={editStep}
            key={item.name}
            {...item}
          />
        );

      default:
        return (
          <SidebarInput
            setInputValue={setInputValue}
            setValues={setValues}
            valueForm={values[item.name]}
            values={values}
            isCurrentStep={item.step.includes(editStep)}
            editStep={editStep}
            key={item.name}
            isEditMode={isEditMode}
            onEnterPress={onEnterPress}
            onFieledFocus={onFieledFocus}
            {...item}
          />
        );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.list}>{list.map(inputRender)}</div>
      <button
        disabled={!isFormValid}
        onClick={onPublish}
        className={styles.publishBtn}
      >
        <div className={styles.publishBtnComponent}>
          <div className={styles.publishBtnCircle}></div>
          <span className={styles.publishBtnText}>Publish</span>
        </div>
        <Progress />
      </button>
    </div>
  );
};

export default AuthSidebar;
