//Refactoring №3
import React from 'react';
import { useTranslation } from 'react-i18next';

import PopUpsHead from '../../shared/PopUpsHead/index';
import Key from 'containers/account-security/AccountSecurityKeyContainer/index';

import s from './style.module.scss';

const KeySecurity = ({ onClose, onSubmit }) => {
  const { t } = useTranslation('owner');
  const onConfirm = (data, context) => {
    onSubmit(
      {
        keyword: 'key',
        value: data,
      },
      context
    );
  };

  return (
    <div className={s.file_security_modal}>
      <PopUpsHead
        closePopup={onClose}
        title={t('rightFileMenu.security.applyPrivateKeyCertificate')}
      />
      <Key
        tabView={true}
        title={t('rightFileMenu.security.pinVerification')}
        onConfirm={onConfirm}
      />
    </div>
  );
};

export default KeySecurity;
