import React from 'react';

const SecurityIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip-security-0)">
      <path
        d="M13.1159 6.13965H2.88334C2.37041 6.13965 1.95312 6.55696 1.95312 7.06987V15.0699C1.95312 15.5828 2.37044 16.0001 2.88334 16.0001H13.1159C13.6288 16.0001 14.0461 15.5828 14.0461 15.0699V7.06987C14.0461 6.55696 13.6288 6.13965 13.1159 6.13965ZM12.9298 14.8838H3.06941V7.25593H12.9299V14.8838H12.9298Z"
        fill="currentColor"
      />
      <path
        d="M8.00002 0C5.48671 0 3.44189 2.06159 3.44189 4.59534V6.51162H4.55818V4.59534C4.55818 2.67703 6.10218 1.11628 8.00005 1.11628C9.89793 1.11628 11.4419 2.67703 11.4419 4.59534V6.51162H12.5582V4.59534C12.5582 2.06159 10.5135 0 8.00002 0Z"
        fill="currentColor"
      />
      <path
        d="M7.90676 10.4189C7.59848 10.4189 7.34863 10.6688 7.34863 10.9771V12.2794C7.34863 12.5877 7.59851 12.8375 7.90676 12.8375C8.21501 12.8375 8.46488 12.5876 8.46488 12.2794V10.9771C8.46491 10.6688 8.21504 10.4189 7.90676 10.4189Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip-security-0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

SecurityIcon.defaultProps = {};

export default SecurityIcon;
