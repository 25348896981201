//Refactoring №3
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useConnectWallet } from '@web3-onboard/react';
import { useTranslation } from 'react-i18next';

import { useReactive, useUpdateEffect } from 'ahooks';
import { isEmpty } from 'ramda';
import { ethers } from 'ethers';
import { getUserRSAKeys, publicKeyToPem } from 'gdgateway-client/lib/es5';

import {
  loginMetamaskClear,
  loginMetamask,
  loginUnstoppableEffect,
  signUpReferralMetamaskEffect,
  savePubKey,
} from 'store/auth/effects';
import { prepareStripeDataEffect } from 'store/payment/effects/stripe/get-stripe-data.effect';
import { MainContextWrapper } from 'store/context';

import {
  SUBSCRIPTION_NAME,
  SUBSCRIPTIONS_PERIOD,
  SUBSCRIPTIONS_PRICING,
} from 'config/tarrifs-types';
import { isElectron } from 'utils/isElectron';
import { showMetamaskMobileOption } from 'utils/showMetamaskMobileOption';
import { getCoinbaseProvider } from 'utils/getCoinbaseProvider';
import { isMobile } from 'utils/mobile';
import { getStripe } from 'utils/payment/get-dollars-from-cent';

import { SharedLayout } from '../components/SharedLayout';
import NotificationBubble from 'containers/notifications/notification-bubble';
import { CryptoButton, CryptoButtonTheme } from '../components/CryptoButton';
import FileSecurityChecker from 'containers/security/EntitySecurityChecker/index';
import ErrorModal from './ErrorModal';
import CryptoCheckPluginModal from '../crypto-check-plugin-modal';
import { signMessage } from '../login-crypto';

import { redirectToDapp } from 'containers/auth/redirectToDapp';

import style from './style.module.scss';

const SignIn = ({ history }) => {
  const { t } = useTranslation('auth');
  const searchParams = new URLSearchParams(history.location.search);
  const isoauth = JSON.parse(searchParams.get('isoauth'));
  const redirectUrl = !isoauth
    ? '/main'
    : `/oauth${history.location.search}`;
  const [signUpError, setSignUpError] = useState(false);
  const [errorContent, setErrorContent] = useState('');
  const [errorType, setErrorType] = useState('');
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const locationArray = history.location.pathname.split('/') || [];
  const subdomain = locationArray.includes('w') && locationArray[2];
  const dispatch = useDispatch();
  const workspace_securities = useSelector(
    (state) => state?.home?.entitiesSecurityCheck?.workspace_securities
  );
  const state = useReactive({
    checked: '',
    modalOpen: false,
    signature: '',
    metamaskError: '',
    signUpError: false,
  });
  const [currentAccount, setCurrentAccount] = useState('');
  const [label, setLabel] = useState('');
  const [provider, setProvider] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);

  const errorMessages = useMemo(
    () => ({
      registered: t('common.publicAddressRegistered'),
      serverError: t('common.internalServerError'),
    }),
    []
  );

  useEffect(() => {
    const shadow = document.getElementsByTagName('onboard-v2')[0].shadowRoot;
    if (connecting) {
      const svelteElement = shadow?.querySelector('.svelte-b3j15j');
      if (svelteElement) {
        svelteElement.style.border = '1px solid #5e5e5e';
        svelteElement.style.borderRadius = '20px';
      }
      const noticeContainer = shadow?.querySelector('.notice-container');
      if (noticeContainer) {
        noticeContainer.style.gridColumn = 'span 1';
      }
      if (isMobile) {
        const container = shadow?.querySelector('.modal-container-mobile');
        container.style.bottom = 'auto';
        noticeContainer.style.display = 'none';
        container.querySelector('.content').style.width = 'calc(100vw - 40px)';
        container.querySelector('.wallets-container').style.justifyContent =
          'space-around';
        redirectToDapp(shadow);
      }
    }
  }, [connecting]);

  useEffect(() => {
    if (isElectron) {
      window?.ethereum?.on('accountsChanged', (accounts) => {
        setCurrentAccount(accounts[0]);
      });
    }
    return () => {
      window?.ethereum?.removeListener('accountsChanged', (accounts) => {
        setCurrentAccount(accounts[0]);
      });
    };
  }, []);

  useEffect(() => {
    if (isConnecting && (currentAccount || window.metamask.selectedAddress)) {
      connectMetamaskViaElectron();
    }
  }, [isConnecting, currentAccount]);

  const setSignatureError = (error) => {
    if (errorMessages.unregistered === error) state.signInError = true;
  };

  useUpdateEffect(() => {
    if (
      label === 'MetaMask' ||
      label === 'Coinbase Wallet' ||
      label === 'WalletConnect'
    ) {
      const isCoinbase = label === 'Coinbase Wallet';
      handleSignUp(isCoinbase);
    }
    if (state.signature && label === 'Unstoppable') {
      loginUnstoppableEffect(history, redirectUrl);
    }
  }, [currentAccount, state.signature]);

  const handleSignUp = async (is_coinbase) => {
    try {
      if (
        searchParams.get(SUBSCRIPTION_NAME) === SUBSCRIPTIONS_PERIOD.year ||
        searchParams.get(SUBSCRIPTION_NAME) === SUBSCRIPTIONS_PERIOD.month
      ) {
        await signUpReferralMetamaskEffect(
          currentAccount || window.metamask.selectedAddress,
          null,
          is_coinbase
        );
        const { signature } = await signMessage(false, provider);
        await loginMetamaskClear(
          currentAccount || window.metamask.selectedAddress,
          signature
        );
        const stripe = await getStripe();
        const { token: sessionId } = await prepareStripeDataEffect(
          SUBSCRIPTIONS_PRICING[searchParams.get(SUBSCRIPTION_NAME)],
          { unregistered: true }
        );
        await stripe.redirectToCheckout({
          sessionId,
        });
      } else {
        await signUpReferralMetamaskEffect(
          currentAccount || window.metamask.selectedAddress,
          null,
          is_coinbase
        );
        const sig = await signMessage(setSignatureError, provider).then(
          (res) => res.signature
        );
        if (sig) {
          const loggedIn = await loginMetamask(
            currentAccount || window.metamask.selectedAddress,
            sig,
            history,
            dispatch,
            '',
            redirectUrl
          );
          setIsConnecting(false);
          if (loggedIn) {
            const currentProvider = new ethers.providers.Web3Provider(
              provider ?? window.metamask
            );
            const signer = currentProvider.getSigner();
            const keys = await getUserRSAKeys({ signer });
            await savePubKey(
              currentAccount || window.metamask.selectedAddress,
              publicKeyToPem({ publicKey: keys.publicKey })
            );
          }
        }
      }
    } catch (error) {
      if (error?.message === errorMessages.registered) {
        const error = (
          <p className={style.errorText}>
            {t('signup.addressAlreadyExist')}
            <br />
            {t('signup.wantToLogin')}
          </p>
        );
        setSignUpError(true);
        setErrorContent(error);
        setErrorType('registered');
      } else if (error?.message === errorMessages.serverError) {
        setSignUpError(true);
        setErrorContent(error?.message);
      }
      setIsConnecting(false);
    }
  };

  const errorModalHandler = () => {
    if (signUpError) setSignUpError(false);
  };

  const goSignUpEmail = () => {
    const historyState = searchParams.get(SUBSCRIPTION_NAME)
      ? {
          tariff: SUBSCRIPTION_NAME,
          price: searchParams.get(SUBSCRIPTION_NAME),
        }
      : {};
    history.push({
      pathname: subdomain ? `/w/${subdomain}/sign-up-email` : '/sign-up-email',
      search: isoauth && history.location.search,
      state: historyState,
    });
  };
  const connectMetamaskViaElectron = async () => {
    const provider = window.metamask;
    setProvider(provider);
    setLabel('MetaMask');
    localStorage.setItem('PROVIDER', 'coinbase');
  };

  const connectCoinbaseViaElectron = async (provider) => {
    try {
      setProvider(provider);
      const accounts = await provider.request({
        method: 'eth_requestAccounts',
      });
      if (accounts) {
        setCurrentAccount(accounts[0]);
        setLabel('Coinbase Wallet');
        localStorage.setItem('PROVIDER', 'coinbase');
      }
    } catch (error) {
      console.warn('COINBASE:', error);
    }
  };

  const connectWalletWithOnboard = () => {
    if (isElectron) {
      window.metamask.request({
        method: 'eth_requestAccounts',
        params: [],
      });
      showMetamaskMobileOption();
      setIsConnecting(true);
    } else {
      if (wallet) {
        disconnect(wallet);
      } else {
        connect().then(async (res) => {
          if (res.length) {
            const provider = res[0].provider;
            setProvider(provider);
            setLabel(res[0].label);
            setCurrentAccount(res[0].accounts[0].address);
          }
        });
      }
    }
  };

  return (
    <SharedLayout
      blackFooter
      isoauth={isoauth}
      history={history}
      className={style.wrapper}
      type="signup"
    >
      <div className={style.container}>
        <div className={style.loginList}>
          <CryptoButton
            type={CryptoButtonTheme.white}
            cryptoType="wallet"
            onClick={!isConnecting ? connectWalletWithOnboard : undefined}
            disabled={isConnecting}
          >
            {t('common.cryptoWallet')}
          </CryptoButton>
          <CryptoButton
            type={CryptoButtonTheme.white}
            cryptoType="mail"
            onClick={goSignUpEmail}
            data-test="sign-in-container_log-metamask_button"
            version="2"
          >
            {t('common.email')}
          </CryptoButton>
        </div>
        {isElectron && (
          <div className={style.coinbaseContainer}>
            <button
              onClick={() => {
                connectCoinbaseViaElectron(getCoinbaseProvider());
              }}
            >
              {`${t('signup.signupWith')} Coinbase Wallet`}
            </button>
          </div>
        )}
      </div>
      <CryptoCheckPluginModal
        then
        onClose={() => {
          state.modalOpen = false;
        }}
        isOpen={state.modalOpen}
        type="metamask"
      />
      <ErrorModal
        isOpen={signUpError}
        errorType={errorType}
        onClose={errorModalHandler}
        error={errorContent}
      />
      {!isEmpty(workspace_securities) && <FileSecurityChecker />}
      <NotificationBubble />
    </SharedLayout>
  );
};

const SignInComponent = (props) => (
  <MainContextWrapper>
    <SignIn {...props} />
  </MainContextWrapper>
);

export default SignInComponent;
