//Refactoring №3
import React from 'react';
import style from './style.module.scss';
export default function SpinnerLoader() {
  return (
    <div className={style['loader10']}>
      <div className={style['spinner3']}>
        <div className={style['container-B']}>
          <div className={style['hex0']}></div>
          <div className={style['hex120']}></div>
          <div className={style['hex240']}></div>
          <div className={style['spinner3']}>
            <div className={style['container-B']}>
              <div className={style['hex0']}></div>
              <div className={style['hex120']}></div>
              <div className={style['hex240']}></div>
              <div className={style['spinner3']}>
                <div className={style['container-B']}>
                  <div className={style['hex0']}></div>
                  <div className={style['hex120']}></div>
                  <div className={style['hex240']}></div>
                  <div className={style['spinner3']}>
                    <div className={style['container-B']}>
                      <div className={style['hex0']}></div>
                      <div className={style['hex120']}></div>
                      <div className={style['hex240']}></div>
                      <div className={style['spinner3']}>
                        <div className={style['container-B']}>
                          <div className={style['hex0']}></div>
                          <div className={style['hex120']}></div>
                          <div className={style['hex240']}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
