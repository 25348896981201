import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import NeyraSmallLogo from 'components/NeyraSmallLogo';
import ShippingForm from '../ShippingForm';
import PayButton from '../PayButton';

import styles from './styles.module.scss';

const Sidebar = ({ type, size, setIsFormValid, disabled, price }) => {
  const { t } = useTranslation('auth');
  const [formValues, setFormValues] = useState({
    city: '',
    address: '',
    zip: '',
  });
  const history = useHistory();

  const handleBack = () => {
    history.push('/ring');
  };

  const handlePay = () => {
    console.log({ ...formValues, type, size });
  };

  return (
    <div className={styles.sidebarWrapper}>
      <div className={styles.sidebarTop}>
        <div className={styles.header}>
          <NeyraSmallLogo className={styles.logo} />
          <button className={styles.backButton} onClick={handleBack}>
            {t('ring.back')}
          </button>
        </div>
        <ShippingForm
          setIsFormValid={setIsFormValid}
          setFormValues={setFormValues}
          type={type}
          size={size}
        />
      </div>
      <div className={styles.sidebarBottom}>
        <div className={styles.sidebarPrice}>
          <p className={styles.sidebarPriceItem}> {t('ring.price')}:</p>
          <p className={styles.sidebarPriceItem}>{`${price}$`}</p>
        </div>
        <PayButton disabled={disabled} price={price} onClick={handlePay} />
      </div>
    </div>
  );
};

export default Sidebar;
