//Refactoring №3
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import HintPopup from 'components/HintPopup';
import CopyButton from 'components/Button/copy-button';

import { selectIsMultisigActivated } from 'features/app';
import { getGroupSlug, getFactoryContract } from 'store/web3';
import copyToClipBoard from 'utils/copy-to-clipboard-without-https';
import useNotification from 'utils/hooks/use-notification';
import { entitiesTypesByKey } from 'config/entities-types';
import { generateSharingLink } from 'utils/file/generateSharingLink';
import { getEncryptedFileKey } from 'containers/main/Main/components/Sharing/utils/get-key';
import { getDecryptedKey } from 'utils/file/getDecryptedKey';

import styles from './style.module.scss';

const InviteLink = ({
  entityType,
  extension,
  slug,
  // setActiveTab,
  entryGroups = [],
  tokenizationData,
  file = null,
}) => {
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const { t } = useTranslation('owner');
  const { addNotification } = useNotification();
  const [contractAddress, setContractAddress] = useState({});
  const [fileId, setFileId] = useState({});
  const isTokenized = entryGroups?.some((item) => item.is_tokenized);

  const [key, setKey] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const fileId = await getGroupSlug(slug);
      const factoryContract = await getFactoryContract(
        tokenizationData?.ghost1155Collection?.id
      );
      setContractAddress(factoryContract.address);
      setFileId(fileId);
    };

    isTokenized && tokenizationData && fetchData();
  }, [isTokenized, tokenizationData]);

  useEffect(() => {
    if (file && file.slug && file.is_clientside_encrypted) {
      const init = async () => {
        const key = await getEncryptedFileKey(file, dispatch);
        const decryptedKey = await getDecryptedKey({ key });
        setKey(decryptedKey || '');
      };
      init();
    }
  }, [file.is_clientside_encrypted, file.slug]);

  // const goTokenize = () => {
  //   setActiveTab('web3');
  // };

  // const goMonetization = () => {
  //   setActiveTab('web3');
  // };

  const hintContent = (
    <div className={styles.hintText}>
      {t('rightFileMenu.sharing.oopsPlease')}
      <span onClick={'goTokenize'} className={styles.hintTextStrong}>
        {t('rightFileMenu.sharing.mint')}
      </span>
      {t('rightFileMenu.sharing.accessToHolders')}
      <span onClick={'goMonetization'} className={styles.hintTextStrong}>
        {t('rightFileMenu.sharing.price')}
      </span>
    </div>
  );

  const shareUrl = useMemo(() => {
    const host = window.location.origin;
    if (isTokenized)
      return `${host}/tokenized/${tokenizationData?.ghost1155Collection?.workspaceId}/${fileId}/${contractAddress}/${tokenizationData?.chain_id}`;

    if (extension === 'pages') {
      return `${host}/${entitiesTypesByKey[entityType]}/${
        extension === 'pages' ? 'editor/' : ''
      }${slug}`;
    } else {
      return `${generateSharingLink(slug)}${key ? `#${key}` : ''}`;
    }
  }, [isTokenized, fileId, tokenizationData, contractAddress, slug, key]);

  const copyLink = () => {
    copyToClipBoard(shareUrl);
    addNotification(t('rightFileMenu.sharing.copied'));
  };

  return (
    <div className={styles.inviteLink}>
      <div className={styles.inviteLinkInput}>
        <p>{shareUrl}</p>
        <HintPopup
          width="225px"
          className={styles.hintWrapper}
          content={hintContent}
          orientation="top"
          disabled={isMultisigActivated}
        >
          <CopyButton
            disabled={isMultisigActivated}
            className={styles.inviteLinkCopy}
            onClick={copyLink}
          ></CopyButton>
        </HintPopup>
      </div>
    </div>
  );
};

export default InviteLink;
