import { uniq } from 'ramda'
/**
 *
 * @param {IFile[] | IFolder[]} [entities=[]]
 */
const getSecuritiesList = (entities = []) => {
  let resSecurities = []

  entities.map((entity) => {
    if (!entity.securities) return false
    return entity.securities.map((security) => resSecurities.push(security))
  })
  return uniq(resSecurities)
}

export default getSecuritiesList
