//Refactoring №3
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import PropTypes from 'prop-types';

import CloseIcon from 'components/svg/close';
import Button, { ButtonTheme } from 'components/Button';

import style from './style.module.scss';

const EXIST_ERROR = 'public address already has been registered';

const ErrorModal = ({ isOpen, onClose, error, errorType }) => {
  const { t } = useTranslation('auth');
  const isAddressAlreadyExistsError =
    error === EXIST_ERROR || errorType === 'registered';
  const history = useHistory();

  const goSignIn = () => {
    history.push('/');
  };

  const handleOnClose = () => {
    onClose(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      className={style.errorModal}
      overlayClassName={style.errorOverlay}
      onRequestClose={handleOnClose}
    >
      <section className={style.errorContainer}>
        <button
          onClick={handleOnClose}
          className={style.errorModal__exit}
          type="button"
        >
          <CloseIcon color="#4B576C" />
        </button>
        <h1 className={style.errorTitle}>{t('signup.oops')}</h1>
        <p className={style.errorText}>{error}</p>
        {isAddressAlreadyExistsError && (
          <Button
            className={style.signInBtn}
            theme={ButtonTheme.DARK}
            onClick={goSignIn}
          >
            {t('common.signin')}
          </Button>
        )}
      </section>
    </Modal>
  );
};

ErrorModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

ErrorModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
};

export default ErrorModal;
