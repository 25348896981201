import React from 'react';

const MoveIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0835 13.5L12.75 10.8335L10.0835 8.16653L9.5835 8.66653L11.396 10.479H7.25V11.1875H11.396L9.5835 13L10.0835 13.5ZM3.729 15.8335C3.382 15.8335 3.09033 15.7154 2.854 15.479C2.618 15.243 2.5 14.9584 2.5 14.625V6.02103C2.5 5.6877 2.618 5.40286 2.854 5.16653C3.09033 4.93053 3.382 4.81253 3.729 4.81253H9.104L10 5.70853H16.2915C16.6248 5.70853 16.9097 5.82653 17.146 6.06253C17.382 6.29853 17.5 6.5832 17.5 6.91653V14.625C17.5 14.9584 17.382 15.243 17.146 15.479C16.9097 15.7154 16.6248 15.8335 16.2915 15.8335H3.729ZM3.2085 6.02103V14.625C3.2085 14.764 3.257 14.882 3.354 14.979C3.45133 15.0764 3.57633 15.125 3.729 15.125H16.2915C16.4305 15.125 16.5487 15.0764 16.646 14.979C16.743 14.882 16.7915 14.764 16.7915 14.625V6.91653C16.7915 6.77786 16.743 6.65986 16.646 6.56253C16.5487 6.4652 16.4305 6.41653 16.2915 6.41653H9.729L8.8335 5.52103H3.729C3.57633 5.52103 3.45133 5.5662 3.354 5.65653C3.257 5.74653 3.2085 5.86803 3.2085 6.02103ZM3.2085 6.02103V5.52103V15.125V14.625V6.02103Z"
      fill="currentColor"
    />
  </svg>
);

MoveIcon.defaultProps = {};

export default MoveIcon;
