import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Sidebar from './components/Sidebar';
import NeyraBorder from 'components/NeyraBorder';
import RoundButton from './components/RoundButton';
import BorderWithDrons from 'components/BorderWithDrons';

import { ReactComponent as HeartIcon } from './assets/heart.svg';
import { ReactComponent as BatteryIcon } from './assets/battery.svg';
import { ReactComponent as BluetoothIcon } from './assets/bluetooth.svg';
import { ReactComponent as EmotionalIcon } from './assets/emotional.svg';
import { ReactComponent as TemperatureIcon } from './assets/temperature.svg';
import { ReactComponent as AccelerometerIcon } from './assets/accelerometer.svg';

import styles from './styles.module.scss';

const OrderRing = () => {
  const { t } = useTranslation('auth');
  const [activeBlock, setActiveBlock] = useState('size');
  const [isFormValid, setIsFormValid] = useState(false);
  const [size, setSize] = useState('');
  const [type, setType] = useState('');
  const isDisabled = Boolean(type && size && isFormValid);
  const price = 399;
  const orderBlock = getOrderBlock(t);

  const changeSize = (value) => {
    setSize(value);
    setActiveBlock('size');
  };

  const changeType = (value) => {
    setType(value);
    setActiveBlock('type');
  };

  return (
    <div className={styles.orderRingWrapper}>
      <NeyraBorder contentClass={styles.contentClass} showCircles={false}>
        {orderBlock.map((item) => (
          <BorderWithDrons
            key={item.key}
            active={activeBlock === item.key}
            containerClass={styles.block}
          >
            <div
              className={classNames(
                styles.titleBlock,
                activeBlock === item.key && styles.activeTitle
              )}
            >
              {item.title}
            </div>
            <div className={styles.content}>
              {item.key === 'size' && (
                <div className={styles.sizeBlock}>
                  {item.values.map((item, index) => (
                    <RoundButton
                      key={index}
                      title={item.value}
                      text={item.size}
                      active={size === item.value}
                      size="medium"
                      onClick={() => {
                        changeSize(item.value);
                      }}
                    />
                  ))}
                </div>
              )}
              {item.key === 'type' && (
                <div className={styles.typeBlock}>
                  {item.values.map((item, index) => (
                    <RoundButton
                      key={index}
                      title={item}
                      active={type === item}
                      size="large"
                      onClick={() => {
                        changeType(item);
                      }}
                    />
                  ))}
                </div>
              )}
              {item.key === 'feature' && (
                <div className={styles.featureBlock}>
                  {item.values.map(({ icon, description }, indexFeature) => (
                    <div
                      key={indexFeature}
                      className={classNames(
                        styles.featureItem,
                        isDisabled && styles.featureItemActive
                      )}
                    >
                      {icon}
                      <div>{description}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </BorderWithDrons>
        ))}
      </NeyraBorder>
      <Sidebar
        setIsFormValid={setIsFormValid}
        type={type}
        size={size}
        disabled={isDisabled}
        price={price}
      />
    </div>
  );
};

export default OrderRing;

function getOrderBlock(t) {
  return [
    {
      key: 'size',
      title: t('ring.size'),
      values: [
        {
          value: 6,
          size: '16.45mm',
        },
        {
          value: 7,
          size: '17.3mm',
        },
        {
          value: 8,
          size: '18.2mm',
        },
        {
          value: 9,
          size: '19mm',
        },
        {
          value: 10,
          size: '19.8mm',
        },
        {
          value: 11,
          size: '20.6mm',
        },
        {
          value: 12,
          size: '21.4mm',
        },
        {
          value: 13,
          size: '22.2mm',
        },
      ],
    },
    {
      key: 'type',
      title: t('ring.type'),
      values: [t('ring.silver'), t('ring.gold'), t('ring.titan')],
    },
    {
      key: 'feature',
      title: t('ring.feature'),
      values: [
        {
          icon: <HeartIcon />,
          description: t('ring.description1'),
        },
        {
          icon: <BluetoothIcon />,
          description: t('ring.description2'),
        },
        {
          icon: <BatteryIcon />,
          description: t('ring.description3'),
        },
        {
          icon: <TemperatureIcon />,
          description: t('ring.description4'),
        },
        {
          icon: <AccelerometerIcon />,
          description: t('ring.description5'),
        },
        {
          icon: <EmotionalIcon />,
          description: t('ring.description6'),
        },
      ],
    },
  ];
}
