import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { loginEmail } from 'web3-auth/lib/es5';
import { NEYRA_AI_API } from 'constants/api-urls';
import { verifyUser } from 'client-neyra';
import setToken from 'utils/auth/set-token';
import { recoveryPasswordEffect } from 'store/auth/effects/recovery-password.effect';

import { LoginForm } from './LoginForm';
import { ForgotPassword } from './ForgotPassword';
import { VerificationCode } from './VerificationCode';
import { ResendLink } from './ResendLink';

import styles from './styles.module.scss';

const initialState = {
  email: '',
  password: '',
  code: '',
};

const EMAIL_ERROR_MESSAGE = 'Field email';
const NAME_ERROR_MESSAGE = 'What is your name?';
const PASSWORD_ERROR_MESSAGE = 'Password is incorrect.';
const VERIFICATION_CODE_ERROR_MESSAGE = 'Verification code is incorrect.';
const VERIFICATION_CODE_SEND_MESSAGE =
  'E-mail containing verification code is sent to';

export const LoginWithEmailModal = ({ setIsEmailLogin, isEmailLogin }) => {
  const formRef = useRef(null);
  const { t } = useTranslation('auth');
  const [errors, setErrors] = useState(initialState);
  const [requestIsSent, setRequestIsSent] = useState(false);
  const [requestIsResent, setRequestIsResent] = useState(false);
  const [isForgotPasswordStep, setIsForgotPasswordStep] = useState(false);
  const [isVerificationCodeStep, setIsVerificationCodeStep] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const history = useHistory();

  useEffect(() => {
    setErrors(initialState);
  }, [isForgotPasswordStep]);

  const checkMessage = ({ message, email, password }) => {
    if (message.startsWith(VERIFICATION_CODE_SEND_MESSAGE)) {
      setIsVerificationCodeStep(true);
      setUserPassword(password);
      setUserEmail(email);
    }
  };

  const loginSubmitHandler = async (values) => {
    const { email, password } = values;
    if (email && password) {
      try {
        const data = await loginEmail({
          password,
          email,
          NEYRA_AI_API,
        });
        if (data.data) {
          setToken(data);
          history.push('/chat');
        }
        checkMessage({ message: data.message, email, password });
      } catch (error) {
        const message = error?.response?.data?.message || '';
        if (message === NAME_ERROR_MESSAGE) {
          const data = await loginEmail({
            password,
            email,
            name: ' ',
            NEYRA_AI_API,
          });
          checkMessage({ message: data.message, email, password });
        } else if (message === EMAIL_ERROR_MESSAGE) {
          setErrors((prev) => ({
            ...prev,
            email: t('emailLogin.incorrectEmail'),
          }));
        } else if (message === PASSWORD_ERROR_MESSAGE) {
          setErrors((prev) => ({
            ...prev,
            password: t('emailLogin.incorrectPassword'),
          }));
        }
      }
    }
  };

  const sendRecoveryLink = async (email, isResending = false) => {
    recoveryPasswordEffect(email)
      .then(() => {
        isResending ? setRequestIsResent(true) : setRequestIsSent(true);
      })
      .catch(({ errors, message }) => {
        if (errors) {
          setErrors((prev) => ({
            ...prev,
            email:
              errors?.children?.email?.errors?.join() ||
              t('emailLogin.incorrectEmail'),
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            email: message || t('emailLogin.incorrectEmail'),
          }));
        }
      });
  };

  const forgotPasswordSubmitHandler = ({ email }) => {
    setUserEmail(email);
    sendRecoveryLink(email);
  };

  const onChangeEmailClick = () => {
    setRequestIsResent(false);
    setRequestIsSent(false);
  };

  const onFocus = () => {
    setErrors(initialState);
  };

  const verificationCodeSubmitHandler = async ({ code }) => {
    if (!code) {
      return;
    }
    try {
      const data = await verifyUser({ body: { email: userEmail, code } });
      if (data.data) {
        setToken(data);
        history.push('/chat');
      }
    } catch (error) {
      const message = error?.response?.message || '';
      if (message === VERIFICATION_CODE_ERROR_MESSAGE) {
        setErrors((prev) => ({
          ...prev,
          code: message,
        }));
      }
    }
  };

  const resentCode = async () => {
    const data = await loginEmail({
      password: userPassword,
      email: userEmail,
      name: ' ',
      NEYRA_AI_API,
    });
    if (data.message.startsWith(VERIFICATION_CODE_SEND_MESSAGE)) {
      setErrors(initialState);
      formRef.current.resetForm({ values: { code: '' } });
    }
  };

  return (
    <Modal
      isOpen={isEmailLogin}
      onRequestClose={() => setIsEmailLogin(false)}
      contentLabel="Analytics"
      className={styles.modal}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick
    >
      {requestIsSent ? (
        <ResendLink
          requestIsResent={requestIsResent}
          onChangeEmailClick={onChangeEmailClick}
          sendRecoveryLink={sendRecoveryLink}
          userEmail={userEmail}
        />
      ) : (
        <div
          className={classNames(
            styles.formWrapper,
            isForgotPasswordStep && styles.forgotPasswordWrapper
          )}
        >
          {isForgotPasswordStep ? (
            <ForgotPassword
              formRef={formRef}
              submitHandler={forgotPasswordSubmitHandler}
              onFocus={onFocus}
              errors={errors}
            />
          ) : isVerificationCodeStep ? (
            <VerificationCode
              formRef={formRef}
              submitHandler={verificationCodeSubmitHandler}
              onFocus={onFocus}
              errors={errors}
              resentCode={resentCode}
            />
          ) : (
            <LoginForm
              formRef={formRef}
              submitHandler={loginSubmitHandler}
              onFocus={onFocus}
              errors={errors}
              setForgotPassword={setIsForgotPasswordStep}
            />
          )}
        </div>
      )}
    </Modal>
  );
};
