/* eslint-disable no-unused-vars */
//Refactoring №2
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import { useUpdateEffect, useSafeState } from 'ahooks';
import axios from 'axios';
import cn from 'classnames';
import printJS from 'print-js';

import { getDownloadOTT } from 'store/home/effects';
import { STORAGE_KEY } from 'store/context/theme';
// import { getPublicSharedFileContent } from 'store/home/effects/file/get-file-content';
import { getTotalDownloadFileSize } from 'store/home/effects/entity-actions/entity-make-action.effect';

import { validateToken } from 'utils/auth/check-token';
import { isFile } from 'utils/file';
import { isMobile } from 'utils/mobile';
import getToken from 'utils/auth/get-token';

import actionsOptions from 'config/actions-options';
import imageSupportedFormats from 'config/image-file-extensions';

import EmptyFolder from 'components/shared/Empty/EmptyFolder';
import FilesSkeleton from 'components/skeletons/files-skeleton';
import Header from 'containers/header/header-container/no-auth-header';
import FileView from '../main/FileView/file-view';
import FolderContent from './folder-content';
import Lock from './Lock';
import { SharingEntityMobileModal } from 'containers/shared-by-link/SharingEntityMobileModal';

import { useDispatch } from 'react-redux';

import * as actions from 'store/home/actions/file/download-file.action';

import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';

import s from './sharedFolder.module.scss';

const SharedFolder = ({ folderId }) => {
  const { t } = useTranslation('account');
  const dispatch = useDispatch();
  const [files, setFiles] = useSafeState([]);
  const [count, setCount] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [viewType, setViewType] = useState('square');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [activeFile, setActiveFile] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [infoIsOpen, setInfoIsOpen] = useState(false);
  const [entityContent, setEntityContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isForbidden, setForbidden] = useState(false);
  const [isOpenMobileModal, setIsOpenMobileModal] = useState(true);
  const [folderName, setFolderName] = useState('');
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const isTelegram = searchParams.get('is_telegram') === 'true';
  const isAuthorized = !!getToken();

  const closeMobileModal = () => {
    setIsOpenMobileModal(false);
  };

  const canBePrinted =
    activeFile &&
    (imageSupportedFormats.includes('.' + activeFile?.extension) ||
      activeFile?.extension === 'pdf' ||
      activeFile?.extension === 'html');

  useUpdateEffect(() => {
    if (validateToken()) {
      history.push(`/folder/${folderId}`);
    }
  }, [folderId]);

  useEffect(() => {
    if (selectedFiles.length === 1) {
      setActiveFile(selectedFiles[0]);
      return;
    }

    setActiveFile(null);
  }, [selectedFiles.length]);

  useEffect(() => {
    const theme = localStorage.getItem(STORAGE_KEY) || '';
    document.documentElement.setAttribute(STORAGE_KEY, theme);
  }, []);

  // useEffect(() => {
  //   setLoading(false);
  //   getPublicSharedFilesEffect(folderId)
  //     .then((folderFiles) => {
  //       setLoading(true);
  //       if (folderFiles && folderFiles.data) {
  //         setCount(folderFiles.count);
  //         if (files.length < folderFiles.count) {
  //           setFiles((state) =>
  //             state
  //               ? [
  //                   ...new Set([
  //                     ...state,
  //                     ...folderFiles?.data?.map((el) => ({
  //                       ...el,
  //                       folderId: folderFiles.folderId,
  //                     })),
  //                   ]),
  //                 ]
  //               : []
  //           );
  //         } else {
  //           setFiles(
  //             folderFiles.data?.map((el) => ({
  //               ...el,
  //               folderId: folderFiles.folderId,
  //             }))
  //           );
  //         }
  //       }
  //     })
  //     .catch((e) => {
  //       if (e?.response?.status === 403) setForbidden(true);
  //     });
  // }, [folderId, history]);

  const loadFile = (file) => {
    dispatch(
      actions.startDownload(getTotalDownloadFileSize([file]), file.slug, [file])
    );
    dispatch(fileFolderActionHandlerEffect(file, actionsOptions.download));
  };

  // const loadArchive = async (list) => {
  //   const [file] = list;
  //   if (list && list.length === 1) {
  //     dispatch(
  //       actions.startDownload(getTotalDownloadFileSize([file]), file.slug, [file])
  //     );
  //     dispatch(fileFolderActionHandlerEffect(file, actionsOptions.download));
  //   } else {
  //     const {
  //       data: {
  //         jwt_ott,
  //         user_tokens: { token: oneTimeToken },
  //         gateway,
  //       },
  //     } = await getDownloadOTT(
  //       list.map((fileItem) => {
  //         return {
  //           slug: fileItem.slug,
  //         };
  //       })
  //     );
  //     const url = `${gateway.url}/download/mix`;
  //     const source = axios.CancelToken.source();

  //     axios
  //       .create({
  //         headers: {
  //           'one-time-token': oneTimeToken,
  //           'X-Download-OTT-JWT': jwt_ott,
  //         },
  //         cancelToken: source.token,
  //       })
  //       .post(
  //         url,
  //         list.map((file) => file.slug),
  //         { responseType: 'blob' }
  //       )
  //       .then((response) => {
  //         const url = window.URL.createObjectURL(response.data);
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.setAttribute('download', 'download.zip');
  //         document.body.appendChild(link);
  //         link.click();
  //         link.parentNode.removeChild(link);
  //       });
  //   }
  // };

  const renderFilePending = () => {
    return !loading && <FilesSkeleton viewType={viewType} count={5} />;
  };

  const downloadEntities = () => {
    if (activeFile) {
      if (isFile(activeFile)) {
        loadFile(activeFile);
        return;
      }
      // loadArchive([activeFile]);
    } else if (selectedFiles?.length === 1) {
      const currentFile = selectedFiles[0];
      if (isFile(currentFile)) {
        loadFile(currentFile);
        return;
      }
      //   loadArchive([currentFile]);
      // } else if (selectedFiles?.length > 1) {
      //   loadArchive(selectedFiles);
    }
  };

  const onLoadMore = (page) => {
    // getPublicSharedFilesEffect(folderId, {}, page).then((folderFiles) => {
    //   if (folderFiles) {
    //     if (folderFiles.data) {
    //       setFiles((state) =>
    //         state
    //           ? [
    //               ...new Set([
    //                 ...state,
    //                 ...folderFiles?.data?.map((el) => ({
    //                   ...el,
    //                   folderId: folderFiles?.folderId,
    //                 })),
    //               ]),
    //             ]
    //           : []
    //       );
    //     }
    //   }
    // });
  };

  const printFile = () => {
    if (canBePrinted && activeFile) {
      let fileType = 'pdf';
      if (imageSupportedFormats.includes(activeFile?.extension)) {
        fileType = 'image';
      } else if (activeFile?.type === '.html') {
        fileType = 'html';
      }
      const fileSignal = axios.CancelToken.source();
      // getPublicSharedFileContent(
      //   activeFile.slug,
      //   fileSignal.token,
      //   true,
      //   activeFile.gateway
      // ).then((data) => {
      //   if (data) {
      //     printJS(data, fileType);
      //   }
      // });
    }
  };

  const fileClickDoubleClickHandler = (file, option) => {
    const { viewFile, goToSettings, goToFolder, download } = actionsOptions;
    if (option.type === viewFile.type) {
      setActiveFile(file);
      setModalIsOpen(true);
    } else if (option.type === goToSettings.type) {
      setActiveFile(file);
    } else if (option.type === goToFolder.type) {
      history.push(
        `/folder/${
          history.location.pathname.includes('shared') ? 'shared/' : ''
        }${file.slug}`
      );
    } else if (option.type === download.type) {
      if (isFile(file)) {
        loadFile(file);
        return;
      }
      // loadArchive([file]);
    }
  };
  const onToggleFileInfo = () => {
    if (infoIsOpen) {
      setActiveFile(null);
    }
    setInfoIsOpen(!infoIsOpen);
  };

  const onCloseViewerHandler = () => {
    setActiveFile(null);
    setModalIsOpen(false);
    setEntityContent(null);
  };

  const selectedFilesAdd = (file) => {
    setSelectedFiles([...selectedFiles, file?.entities]);
    setActiveFile(null);
  };

  const selectedFilesRemove = (file) => {
    const nextSelectedFiles = selectedFiles.filter(
      (prevFile) => prevFile.slug !== file.slug
    );
    setSelectedFiles([...nextSelectedFiles]);
    setActiveFile(null);
  };

  const changeSelectAll = () => {
    if (selectAll) {
      setSelectedFiles([]);
    } else {
      setSelectedFiles(files);
    }
    setSelectAll(!selectAll);
  };

  return (
    <>
      <section
        className={cn(
          'non-auth',
          !isAuthorized && s.sharedByLink,
          infoIsOpen && 'non-auth-right-sided'
        )}
      >
        <Header
          fileViewType={viewType}
          setFileViewTypeEffect={setViewType}
          files={activeFile ? [activeFile] : selectedFiles}
          downloadEntities={downloadEntities}
          printEntities={printFile}
          canBePrinted={canBePrinted}
          selectedFile={activeFile}
          infoIsOpen={infoIsOpen}
          onToggleFileInfo={onToggleFileInfo}
          isForbidden={isForbidden}
          isPublic={true}
          changeSelectAll={changeSelectAll}
          selectAll={selectAll}
          getFolderName={setFolderName}
        />
        {!isForbidden && loading && files?.length === 0 && (
          <div className="home__page entry-list entry-list-empty">
            <EmptyFolder description="" dropBtn={false} />
          </div>
        )}
        <main>
          {isForbidden ? (
            <div className="non-auth__main-acces-denied">
              <div className="non-auth__main-acces-denied__lock">
                <Lock />
              </div>
              <span
                className={cn(
                  'non-auth__main-acces-denied__bold-text text--bold',
                  s.accessDeniedTitle
                )}
              >
                {t('security.accessDenied')}
              </span>
              <span className="non-auth__main-acces-denied__text">
                {t('security.askUserToInviteDirectly')}
              </span>
            </div>
          ) : (
            <div className="non-auth__main-content">
              {loading && (
                <FolderContent
                  files={files}
                  onLoadMore={onLoadMore}
                  folderId={folderId}
                  count={count}
                  fileViewType={viewType}
                  fileClickDoubleClickHandler={fileClickDoubleClickHandler}
                  activeFile={activeFile}
                  selectedFilesAdd={selectedFilesAdd}
                  selectedFilesRemove={selectedFilesRemove}
                  selectedFiles={selectedFiles}
                  changeSelectAll={changeSelectAll}
                  selectAll={selectAll}
                />
              )}
              {activeFile && (
                <FileView
                  isOpen={modalIsOpen}
                  entity={activeFile}
                  onRequestClose={onCloseViewerHandler}
                  fileContentExternal={entityContent}
                  isPublic
                />
              )}
              {renderFilePending()}
            </div>
          )}
        </main>
      </section>
      {isOpenMobileModal && isMobile && !isForbidden && !isTelegram && (
        <SharingEntityMobileModal
          closeMobileModal={closeMobileModal}
          name={folderName}
          entityType="folder"
          slug={folderId}
        />
      )}
    </>
  );
};

export default SharedFolder;
