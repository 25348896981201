import React from 'react';

import { ReactComponent as WalletIcon } from '../../assets/wallet.svg';
import { ReactComponent as TelegramIcon } from '../../assets/telegram.svg';
import { ReactComponent as EmailIcon } from '../../assets/email.svg';
import styles from './styles.module.scss';

const ButtonWithIcon = ({ option, handleClick }) => {
  let icon;
  switch (option) {
    case 'telegram':
      icon = <TelegramIcon />;
      break;
    case 'email':
      icon = <EmailIcon />;
      break;
    case 'wallet':
      icon = <WalletIcon />;
      break;
    default:
      icon = null;
  }

  return (
    <button className={styles.optionButton} onClick={handleClick}>
      {icon}
    </button>
  );
};

const ButtonsWithIcons = ({ options, selectButton }) => {
  return (
    <div className={styles.optionsContainer}>
      {options.map((option, index) => (
        <ButtonWithIcon
          key={index}
          option={option}
          handleClick={() => {
            selectButton(option);
          }}
        />
      ))}
    </div>
  );
};

export default ButtonsWithIcons;
