import * as actions from '../actions/ghost-mode.actions'
import { merge, prop } from 'ramda'

const initialState = {
  status: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GHOST_MODE_TOGGLE:
      return merge(state, {
        status: prop('payload', action),
      })
    default:
      return state
  }
}
