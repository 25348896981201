//Refactoring №3
import React from 'react';
import { useSelector } from 'react-redux';

import {
  selectSidebarWidth,
  selectCollapsed,
} from 'features/leftMenu/leftMenuSlice';
import { selectTechnicalWorksState } from 'features/app';

import { WorkspaceButton } from './components/WorkspaceButtonV2';
import Container from './components/Container';
// import { StorageInfo } from './components/StorageInfo';
import { SettingsInfo } from './components/SettingsInfo';

import CN from 'classnames';
import style from './style.module.scss';

export default function Sidebar({
  isGuest,
  isMember,
  className,
  setCurrentSizes,
  currentSizes,
}) {
  const isTechnicalWorks = useSelector(selectTechnicalWorksState);
  const width = useSelector(selectSidebarWidth);
  const collapsed = useSelector(selectCollapsed);

  return (
    <div
      style={!collapsed ? { width: width - 1 + 'px' } : {}}
      className={CN(
        style.container,
        isTechnicalWorks && style.containerTec,
        className
      )}
    >
      <div>
        <WorkspaceButton
          collapsed={collapsed}
          isGuest={isGuest}
        />
        <Container
          isGuest={isGuest}
          isMember={isMember}
          setCurrentSizes={setCurrentSizes}
          currentSizes={currentSizes}
        />
      </div>
      <div>
        {/* {!isGuest && <StorageInfo />} */}
        <SettingsInfo collapsed={collapsed} />
      </div>
    </div>
  );
}
