import * as actions from '../../actions/file/toggle-security-file.actions'
import { merge } from 'ramda'
import getErrorData from 'utils/request/get-error-data'

const initialState = {
  status: false,
  error: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE_SECURITY_FILE: {
      return merge(state, {
        pending: true,
      })
    }

    case actions.TOGGLE_SECURITY_FILE_SUCCESS: {
      return merge(state, {
        pending: false,
        data: {},
        error: {},
      })
    }

    case actions.TOGGLE_SECURITY_FILE_FAILURE: {
      return merge(state, {
        status: false,
        error: getErrorData(action.payload),
        securityId: null,
      })
    }

    default:
      return state
  }
}
