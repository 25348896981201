export type INotification = {
  id: number,
  message: string,
  type: 'alert' | 'warning' | 'success' | 'default',
  hideIcon: boolean,
  width: string,
  merged: boolean,
  duration: number,
}


type INotificationResponce = {
  payload: { notification: INotification },
  type: string
}

type INotificationCleanResponce = {
  type: string
}

export const NotificationActionTypes = {
  addNotification: 'addNotification',
  removeNotification: 'removeNotification',
  clearAll: 'clearAll',
}

export const addNotification = (notification: INotification): INotificationResponce  => ({
  payload: { notification },
  type: NotificationActionTypes.addNotification,
})

export const removeNotification = (notification: INotification): INotificationResponce => ({
  payload: { notification },
  type: NotificationActionTypes.removeNotification,
})

export const clearAllNotification = (): INotificationCleanResponce => ({
  type: NotificationActionTypes.clearAll,
})
