// Refactoring №3
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import getToken from 'utils/auth/get-token';

import GhostLoader from 'components/GhostLoader';

import style from './style.module.scss';

export default function OAuth({ history }) {
  const { t } = useTranslation('auth');

  useEffect(() => {
    const queryParams = history.location.search
      ? history.location.search + '&'
      : '?';
    const api = process.env.REACT_APP_API_PATH;
    const endpoint = api && api.replace(/\/api$/, '');
    const userToken = getToken();
    if (userToken) {
      const tokenWithoutBearer = userToken.replace('Bearer ', '');
      window.location.href = `${endpoint}/oauth/v2/auth${queryParams}bearer=${tokenWithoutBearer}`;
    } else {
      history.push(`/sign-in${queryParams}isoauth=true`);
    }
  }, []);
  return (
    <div className={style.container}>
      <GhostLoader texts={[t('common.routing')]} />
    </div>
  );
}
