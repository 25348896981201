import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  addAudioToQueue,
  removeAudioFromQueue,
  removeMessageFromQueue,
  setAutoSpeaker,
} from 'features/speaker/speakerSlice';
import { unrealSpeechStream } from 'utils/unrealSpeach';
import useIsRegistrationFlow from 'utils/hooks/use-is-registration-flow';

import { ReactComponent as SpeakerIcon } from './speaker.svg';
import styles from './styles.module.scss';

const TextToVoiceSpeaker = () => {
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [queueProcessing, setQueueProcessing] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const dispatch = useDispatch();
  const { messageQueue, audioQueue, autoSpeaker } = useSelector(
    (state) => state.speaker
  );

  const isRegistrationFlow = useIsRegistrationFlow();

  useEffect(() => {
    const speakNextMessage = async () => {
      if (autoSpeaker && messageQueue.length > 0 && !queueProcessing) {
        setQueueProcessing(true);
        const nextMessage = messageQueue[0];
        dispatch(removeMessageFromQueue());
        try {
          const outputUri = await unrealSpeechStream(nextMessage);
          dispatch(addAudioToQueue(outputUri));
        } catch (error) {
          console.error('Error during speech synthesis:', error);
        } finally {
          setQueueProcessing(false);
        }
      }
    };

    speakNextMessage();
  }, [autoSpeaker, messageQueue, queueProcessing]);

  useEffect(() => {
    if (!isPlaying && audioQueue.length > 0) {
      playNextAudio(audioQueue);
    }
  }, [audioQueue, isPlaying]);

  const playNextAudio = (audioQueue) => {
    if (audioQueue.length > 0) {
      const nextAudio = audioQueue[0];
      const newAudioPlayer = new Audio(nextAudio);
      setAudioPlayer(newAudioPlayer);
      newAudioPlayer.play();
      newAudioPlayer.onended = () => {
        dispatch(removeAudioFromQueue());
        setIsPlaying(false);
      };
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    const storedAutoSpeaker = localStorage.getItem('AutoSpeaker');
    if (storedAutoSpeaker !== null) {
      dispatch(setAutoSpeaker(storedAutoSpeaker === 'true'));
    }
  }, []);

  useEffect(() => {
    if (!autoSpeaker && audioPlayer !== null) {
      audioPlayer.pause();
      setIsPlaying(false);
    }
  }, [autoSpeaker]);

  const handleSpeaker = () => {
    const newAutoSpeakerSetting = !autoSpeaker;
    localStorage.setItem('AutoSpeaker', newAutoSpeakerSetting.toString());
    dispatch(setAutoSpeaker(newAutoSpeakerSetting));
  };

  return (
    <button
      disabled={isRegistrationFlow}
      onClick={handleSpeaker}
      className={classNames(
        styles.speakerBtn,
        autoSpeaker && styles.activeSpeakerBtn
      )}
    >
      <SpeakerIcon />
    </button>
  );
};

export default React.memo(TextToVoiceSpeaker);
