import { isString } from "lodash";

const localKey = 'tokenPurchaseForBiosActivation';
const localPublishKey = 'tokenPurchaseForPublish';

export const saveTokenPurchaseStatusToLocalStorage = (key, value) => {
  localStorage.setItem(key || localKey, value || 'true');
};

export const deleteTokenPurchaseStatusFromLocalStorage = (key) => {
  localStorage.removeItem(key || localKey);
};

export const checkTokenPurchaseStatusInLocalStorage = (key) => {
  const tokenPurchaseStatus = localStorage.getItem(key || localKey);
  if (tokenPurchaseStatus !== null && (tokenPurchaseStatus === 'true' || isString(tokenPurchaseStatus))) {
    return true;
  } else {
    return false;
  }
};

export { localPublishKey }