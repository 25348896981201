/* eslint-disable no-debugger */
import React, { useState, useContext } from "react";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TemplateForm from "containers/main/NeyraDriveContainer/components/ContentDrive/Template";
import { updateWorkspaceSettings } from 'features/workspaceSettings/workspaceSettingsSlice';
import { setCurrentWorkspace } from 'store/main/actions';
import MainContext from 'store/main/context/main-context';
import {
  handleEncryptModal,
  needEncryptFile,
  handleUploadDropedFiles,
} from 'store/home/actions';
import Button, { BUTTON_TYPE } from "../Template/Button";
import Checkbox from 'components/Checkbox';
import { IFile } from 'interfaces/file-folder';

import styles from './styles.module.scss';
import { IRootState } from "store/root-store";

type IProps = {
  entity: IFile,
  onClose: () => void,
  dispatch: any,
}

const CreateRename = ({ dispatch }: IProps) => {
  const { t } = useTranslation('owner');
  const [checked, setChecked] = useState<boolean>(false);
  const { dispatch: mainDispatch } = useContext(MainContext);
  const uploadDropedFiles = useSelector((state: IRootState) => state.home.encrypt?.uploadDropedFiles);
  const errorCode = useSelector((state: IRootState) => state.home?.getFiles?.getFilesError?.code);

  const {
    dropUploaderRef
  } = useContext<{
    state: {
      workspace: any;
    };
    dropUploaderRef: any;
  }>(MainContext);

  const toggleChecked = () => {
    setChecked((prevState) => !prevState);
  };

  const handleCloseEncrypt = () => {
    dispatch(handleEncryptModal(false));
    dispatch(handleUploadDropedFiles(false));
  };
  
  const onAddFileEncrypt = () => {
    if (uploadDropedFiles) {
      handleCloseEncrypt();
    } else if (dropUploaderRef?.current && !errorCode) {
      dropUploaderRef.current.open();
    }
  };

  const updateWorkspace = (props: any) => {
    return dispatch(updateWorkspaceSettings(props)).then((ws: any) => {
      mainDispatch(setCurrentWorkspace(ws));
    });
  };

  const handleYesButton = async () => {
    if (checked) {
      updateWorkspace({
        showEncryptModal: false,
        encryptFilesOnUpload: true,
      });
    }
    dispatch(needEncryptFile(true));
    handleCloseEncrypt();
    setTimeout(() => {
      onAddFileEncrypt();
    }, 400)
  };

  const handleNoButton = () => {
    if (checked) {
      updateWorkspace({
        showEncryptModal: false,
        encryptFilesOnUpload: false,
      });
    }
    dispatch(needEncryptFile(false));
    handleCloseEncrypt();
    setTimeout(() => {
      onAddFileEncrypt();
    }, 400)
  };

  return (
    <div className={styles.createContainer}>
      <p className={styles.text}>{t('encryptModal.description')}</p>
      <div className={styles.actions}>
        <Button
          onClick={handleNoButton}
          type={BUTTON_TYPE.cancel}
        >
          {t('encryptModal.no')}
        </Button>
        <Button
          onClick={handleYesButton}
        >
          {t('encryptModal.yes')}
        </Button>
      </div>
      <div className={styles.footer} >
        <Checkbox checked={checked} onClick={toggleChecked} />
        <p>{t('encryptModal.rememberChoice')}</p>
      </div>
    </div>
  )
}

const CreateRenameModal = ({...rest }: any) => {
  const { t } = useTranslation('owner');
  const dispatch = useDispatch();

  const onCloseModal= () => {
    dispatch(handleEncryptModal(false));
    dispatch(handleUploadDropedFiles(false));
  }

  return (
    <TemplateForm
      onClose={onCloseModal}
      title={t('encryptModal.title')}
    >
      <CreateRename
        {...rest}
        dispatch={dispatch}
        onClose={onCloseModal}
      />
    </TemplateForm>
  )
}

export default CreateRenameModal;