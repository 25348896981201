import * as actions from '../../actions/file/file-move.actions'

import { assoc, merge } from 'ramda'
import getErr from 'utils/request/get-error-data'

const initialState = {
  status: false,
  err: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FILE_MOVE:
      return assoc('pending', true, state)

    case actions.FILE_MOVE_SUCCESS:
      return assoc('pending', false, state)

    case actions.FILE_MOVE_FAILURE:
      return merge(state, {
        status: false,
        err: getErr(action),
      })

    default:
      return state
  }
}
