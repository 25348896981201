//Refactoring №3
import React, { useEffect, useContext } from 'react';

import { ReactComponent as ValueIcon } from './assets/ValueIcon.svg';
import { ThemeContext, LIGHT_THEME } from 'store/context/theme';

import styles from './style.module.scss';

const MetaItem = ({ icon, label, value }) => {
  const { theme } = useContext(ThemeContext);
  const isWhiteTheme = theme === LIGHT_THEME;

  const progressId = `progress-${label}`

  useEffect(() => {
    const svgElement = document.getElementById(progressId);
    for (let step = 0; step < value; step++) {
      svgElement.children[step].style.fill = isWhiteTheme ? '#1B1919' : '#fff' ;
      svgElement.children[step].style.fillOpacity = '1';
    }
  }, [progressId, isWhiteTheme, value])

  return (
    <div className={styles.item}>
      <div className={styles.itemLabels}>
        <div className={styles.itemLabelContent}>
          {icon}
          <p className={styles.label}>{label}</p>
        </div>
        <p className={styles.labelValue}>{value}</p>
      </div>
      <div className={styles.value}>
        <ValueIcon id={progressId} />
      </div>
    </div>
  );
};

export default MetaItem;
