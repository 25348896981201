import React from 'react';

const CommentIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C3.589 0 0 3.589 0 8C0 9.538 0.431 11.015 1.248 12.288L0.036 15.314C-0.038 15.5 0.005 15.712 0.147 15.854C0.242 15.949 0.37 16 0.5 16C0.563 16 0.625 15.988 0.686 15.964L3.712 14.752C4.985 15.569 6.462 16 8 16C12.411 16 16 12.411 16 8C16 3.589 12.411 0 8 0ZM8 15C6.574 15 5.209 14.578 4.054 13.779C3.97 13.72 3.87 13.69 3.77 13.69C3.707 13.69 3.644 13.702 3.584 13.726L1.398 14.602L2.274 12.416C2.336 12.26 2.316 12.083 2.221 11.946C1.422 10.791 1 9.426 1 8C1 4.14 4.14 1 8 1C11.86 1 15 4.14 15 8C15 11.86 11.86 15 8 15Z"
      fill="currentColor"
    />
  </svg>
);

CommentIcon.defaultProps = {};

export default CommentIcon;
