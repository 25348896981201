//Refactoring №3
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
import { useTranslation } from 'react-i18next';

import { useUpdateEffect } from 'ahooks';

import { loginMetamask, loginUnstoppableEffect } from 'store/auth/effects';
import { checkMetamaskInstalled } from 'utils/crypto/wallet/metamask';
import { isMobile } from 'utils/mobile';
import { injected } from 'utils/crypto/wallet/connectors';

import { signMessage } from 'containers/auth/login-crypto';
import CryptoCheckPluginModal from 'containers/auth/crypto-check-plugin-modal';
import ErrorModal from './ErrorModal';

import { ReactComponent as MetamaskIcon } from 'static/assets/svg/metamask-icon.svg';
import { ReactComponent as CoinbaseIcon } from 'static/assets/svg/coinbase-wallet-icon.svg';
import { ReactComponent as UnstoppableIcon } from 'static/assets/svg/unstoppable-wallet-icon.svg';

import style from './style.module.scss';

type LoginButtonsListProps = {
  subdomain: string;
  history: unknown;
};

export default function LoginButtonsList({
  subdomain,
  history,
}: LoginButtonsListProps) {
  const [signature, setSignature] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [error, setError] = useState('');
  const [isInstallExtensionModal, setInstallExtensionModal] = useState(false);
  const { t } = useTranslation('auth');

  const { activate, account } = useWeb3React();
  const dispatch = useDispatch();

  const closeModalHandler = () => {
    if (isInstallExtensionModal) setInstallExtensionModal(false);
    if (errorModal) setErrorModal(false);
  };

  useUpdateEffect(() => {
    if (signature) {
      loginMetamask(account, signature, history, dispatch, subdomain).catch(
        (error) => {
          setError(error?.message);
          setErrorModal(true);
        }
      );
    }
  }, [account, signature]);

  const connect = async () => {
    if (isMobile && !checkMetamaskInstalled()) {
      const dappUrl = `${window?.location?.host}/sign-in`;
      const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
      return window.open(metamaskAppDeepLink, '_self');
    }
    if (!checkMetamaskInstalled()) {
      setInstallExtensionModal(true);
    }
    await activate(injected);
  };

  const metamaskLogin = async (e) => {
    try {
      e.preventDefault();
      await connect().then(async () => {
        const sig = await signMessage()
          .then((res) => res.signature)
          .catch(() => {
            setErrorModal(true);
          });
        if (sig) {
          setSignature(sig);
        }
      });
    } catch (error) {
      setErrorModal(true);
      setError(error?.message);
    }
  };
  const unstoppableLogin = () => {
    loginUnstoppableEffect(history);
  };

  return (
    <div className={style.login__wrapper}>
      <h3 className={style.login__title}>{t('common.pleaseSignIn')}</h3>
      <div className={style.login__list}>
        <button className={style.login__button} onClick={metamaskLogin}>
          <MetamaskIcon />
        </button>
        <button className={style.login__button} onClick={unstoppableLogin}>
          <UnstoppableIcon />
        </button>
        <button className={style.login__button} onClick={metamaskLogin}>
          <CoinbaseIcon />
        </button>
      </div>
      <ErrorModal
        isOpen={errorModal}
        error={error}
        onClose={closeModalHandler}
      />
      <CryptoCheckPluginModal
        type="metamask"
        isOpen={isInstallExtensionModal}
        onClose={closeModalHandler}
      />
    </div>
  );
}
