//Refactoring №3
import { combineReducers } from 'redux';

import uploadFiles from './upload-files.reducer';
import saveUploadFiles from './save-upload-files.reducer';
import getFiles from './get-files.reducer';
import getDocuments from './get-documents.reducer';
import getVaults from './get-vaults.reducer';
import getLinks from './get-links.reducer';
import getFilteredFiles from './get-filtered-files.reduser';
import createFolder from './create-folder.reducer';
import selectedEntity from './selected-entity.reducer';
import getTfaData from './get-tfa-reducer';
import activateTfa from './activate-tfa.reducer';
import userInfo from './get-user-info.reducer';
import fileView from './file-view.reducer';
import toggleSecurityFile from './file/toggle-security-file.reducer';
import fileMove from './file/file-move.reducer';
import fileDownload from './file/download-file.reducer';
import imageToImage from './file/image-to-image.reducer';
import aiUpscaller from './file/ai-upscaller.reducer';
import deleteFile from './file/delete-file.reducer';
import removeFileFromTrash from './file/remove-file-from-trash.reducer';
import restoreFile from './file/restore-file.reducer';
import entitiesSecurityCheck from './entities-security-check.reducer';
import ghostMode from './ghost-mode.reducer';
import ghostFiles from './ghost-files.reducer';
import fileUpdate from './file/file-update.reducer';
import activatePin from './activate-pin.reducer';
import pendingFilesFolders from './pending-files-folders.reducer';
import selectedFilesFolders from './selected-files-folders.reducer';
import search from './search.reducer';
import filesDrag from './files-drag/files-drag.reducer';
import encrypt from './encrypt.reducer';
import cancelFolderUploading from './cancel-folder-uploading.reducer';
import encryptionKey from './encryption-key.reducer';

export const homeReducer = combineReducers({
  uploadFiles,
  saveUploadFiles,
  getFiles,
  selectedEntity,
  ghostFiles,
  pendingFilesFolders,
  selectedFilesFolders,
  deleteFile,
  fileUpdate,
  fileView,
  fileMove,
  toggleSecurityFile,
  getTfaData,
  activateTfa,
  userInfo,
  createFolder,
  entitiesSecurityCheck,
  ghostMode,
  activatePin,
  search,
  filesDrag,
  removeFileFromTrash,
  restoreFile,
  getDocuments,
  getVaults,
  getLinks,
  getFilteredFiles,
  fileDownload,
  imageToImage,
  aiUpscaller,
  encrypt,
  encryptionKey,
  cancelFolderUploading,
});
