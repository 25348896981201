//Refactoring №2
import React, { useState, useEffect, forwardRef } from 'react';
import { DebounceInput } from 'react-debounce-input';

import cn from 'classnames';

import EyeIcon from 'components/svg/eye';
import ErrorIcon from 'components/svg/error-triangle';

const AuthInput = forwardRef(
  (
    {
      label,
      value,
      onChange,
      onBlur,
      onKeyDown,
      placeholder,
      name,
      description,
      hidden,
      type,
      withValidation = true,
      error,
      isPassword,
      autoComplete,
      disabled,
      tooltip,
      tabIndex,
      maxLength,
      className,
      debounceTimeout,
      ...props
    },
    ref
  ) => {
    const [inputType, setInputType] = useState(type);
    const hasError = withValidation && error;
    useEffect(() => {
      if (type !== inputType) {
        setInputType(type);
      }
    }, [type]);

    if (hidden) return null;

    const changeType = () => {
      if (inputType === 'text') {
        setInputType(type);
      } else {
        setInputType('text');
      }
    };

    return (
      <label
        className={cn('auth__label auth__label-column', {
          'auth__label-error': hasError,
        })}
      >
        {label}
        <DebounceInput
          name={name}
          type={inputType}
          className={cn(
            {
              auth__input: true,
              'auth__input-error': hasError,
            },
            className
          )}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          disabled={disabled}
          autoComplete={autoComplete}
          tabIndex={tabIndex}
          maxLength={maxLength}
          inputRef={ref}
          debounceTimeout={debounceTimeout}
          data-test={props['data-test']}
        />
        {isPassword && (
          <button
            type="button"
            className={cn(
              'auth__input-button',
              inputType === 'text' && 'active'
            )}
            onClick={changeType}
          >
            <EyeIcon />
          </button>
        )}
        {hasError && (
          <p className="auth__input-description auth__input-errors">
            <ErrorIcon />
            {error}
          </p>
        )}
        {description && (
          <p className="auth__input-description">{description}</p>
        )}
        {tooltip}
      </label>
    );
  }
);

AuthInput.displayName = 'AuthInput';

AuthInput.defaultProps = {
  name: '',
  label: '',
  value: '',
  placeholder: '',
  description: '',
  type: 'text',
  className: '',
  tabIndex: 1,
  isPassword: false,
  disabled: false,
  debounceTimeout: 1000,
  onChange: () => {},
  onBlur: () => {},
  onKeyDown: () => {},
};
export default AuthInput;
