import * as actions from '../../actions/file/delete-file.actions'
import { assoc, merge } from 'ramda'
import getErrData from 'utils/request/get-error-data'

const initialState = {
  status: false,
  err: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.FILE_DELETE:
      return assoc('pending', true, state)

    case actions.FILE_DELETE_SUCCESS:
      return merge(state, {
        status: false,
        err: {},
      })

    case actions.FILE_DELETE_FAILURE:
      return merge(state, {
        status: false,
        err: getErrData(action),
      })

    default:
      return state
  }
}
