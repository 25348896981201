//Refactoring №3
import React, { useEffect, useRef } from 'react';

import CN from 'classnames';

import { Header } from '../Header';
import AuthFooterInfo from '../auth-footer-info';
import Footer from '../Footer';

import { ReactComponent as XBackground } from './assets/X.svg';
import { ReactComponent as WhiteBlock } from './assets/blocks.svg';
import { Canvas } from './bg';
import { prepareAnimation } from './animation';

import style from './style.module.css';

const getFooterProps = (type) => {
  switch (type) {
    case 'signup':
    case 'signUpEmail':
      return { signup: true };
    case 'signin':
      return { signin: true };
    case 'NFT':
      return { NFT: true };
    case 'joinWorkspace':
      return {};
    default:
      return { signin: true };
  }
};

export const SharedLayout = ({
  className,
  children,
  type,
  showNeuroBackground = false,
  whiteBlockBackground = false,
  blackFooter = false,
  customUrl = false,
  isoauth = false,
  isTelegramPage = false,
  history,
}) => {
  const showAnimation = false; // type !== 'signinEmail' && type !== 'joinWorkspace'
  const showXBackground = false;
  const canvasRef = useRef(null);
  useEffect(() => {
    if (showAnimation) return prepareAnimation(canvasRef.current);
  }, [showAnimation]);

  const footerProps = getFooterProps(type);

  return (
    <div
      className={CN(
        style.container,
        className,
        type === 'joinWorkspace' && style.joinContainer
      )}
    >
      <div className={style.content}>
        {whiteBlockBackground && (
          <WhiteBlock className={style.whiteBlockBackground} />
        )}
        {showNeuroBackground && <Canvas />}
        {showXBackground && <XBackground className={style.background} />}
        {showAnimation && (
          <canvas ref={canvasRef} className={style.scene}></canvas>
        )}
        <Header
          darkTheme
          blueIcon={
            type === 'joinWorkspace' || (type === 'signinEmail' && customUrl)
          }
          type={type}
          isTelegramPage={isTelegramPage}
        />
        {children}
        {blackFooter ? (
          <Footer {...footerProps} isoauth={isoauth} history={history} />
        ) : (
          <AuthFooterInfo className={style.footer} {...footerProps} />
        )}
      </div>
    </div>
  );
};
