// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit'
import { createApi } from '@reduxjs/toolkit/query/react'
import { fetchBaseQuery } from '@reduxjs/toolkit/query'
import getToken from 'utils/auth/get-token'

const initialState = { unsubscribed: false }

const unsubscibeSlice = createSlice({
  name: 'unsubscibe',
  initialState,
  reducers: {
    setUnsubscribed(state, action) {
      state.unsubscribed = action.payload
    },
  },
})

export default unsubscibeSlice.reducer

export const api = createApi({
  reducerPath: 'unsubscibe',
  baseQuery: fetchBaseQuery({
    baseUrl: `http://localhost:1437/api/user/tariff/unsubscribe`,
    prepareHeaders(headers) {
      headers.append('authorization', `Bearer ${getToken()}`)
      headers.append('accepts', 'application/json')
      return headers
    },
  }),
  endpoints: (build) => ({
    unsubscibe: build.mutation({
      query: () => ({
        url: `/`,
        method: 'POST',
      }),
    }),
  }),
})
