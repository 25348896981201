import React from 'react';

const GeoSecurityIcon = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M10 9.18762C9.639 9.18762 9.33 9.05912 9.073 8.80212C8.816 8.54512 8.6875 8.23612 8.6875 7.87512C8.6875 7.50012 8.8125 7.18762 9.0625 6.93762C9.3125 6.68762 9.625 6.56262 10 6.56262C10.361 6.56262 10.67 6.68762 10.927 6.93762C11.184 7.18762 11.3125 7.50012 11.3125 7.87512C11.3125 8.23612 11.1875 8.54512 10.9375 8.80212C10.6875 9.05912 10.375 9.18762 10 9.18762ZM6.4375 17.1041C6.4375 16.9098 6.51033 16.7431 6.656 16.6041C6.802 16.4655 6.96533 16.3961 7.146 16.3961H9.646C9.646 15.5211 9.43417 14.7016 9.0105 13.9376C8.58683 13.1736 8.007 12.5486 7.271 12.0626C6.56267 11.5903 6.007 10.9861 5.604 10.2501C5.20133 9.51412 5 8.72246 5 7.87512C5 6.48612 5.48617 5.30562 6.4585 4.33362C7.4305 3.36129 8.611 2.87512 10 2.87512C11.389 2.87512 12.5695 3.36129 13.5415 4.33362C14.5138 5.30562 15 6.48612 15 7.87512C15 8.73612 14.7987 9.53812 14.396 10.2811C13.993 11.0245 13.4305 11.6253 12.7085 12.0836C11.9585 12.542 11.3787 13.153 10.969 13.9166C10.559 14.6806 10.354 15.5071 10.354 16.3961H12.854C13.0487 16.3961 13.212 16.4655 13.344 16.6041C13.4757 16.7431 13.5415 16.9098 13.5415 17.1041H6.4375ZM10 12.1666C11.1943 12.1666 12.2082 11.75 13.0415 10.9166C13.8748 10.0833 14.2915 9.06946 14.2915 7.87512C14.2915 6.68079 13.8748 5.66696 13.0415 4.83362C12.2082 4.00029 11.1943 3.58362 10 3.58362C8.80567 3.58362 7.79183 4.00029 6.9585 4.83362C6.12517 5.66696 5.7085 6.68079 5.7085 7.87512C5.7085 9.06946 6.12517 10.0833 6.9585 10.9166C7.79183 11.75 8.80567 12.1666 10 12.1666Z"
      fill="var(--iconColor)"
    />
  </svg>
);

GeoSecurityIcon.defaultProps = {
  className: '',
};

export default GeoSecurityIcon;
