export const transformSize = (size = '', decimals = 1, showSize = true) => {
  const bytes = Number(size);
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return `0${showSize ? ' Bytes': ''}`;
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  const result = parseFloat(bytes / Math.pow(1024, i));
  return `${Number.isInteger(result.toFixed(decimals)) ? result : result.toFixed(decimals)} ${
    showSize && sizes[i]
  }`;
};

export const calcPercentageByFive = (storage, storageSize) =>
  Math.round((Number(storage) / storageSize + Number.EPSILON) * 100);

export const fromKbToGb = (kb) => {
  const gb = Number(kb) / 1000000;
  const res = gb === 0 ? 0 : gb.toFixed(4);

  return Number(res);
};

export const fromByteToMb = (bytes) => {
  const mb = Number(bytes) / 1048576;
  const res = mb === 0 ? 0 : mb.toFixed(2);

  return Number(res);
};

export const fromByteToGb = (bytes) => {
  const mb = Number(bytes) / 1073741824;
  const res = mb === 0 ? 0 : mb.toFixed(2);

  return Number(res);
};

export const fromByteToTb = (bytes) => {
  const tb = bytes / 1099511627776;
  const res = tb === 0 ? 0 : tb.toFixed(2);
  return Number(res);
}

export const calcPercentageByFiveFromGb = (bytes) =>
  (Math.round((Number(bytes) / 1073741824 + Number.EPSILON) * 20) / 20) * 100;

export const calcPercentageByFiveFromTb = (bytes) =>
  (Math.round((Number(bytes) / 10995116277759978 + Number.EPSILON) * 20) / 20) *
  100;

export const sidebarSizeTransformer = (size) => {
  if (size < 1073741824) {
    return `${fromByteToMb(size)}MB`
  } else if (size < 1099511627776) {
    return `${fromByteToGb(size)}GB`
  } else {
    return `${fromByteToTb(size)}TB`
  }
}
