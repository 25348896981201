const STORAGE_TYPES = {
  ghost: 'ghost_cloud',
  ipfs: 'ipfs',
  ghostIpfs: 'node',
  dropbox: 'dropbox',
  googleDrive: 'google',
  oneDrive: 'onedrive',
}

export default STORAGE_TYPES
