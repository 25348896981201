// Refactoring №3
import React from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { Portal } from 'components/Portal';
import Button from 'components/Button';
import CloseIcon from 'components/svg/close';

import styles from './style.module.scss';

export const ChangeAddressWalletModal = ({
  isOpen,
  onClose,
  addressList,
  onRetry,
}) => {
  const { t } = useTranslation('account');
  return (
    <Portal id={'no-attached-address'}>
      <Modal
        isOpen={isOpen}
        overlayClassName={styles.overlay}
        className={styles.walletModal}
        contentLabel="Example Modal"
        shouldCloseOnOverlayClick
        onRequestClose={onClose}
      >
        <div className={styles.modal__wrapper}>
          <button
            type="button"
            className={styles.walletModal__exit}
            onClick={onClose}
          >
            <CloseIcon color="#4B576C" />
          </button>
          <div className={styles.modal__changeWalletAddressContent}>
            <h3 className={styles.modal__title}>
              {t('emailWarningModal.addEmailWarning')}
            </h3>
            <p className={styles.modal__changeWalletAddressContentl__text}>
              {t('emailWarningModal.needToAddEmail')}
            </p>
            <div
              className={
                styles.modal__changeWalletAddressContent__smartContractsAddresses
              }
            >
              <span
                className={
                  styles.modal__changeWalletAddressContent__smartContractsAddress
                }
              >{`BSC: ${window.metamask?.selectedAddress}`}</span>
            </div>
            <p className={styles.modal__changeWalletAddressContentl__text}>
              {t('emailWarningModal.notAttached')}
            </p>
            <p className={styles.modal__changeWalletAddressContentl__text}>
              {t('emailWarningModal.switchToAddress')}
            </p>
            <div
              className={
                styles.modal__changeWalletAddressContent__smartContractsAddresses
              }
            >
              {addressList?.length &&
                addressList.map((address, index) => (
                  <span
                    key={index}
                    className={
                      styles.modal__changeWalletAddressContent__smartContractsAddress
                    }
                  >
                    {`BSC: ${address.public_address}`}
                  </span>
                ))}
            </div>
            <div className={styles.modal__buttons}>
              <Button onClick={onRetry}>{t('emailWarningModal.retry')}</Button>
            </div>
          </div>
        </div>
      </Modal>
    </Portal>
  );
};
