//Refactoring №5
import React, { useState, useRef, useCallback } from 'react';
import TagsInput from 'react-tagsinput';
import AutosizeInput from 'react-input-autosize';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';

import { ReactComponent as CloseIcon } from 'static/assets/svg/close.svg';

import styles from './style.module.scss';

type IProps = {
  tags: string[],
  setTags: (tags: string[]) => void
}

const Tags = ({ tags, setTags }: IProps) => {
  const { t } = useTranslation('contextMenu');
  const wrapperFef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [nextTag, setNextTag] = useState('');

  const onChangeHandler = useCallback((newTags: string[]) => {
    const updatedTags = newTags.map((name) => {
      const result = tags.find((tag) => tag === name);
      return result ? result : name;
    });

    setTimeout(() => {
      wrapperFef.current?.scrollTo(0, wrapperFef.current?.scrollHeight);
    }, 300);

    setTags(updatedTags);
  }, [tags, wrapperFef]);

  const onChangeInputHandler = useCallback((newTag: string) => {
    setNextTag(newTag);
  }, []);

  const renderInput = ({ onChange, value, ...other }: any) => {
    return (
      <AutosizeInput type="text" onChange={onChange} value={value} {...other} />
    );
  };

  const renderTag = ({ key, tag, getTagDisplayValue, onRemove, ...other }: any) => {
    return (
      <span {...other} className={styles.tagItem} key={key}>
        {`#${getTagDisplayValue(tag)}`}
        <button
          type="button"
          className={styles.tagItemDelete}
          onClick={() => onRemove(key)}
        >
          <CloseIcon />
        </button>
      </span>
    );
  };

  const renderLayout = (tagElements: any, inputElement: any) => {
    return (
      <span className={styles.tagLayout}>
        {tagElements}
        {inputElement}
      </span>
    );
  };

  return (
    <div ref={wrapperFef} className={styles.wrapper}>
      <TagsInput
        onlyUnique
        value={tags}
        renderTag={renderTag}
        renderInput={renderInput}
        renderLayout={renderLayout}
        inputValue={nextTag}
        onChangeInput={onChangeInputHandler}
        onChange={onChangeHandler}
        className={styles.inputWrapper}
        placeholder=""
        inputProps={{
          ref: inputRef,
          placeholder: `+ ${t('main.addPassword.addTag')}`,
          autoComplete: 'off',
          className: CN(
            styles.activeInput,
            tags.length && styles.activeInputTagExist
          ),
        }}
      />
    </div>
  );
};

export default Tags;
