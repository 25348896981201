import React from 'react';

import HistoryItem from './history-item';

const HistoryBlock = ({
  historyItems,
  name,
  color,
  entityType,
  entityName,
}) => {
  return (
    <div className="entity-settings__history-block">
      <div className="entity-settings__history-block-date">{name}</div>
      <div className="entity-settings__history-block-content">
        {historyItems.map((item) => (
          <HistoryItem
            historyItem={item}
            color={color}
            key={`history-item-${item.id}`}
            entityType={entityType}
            entityName={entityName}
          />
        ))}
      </div>
    </div>
  );
};

HistoryBlock.defaultProps = {
  historyItems: [],
  name: '',
};

export default HistoryBlock;
