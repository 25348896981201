function createAndDownload(filename, data) {
  const blob = new Blob([data], { type: 'text/html' })
  const elem = window.document.createElement('a')
  elem.href = window.URL.createObjectURL(blob)
  elem.download = filename
  document.body.appendChild(elem)
  elem.click()
  document.body.removeChild(elem)
}

function loadFileAsText(event) {
  const fileToLoad = event.target.files[0]

  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = resolve
    fileReader.onerror = reject
    fileReader.readAsText(fileToLoad, 'UTF-8')
  })
}

export { createAndDownload, loadFileAsText }
