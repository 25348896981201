/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useRef, useState, useEffect } from "react";
import CN from 'classnames';

import { enterKeyPress } from 'utils/actions/enter-key-press';

import styles from './styles.module.scss';

const SidebarInput = ({
  isCurrentStep,
  editStep,
  icon,
  name,
  placeholder,
  setValues,
  valueForm,
  isEditMode,
  onEnterPress,
  disabled,
  onFieledFocus,
  values,
  type,
}) => {
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef(null);
  const isImageActive = !isEditMode && name === 'image' && values.description

  useEffect(() => {
    if (isCurrentStep && name !== 'image') {
      setIsActive(true);
      inputRef.current.focus();
    }
  }, [isCurrentStep, inputRef])

  const doActive = () => {
    if (name !== 'image') {
      setIsActive(true)
      inputRef.current.focus()
    }
  }

  const onBlur = () => {
    setIsActive(false)
  }

  const onKeyDown = (e) => {
    if (enterKeyPress(e)) {
      e.target.blur()
      onEnterPress(name)
      setIsActive(false)
    }  
  }

  const onFocus = () => {
    setIsActive(true)
    onFieledFocus(editStep, name)
  } 

  return (
    <div
      className={CN(
        styles.inputSidebar,
        ((!isActive && valueForm) || isImageActive) && styles.inputSidebarFinish,
        isCurrentStep && styles.inputSidebarCurrent,
        isActive && styles.inputSidebarActivet
      )}
      onClick={(isCurrentStep || isEditMode) ? doActive : undefined }
    >
      <div className={styles.inputSidebarIcon}>
        { icon }
      </div>
      <div className={CN(styles.inputSidebarInput, isActive && styles.inputSidebarInputActive)}>
        <input
          className={styles.inputSidebarMyInput}
          ref={inputRef}
          type={type || "text"}
          onFocus={onFocus}
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={({ target}) => {setValues((data) => ({ ...data, [name]: target.value}))}}
          value={valueForm}
          onKeyDown={onKeyDown}
          maxLength={name === 'description' ? 64 : undefined}
          disabled={disabled}
        />
        {name === 'description' && isActive && isCurrentStep && <span className={styles.skills}>{`${valueForm.length}/64`}</span>}
      </div>
    </div>
  )
}

export default SidebarInput;