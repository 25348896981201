//Refactoring №5
import React, { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';

import { ReactComponent as PasswordIcon } from 'components/VaultModal/assets/password-field.svg';
import { ReactComponent as TextIcon } from 'components/VaultModal/assets/text-field.svg';
import { ReactComponent as LinkIcon } from 'components/VaultModal/assets/link-field.svg';
import { ReactComponent as CalendarIcon } from 'components/VaultModal/assets/calendar.svg';
import { ReactComponent as NotesIcon } from 'components/VaultModal/assets/notes.svg';

import useClickOutside from 'utils/hooks/use-click-outside';

import styles from './style.module.scss';

type IProps = {
  onAddField: (field: IField) => void
}

type IField = {
  name: string,
  icon?: JSX.Element,
  type: string,
  value?: string, 
}

export const fields = [
  {
    name: 'Date',
    icon: <CalendarIcon />,
    type: 'date',
  },
  {
    name: 'Password',
    icon: <PasswordIcon />,
    type: 'password',
  },
  {
    name: 'Text Field',
    icon: <TextIcon />,
    type: 'text',
  },
  {
    name: 'Notes',
    icon: <NotesIcon />,
    type: 'notes',
  },
  {
    name: 'Website',
    icon: <LinkIcon />,
    type: 'website',
  },
];

const VaultTitle = ({ onAddField }: IProps) => {
  const { t } = useTranslation('contextMenu');
  const popupRef = useRef(null);
  const [isOpen, setIsOpne] = useState(false);

  const handleClosePopup = useCallback(() => {
    setIsOpne(false);
  }, []);

  const handleOpenPoup = useCallback(() => {
    setIsOpne(true);
  }, []);

  const handleAddField = useCallback((field: IField) => {
    onAddField(field);
    handleClosePopup();
  }, []);

  useClickOutside(popupRef, handleClosePopup);

  return (
    <div className={styles.wrapper}>
      <button type="button" onClick={handleOpenPoup} className={styles.action}>
        {`+ ${t('main.addPassword.addField')}`}
      </button>
      <div
        ref={popupRef}
        className={CN(styles.popup, isOpen && styles.popupOpen)}
      >
        {fields.map(({ name, icon, type }, index) => (
          <div
            onClick={() => {
              handleAddField({ type, name });
            }}
            key={index}
            className={styles.popupItem}
          >
            {icon}
            <span>{t(`main.addPassword.${name}`)}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VaultTitle;
