import authRequest from 'utils/request/auth-request';
import { API_FILE } from 'constants/api-urls';

export default async (slug, body) => {
  const url = `${API_FILE}/download/${slug}`;

  return authRequest
    .put(url, body)
    .then((response) => response.data)
    .catch((response) => {
      throw response;
    });
};
