import React from 'react'
import typeColors from 'config/file-type-colors'

const History = ({ history, name, size, trialDays, type }) => {
  const color = Object.prototype.hasOwnProperty.call(typeColors, type)
    ? typeColors[type]
    : typeColors.default

  const showCurrent = () => {}

  const historyItems =
    history &&
    history.map((item) => (
      <div className="file-history__list-item" key={`history-item-${item.id}`}>
        <div className="file-history__list-item-user">
          {item.user}
          <div className="file-history__list-item-date">
            Modified: {item.date}
          </div>
          <div className="file-history__list-item-action">{item.action}</div>
        </div>
        <button
          className="button button--primary button--primary_outline text--medium text--12 text--uppercase file-history__list-item-button"
          type="button"
          onClick={showCurrent}
        >
          Current Version
        </button>
      </div>
    ))

  return (
    <div className="right-menu__container file-history">
      <section className="file-history__info">
        <div className="file-icon">
          <svg
            className="icon__img_file"
            width="36px"
            height="48px"
            viewBox="0 0 80 102"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs />
            <g
              id="icons/filled/file-80x102"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g>
                <path
                  d="M4.5,0.5 L4.5,101.5 L79.5,101.5 L79.5,17.2071068 L62.7928932,0.5 L4.5,0.5 Z"
                  id="Rectangle-6"
                  stroke={color.stroke}
                  fill={color.background}
                />
                <g
                  id="elements/file-type/pdf"
                  transform="translate(0.000000, 42.000000)"
                >
                  <polygon
                    id="Rectangle"
                    fill={color.label}
                    points="0 0 54 0 54 32 0 32"
                  />
                  <text className="icon__img--text" fill="#FFFFFF">
                    <tspan x="7" y="21">
                      {type.split('/').pop()}
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <div>
          <div className="file-history__info-name">{name}</div>
          <div className="file-history__info-size">{size}</div>
        </div>
      </section>

      <div className="file-history__list">{historyItems}</div>

      <div className="file-history__trial">
        End of {trialDays} day version history
      </div>
    </div>
  )
}

export default History
