import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import s from './style.module.scss';

export const Panorama360 = ({
  imageUrl,
  shouldRotate = false,
}: {
  imageUrl: string;
  shouldRotate?: boolean;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const camera = useRef<THREE.PerspectiveCamera>();
  const renderer = useRef<THREE.WebGLRenderer>();
  const controls = useRef<OrbitControls>();
  const texture = useRef();

  useEffect(() => {
    const scene = new THREE.Scene();
    camera.current = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    renderer.current = new THREE.WebGLRenderer();
    renderer.current.setSize(
      containerRef.current.clientWidth,
      containerRef.current.clientHeight
    );
    containerRef.current.appendChild(renderer.current.domElement);
    const geometry = new THREE.SphereGeometry(500, 60, 40);
    texture.current = new THREE.TextureLoader().load(imageUrl);
    texture.current.mapping = THREE.EquirectangularReflectionMapping;

    const material = new THREE.MeshBasicMaterial({
      map: texture.current,
      side: THREE.DoubleSide,
    });
    const sphere = new THREE.Mesh(geometry, material);
    scene.add(sphere);
    camera.current.position.set(0, 0, 0.1);
    controls.current = new OrbitControls(
      camera.current,
      renderer.current.domElement
    );
    controls.current.enableZoom = true;
    controls.current.enablePan = true;
    controls.current.enableDamping = true;
    controls.current.dampingFactor = 0.2;
    controls.current.rotateSpeed = 0.5;

    const animate = () => {
      requestAnimationFrame(animate);
      if (shouldRotate) sphere.rotation.y += 0.0005;
      renderer.current.render(scene, camera.current);
      controls.current.update();
    };
    animate();

    const handleResize = () => {
      camera.current.aspect = window.innerWidth / window.innerHeight;
      camera.current.updateProjectionMatrix();
      renderer.current.setSize(window.innerWidth, window.innerHeight);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [imageUrl]);

  return <div className={s.container} ref={containerRef} />;
};
