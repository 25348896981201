/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import CN from 'classnames';

import { ReactComponent as PlayerIcon } from './assets/player.svg';
import { ReactComponent as StopPlayIcon } from './assets/stopPlay.svg';

import styles from './style.module.scss';

const formWaveSurferOptions = (ref) => ({
  container: ref,
  waveColor: '#D9D9D9',
  progressColor: '#0F73EF',
  cursorColor: 'transparent',
  barWidth: 3,
  barRadius: 1,
  responsive: true,
  height: 25,
  normalize: true,
  partialRender: true,
  barGap: 3,
});

export default function WafeSurfer({
  url,
  volume,
  className = '',
  nextParams = {},
}) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const load = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState('');
  const [fullTime, setFullTime] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    create();
    load.current = 'loaded';

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
    };
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemainder = Math.round(seconds) % 60;
    const paddedSeconds = `0${secondsRemainder}`.slice(-2);
    return `${minutes}:${paddedSeconds}`;
  };

  const create = () => {
    const options = {
      ...formWaveSurferOptions(waveformRef.current),
      ...nextParams,
    };

    wavesurfer.current = WaveSurfer.create(options);
    wavesurfer.current.on('decode', (duration) =>
      setDuration(formatTime(duration))
    );
    wavesurfer.current.on('timeupdate', (currentTime) =>
      setFullTime(formatTime(currentTime))
    );
    wavesurfer.current.load(url);
    wavesurfer.current.on('ready', function () {
      setLoading(false);
    });
    wavesurfer.current.on('finish', function () {
      setPlaying(false);
    });
  };

  useEffect(() => {
    if (wavesurfer && wavesurfer.current) {
      wavesurfer.current.media.volume = 1;
    }
  }, [volume]);

  const handlePlayPause = () => {
    setPlaying(!playing);
    wavesurfer.current.playPause();
  };

  return (
    <div className={CN(styles.voicePlayer, className)}>
      <div className={styles.voice}>
        <button className={styles.voiceBtn} onClick={handlePlayPause}>
          {playing ? <StopPlayIcon /> : <PlayerIcon />}
        </button>
        <div className={styles.voiceBar} ref={waveformRef} />
      </div>
      <p className={styles.voiceDuration}>
        {' '}
        {playing || fullTime !== '' ? fullTime : duration}
      </p>
    </div>
  );
}
