//Refactoring №3
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowBackIcon } from './assets/arrow-back.svg';

import cn from 'classnames';
import s from './style.module.scss';

export default function AppNavLink({ className, to }) {
  const { t } = useTranslation('common');
  return (
    <Link
      className={cn(s.wrapper, className)}
      to={to}
      title={t('common.goBack')}
    >
      <ArrowBackIcon className={s.icon} />
    </Link>
  );
}
