import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Logo } from 'static/assets/svg/gd-logo-text.svg'
import InviteForm from './InviteForm'
import InviteSent from './InviteSent'
import Preloader from 'components/shared/Preloader'
import {
  requestInvite,
  selectInviteRequestState,
} from 'features/getInvite/getInviteSlice'

import s from './style.module.css'

export default function GetInvite() {
  const dispatch = useDispatch()
  const { showForm, processing, error, name } = useSelector(
    selectInviteRequestState
  )

  const handleFormSubmit = useCallback(
    (values) => {
      dispatch(requestInvite(values))
    },
    [dispatch]
  )

  if (processing) return <Preloader />

  return (
    <div className={s.wrapper}>
      <Logo className={s.logo} />
      {showForm ? (
        <InviteForm className={s.inviteForm} onSubmit={handleFormSubmit} />
      ) : (
        <InviteSent className={s.inviteSent} name={name} error={error} />
      )}
    </div>
  )
}
