import authRequest from 'utils/request/auth-request';
import { API_FILE } from 'constants/api-urls';
import { CidLevelType } from 'gdgateway-client/lib/es5/types';

type GetFileCidsParams = {
  slug: string;
  level: CidLevelType;
};

export const getFileCids = async ({ slug, level }: GetFileCidsParams) => {
  const response: any = await authRequest.get(
    `${API_FILE}/cid/${slug}/${level}`
  );
  return response.data;
};
