/* eslint-disable no-undef */
import { getFromCookies } from 'utils/cookies';

const getRefreshToken = () => getFromCookies('refresh_token');

export { getRefreshToken };

export default function () {
  const token = getFromCookies('access_token');
  return token ? `Bearer ${token}` : false;
}

export const getAccessToken = async () => {
  if (typeof getTokens === 'function') {
    const tokens = await getTokens();
    const accessToken = tokens?.accessToken;

    if (accessToken) return `Bearer ${accessToken}`;
  }
  return false;
};
