import { OPTION_HEIGHT, width } from 'config/menu';

const calculatePositionByElement = (event, optionCount) => {
  // const pageY = event.screenY - 100;
  const pageY = event.pageY;
  let top = `${pageY}px`;
  let left = `${event.pageX}px`;
  let bottom = 'auto';
  let right = 'auto';
  const menuHeight = optionCount * OPTION_HEIGHT;
  if (pageY + menuHeight > window.innerHeight) {
    if (pageY > menuHeight) {
      bottom = `${
        window.innerHeight -
        pageY -
        (menuHeight > event.clientY ? menuHeight : 0)
      }px`;
      top = 'auto';
    } else {
      top = '0';
      bottom = 'auto';
    }
  }

  if (event.pageX + width > window.innerWidth) {
    right = `${window.innerWidth - event.pageX}px`;
    left = 'auto';
  }

  return { top, left, bottom, right };
};

export default calculatePositionByElement;
