/* eslint-disable no-unused-vars */
import React from "react";

import { ReactComponent as LogoIcon } from 'containers/mobile/assets/logo.svg';
// import { ReactComponent as SettingsIcon } from 'containers/mobile/assets/settings.svg'

import styles from './styles.module.scss';

const Header = ({ onLogoClick, onMenuClick }) => {

  return (
    <header className={styles.header}>
      <button onClick={onLogoClick} className={styles.headerBtn}>
        <LogoIcon />
      </button>

    </header>

  )
}

export default Header;