import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router";

import { onCloseAllModal } from 'features/modals/modal-slice';
import { IRootState } from 'store/root-store';

import CreateRenameModal from 'containers/main/NeyraDriveContainer/components/ContentDrive/CreateRename';
import CreateDocumentModal from 'containers/main/NeyraDriveContainer/components/ContentDrive/CreateDocument';
import EncryptFileModal from 'containers/main/NeyraDriveContainer/components/ContentDrive/Encrypt';
import ConvertModal from 'containers/main/NeyraDriveContainer/components/ContentDrive/Convert';
import MoveModal from 'containers/main/NeyraDriveContainer/components/ContentDrive/Move';
import PreviewModal from 'containers/main/NeyraDriveContainer/components/ContentDrive/Preview';

import { isEmpty } from 'lodash';

type IProps = {
  children: React.ReactElement,
}

const Content = ({ children }: IProps) => {
  const modalState = useSelector((store: IRootState): any => store.modals) || {};
  const isEncryptOpen = useSelector((store: IRootState): any => store.home.encrypt.openModal);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const closeList: string[] = []; 
    Object.keys(modalState).forEach((key: any) => {
      if (modalState[key] === true)
        closeList.push(key)
    })
    if (!isEmpty(closeList)){
      dispatch(onCloseAllModal());
    }
  }, [history.location])

  if (modalState.createFolderOpen) 
    return <CreateRenameModal entity={modalState.additionalData} />
  
  if (modalState.createDocumentOpen)
    return <CreateDocumentModal entity={modalState.additionalData} />

  if (isEncryptOpen) 
    return <EncryptFileModal />

  if (modalState.convertModal)
    return <ConvertModal entity={modalState.additionalData} />

  if (modalState.moveModal)
    return <MoveModal entity={modalState.additionalData} />

  if (modalState.previewModal)
    return <PreviewModal entity={modalState.additionalData} />

  return children
}

export default Content;