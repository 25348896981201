import React, { useEffect, useMemo, useState }  from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useHistory } from 'react-router';
import { connect, useDispatch } from 'react-redux';

import {
  getFilesSuccess,
  getFilesClear,
} from 'store/home/actions/files/get-files.actions';
import { getFilteredFilesEffect } from 'store/home/effects';
import { selectedFilesFoldersClear } from 'store/home/actions/selected-files-folders.actions';
import { getDownloadOTT } from 'store/home/effects/files-upload/upload-file.effect';
import { setLink } from 'features/neyra'

import { ReactComponent as LinkIcon } from '../../assets/link.svg';
import { ReactComponent as ArrowBack } from 'components/MemosSidebar/assets/arrow-back.svg';
import SidebarItem from './sidebarItem';
import NeyraTooltip from 'components/shared/NeyraTooltip/NeyraTooltip';

import styles from './styles.module.scss';

const Sidebar = ({ 
  files,
  filesCount,
  sorter,
  filter,
  getFilteredFilesEffect,
  getFilesSuccess,
  getFilesClear,
  selectedFilesFoldersClear,
 }) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (files.length) {
      getDownloadOTT(files.map(({ slug }) => ({ slug })))
      .then((data) => {
        setToken({ ...data?.data })
      })
    }
  }, [files])

  useEffect(() => {
    getFilesClear();
    setIsLoading(true);
    setPage(1);
    getFilteredFilesEffect('link', {
      page: 1,
      order_by: sorter.option,
      order: sorter.dir,
      color: filter.color,
    })
      .then((data) => {
        getFilesSuccess({ data }, true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err)
        setIsLoading(false)
      });
    return () => {
      getFilesClear();
      selectedFilesFoldersClear();
    };
  }, [sorter, filter]);

  const getNextPage = () => {
    if (page * 15 < filesCount) {
      const nextPage = page + 1;
      setIsLoading(true);
      setPage(nextPage);
      getFilteredFilesEffect('link', {
        page: nextPage,
        order_by: sorter.option,
        order: sorter.dir,
        color: filter.color,
      })
        .then((data) => {
          getFilesSuccess({ data });
          setIsLoading(false);
        })
        .catch((err) => console.error(err));
    }
  };

  const hasMore = () => files.length < filesCount && !isLoading;

  const handleGoBack = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/chat');
  };

  const sortedFiles = useMemo(() => {
    return files.sort((a, b) => b.created_at - a.created_at);
  }, [files]);

  const onCreateNewLin = () => {
    dispatch(setLink({}))
  }

  return (
    <div className={styles.sidebar}>
      <div
        className={styles.mainItem}
        onClick={onCreateNewLin}
      >
        <div className={styles.preview}>
          <div className={styles.logo}>
            <LinkIcon />
          </div>
          <span>Links</span>
        </div>
        <NeyraTooltip text='Back' position="bottom">
          <button className={styles.back} onClick={handleGoBack} >
            <ArrowBack />
          </button>
        </NeyraTooltip>
      </div>
      {sortedFiles.length ? (
        <InfiniteScroll
          className={styles.list}
          initialLoad
          pageStart={2}
          threshold={250}
          loadMore={getNextPage}
          hasMore={hasMore()}
          useWindow={false}
          getScrollParent={() =>
            document.getElementsByClassName('entry-list ')[0]
          }
        >
          {sortedFiles.map((item, index) => (
            <SidebarItem
              token={token}
              name={item.name}
              key={`sidebar-${index}`}
              slug={item.slug}
              file={item}
            />
          ))}
        </InfiniteScroll>
      ) : null}
    </div>
  )
};

const mapDispatchToProps = {
  getFilteredFilesEffect,
  getFilesSuccess,
  getFilesClear,
  selectedFilesFoldersClear,
};

const mapStateToProps = (state) => ({
  files: state.home?.getFiles?.entities,
  filesCount: state.home?.getFiles?.count,
  sorter: state.app.sorter,
  filter: state.app.filter,
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
