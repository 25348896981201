import { API_FOLDERS } from 'constants/api-urls';
import authRequest from 'utils/request/auth-request';
import _values from 'lodash/values';

const formatFolderDataForTree = (folders) =>
  _values(folders)?.map((item) => ({
    ...item,
    title: item.name,
    children: item.children ? formatFolderDataForTree(item.children) : [],
  }));

export const getFolderTreeEffect = async (items) =>
  authRequest
    .get(`${API_FOLDERS}/tree?slug[]=${items.join('&slug[]=')}`)
    .then((result) => {
      const {
        data: { data },
      } = result;
      return formatFolderDataForTree(data);
    })
    .catch((e) => {
      throw e;
    });
