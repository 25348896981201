// Refactoring №3
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as LogoIcon } from '../assets/logo_light.svg';
import { ReactComponent as FileIcon } from '../assets/file.svg';
import { ReactComponent as FolderIcon } from '../assets/folder.svg';
import { MOBILE_LINKING } from 'constants/api-urls';
import { isiOSPlatform } from 'utils/browser';

import s from './style.module.scss';

type MobileModalProps = {
  closeMobileModal: () => void;
  name: string;
  entityType: string;
  slug: string;
};

export const SharingEntityMobileModal = ({
  closeMobileModal,
  name,
  entityType,
  slug,
}: MobileModalProps) => {
  const { t } = useTranslation('popup');
  const isiOS = isiOSPlatform();

  const getApp = () => {
    setTimeout(function () {
      const link = isiOS ? (
        "https://apps.apple.com/ua/app/ghostdrive-app/id6475002179"
       ) : (
        "https://play.google.com/console/u/0/developers/8330585040968110299/app/4972267943153635753/publishing"
       )  
      window.location.href = link;
    }, 25);
    window.location.href = `${MOBILE_LINKING}file/${slug}`;
  };

  useEffect(() => {
    if (entityType === 'folder') {
      const authContainer = document.getElementsByClassName(
        'main-container'
      )[0] as HTMLElement;
      const nonAuthContainer = document.getElementsByClassName(
        'non-auth'
      )[0] as HTMLElement;
      const splitPane = document.getElementsByClassName(
        'SplitPane'
      )[0] as HTMLElement;

      if (authContainer) authContainer.style.paddingBottom = '0px';
      if (nonAuthContainer) nonAuthContainer.style.minHeight = '0px';
      if (splitPane) splitPane.style.height = '100%';

      return () => {
        if (authContainer) authContainer.style.paddingBottom = '25px';
        if (splitPane) splitPane.style.height = '';
        if (nonAuthContainer) nonAuthContainer.style.minHeight = '';
      };
    }
  }, []);

  return (
    <div className={s.container}>
      <div className={s.contentWrapper}>
        <LogoIcon />
        <h1 className={s.container__title}>
          {t('sharingEntity.upgradeExperience')}
        </h1>
        <p className={s.container__subtitle}>{t('sharingEntity.withApp')}</p>
        {entityType === 'file' ? <FileIcon /> : <FolderIcon />}
        <p className={s.container__entityName}>{name}</p>
      </div>
      <div className={s.container__buttons}>
        <button onClick={getApp}>{t('sharingEntity.getApp')}</button>
        <button onClick={closeMobileModal}>
          {t('sharingEntity.continueWithWebsite')}
        </button>
      </div>
    </div>
  );
};
