import * as actions from '../actions';

const initialState = {
  isWeb3FeatureRun: false,
  callback: () => null,
  forceChainId: null,
};

type InitState = typeof initialState;

interface Action<TType, TPayload> {
  type: TType;
  payload: TPayload;
}

const reducer = (
  state: InitState = initialState,
  action: Action<string, InitState>
) => {
  switch (action.type) {
    // eslint-disable-next-line import/namespace
    case actions.RUN_CHECK:
      return {
        ...state,
        isWeb3FeatureRun: action.payload.isWeb3FeatureRun,
        callback: action.payload.callback,
        forceChainId: action.payload.forceChainId,
      };
    case actions.ADD_CALLBACK:
      return {
        ...state,
        callback: action.payload.callback,
      };
    // eslint-disable-next-line import/namespace
    case actions.STOP_CHECK:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
