export const TOGGLE_SECURITY_FILE = 'TOGGLE_SECURITY_FILE'
export const TOGGLE_SECURITY_FILE_SUCCESS = 'TOGGLE_SECURITY_FILE_SUCCESS'
export const TOGGLE_SECURITY_FILE_FAILURE = 'TOGGLE_SECURITY_FILE_FAILURE'

export function toggleSecurityFile() {
  return {
    type: TOGGLE_SECURITY_FILE,
  }
}

export function toggleSecurityFileSuccess() {
  return {
    type: TOGGLE_SECURITY_FILE_SUCCESS,
  }
}

export function toggleSecurityFileFailure() {
  return {
    type: TOGGLE_SECURITY_FILE_FAILURE,
  }
}
