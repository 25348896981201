import React from "react";

import SidebarItem from 'containers/main/NeyraDriveContainer/components/SidebarItem';
import { ReactComponent as SpaceIcon } from 'containers/account/Space/assets/space.svg';

import SidebarInput from './SidebarInput';
import SidebarButton from './SidebatButton';
import PublishInfo from './PublishInfo';

import styles from '../styles.module.scss';

import { MODES } from 'containers/account/Space/SpaceSidebar';
import { isEmpty } from "lodash";
import { enterKeyPress } from 'utils/actions/enter-key-press';

const EditContent = ({
  generate3Dbackground,
  onCeateUpdate,
  avatarArray,
  selectedSpace,
  setPublishValues,
  INITIAL_PUBLISH_VALUES,
  panoramLoading,
  publishValues,
  onChnage,
  isActivated,
  isCreated,
  isDisabled,
  onPublish,
  setMode,
}) => {

  const handlePrompthKeyPress = (e) => {
    if (enterKeyPress(e) && publishValues['3Dbg']) {
      generate3Dbackground(publishValues['3Dbg'])
    }
  }

  const generate3DbackgroundHandler = () => {
    if (publishValues['3Dbg']) {
      generate3Dbackground(publishValues['3Dbg'])
    }
  }

  return (
    <div className={styles.editContent}>
      <div className={styles.editSidebar}>
        <SidebarItem item={{
          icon: <SpaceIcon />,
          isMain: true,
          name: 'Profile',
          action: () => { },
          backButton: !isEmpty(avatarArray),
          backOnClick: () => {
            if (selectedSpace) {
              setPublishValues(INITIAL_PUBLISH_VALUES);
              setMode(MODES.menu)
            } else {
              setMode(MODES.main)
            }
          }
        }}
        />
        <SidebarInput
          value={publishValues.name}
          name="name"
          onChnage={onChnage}
          maxLength={64}
          placeholder="Name"
        />
        <SidebarInput
          value={publishValues.mission}
          name="mission"
          onChnage={onChnage}
          maxLength={256}
          placeholder="Mission"
        />
        <SidebarInput
          value={publishValues['3Dbg']}
          name="3Dbg"
          onChnage={onChnage}
          maxLength={64}
          placeholder="360° Background (prompt)"
          onClick={generate3DbackgroundHandler}
          onKeyPress={handlePrompthKeyPress}
          loading={panoramLoading}
        />
        <SidebarButton
          className={styles.create}
          onClick={onCeateUpdate}
          disabled={isDisabled}
        >
          {isCreated ? (<span>Update</span>) : (<span>Create</span>)}
        </SidebarButton>
      </div>
      <PublishInfo
        onUpdateAvatar={onPublish}
        publishValues={publishValues}
        onChnage={onChnage}
        isActivated={isActivated}
      />
  </div>
  )
}

export default EditContent;