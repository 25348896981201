import React, { useRef } from 'react'

const ManageLink = ({ link, emailFrom }) => {
  const sendToInputRef = useRef(null)

  const copyLink = () => {
    navigator.clipboard.writeText(link)
  }

  const sendEmail = () => {
    // console.warn(sendToInputRef.current.value)
  }

  return (
    <div className="right-menu__container manage-link">
      <label className="right-menu__label">
        Link
        <input
          type="text"
          name="link"
          className="right-menu__input"
          value={link}
          readOnly
        />
      </label>
      <div className="manage-link__action-links">
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="link"
          type="button"
        >
          OPEN LINK
        </a>
        <span className="splitter">|</span>
        <button className="link" type="button" onClick={copyLink}>
          COPY LINK
        </button>
      </div>
      <button
        className="button button--primary text--medium text--12 text--uppercase"
        type="button"
      >
        CHOOSE FILE
      </button>

      <label className="right-menu__label">
        Send this link to
        <input
          type="text"
          name="email-to"
          className="right-menu__input"
          ref={sendToInputRef}
          placeholder="Enter email address"
        />
      </label>
      <label className="right-menu__label">
        Display name
        <input
          type="text"
          name="email-from"
          className="right-menu__input"
          defaultValue={emailFrom}
          placeholder="Your email"
        />
      </label>
      <button
        className="button button--primary button--primary_outline text--medium text--12 text--uppercase bottom-button"
        type="button"
        onClick={sendEmail}
      >
        SEND EMAIL
      </button>
    </div>
  )
}

export default ManageLink
