//Refactoring №3
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import { getCollectionContract } from '../../store/web3';
import { getTokenExchangeRate } from '../../store/crypto/effects';
import { getMonetize } from './api';

import { getCurrentChainId } from '../../utils/crypto';

import { FullScreenModal } from './components/FullScreenPreviewModal';
import { MemoBorderedButton as BorderedButton } from './components/BorderedButton';
import { FrontCard } from './components/FrontCard';
import { BackCard } from './components/BackCard';
import { ReportModal } from './components/ReportModal';
import { Switcher } from './components/Switcher';
import { ContactDetailsByURi } from '../../containers/share-by-link-nft';
import { InitialValues } from '../../containers/main/EntitySettingsContainer/components/Web3Tab/components/Tokenization/components/Monetization';
import Preloader from '../shared/Preloader';
import { ReactComponent as RotateIcon } from 'static/assets/svg/rotate.svg';

import s from './styles.module.scss';

export const TEXT = {
  title: 'WHALES Doc',
  description: 'Just one more step before it’s yours!',
  priceUsd: '$0.2',
  priceCrypto: ' ≈0.002BNB',
  save: 'Save',
  report: 'Report',
};

export const THEME = {
  white: {
    title: {
      color: '#1A1A1A',
    },
    description: { color: '#4B576C' },
  },
};

interface PreviewMonetizeModalProps {
  isPreviewMode: boolean;
  contactInfo?: ContactDetailsByURi;
  selectedPublicAddress?: string;
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
  fileName: string;
  formData?: InitialValues;
  isCorrectNetwork: boolean;
  currentNetwork?: number;
  showOnClose?: boolean;
}

export type PreviewParams = {
  contract_address: string;
  networkId: string;
  slug: string;
  workspaceId: string;
};

type GetTokenExchangeRateMap = {
  '1': string;
  '56': string;
  '97': string;
  '137': string;
  '420': string;
  '31337': string;
  '80001': string;
};

type GetTokenExchangeRate = {
  btc: number;
  eth: number;
  matic: number;
  bnb: number;
  eth_decimals: number;
  matic_decimals: number;
  bnb_decimals: number;
  map: GetTokenExchangeRateMap;
};

export type Monetize = {
  id: number;
  group: { id: number; is_tokenized: boolean };
  price: number;
  description: string;
  image: string;
};

export type ConvertedPrice = {
  value: string;
  name: string;
} | null;

const PreviewMonetizeModalNoMemo: FC<PreviewMonetizeModalProps> = ({
  isOpen,
  isPreviewMode = true,
  contactInfo,
  selectedPublicAddress,
  setIsOpen,
  fileName,
  formData,
  isCorrectNetwork,
  currentNetwork,
  showOnClose,
}) => {
  const { t } = useTranslation('common');
  const params = useParams<PreviewParams>();
  const [isDarkMode, setIsDarkMode] = useState<boolean>(true);
  const [isFront, setIsFront] = useState<boolean>(true);
  const [isReportOpen, setIsReportOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [convertedPrice, setConvertedPrice] = useState<ConvertedPrice>(null);
  const [monetize, setMonetize] = useState<Monetize | null>(null);

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const checkAccess = async () => {
      setLoading(true);
      const parsedNetworkId = parseInt(params.networkId);
      const currentChainId = currentNetwork;
      if (!isCorrectNetwork || currentChainId !== parsedNetworkId) return;

      const contract = await getCollectionContract(params.contract_address);
      const tokenId = await contract.fileToToken(params.slug);
      const balance = await contract.balanceOf(selectedPublicAddress, tokenId);
      const balanceNum = balance.toNumber();
      if (balanceNum) {
        handleClose();
      } else {
        setIsOpen(true);
      }
      setLoading(false);
    };
    !isPreviewMode && void checkAccess();
  }, [isCorrectNetwork]);

  useEffect(() => {
    const cryptoPrice = async (): Promise<void> => {
      try {
        const range: GetTokenExchangeRate = await getTokenExchangeRate();
        const currentChainId = isPreviewMode
          ? await getCurrentChainId()
          : currentNetwork;
        const cryptoName =
          range.map[
            isPreviewMode
              ? (currentChainId as unknown as keyof GetTokenExchangeRateMap)
              : (params.networkId as keyof GetTokenExchangeRateMap)
          ];
        const cryptoCurrency: number | GetTokenExchangeRateMap =
          range[cryptoName as keyof GetTokenExchangeRate];
        if (isPreviewMode) {
          setConvertedPrice({
            // @ts-ignore
            value: (parseFloat(formData?.price) / cryptoCurrency).toFixed(4),
            name: cryptoName,
          });
          return;
        }
        const {
          data: { data: monetizeData, json },
        } = await getMonetize(
          params.slug,
          params.contract_address,
          contactInfo?.tokenId as string
        );
        // const checkImg = await isImageExist(json.image);
        setMonetize({ ...monetizeData, image: json?.image || '' });
        setConvertedPrice({
          // @ts-ignore
          value: (monetizeData.price / cryptoCurrency).toFixed(4),
          name: cryptoName,
        });
      } catch (e) {
        console.warn({ FrontCardError: e });
      }
    };
    contactInfo?.tokenId && void cryptoPrice();
    return () => {
      setMonetize(null);
      setConvertedPrice(null);
    };
  }, [contactInfo?.tokenId, formData?.price]);

  const handleModeSwitcher = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleOpenReport = () => {
    setIsReportOpen(true);
  };

  const handleViewSide = () => {
    if (contactInfo) {
      setIsFront(!isFront);
    }
  };

  const handleCloseReport = () => {
    setIsReportOpen(false);
  };

  return (
    <>
      {!isPreviewMode && isLoading ? <Preloader /> : null}
      <FullScreenModal
        isOpen={isOpen}
        onClose={isPreviewMode || showOnClose ? handleClose : undefined}
      >
        {isReportOpen ? (
          <ReportModal
            slug={params.slug}
            network={parseInt(params.networkId)}
            token_address={params.contract_address}
            onClose={handleCloseReport}
          />
        ) : (
          <>
            <Switcher {...{ isDarkMode, isPreviewMode, handleModeSwitcher }} />
            {isFront ? (
              <FrontCard
                isPreviewMode={isPreviewMode}
                fileName={fileName}
                info={contactInfo}
                isDarkMode={isDarkMode}
                isUpload={false}
                formData={formData}
                handleClose={handleClose}
                convertedPrice={convertedPrice}
                monetize={monetize}
              />
            ) : (
              <BackCard
                formData={formData}
                info={contactInfo}
                isPreview={isPreviewMode}
                monetize={monetize}
                isDarkMode={isDarkMode}
                networkName={convertedPrice?.name}
              />
            )}
            <RotateIcon onClick={handleViewSide} className={s.rotate} />
            {isPreviewMode ? (
              <div className={s.view_button}>
                <BorderedButton
                  isDarkMode
                  cryptoPrice={undefined}
                  title={t('common.save')}
                  onClick={handleClose}
                />
              </div>
            ) : null}

            {!isPreviewMode ? (
              <ReportButton onClick={handleOpenReport} />
            ) : null}
          </>
        )}
      </FullScreenModal>
    </>
  );
};

const ReportButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation('common');
  return (
    <div onClick={onClick} className={s.report}>
      <p className={s.report_text}>{t('common.report')}</p>
    </div>
  );
};

export const PreviewMonetizeModal = React.memo(PreviewMonetizeModalNoMemo);
