export const WORKSPACE_PLAN_ADD = 'WORKSPACE_PLAN_ADD'
export const WORKSPACE_PLAN_CLEAR = 'WORKSPACE_PLAN_CLEAR'
export const WORKSPACE_ADD_CURRENT = 'WORKSPACE_ADD_CURRENT'

export const workspacePlanAdd = (data) => (
  {
    type: WORKSPACE_PLAN_ADD,
    payload: { data },
  }
)

export const workspacePlanClear = () => (
  {
    type: WORKSPACE_PLAN_CLEAR,
  }
)

export const workspaceAddCurrent = (data) => (
  {
    type: WORKSPACE_ADD_CURRENT,
    payload: { data },
  }
)
