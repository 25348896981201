import getToken from 'utils/auth/get-token';
import authRequest from 'utils/request/auth-request';
import { getDownloadOTT } from '../files-upload/upload-file.effect';
import { downloadFileFromSP } from 'gdgateway-client/lib/es5';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CarReader } from '@ipld/car';

export const previewUpdateDay = 1689920400;

export default async (
  fileId,
  width,
  height,
  fit,
  cancelToken,
  type = undefined
) => {
  const {
    data: {
      jwt_ott,
      user_tokens: { token: oneTimeToken },
      gateway,
    },
  } = await getDownloadOTT([{ slug: fileId }]);
  let url;
  if (type.includes('doc')) {
    url = `${gateway.url}/doc/preview/${fileId}`;
    return authRequest
      .get(url, null, {
        options: {
          responseType: 'blob',
          cancelToken,
          headers: {
            'X-Token': getToken(),
            'One-Time-Token': oneTimeToken,
            'X-Download-OTT-JWT': jwt_ott,
          },
        },
      })
      .then((response) => {
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(response.data);
      })
      .catch((response) => {
        throw response;
      });
  } else {
    url = `${gateway.url}/preview/${fileId}`;

    return authRequest
      .get(url, null, {
        options: {
          responseType: 'blob',
          cancelToken,
        },
        headers: {
          'one-time-token': oneTimeToken,
          'X-Download-OTT-JWT': jwt_ott,
        },
      })
      .then(async (response) => {
        // TO DO: after server change delete
        if (
          response.data.size === 34 &&
          response.data.type === 'application/json'
        )
          throw 'not found';
        const text = await response.data.text();

        if (text.startsWith('data:image')) {
          return text;
        } else {
          const urlCreator = window.URL || window.webkitURL;
          return urlCreator.createObjectURL(response.data);
        }
      })
      .catch((response) => {
        throw response;
      });
  }
};

// don't use the pub request
// export const getPublicISharedImagePreview = async (fileId, cancelToken) => {
//   const {
//     data: { gateway },
//   } = await getDownloadOTT([{ slug: fileId }]);
//   const url = `${gateway.url}/pub/preview/${fileId}`;

//   return axios
//     .get(url, {
//       responseType: 'blob',
//       cancelToken,
//       headers: { 'X-Token': getToken() },
//     })
//     .then((response) => {
//       const urlCreator = window.URL || window.webkitURL;
//       return urlCreator.createObjectURL(response.data);
//     })
//     .catch((response) => {
//       throw response;
//     });
// };

export const getFilePreview = async (file, gatewayData) => {
  if (!file.is_on_storage_provider) {
    return authRequest
      .get(`${gatewayData.url}/preview/${file.slug}`, null, {
        options: {
          responseType: 'blob',
        },
        headers: {
          'one-time-token': gatewayData.token,
          'X-Download-OTT-JWT': gatewayData.jwt_ott,
        },
      })
      .then(async (res) => {
        const text = await res.data.text();
        if (text.startsWith('data:image')) {
          if (file.created_at > previewUpdateDay) {
            return text;
          }
        } else {
          if (file.created_at > previewUpdateDay) {
            const urlCreator = window.URL || window.webkitURL;
            return urlCreator.createObjectURL(res.data);
          }
        }
      });
  } else {
    if (!file.preview_large && !file.preview_small) {
      return null;
    }
    const fileBlob = await downloadFileFromSP({
      carReader: CarReader,
      url: `${file.storage_provider.url}/${
        file.preview_large ?? file.preview_small
      }`,
      isEncrypted: false,
      uploadChunkSize: 0,
      key: undefined,
      iv: undefined,
      file,
      level: 'root',
    });
    const realBlob = new Blob([fileBlob]);
    const text = await realBlob?.text();
    if (text && text.startsWith('data:image')) {
      if (file.created_at > previewUpdateDay) {
        return text;
      }
    } else {
      if (file.created_at > previewUpdateDay) {
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(realBlob);
      }
    }
  }
};
