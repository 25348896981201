//Refactoring №3
import React, { createRef, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { useKeyPress } from 'ahooks';

import { uploadMultiFileEffectRedux } from 'store/home/effects';
import MainContext from 'store/main/context/main-context';

import Button from 'components/Button';
import Corners from './Corners';

import CloseIcon from 'components/svg/close';
import { ReactComponent as Camera } from './assets/camera.svg';
import { ReactComponent as StopIcon } from './assets/stop.svg';
import { ReactComponent as PlayIcon } from './assets/rec.svg';

import s from './style.module.scss';

const AddModal = ({ onClose, isOpen }) => {
  const { t } = useTranslation('contextMenu');
  const recording = createRef();
  const [isActive, setIsActive] = useState(false);
  const [time, setTime] = useState(0);
  const dispatch = useDispatch();
  const {
    state: {
      workspace: { storage },
    },
  } = useContext(MainContext);

  React.useEffect(() => {
    let interval = null;

    if (isActive) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive]);

  const handleStart = () => {
    setIsActive(true);
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(0);
    onClose();
  };

  useKeyPress(['escape'], () => {
    onClose();
  });

  function startRecording(stream) {
    handleStart();
    let recorder = new MediaRecorder(stream);
    let data = [];

    recorder.ondataavailable = (event) => data.push(event.data);
    recorder.start();

    let stopped = new Promise((resolve, reject) => {
      recorder.onstop = resolve;
      recorder.onerror = (event) => reject(event.name);
    });

    return Promise.all([stopped]).then(() => data);
  }

  const Recording = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true,
      })
      .then((stream) => {
        recording.current.srcObject = stream;
        recording.current.captureStream =
          recording.current.captureStream || recording.current.mozCaptureStream;
        return new Promise(
          (resolve) => (recording.current.onplaying = resolve)
        );
      })
      .then(() => {
        return startRecording(recording.current.captureStream());
      })
      .then((recordedChunks) => {
        let recordedBlob = new Blob(recordedChunks, { type: 'video/webm' });
        var file = new File(
          [recordedBlob],
          `RecordedVideo__${new Date().toLocaleDateString()}.webm`
        );
        dispatch(
          uploadMultiFileEffectRedux({
            folderData: {},
            files: [file],
            storageType: storage,
          })
        );
      });
  };

  function stop(stream) {
    stream.getTracks().forEach((track) => track.stop());
    handleReset();
  }

  const getMilliSeconds = () => ('0' + ((time / 10) % 100)).slice(-2);

  const getSeconds = () => ('0' + Math.floor((time / 1000) % 60)).slice(-2);

  const getMinuts = () => ('0' + Math.floor((time / 60000) % 60)).slice(-2);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={s.modal}
      overlayClassName={s.overlay}
      shouldCloseOnOverlayClick
    >
      <div className={s.modal__content}>
        <button type="button" className={s.modal__exit} onClick={onClose}>
          <CloseIcon color="#7C7E88" />
        </button>
        <div className={s.modal__title}>{t('main.addRecord.recordVideo')}</div>
        <div className={s.modal__container}>
          <Corners top left />
          <Corners top right />
          <Corners bottom right />
          <Corners bottom left />
          {isActive && (
            <div className={s.modal__container__rec}>
              <PlayIcon /> REC
            </div>
          )}
          {!isActive && (
            <div className={s.modal__container__camera}>
              <Camera />
            </div>
          )}
          <div className={s.modal__container__date}>
            {new Date().toLocaleDateString()}
          </div>
          <video
            ref={recording}
            width="484"
            height="290"
            autoPlay
            controlsList="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
            muted
          >
            <track kind="captions" />
          </video>
          <div className={s.modal__container__timer}>
            {getMinuts() + ':' + getSeconds() + ':' + getMilliSeconds()}
          </div>
        </div>
        <div className={s.modal__buttons}>
          <Button
            className={s.modal__buttons__stop}
            disabled={!isActive}
            onClick={() => stop(recording.current.srcObject)}
          >
            <StopIcon />
            {t('main.addRecord.stop')}
          </Button>
          <Button
            className={s.modal__buttons__play}
            disabled={isActive}
            onClick={Recording}
          >
            <PlayIcon />
            {t('main.addRecord.start')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddModal;
