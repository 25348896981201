import React from "react";
import { isEmpty } from "lodash";
import { copyToClipboard } from 'utils/string';

import DeleteIcon from 'components/svg/delete';
import CopyButton from 'components/Button/copy-button';
import styles from './styles.module.scss'

const APIList = ({ list, onDelete }) => {
  if ( isEmpty(list) )
    return ''

  const copyToClipboardHandler = (value) => {
    copyToClipboard(value)
  }

  return (
    <div className={styles.apiForm}>
      {list.map((item, index) => (
        <div
          className={styles.apiItem}
          key={index}
        >
          <p className={styles.info}>
            <span>{ `Name: ${item.name}` }</span>
            <span>{ `Secret: ${item.secret}` }</span>
          </p>
          <div className={styles.buttons}>
            { item.fullSecret && (
              <CopyButton
                className={styles.copyBtn}
                onClick={() => {copyToClipboardHandler(item.fullSecret)}}
              />
            ) }
            <button
              onClick={() => {onDelete(item.id)}}
              className={styles.deleteBtn}
            >
              <DeleteIcon/>
            </button>
          </div>
        </div>
        ))}
    </div>
  )
}

export default APIList;