//Refactoring №5
import React, { useCallback } from 'react';
import moment from 'moment';
import CN from 'classnames';

import { FieldItemType } from 'components/VaultModal/constants'
import CopyButton from 'components/Button/copy-button';
import PasswordText from 'components/VaultModal/components/PasswordText';

import { trimLongText } from 'utils';
import { copyToClipboard } from 'utils/string';

import styles from '../../styles.module.scss'

const MAX_TEXT_LENGTH = 60;

const PreviewItem = ({type, value, name, label}: FieldItemType) => {
  const validateLink = useCallback((link: string) => {
    const pattern = new RegExp('^https?://');
    if (!pattern.test(link)) {
      return `https://${link}`;
    }

    return link;
  }, []);

  switch (type) {
    case 'text':
      return (
        <div key={name} className={styles.previewFieldItem}>
          <p className={styles.fieldName}>{label}</p>
          <div className={styles.filedPreviewValue}>
            <p className={styles.fieldUserNameValue}>
              {trimLongText(value, MAX_TEXT_LENGTH)}
            </p>
            {value && (
              <CopyButton
                onClick={() => {
                  copyToClipboard(value);
                }}
                className={styles.copyButton}
              />
            )}
          </div>
        </div>
      );
    case 'password':
      return (
        <PasswordText
          key={name}
          value={value}
          label={label}
          maxtTextLength={MAX_TEXT_LENGTH}
        />
      );
    case 'date':
      return (
        <div key={name} className={styles.previewFieldItem}>
          <p className={styles.fieldName}>{label}</p>
          <p className={styles.fieldValue}>
            {moment(value, 'DD-MM-YYYY').format('DD-MM-YYYY')}
          </p>
        </div>
      );

    case 'notes':
      return (
        <div key={name} className={styles.previewFieldItem}>
          <p className={styles.fieldName}>{label}</p>
          <p className={styles.fieldValue}>{value}</p>
        </div>
      );

    case 'website':
      return (
        <div key={name} className={styles.previewFieldItem}>
          <p className={styles.fieldName}>{label}</p>
          <a
            href={validateLink(value)}
            target="_blank"
            className={CN(styles.fieldValue, styles.fieldLinkValue)}
            rel="noreferrer"
          >
            {trimLongText(value, MAX_TEXT_LENGTH)}
          </a>
        </div>
      );

    default:
      return (
        <div key={name} className={styles.previewFieldItem}>
          <p className={styles.fieldName}>{label}</p>
          <p className={styles.fieldValue}>
            {trimLongText(value, MAX_TEXT_LENGTH)}
          </p>
        </div>
      );
  }
}

export default PreviewItem