//Refactoring №3
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AccessDenyLogo } from 'static/assets/svg/access-deny.svg';

import s from './style.module.scss';

const EmptyFolder = () => {
  const { t } = useTranslation('account');
  const [error, setError] = useState('');
  const errors = useSelector((state) => state?.errors?.errors);
  const message = error || t('security.askUserToInviteDirectly');
  useEffect(() => errors?.message && setError(errors?.message), [errors]);

  return (
    <section className={s.empty}>
      <AccessDenyLogo />
      <h4 className={s.accessDenyTitle}>{t('security.accessDenied')}</h4>
      <p className={s.description}>{message}</p>
    </section>
  );
};

export default EmptyFolder;
