// store
import * as fileActions from 'store/home/actions/files/get-files.actions';
import * as uploadActions from 'store/home/actions/upload-file.actions';
import { updateSorter, SORT_DIR, SORT_PAGE_TYPE } from 'features/app'
import authRequest from 'utils/request/auth-request';
import { API_TRASH } from 'constants/api-urls';

export const getTrashEffect =
  (userData, clearBeforeGet = false, cancelToken, initialCommit) =>
  async (dispatch) => {
    if (clearBeforeGet) {
      dispatch(fileActions.getFilesClear());
      dispatch(fileActions.getFilesReload());
    }

    dispatch(fileActions.getFiles());

    const url = `${API_TRASH}`;

    await authRequest
      .get(url, null, { options: { cancelToken, params: userData }})
      .then((result) => {
        const data =
          result.data?.data.map((file) => ({ ...file, folderId: 'trash' })) ||
          [];
        const count = result.data?.count;

        if (initialCommit) {
          dispatch(updateSorter({
            initialCommit: false,
            option: result.data.orderBy || 'createdAt',
            dir: result.data.orderDirection || SORT_DIR.asc,
            page: SORT_PAGE_TYPE.deleted
          }))
        }

        dispatch(
          fileActions.getFilesSuccess(
            {
              data: {
                data: data.map((file) => ({ ...file, isDeleted: true })),
                count,
              },
            },
            userData.page === 1 || !userData.page
          )
        );
        dispatch(uploadActions.endUpload());
      })
      .catch((e) => {
        dispatch(uploadActions.endUpload());
        dispatch(fileActions.getFilesFailure(e));
      });
  };
