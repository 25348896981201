import axios from 'axios';

import getImagePreviewEffect from 'store/home/effects/file/get-image-preview.effect';
import { entityCheckIsEmptyEffect } from 'store/home/effects/entity-actions/entity-check-is-empty.effect';
import { getDownloadOTT } from 'store/home/effects';
import imageFileExtensions from 'config/image-file-extensions';

export const getFolderFileSlug = async (folder, callback) => {
  const res = await entityCheckIsEmptyEffect(folder.slug, 1)
  const previewUpdateDay = 1689920400;
  const blobsArray = [];

  for (const file of res.data) {
    if (blobsArray.length === 3) {
      break;
    }

    const isImageValid =
      file.type === 1 &&
      imageFileExtensions.includes(`.${file.extension}`) &&
      file.created_at > previewUpdateDay;

    if (isImageValid) {
      if (!file.is_clientside_encrypted) {
        const fileSignal = axios.CancelToken.source();
        const data = await getImagePreviewEffect(
          file.slug,
          32,
          62,
          'crop',
          fileSignal.token,
          file.mime
        );
        blobsArray.push(data);
      } else {
        const downloadOTTResponse = await getDownloadOTT([
          { slug: file.slug },
        ]);
        const gateway = downloadOTTResponse.data.gateway;
        const res = await axios.get(`${gateway.url}/preview/${file.slug}`, {
          headers: {
            'one-time-token': downloadOTTResponse.data.user_tokens.token,
            'X-Download-OTT-JWT': downloadOTTResponse.data.jwt_ott,
          },
        });
        blobsArray.push(res.data);
      }
    }
  }

  callback({ images: blobsArray,  entities: res.count });
};