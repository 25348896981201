import React from 'react'

import Role from 'containers/dashboard/DashboardUserRoles/item'
import actionsOptions from 'config/actions-options'

const TeamInfo = ({ id, team_users, name, onShowAddUser, onRemoveUser }) => {
  const { removeUserFromTeam } = actionsOptions
  const options = [removeUserFromTeam]

  const actionHandler = (user) => (option) => {
    if (option.type === removeUserFromTeam.type && user) {
      onRemoveUser(user)
    }
  }

  const userItems =
    team_users &&
    team_users.map((item, index) => {
      return (
        <Role
          key={`users-item-${index}`}
          {...item.user}
          invite={item.invite}
          options={options}
          actionHandler={actionHandler(item.user)}
          showActions
        />
      )
    })

  return (
    <div className="right-menu__container team-info">
      <label className="right-menu__label">
        Team Name
        <input
          type="text"
          name="name"
          className="right-menu__input"
          placeholder="Ex.Acme or Acme Marketing"
          defaultValue={name}
        />
      </label>

      <div className="team-user__list">
        {team_users && team_users.length > 0 && (
          <a href="#all-users">{team_users.length} Users in Team</a>
        )}
        {userItems}
      </div>

      <button
        className="button button--primary button--primary_outline text--medium text--12 text--uppercase"
        type="button"
        onClick={onShowAddUser(id)}
      >
        Add user
      </button>
    </div>
  )
}

export default TeamInfo
