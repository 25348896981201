// Refactoring №3
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { stopCheckWeb3 } from 'store/account/actions';
import { connectNetwork, isNetworkCorrect } from 'utils/crypto/networkSetter';

import { Portal } from 'components/Portal';
import { Modal } from 'components/Modal';
import Select from 'components/AppSelect';
import Button, { ButtonTheme } from 'components/Button';

import BNBIcon from 'containers/workspace/WorkspaceSettingsPage/components/WorkspaceSettingsForm/icons/bnb';
import { ReactComponent as EthIcon } from 'static/assets/svg/crypto/eth.svg';
import { ReactComponent as PolygonIcon } from 'static/assets/svg/crypto/polygon.svg';

import styles from './style.module.scss';

export const selectStyles = {
  control: (styles) => {
    return {
      ...styles,
      height: 48,
      borderRadius: 0,
      borderColor: 'var(--inputBorder)',
      backgroundColor: 'var(--inputBg)',
      boxShadow: 'none',
      padding: '0 15px',
      cursor: 'pointer',
      fontFamily: "'SF Pro Text', sans-serif",
      fontSize: '14px',
      lineHeight: '20px',
      '&:hover': {
        borderColor: 'var(--inputBorder)',
      },
    };
  },
};

export const icons = {
  56: <BNBIcon />,
  97: <BNBIcon />,
  80001: <PolygonIcon />,
  420: <EthIcon />,
};

const NetworkNotSupported = ({
  isOpen,
  onClose,
  callback,
  isNetworkCorrectLocal,
}) => {
  const { t } = useTranslation('owner');
  const dispatch = useDispatch();
  const chainId = useSelector((state) => state.account.web3.forceChainId);
  const [isCorrect, setIsCorrect] = useState(false);
  const [networkList, setNetworkList] = useState([]);
  const [value, setValue] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const networkOptions = useMemo(() => {
    const list = chainId
      ? networkList.filter((network) => network.chain_id === chainId)
      : networkList;
    return list.map((network) => ({
      label: (
        <p className={styles.selectOption}>
          {icons[network.chain_id]}
          <span>{network?.name}</span>
        </p>
      ),
      value: network?.chain_id,
    }));
  }, [networkList, chainId]);

  useEffect(() => {
    const checkNetwork = async () => {
      const { isCorrect: isCorrectNetwork, networkList } = isNetworkCorrectLocal
        ? isNetworkCorrectLocal({
            neededChainId: chainId,
          })
        : await isNetworkCorrect({
            neededChainId: chainId,
          });
      if (isCorrectNetwork) {
        callback?.();
        dispatch(stopCheckWeb3());
        return;
      }
      setNetworkList(networkList);
      setIsCorrect(!isCorrectNetwork);
    };
    isOpen && checkNetwork();
    return () => {
      setIsCorrect(false);
    };
  }, [callback, chainId, dispatch, isOpen]);

  useEffect(() => {
    setValue(networkOptions?.[0]);
  }, [networkOptions]);

  const handleChange = (network) => {
    setValue(network);
  };

  const applyNetwork = async () => {
    setLoading(true);
    try {
      const isChecked = await connectNetwork({
        neededChainId: value.value,
        isNetworkCorrectLocal,
      });
      if (isChecked) {
        onClose();
        callback && callback();
        dispatch(stopCheckWeb3());
        setValue(undefined);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    isCorrect && (
      <Portal id={'network-not-supported'}>
        <Modal modalClassName={styles.modal} onClose={onClose} isOpen={isOpen}>
          <div className={styles.modalContent}>
            <p className={styles.modalTitle}>
              {t('common.networkNotSupported')}
            </p>
            <p className={styles.modalSubtitle}>
              {t('common.chooseOneNetwork')}
            </p>
            <Select
              className={`${styles.selector}`}
              value={value}
              onChange={handleChange}
              options={networkOptions}
              newStyles={selectStyles}
              isSearchable={false}
            />
            <div className={styles.buttonsContainer}>
              <Button onClick={onClose} theme={ButtonTheme.TRANSPARENT}>
                {t('common.cancel')}
              </Button>
              <Button
                theme={ButtonTheme.DARK}
                disabled={loading}
                showPreloader={loading}
                onClick={applyNetwork}
              >
                {t('common.connect')}
              </Button>
            </div>
          </div>
        </Modal>
      </Portal>
    )
  );
};

export default memo(NetworkNotSupported);
