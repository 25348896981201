import { getRefreshToken } from 'client-neyra';
import axios, { AxiosRequestConfig } from 'axios';
import { removeToken, setToken } from 'web3-auth/lib/es5';
import { getRefreshToken as refreshToken } from 'utils/auth/get-token';

const redirectToConnect = () => {
  removeToken();
  window.location.href = '/connect';
};

type ResolveFunction = (tokens: string | null) => void;
const refreshQueue: ResolveFunction[] = [];

let accessToken = '';
let isRefreshing = false;

export const handleUnauthorizedError = async (
  config: AxiosRequestConfig,
  serviceType: 'neyra' | 'ghost'
) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const newAccessToken = await getNewAccessToken();

    if (!newAccessToken) {
      throw new Error('No access token received');
    }

    if (!config.headers) {
      config.headers = {};
    }
    if (serviceType === 'neyra') {
      config.headers['Authorization'] = `Bearer ${newAccessToken}`;
    } else {
      config.headers['X-Token'] = `Bearer ${newAccessToken}`;
    }

    const originalHeaders = {};
    Object.entries(config.headers).forEach(([key, value]) => {
      if (typeof value === 'string') {
        originalHeaders[key] = value;
      }
    });

    return await axios({ ...config, headers: originalHeaders });
  } catch (error) {
    throw error;
  }
};

async function refreshAccessToken() {
  try {
    const refresh_token = refreshToken();
    if (refresh_token) {
      const res = await getRefreshToken({ body: { refresh_token } });
      setToken(res);

      //@ts-ignore
      if (typeof setTokens === 'function') {
        const tokens = {
          accessToken: res.data.access_token,
          refreshToken: res.data.refresh_token,
        };
        //@ts-ignore
        setTokens(tokens);
      }
      accessToken = res.data.access_token;
      return res.data.access_token;
    } else {
      redirectToConnect();
      return null;
    }
  } catch (error) {
    redirectToConnect();
    return null;
  } finally {
    isRefreshing = false;
    processQueue();
  }
}

function processQueue() {
  while (refreshQueue.length) {
    const resolve = refreshQueue.shift();
    if (resolve) {
      resolve(accessToken);
    }
  }
}

export const getNewAccessToken = async (): Promise<string | null> => {
  if (!isRefreshing) {
    isRefreshing = true;
    const token = await refreshAccessToken();

    refreshQueue.forEach((resolve) => resolve(token));
    refreshQueue.length = 0;
    isRefreshing = false;
    return token;
  } else {
    return new Promise((resolve) => {
      refreshQueue.push(resolve);
    });
  }
};
