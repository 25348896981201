// Refactoring №3
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { string, object } from 'yup';
import { Formik, Form } from 'formik';

import { checkGhostPassEffect } from 'store/account/effects/ghost-access';

import Button, { ButtonTheme } from 'components/Button';
import InputFieldFormik from 'components/InputFieldFormik';

import style from './style.module.scss';

const GhostPasswordForm = ({ onClose }) => {
  const { t } = useTranslation('account');
  const form = useRef(null);

  const validationSchema = useMemo(
    () =>
      object({
        password: string().required(`${t('Settings.enterPassword')}`),
      }),
    []
  );

  const handleOnSubmit = (data) => {
    checkGhostPassEffect(data)
      .then(() => {
        document.location.reload();
      })
      .catch((error) => {
        form.current.setFieldError(
          'password',
          error?.message || t('Profile.wrongPassword')
        );
      });
  };

  return (
    <Formik
      innerRef={form}
      initialValues={{ password: '' }}
      validationSchema={validationSchema}
      onSubmit={handleOnSubmit}
    >
      <Form className={style.form}>
        <InputFieldFormik
          type="password"
          isPassword
          name="password"
          className={style.passField}
        />
        <div className={style.formButtons}>
          <Button theme={ButtonTheme.TRANSPARENT} onClick={onClose}>
            {t('Profile.cancel')}
          </Button>
          <Button type="submit">{t('Settings.enter')}</Button>
        </div>
      </Form>
    </Formik>
  );
};

export default GhostPasswordForm;
