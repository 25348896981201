// Refactoring №3
import React from 'react';

const Lock = () => (
  <svg
    width="280"
    height="224"
    viewBox="0 0 280 224"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.89"
      d="M261.499 129.154C262.249 101.335 208.727 78.0429 141.955 77.1301C75.1819 76.2173 20.4437 98.0295 19.6934 125.849C18.943 153.668 72.4647 176.96 139.237 177.873C206.01 178.786 260.748 156.974 261.499 129.154Z"
      stroke="url(#paint0_linear_19962:51162)"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.44"
      d="M173.282 171.334C232.221 148.972 265.099 110.939 246.718 86.3832C228.337 61.8277 165.657 60.0489 106.718 82.4102C47.7792 104.772 14.9009 142.805 33.2821 167.361C51.6633 191.916 114.343 193.695 173.282 171.334Z"
      stroke="url(#paint1_linear_19962:51162)"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.55"
      d="M226.4 188.375C254.036 168.7 238.157 125.496 190.933 91.8753C143.708 58.2548 83.0216 46.9499 55.3853 66.6249C27.7491 86.3 43.6284 129.504 90.8528 163.125C138.077 196.745 198.764 208.05 226.4 188.375Z"
      stroke="url(#paint2_linear_19962:51162)"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      opacity="0.4"
      d="M158.946 160.551C160.83 161.689 160.888 164.402 159.053 165.619L120.812 190.998C117.99 192.87 114.412 193.187 111.305 191.841L53.9234 166.97C46.4425 163.727 45.7776 153.376 52.7822 149.203L91.6138 126.07C94.7858 124.18 98.741 124.192 101.902 126.101L158.946 160.551Z"
      fill="url(#paint3_linear_19962:51162)"
    />
    <path
      d="M98 52.7563V122.812C98.0663 123.679 98.3638 124.513 98.8617 125.226C99.3597 125.94 100.04 126.508 100.832 126.871L159.139 160.467C163.339 162.895 170.675 162.591 175.521 159.784C178.238 158.228 179.588 156.217 179.531 154.32V84.2461L98 52.7563Z"
      fill="url(#paint4_linear_19962:51162)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M130.667 108.599C133.012 107.47 134.44 103.883 133.962 99.852C133.409 95.1903 130.515 91.7013 127.499 92.0592C124.482 92.4171 122.485 96.4862 123.038 101.148C123.388 104.095 124.673 106.574 126.333 107.908V121.476C126.333 121.804 126.494 122.112 126.764 122.298L129.097 123.914C129.761 124.373 130.667 123.898 130.667 123.091V108.599Z"
      fill="white"
    />
    <path
      opacity="0.3"
      d="M163.226 161.886C167.424 162.517 171.715 161.78 175.45 159.787C178.216 158.223 179.589 156.2 179.531 154.292V83.8021L163.535 77.6958H163.226V161.886Z"
      fill="url(#paint5_linear_19962:51162)"
    />
    <path
      d="M159.139 90.4102L100.833 56.1476C96.6334 53.6712 97.1656 49.3763 102.012 46.4743C106.858 43.6304 114.194 43.3209 118.394 45.7972L176.699 80.0598C180.899 82.5361 180.367 86.8311 175.521 89.733C170.675 92.577 163.339 92.8865 159.139 90.4102Z"
      fill="white"
    />
    <path
      d="M164.909 85.3693C163.573 85.3693 162.292 84.8399 161.345 83.897C160.398 82.954 159.864 81.6745 159.859 80.3384V49.1847C159.859 38.5533 152.094 25.0932 142.886 19.7775L129.596 12.0697C126.065 10.0384 122.875 9.52581 120.844 10.7029C118.812 11.8799 117.654 14.8795 117.654 18.9612V50.1149C117.654 51.4492 117.124 52.7288 116.181 53.6723C115.237 54.6158 113.958 55.1458 112.623 55.1458C111.289 55.1458 110.009 54.6158 109.066 53.6723C108.122 52.7288 107.592 51.4492 107.592 50.1149V18.9612C107.592 11.0825 110.516 5.04544 115.813 1.98892C121.11 -1.0676 127.812 -0.592998 134.627 3.3558L147.936 11.0446C160.277 18.1638 169.94 34.9082 169.94 49.1657V80.3384C169.94 81.6727 169.41 82.9524 168.467 83.8958C167.523 84.8393 166.244 85.3693 164.909 85.3693Z"
      fill="url(#paint6_linear_19962:51162)"
    />
    <path
      d="M261 132C263.209 132 265 130.209 265 128C265 125.791 263.209 124 261 124C258.791 124 257 125.791 257 128C257 130.209 258.791 132 261 132Z"
      fill="white"
    />
    <path
      d="M58.17 139.34C61.5776 139.34 64.34 136.578 64.34 133.17C64.34 129.762 61.5776 127 58.17 127C54.7624 127 52 129.762 52 133.17C52 136.578 54.7624 139.34 58.17 139.34Z"
      fill="#CFDDF5"
    />
    <path
      opacity="0.5"
      d="M223.17 129.34C226.578 129.34 229.34 126.578 229.34 123.17C229.34 119.762 226.578 117 223.17 117C219.762 117 217 119.762 217 123.17C217 126.578 219.762 129.34 223.17 129.34Z"
      fill="#CFDDF5"
    />
    <path
      d="M227.5 69C228.589 69 229.653 69.323 230.557 69.9282C231.462 70.5333 232.167 71.3934 232.583 72.3994C232.999 73.4054 233.107 74.5122 232.893 75.5796C232.679 76.6469 232.154 77.6269 231.383 78.3954C230.612 79.1639 229.63 79.6863 228.562 79.8965C227.494 80.1067 226.388 79.9952 225.383 79.5762C224.378 79.1572 223.52 78.4495 222.918 77.5427C222.316 76.6358 221.997 75.5707 222 74.4822C222.001 73.7611 222.144 73.0473 222.421 72.3815C222.698 71.7157 223.104 71.1111 223.615 70.602C224.125 70.0929 224.731 69.6894 225.398 69.4146C226.065 69.1397 226.779 68.9988 227.5 69Z"
      fill="white"
    />
    <path
      d="M43 77C43.9896 77 44.9569 77.2937 45.7795 77.8438C46.6021 78.3939 47.243 79.1758 47.621 80.0903C47.9989 81.0049 48.097 82.0111 47.9027 82.9814C47.7085 83.9518 47.2307 84.8427 46.5298 85.5413C45.8289 86.2399 44.9365 86.7148 43.9655 86.9059C42.9945 87.097 41.9887 86.9957 41.0754 86.6147C40.162 86.2338 39.3822 85.5904 38.8348 84.766C38.2873 83.9417 37.9968 82.9734 38 81.9838C38.0011 81.3282 38.1313 80.6793 38.3831 80.0741C38.635 79.4689 39.0035 78.9192 39.4678 78.4564C39.9321 77.9936 40.483 77.6268 41.0891 77.3769C41.6951 77.127 42.3445 76.9989 43 77Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_19962:51162"
        x1="138.26"
        y1="214.088"
        x2="140.628"
        y2="40.8831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DCE1FF" />
        <stop offset="0.5169" stopColor="#B3C8FF" stopOpacity="0" />
        <stop offset="0.6345" stopColor="#B7CBFF" stopOpacity="0.2435" />
        <stop offset="0.7433" stopColor="#C2D3FF" stopOpacity="0.4688" />
        <stop offset="0.8487" stopColor="#D5E0FF" stopOpacity="0.6869" />
        <stop offset="0.9513" stopColor="#EFF3FF" stopOpacity="0.8992" />
        <stop offset="1" stopColor="#D9E4F7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_19962:51162"
        x1="83.1844"
        y1="203.12"
        x2="147.775"
        y2="32.1013"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DCE1FF" />
        <stop offset="0.5169" stopColor="#B3C8FF" stopOpacity="0" />
        <stop offset="0.6345" stopColor="#B7CBFF" stopOpacity="0.2435" />
        <stop offset="0.7433" stopColor="#C2D3FF" stopOpacity="0.4688" />
        <stop offset="0.8487" stopColor="#D5E0FF" stopOpacity="0.6869" />
        <stop offset="0.9513" stopColor="#EFF3FF" stopOpacity="0.8992" />
        <stop offset="1" stopColor="#E4ECFA" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_19962:51162"
        x1="140.892"
        y1="178.415"
        x2="140.892"
        y2="76.5844"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DCE1FF" />
        <stop offset="0.5169" stopColor="#B3C8FF" stopOpacity="0" />
        <stop offset="0.6345" stopColor="#B7CBFF" stopOpacity="0.2435" />
        <stop offset="0.7433" stopColor="#C2D3FF" stopOpacity="0.4688" />
        <stop offset="0.8487" stopColor="#D5E0FF" stopOpacity="0.6869" />
        <stop offset="0.9513" stopColor="#EFF3FF" stopOpacity="0.8992" />
        <stop offset="1" stopColor="#CEDCF4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_19962:51162"
        x1="186.036"
        y1="6.81082"
        x2="146.207"
        y2="170.822"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.752163" stopColor="#5BA1F5" stopOpacity="0.25" />
        <stop offset="1" stopColor="#007CF8" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_19962:51162"
        x1="99.5"
        y1="55.5"
        x2="138.766"
        y2="162.106"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DADDE4" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_19962:51162"
        x1="171.379"
        y1="77.6958"
        x2="171.379"
        y2="162.106"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CBD9F0" />
        <stop offset="1" stopColor="#CEDCF4" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_19962:51162"
        x1="17785.9"
        y1="9273.67"
        x2="22630.2"
        y2="9881.03"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CEDCF4" />
        <stop offset="0.87" stopColor="#DCE6F8" />
      </linearGradient>
    </defs>
  </svg>
);

export default Lock;
