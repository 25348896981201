// utils
import { API_GET_FILE, API_COLORS } from 'constants/api-urls';

import authRequest from 'utils/request/auth-request';

// store

export const colorsGetEffect = async () => {
  return authRequest
    .get(API_COLORS)
    .then((response) => {
      return response.data?.data;
    })
    .catch((response) => {
      return response.message;
    });
};

export const colorAddEffect = async (itemId, colorId) => {
  const url = `${API_GET_FILE}/${itemId}/color`;

  return authRequest
    .post(url, {
      color: colorId,
    })
    .then((response) => {
      return response.data?.data;
    })
    .catch((response) => {
      return response.message;
    });
};
