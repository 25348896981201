// libs
import * as R from 'ramda'

const getArrayFromData = (data) => {
  if (R.isNil(data) || R.isEmpty(data)) return []
  if (data instanceof Array) {
    return data
  }
  return [data]
}

export default getArrayFromData
