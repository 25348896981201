// libs
import * as R from 'ramda'

// utils
import getData from 'utils/request/get-data'

// store
import * as actions from '../actions'

const initialState = {
  data: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.PAYMENT_USER_DATA_ADD:
      return R.merge(state, {
        data: getData(action),
      })
    case actions.PAYMENT_USER_DATA_CLEAR:
      return R.merge(state, {
        data: {},
      })
    default:
      return state
  }
}
