// Refactoring №3
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import UserContext from 'store/home/contexts/user-context';

import AddEmailModal from './addEmailModal';
import { ChangeAddressWalletModal } from './changeAddressWalletModal';

export default function AddEmailFlow({ isStarting, close, titleName }) {
  const [noAttachedAddress, setNoAttachedAddres] = useState(false);
  const [isAddEmailModal, setAddEmailModal] = useState(false);
  const { t } = useTranslation('account');

  const startRetry = () => {
    setNoAttachedAddres(false);
    location.reload();
  };
  const { user } = useContext(UserContext);
  const publicAddresses = user?.user_public_addresses;

  const closeModalHandler = () => {
    if (noAttachedAddress) setNoAttachedAddres(false);
    if (isAddEmailModal) setAddEmailModal(false);
    close();
  };

  useEffect(() => {
    if (isStarting) startAddingEmail();
  }, [isStarting]);

  const providers = window?.ethereum?.providers;
  let selectedProvider;

  for (const provider of providers || []) {
    if (provider.isMetaMask) {
      selectedProvider = provider;
    }
  }
  if (selectedProvider) {
    window.ethereum.setSelectedProvider &&
      window.ethereum.setSelectedProvider(selectedProvider);
  }

  const isAttached = publicAddresses
    ? publicAddresses.find(
        (addres) =>
          addres.public_address.toUpperCase() ===
          window?.ethereum?.selectedAddress?.toUpperCase()
      )
    : null;
  const startAddingEmail = () => {
    if (isAttached) {
      setAddEmailModal(true);
    } else {
      setNoAttachedAddres(true);
    }
  };
  return (
    <>
      <ChangeAddressWalletModal
        isOpen={noAttachedAddress}
        onClose={closeModalHandler}
        addressList={publicAddresses}
        onRetry={startRetry}
      />
      <AddEmailModal
        isOpen={isAddEmailModal}
        close={closeModalHandler}
        titleName={titleName ?? t('Profile.addEmail')}
      />
    </>
  );
}
