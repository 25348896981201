import React from 'react';
import { Route } from 'react-router-dom';
import { isEmpty } from 'ramda';

export default (routing, additionalProps = {}) => {
  if (isEmpty(routing)) return [];
  const isArray = Array.isArray(routing);
  const routes = isArray ? routing : routing.routes;
  const parentPath = isArray ? '' : routing.parent;

  return routes.map((route, i) => {
    const path = isEmpty(parentPath) ? route.path : parentPath + route.path;

    return (
      <Route
        key={i}
        path={path}
        exact={route.exact}
        render={(props) => (
          <route.component
            {...props}
            {...additionalProps}
            routes={{ routes: route.children, parent: route.path }}
          />
        )}
      />
    );
  });
};
