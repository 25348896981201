//Refactoring №3
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import TelegramLoginButton from 'react-telegram-login';
import axios from 'axios';
// @ts-ignore
import { isEmpty } from 'ramda';

import FileSecurityChecker from '../../security/EntitySecurityChecker/index';
import { SharedLayout } from '../components/SharedLayout';

import { API_TELEGRAM, TELEGRAM_BOT_NAME } from 'constants/api-urls';

import style from './style.module.scss';
import setToken from 'utils/auth/set-token';

type TelegramResponseProps = {
  id: number | string;
  username: string;
  first_name: string;
  last_name?: string;
  photo_url?: string;
  hash?: string;
  auth_date?: number,
};
const SignInContainer = () => {
  const { t } = useTranslation('auth');
  const history = useHistory();
  const searchParams: any = new URLSearchParams(history.location.search);
  const isoauth = JSON.parse(searchParams.get('isoauth'));
  const workspace_securities = useSelector(
    (state: any) => state?.home?.entitiesSecurityCheck?.workspace_securities
  );
  const handleTelegramResponse = (response: TelegramResponseProps) => {
    const { id, username, first_name, last_name, photo_url, hash, auth_date } = response;
    const data = {
      id,
      username,
      first_name,
      last_name,
      photo_url,
      hash,
      auth_date,
    };
    const res = axios.post(API_TELEGRAM, data)
      .then((data) => {
        setToken(data)
        history.push('/')
      });
    console.warn(API_TELEGRAM, res);
  };

  return (
    // @ts-ignore
    <SharedLayout
      isoauth={isoauth}
      isTelegramPage
      history={history}
      blackFooter
      type="signinEmail"
    >
      <div className={style.container}>
        <div className={style.form}>
          <h2 className={style.title}>{t('common.connectTelegram')}</h2>
          <TelegramLoginButton
            dataOnauth={handleTelegramResponse}
            botName={TELEGRAM_BOT_NAME}
          />
          {/* <SignInTelegramForm onSubmit={submitHandler} loading={loading} /> */}
        </div>
      </div>
      {!isEmpty(workspace_securities) && <FileSecurityChecker />}
    </SharedLayout>
  );
};

export default React.memo(SignInContainer);
