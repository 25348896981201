/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
//Refactoring №2
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Portal } from 'react-portal';

import classNames from 'classnames';
import { useUpdateEffect } from 'ahooks';

import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import MainContext from 'store/main/context/main-context';
import UserContext from 'store/home/contexts/user-context';
import { isMember } from 'store/home/selectors';
import { setIsClearAllPressed } from 'store/main/actions';
import updateFileEffect from 'store/home/effects/file/file-update.effect';

import {
  handleCreateFolderModal,
  handleCreateDocumentModal,
  handleVaultModal,
  handleLinkModal,
  handleUpload,
} from 'features/modals/modal-slice';
import {
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
} from 'features/app';
import actionsOptions from 'config/actions-options';
import { EMBARGO_MULTISIG_OPTIONS } from 'config/multisig';
import useNotification from 'utils/hooks/use-notification';
import { isMobile } from 'utils/mobile';
import { checkDeviceAndScroll } from 'utils/browser';

import ContextMenu from 'components/context-menu';
import CreateEntityModal from 'containers/header/header-container/create-entity-modal';
import DeleteModal from 'components/DeleteModal';
// import ContextMenuButton, {
//   contextMenuButtonStyle,
// } from 'components/ContextMenuButton';
import BreadcrumbItem from './breadcrumb-item';

import { ReactComponent as Diliver } from './assets/diliver.svg';

import style from './styles.module.scss';

const breadcrumbUrls = [
  {
    path: '/main',
    exact: true,
  },
  {
    path: '/folder/:folderId',
  },
];

const Breadcrumbs = ({ isSearch, searchCount }) => {
  const { state: mainState, dispatch: mainDispatch } = useContext(MainContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('owner');
  const { t: contextT } = useTranslation('contextMenu');
  const { user } = useContext(UserContext);
  const location = useLocation();
  const { addNotification } = useNotification();
  const {
    folder,
    pageName,
    pageUrl,
    breadcrumbs,
    additionalLabel,
    workspace,
    isDeletedPage,
  } = mainState;
  const [parent, setParent] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [showContext, setShowContext] = useState(false);
  const [showFolderContext, setShowFolderContext] = useState(false);
  const [contextPosition, setContextPosition] = useState({
    top: '10px',
    left: '50%',
  });
  const [folderContextPosition, setFolderContextPosition] = useState({
    top: '10px',
    left: '50%',
  });
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);

  const isBetaEnviroment = useMemo(
    () => process.env.REACT_APP_ENVIROMENT === 'BETA',
    []
  );

  const allowBreadcrumbFolderContextMenu =
    location?.pathname.includes('folder') || location?.pathname === '/main';

  const contextFilter = (option) => {
    if (!option) return false;
    if (isMultisigActivated && !isMultisigPartisipant) {
      const embargoOptions = EMBARGO_MULTISIG_OPTIONS;

      return !embargoOptions.includes(option);
    }

    return true;
  };

  const successFavoriteNotification = useCallback((entity) => {
    const type = t('rightFileMenu.meta.folder');
    addNotification(
      entity.user_favorites.length
        ? t('favourites.successfullyRemoved', { type })
        : t('favourites.successfullyAdded', { type })
    );
  }, []);

  const contextOptions = useMemo(() => {
    if (location?.pathname === '/main') {
      return isBetaEnviroment
        ? [
            actionsOptions.createFolder,
            actionsOptions.upload,
            actionsOptions.addLink,
          ]
        : [
            actionsOptions.createFolder,
            actionsOptions.upload,
            actionsOptions.addPage,
            actionsOptions.addPassword,
            actionsOptions.addLink,
          ];
    }

    if (location?.pathname.includes('folder')) {
      const commonOptions = [
        actionsOptions.accessPreferences,
        actionsOptions.myAI,
        actionsOptions.showMove,
        actionsOptions.edit,
        actionsOptions.nft,
      ];

      switch (true) {
        case user.isGuest:
          return [actionsOptions.download];
        case isMember(user) && isBetaEnviroment:
          return commonOptions.filter(contextFilter);
        case isMember(user):
          return [
            ...commonOptions,
            actionsOptions.guardians,
            actionsOptions.download,
            actionsOptions.remove,
          ].filter(contextFilter);

        case isBetaEnviroment:
          return [
            ...commonOptions,
            actionsOptions.geoSecurity,
            actionsOptions.download,
            actionsOptions.remove,
          ].filter(contextFilter);
        default:
          return [
            ...commonOptions,
            actionsOptions.geoSecurity,
            actionsOptions.guardians,
            actionsOptions.download,
            actionsOptions.remove,
          ].filter(contextFilter);
      }
    }

    return [];
  }, [location?.pathname]);

  const onFolderActionHandler = (option) => {
    switch (option.type) {
      case actionsOptions.createFolder.type:
        dispatch(handleCreateFolderModal(true));
        break;
      case actionsOptions.addPage.type:
        dispatch(handleCreateDocumentModal(true));
        break;
      case actionsOptions.upload.type:
        dispatch(handleUpload(true));
        break;
      case actionsOptions.addPassword.type:
        dispatch(handleVaultModal({ isOpen: true }));
        break;
      case actionsOptions.addLink.type:
        dispatch(handleLinkModal({ isOpen: true }));
        break;
      case actionsOptions.guardians.type:
      case actionsOptions.geoSecurity.type:
      case actionsOptions.accessPreferences.type:
        dispatch(
          fileFolderActionHandlerEffect(folder, option, { skipGetData: true })
        );
        break;
      case actionsOptions.nft.type:
        dispatch(
          fileFolderActionHandlerEffect(folder, actionsOptions.goToTokenization)
        );
        break;
      case actionsOptions.remove.type:
        setIsDeleteModalOpen(true);
        break;
      case actionsOptions.myAI.type:
        dispatch(
          fileFolderActionHandlerEffect(folder, option, {
            addNotification,
            showSuccessNotification: () => {successFavoriteNotification(folder)},
          })
        );
        break;
      case actionsOptions.edit.type:
        setIsEditModalOpen(true);
        break;

      default:
        dispatch(fileFolderActionHandlerEffect(folder, option));
    }
  };

  const onClickOutsideFolderContext = () => setShowFolderContext(false);

  const openContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.clientY === 0 && e.clientX === 0) return;

    const isTablet = isMobile && window.screen.width > 480;

    const rect = e.target.getBoundingClientRect();
    const left = isTablet ? rect?.x - 100 : isMobile ? rect?.x : rect?.x - 300;
    const position = {
      top: `${e.clientY + 15}px`,
      left: `${parseInt(left)}px`,
    };

    setFolderContextPosition(position);
    setShowFolderContext(true);
  };

  var flattenObject = (tree) => {
    var result = [];
    var rec = (obj) => {
      if (obj?.parent) {
        rec(obj?.parent);
      }
      return result.push({ ...obj, parent: null });
    };
    rec(tree);
    return result;
  };

  useUpdateEffect(() => {
    setParent(folder ? flattenObject(folder) : []);
  }, [folder]);

  const isShared =
    location?.pathname.indexOf('/sharing') !== -1 ||
    location.state?.sharedPage ||
    (folder?.user && !(folder?.user?.id === user.id));
  const matches = [];
  breadcrumbUrls.forEach((item) => {
    const match = matchPath(location.pathname, item);
    if (match) {
      matches.push(match);
    }
  });

  if (isSearch) {
    return (
      <div className="breadcrumb-search">
        {t('common.searchResult')}
        <span className="breadcrumb-search-count">
          ({searchCount}) {t('common.results')}
        </span>
      </div>
    );
  }

  if (matches.length === 0 && !pageName) return null;
  let firstCrumb = (
    <NavLink onClick={checkDeviceAndScroll} to={pageUrl ? pageUrl : '/main'}>
      {isShared ? 'Shared' : pageName || 'Drive'}
      {additionalLabel || isDeletedPage ? (
        <span
          onClick={
            isDeletedPage
              ? () => mainDispatch(setIsClearAllPressed(true))
              : undefined
          }
          className={`breadcrumb-item-additional${
            isDeletedPage ? '-clear' : '-'
          }-label`}
          data-test="breadcrumb-item_first_label"
        >
          {isDeletedPage ? t('deleted.clear') : additionalLabel}
        </span>
      ) : null}
    </NavLink>
  );

  const { folderId } = matches[0]?.params || {};
  const isMyFolder = folderId && folder?.user?.id === user.id;
  if (
    !workspace?.is_workspace_owner &&
    folder &&
    !isMyFolder &&
    !workspace?.role === 'ROLE_MEMBER'
  ) {
    firstCrumb = (
      <NavLink onClick={checkDeviceAndScroll} to="/sharing/with-me">
        {t('shared.sharedForMe')}
      </NavLink>
    );
  } else if (folder && isMyFolder && location.state?.sharedPage) {
    firstCrumb = (
      <NavLink onClick={checkDeviceAndScroll} to="/sharing">
        {t('shared.sharedItems')}
      </NavLink>
    );
  }

  let lastCrumb;
  if (folderId && folder) {
    // eslint-disable-next-line no-unused-vars
    lastCrumb = (
      <>
        <div className="breadcrumb-divider" />
        <div className="breadcrumb-item active">
          <NavLink onClick={checkDeviceAndScroll} to={`/folder/${folder.slug}`}>
            {folder.name}
          </NavLink>
        </div>
      </>
    );
  }

  const otherCrumbs =
    parent?.length >= 1 ? (
      <BreadcrumbItem
        crumb={parent.slice(-2)}
        activeCrumbId={folderId}
        key={`breadcrumb-item-${breadcrumbs?.slug}`}
      />
    ) : null;

  const onRightFileClickHandler = (e) => {
    e.preventDefault();
    const rect = e.target.getBoundingClientRect();
    if (e.clientY === 0 && e.clientX === 0) return;
    const left = rect?.x - 238;
    const position = { top: `${105}px`, left: `${parseInt(left)}px` };
    setContextPosition(position);
    setShowContext(true);
  };

  const onClickOutsideContext = () => {
    setShowContext(false);
  };

  const onActionHandler = (option) => {
    history.push('/folder/' + option.slug);
  };

  const onDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
  };

  const onDeleteFolder = () => window.history.back();

  const onCancelEditHandler = () => {
    setIsEditModalOpen(false);
  };

  const onEndEditHandler = (newName) => {
    dispatch(
      updateFileEffect(folder, () => setIsEditModalOpen(false), null, {
        update: { name: newName },
        updatedPropsList: ['name'],
        mainDispatch: mainDispatch,
        folder: folder,
      })
    );
  };

  const renderEditForm = () => {
    return (
      <Portal>
        <CreateEntityModal
          closeModal={onCancelEditHandler}
          modalIsOpen={isEditModalOpen}
          onCreate={onEndEditHandler}
          title={contextT('files.rename.renameFile')}
          contentLabel={contextT('files.rename.renameFile')}
          inputPlaceholder={contextT('files.rename.enterName')}
          defaultValue={folder?.name}
          acceptButtonText={contextT('files.rename.rename')}
          name={contextT('files.rename.fileName')}
        />
      </Portal>
    );
  };

  const renderMobileBreadcrumb = () => {
    return parent.length === 0 ? (
      <div className={`breadcrumb-title ${!folderId ? 'active' : ''}`}>
        {firstCrumb}
      </div>
    ) : (
      <BreadcrumbItem
        crumb={parent.slice(-1)}
        activeCrumbId={folderId}
        key={`breadcrumb-item-${breadcrumbs?.slug}`}
        showDiliver={false}
      />
    );
  };

  return (
    <section className="breadcrumb" data-test={`section_breadcrumb`}>
      {/* {isMobile ? (
        renderMobileBreadcrumb()
      ) : (
        <> */}
          <div className={`breadcrumb-title ${!folderId ? 'active' : ''}`}>
            {firstCrumb}
          </div>
          {parent.length > 2 && (
            <>
              {/* <div className="breadcrumb-divider">
                <Diliver />
              </div> */}
              {/* <ContextMenuButton
                className={`${'breadcrumb-action'} ${
                  style.breadcrumbDelimiter
                }`}
                onClick={onRightFileClickHandler}
                buttonStyle={contextMenuButtonStyle.DARK}
              />
              {showContext && (
                <ContextMenu
                  className={`${'breadcrumb-context-menu'} ${
                    style.breadcrumbContextMenu
                  }`}
                  onClickOutside={onClickOutsideContext}
                  contextPosition={contextPosition}
                  actionHandler={onActionHandler}
                  isBreadcrumbs={true}
                  options={parent.slice(0, parent.length - 2).map((el) => ({
                    ...el,
                    type: 'folder',
                    name:
                      el?.name?.length < 24
                        ? el?.name
                        : el?.name.substr(0, 22) + '...',
                  }))}
                />
              )} */}
            </>
          )}
          {/* {otherCrumbs} */}
        {/* </>
      )} */}
      {allowBreadcrumbFolderContextMenu && (
        <div
          className={classNames(
            'breadcrumb-divider',
            showFolderContext ? style.diliverUp : style.diliverDown
          )}
          onClick={openContextMenu}
        >
          <Diliver />
        </div>
      )}

      {showFolderContext && (
        <ContextMenu
          contextPosition={folderContextPosition}
          actionHandler={onFolderActionHandler}
          onClickOutside={onClickOutsideFolderContext}
          options={contextOptions}
          permission={false}
          entity={folder}
        />
      )}
      {isEditModalOpen && renderEditForm()}
      {isDeleteModalOpen && (
        <DeleteModal
          entity={folder}
          handleClose={onDeleteModalClose}
          handleDelete={onDeleteFolder}
        />
      )}
    </section>
  );
};

Breadcrumbs.defaultProps = {
  folder: null,
};

export default Breadcrumbs;
