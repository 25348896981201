export const CHECK_AUTH = 'CHECK_AUTH'
export const CHECK_AUTH_SUCCESS = 'CHECK_AUTH_SUCCESS'
export const CHECK_AUTH_FAIL = 'CHECK_AUTH_FAIL'

export function checkAuth() {
  return {
    type: CHECK_AUTH,
  }
}

export function checkAuthSuccess() {
  return {
    type: CHECK_AUTH_SUCCESS,
  }
}

export function checkAuthFail() {
  return {
    type: CHECK_AUTH_FAIL,
  }
}
