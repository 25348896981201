import { compose, find, prop, propEq, isEmpty } from 'ramda'

const isExist = (arr, element, propName) => {
  return !compose(isEmpty, find(propEq(propName, element.id)))(arr)
}

const removeBy = (element, arr, propName) => {
  if (!isExist(arr, element, propName)) {
    return arr
  }

  return arr.filter((item) => {
    return prop(propName, item) !== prop(propName, element)
  })
}

export default removeBy
