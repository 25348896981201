import React from 'react';
import styles from './styles.module.scss';

const Dropdown = ({ options, onClick }) => {
  return (
    <div className={styles.dropdownContainer}>
      {options.map((item) => (
        <div
          className={styles.dropdownItem}
          key={item.value}
          onClick={() => {
            onClick(item.value);
          }}
        >
          {item.value}
        </div>
      ))}
    </div>
  );
};

export default Dropdown;
