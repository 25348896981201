// libs
import { clone } from 'ramda';

/**
 *
 * @param {object} obj1 - object with new properties
 * @param {object} obj2 - object with old properties, should be updated and returned
 * @param {Array} properties - array of properties that should be updated
 */

const updateProperties = (obj1, obj2, properties) => {
  const newObj = clone(obj2);
  properties.map((property) => {
    newObj[property] = obj1[property];
    return null;
  });

  return newObj;
};

export { updateProperties };
