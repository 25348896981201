export const TOKEN_COST = {
  AIImages: '1',
  AIImage2Image: '2',
  AIImageUpscale: '3',
  realtimeImageGenerator1: '30',
  realtimeImageGenerator2: '31',
  realtimeImageGenerator3: '32',
  realtimeImageGenerator4: '33',
  '360ImageGenerator': '34',
};
