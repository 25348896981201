/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';

import { ReactComponent as BigCircleIcon } from '../../assets/big-circle.svg';
import { ReactComponent as ProgressRing } from '../../assets/progress-ring.svg';
import { ReactComponent as PlusIcon } from '../AuthSidebar/assets/plus.svg'
import NeyraLogo from 'containers/auth/NeyraWelcome/component/NeyraLogo'


import styles from './styles.module.scss';

const AuthContent = ({
  inputValue,
  list,
  contentState,
  logo,
  setLogo,
  setValues,
  timeRef,
  setCityList,
}) => {
  const inputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState();

  const handleImageChange = (event) => {
    // const files = Array.from(event.target.files);
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("resource_id", undefined);

      // const formData = new FormData();
      // formData.append('image', file);
      setLogo(formData);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setLogo('null');
      setImagePreview(null);
    }
  };

  const handleUploadStart = () => {
    inputRef.current?.click()
  }

  const onCitySelect = (city, timeZone) => {
    setValues((values) => ({ ...values, city, timeZone }));
    setCityList([])
    timeRef.current?.focus()
    document.getElementById('time-input').focus();
  }

  return (
    <div className={styles.container}>
      <div className={styles.avatarContainer}>
        <BigCircleIcon className={styles.bigCircle} />
        {!list.length && <ProgressRing  className={styles.progressRing} />}
        {logo && !list.length  && (
          <div
            className={styles.addLogoTP}
            onClick={handleUploadStart}
          >
            <img src={imagePreview || logo} alt="avatar" className={styles.logoPreview} />
              <input
                ref={inputRef}
                className={styles.addLogoInput}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              <div className={styles.editLogo}>
                <button>
                  <PlusIcon />
                </button>
                <p>Chnage Photo</p>
              </div>
          </div>
        )}
          {(list.length ? (
            <div className={styles.cityList}>
              { list.map((item, index) => (
                <div
                  onClick={() => {onCitySelect(item.city, item.tz)}}
                  className={styles.cityItem}
                  key={`city-${index}`}
                >
                  {`${item.city} / ${item.land} / ${item.sdiv} / ${item.tz}`}
                </div>
              ))}
            </div>
          ) : (
          !logo && <NeyraLogo className={styles.neyraLogo} />
        ))}
      </div>
      <div className={styles.typer}>
        <p>{inputValue}</p>
      </div>
    </div>
  );
};

export default AuthContent;
