export const GET_FILE_VIEW = 'GET_FILE_VIEW'

export function getFileView(data) {
  return {
    type: GET_FILE_VIEW,
    payload: {
      data,
    },
  }
}

export const SET_FILE_VIEW = 'SET_FILE_VIEW'

export function setFileView(data) {
  return {
    type: SET_FILE_VIEW,
    payload: {
      data,
    },
  }
}
