import React, { useEffect, useReducer } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import { entitiesTypes } from 'config/entities-types';
import { historyGetEffect } from 'store/history/effects';
import reducer, { defaultState, initiateState } from 'store/history/reducers';
import {
  loadHistory,
  filterByDate,
  resetHistory,
  clearHistoryFilter,
} from 'store/history/actions';

import HistoryItem from './history-item';
import { ReactComponent as CalendarIcon } from './assets/calendar_today.svg';

import './react-datepicker.css';
import styles from './style.module.scss';

export const HistoryTab = ({ entity }) => {
  const { t } = useTranslation('owner');
  const [state, dispatch] = useReducer(reducer, defaultState, initiateState);
  const { startDate, filteredHistoryItems } = state;
  const { folder, file } = entitiesTypes;

  const signal = axios.CancelToken.source();
  const color = entity.color?.[entity.color.length - 1];

  const getDataHandler = (responseData) => {
    if (typeof responseData === 'object') {
      dispatch(loadHistory(responseData));
    }
  };

  useEffect(() => {
    if (entity.slug) {
      dispatch(resetHistory());
      historyGetEffect(entity.slug, signal.token, 1).then(getDataHandler);
    }

    return () => {
      dispatch(resetHistory());
      signal.cancel('commentGetEffect is being canceled');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.slug]);

  const handleDatePickerChange = (date) => {
    if (!date) {
      dispatch(clearHistoryFilter());
    } else {
      dispatch(filterByDate(date));
    }
  };

  const historyItemElements = filteredHistoryItems.map((item) => (
    <HistoryItem
      historyItem={item}
      color={color}
      key={`history-item-${item.id}`}
      entityType={entity.type === 2 ? folder.type : file.type}
      entityName={entity.name}
    />
  ));

  return (
    <section className={styles.logsWrapper}>
      <h2 className={styles.logsWrapper__title}>{t('common.logs')}</h2>
      <div className={styles.datePicker}>
        <DatePicker
          placeholderText={t('rightFileMenu.history.date')}
          selected={startDate}
          onChange={handleDatePickerChange}
          placeholder={t('rightFileMenu.history.date')}
          className={styles.datePickerInput}
          showIcon={false}
        />
        <CalendarIcon className={styles.pickerIcon} />
      </div>
      <div className={styles.logsWrapper__content}>{historyItemElements}</div>
    </section>
  );
};

export default HistoryTab;
