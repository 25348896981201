import React from 'react'

const TrashIcon = ({ color }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12.76 3.0791L11.7399 14.7033H4.26141L3.24153 3.0791L1.94922 3.19238L2.98772 15.0274C3.04237 15.5729 3.51569 16.0006 4.06553 16.0006H11.9358C12.4854 16.0006 12.9589 15.5731 13.0145 15.0196L14.0523 3.19238L12.76 3.0791Z"
        fill={color}
      />
      <path
        d="M10.3789 0H5.62211C5.02598 0 4.54102 0.484969 4.54102 1.08109V3.13516H5.8383V1.29728H10.1626V3.13512H11.4599V1.08106C11.46 0.484969 10.975 0 10.3789 0Z"
        fill={color}
      />
      <path
        d="M14.9181 2.48633H1.0803C0.722016 2.48633 0.431641 2.7767 0.431641 3.13498C0.431641 3.49327 0.722016 3.78364 1.0803 3.78364H14.9181C15.2764 3.78364 15.5668 3.49327 15.5668 3.13498C15.5668 2.7767 15.2764 2.48633 14.9181 2.48633Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

TrashIcon.defaultProps = {
  color: '#7E8A9F',
}

export default TrashIcon
