//Refactoring №3
import React from 'react';

const SingleFolder = ({ color = 'rgb(193, 201, 217)', isSelected = false }) => (
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.94853 0H0.978431V1.30336H0.949721V13.9998H18.221V1.30336H7.63192L6.94853 0Z"
      fill={color}
    />
    <rect
      x="1.57812"
      y="2.08203"
      width="15.3872"
      height="1.51314"
      rx="0.756568"
      fill="white"
    />
    <path
      d="M0 2.64844H19.0061L18.221 13.9973H0.949672L0 2.64844Z"
      fill={isSelected ? color : '#C1C9D9'}
    />
  </svg>
);

export default SingleFolder;
