/* eslint-disable no-unused-vars */
//Refactoring №3
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
// import { isEmpty } from 'ramda';

// import { reindexNftMetadataEffect } from 'store/file/effects';
import getFileTokenization from 'store/home/effects/file/get-file-tokenization.effect';
import MainContext from 'store/main/context/main-context';
import LogoNeyra from 'containers/auth/NeyraWelcome/component/NeyraLogo';

import CreateForm from 'containers/main/EntitySettingsContainer/components/Web3Tab/components/CreateForm';
import Tokenization from 'containers/main/EntitySettingsContainer/components/Web3Tab/components/Tokenization';

import {
  switchNetwork,
  getNetworksEffects,
  COLECTION_COLORS,
} from 'store/web3';

import { getCurrentChainIdAsync } from 'utils/crypto/wallet/metamask';
import { isElectron } from 'utils/isElectron';
import { showMetamaskMobileOption } from 'utils/showMetamaskMobileOption';
import { getCoinbaseProvider } from 'utils/getCoinbaseProvider';

import { SMART_CONTRACT_TYPES } from 'config/smart-contrat-types';

import styles from './styles.module.scss';
// import Loader from 'components/MultisigStatusModal/components/Loader';

const Tokenize = ({
  loading,
  selectedEntity,
  tokenizationData,
  setTokenizationData,
}) => {
  const { t: accountT } = useTranslation('account');
  const { t } = useTranslation('owner');
  const {
    state: { workspace },
  } = useContext(MainContext);
  const [collections, setCollections] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [chainId, setChainId] = useState(0);
  const [provider, setProvider] = useState(null);
  const smart_contracts = workspace.smart_contracts || [];

  const isTokanized = !!tokenizationData.length;
  const providerName = localStorage.getItem('PROVIDER');

  const getInitial = async (selectedProvider = null) => {
    const chainId = await getCurrentChainIdAsync(selectedProvider);
    const networks = await getNetworksEffects();
    setNetworks(networks || []);
    handleSetCollections(smart_contracts);
    setChainId(chainId);
  };

  useEffect(() => {
    let currentProvider = null;
    if (isElectron) {
      if (providerName === 'metamask') {
        window.metamask.on('accountsChanged', (accounts) => {
          
          console.warn('Account is changed', accounts[0]);
        });
      }
      if (providerName === 'coinbase') {
        currentProvider = getCoinbaseProvider();
        getCoinbaseAddress(currentProvider);
      }
    }
    getInitial(currentProvider);

    return () => {
      window?.metamask?.removeListener('accountsChanged', (accounts) => {
  
        console.warn('Account is changed', accounts[0]);
      });
    };
  }, []);

  useEffect(() => {
    if (isElectron && providerName === 'metamask') {
      if (!window.metamask.selectedAddress) {
        window.metamask.request({
          method: 'eth_requestAccounts',
          params: [],
        });
        showMetamaskMobileOption();
      }
      if (window.metamask.selectedAddress) {
        getInitial();
      }
    }
  }, [window.metamask.selectedAddress]);

  const getCoinbaseAddress = async (coinbaseProvider) => {
    setProvider(coinbaseProvider);
    await coinbaseProvider.request({
      method: 'eth_requestAccounts',
    });
  };

  const handleSetCollections = useCallback((smart_contracts) => {
    const filterdColection = smart_contracts.filter(
      (item) => item.type === SMART_CONTRACT_TYPES.collection
    );

    setCollections(
      filterdColection.map((item, index) => ({
        ...item,
        color: COLECTION_COLORS[index % 3],
      }))
    );
  }, []);

  const handleChangeNetwork = useCallback(
    async (nextChailId) => {
      try {
        const nextNetwork = networks.find(
          (item) => item.chain_id === nextChailId
        );
        const network = await switchNetwork(nextNetwork, provider);
        return network;
      } catch (error) {
        return '';
      }
    },
    [networks]
  );

  const updateTokenization = useCallback(
    (cb) => {
      getFileTokenization(selectedEntity.slug).then((data) => {
        setTokenizationData(data);
        cb && cb();
      });
    },
    [selectedEntity]
  );

  if (loading) return (
    <div className={styles.createWidget}>
      <div className={styles.logoWrapper}>
        <LogoNeyra className={styles.loader} />
      </div>
      <div className={styles.loaderTextContainer} >
        <p className={styles.loaderText}>
          {`${accountT('convertModal.loading')}...`}
        </p>
      </div>
    </div>
  )
  
  return (
    <div className={styles.tokenize}>
      {isTokanized ? (
        <Tokenization
          tokenization={tokenizationData}
          entity={selectedEntity}
          updateTokenization={updateTokenization}
          setTokenizationData={setTokenizationData}
          handleChangeNetwork={handleChangeNetwork}
          networks={networks}
        />
      ) : (
        <div className={styles.createWidget}>
          <h2 className={styles.createLabel}>
            {t('rightFileMenu.item.createItemTitle')}
          </h2>
          <CreateForm
            collections={collections}
            networks={networks}
            entity={selectedEntity}
            chainId={chainId}
            setCollections={handleSetCollections}
            handleChangeNetwork={handleChangeNetwork}
            setTokenizationData={setTokenizationData}
          />
        </div>
      )}
    </div>
  );
};

export default Tokenize;
