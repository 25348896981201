import USER_ROLE_TYPES from 'config/user-role-types'
import { CONTRACT_MULTYSIG_TYPE } from 'config/multisig'

const isOwnerChecker = (role) => {
  return USER_ROLE_TYPES.OWNER === role
}

const isMemberChecker = (role) => {
  return USER_ROLE_TYPES.MEMBER === role
}

const isGuestChecker = (role) => {
  return USER_ROLE_TYPES.GUEST === role
}

const getOwnerItemFromList = (list = []) => {
  return list.find((item) => item.role === USER_ROLE_TYPES.OWNER)
}

const isMultisigActivated = (smartContracts = []) => {
  return !!smartContracts.find((item) => item.type === CONTRACT_MULTYSIG_TYPE)
}

const getMultisigContractByList = (smartContracts = []) => {
  return smartContracts.find((item) => item.type === CONTRACT_MULTYSIG_TYPE)
}

export { isOwnerChecker, isMemberChecker, isGuestChecker, getOwnerItemFromList, isMultisigActivated, getMultisigContractByList }
