/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { selectSelectedFilePreview, FILE_PREVIEW } from 'features/app';

import { isEmpty } from 'ramda';

import { reindexNftMetadataEffect } from 'store/file/effects';
import getFileTokenization from 'store/home/effects/file/get-file-tokenization.effect';
import { getMetaEntityInformationEffect } from 'store/entityMetaInformation';
import { fileUpdateDataEffect } from 'store/home/effects/file/file-update.effect';
import {  selectedEntityClearEffect } from 'store/home/effects/selected-entity/selected-entity-clear-effect';
import { fileUpdateDataSelectedEntityEffect } from 'store/home/effects/entity-actions/entity-make-action.effect';

import MetaInfo from '../MetaContainer';
import TokenizeInfo from '../TokenizeContainer';
import CommentInfo from '../Comment';
import ShareInfo from '../Share';
import SettingsInfo from '../Settings';
import MyAiInfo from '../MyAi';
import HistoryTab from '../HistoryTab';

import MetaComponent from 'containers/main/EntitySettingsContainer/components/InfoTab'

import useEnv from 'utils/hooks/use-env';

import styles from './styles.module.scss';

const Container = ({
  selectedEntity,
  selectedPreview,
  fileUpdateDataEffect,
  selectedEntityClearEffect,
  fileUpdateDataSelectedEntityEffect
}) => {
  const [loading, setLoading] = useState(false);
  const isDevNev = useEnv();
  const [entityMetaStatistic, setEntityMetaStatistic] = useState({
    downloaded: 0,
    viewed: 0,
  });
  const [tokenizationData, setTokenizationData] = useState([]);

  useEffect(() => {
    setLoading(true)
    getFileTokenization(selectedEntity.slug)
      .then((data) => {
        if (isEmpty(data)) {
          setTokenizationData(data);
          setLoading(false);
        } else {
          reindexNftMetadataEffect(
            selectedEntity.slug,
            data[0].ghost1155Collection.id
          ).then(() => {
            getFileTokenization(selectedEntity.slug).then((data) => {
              setLoading(false);
              setTokenizationData(data);
            }).catch(() => {
              setLoading(false);
            });
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        console.warn({ e });
      });

      getMetaEntityInformationEffect(selectedEntity.slug).then((data) => {
        setEntityMetaStatistic(data.data.entry.entry_statistic);
      });
  }, [selectedEntity.slug]);

  const entityUpdateStoreEffect = (data) => {
    fileUpdateDataEffect(data);
    fileUpdateDataSelectedEntityEffect(data);
  };

  const onCloseSettings = () => {
    selectedEntityClearEffect();
  };

  const renderContent = () => {
    switch (selectedPreview) {
      case FILE_PREVIEW.meta:
        return (
          isDevNev ? (
            <MetaInfo selectedEntity={selectedEntity} />
          ) : (
          <MetaComponent
            onCloseSettings={onCloseSettings}
            tokenizationData={!!tokenizationData.length && tokenizationData[0]}
            workspaceMembers={[]}
            entity={selectedEntity}
            entityStatistic={entityMetaStatistic}
            entityView={false}
            entityUpdateStoreEffect={entityUpdateStoreEffect}
            hideUsers={false}
            hideColors={false}
            hideTags={false}
            isPublic={false}
            setActiveTab={() => {}}
            setInitialOption={() => {}}
          />
          )
        );
      case FILE_PREVIEW.tokenize:
        return (
          <TokenizeInfo
            selectedEntity={selectedEntity}
            tokenizationData={tokenizationData}
            setTokenizationData={setTokenizationData}
            loading={loading}
          />
        );
      case FILE_PREVIEW.comment:
        return <CommentInfo entity={selectedEntity} />;
      case FILE_PREVIEW.share:
        return (
          <ShareInfo
            selectedEntity={selectedEntity}
            tokenizationData={tokenizationData}
          />
        );
      case FILE_PREVIEW.settings:
        return (
          <SettingsInfo
            selectedEntity={selectedEntity}
          />
        )
      case FILE_PREVIEW.logs:
        return <HistoryTab entity={selectedEntity} />;
      case FILE_PREVIEW.myAi:
        return <MyAiInfo entity={selectedEntity} />;
      default:
        return '';
    }
  };

  return <div className={styles.metaContainer}>{renderContent()}</div>;
};

const mapStateToProps = (state) => ({
  selectedPreview: selectSelectedFilePreview(state),
});

const mapDispatchToProps = {
  fileUpdateDataEffect,
  selectedEntityClearEffect,
  fileUpdateDataSelectedEntityEffect,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
