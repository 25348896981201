import React from "react";

import styles from './styles.module.scss';

const Input = ({ icon, ...rest }) => {
  
  return (
    <div className={styles.inputWrapper}>
      <div className={styles.inputIcon}>
        {icon}
      </div>
      <input
        className={styles.input}
        {...rest}
      />
    </div>
  )
}

export default Input