import { equals, prop } from 'ramda'

const isEquals = (item1, item2, propName = '') => {
  return (
    equals(item1, item2) || equals(prop(propName, item1), prop(propName, item2))
  )
}

export const removeIfEqual = (
  mainArr = [],
  checkerArray = [],
  propName = ''
) => {
  return mainArr.filter((item) => {
    for (let i = 0; i < checkerArray.length; i++) {
      if (isEquals(item, checkerArray[i], propName)) return false
    }
    return true
  })
}

export default removeIfEqual
