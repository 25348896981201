import React from 'react'

const NFTIcons = ({ color, classNames, width = 16, height = 16 }) => (
  <svg
    className={classNames}
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3564 3.97927L8.4282 1.13397C8.1188 0.955342 7.7376 0.955342 7.4282 1.13397L2.5 3.97927C2.1906 4.15791 2 4.48803 2 4.8453V10.5359C2 10.8932 2.1906 11.2233 2.5 11.4019L7.4282 14.2472C7.7376 14.4259 8.1188 14.4259 8.4282 14.2472L13.3564 11.4019C13.6658 11.2233 13.8564 10.8932 13.8564 10.5359V4.8453C13.8564 4.48803 13.6658 4.15791 13.3564 3.97927ZM8.9282 0.267949C8.3094 -0.0893163 7.54701 -0.0893165 6.9282 0.267949L2 3.11325C1.3812 3.47051 1 4.13077 1 4.8453V10.5359C1 11.2504 1.3812 11.9107 2 12.268L6.9282 15.1132C7.54701 15.4705 8.3094 15.4705 8.9282 15.1132L13.8564 12.268C14.4752 11.9107 14.8564 11.2504 14.8564 10.5359V4.8453C14.8564 4.13077 14.4752 3.47051 13.8564 3.11325L8.9282 0.267949Z"
      fill={color}
    />
    <path
      d="M4 10V6H4.62295L5.598 8.08395V6H6.3347V10H5.73884L4.76379 7.78765V10H4Z"
      fill={color}
    />
    <path
      d="M7.36055 10V6H9.21314V6.55309H8.25434V7.63457H9.06688V8.20247H8.25434V10H7.36055Z"
      fill={color}
    />
    <path
      d="M10.4345 10V6.60741H9.75739V6H12V6.60741H11.3337V10H10.4345Z"
      fill={color}
    />
  </svg>
)

NFTIcons.defaultProps = {
  color: '#4B576C',
  classNames: '',
}

export default NFTIcons
