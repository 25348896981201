// Refactoring №3
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import MainContext from 'store/main/context/main-context';
import {
  generateImageToImageEffect,
  tokenUtilization,
} from 'store/aiGenerator/effects';
import * as AiGeneratorActions from 'store/home/actions/file/ai-generator.action';
import { uploadMultiFileEffectRedux } from 'store/home/effects';
import { extractTokensAction, selectTokenCountState } from 'features/app';
import useNotification from 'utils/hooks/use-notification';
import TokenCostContext from 'store/tokenCost/contexts';
import { TOKEN_COST } from 'config/token-cost';
import { handleNotEnoughTokenModal } from 'features/modals/modal-slice';

import Button, { ButtonTheme } from 'components/Button';
import Preloader from 'components/shared/Preloader';
import { AiGeneratorsErrorModal } from './AiGeneratorsErrorModal';
import SpinnerLoader from './SpinnerLoader';
import { FilterStylesSlider } from 'containers/ai-generator/generatorSidebar/FilterStylesSlider';
import { getPreviewFileBlob } from 'store/home/effects/file/get-preview-file-blob';

import CloseIcon from 'components/svg/close';
import { ReactComponent as Corner } from 'static/assets/svg/ai-generator-corner.svg';
import { ReactComponent as ObtuseCorner } from 'static/assets/svg/ai-generator-obtuse-corner.svg';

import style from './style.module.scss';

type BugReportModalProps = {
  isOpen: boolean;
  onClose: () => void;
  imageAsObj: imageAsObj;
};
export type imageAsObj = {
  mime: string;
  name: string;
  slug: string;
  folderId: string;
  size: number;
};

const aiGetGenerationPhase = (isLoading: boolean, isGeneratedImage: string) => {
  let prompt = 'READY FOR A GENERATION';
  if (isLoading) prompt = ' CREATING...';
  if (isGeneratedImage) prompt = ' GENERATION COMPLETE.';
  return prompt;
};

export default function ImageToImageModal({
  isOpen,
  imageAsObj,
}: BugReportModalProps) {
  const { t } = useTranslation('contextMenu');
  const { name, slug, folderId, size } = imageAsObj;
  const [firstImageBlob, setFirstImageBlob] = useState<{
    imageView: string;
    blob: Blob | null | BlobPart;
  }>({
    imageView: '',
    blob: null,
  });
  const [generatedImage, setGeneratedImage] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<null | File>(null);
  const [isError, setError] = useState({ isError: false, message: '' });
  const [imageFilter, setImageFilter] = useState('');

  const folderIdForUpload = folderId === 'main' ? null : folderId;
  const dispatch = useDispatch();
  const { addNotification } = useNotification();
  const tokenCountRedux = useSelector(selectTokenCountState);

  const { tokenCost } = useContext(TokenCostContext);
  const imageToImageCost = tokenCost.reduce(
    (_, item: { type: number; tokens: number }) => {
      if (TOKEN_COST.AIImage2Image === item.type) {
        return item.tokens;
      }
      return _;
    },
    0
  );

  const onClose = () => {
    dispatch(AiGeneratorActions.endGenerate());
  };

  function resizeImage(blob: Blob, callback: BlobCallback) {
    const resizedSizes = 1024;
    const img = new Image();
    img.src = URL.createObjectURL(blob);
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = resizedSizes;
      canvas.height = resizedSizes;
      if (ctx) ctx.drawImage(img, 0, 0, resizedSizes, resizedSizes);
      canvas.toBlob(callback, 'image/jpeg', 0.8);
    };
  }

  const cancelHandler = useCallback(() => {
    if (isError.isError) {
      setError({ isError: false, message: '' });
      return;
    }
    onClose();
    setFirstImageBlob({
      imageView: '',
      blob: null,
    });
    setGeneratedImage('');
    setLoading(false);
    setFileToUpload(null);
  }, [firstImageBlob, generatedImage, isLoading, fileToUpload, isError]);
  useEffect(() => {
    setLoading(true);
    getPreviewFileBlob({ file: imageAsObj, sendStatistic: false }).then(
      (data) => {
        if (data) {
          resizeImage(data, (resizedImage) => {
            if (resizedImage !== null) {
              const viewImage = URL.createObjectURL(resizedImage);
              setFirstImageBlob({ blob: resizedImage, imageView: viewImage });
              setLoading(false);
            }
          });
        }
      }
    );
  }, [imageAsObj]);

  const {
    state: {
      workspace: { storage },
    },
  } = useContext(MainContext);

  function base64ToBlob(base64String: string, contentType: string) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  const generateImageHandler = async () => {
    if (tokenCountRedux >= imageToImageCost) {
      setLoading(true);
      if (firstImageBlob.blob !== null) {
        const imageAsFile = new File([firstImageBlob.blob], name, {
          type: 'image/png',
        });
        const formData = new FormData();
        formData.append('init_image', imageAsFile);
        formData.append('init_image_mode', 'IMAGE_STRENGTH');
        formData.append('image_strength', 0.35);
        formData.append('cfg_scale', 7);
        formData.append(
          'text_prompts[0][text]',
          `Create a visually striking, high-quality, and uniquely stylish image based on the following prompt: ${name}`
        );
        formData.append('clip_guidance_preset', 'FAST_BLUE');
        formData.append('samples', 1);
        formData.append('steps', 30);
        imageFilter && formData.append('style_preset', imageFilter);
        generateImageToImageEffect(formData)
          .then((data) => {
            tokenUtilization(2).then(() => {
              dispatch(extractTokensAction(imageToImageCost));
            });
            setGeneratedImage(data.imageUrl);
            const blob = base64ToBlob(data.base64Image, 'image/png');
            const imageAsFile = new File([blob], 'generated_by_image.jpg', {
              type: 'image/jpeg',
              lastModified: new Date(),
            });
            // @ts-ignore:next-line
            imageAsFile.folderId = folderIdForUpload;
            // @ts-ignore:next-line
            imageAsFile.mime = imageAsFile.type;
            setFileToUpload(imageAsFile);
          })
          .catch((err) => {
            setError({
              isError: true,
              message: err?.response?.data?.message ?? '',
            });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      dispatch(handleNotEnoughTokenModal(true));
    }
  };

  const saveImageHandler = () => {
    dispatch(
      uploadMultiFileEffectRedux({
        folderData: {},
        files: [fileToUpload],
        folderId: folderIdForUpload,
        storageType: storage,
        addNotification: addNotification(
          t('main.upscale.artWorkSaved'),
          'success'
        ),
        setErrors: false,
        isGeneratedByAi: true,
      })
    );
  };

  const handleGenerationStage = () => {
    const text = aiGetGenerationPhase(isLoading, generatedImage);
    switch (text) {
      case 'READY FOR A GENERATION':
        return t('main.imageToImage.readyForGen');
      case ' CREATING...':
        return t('main.imageToImage.creatingAIModal');
      case ' GENERATION COMPLETE.':
        return t('main.imageToImage.completeGenAIModal');
      default:
        return t('main.imageToImage.readyForGen');
    }
  };

  return (
    <>
      {firstImageBlob || generatedImage ? (
        <Modal
          isOpen={isOpen}
          overlayClassName={style.overlay}
          className={style.modal}
          contentLabel="Example Modal"
          shouldCloseOnOverlayClick
          onRequestClose={cancelHandler}
        >
          <div className={style.modal__wrapper}>
            <button
              type="button"
              className={style.modal__exit}
              onClick={cancelHandler}
            >
              <CloseIcon />
            </button>
            <div className={style.modal__imageToImageContainer}>
              <h2 className={style.modal__title}>
                <span className={style.modal__title__icon}></span>
                <label className={style.modal__title__icon__label}>ai </label>
                {t('main.imageToImage.imageToImage')}
              </h2>
              <div className={style.modal__imageContent}>
                <ObtuseCorner />
                <Corner />
                <Corner />
                <Corner />
                <span className={style.modal__imageContent__textLoader}>
                  <label className={style.modal__title__icon__label}>ai</label>
                  {handleGenerationStage()}
                </span>

                {!isLoading ? (
                  <img
                    className={style.modal__imageContent__image}
                    src={generatedImage || firstImageBlob.imageView}
                    alt={''}
                  />
                ) : (
                  <SpinnerLoader />
                )}
              </div>
              <div className={style.modal__imageFilters}>
                <p className={style.modal__imageFilters__title}>
                  {t('main.imageToImage.chooseImageStyle')}
                </p>
                <FilterStylesSlider
                  imageFilter={imageFilter}
                  setImageFilter={setImageFilter}
                />
              </div>
              <div className={style.modal__buttons}>
                <Button
                  className={`${style.modal__button} ${
                    isLoading && style.modal__button__disabled
                  }`}
                  theme={ButtonTheme.NEW_BORDERRED_DARK}
                  onClick={generateImageHandler}
                  disabled={isLoading}
                  showPreloader={isLoading}
                >
                  {t('main.imageToImage.createArt')}
                </Button>
                <Button
                  className={`${style.modal__button} ${
                    (isLoading || !generatedImage) &&
                    style.modal__button__disabled
                  }`}
                  theme={ButtonTheme.NEW_BORDERRED_DARK}
                  onClick={saveImageHandler}
                  disabled={isLoading || !generatedImage}
                  showPreloader={false}
                >
                  {t('main.imageToImage.save')}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      ) : (
        <Preloader />
      )}
      <AiGeneratorsErrorModal
        isOpen={isError.isError}
        error={isError.message}
        onClose={cancelHandler}
      />
    </>
  );
}
