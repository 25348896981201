import React, { Component } from 'react'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import PopUpsHead from 'components/shared/PopUpsHead'
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect'
import actionsOptions from 'config/actions-options'

import { compose } from 'redux'
import { connect } from 'react-redux'

class EntitySettingsContainer extends Component {
  constructor(props) {
    super(props)
    this.onClosePopup = this.onClosePopup.bind(this)
    this.onGhost = this.onGhost.bind(this)
    this.state = {
      startDate: new Date(),
    }
  }
  handleChange = (date) => {
    this.setState({
      startDate: date,
    })
  }
  onClosePopup() {
    this.props.onClose()
  }
  onGhost() {
    this.props.fileFolderActionHandlerEffect(
      this.props.file,
      actionsOptions.ghostMode
    )
    let shadow = JSON.parse(localStorage.getItem('shadow'))
    if (shadow == null) {
      shadow = []
    }
    shadow.push({
      file: this.props.file,
      date: Date.now() + 24 * 60 * 60 * 1000,
    })
    localStorage.setItem('shadow', JSON.stringify(shadow))
    this.props.onClose()
  }
  render() {
    return (
      <section className="entity-settings asside-popup">
        <PopUpsHead
          beforeTitle={'File Details > '}
          title="Ghost File"
          closePopup={this.onClosePopup}
        />
        <div className="entity-settings__content-wrapper">
          <img
            className="entity-settings__popup-img"
            src={require('static/assets/img/ghostFile.svg').default}
            alt=""
          />
          <p className="entity-settings__popup-title">Ghost File</p>
          <p className="entity-settings__popup-content">
            Select the dates you would like your file to remain hidden for.
            note: once you select the date your files will be unrecoverable
            until contract has been completed
          </p>
          <p className="entity-settings__popup-subtitle">Ghost file to</p>
          <DatePicker
            selected={this.state.startDate}
            onChange={this.handleChange}
            className="input"
          />
          <button
            onClick={this.onGhost}
            title={'CONFIRM'}
            className={'entity-settings__btn'}
          >
            CONFIRM
          </button>
          <button className="entity-settings__popup__cancel">Cancel</button>
        </div>
      </section>
    )
  }
}
const mapDispatchToProps = {
  fileFolderActionHandlerEffect,
}
export default compose(connect(null, mapDispatchToProps))(
  EntitySettingsContainer
)
