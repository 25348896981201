import React from 'react';

const Plus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    {...props}
  >
    <path fill="currentColor" d="M6.5 13.5v-6h-6v-1h6v-6h1v6h6v1h-6v6h-1Z" />
  </svg>
);

Plus.defaultProps = {};

export default Plus;
