import React from 'react';

import Circle from './Circle';
import Dots from './Dots';

const Spinner = ({ type = 'circle' }) => {

  if (type === 'circle')
    return <Circle />

  if (type === 'dot')
    return <Dots />

  return <Circle />
}

export default Spinner