//Refactoring №2
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import { activetedLinkChangeEffect } from 'store/account/effects/edit-user-info.effects';
import { setErrors } from 'store/errors/actions/index';

import Button, { ButtonTheme } from 'components/Button';

import { ReactComponent as MainLogo } from 'static/assets/svg/ghost-drive-main-logo.svg';
import { ReactComponent as SuccessIcon } from 'static/assets/svg/check-link-success-icon.svg';
import { ReactComponent as ErrorIcon } from 'static/assets/svg/check-link-error-icon.svg';

import styles from './style.module.scss';

export default function CheckEmailChangeLinkActivate() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const hash = history.location.pathname.slice(14);

  useEffect(() => {
    activetedLinkChangeEffect(hash)
      .then(() => {
        setIsSuccess(true);
      })
      .catch(() => {
        setIsSuccess(false);
        dispatch(setErrors({}));
      })
      .finally(() => {
        setIsloading(false);
      });
  }, []);

  return (
    <section className={'global__wrapper'}>
      <div className={`site__pages ${styles.checkPageWrapper}`}>
        <Link to="/" className={styles.checkPageWrapper__iconLogo}>
          <MainLogo />
        </Link>
        {!isLoading && (
          <div className={styles.checkPageWrapper__answerContent}>
            {isSuccess ? (
              <>
                <SuccessIcon />
                <div className={styles.checkPageWrapper__answerContent_title}>
                  Success
                </div>
                <div
                  className={styles.checkPageWrapper__answerContent_description}
                >
                  Your email wass successfully changed.{`\n`} You need to Sign
                  in
                </div>
                <Link to="/sign-in">
                  <Button value="Sign in" theme={ButtonTheme.DARK} />
                </Link>
              </>
            ) : (
              <>
                <ErrorIcon />
                <div className={styles.checkPageWrapper__answerContent_title}>
                  Unable to Change email
                </div>
                <div
                  className={styles.checkPageWrapper__answerContent_description}
                >
                  The link is outdated. If you want to change your mail you need
                  to do it again in your
                  <Link to="/account/profile"> profile</Link>.
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </section>
  );
}
