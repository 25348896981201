import React from "react";
import CN from 'classnames';

import styles from '../styles.module.scss';

const alignClass = {
  center: styles.sidebarButtonAlignCenter,
}

export const SidebarButton = ({ children, className, align , ...rest }) => {

  return (
    <button className={CN(styles.sidebarButton, alignClass[align], className)} {...rest}>
      {children}
    </button>
  )
}

export default SidebarButton