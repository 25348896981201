export const MAKE_STRIPE_PAYMENT = 'MAKE_PAYMENT'
export const MAKE_STRIPE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS'
export const MAKE_STRIPE_PAYMENT_FAILURE = 'MAKE_PAYMENT_FAILURE'
export const MAKE_STRIPE_PAYMENT_CLEAR = 'MAKE_PAYMENT_CLEAR'

export function makeStripePayment() {
  return {
    type: MAKE_STRIPE_PAYMENT,
  }
}

export function makeStripePaymentSuccess() {
  return {
    type: MAKE_STRIPE_PAYMENT_SUCCESS,
  }
}

export function makeStripePaymentFailure() {
  return {
    type: MAKE_STRIPE_PAYMENT_FAILURE,
  }
}

export function makeStripePaymentClear() {
  return {
    type: MAKE_STRIPE_PAYMENT_CLEAR,
  }
}
