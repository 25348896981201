import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';

import Button, { ButtonTheme } from 'components/Button';

import useNotification from 'utils/hooks/use-notification';

import { manageKeys } from '../../utils/manage-keys';
import { getEncryptedFileKey } from '../../utils/get-key';

import styles from './styles.module.scss';
import { getDecryptedKey } from 'utils/file/getDecryptedKey';

export const AllowManageKeyPopup = ({
  file,
  onClose,
  goToTokenization,
  isOpen,
}: {
  file: any;
  onClose: () => void;
  goToTokenization: () => void;
  isOpen: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { addNotification } = useNotification();

  const dispatch = useDispatch();

  const onAllowManageKey = async () => {
    if (!file.slug) return;
    setIsLoading(true);
    const key = await getEncryptedFileKey(file, dispatch);
    //@ts-ignore
    const decryptedKey = await getDecryptedKey({ key });
    if (!key) {
      setIsLoading(false);
      //@ts-ignore
      addNotification('Something went wrong', 'alert');
      return;
    }
    //@ts-ignore
    const res = await manageKeys(file.slug, decryptedKey);
    goToTokenization();
    //@ts-ignore
    addNotification(res.data?.message, 'success');
    setIsLoading(false);
  };

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      contentLabel="Access preferences"
      overlayClassName="Overlay"
      shouldCloseOnOverlayClick
    >
      <div className={styles.tooltip}>
        <div className={styles.tooltip_container}>
          <p className={styles.tooltip_text}>
            For tokenizing an encrypted file please allow us to manage file
            encryption key
          </p>
          <div className={styles.tooltip_buttons}>
            <Button
              theme={ButtonTheme.SECONDARY}
              type="button"
              value="Cancel"
              onClick={onClose}
            />
            <Button
              theme={ButtonTheme.PRIMARY}
              type="button"
              disabled={isLoading}
              value={isLoading ? 'Loading...' : 'Continue'}
              onClick={onAllowManageKey}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
