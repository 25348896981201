//Refactoring №3
import React from 'react';

import CN from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as MetamaskIcon } from 'static/assets/svg/metamask-icon.svg';
import { ReactComponent as WalletIcon } from './assets/wallet-icon.svg';
import { ReactComponent as MetamaskDrakIcon } from './assets/metamask-dark.svg';
import { ReactComponent as PhantomIcon } from 'static/assets/svg/phantom-icon.svg';
import { ReactComponent as MailIcon } from './assets/email.svg';
import { ReactComponent as UnstoppableIcon } from 'static/assets/svg/unstoppable-icon.svg';
import { ReactComponent as CoinbaseIcon } from 'static/assets/svg/coinbase-icon.svg';
import { ReactComponent as CoinbaseIconBlue } from 'static/assets/svg/coinbase-icon-blue.svg';
import { ReactComponent as TopLeftCornerIcon } from 'static/assets/svg/corners/TopLeftCutCorner.svg';
import { ReactComponent as TopRightCornerIcon } from 'static/assets/svg/corners/TopRightCorner.svg';
import { ReactComponent as BotLeftCornerIcon } from 'static/assets/svg/corners/BottomLeftCorner.svg';
import { ReactComponent as BotRightCornerIcon } from 'static/assets/svg/corners/BottomRightCorner.svg';

import style from './style.module.scss';

const iconsStandart = {
  metamask: <MetamaskIcon />,
  phantom: <PhantomIcon />,
};
const iconsDark = {
  wallet: <WalletIcon />,
  metamask: <MetamaskDrakIcon />,
  phantom: <PhantomIcon />,
};

const iconsWhite = {
  wallet: <WalletIcon className={style.darkWallet} />,
  mail: <MailIcon className={style.darkWallet} />,
};

export const CryptoButtonTheme = {
  standart: style.containerStandart,
  dark: style.containerDark,
  white: style.containerWhite,
};

export const CryptoButton = ({
  cryptoType,
  unstoppableIconColor = '#ffffff',
  children,
  className,
  selected,
  version = '3',
  id,
  icon,
  type = CryptoButtonTheme.standart,
  ...props
}) => {
  const isStandartTheme = type === CryptoButtonTheme.standart;
  const isWhiteTheme = type === CryptoButtonTheme.white;
  const iconObject = {
    [CryptoButtonTheme.standart]: iconsStandart,
    [CryptoButtonTheme.dark]: iconsDark,
    [CryptoButtonTheme.white]: iconsWhite,
  };

  if (cryptoType === 'unstoppable')
    return (
      <button
        id={id}
        className={CN(type, className, selected && style.selected)}
        {...props}
      >
        <UnstoppableIcon
          color={unstoppableIconColor}
          className={style.unstoppable}
        />
        <span className={style.text}>
          Unstoppable <br />
          domain
        </span>
      </button>
    );
  if (cryptoType === 'coinbase')
    return (
      <button
        id={id}
        className={CN(type, className, selected && style.selected)}
        {...props}
      >
        {isStandartTheme ? <CoinbaseIconBlue /> : <CoinbaseIcon />}
        <span className={style.text}>{children}</span>
        <span className={style.web3}>web 3.0</span>
      </button>
    );

  return (
    <button
      id={id}
      className={CN(type, className, selected && style.selected)}
      {...props}
    >
      {icon || iconObject[type][cryptoType]}
      <span className={style.text}>{children}</span>
      <span className={style.web3}>{`web ${version}.0`}</span>
      {isWhiteTheme && (
        <div className={style.corners}>
          <TopLeftCornerIcon className={style.topLeftCorner} />
          <TopRightCornerIcon className={style.topRightCorner} />
          <BotLeftCornerIcon className={style.botLeftCorner} />
          <BotRightCornerIcon className={style.botRightCorner} />
        </div>
      )}
    </button>
  );
};

CryptoButton.propTypes = {
  cryptoType: PropTypes.oneOf([
    'metamask',
    'phantom',
    'unstoppable',
    'coinbase',
    'wallet',
    'mail',
  ]),
  className: PropTypes.string,
  children: PropTypes.any,
};

CryptoButton.defaultProps = {
  cryptoType: 'metamask',
  className: '',
  children: '',
};
