// Refactoring №3
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import AccountInput from '../../../components/AccountInput/account-input';

import s from './style.module.scss';

export const DEFAULT_ERROR_MESSAGES = {
  email: 'Enter a valid email',
  password: 'Password is not secure',
  username: 'Enter name',
};
export const validations = [
  'hasMinLength',
  'hasLowCaseCharacter',
  'hasUpperCaseCharacter',
  'hasDigits',
];
export const minCharsLength = 8;

export const SignInModal = ({
  onSignInHandler,
  fieldData,
  onInputChange,
  handleKeyDown,
  currentErrors,
  errorMessages,
}) => {
  const { t } = useTranslation('auth');
  return (
    <>
      <main className={s.authWrapper}>
        <div className={s.authContent}>
          <div className={s.authContent__title}>{t('common.welcomeBack')}</div>
          <AccountInput
            label={t('common.email')}
            value={fieldData.email}
            placeholder={t('common.yourEmail')}
            name="email"
            type="text"
            onChange={onInputChange}
            onBlur={onInputChange}
            onKeyDown={handleKeyDown}
            hasError={currentErrors.email}
            errorMsg={errorMessages.email}
          />
          <AccountInput
            label={t('signup.password')}
            value={fieldData.password}
            placeholder="******"
            name="password"
            type="password"
            isPassword
            autoComplete="new-password"
            onChange={onInputChange}
            onBlur={onInputChange}
            onKeyDown={handleKeyDown}
            hasError={currentErrors.password}
            errorMsg={errorMessages.password}
          />
          <a href="/forgot-password" className={s.authContent__forgot}>
            {t('common.forgotPassword')}
          </a>
          <button
            type="button"
            className={s.authContent__login}
            onClick={onSignInHandler}
          >
            {t('common.login')}
          </button>
        </div>
        <div className={s.authFooter}>
          <span>{t('common.dontHaveAccount')}</span>
          <Link to="/sign-up" className={s.authFooter__link}>
            {t('signup.signup')}
          </Link>
        </div>
      </main>
    </>
  );
};
