import React from "react";
import CN from 'classnames';

import styles from "./styles.module.scss";

export const BUTTON_TYPE = {
  submit: 'SUBMIT',
  cancel: 'CANCELL',
}

type IProps = {
  type?: string,
  className?: string,
  children?: any,
  disabled?: boolean,
  onClick?: (e: any) => void,
}

const Button = ({ type = BUTTON_TYPE.submit, className, children,  disabled,...rest }: IProps) => {

  if (type ===  BUTTON_TYPE.cancel) {
    return (
      <button
        className={CN(styles.cancelBtn, className)}
        disabled={disabled}
        {...rest}
      >
        <span className={styles.borderTopRadius} />
        { children }
      </button>
    )
  }

  return (
    <>
      <button
        className={CN(styles.submitBtn, className)}
        disabled={disabled}
        {...rest}
      >
        <span className={styles.borderTopRadius} />
        { children }
        <span className={styles.borderBottomRadius} />
      </button>
    </>

  )
}

export default Button;