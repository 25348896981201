import authRequest from 'utils/request/auth-request';
import { API_GET_FILE } from 'constants/api-urls';

export default async (folderId) => {
  const url = `${API_GET_FILE}/file/${folderId}`;

  return authRequest
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};

export const getFolderInfoEffect = async (slug) => {
  const url = `${API_GET_FILE}/folder/${slug}/info`;

  return authRequest
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      throw response;
    });
};
