import React, { useState } from "react";

import SidebarInput from "../SidebarInput";
import SidebatButton from "../SidebatButton";

import styles from './styles.module.scss';

const CreateForm = ({ onCreate }) => {
  const [name, setName] = useState('');

  const onChnage = ({ target: { value } }) => {
    setName(value);
  }

  const onCreateHandler = () => {
    onCreate(name)
  }

  return (
    <div className={styles.apiForm}>
      <SidebarInput
        placeholder='Name'
        value={name}
        onChnage={onChnage}
      />
      <SidebatButton
        disabled={!name}
        onClick={onCreateHandler}
      >
        Create
      </SidebatButton>
    </div>
  )
}

export default CreateForm;