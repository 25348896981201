import * as uploadActions from 'store/home/actions/upload-file.actions.js';
import {
  encryptExistingFile,
  handleEncryptFileError,
  updateFileProperty,
} from 'store/home/actions';
import {
  closeEncryptionModal,
  changeEncryptionStatus,
} from 'store/home/actions/file/encryption-key.action';

import { IFile } from 'interfaces/file-folder';

const encryptExistingFileCallback = ({
  file,
  size,
  dispatch,
}: {
  file: IFile | any;
  size: number;
  dispatch: any;
}) => {
  dispatch(handleEncryptFileError(undefined));
  dispatch(uploadActions.uploadFile({ ...file, size }));
  dispatch(encryptExistingFile(true));
};

const catchErrorCallback = ({
  slug,
  dispatch,
}: {
  slug: string;
  dispatch: any;
}) => {
  dispatch(handleEncryptFileError(slug));
  dispatch(encryptExistingFile(undefined));
};

const updateFilePropertyCallback = ({
  isCancelModalOpen,
  response,
  dispatch,
}: {
  isCancelModalOpen: any;
  response: any;
  dispatch: any;
}) => {
  !isCancelModalOpen && dispatch(encryptExistingFile(undefined));

  dispatch(
    updateFileProperty(
      response.data.slug,
      'is_clientside_encrypted',
      response.data.is_clientside_encrypted
    )
  );
  dispatch(
    updateFileProperty(
      response.data.slug,
      'entry_clientside_key',
      response.data.entry_clientside_key
    )
  );
};

const updateProgressCallback = ({
  id,
  progress,
  timeLeft,
  dispatch,
}: {
  id: string;
  progress: string | number;
  timeLeft: string | number;
  dispatch: any;
}) => {
  dispatch(
    uploadActions.uploadChangeProgress({
      progress,
      id: id,
    })
  );
  dispatch(
    uploadActions.uploadChangeSecondsLeft({
      timeLeft,
      id: id,
    })
  );
};

export const updateDownloadProgressCallback = () => {
  return;
};

const successfullyDecryptedCallback = ({ dispatch }: { dispatch: any }) => {
  dispatch(changeEncryptionStatus(false));
  dispatch(closeEncryptionModal(true));
};

export const encodeFileData = {
  callbacks: {
    onStart: encryptExistingFileCallback,
    onSuccess: updateFilePropertyCallback,
    onError: catchErrorCallback,
    onProgress: updateProgressCallback,
  },
  handlers: ['onStart', 'onSuccess', 'onError', 'onProgress'],
};

export const downloadFileData = {
  callbacks: {
    onSuccess: successfullyDecryptedCallback,
    onProgress: updateDownloadProgressCallback,
  },
  handlers: ['onSuccess', 'onProgress'],
};
