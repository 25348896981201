import { AxiosResponse } from 'axios';
import authRequest from 'utils/request/auth-request';

export const getKeysByWorkspace = (): AxiosResponse<{
  keys: Array<string>;
  usersMissedCount: number;
}> => {
  // @ts-ignore
  return authRequest.get(
    `${process.env.REACT_APP_API_PATH}/keys/get_workspace`
  );
};
