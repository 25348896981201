// libs
import { combineReducers } from 'redux'

// reducers
import paymentUserData from './payment-user-data.reducer'
import getStripeData from './get-stripe-data.reducers'
import makeStripePayment from './make-stripe-payment.reducer'

export const paymentReducer = combineReducers({
  paymentUserData,
  getStripeData,
  makeStripePayment,
})
