// Refactoring №3
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import Button, { ButtonTheme } from 'components/Button';
import { CryptoButton } from 'containers/auth/components/CryptoButton';

import { ReactComponent as LoadingIcon } from 'static/assets/svg/loading-icon.svg';
import CloseIcon from 'components/svg/close';

import s from './style.module.scss';

export default function AddWalletModal({
  isOpen,
  close,
  clickMetamaskOrCoinbase,
  clickUnstoppable,
  isWaiting,
}) {
  const { t } = useTranslation('account');
  const [selectedWallet, setSelectedWallet] = useState('');
  const connectToWalletHandler = () => {
    switch (selectedWallet) {
      case 'metamask':
      case 'coinbase':
        clickMetamaskOrCoinbase(selectedWallet);
        break;

      case 'unstoppable':
        clickUnstoppable();
        break;
      default:
        clickMetamaskOrCoinbase();
    }
  };
  const selectWalletHandler = (e) => {
    e.preventDefault();
    setSelectedWallet(e.currentTarget.id);
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={s.overlay}
      className={s.modal}
      contentLabel="Example Modal"
    >
      <div className={s.modal__wrapper}>
        <button type="button" className={s.modal__exit} onClick={close}>
          <CloseIcon color="#4B576C" />
        </button>
        <div className={s.modal__addWalletModalContent}>
          <h2 className={s.modal__title}>{t('Profile.addWallet')}</h2>
          <div className={s.modal__extensionItemsWrapper}>
            <CryptoButton
              id="metamask"
              className={s.loginItem}
              cryptoType="metamask"
              data-test="sign-in-container_log-metamask_button"
              onClick={selectWalletHandler}
              selected={selectedWallet === 'metamask'}
            >
              Metamask
            </CryptoButton>
            <CryptoButton
              id="unstoppable"
              className={s.loginItem}
              unstoppableIconColor="#0D67FE"
              cryptoType="unstoppable"
              onClick={selectWalletHandler}
              selected={selectedWallet === 'unstoppable'}
            />
            <CryptoButton
              id="coinbase"
              className={s.loginItem}
              cryptoType="coinbase"
              onClick={selectWalletHandler}
              selected={selectedWallet === 'coinbase'}
            >
              Coinbase
            </CryptoButton>
          </div>
          <div className={s.modal__buttons}>
            <Button
              value={t('Profile.cancel')}
              theme={ButtonTheme.TRANSPARENT}
              onClick={close}
            />
            <Button
              theme={ButtonTheme.DARK}
              type="button"
              disabled={isWaiting}
              value={
                !isWaiting ? (
                  t('Profile.connect')
                ) : (
                  <div className={s.preload}>
                    <LoadingIcon />
                  </div>
                )
              }
              onClick={connectToWalletHandler}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
