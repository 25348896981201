import * as saveUploadActions from '../../actions/save-upload-file.actions';
import * as UploadFileActions from '../../actions/upload-file.actions';
import * as getFilesActions from '../../actions/files/get-files.actions';
import authRequest from 'utils/request/auth-request';
import getMimeType from 'utils/files-folders/get-mime-type';
import { API_SAVE_UPLOAD_FILES } from 'constants/api-urls';

export function saveUploadFileEffect(fileData, folderId) {
  return async (dispatch) => {
    dispatch(saveUploadActions.saveUploadFile());
    const url = API_SAVE_UPLOAD_FILES;

    try {
      const { bucket, etag, extension, key, location, name, service, size } =
        fileData.file;
      const result = await authRequest.post(url, {
        bucket,
        etag,
        extension,
        folder: folderId,
        key,
        location,
        mime: getMimeType(fileData.file),
        name,
        service,
        size,
      });

      dispatch(saveUploadActions.saveUploadFileSuccess());
      dispatch(UploadFileActions.addSlug(fileData.uploadId, result.data.data));
      //dispatch(uploadActions.filterFiles(fileData.uploadId))
      dispatch(
        getFilesActions.getFilesAdd({
          ...result.data.data,
          folderId: folderId ?? 'main',
        })
      );
    } catch (e) {
      dispatch(saveUploadActions.saveUploadFileFail(e));
    }
  };
}

export function saveUploadFileEffectGhostIpfs(result, folderId, thumbnail = undefined) {
  return async (dispatch) => {
    dispatch(saveUploadActions.saveUploadFile());
    try {
      dispatch(saveUploadActions.saveUploadFileSuccess());
      dispatch(
        UploadFileActions.addSlug(result.data.data.hash, result.data.data)
      );

      if (!(window.location.pathname === '/main' && folderId)) {
        dispatch(
          getFilesActions.getFilesAdd({
            ...result.data.data,
            thumbnail,
            folderId: folderId ?? 'main',
          })
        );
      }
    } catch (e) {
      dispatch(saveUploadActions.saveUploadFileFail(e));
    }
  };
}
