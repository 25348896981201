/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
//Refactoring №3
import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useCallback,
  useMemo,
} from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { matchPath } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import UserContext from 'store/home/contexts/user-context';
import RightMenuContext from 'store/home/contexts/right-menu';
import EntitySettings from 'store/home/contexts/entity-settings';
import { tagsGetEffect } from 'store/tags/effects';
import TagContext from 'store/tags/contexts/tags';
import { getTokenCostEffect } from 'store/tokenCost/effects';
import TokenCostContext from 'store/tokenCost/contexts';
import { colorsGetEffect } from 'store/colors/effects';
import ColorContext from 'store/colors/contexts/colors';
import reducer, { initiateState, defaultState } from 'store/main/reducers';
import MainContext from 'store/main/context/main-context';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import {
  setNotifications,
  updateNotification,
} from 'store/notifications/actions';
import getNotificationEffect from 'store/notifications/effects/notifications-get.effect';
import { updateNotificationAdditionalData } from 'store/notifications/effects/notification-settings.effect';
import { workspaceSetFileCountByType } from 'features/workspaceSettings/workspaceSettingsSlice';
import { selectTechnicalWorksState } from 'features/app';

import USER_ROLE_TYPES from 'config/user-role-types';
// import renderRoutes from 'utils/routing/renderRoutes';
import { notificationChecker } from 'utils/notifications';
import useWindowSize from 'utils/hooks/use-window-size';
import { getWorkspaceFileCount } from 'store/workspace/effects';
import { isMobile } from 'utils/mobile';

import Content from './Content';
import TapeOfTechnicalWorks from 'components/TapeOfTechnicalWorks';
import { onChangeWidth } from 'features/leftMenu/leftMenuSlice';

import MultyUploadDetails from 'components/home/multy-upload-details/index';
import IconnectNotification from 'components/home/iconnect-notification';
import NotificationBubble from 'containers/notifications/notification-bubble';
import { getMultisigAction, deletMultisigAction } from 'utils/web3Helper';
import { saveWorkspaceTransaction } from 'store/web3';
import getToken from 'utils/auth/get-token';

const SIDEBAR_WIDTH_MAX = 240;
const NOTIFICATION_CHECKER_TIME = 30000;

const Home = ({
  userRedux,
  selectedEntity,
  fileFolderActionHandlerEffect,
  updateNotification,
  activeOption,
  history,
  unreadNotifications,
  uploadDisplayed,
  downloadStatus,
  match,
  uploadingFiles,
}) => {
  const [state, dispatch] = useReducer(reducer, defaultState, initiateState);
  const dispatchRedux = useDispatch();
  const [showRightMenu, setShowRightMenu] = useState(false);
  const [rightMenuProps, setRightMenuProps] = useState({});
  const [allTags, setAllTags] = useState([]);
  const [tokenCost, setTokenCost] = useState([]);
  const [colors, setColors] = useState([]);
  const [showEntitySettings, setShowEntitySettings] = useState(true);
  const [propsEntitySettings, setPropsEntitySettings] = useState({});
  const [user, setUser] = useState(userRedux);
  const dropUploaderRef = useRef(null);
  const location = useLocation();
  const isAccountPage = useRouteMatch('/account/:slug');
  const isWorkspacePage = useRouteMatch('/workspace/:slug');
  const isAuthenticated = !!getToken();
  const isFolderPage = useRouteMatch('/folder/:folderId');

  const showSidebar = useMemo(
    () =>
      !matchPath(location.pathname, {
        path: '/editor/:fileId',
      }) &&
      !isAccountPage &&
      !isWorkspacePage,
    [location]
  );
  const initialJoinNotification = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return !!params.get('token') && !params.get('payment');
  }, [location]);

  const [showJoinNotification, setShowJoinNotification] = useState(
    initialJoinNotification
  );
  const showComponentWithoutLayout = false;
  const isGuest = useMemo(
    () =>
      isFolderPage && !isAuthenticated
        ? false
        : user.role === USER_ROLE_TYPES.GUEST,
    [user]
  );
  window.mainContextDispatch = dispatch;
  useEffect(() => {
    if (isAuthenticated) {
      tagsGetEffect().then((tags) => setAllTags(tags));
      colorsGetEffect().then((colors) => setColors(colors));
      getWorkspaceFileCount().then((data) => {
        dispatchRedux(workspaceSetFileCountByType(data));
      });
      getTokenCostEffect().then((data) => {
        setTokenCost(data);
      });
      getNotificationEffect(1).then((data) => {
        dispatchRedux(setNotifications(data));
      });
      const multisigAction = getMultisigAction();
      if (multisigAction) {
        if (multisigAction.notification) {
          saveWorkspaceTransaction({
            chainId: multisigAction.chainId,
            txHash: multisigAction.txHash,
          }).then(() => {
            updateNotificationAdditionalData(
              multisigAction.notification.id,
              multisigAction.notification.additionalReq
            ).then((notification) => {
              updateNotification(multisigAction.notification.id, notification);
              deletMultisigAction();
            });
          });
        } else {
          saveWorkspaceTransaction(multisigAction).then(() => {
            deletMultisigAction();
          });
        }
      }
    }
  }, []);

  const recTimeout = useCallback(() => {
    window.timeout = setTimeout(() => {
      notificationChecker(unreadNotifications, dispatchRedux);
      recTimeout();
    }, NOTIFICATION_CHECKER_TIME);
  }, [unreadNotifications]);

  useEffect(() => {
    clearTimeout(window.timeout);
    if (isAuthenticated) {
      recTimeout();
    }

    return () => {
      clearTimeout(window.timeout);
    };
  }, [unreadNotifications]);

  useEffect(() => {
    if (activeOption?.type === 'goToSecuritySettings') {
      setShowEntitySettings(true);
    }
  }, [activeOption]);

  const initialSizes = [
    SIDEBAR_WIDTH_MAX,
    window.innerWidth - SIDEBAR_WIDTH_MAX,
  ];
  const [currentSizes, setCurrentSizes] = useState(initialSizes);
  const [collapsedSizes, setCollapsedSizes] = useState([
    isMobile ? SIDEBAR_WIDTH_MAX : null,
    null,
  ]);

  const windowSize = useWindowSize();

  useEffect(() => {
    const sidebarWidth = currentSizes[0];
    const contentWidth = window.innerWidth - sidebarWidth;
    setCurrentSizes([sidebarWidth, contentWidth]);
    dispatchRedux(onChangeWidth(sidebarWidth));
  }, [windowSize]);

  const handleCloseModal = () => {
    setShowJoinNotification(false);
  };

  if (isFolderPage && !isAuthenticated) {
    window.location.href = '/';
    return null;
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <MainContext.Provider value={{ state, dispatch, dropUploaderRef }}>
        <UserContext.Provider value={{ user, setUser }}>
          <TokenCostContext.Provider value={{ tokenCost }}>
            <EntitySettings.Provider
              value={{
                entity: selectedEntity,
                show: showEntitySettings,
                toggleSettings: setShowEntitySettings,
                props: propsEntitySettings,
                setProps: setPropsEntitySettings,
              }}
            >
              <ColorContext.Provider
                value={{ colors, updateColors: setColors }}
              >
                <TagContext.Provider
                  value={{ tags: allTags, updateTags: setAllTags }}
                >
                  <RightMenuContext.Provider
                    value={{
                      props: rightMenuProps,
                      setProps: setRightMenuProps,
                      show: showRightMenu,
                      toggleMenu: setShowRightMenu,
                    }}
                  >
                    <TapeOfTechnicalWorks />
                    <Content />
                    {!isGuest &&
                      !downloadStatus &&
                      uploadDisplayed &&
                      uploadingFiles.length > 0 && (
                        <MultyUploadDetails
                          history={history}
                          dispatch={dispatchRedux}
                        />
                      )}
                    {showJoinNotification && (
                      <IconnectNotification
                        open={showJoinNotification}
                        onClose={handleCloseModal}
                      />
                    )}
                    <NotificationBubble />
                  </RightMenuContext.Provider>
                </TagContext.Provider>
              </ColorContext.Provider>
            </EntitySettings.Provider>
          </TokenCostContext.Provider>
        </UserContext.Provider>
      </MainContext.Provider>
    </DndProvider>
  );
};

const mapStateToProps = (state) => ({
  activeOption: state.home?.entitiesSecurityCheck?.actionType,
  selectedEntity: state.home?.selectedEntity?.entity,
  userRedux: state.home?.userInfo?.user,
  unreadNotifications: state.notifications.unreadNotifications,
  isTechnicalWorks: selectTechnicalWorksState(state),
  downloadStatus: state.home?.fileDownload?.status,
  uploadingFiles: state.home?.uploadFiles?.entities,
  uploadDisplayed: state.home?.uploadFiles?.displayed,
});

const mapDispatchToProps = {
  fileFolderActionHandlerEffect,
  updateNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
