/* eslint-disable no-unused-vars */
//Refactoring №2
import React, { useState, useRef, useMemo } from 'react';
import RSC from 'react-scrollbars-custom';
import CN from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isiOSPlatform } from 'utils/browser';

import MetaTab from './MetaTab';
import HistoryTab from './HistoryTab';
import Tags from './MetaTab/tags';
import Colors from './MetaTab/colors';
import CommentTab from 'containers/main/EntitySettingsContainer/comments-tab';
// import EventsTab from './EventsTab';
import ShareTab from './ShareTab';
import MetaInfo from './MetaInfo';

import styles from './style.module.scss';
import {
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
} from 'features/app';

const InfoTab = ({
  entity,
  tokenizationData,
  entityStatistic,
  setActiveTab,
  setInitialOption,
  entityUpdateStoreEffect,
  workspaceMembers,
  hideUsers,
  hideColors,
  hideTags,
}) => {
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);
  const { t } = useTranslation('owner');
  const [activePage, setActivePage] = useState('info');
  const isiOS = isiOSPlatform();
  const userPopupRef = useRef(null);

  const handlePageTabsClick = (page) => () => {
    setActivePage(page);
  };

  const entityUpdateColor = (data) => {
    const nextData = {
      ...entity,
      color: data?.color ? data.color : entity.color,
    };
    entityUpdateStoreEffect(nextData);
  };

  const tabs = (
    <nav className={styles.tabs}>
      <button
        type="button"
        className={CN(styles.tab, activePage === 'info' && styles.tabActive)}
        onClick={handlePageTabsClick('info')}
        data-test={`info-tab_meta_button${
          activePage === 'info' ? '_active' : ''
        }`}
      >
        {t('rightFileMenu.meta.meta')}
      </button>
      <button
        type="button"
        disabled={isMultisigActivated && !isMultisigPartisipant}
        className={CN(styles.tab, activePage === 'sharing' && styles.tabActive)}
        onClick={handlePageTabsClick('sharing')}
        data-test={`info-tab_sharing_button${
          activePage === 'sharing' ? '_active' : ''
        }`}
      >
        {t('common.share')}
      </button>
      <button
        type="button"
        className={CN(styles.tab, activePage === 'comment' && styles.tabActive)}
        onClick={handlePageTabsClick('comment')}
        data-test={`info-tab_events_button${
          activePage === 'comment' ? '_active' : ''
        }`}
      >
        {t('rightFileMenu.comment.comments')}
      </button>
      <button
        type="button"
        className={CN(styles.tab, activePage === 'history' && styles.tabActive)}
        onClick={handlePageTabsClick('history')}
        data-test={`info-tab_history_button${
          activePage === 'history' ? '_active' : ''
        }`}
      >
        {t('common.logs')}
      </button>
    </nav>
  );

  const infoContent =
    activePage === 'info' ? (
      <MetaTab
        entity={entity}
        entityStatistic={entityStatistic}
        hideUsers={hideUsers}
        userPopupRef={userPopupRef}
        workspaceMembers={workspaceMembers}
      />
    ) : null;

  const shareContent =
    activePage === 'sharing' ? (
      <ShareTab
        entity={entity}
        entityStatistic={entityStatistic}
        setActiveTab={setActiveTab}
        setInitialOption={setInitialOption}
        hideUsers={hideUsers}
        userPopupRef={userPopupRef}
        workspaceMembers={workspaceMembers}
        tokenizationData={tokenizationData}
      />
    ) : null;

  const tagsContent =
    activePage === 'info' && !hideTags ? (
      <Tags tags={entity.tags} entityId={entity.slug} />
    ) : null;

  const isFolder = useMemo(() => entity.type === 2, [entity.type]);

  const colorsContainer =
    activePage === 'info' && !hideColors ? (
      <Colors
        selectedEntity={entity}
        colors={entity.color}
        entityId={entity.slug}
        isFolder={isFolder}
        entityUpdateStoreEffect={entityUpdateColor}
      />
    ) : null;

  const historyContent =
    activePage === 'history' ? <HistoryTab entity={entity} /> : null;

  const actionContent =
    activePage === 'comment' ? <CommentTab entity={entity} hideTitle /> : null;

  return (
    <>
      <div className={styles.content}>
        <MetaInfo
          {...entityStatistic}
          shares={entity.shares?.filter((share) => share.type === 3).length}
        />
        {/* <Preview entity={entity} entityView={entityView} /> */}
      </div>
      <div className={styles.tabs}>{tabs}</div>
      <div className="entity-settings__content user-popup-position-container">
        {activePage === 'info' && (
          <RSC
            className={CN(
              'entity-settings__scrollbars-custom',
              isiOS && 'entity-settings-isiOS'
            )}
            thumbYProps={{
              style: { background: '#206ACF', width: '1px', height: '60px' },
            }}
            trackYProps={{ style: { background: 'none', width: '1px' } }}
          >
            {infoContent}
            {tagsContent}
            {colorsContainer}
          </RSC>
        )}
        {shareContent}
        {historyContent}
        {actionContent}
        <div className="user-popup-portal" ref={userPopupRef} />
      </div>
    </>
  );
};

export default InfoTab;
