import React from 'react'
import Card from 'components/Card'
import cn from 'classnames'
import s from './style.module.css'

export default function InviteSent({ className, name, error = false }) {
  return (
    <Card className={cn(s.wrapper, error && s.error, className)}>
      <img src={require('static/assets/img/envelope.png').default} alt="" />
      <h1 className={s.title}>
        {error ? `Something went wrong.` : `Thank You, ${name}.`}
      </h1>
      <p className={s.message}>
        {error
          ? `Please, try again later`
          : `
        After checking your data, you will receive a link to register in our
        service. It will take some time.
        `}
      </p>
    </Card>
  )
}
