import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import CN from 'classnames';

import { getFileImagePreview } from 'store/file/effects';
import { selectTokenCountState } from 'features/app';
import { getFolderFileSlug } from 'store/folder';
import { ReactComponent as PlusIcon } from 'containers/main/NeyraDriveContainer/components/FileInfoSidebar/assets/plus.svg';
import CustomFolderIcon from 'components/CustomFileIcon/CustomFolderIcon';
import CustomFileIcon from 'components/CustomFileIcon/CustomFileIcon';

import { isFile, isFileHavePreviewV2 } from 'utils/file';
import { formatNumberToTokens } from 'utils/string';

import styles from './styles.module.scss';

const initialState = {
  modal: false,
  loading: false,
  preview: '',
  folder: {
    entities: 0,
    images: [],
  },
};

const ProgressWidget = ({ selectedEntity }) => {
  const [state, setState] = useState(initialState);
  const { t } = useTranslation('owner');
  const tokenCountRedux = useSelector(selectTokenCountState);

  const handleUpdatePreview = (data) => {
    setState({
      ...state,
      folder: initialState.folder,
      loading: false,
      preview: data,
    });
  };

  const handleUpdateFolderPreview = (data) => {
    setState({ ...state, preview: '', loading: false, folder: data });
  };

  useEffect(() => {
    if (selectedEntity.slug) {
      setState({ ...state, loading: true });
      if (isFile(selectedEntity)) {
        const isHavePreview = isFileHavePreviewV2(selectedEntity);
        if (isHavePreview) {
          getFileImagePreview(selectedEntity, handleUpdatePreview);
        } else {
          setState({ ...state, preview: '', loading: false });
        }
      } else {
        getFolderFileSlug(selectedEntity, handleUpdateFolderPreview);
      }
    }
  }, [selectedEntity.slug]);

  const handleModal = () => {
    setState({ ...state, modal: !state.modal });
  };

  const onRemixFormSubmit = (data) => {
    alert(JSON.stringify(data, null, 2));
  };

  return (
    <div
      className={CN(
        styles.progress,
        !!state.folder.entities && styles.progressInitial
      )}
    >
      <div className={styles.progressHeader}>
        <h1 className={styles.progressHeaderTitle}>
          {t('rightFileMenu.meta.progress')}
        </h1>
        <button onClick={handleModal} className={styles.progressHeaderButoon}>
          <PlusIcon />
        </button>
      </div>
      <div className={styles.progressContent}>
        {!state.laoding &&
          (state.preview ? (
            <img src={state.preview} alt="Preview" />
          ) : isFile(selectedEntity) ? (
            <CustomFileIcon
              extension={selectedEntity.extension}
              color={selectedEntity?.color?.length && selectedEntity?.color[0]?.hex}
              dateCreated={selectedEntity?.created_at}
            />
          ) : (
            <CustomFolderIcon
              sidebar
              viewType="square"
              color={selectedEntity?.color?.length && selectedEntity?.color[0]?.hex}
              folderEntities={state.folder.entities}
              folderImages={state.folder.images}
            />
          ))}
      </div>
      <div className={styles.progressFooter}>
        <div className={styles.progressFooterItem}>
          <p className={styles.progressFooterItemTitle}>0</p>
          <span className={styles.progressFooterItemText}>
            {t('rightFileMenu.meta.generated')}
          </span>
        </div>
        <div className={styles.progressFooterItem}>
          <p className={styles.progressFooterItemTitle}>0</p>
          <span className={styles.progressFooterItemText}>
            {t('rightFileMenu.meta.remixes')}
          </span>
        </div>
        <div className={styles.progressFooterItem}>
          <p className={styles.progressFooterItemTitle}>0</p>
          <span className={styles.progressFooterItemText}>
            {t('rightFileMenu.meta.tokens')}
          </span>
        </div>
        <div className={styles.progressFooterItem}>
          <p className={styles.progressFooterItemTitle}>0</p>
          <span className={styles.progressFooterItemText}>
            {t('rightFileMenu.meta.spend')}
          </span>
        </div>
      </div>
      {state.modal && (
        <RemixForm
          onSubmit={onRemixFormSubmit}
          total={formatNumberToTokens(
            Math.round(parseFloat(tokenCountRedux)) || 0
          )}
        />
      )}
    </div>
  );
};

const RemixForm = ({ onSubmit, total }) => {
  const { t } = useTranslation('owner');
  const formik = useFormik({
    initialValues: {
      deposit: 10,
      goal: '',
    },
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <form className={styles.remixForm} onSubmit={formik.handleSubmit}>
      <div className={styles.remixFormRow}>
        <label htmlFor="deposit">{t('rightFileMenu.meta.deposit')}</label>
        <p>
          {t('rightFileMenu.meta.balance')}: {`${total}`}
        </p>
      </div>
      <input
        id="deposit"
        name="deposit"
        type="number"
        min={0}
        required
        onChange={formik.handleChange}
        value={formik.values.deposit}
      />
      <label htmlFor="goal">{t('rightFileMenu.meta.goal')}</label>
      <input
        id="goal"
        name="goal"
        type="text"
        required
        placeholder={t('rightFileMenu.meta.addTask')}
        onChange={formik.handleChange}
        value={formik.values.goal}
      />
      <button type="submit">{t('rightFileMenu.meta.start')}</button>
    </form>
  );
};

export default ProgressWidget;
