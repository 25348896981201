import axios from 'axios';
import { removeFromCookies } from 'utils/cookies';
import { getAccessToken } from './get-token';

export default async function () {
  const token = await getAccessToken();
  axios
    .create({
      headers: {
        'X-Token': token,
      },
    })
    .delete(`${process.env.REACT_APP_API_PATH}/users/logout`);

  localStorage.removeItem('localSignature');
  removeFromCookies('access_token');
  removeFromCookies('refresh_token');

  removeFromCookies('access_token', `.${window.location.hostname}`);
  removeFromCookies('refresh_token', `.${window.location.hostname}`);
}
