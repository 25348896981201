export const ENCRYPTION_KEY_WRONG = 'ENCRYPTION_KEY_WRONG';
export const CLOSE_ENCRYPTION_MODAL = 'CLOSE_ENCRYPTION_MODAL';
export const CHANGE_ENCRYPTION_STATUS = 'CHANGE_ENCRYPTION_STATUS';

export function encryptionKeyError(data) {
  return {
    type: ENCRYPTION_KEY_WRONG,
    payload: data,
  };
}

export function closeEncryptionModal(bool) {
  return {
    type: CLOSE_ENCRYPTION_MODAL,
    payload: bool,
  };
}

export function changeEncryptionStatus(bool) {
  return {
    type: CHANGE_ENCRYPTION_STATUS,
    payload: bool,
  };
}
