// Refactoring №4
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import printJS, { PrintTypes } from 'print-js';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CarReader } from '@ipld/car';
import { downloadFile, getCidLevelByFileSize } from 'gdgateway-client/lib/es5';

import imageSupportedFormats, {
  imageMediaTypes,
} from 'config/image-file-extensions';
import { docMediaTypes } from 'config/docs-file-extensions';

import FileView from '../../main/FileView/file-view';
import Header from '../../header/header-container/no-auth-header';
import { FileContainer } from '../../shared/file-container';
import { File } from 'containers/share-by-link-nft';

import downloadFileEffect from 'store/home/effects/file/download-file.effect';
import { getDownloadOTT } from 'store/home/effects/files-upload/upload-file.effect';
import { getFileCids } from 'store/home/effects/file/get-file-cid';

import { downloadFileData } from 'utils/file/library-callbacks';

import style from './style.module.scss';

interface SharedNftFileProps {
  file: File;
  modalIsOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  fileContent: null | string;
}

const SharedNftFile = ({
  file,
  modalIsOpen,
  openModal,
  closeModal,
}: SharedNftFileProps) => {
  const [viewType, setViewType] = useState('square');
  const dispatch = useDispatch();
  const canBePrinted =
    imageSupportedFormats.includes(file?.extension) ||
    file?.extension === '.pdf' ||
    file?.extension === '.html';

  const downloadFileLocal = async () => {
    dispatch(
      downloadFileEffect(file, undefined, undefined, undefined, dispatch)
    );
  };
  const printFile = async () => {
    const { handlers, callbacks } = downloadFileData;
    let cidData;

    const callback = ({ type, params }: any) => {
      if (handlers.includes(type)) {
        // @ts-ignore
        callbacks[type]({ ...params, dispatch });
      } else {
        console.error(`Handler "${type}" isn't provided`);
      }
    };
    const {
      data: {
        jwt_ott,
        user_tokens: { token: oneTimeToken },
        gateway,
        upload_chunk_size,
      },
    } = await getDownloadOTT([{ slug: file.slug }]);
    if (file?.is_on_storage_provider) {
      const level = getCidLevelByFileSize(file);
      cidData = await getFileCids({ slug: file.slug, level });
    }
    const controller = new AbortController();
    const blob = await downloadFile({
      // @ts-ignore
      file,
      oneTimeToken,
      jwtOneTimeToken: jwt_ott,
      endpoint: gateway.url,
      isEncrypted: false,
      callback,
      handlers,
      signal: controller.signal,
      carReader: CarReader,
      uploadChunkSize:
        upload_chunk_size[file.slug] || gateway.upload_chunk_size,
      cidData,
    });
    if (canBePrinted) {
      let fileType = 'pdf';
      if (imageMediaTypes.includes(file?.extension)) {
        fileType = 'image';
      } else if (docMediaTypes.includes(file?.extension)) {
        fileType = 'html';
      }
      printJS(blob, fileType as PrintTypes);
    }
  };

  const openPrewiev = () => {
    openModal();
  };

  return (
    <section className={style.sharedByLink}>
      <Header
        fileViewType={viewType}
        setFileViewTypeEffect={setViewType}
        files={[file]}
        downloadEntities={downloadFileLocal}
        printEntities={printFile}
        canBePrinted={canBePrinted}
        hideBreadcrumb
      />
      <main>
        <div className={style.sharedByLink__main}>
          <FileContainer
            file={file}
            viewType={viewType}
            options={[]}
            canDrag={false}
            disabled={false}
            isSelected={false}
            isDragged={false}
            hideActions
            fileFolderActionHandlerEffect={openPrewiev}
            selectedEntity={{ entity: file }}
            isPublic
          />
          {file && (
            <FileView
              isOpen={modalIsOpen}
              entity={file}
              onRequestClose={closeModal}
              isPublic
            />
          )}
        </div>
      </main>
    </section>
  );
};

export default SharedNftFile;
