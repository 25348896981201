import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { ReactComponent as PlusIcon } from './assets/plus.svg';

const NeyraProfileButton = ({ handleClick }) => {
  const { t } = useTranslation('auth');
  const { t: accountT } = useTranslation('account');
  return (
    <div className={styles.neyraButton} onClick={handleClick}>
      <div className={styles.buttonText}>{t('initialPage.neyra')}</div>
      <PlusIcon />
      <div className={styles.buttonText}>{accountT('AIAvatar.profile')}</div>
    </div>
  );
};

export default NeyraProfileButton;
