import { imageAsObj } from "containers/header/header-container/components/ImageToImageModal"

//New
export const START_GENERATE = 'START_GENERATE'
export const END_GENERATE = 'END_GENERATE'
export const START_UPSCALE = 'START_UPSCALE'
export const END_UPSCALE = 'END_UPSCALE'

export function startGenerate(currentImage:imageAsObj) {
  return {
    type: START_GENERATE,
    payload: currentImage
  }
}

export function endGenerate() {
  return {
    type: END_GENERATE,
  }
}
export function startUpscale(currentImage:imageAsObj) {
  return {
    type: START_UPSCALE,
    payload: currentImage
  }
}

export function endUpscale() {
  return {
    type: END_UPSCALE,
  }
}
