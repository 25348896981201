// Refactoring №3
import React from 'react';

const Icon = ({
  type,
  color,
  rotate = 0,
}: {
  type: string;
  color?: string;
  rotate?: number;
}) => {
  switch (type) {
    case 'color':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
        >
          <mask
            id="colorFilter"
            width="24"
            height="24"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
            style={{ maskType: 'alpha' }}
          >
            <path fill="#D9D9D9" d="M0 0h24v24H0z" />
          </mask>
          <g mask="url(#colorFilter)">
            <path
              fill="currentColor"
              d="m16.94 15.092-.607-.598c.098-.22.166-.456.205-.707.039-.252.058-.522.058-.811a4.56 4.56 0 0 0-.34-1.744 4.185 4.185 0 0 0-1.02-1.472l-3.302-3.264-1.83 1.79-.549-.55 1.728-1.692a.875.875 0 0 1 .313-.208.964.964 0 0 1 .338-.063c.113 0 .228.022.345.065a.913.913 0 0 1 .322.206l3.185 3.127c.48.455.865 1.03 1.154 1.724.289.695.433 1.389.433 2.081a5.402 5.402 0 0 1-.433 2.116Zm-5.006 3.216c-1.513 0-2.798-.52-3.855-1.558-1.056-1.039-1.584-2.297-1.584-3.774 0-.72.14-1.397.422-2.03a6.235 6.235 0 0 1 1.09-1.682l-2.93-2.929a.391.391 0 0 1-.116-.267.363.363 0 0 1 .117-.283.381.381 0 0 1 .275-.124c.1 0 .192.041.275.124L18.33 18.487a.391.391 0 0 1 .116.268.363.363 0 0 1-.116.282.381.381 0 0 1-.275.124.381.381 0 0 1-.275-.124l-2.164-2.164a4.829 4.829 0 0 1-1.657 1.052 5.515 5.515 0 0 1-2.025.383Zm0-.777c.666 0 1.271-.122 1.816-.366.546-.244.981-.528 1.307-.85l-6.5-6.492a5.48 5.48 0 0 0-.907 1.385 4.114 4.114 0 0 0-.378 1.768c0 1.27.453 2.346 1.36 3.23.906.883 2.007 1.325 3.302 1.325Z"
            />
          </g>
        </svg>
      );

    case 'sort':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
        >
          <mask
            id="sortFilter"
            width="24"
            height="24"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
            style={{ maskType: 'alpha' }}
          >
            <path fill="#D9D9D9" d="M0 0h24v24H0z" />
          </mask>
          <g mask="url(#sortFilter)">
            <path
              fill="currentColor"
              d="M4.5 17a.484.484 0 0 1-.356-.144A.485.485 0 0 1 4 16.5c0-.141.048-.26.144-.356A.485.485 0 0 1 4.5 16h3.75c.142 0 .26.048.356.144a.485.485 0 0 1 .144.357c0 .141-.048.26-.144.356A.485.485 0 0 1 8.25 17H4.5Zm0-4.5a.484.484 0 0 1-.356-.144A.485.485 0 0 1 4 12c0-.142.048-.26.144-.356A.485.485 0 0 1 4.5 11.5h9.365c.142 0 .26.048.357.144a.485.485 0 0 1 .143.357c0 .142-.047.26-.143.356a.485.485 0 0 1-.357.143H4.5Zm0-4.5a.483.483 0 0 1-.356-.144A.485.485 0 0 1 4 7.499c0-.142.048-.26.144-.356A.485.485 0 0 1 4.5 7h15c.142 0 .26.048.356.144a.485.485 0 0 1 .144.357c0 .142-.048.26-.144.356A.484.484 0 0 1 19.5 8h-15Z"
            />
          </g>
        </svg>
      );

    case 'filter':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
        >
          <mask
            id="iconFilter"
            width="24"
            height="24"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
            style={{ maskType: 'alpha' }}
          >
            <path fill="#D9D9D9" d="M0 0h24v24H0z" />
          </mask>
          <g mask="url(#iconFilter)">
            <path
              fill="currentColor"
              d="M11.77 19a.745.745 0 0 1-.55-.22.745.745 0 0 1-.22-.55v-5.576L5.604 5.83c-.134-.18-.153-.363-.055-.55A.494.494 0 0 1 6.02 5h11.962c.215 0 .372.094.47.28.098.188.08.371-.055.55L13 12.655v5.577c0 .219-.073.402-.22.549a.745.745 0 0 1-.549.22h-.461Zm.23-6.7L16.95 6h-9.9L12 12.3Z"
            />
          </g>
        </svg>
      );

    case 'view':
      return (
        <svg
          width="17"
          height="17"
          viewBox="-0.3 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 6.5C0.73205 6.5 0.498392 6.40032 0.299025 6.20095C0.099675 6.0016 0 5.76795 0 5.5V1.5C0 1.23205 0.099675 0.998392 0.299025 0.799025C0.498392 0.599675 0.73205 0.5 1 0.5H5C5.26795 0.5 5.5016 0.599675 5.70095 0.799025C5.90032 0.998392 6 1.23205 6 1.5V5.5C6 5.76795 5.90032 6.0016 5.70095 6.20095C5.5016 6.40032 5.26795 6.5 5 6.5H1ZM1 16.5C0.73205 16.5 0.498392 16.4003 0.299025 16.201C0.099675 16.0016 0 15.768 0 15.5V11.5C0 11.232 0.099675 10.9984 0.299025 10.7991C0.498392 10.5997 0.73205 10.5 1 10.5H5C5.26795 10.5 5.5016 10.5997 5.70095 10.7991C5.90032 10.9984 6 11.232 6 11.5V15.5C6 15.768 5.90032 16.0016 5.70095 16.201C5.5016 16.4003 5.26795 16.5 5 16.5H1ZM11 6.5C10.732 6.5 10.4984 6.40032 10.299 6.20095C10.0997 6.0016 10 5.76795 10 5.5V1.5C10 1.23205 10.0997 0.998392 10.299 0.799025C10.4984 0.599675 10.732 0.5 11 0.5H15C15.268 0.5 15.5016 0.599675 15.701 0.799025C15.9003 0.998392 16 1.23205 16 1.5V5.5C16 5.76795 15.9003 6.0016 15.701 6.20095C15.5016 6.40032 15.268 6.5 15 6.5H11ZM11 16.5C10.732 16.5 10.4984 16.4003 10.299 16.201C10.0997 16.0016 10 15.768 10 15.5V11.5C10 11.232 10.0997 10.9984 10.299 10.7991C10.4984 10.5997 10.732 10.5 11 10.5H15C15.268 10.5 15.5016 10.5997 15.701 10.7991C15.9003 10.9984 16 11.232 16 11.5V15.5C16 15.768 15.9003 16.0016 15.701 16.201C15.5016 16.4003 15.268 16.5 15 16.5H11Z"
            fill="currentColor"
          />
        </svg>
      );

    case 'viewList':
      return (
        <svg
          width="18"
          height="12"
          viewBox="0 0 18 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.200195 11.1329V10.4228H17.8002V11.1329H0.200195ZM0.200195 6.36951V5.65941H17.8002V6.36951H0.200195ZM0.200195 1.57631V0.866211H17.8002V1.57631H0.200195Z"
            fill="currentColor"
          />
        </svg>
      );

    case 'reload':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
        >
          <mask
            id="reloaFilter"
            width="24"
            height="24"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
            style={{ maskType: 'alpha' }}
          >
            <path fill="#D9D9D9" d="M0 0h24v24H0z" />
          </mask>
          <g mask="url(#reloaFilter)">
            <path
              fill="currentColor"
              d="M12 21a7.805 7.805 0 0 1-3.118-.626 8.065 8.065 0 0 1-2.543-1.713 8.065 8.065 0 0 1-1.713-2.542A7.805 7.805 0 0 1 4 13c0-1.109.209-2.148.626-3.118A8.066 8.066 0 0 1 6.339 7.34a8.066 8.066 0 0 1 2.543-1.714A7.805 7.805 0 0 1 12 5h.38L11.07 3.69a.488.488 0 0 1-.16-.351c0-.128.053-.247.16-.357a.485.485 0 0 1 .346-.149.528.528 0 0 1 .367.143l1.96 1.94a.772.772 0 0 1 .236.566c0 .22-.079.408-.237.566l-1.92 1.94a.44.44 0 0 1-.348.162.52.52 0 0 1-.366-.168.507.507 0 0 1-.16-.357c0-.127.053-.244.16-.35L12.38 6H12c-1.95 0-3.604.68-4.963 2.038C5.68 9.396 5 11.05 5 13c0 1.95.68 3.605 2.037 4.963C8.396 19.32 10.05 20 12 20c1.844 0 3.43-.623 4.76-1.869 1.33-1.246 2.066-2.789 2.21-4.629a.62.62 0 0 1 .176-.354A.48.48 0 0 1 19.5 13c.139 0 .256.048.354.145a.432.432 0 0 1 .134.346c-.13 2.124-.962 3.907-2.496 5.348C15.96 20.279 14.128 21 12 21Z"
            />
          </g>
        </svg>
      );

    case 'settings':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3"
          height="15"
          fill="none"
        >
          <path
            fill="currentColor"
            d="M0 1.73C0 1.318.147.965.44.671c.294-.294.648-.44 1.06-.44.413 0 .766.146 1.06.44.293.294.44.647.44 1.06 0 .412-.147.765-.44 1.059-.294.294-.647.44-1.06.44-.412 0-.766-.146-1.06-.44A1.444 1.444 0 0 1 0 1.73ZM0 7.5c0-.413.147-.766.44-1.06C.735 6.147 1.089 6 1.5 6c.413 0 .766.147 1.06.44.293.294.44.647.44 1.06 0 .412-.147.765-.44 1.059-.294.294-.647.44-1.06.44-.412 0-.766-.146-1.06-.44A1.444 1.444 0 0 1 0 7.499Zm0 5.769c0-.413.147-.766.44-1.06.294-.293.648-.44 1.06-.44.413 0 .766.147 1.06.44.293.294.44.647.44 1.06 0 .412-.147.765-.44 1.06-.294.293-.647.44-1.06.44-.412 0-.766-.147-1.06-.44a1.444 1.444 0 0 1-.44-1.06Z"
          />
        </svg>
      );

    case 'right':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
        >
          <mask
            id="rightIcon"
            width="20"
            height="20"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
            style={{ maskType: 'alpha' }}
          >
            <path fill="#E4E7EC" d="M0 20V0h20v20z" />
          </mask>
          <g mask="url(#rightIcon)">
            <path
              fill="currentColor"
              d="m12.25 10-4.416 4.417-.605-.583L11.063 10 7.229 6.167l.605-.583L12.25 10Z"
            />
          </g>
        </svg>
      );

    case 'listSmall':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="2 2 16 16"
        >
          <mask
            id="listSmall"
            width="20"
            height="14"
            x="-1"
            y="3"
            maskUnits="userSpaceOnUse"
            style={{ maskType: 'alpha' }}
          >
            <path fill="#D9D9D9" d="M-1 3.584h20v12.833H-1z" />
          </mask>
          <g mask="url(#listSmall)">
            <path
              fill="currentColor"
              d="M4.783 12.343v-.462H15.21v.462H4.783Zm0-3.104v-.462H15.21v.462H4.783Zm0-3.123v-.463H15.21v.463H4.783Z"
            />
          </g>
        </svg>
      );

    case 'listTable':
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
        >
          <mask
            id="tableSmall"
            width="12"
            height="12"
            x="4"
            y="4"
            maskUnits="userSpaceOnUse"
            style={{ maskType: 'alpha' }}
          >
            <path fill="#D9D9D9" d="M4 4h12v12H4z" />
          </mask>
          <g mask="url(#tableSmall)">
            <path
              fill="currentColor"
              d="M4 16h3.714v-3.714H4V16Zm0-8.286h3.714V4H4v3.714ZM12.286 16H16v-3.714h-3.714V16Zm0-8.286H16V4h-3.714v3.714Z"
            />
          </g>
        </svg>
      );

    case 'check':
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_37685_4759"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
          >
            <rect width="20" height="20" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_37685_4759)">
            <path
              d="M8.33317 13.1361L5.59277 10.3957L6.06234 9.92611L8.33317 12.1969L13.9373 6.59277L14.4069 7.06234L8.33317 13.1361Z"
              fill="currentColor"
            />
          </g>
        </svg>
      );

    case 'checkSmall':
      return (
        <svg
          width="8"
          height="6"
          viewBox="0 0 8 6"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.66677 5.74604L0.237305 3.31658L0.850105 2.70378L2.66677 4.52044L7.15011 0.0371094L7.76291 0.649909L2.66677 5.74604Z"
            fill="#1C1B1F"
          />
        </svg>
      );

    case 'colorSelected':
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_37846_100495"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
          >
            <rect width="20" height="20" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_37846_100495)">
            <path
              d="M11.9998 18.2578C10.4876 18.2578 9.205 17.7448 8.15191 16.7187C7.09881 15.6926 6.57227 14.4235 6.57227 12.9113C6.57227 12.1958 6.7174 11.5173 7.00768 10.876C7.29796 10.2347 7.67937 9.65754 8.15191 9.14449L11.3719 6.00546C11.4665 5.91095 11.5677 5.84345 11.6757 5.80294C11.7837 5.76244 11.8917 5.74219 11.9998 5.74219C12.1078 5.74219 12.2158 5.76244 12.3238 5.80294C12.4318 5.84345 12.5331 5.91095 12.6276 6.00546L15.8476 9.14449C16.3201 9.65754 16.7016 10.2347 16.9918 10.876C17.2821 11.5173 17.4272 12.1958 17.4272 12.9113C17.4272 14.4235 16.9007 15.6926 15.8476 16.7187C14.7945 17.7448 13.5119 18.2578 11.9998 18.2578ZM7.20007 13.6404H16.7994C16.9614 12.6683 16.8669 11.8312 16.5159 11.1292C16.1649 10.4271 15.8071 9.90056 15.4426 9.54953L11.9998 6.14722L8.55694 9.54953C8.19241 9.90056 7.838 10.4271 7.49372 11.1292C7.14944 11.8312 7.05156 12.6683 7.20007 13.6404Z"
              fill={color || 'currentColor'}
            />
          </g>
        </svg>
      );

    case 'direction':
      return (
        <svg
          width="16"
          transform={`rotate(${rotate})`}
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_38025_66576"
            style={{ maskType: 'alpha' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="16"
          >
            <rect
              y="16"
              width="16.0005"
              height="16.0005"
              transform="rotate(-90 0 16)"
              fill="var(--headerDirectionBg)"
            />
          </mask>
          <g mask="url(#mask0_38025_66576)">
            <path
              d="M4.41406 9.08791L4.88835 8.63297L7.58435 11.2613V3.5L8.24377 3.5V11.2613L10.9398 8.63297L11.4141 9.08791L7.91406 12.5L4.41406 9.08791Z"
              fill="currentColor"
            />
          </g>
        </svg>
      );

    default:
      return <span />;
  }
};

export default Icon;
