import React from 'react';

const Close = ({ className, size, onClick }) => {
  if (size === 10)
    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 1L1 9M8.99305 8.99307L1.00691 1.00693"
          stroke="currentColor"
          strokeWidth="1.2"
          strokeLinecap="round"
        />
      </svg>
    );

  if (size === 13)
    return (
      <svg
        onClick={onClick}
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.26219 6.9966L12.8348 12.5692C13.0498 12.7769 13.0558 13.1196 12.8481 13.3347C12.6404 13.5498 12.2976 13.5557 12.0826 13.348C12.0781 13.3437 12.0736 13.3392 12.0692 13.3347L6.49663 7.76213L0.92405 13.3347C0.708972 13.5425 0.366243 13.5365 0.158525 13.3214C-0.0441179 13.1116 -0.0441179 12.779 0.158525 12.5692L5.73111 6.9966L0.158525 1.42403C-0.0528416 1.21263 -0.0528416 0.8699 0.158525 0.658502C0.369923 0.447166 0.712652 0.447166 0.92405 0.658502L6.49663 6.23108L12.0692 0.658502C12.2843 0.450783 12.627 0.456746 12.8347 0.671824C13.0373 0.881636 13.0373 1.21425 12.8347 1.42403L7.26219 6.9966Z"
          fill="currentColor"
        />
      </svg>
    );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      className={className}
    >
      <path fill="currentColor" d="M0 0h18v18H0z" opacity=".01" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.342.342a.483.483 0 0 0 0 .683L7.317 8 .342 14.975a.483.483 0 0 0 .683.683L8 8.683l6.975 6.975a.483.483 0 0 0 .683-.683L8.683 8l6.975-6.975a.483.483 0 1 0-.683-.683L8 7.317 1.025.342a.483.483 0 0 0-.683 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

Close.defaultProps = {
  onClick: () => {},
  size: 18,
  className: '',
};

export default Close;
