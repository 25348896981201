import React from 'react';

const DownloadIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.31246 15.8335C4.99301 15.8335 4.72218 15.7224 4.49996 15.5002C4.27774 15.2779 4.16663 15.0071 4.16663 14.6877V12.4793H4.81246V14.6877C4.81246 14.8127 4.86454 14.9272 4.96871 15.0314C5.07288 15.1356 5.18746 15.1877 5.31246 15.1877H14.6875C14.8125 15.1877 14.927 15.1356 15.0312 15.0314C15.1354 14.9272 15.1875 14.8127 15.1875 14.6877V12.4793H15.8333V14.6877C15.8333 15.0071 15.7222 15.2779 15.5 15.5002C15.2777 15.7224 15.0069 15.8335 14.6875 15.8335H5.31246ZM9.99996 12.7502L7.12496 9.87515L7.58329 9.43765L9.68746 11.521V3.87515H10.3125V11.521L12.4166 9.43765L12.875 9.87515L9.99996 12.7502Z"
      fill="currentColor"
    />
  </svg>
);

DownloadIcon.defaultProps = {};

export default DownloadIcon;
