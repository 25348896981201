import React from 'react';

const ConvertIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0728 2.32366L11.0728 17.055L11.1317 18.5282L11.3969 18.263L16.6708 12.9892L16.2288 12.5472L11.7357 17.0403L11.721 2.32366L11.0728 2.32366Z"
      fill="var(--iconColor)"
    />
    <path
      d="M8.45586 18.2047L8.45586 3.47328L8.39694 2.00013L8.13176 2.2653L2.85793 7.53914L3.29987 7.98108L7.79294 3.48801L7.80768 18.2047L8.45586 18.2047Z"
      fill="var(--iconColor)"
    />
  </svg>
);

ConvertIcon.defaultProps = {};
export default ConvertIcon;
