import React, {
  useState,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import TagsInput from 'react-tagsinput';
import AutosizeInput from 'react-input-autosize';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/shared/Tooltip/tooltip';

import TagContext from 'store/tags/contexts/tags';
import MainContext from 'store/main/context/main-context';

import { tagAddEffect } from 'store/tags/effects';
import useClickOutside from 'utils/hooks/use-click-outside';
import { getFilesUpdate } from 'store/home/actions/files/get-files.actions';

const numberVisibleTags = 3;

const Tags = ({ entityId, tags }) => {
  const { t } = useTranslation('owner');
  const { tags: allTags, updateTags } = useContext(TagContext);
  const {
    state: { isDeletedPage },
  } = useContext(MainContext);

  const dispatch = useDispatch();
  const [addingNew, setAddingNew] = useState(false);
  const [error, setError] = useState(null);
  const [nextTags, setNextTags] = useState([...tags]);
  const [nextTag, setNextTag] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showAll, setShowAll] = useState(tags.length <= numberVisibleTags);
  const inputRef = useRef(null);
  const tagContainerRef = useRef(null);

  useClickOutside(tagContainerRef, () => {
    setShowAll(false);
    setAddingNew(false);
  });

  useLayoutEffect(() => {
    if (addingNew && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  });

  useEffect(() => {
    setNextTags([...tags]);
    setShowAll(tags.length <= numberVisibleTags);
  }, [entityId, tags]);

  useEffect(() => {
    setNextTags([...tags]);
    setNextTag('');
  }, [entityId]);

  const addNewButtonClickHandler = () => {
    setAddingNew(true);
  };

  const addNewTagToAllTags = (file) => {
    if (file.tags && file.tags.length) {
      const newTags = [...allTags];
      file.tags.forEach((item) => {
        const searchResult = allTags.find((tag) => tag.id === item.id);
        if (!searchResult) {
          newTags.push(item);
        }
      });
      if (newTags.length !== allTags.length) {
        updateTags(newTags);
      }
      setNextTags([...file.tags]);
    }
  };

  const addTagToEntity = (file) => {
    dispatch(getFilesUpdate({ entity: file, updatedPropsList: ['tags'] }));
    addNewTagToAllTags(file);
  };

  const onChangeHandler = (newTags) => {
    const updatedTags = newTags.map((name) => {
      const result = nextTags.find((tag) => tag.name === name);
      return result ? result : { name, id: 'new' };
    });

    setNextTags(updatedTags);
    tagAddEffect(entityId, newTags).then(addTagToEntity);
  };

  const onSuggestClickHandler = (name) => () => {
    if (!error) {
      const newTags = [...nextTags, { name, id: 'new' }];
      setNextTags(newTags);
      setNextTag('');
      setSuggestions([]);
      tagAddEffect(
        entityId,
        newTags.map((tag) => tag.name)
      ).then(addTagToEntity);
    }
  };

  const onChangeInputHandler = (newTag) => {
    const inputValue = (newTag && newTag.trim().toLowerCase()) || '';
    const inputLength = inputValue.length;
    if (error) setError(null);
    if (!/^[a-zA-Z0-9_.-]*$/g.test(inputValue))
      setError(t('rightFileMenu.meta.characterError'));
    if (inputLength >= 32) setError(t('rightFileMenu.meta.lengthError'));
    if (inputLength > 0) {
      const newSuggestions = allTags.filter((tag) => {
        return tag.name.toLowerCase().slice(0, inputLength) === inputValue;
      });

      setSuggestions(newSuggestions);
    } else {
      setSuggestions([]);
    }

    setNextTag(newTag);
  };

  const renderName = (name) => {
    const maxLength = 16;
    const sliceName = () => name?.slice(0, maxLength).trim() + '...';
    return name?.length > maxLength ? (
      <>
        {sliceName()}
        <Tooltip className="folder-tooltip" text={name} />
      </>
    ) : (
      name
    );
  };

  const tagItems = !addingNew
    ? (showAll ? nextTags : nextTags.slice(0, numberVisibleTags)).map(
        ({ id, name }) => (
          <button
            type="button"
            className="tag-button entity-settings__tags-item"
            key={`tag-${id}`}
          >
            {`# `}
            {renderName(name)}
          </button>
        )
      )
    : null;
  const tagCountButton =
    !addingNew && !showAll && nextTags.length - numberVisibleTags > 0 ? (
      <button
        type="button"
        className="tag-button entity-settings__tags-item"
        onClick={() => setShowAll(true)}
      >
        {`+`}
        {nextTags.length - numberVisibleTags}
      </button>
    ) : null;

  const suggestionItems =
    addingNew && suggestions && suggestions.length > 0 ? (
      suggestions.map((suggestion) => (
        <button
          type="button"
          className="tags-suggest-item"
          key={`suggest-${suggestion.id}`}
          onClick={onSuggestClickHandler(suggestion.name)}
          data-test={`tags_suggestion_button`}
        >
          {suggestion.name}
        </button>
      ))
    ) : nextTag ? (
      <>
        {error ? (
          <span className="tags-suggest-item-error">{error}</span>
        ) : (
          <button
            type="button"
            className="link link--shrink"
            onClick={onSuggestClickHandler(nextTag)}
            data-test={`tags_create_next-tag_button`}
          >
            {`${t('rightFileMenu.meta.create')} ${nextTag}`}
          </button>
        )}
      </>
    ) : null;

  const onInputWrapperClick = (e) => {
    if (tagContainerRef && tagContainerRef.current) {
      const activeArea =
        tagContainerRef.current.getElementsByClassName('tags-active-aria')[0];
      if (e.target === activeArea) {
        inputRef.current.focus();
      }
    }
  };

  const addInput = addingNew ? (
    <>
      <div
        className="entity-settings__tags-input-wrapper"
        onClick={onInputWrapperClick}
        data-test={`tags_add-tag_section`}
      >
        <TagsInput
          // eslint-disable-next-line no-unused-vars
          renderInput={({ addTag, ...props }) => {
            const { onChange, value, ...other } = props;
            return (
              <AutosizeInput
                type="text"
                className="react-tagsinput-input"
                onChange={onChange}
                value={value}
                {...other}
              />
            );
          }}
          onlyUnique
          value={nextTags.map((tag) => tag.name)}
          inputValue={nextTag}
          onChangeInput={onChangeInputHandler}
          onChange={onChangeHandler}
          className="entity-settings__tags-input tags-active-aria"
          placeholder=""
          inputProps={{
            placeholder: '',
            autoComplete: 'off',
            ref: inputRef,
          }}
        />
      </div>
      <div className="entity-settings__tags-container tags-suggest-container">
        {suggestionItems}
      </div>
    </>
  ) : null;

  return (
    <div className="entity-settings__content">
      <section
        className={cn('entity-settings__tags', addingNew && 'adding')}
        ref={tagContainerRef}
      >
        <div className="entity-settings__tags-name">
          {t('rightFileMenu.meta.tags')}
        </div>
        {addInput}
        <div className="entity-settings__tags-container">
          {tagItems}
          {tagCountButton}
        </div>
        {addingNew || isDeletedPage ? null : (
          <button
            type="button"
            className="link link--shrink"
            onClick={addNewButtonClickHandler}
            data-test={`tags_add-tag_button`}
          >
            {nextTags.length > 0
              ? t('rightFileMenu.meta.editTags')
              : t('rightFileMenu.meta.addTags')}
          </button>
        )}
      </section>
    </div>
  );
};

Tags.defaultProps = {
  tags: [],
};

export default Tags;
