//Refactoring №3
import React, { useEffect, useState } from 'react';

import { validationRegExp } from 'utils/validation/regexp';
import { doesFileExist } from 'utils/request/does-file-exist';
import { copyToClipboard } from 'utils/string';

import CopyButton from 'components/Button/copy-button';

import { ReactComponent as LinkIcon } from 'static/assets/svg/link.svg';

import styles from './style.module.scss';

type LinkPropTypes = {
  text: string;
  link: string;
};

const Link = ({ text, link }: LinkPropTypes) => {
  const [iconUrl, setIconUrl] = useState('');

  useEffect(() => {
    if (link && validationRegExp.isLink.test(link)) {
      doesFileExist(link).then((url) => {
        setIconUrl(url);
      });
    }

    return () => {
      setIconUrl('');
    };
  }, [link]);

  const copyLink = () => {
    copyToClipboard(link);
  };

  const addProtocol = (url = '') => {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'http://' + url;
    }
    return url;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.link}>
        {iconUrl ? (
          <div className={styles.logo}>
            <img className={styles.logoImage} src={iconUrl} alt="logo" />
          </div>
        ) : (
          <div className={styles.defaultLogo}>
            <LinkIcon />
          </div>
        )}
        <a
          href={addProtocol(link)}
          target="_blank"
          className={styles.directLink}
          rel="noreferrer"
        >
          {text}
        </a>
      </div>
      <div className={styles.linkCopy}>
        <CopyButton onClick={copyLink} />
      </div>
    </div>
  );
};

export default Link;
