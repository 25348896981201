// Libs
import * as R from 'ramda';

// Store
import * as getFilesActions from '../../actions/files/get-files.actions';
import * as ghostFilesActions from '../../actions/ghost-files.actions';
import fileUpdateEffect from './file-update.effect';

export default (file, afterCb, securityData) => {
  return (dispatch) => {
    const ghostModeStatus = R.prop('ghost_mode', file);

    const update = {
      ghost_mode: !ghostModeStatus,
    };

    const enableGhostMode = () => {
      dispatch(getFilesActions.getFilesRemove(file));
      dispatch(ghostFilesActions.ghostFilesAdd(file));
      afterCb(file);
    };

    const disableGhostMode = () => {
      dispatch(ghostFilesActions.ghostFileRemove(file));
      dispatch(getFilesActions.getFilesAdd(file));
      afterCb(file);
    };

    if (!ghostModeStatus) {
      dispatch(
        fileUpdateEffect(file, enableGhostMode, securityData, { update })
      );
    } else {
      dispatch(
        fileUpdateEffect(file, disableGhostMode, securityData, { update })
      );
    }
  };
};
