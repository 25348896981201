import React from 'react';

const Link = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.59401 16.2842C5.96011 16.2842 5.36417 16.0371 4.91604 15.5889L4.43637 15.1099C3.51128 14.1849 3.51128 12.6791 4.43637 11.7543L7.55232 8.6384C8.00046 8.19027 8.5964 7.94315 9.2303 7.94315C9.8642 7.94315 10.4601 8.19027 10.9079 8.6384L11.3873 9.11772C11.5198 9.25027 11.5198 9.4645 11.3873 9.59705C11.2547 9.72959 11.0405 9.72959 10.9079 9.59705L10.4286 9.11772C10.1086 8.79772 9.68285 8.62111 9.2303 8.62111C8.77742 8.62111 8.35165 8.79772 8.03165 9.11772L4.9157 12.2333C4.25501 12.894 4.25501 13.9693 4.9157 14.6303L5.39502 15.1096C5.71502 15.4296 6.14079 15.6062 6.59367 15.6062C7.04622 15.6062 7.47199 15.4296 7.79199 15.1096L9.94963 12.9523C10.0822 12.8198 10.2964 12.8198 10.429 12.9523C10.5615 13.0849 10.5615 13.2991 10.429 13.4316L8.27165 15.5889C7.82385 16.0374 7.22792 16.2842 6.59401 16.2842Z"
      fill={color}
    />
    <path
      d="M10.6686 12.209C10.0351 12.209 9.43881 11.9619 8.99101 11.5138C8.85847 11.3812 8.85847 11.167 8.99101 11.0344C9.12355 10.9019 9.33779 10.9019 9.47033 11.0344C9.79034 11.3544 10.2161 11.531 10.6686 11.531C11.1215 11.531 11.5473 11.3544 11.8673 11.0344L14.9833 7.9185C15.6439 7.25782 15.6439 6.18256 14.9833 5.52188L14.5043 5.04188C13.8436 4.3812 12.768 4.3812 12.1073 5.04188L9.94966 7.19951C9.81712 7.33205 9.60288 7.33205 9.47033 7.19951C9.33779 7.06697 9.33779 6.85273 9.47033 6.72019L11.6276 4.56289C12.5527 3.63781 14.0582 3.63781 14.9833 4.56289L15.4626 5.04256C16.3877 5.96764 16.3877 7.47307 15.4626 8.39782L12.3466 11.5138C11.8988 11.9619 11.3025 12.209 10.6686 12.209Z"
      fill={color}
    />
    <path
      d="M8.91528 5.16184V2.31702"
      stroke={color}
      strokeWidth="0.413795"
      strokeLinejoin="round"
    />
    <path
      d="M2.70831 7.4894L5.81177 7.74802"
      stroke={color}
      strokeWidth="0.413795"
      strokeLinejoin="round"
    />
    <path
      d="M7.10502 5.93756L6.19985 5.16169L5.29468 4.38583"
      stroke={color}
      strokeWidth="0.413795"
      strokeLinejoin="round"
    />
    <path
      d="M11.5016 14.2135V17.0583"
      stroke={color}
      strokeWidth="0.413795"
      strokeLinejoin="round"
    />
    <path
      d="M17.7084 11.886L14.605 11.6273"
      stroke={color}
      strokeWidth="0.413795"
      strokeLinejoin="round"
    />
    <path
      d="M13.3117 13.4378L14.2169 14.2137L15.1221 14.9895"
      stroke={color}
      strokeWidth="0.413795"
      strokeLinejoin="round"
    />
  </svg>
);

Link.defaultProps = {
  color: 'currentColor',
};

export default Link;
