// Refactoring №3
import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { isBoolean } from 'lodash';

import { FormItem } from '../FormItem';
import { ReactComponent as ValueIcon } from '../../../assets/neyra.svg'

import style from './style.module.css';

const validationSchema = Yup.object({
  value: Yup.number(),
});

const tradeOptions = [
  {
    label: 'No',
    value: false,
  },
  {
    label: 'Yes',
    value: true,
  },
];

export const DataFourForm = ({ entity, onUpdate }) => {
  const { t } = useTranslation('owner');
  const form = useRef(null);
  const entry_meta = entity.entry_meta || {};
  const initialValues = {
    value: entry_meta.value ? entry_meta.value : 0,
    trade: isBoolean(entry_meta.trade)
      ? tradeOptions.find((item) => item.value === entry_meta.trade)
      : null,
  };

  useEffect(() => {
    form.current.setValues(initialValues);
  }, [entity]);

  const updateField = (option) => {
    onUpdate(option);
  };

  const preventMinus = (e) => {
    if (e.key === '-') {
      e.preventDefault();
    }
  };

  const onFocus = ({ target }) => {
    if (target.value === '0') {
      form.current.setFieldValue(target.name, '');
    }
  };

  const updateInputField = (option) => {
    if (option.value) {
      updateField(option);
    } else {
      form.current.setFieldValue(option.name, '0');
      updateField({ ...option, value: '0' });
    }
  };

  return (
    <Formik
      innerRef={form}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      <Form className={style.content}>
        <FormItem
          className={style.formItem}
          name="value"
          label={t('rightFileMenu.meta.value')}
          postfix="$"
          onChnage={updateInputField}
          onKeyPress={preventMinus}
          onFocus={onFocus}
          icon={<ValueIcon />}
        />
      </Form>
    </Formik>
  );
};

DataFourForm.propTypes = {
  className: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
};

DataFourForm.defaultProps = {
  className: '',
};
