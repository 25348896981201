//Refactoring №3
import React, { useEffect, useState, useContext, useCallback } from 'react';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import CN from 'classnames';

import UserContext from 'store/home/contexts/user-context';
import {
  generateKeyEffect,
  toggleSecurityKey,
} from 'store/account/effects/securities/generate-key.effect';
import { updateUserInfo } from 'store/home/actions/get-user-info.actions';

import { ReactComponent as GenerateIcon } from 'static/assets/svg/securityLayers/generate.svg';
import { ReactComponent as PageIcon } from 'static/assets/svg/securityLayers/page.svg';
import { ReactComponent as HackerIcon } from 'static/assets/svg/securityLayers/hacker.svg';
import { ReactComponent as CheckIcon } from 'static/assets/svg/securityLayers/check.svg';
import { ReactComponent as KeyIcon } from 'static/assets/svg/key.svg';
import PopUpsHead from 'components/shared/PopUpsHead/index';
import Button, { ButtonTheme } from 'components/Button';
import KeySelector from './KeySelector';

import securitiesType from 'config/securities-types';
import styles from './styles.module.scss';

import { createAndDownload } from 'utils/download';
import useNotification from 'utils/hooks/use-notification';

const rules = [
  {
    img: <PageIcon />,
    title: 'Don’t Lose It',
    description:
      'Save your Key Certificate in safe place. It can not be recovered if you lose it.',
  },
  {
    img: <HackerIcon />,
    title: 'Don’t Share It',
    description:
      "Please don't share your Certificate via chat or other public communication.",
  },
];

const KEY_MAX_LENGTH = 128;

const AccountSecurityKeyContainer = ({
  onCloseModal,
  activatePinResult,
  onConfirm,
  tabView,
}) => {
  const { addNotification } = useNotification();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [isValid, setIsValid] = useState(true);
  const [key, setKey] = useState('');
  const { user } = useContext(UserContext);

  useEffect(() => {
    const { key } = securitiesType;

    if (user[key.activatedStatusName]) {
      onClose();
    }

    return () => {
      if (activatePinResult && activatePinResult.message === 'success') {
        onClose();
      }
    };
  }, []);

  const onClose = useCallback(() => {
    onCloseModal && onCloseModal();
  }, []);

  const goNextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const generateKey = useCallback(() => {
    generateKeyEffect().then((key) => {
      setKey(key);
      setStep(step + 1);
    });
  }, [step]);

  const downloadKey = useCallback(() => {
    setStep(step + 1);
    createAndDownload('key.gdkey', key);
  }, [step, key]);

  const activateAndUpload = useCallback(() => {
    toggleSecurityKey({ value: key, privateKey: true })
      .then(() => {
        addNotification('Key has been activated', 'success');
        dispatch(
          updateUserInfo({
            is_key_authenticator_enabled: true,
          })
        );
        onClose();
        setStep(1);
        setKey('');
      })
      .catch(() => {
        setIsValid(false);
      });
  }, [key]);

  const keyValidator = useCallback((myKey = '') => {
    if (myKey.length === KEY_MAX_LENGTH) {
      return true;
    }
    return false;
  }, []);

  const handleSubmit = useCallback(() => {
    onConfirm(key, {
      setState: ({ isValid }) => {
        setIsValid(isValid);
      },
    });
  }, [key]);

  const handleSetKey = useCallback((data) => {
    const keyIsValid = keyValidator(data);
    if (keyIsValid) {
      setKey(data);
      setIsValid(true);
      return;
    }
    setIsValid(false);
  }, []);

  if (tabView) {
    return (
      <div className={styles.keyTab}>
        <div className={styles.keyTab__container}>
          <div className={styles.keyTab__icon}>
            <KeyIcon />
          </div>
          <h2 className={styles.keyTab__header} k>
            Private Key Certificate
          </h2>
          <h2 className={styles.keyTab__sub_header}>
            Upload the private key file
          </h2>
          <div className={styles.keyTab__selector}>
            <KeySelector
              placeholder="Private Key Certificate"
              hasError={!isValid && key}
              setKey={handleSetKey}
            />
          </div>
          <p className={styles.keyTab__description}>
            If you deactivate the certificate and want to activate
            <br /> it again, the system will create a new certificate
          </p>
          <div className={styles.keyTab__actions}>
            <Button
              theme={ButtonTheme.DARK}
              className={CN(
                styles.keyTab__btn,
                !isValid && styles.keyTab__btn_disabled
              )}
              disabled={!isValid}
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    );
  }

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div
            className={CN(
              styles.keyPopup__container,
              styles.keyPopup__step_one
            )}
          >
            <div className={styles.keyPopup__preview}>
              <GenerateIcon />
            </div>
            <h1 className={styles.keyPopup__header}>Private Key Certificate</h1>
            <p className={styles.keyPopup__text}>
              The system generate private certificate, you need to <br />{' '}
              download and save certificate in safety place
            </p>
            <div className={styles.keyPopup__actions}>
              <Button theme={ButtonTheme.DARK} onClick={generateKey}>
                Generate
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div
            className={CN(
              styles.keyPopup__container,
              styles.keyPopup__step_two
            )}
          >
            <h1 className={styles.keyPopup__header}>
              Save My Private Key Certificate
            </h1>
            <ul className={styles.keyPopup__list}>
              {rules.map((item) => (
                <li key={item.title} className={styles.keyPopup__list_item}>
                  {item.img}
                  <div className={styles.keyPopup__item_text}>
                    <h2>{item.title}</h2>
                    <p>{item.description}</p>
                  </div>
                </li>
              ))}
            </ul>
            <div className={styles.keyPopup__actions}>
              <Button theme={ButtonTheme.DARK} onClick={downloadKey}>
                Download key file
              </Button>
            </div>
          </div>
        );
      case 3:
        return (
          <div
            className={CN(
              styles.keyPopup__container,
              styles.keyPopup__step_three
            )}
          >
            <div className={styles.keyPopup__preview}>
              <CheckIcon />
            </div>
            <h1 className={styles.keyPopup__header}>Success</h1>
            <p className={styles.keyPopup__text}>
              You have dowmload file successfully.
            </p>
            <div className={styles.keyPopup__actions}>
              <Button theme={ButtonTheme.DARK} onClick={goNextStep}>
                Activate
              </Button>
            </div>
          </div>
        );
      case 4:
        return (
          <div
            className={CN(styles.keyPopup__container, styles.keyPopup__four)}
          >
            <h1 className={styles.keyPopup__header}>
              Activate Private Key Certificate
            </h1>
            <p className={styles.keyPopup__text}>
              Upload the Private Key Certificate file for activation
            </p>
            <div className={styles.keyPopup__selector}>
              <KeySelector
                hasError={!isValid}
                setKey={handleSetKey}
                placeholder="Private Key Certificate"
              />
            </div>
            <div className={styles.keyPopup__actions}>
              <Button
                theme={ButtonTheme.DARK}
                onClick={activateAndUpload}
                disabled={!isValid}
              >
                Activate
              </Button>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <Modal
      isOpen={true}
      overlayClassName={styles.basicModal}
      className={styles.modalContent}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick
    >
      <div className={styles.wrapper}>
        <PopUpsHead hideBorder closePopup={onClose} />
        <div className={styles.keyPopup}>
          <div className={styles.keyPopup__content}>{renderStep()}</div>
        </div>
      </div>
    </Modal>
  );
};

export default AccountSecurityKeyContainer;
