import React from 'react'
import MoreAppsItem from 'components/more-apps/app-item/index.js'

let array = [
  {
    name: 'GhostPass',
    src: require('static/assets/img/app-icon/GhostPass.svg').default,
  },
  {
    name: 'GhostNotes',
    src: require('static/assets/img/app-icon/GhostNotes.svg').default,
  },
  {
    name: 'GhostPay',
    src: require('static/assets/img/app-icon/GhostPay.svg').default,
  },
  {
    name: 'GhostID',
    src: require('static/assets/img/app-icon/GhostID.svg').default,
  },
  {
    name: 'GhostVPN',
    src: require('static/assets/img/app-icon/GhostVPN.svg').default,
  },
  {
    name: 'GhostHost',
    src: require('static/assets/img/app-icon/GhostHost.svg').default,
  },
  {
    name: 'GhostSearch',
    src: require('static/assets/img/app-icon/GhostSearch.svg').default,
  },
  {
    name: 'GhostBird',
    src: require('static/assets/img/app-icon/GhostBird.svg').default,
  },
  {
    name: 'GhostChat',
    src: require('static/assets/img/app-icon/GhostChat.svg').default,
  },
  {
    name: 'GhostPhone',
    src: require('static/assets/img/app-icon/GhostPhone.svg').default,
  },
]

class MoreAppsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="more-apps__container">
        <h1 className="more-apps__headline text--bold text--32">
          Get started with Ghost Apps{' '}
        </h1>
        <div className="more-apps__list">
          {array.map((value, index) => {
            return (
              <MoreAppsItem key={index} name={value.name} logo={value.src} />
            )
          })}
        </div>
      </div>
    )
  }
}

export default MoreAppsContainer
