import axios from 'axios';
import { fromByteArray } from 'base64-js';

import { getOneTimeToken } from 'store/home/effects';

export const convertTextToBase64 = (text: string) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(text);
  const binary = new Uint8Array(data.buffer);
  const base64 = fromByteArray(binary);
  return base64;
};

export const fetchBlobFromUrl = (blobUrl: any) => {
  return fetch(blobUrl)
    .then((response) => response.blob())
    .catch((error) => {
      throw new Error(error);
    });
};

export const convertBlobToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64Data = reader.result;
      if (typeof base64Data === 'string') {
        const dataURL = base64Data.replace(/^data:[^;]+/, 'data:image/png');
        resolve(dataURL);
        resolve(base64Data);
      } else {
        reject(new Error('Failed to convert blob to base64'));
      }
    };

    reader.onerror = reject;

    reader.readAsDataURL(blob);
  });
};

export const setExistingThumbnail = async ({
  file,
  thumbnail,
}: {
  file: any;
  thumbnail: any;
}) => {
  try {
    const {
      data: { jwt_ott, user_token, gateway: thumbEndpoint },
    } = await getOneTimeToken([
      {
        filename: file.name,
        filesize: file.size,
        isPublic: true,
      },
    ]);
    const thumbToken = user_token[0].token;
    const fileName = convertTextToBase64(file.name);
    fetchBlobFromUrl(thumbnail)
      .then(async (blob) => {
        if (blob?.type === 'text/xml') {
          return await blob.text();
        }
        return convertBlobToBase64(blob);
      })
      .then((base64Data: any) => {
        axios
          .create({
            headers: {
              'x-file-name': fileName,
              'Content-Type': 'application/octet-stream',
              'one-time-token': thumbToken,
              'X-Upload-OTT-JWT': jwt_ott[0],
            },
          })
          .post(`${thumbEndpoint.url}/chunked/thumb/${file?.slug}`, base64Data);
      })
      .catch((e) => {
        console.error('ERROR', e);
      });
  } catch (e) {
    console.error('ERROR', e);
  }
};
