import React, { useState, useContext } from 'react';
import cn from 'classnames';

import UserContext from 'store/home/contexts/user-context';

const ManageShare = ({ users, onCancel }) => {
  const [canView, setCanView] = useState('true');
  const [canEdit, setCanEdit] = useState('true');
  const [canInvite, setCanInvite] = useState('true');
  const { user } = useContext(UserContext);

  const usersContainer =
    users &&
    users.map((item) => (
      <section
        className="manage-share__user-container"
        key={`user-info-${item}`}
      >
        <div
          className={cn({ 'file__checkbox checkbox': true, active: false })}
        />
        <div className="manage-share__user-name">
          {item}
          <div className="manage-share__user-email">{item}</div>
        </div>
        <div className="manage-share__user-permissions">View / Edit / See</div>
      </section>
    ));

  return (
    <div className="right-menu__container manage-share">
      <a href="#users" className="link">
        {users.length || '0'} Users
      </a>

      {usersContainer}

      <label className="right-menu__label">
        Permissions
        <div className="checkbox__container">
          <div
            className={cn({
              'file__checkbox checkbox': true,
              active: canView,
            })}
            onClick={() => setCanView(!canView)}
          />
          <div className="checkbox__label">Can view</div>
          <div
            className={cn({
              'file__checkbox checkbox': true,
              active: canEdit,
            })}
            onClick={() => setCanEdit(!canEdit)}
          />
          <div className="checkbox__label">Can edit</div>
          <div
            className={cn({
              'file__checkbox checkbox': true,
              active: canInvite,
            })}
            onClick={() => setCanInvite(!canInvite)}
          />
          <div className="checkbox__label">Can invite</div>
        </div>
      </label>

      <section className="manage-share__user-container">
        <div className="manage-share__user-name">
          You
          <div className="manage-share__user-email">{user.username}</div>
        </div>
        <div className="manage-share__user-permissions">Owner</div>
      </section>

      <button
        className="button button--primary button--primary_outline text--medium text--12 text--uppercase middle-button"
        type="button"
      >
        REMOVE SELECTED USERS
      </button>

      <button
        className="button button--primary text--medium text--12 text--uppercase bottom-button"
        type="button"
      >
        CREATE
      </button>
      <button className="link" type="button" onClick={onCancel}>
        Cancel
      </button>
    </div>
  );
};

export default ManageShare;
