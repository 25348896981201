/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'

const RemoveUserFromTeam = ({ user, id, onDeleteTeam, onCancel }) => {
  const [removeRadio, setRemoveRadio] = useState(true)

  const deleteTeamHandler = () => {
    if (removeRadio) {
      onDeleteTeam({ id })
    }
  }

  const onRadioChange = (e) => {
    if (e.target.value === 'no') {
      setRemoveRadio(false)
    } else {
      setRemoveRadio(true)
    }
  }

  return (
    <div className="right-menu__container remove-user">
      <p>
        If you remove <a href={`mailto:${user.username}`}>{user.username}</a>,
        they won't be able to see future changes to this shared folder.
      </p>

      <div className="right-menu__label">
        Remove wipe
        <div className="right-menu__input-container">
          <label className="right-menu__input-radio-label">
            <input
              type="radio"
              name="remove"
              className="right-menu__input-radio"
              value="yes"
              checked={removeRadio}
              onChange={onRadioChange}
            />
            <span className="right-menu__input-radio-styled" />
            <span className="right-menu__input-radio-title">Yes</span>
          </label>
          <label className="right-menu__input-radio-label">
            <input
              type="radio"
              name="remove"
              className="right-menu__input-radio"
              value="no"
              checked={!removeRadio}
              onChange={onRadioChange}
            />
            <span className="right-menu__input-radio-styled" />
            <span className="right-menu__input-radio-title">No</span>
          </label>
        </div>
      </div>

      <button
        className="button button--primary text--medium text--12 text--uppercase bottom-button"
        type="button"
        onClick={deleteTeamHandler}
      >
        REMOVE
      </button>
      <button className="link" type="button" onClick={onCancel}>
        Cancel
      </button>
    </div>
  )
}

export default RemoveUserFromTeam
