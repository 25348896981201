// New
export const VAULTS_GET = 'VAULTS_GET';
export const VAULTS_GET_SUCCESS = 'VAULTS_GET_SUCCESS';
export const GET_VAULT_REMOVE = 'GET_VAULT_REMOVE';
export const RESET_STATE = 'VAULTS_RESET_STATE';
export const SET_VAULTS = 'SET_VAULTS';
export const VAULTS_GET_FAILURE = 'VAULTS_GET_FAILURE';
export const UPDATE_VAULT_SUCCESS = 'UPDATE_VAULT_SUCCESS';
export const DELETE_VAULT_SUCCESS = 'DELETE_VAULT_SUCCESS';

export const vaultsGet = (payload) => ({
  type: VAULTS_GET,
  payload: payload,
});

export const vaultsGetSuccess = (data) => ({
  type: VAULTS_GET_SUCCESS,
  payload: data,
});

export const removeVaultSuccess = (data) => ({
  type: GET_VAULT_REMOVE,
  payload: { data },
});

export const vaultsGetFailure = (err) => ({
  type: VAULTS_GET_FAILURE,
  payload: err,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const setVaults = (vaults) => ({
  type: SET_VAULTS,
  payload: vaults,
});

export const updateVaultSuccess = (vault) => ({
  type: UPDATE_VAULT_SUCCESS,
  payload: vault,
});

export const deleteVaultSuccess = (slug) => ({
  type: DELETE_VAULT_SUCCESS,
  payload: slug,
});
