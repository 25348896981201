export const NEEDED_ENCRYPT_FILE = 'NEEDED_ENCRYPT_FILE';
export const ENCRYPT_EXISTING_FILE = 'ENCRYPT_EXISTING_FILE';
export const HANDLE_ENCRYPT_MODAL = 'HANDLE_ENCRYPT_MODAL';
export const HANDLE_UPLOAD_DROPED_FILES = 'HANDLE_UPLOAD_DROPED_FILES';
export const ENTER_ENCRYPTION_KEY = 'ENTER_ENCRYPTION_KEY';
export const ENCRYPT_EXISTING_FILE_ERROR = 'ENCRYPT_EXISTING_FILE_ERROR';
export const ENCRYPT_DROPED_FILES = 'ENCRYPT_DROPED_FILES';

export function needEncryptFile(bool: boolean) {
  return {
    type: NEEDED_ENCRYPT_FILE,
    payload: {
      needEncryptFile: bool,
    },
  };
}

export function encryptExistingFile(bool: boolean | undefined) {
  return {
    type: ENCRYPT_EXISTING_FILE,
    payload: {
      encryptExistingFile: bool,
    },
  };
}

export function handleEncryptModal(bool: boolean) {
  return {
    type: HANDLE_ENCRYPT_MODAL,
    payload: {
      isOpen: bool,
    },
  };
}

export function handleUploadDropedFiles(bool: boolean) {
  return {
    type: HANDLE_UPLOAD_DROPED_FILES,
    payload: {
      isStart: bool,
    },
  };
}
export function handleShowEncryptionKeyModal(bool: boolean | string) {
  return {
    type: ENTER_ENCRYPTION_KEY,
    payload: {
      encryptionKeyModal: bool,
    },
  };
}
export function handleEncryptFileError(data: string | undefined) {
  return {
    type: ENCRYPT_EXISTING_FILE_ERROR,
    payload: {
      handleEncryptFileError: data,
    },
  };
}
export function handleDropedFile(data: any) {
  return {
    type: ENCRYPT_DROPED_FILES,
    payload: data,
  };
}
