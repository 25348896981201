//Refactoring №3
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { selectTechnicalWorksState } from 'features/app';

import { ReactComponent as InfoIcon } from './assets/info.svg';

import styles from './styles.module.scss';

const TapeOfTechnicalWorks = () => {
  const { t } = useTranslation('common');
  const isTechnicalWorks = useSelector(selectTechnicalWorksState);

  if (!isTechnicalWorks) return null;

  return (
    <div className={styles.wrapper}>
      <InfoIcon className={styles.icon} />
      {t('common.technicalMaintenance')}
    </div>
  );
};

export default TapeOfTechnicalWorks;
