import { store } from 'store/root-store';
import { getDownloadOTT } from '../files-upload/upload-file.effect';
import { addGatewayDataForThumbnails } from 'store/home/actions';
import { IFile } from 'gdgateway-client/lib/es5/process/types';

interface IGatewayData {
  url: string;
  token: string;
  jwt_ott: string;
}

interface IResult {
  [slug: string]: IGatewayData;
}

export const getOttForThumbnails = async (
  files: IFile[],
  pushToStore = true
) => {
  try {
    const filter = files.filter((el) => !el?.thumbnail && el?.preview_small);
    const slugs = filter.map((el) => el.slug);

    if (slugs.length === 0) {
      return;
    }
    const res = await getDownloadOTT(slugs);

    const { jwt_ott, user_tokens, gateway } = res.data;

    const result: IResult = {};
    for (const slug of user_tokens.slugs) {
      result[slug] = {
        url: gateway.url,
        token: user_tokens.token,
        jwt_ott: jwt_ott,
      };
    }

    pushToStore && store.dispatch(addGatewayDataForThumbnails(result));
    return result;
  } catch (error) {
    console.log('error', error);
  }
};
