//Refactoring №3
import React from 'react';
import { LIGHT_THEME, STORAGE_KEY } from 'store/context/theme';

import { ReactComponent as LogoIcon } from './assets/logo.svg'

import s from './style.module.scss';

const DARK = '#000';
const LIGHT = '#FFFFFF';

const Preloader = ({ text = '' }) => {
  const theme = localStorage.getItem(STORAGE_KEY)
  const isLightTheme = theme === LIGHT_THEME

  return (
    <section
      style={{ backgroundColor: theme === LIGHT_THEME ? LIGHT : DARK }}
      id="preloader"
      className={s.preloader}
    >
      <div className={s.loader}>
        <LogoIcon className={isLightTheme ? s.loadeLight :  s.loadeDark} />
      </div>
      {text && <div className={s.preloader__text}>{text}</div>}
    </section>
  );
};

export default Preloader;
