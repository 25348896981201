/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import CN from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import NeyraBorder from 'components/NeyraBorder';
import NeyraSidebar from 'components/NeyraSidebar';
import SidebarItem from 'containers/main/NeyraDriveContainer/components/SidebarItem';
import SidebarFooter from 'containers/main/NeyraDriveContainer/components/SidebarFooter';

import { ReactComponent as AccountIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/account.svg';
import { ReactComponent as SettingsIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/settings.svg';
import { ReactComponent as NotificationsIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/notifications.svg';
import { ReactComponent as LogsIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/logs.svg';
import { ReactComponent as SecurityIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/security.svg';
import { ReactComponent as WalletIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/wallet.svg';
import { ReactComponent as RechargeIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/recharge.svg';
import { ReactComponent as CreditFetures } from 'containers/main/Main/components/SettingsSidebar/assets/v2/creditFetures.svg';
import { ReactComponent as FileEncryptionIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/fileEncryption.svg';
import { ReactComponent as PlanetaIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/planeta.svg';
import { ReactComponent as HistoryIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/history.svg';
import { ReactComponent as LogoutIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/log-out.svg';
import { ReactComponent as MultisigIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/multisig.svg';
import { ReactComponent as Settings2Icon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/settings2.svg';
// import { ReactComponent as MembersIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/memebers.svg';


import removeToken from 'utils/auth/remove-token';
import { workspaceResetFileCountByType } from 'features/workspaceSettings/workspaceSettingsSlice';
import { resetUploadingFiles } from 'store/home/actions';
import { STORAGE_KEY } from 'store/context/theme';

import styles from './styles.module.scss';
import { useHistory } from 'react-router';

const SettingsContainer = ({ children, isGuest }) => {
  const isWalletPage = useRouteMatch('/wallet/:slug');
  const isAccountPage = useRouteMatch('/account/:slug');
  const history = useHistory();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation('sidebar');
  const { t: accountT } = useTranslation('account');
  const { t: workspaceT } = useTranslation('workspace');

  const logOut = (e) => {
    document.documentElement.removeAttribute(STORAGE_KEY);
    e.preventDefault();
    e.stopPropagation();
    removeToken();
    dispatch(resetUploadingFiles());
    dispatch(workspaceResetFileCountByType());
    window.location.href = `${window.location.origin}/connect`;
  };

  const logOutOption = {
    name: t('accountSettings.logOut'),
    icon: <LogoutIcon />,
    action: logOut,
    url: '',
  };

  const walletMenuItems = useMemo(
    () => [
      {
        name: accountT('Settings.wallet'),
        icon: <WalletIcon />,
        url: '/wallet/bank',
        backOnClick: () => { history.goBack() },
        backButton: true,
        isMain: true,
        showCurrentRef: true,
      },
      {
        name: accountT('tokens.buyTokens'),
        icon: <RechargeIcon />,
        url: '/wallet/buy-credits',
      },
      {
        name: accountT('tokens.history'),
        icon: <HistoryIcon />,
        url: '/wallet/credits',
      },
      {
        name: accountT('storage.features'),
        icon: <CreditFetures />,
        url: '/wallet/features',
      },
    ], [i18n.language]);

  const accountMenuItems2 = useMemo(
    () => [
      {
        collapsed: false,
        icon: <SettingsIcon />,
        name: t('accountSettings.accSettings'),
        action: () => { history.push('/account/settings') },
        backButton: true,
        isMain: true,
        backOnClick: () => { history.goBack() }
      },
      {
        name: t('accountSettings.notifications'),
        icon: <NotificationsIcon />,
        url: '/account/notifications',
      },
      // {
      //   name: t('workspaceSettings.members'),
      //   icon: <NotificationsIcon />,
      //   url: '/account/members',
      // },
      {
        name: "Language",
        icon: <PlanetaIcon />,
        url: '/account/language',
      },
      {
        name: accountT('Logs.logs'),
        icon: <LogsIcon />,
        url: '/account/logs',
      },
      logOutOption
    ], [i18n.language]
  )

  const workspaceMenuItems2 = useMemo(
    () => [
      {
        name: workspaceT('settings.driveSettings'),
        icon: <Settings2Icon />,
        url: '/workspace/settings',
        backOnClick: () => { history.goBack()},
        backButton: true,
        isMain: true,
        showCurrentRef: true,
      },
      {
        name: accountT('security.MultiSig'),
        icon: <MultisigIcon />,
        url: '/workspace/multisig',
      },
      {
        name: t('accountSettings.security'),
        icon: <SecurityIcon />,
        url: '/workspace/security-layers',
      },
      {
        name: accountT('Settings.fileEncryption'),
        icon: <FileEncryptionIcon />,
        url: '/workspace/encryption',
      },
    ], [i18n.language])

  const guestAccountMenuItems = useMemo(
    () => [
      {
        name: t('accountSettings.profile'),
        icon: <AccountIcon />,
        url: '/account/settings',
      },
    ],
    [i18n.language]
  );


  const list = isGuest
    ? [...guestAccountMenuItems, logOutOption]
    : (isWalletPage ?
        walletMenuItems : (
          isAccountPage ? accountMenuItems2 :  workspaceMenuItems2
        )
      );

  return (
    <div className={styles.settingsContainer}>
      <NeyraBorder>{children}</NeyraBorder>
      <NeyraSidebar>
        <div className={styles.siteSidebar}>
          <div
            className={CN(
              styles.settingsList,
            )}
          >
            {list.map((item, index) => (
              <SidebarItem key={`settings-item-${index}`} item={item} />
            ))}
          </div>
          <SidebarFooter />
        </div>
      </NeyraSidebar>
    </div>
  );
};

export default SettingsContainer;
