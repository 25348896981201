export const UPDATE_WEB3_CHECKER = 'UPDATE_WEB3_CHECKER';
export const UPDATE_START_WEB3_CHECKER = 'UPDATE_START_WEB3_CHECKER';
export const UPDATE_CURRENT_CHAIN_ID = 'UPDATE_CURRENT_CHAIN_ID';
export const START_CHECK_WITH_CALLBACK = 'START_CHECK_WITH_CALLBACK';
export const ADD_CALLBACK = 'ADD_CALLBACK';

export function updateWeb3Checker(checker) {
  return {
    type: UPDATE_WEB3_CHECKER,
    payload: checker,
  };
}

export function updateStartWeb3Checker(status, entities, additionalData) {
  return {
    type: UPDATE_START_WEB3_CHECKER,
    payload: { status, entities, additionalData },
  };
}

export function updateCurrentChainId(chainId) {
  return {
    type: UPDATE_CURRENT_CHAIN_ID,
    payload: { chainId },
  };
}

export function startCheckWithCallback(status, callback, neededChainId) {
  return {
    type: START_CHECK_WITH_CALLBACK,
    payload: { status, callback, neededChainId },
  };
}

export function addCallback(callback) {
  return {
    type: ADD_CALLBACK,
    payload: { callback },
  };
}
