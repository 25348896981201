export const AccountActionTypes = {
  saveAccountData: 'saveAccountData',
  setIsAccountDataChanged: 'setIsAccountDataChanged',
}

export const setIsAccountDataChanged = (isDataChanged) => ({
  payload: {
    isDataChanged,
  },
  type: AccountActionTypes.setIsAccountDataChanged,
})

export const setSaveAccountData = (saveAccountData) => ({
  payload: {
    saveAccountData,
  },
  type: AccountActionTypes.saveAccountData,
})
