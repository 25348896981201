// utils
import authRequest from 'utils/request/auth-request';
import { API_PAYMENT, API_WORKSPACE } from 'constants/api-urls';

export const activateFreeTariffEffect = async () =>
  authRequest
    .put(`${process.env.REACT_APP_API_PATH}/attach/free/subscription`)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });

export const checkPayedTariffEffect = async (token) => {
  return authRequest
    .get(`${API_PAYMENT}/done?token=${token}`)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const updateTariffEffect = async (amount) => {
  return authRequest
    .get(`${API_PAYMENT}/stripe/subscription/update?totalAmount=${amount}`)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const getNFTMeta = async (chainId) => {
  return authRequest
    .get(`${process.env.REACT_APP_API_PATH}/net/${chainId}/shopSimple`)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const getUserByReferral = async (referral = '') => {
  return authRequest
    .get(
      `${process.env.REACT_APP_API_PATH}/referral/wallet?referral=${referral}`
    )
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
};

export const setNftSubscription = async (publicAddress) =>
  authRequest
    .post(`${API_PAYMENT}/nft`, { publicAddress })
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });

export const setWorkspaceSubscription = async (body) =>
  authRequest
    .post(`${API_WORKSPACE}/create`, body)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });

export const updateWorkspaceMembersStripe = async (body) =>
  authRequest
    .post(`${API_WORKSPACE}/update/member`, body)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });

export const updateWorkspaceMembers = async (body) =>
  authRequest
    .put(`${API_WORKSPACE}`, body)
    .then((response) => response?.data)
    .catch((error) => {
      throw error;
    });
