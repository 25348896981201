// Refactoring №5
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

const Tooltip = () => {
  const { t } = useTranslation('contextMenu');
  return (
    <div className={styles.tooltipWrapper}>
      <p className={styles.tooltipTitle}>{t('main.imageToImage.oopss')}</p>
      <p className={styles.tooltipText}>{t('files.fillOneField')}</p>
      <div className={styles.arrowDown}></div>
    </div>
  );
};

export default Tooltip;
