import React from "react";

import { titles } from 'config/neyra-bios';
import BiosIcons from 'static/assets/svg/bios_icons';


import styles from './styles.module.scss';

const TalentList = ({ bios, onSelectShape }) => (
  <>
    {bios?.spheres?.map((item, index) => {
      if (index === 4) {
        return (
          <div
            key={`talent-${index}`}
            className={styles.biosTalent}
            onClick={() => {
              onSelectShape(
                titles[item.reverse.talent_index],
                item.reverse.talent_hr_description
              );
            }}
          >
            <button className={styles.biosCircle}>
              {
                BiosIcons[
                  titles[item.reverse.talent_index]
                    ?.toLowerCase()
                    ?.replaceAll(`'`, '')
                    ?.split(' ')
                    .join('_')
                ]
              }
            </button>
            <div className={styles.description}>
              <p className={styles.biosTalentTitle}>
                {titles[item.reverse.talent_index]}
              </p>
              <p className={styles.biosTalentText}>
                {item.reverse.talent_corp_hint}
              </p>
            </div>
          </div>
        );
      }

      return (
        <>
          <div
            key={`talent-${index}`}
            className={styles.biosTalent}
            onClick={() => {
              onSelectShape(
                titles[item.simple.talent_index],
                item.simple.talent_hr_description
              );
            }}
          >
            <button className={styles.biosCircle}>
              {
                BiosIcons[
                  titles[item.simple.talent_index]
                    ?.toLowerCase()
                    ?.replaceAll(`'`, '')
                    ?.split(' ')
                    .join('_')
                ]
              }
            </button>
            <div className={styles.description}>
              <p className={styles.biosTalentTitle}>
                {titles[item.simple.talent_index]}
              </p>
              <p className={styles.biosTalentText}>
                {item.simple.talent_corp_hint}
              </p>
            </div>
          </div>
          <div
            className={styles.biosTalent}
            onClick={() => {
              onSelectShape(
                titles[item.reverse.talent_index],
                item.reverse.talent_hr_description
              );
            }}
          >
            <button className={styles.biosCircle}>
              {
                BiosIcons[
                  titles[item.reverse.talent_index]
                    ?.toLowerCase()
                    ?.replaceAll(`'`, '')
                    ?.split(' ')
                    .join('_')
                ]
              }
            </button>
            <div className={styles.description}>
              <p className={styles.biosTalentTitle}>
                {titles[item.reverse.talent_index]}
              </p>
              <p className={styles.biosTalentText}>
                {item.reverse.talent_corp_hint}
              </p>
            </div>
          </div>
        </>
      );
    })}
  </>
)

export default TalentList;