import web3 from 'web3';
import { Contract, ethers } from 'ethers';
import authRequest from 'utils/request/auth-request';
import { getCurrentChainId } from 'utils/crypto';
import { getSigner } from 'store/auth/effects';
import {
  updateNftMetadataEffect,
  reindexNftMetadataEffect,
} from 'store/file/effects';
import { getProvider, web3ErrorHandler } from 'utils/web3Helper';
import { API_GET_FILE, API_WORKSPACE } from 'constants/api-urls';

export const COLECTION_COLORS = ['#54BB8E', '#F2BF65', '#C085EC'];

const DEFAULT_TYPE_FILE = 2;

const TOKEN_ALICE = 'fileTokenizationToken';
const FACTORY_ALICE = 'fileTokenizationFactory';

export const getAbi = async (networkId, contractAlias) => {
  const {
    data: { data },
  } = await authRequest.get(
    `${process.env.REACT_APP_API_PATH}/net/${networkId}/${contractAlias}`
  );

  return data;
};

export const saveWorkspaceTransaction = (data) => {
  return authRequest.post(
    `${process.env.REACT_APP_API_PATH}/workspace/workspace-tx`,
    data
  );
};

export const getSigne = async (currentProvider = null) => {
  const provider = new ethers.providers.Web3Provider(
    currentProvider ?? window.metamask
  );

  const walletsList = await provider.provider.request({
    method: 'eth_requestAccounts',
  });
  if (!walletsList.length) {
    return;
  }
  const walletAddress = walletsList[0];
  return provider.getSigner(walletAddress);
};

export const getCollectionContract = async (
  collectionHash,
  currentProvider
) => {
  const signer = await getSigne(currentProvider);
  const network = await getCurrentChainId({ useMetamask: true });
  const { abi } = await getAbi(network, TOKEN_ALICE);

  const collectionContract = new Contract(collectionHash, abi, signer);
  return collectionContract;
};

export const attachContract = async (options) => {
  const data = await authRequest.post(
    `${API_WORKSPACE}/attach/contract`,
    options
  );

  return data;
};

export const getNetworksEffects = async () => {
  try {
    const {
      data: { data },
    } = await authRequest.get(`${process.env.REACT_APP_API_PATH}/net/list`);
    return data;
  } catch (error) {
    console.warn(error.message);
  }
};

export const switchNetwork = async (network, provider) => {
  const currentProvider = provider || window.metamask;

  try {
    const currentChainId = await getCurrentChainId(
      { useMetamask: true },
      currentProvider
    );
    if (currentChainId === network.chainId) {
      return true;
    }

    const hexChainId = web3.utils.toHex(network.chain_id);

    try {
      await currentProvider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }],
      });
      return true;
    } catch (error) {
      if (error.code === 4902 || error.code === -32603) {
        try {
          await currentProvider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: hexChainId,
                chainName: network.name,
                rpcUrls: [network.rpc_url],
                nativeCurrency: {
                  name: network.name,
                  symbol: network.symbol,
                  decimals: 18,
                },
              },
            ],
          });
          await currentProvider.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: hexChainId }],
          });
          return true;
        } catch (error) {
          return false;
        }
      }
    }
  } catch (error) {
    return false;
  }
};

export const getFactoryContract = async (hash, chain_id) => {
  try {
    let network = chain_id;
    if (!chain_id) network = await getCurrentChainId({ useMetamask: true });
    const signer = await getSigne();
    const { abi: factoryAbi } = await getAbi(network, FACTORY_ALICE);

    const factoryContract = new Contract(hash, factoryAbi, signer);
    return factoryContract;
  } catch (error) {
    console.warn(error);
    throw error;
  }
};

export const createCollection = async (
  name,
  callback,
  errorHandler,
  currentProvider
) => {
  try {
    const network = await getCurrentChainId(
      { useMetamask: true },
      currentProvider
    );
    const signer = await getSigne(currentProvider);
    const { abi: factoryAbi, address: factoryAddress } = await getAbi(
      network,
      FACTORY_ALICE
    );

    const workspace = localStorage.getItem('workspace');
    const workspaceId = JSON.parse(workspace)?.id;

    const req = {
      workspaceId,
      name,
      description: '',
    };
    const factoryContract = new Contract(factoryAddress, factoryAbi, signer);
    const {
      data: { reqCooked, signature },
    } = await getSigner({
      type: 'SNewFileACCall',
      contract: 'FileAccessCollectionFactoryUpgradeable',
      contractAddress: factoryContract.address,
      chainId: network,
      req,
      currentProvider,
    });

    console.warn(reqCooked);
    const contract = await factoryContract.newFileAccessContract(
      reqCooked,
      signature,
      { value: reqCooked.callCost }
    );
    const tx = await contract.wait();

    let contractAddress = tx.events.find(
      (item) => item.event === 'AddNewFileAccessContract'
    ).args['contractAddress'];
    const collectionOption = {
      name,
      contract: contractAddress,
      type: DEFAULT_TYPE_FILE,
      network,
    };
    await callback(collectionOption);
    await attachContract(collectionOption);
  } catch (error) {
    console.warn({ error });
    errorHandler();
    web3ErrorHandler(error);
    throw error;
  }
};

export const createToken = async (addTokenData, onSuccess, stopLoading) => {
  try {
    const collectionContract = await getCollectionContract(addTokenData.hash);
    const slug = await getGroupSlug([addTokenData.fileId]);
    let realTokenId = await collectionContract.fileToToken(slug);
    const chainId = await getCurrentChainId({ useMetamask: true });
    if (realTokenId.isZero()) {
      let req = {
        slug,
        royalties: addTokenData.royalties,
        tokenMaxSupply: addTokenData.supply,
      };
      const {
        data: { reqCooked, signature },
      } = await getSigner({
        type: 'SAddTokenCall',
        contract: 'FileAccessCollectionUpgradeable',
        contractAddress: collectionContract.address,
        chainId,
        req,
      });

      const tx = await collectionContract.addToken(reqCooked, signature, {
        value: reqCooked.callCost,
      });
      const receipt = await tx.wait();
      const createTokenEvent = receipt.events.find(
        (item) => item.event === 'TransferSingle'
      );

      const realTokenId = createTokenEvent.args['id'];
      const address = createTokenEvent.address;
      onSuccess({
        collection: collectionContract.address,
        token: realTokenId.toString(10),
        name: addTokenData.name,
        description: addTokenData.description,
        network: chainId,
        royalties: addTokenData.royalties / 100,
        tokenMaxSupply: addTokenData.supply,
        address,
        slug,
      });
      await updateNftMetadataEffect({
        txId: receipt.transactionHash,
        token: realTokenId.toString(),
        name: addTokenData.name,
        description: addTokenData.description,
        network: chainId,
      });
      await reindexNftMetadataEffect(
        addTokenData.fileId,
        collectionContract.address
      );
    } else {
      console.warn('Token for this file already exists');
      stopLoading();
      web3ErrorHandler('Token for this file already exists');
    }
  } catch (error) {
    stopLoading();
    console.warn({ error });
    web3ErrorHandler(error);
  }
};

export const mintToken = async (
  { tokenId, addressArray, supplysArray, collectionHash },
  stopLoading
) => {
  try {
    const collectionContract = await getCollectionContract(collectionHash);
    const chainId = await getCurrentChainId({ useMetamask: true });
    const req = {
      id: tokenId,
      to: addressArray,
      amounts: supplysArray,
    };
    const {
      data: { reqCooked, signature },
    } = await getSigner({
      type: 'SMintMultipleCall',
      contract: 'FileAccessCollectionUpgradeable',
      contractAddress: collectionContract.address,
      chainId,
      req,
    });
    const mintedToken = await collectionContract.mintMultiple(
      reqCooked,
      signature,
      { value: reqCooked.callCost }
    );
    const endOfMinting = await mintedToken.wait();
    if (endOfMinting) {
      return 'success';
    }
  } catch (error) {
    console.warn(error);
    stopLoading();
    web3ErrorHandler(error);
  }
};

export const getCollectionsListEffect = async () => {
  try {
    const network = await getCurrentChainId({ useMetamask: true });
    const { abi } = await getAbi(network, TOKEN_ALICE);
    const { abi: factoryAbi, address: factoryAddress } = await getAbi(
      network,
      FACTORY_ALICE
    );
    const provider = await new ethers.providers.Web3Provider(
      window.metamask,
      'any'
    );
    const walletsList = await provider.provider.request({
      method: 'eth_requestAccounts',
    });
    if (!walletsList.length) {
      return;
    }
    const walletAddress = walletsList[0];
    const signer = await provider.getSigner(walletAddress);
    const factoryContract = await new Contract(
      factoryAddress,
      factoryAbi,
      signer
    );
    const workspace = localStorage.getItem('workspace');
    const workspaceId = JSON.parse(workspace)?.id;
    const collections = [];

    const fileContracts = await factoryContract.getFileAccessContracts(
      workspaceId
    );

    for (let index = 0; index < fileContracts.length; index++) {
      const element = fileContracts[index];
      const collectionContract = await new Contract(element, abi, signer);
      const name = await collectionContract.name();
      const validCollection = {
        text: name,
        color: COLECTION_COLORS[index % 3],
        hash: element,
      };

      await collections.push(validCollection);
    }
    return collections;
  } catch (error) {
    console.warn('error', error);
  }
};

export const getNetworkListEffect = async () =>
  await authRequest
    .get(`${process.env.REACT_APP_API_PATH}/net/list`)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.message;
    });

export const getGroupSlug = async (fileId) => {
  const {
    data: { data },
  } = await authRequest.post(`${API_GET_FILE}/multiply/entry/group`, [fileId]);

  return data.slug;
};

export const networkSwitcher = async (chainId, showNotification = true) => {
  try {
    const currentChainId = await getCurrentChainId();
    if (chainId !== currentChainId) {
      const { data: networkList } = await getNetworkListEffect();
      const nework = networkList.find((net) => net.id === chainId);
      const provider = await getProvider();
      const res = await switchNetwork(nework, provider);
      if (res) {
        Promise.resolve();
      } else {
        throw new Error('User rejected the request.');
      }
    } else {
      Promise.resolve();
    }
  } catch (error) {
    showNotification && web3ErrorHandler(error);
    throw new Error('User rejected the request.');
  }
};
