//Refactoring №5
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { switchWorkspace } from 'store/workspace/effects';
import { getWorkspaceBySlug } from 'store/workspace/effects/';

import Avatar from 'components/Avatar';
import Preloader from 'components/shared/Preloader';
import { Workspace } from 'docs/types/workspace';
import Header from './Header'
import Footer from './Footer'

import RequestToJoinBlock from './RequestToJoinBlock';
import LoginButtonsList from './LoginButtonsList';

import style from './style.module.scss';

const JoinToWorkspace = () => {
  const { pathname } = useLocation();
  const  history  = useHistory();
  const [isLoading, setLoading] = useState(false);
  const locationArray = useMemo(() => pathname.split('/') || [], [pathname]);
  const subdomain = useMemo(
    () => (locationArray.includes('w') ? locationArray[2] : ''),
    [locationArray]
  );
  const isLogin = useMemo(
    () => (locationArray.includes('login') ? locationArray[2] : ''),
    [locationArray]
  );
  const [workspace, setWorkspace] = useState<Workspace>({
    color: '',
    id: 0,
    is_workspace_member: false,
    is_workspace_owner: false,
    logo: '',
    name: '',
    smart_contracts: [],
  });

  useEffect(() => {
    getWorkspaceBySlug(subdomain).then((data) => {
      if (data?.is_workspace_member) {
        switchWorkspace(data.id).then((data) => {
          if (data.token) {
            localStorage.setItem('workspace', JSON.stringify(data));
            window.location = '/main';
          }
        });
      } else {
        setLoading(true);
        setWorkspace(data);
      }
    });
  }, []);

  if(!isLoading)
  return <Preloader />

  return (
    <div className={style.container}>
      <div className={style.content}>
        <Header />
        <p className={style.additionalTopText}>smart storage solution</p>
        <Avatar
          color={workspace.color}
          src={workspace?.logo ?? undefined}
          className={style.avatar}
          name={`${workspace.name}`}
        />
        <h1 className={style.welcomeTitle}>
          Welcome to {workspace.name} workspace
        </h1>
        <div className={style.loginList}>
          {isLogin ? <LoginButtonsList subdomain={subdomain} history={history} /> 
          :<RequestToJoinBlock {...workspace} slug={subdomain}/>
          }
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JoinToWorkspace;
