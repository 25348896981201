// Refactoring №3
import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { aspectRatioSizes, imageSizeOptions } from '..';
import { getPromtHistory } from 'store/aiGenerator/effects';
import { getGenerationFilterOptions } from '../utils/filter_options';

import FiltersList from './GenerationFilters';
import SidebarFooter from 'containers/main/NeyraDriveContainer/components/SidebarFooter';
import { CopyButton } from 'components/Button';
import { ReactComponent as PlusIcon } from '../assets/plus.svg';
import { ReactComponent as MinusIcon } from '../assets/minus.svg';
import { ReactComponent as StudioIcon } from '../assets/studio_logo.svg';
import { ReactComponent as ArrowBackIcon } from '../assets/arrow_back.svg';

import CN from 'classnames';
import style from './ai-sidebar.module.scss';

const initialHistoryState = {
  initial: [],
  filtered: [],
};

type aiSidebarPropTypes = {
  setImageFilter: (arg: string) => void;
  setImageAspectRatio: (arg: any) => void;
  imageSize: number;
  setImageSize: (arg: number) => void;
  imageFilter: string;
  imageCount: number;
  setImageCount: (arg: number) => void;
  setSecretPromt: (arg: string) => void;
  isGeneratingCompleted: boolean;
  isDrawTab: boolean;
  imageAspectRatio: any;
  setCreativityStrength: (arg: number) => void;
  creativityStrength: number;
  handleActiveTab: any;
  isGenerateTab: boolean;
  is3DTab: boolean;
};

const AiGeneratorSidebar = ({
  setImageFilter,
  setImageAspectRatio,
  setImageSize,
  imageSize,
  imageFilter,
  imageCount,
  setImageCount,
  setSecretPromt,
  isGeneratingCompleted,
  isDrawTab,
  isGenerateTab,
  is3DTab,
  imageAspectRatio,
  creativityStrength,
  setCreativityStrength,
  handleActiveTab,
}: aiSidebarPropTypes) => {
  const [activeTab, setActiveTab] = useState('styles');
  const [promptHistory, setPromptHistory] = useState(initialHistoryState);
  const [isCopy, setCopy] = useState(false);
  const [generationModel, setGenerationModel] = useState(2);
  const isStylesTab = activeTab === 'styles';
  const { t } = useTranslation('owner');
  const history = useHistory();
  // const searchInputRef = useRef(null);
  const filter_options = useMemo(
    () => getGenerationFilterOptions(isDrawTab),
    [isDrawTab]
  );
  const [imageFilters, setImageFilters] = useState(filter_options);
  const sidebarWrapper = document.getElementById('sidebar') as HTMLDivElement;

  const getHistory = async () => {
    try {
      const data = await getPromtHistory();
      if (data?.code) throw new Error(data?.message);
      setPromptHistory({ initial: data, filtered: data });
    } catch (error) {
      setPromptHistory(initialHistoryState);
    }
  };

  useEffect(() => {
    const filters = getGenerationFilterOptions(isDrawTab);
    setImageFilters(filters);
    // searchInputRef.current.value = '';
    setImageFilter('');
  }, [isDrawTab]);

  useEffect(() => {
    if (isGeneratingCompleted) {
      getHistory();
    }
  }, [isGeneratingCompleted]);

  useEffect(() => {
    getHistory();
  }, []);

  const slider = document.getElementById('creativityRange');
  useEffect(() => {
    if (slider) {
      const min = slider.min;
      const max = slider.max;
      const value = slider.value;
      slider.style.background = `linear-gradient(to right, #fff 0%, #fff ${
        ((value - min) / (max - min)) * 100
      }%, var(--rangeInputBg) ${
        ((value - min) / (max - min)) * 100
      }%, var(--rangeInputBg)  100%)`;
      slider.oninput = function () {
        this.style.background = `linear-gradient(to right, #fff 0%, #fff ${
          ((this.value - this.min) / (this.max - this.min)) * 100
        }%, var(--rangeInputBg)  ${
          ((this.value - this.min) / (this.max - this.min)) * 100
        }%,var(--rangeInputBg)  100%)`;
      };
    }
  }, [slider]);

  const changeAspectRatio = (bool: boolean) => {
    const keys = Object.keys(aspectRatioSizes[imageSize]);
    const min = Number(keys[0]);
    const max = Number(keys[keys.length - 1]);
    const currentRatio = (ratio: number) => (bool ? ratio + 1 : ratio - 1);
    setImageAspectRatio((prev) => {
      if (currentRatio(prev.index) < min || currentRatio(prev.index) > max)
        return prev;
      return aspectRatioSizes[imageSize][currentRatio(prev.index)] as {
        width: number;
        height: number;
        aspectRatio: string;
        index: number;
      };
    });
  };

  const changeImageSize = (bool: boolean) => {
    const min = 0;
    const max = isDrawTab ? 1 : imageSizeOptions.length - 1;
    const sizeIndex = imageSizeOptions.indexOf(imageSize);
    const currentSize = bool ? sizeIndex + 1 : sizeIndex - 1;
    if (currentSize < min || currentSize > max) return;
    setImageSize(imageSizeOptions[currentSize]);
    setImageAspectRatio(aspectRatioSizes[imageSizeOptions[currentSize]][8]);
  };

  const changeImageCount = (bool: boolean) => {
    const max = 4;
    const min = 1;
    const currentCount = bool ? imageCount + 1 : imageCount - 1;
    if (currentCount < min || currentCount > max) return;
    setImageCount(currentCount);
  };

  const optionSelectionHandler = (e) => {
    const target = e.target as HTMLElement;
    const clickedElementWithId = target.closest('[id]');

    if (clickedElementWithId) {
      const clickedId = clickedElementWithId.getAttribute('id');
      if (clickedId === 'filters') return;
      if (
        (clickedId === 'neyra-face' ||
          clickedId === 'neyra-art' ||
          clickedId === 'neyra-punk') &&
        clickedId !== imageFilter
      ) {
        const scrtPrompt = filter_options.reduce((acc, opt) => {
          if (opt.label === clickedId) {
            return acc + opt?.prompt;
          } else {
            return acc;
          }
        }, '');
        setSecretPromt(scrtPrompt);
        setImageFilter(clickedId as string);
        return;
      } else if (clickedId === imageFilter) {
        setImageFilter('');
        setSecretPromt('');
      } else {
        setImageFilter(clickedId as string);
        setSecretPromt('');
      }
    }
  };

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    const filteredStyles = filter_options.filter((el) =>
      el.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    const filteredPrompts = promptHistory.initial.filter(
      (el: { text: string }) =>
        el.text.toLowerCase().includes(searchValue.toLowerCase())
    );
    setImageFilters(filteredStyles);
    setPromptHistory((prev) => ({ ...prev, filtered: filteredPrompts }));
  };

  const handleTabChange = (e: React.SyntheticEvent<EventTarget>) => {
    sidebarWrapper.classList.remove(style.shadowHidden);
    const clickedTab = (e.target as HTMLDivElement).id;
    setActiveTab(clickedTab);
  };
  const handleModelChange = (e: React.SyntheticEvent<EventTarget>) => {
    const clickedModel = (e.target as HTMLDivElement).id;
    if (clickedModel) setGenerationModel(Number(clickedModel));
  };

  const copyPrompt = (text: string) => {
    if (!isCopy) {
      setCopy(true);
      navigator.clipboard.writeText(text);
      setTimeout(() => {
        setCopy(false);
      }, 1000);
    }
  };

  const handleCreativityChange = (e) => {
    const { value, id } = e.target;
    const number =
      id === 'creativityRange'
        ? Number((value / 100).toFixed(2))
        : Number((value / 1).toFixed(2));
    setCreativityStrength(number);
  };

  const studioTabs = (
    <div
      className={CN(style.tabsWrapper, style.solidBorder)}
      onClick={handleActiveTab}
    >
      <div className={CN(isDrawTab && style.activeTab)} id="draw">
        Draw
      </div>
      <div className={CN(isGenerateTab && style.activeTab)} id="generate">
        Generate
      </div>
      <div className={CN(is3DTab && style.activeTab)} id="skybox">
        360°
      </div>
    </div>
  );

  const hideShadowOnScrollToEnd = () => {
    const filters = document.getElementById('filters') as HTMLDivElement;
    const history = document.getElementById(
      'promptHistory'
    ) as HTMLUListElement;
    const container = isStylesTab ? filters : history;
    const isAtBottom =
      container.scrollHeight - Number(container.scrollTop.toFixed()) ===
      container.clientHeight;

    if (isAtBottom) {
      sidebarWrapper.classList.add(style.shadowHidden);
    } else {
      sidebarWrapper.classList.remove(style.shadowHidden);
    }
  };

  return (
    <div className={style.sidebarWrapper} id="sidebar">
      <div className={style.backOption}>
        <span>
          <StudioIcon />
          Studio
        </span>
        <div
          onClick={() => {
            history.goBack();
          }}
        >
          <ArrowBackIcon />
        </div>
      </div>
      {studioTabs}
      <ul className={style.imageOptionsList}>
        {isDrawTab && (
          <>
            <li className={style.imageOptionsList__item}>
              <button onClick={() => changeAspectRatio(false)}>
                <MinusIcon />
              </button>
              <p>{imageAspectRatio.aspectRatio}</p>
              <button onClick={() => changeAspectRatio(true)}>
                <PlusIcon />
              </button>
            </li>
            <li className={style.imageOptionsList__item}>
              <button onClick={() => changeImageSize(false)}>
                <MinusIcon />
              </button>
              <p>{`${imageSize}x${imageSize}`}</p>
              <button onClick={() => changeImageSize(true)}>
                <PlusIcon />
              </button>
            </li>
            <li
              className={CN(style.imageOptionsList__item, style.dashedBorder)}
            >
              <div className={style.creativityWrapper}>
                <h3>Creativity Strength</h3>
                <input
                  type="range"
                  id="creativityRange"
                  min={30}
                  max={90}
                  onChange={handleCreativityChange}
                  value={creativityStrength * 100}
                />
              </div>
              <span className={style.creativityWrapper__value}>
                {creativityStrength}
              </span>
            </li>
          </>
        )}
        {isGenerateTab && (
          <li className={style.imageOptionsList__item}>
            <button
              onClick={() => {
                changeImageCount(false);
              }}
            >
              <MinusIcon />
            </button>
            <p>{`${imageCount}`}</p>
            <button
              onClick={() => {
                changeImageCount(true);
              }}
            >
              <PlusIcon />
            </button>
          </li>
        )}
      </ul>
      {/* <div className={style.searchWrapper}>
        <input
          placeholder={`${t('common.search')}`}
          onChange={handleFilterSearch}
          ref={searchInputRef}
        />
      </div> */}
      {is3DTab && (
        <div className={style.modelsWrapper} onClick={handleModelChange}>
          <h3 className={style.modelTitle}>Choose generation engine</h3>
          <div>
            <div
              id="2"
              className={CN(generationModel === 2 && style.activeTab)}
            >
              Model 2
            </div>
            <div
              id="3"
              className={CN(generationModel === 3 && style.activeTab)}
            >
              Model 3
            </div>
          </div>
        </div>
      )}
      <div className={style.tabsWrapper} onClick={handleTabChange}>
        <div id="styles" className={CN(isStylesTab && style.activeTab)}>
          {t('studio.styles')}
        </div>
        <div id="history" className={CN(!isStylesTab && style.activeTab)}>
          {t('rightFileMenu.meta.history')}
        </div>
      </div>
      {isStylesTab ? (
        <FiltersList
          isDrawTab={isDrawTab}
          is3DTab={is3DTab}
          setImageFilter={setImageFilter}
          optionSelectionHandler={optionSelectionHandler}
          imageFilter={imageFilter}
          hideShadowOnScrollToEnd={hideShadowOnScrollToEnd}
          generationModel={generationModel}
        />
      ) : (
        <div className={style.historyContainer}>
          {promptHistory.filtered.length === 0 && (
            <h2 className={style.filtersTitle}>{t('studio.noHistoryFound')}</h2>
          )}
          <ul
            className={style.historyWrapper}
            id="promptHistory"
            onScroll={hideShadowOnScrollToEnd}
          >
            {promptHistory.filtered.map(({ text, id }) => (
              <li className={style.historyItem} key={id} id={id}>
                <p>{text}</p>
                <CopyButton
                  onClick={() => copyPrompt(text)}
                  disabled={isCopy}
                  timeDelay={1000}
                  className={style.historyItem__button}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
      <SidebarFooter isStudio={true} />
    </div>
  );
};

export default AiGeneratorSidebar;
