import React from 'react'
import Modal from 'react-modal'
import _omit from 'lodash/omit'
import { ReactComponent as CloseIcon } from 'static/assets/svg/close.svg'
import CN from 'classnames'
import s from './style.module.scss'

interface ContainerProps {
  className?:string;
  overlayClassName?:string;
  isOpen:boolean;
  children?:string;
  title?:string;
  onClose:(event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>)=> void;
  cancelButtonProps?:React.ButtonHTMLAttributes<HTMLButtonElement>;
  primaryButtonProps?:React.ButtonHTMLAttributes<HTMLButtonElement> & {text:string};
}


export default function Container({
  className,
  overlayClassName,
  isOpen,
  children,
  title,
  onClose,
  cancelButtonProps,
  primaryButtonProps,
}:ContainerProps) {
  return (
    <Modal
      overlayClassName={CN(s.overlay, overlayClassName)}
      className={CN(s.wrapper, className)}
      onRequestClose={onClose}
      isOpen={isOpen}
      shouldCloseOnOverlayClick
    >
      <section
        className={CN(`modal modal__contented  error-auth-modal`, s.modal)}
      >
        <CloseIcon className={s.closeIcon} onClick={onClose} />
        <div className="modal__title modal__title-medium">{title}</div>
        <div className="modal__text"
          dangerouslySetInnerHTML={{ __html: children || '' }}/>

        <div className={CN({"modal__buttons":cancelButtonProps})}>
        {cancelButtonProps && (
          <button onClick={onClose}  {...cancelButtonProps}
          
            className={CN("link link--shrink decline", cancelButtonProps.className)}>
            Cancel
          </button>
        )}
        {primaryButtonProps && (
          <button
            {..._omit(primaryButtonProps, ['text'])}
            className={CN(s.primary, primaryButtonProps.className,{[s.buttons]:cancelButtonProps})}
          >
            {primaryButtonProps.text}
          </button>
        )}</div>
      </section>
    </Modal>
  )
}
