import { signMessage } from 'containers/auth/login-crypto';

export const canUserEncryptFile = async (
  user_public_addresses,
  freeEncryptionsCount = null
) => {
  if (user_public_addresses.length === 0) {
    return {
      error: true,
      message:
        'You must connect a web3 wallet to encrypt a file. Please connect your wallet to proceed.',
    };
  }
  const providers = window?.ethereum?.providers;
  let selectedProvider;

  if (providers) {
    for (const provider of providers || []) {
      if (provider.isMetaMask) {
        selectedProvider = provider;
      }
    }
  } else {
    selectedProvider = window.ethereum;
  }

  if (!selectedProvider) {
    return { error: true, message: 'You should have metamask installed' };
  }

  if (selectedProvider) {
    const [userPublicAddress] = await window.metamask.request({
      method: 'eth_requestAccounts',
    });
    const isAddressAttached = user_public_addresses.some(
      (el) =>
        el.public_address.toLowerCase() === userPublicAddress.toLowerCase()
    );
    if (!isAddressAttached) {
      await window.metamask.request({
        method: 'wallet_requestPermissions',
        params: [
          {
            eth_accounts: {},
          },
        ],
      });
      const [userAddress] = await window.metamask.request({
        method: 'eth_requestAccounts',
      });
      const rechecking = user_public_addresses.some(
        (el) =>
          el.public_address.toLowerCase() === userAddress.toLowerCase()
      );
      if (!rechecking) {
        return {
          error: true,
          message:
            'You cannot sign an encrypted file with a wallet that is not connected to your account. Please switch to the correct wallet to proceed.',
        };
      }
    }
    if (freeEncryptionsCount === 0) {
      try {
        const { address } = await signMessage(false, window.metamask);
        if (address) {
          return {
            error: false,
            message: '',
          };
        }
      } catch {
        return {
          error: true,
          message: 'Signature rejected',
        };
      }
    }
    return {
      error: false,
      message: '',
    };
  }
};
