/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';
import { unix } from 'moment';

import { ReactComponent as ToggleIcon } from 'containers/main/NeyraDriveContainer/components/assets/toggle.svg';
import { transformSize } from 'utils/storage';

import styles from './styles.module.scss';

const MetaWidget = ({ selectedEntity }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('owner');

  const toggleView = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.meta}>
      <div className={styles.valueHeader}>
        <div className={styles.valueLogo}>
          <h1 className={CN(styles.progressHeaderTitle, styles.infoTitle)}>
            {t('rightFileMenu.meta.info')}
          </h1>
        </div>
        <button className={styles.metaBtn} onClick={toggleView}>
          <ToggleIcon />
        </button>
      </div>
      {isOpen && (
        <div className={styles.metaContent}>
          <div className={styles.metaItem}>
            <p className={styles.metaItemTitle}>
              {t('rightFileMenu.meta.name')}
            </p>
            <span className={styles.metaItemText}>{selectedEntity?.name}</span>
          </div>
          <div className={styles.metaItem}>
            <p className={styles.metaItemTitle}>
              {t('rightFileMenu.meta.size')}
            </p>
            <span className={styles.metaItemText}>
              {transformSize(selectedEntity?.size)}
            </span>
          </div>
          <div className={styles.metaItem}>
            <p className={styles.metaItemTitle}>
              {t('rightFileMenu.meta.type')}
            </p>
            <span className={styles.metaItemText}>
              {String(selectedEntity?.extension).toUpperCase()}
            </span>
          </div>
          <div className={styles.metaItem}>
            <p className={styles.metaItemTitle}>
              {t('rightFileMenu.meta.date')}
            </p>
            <span className={styles.metaItemText}>
              {unix(selectedEntity?.created_at).format('DD.MM.YYYY')}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MetaWidget;
