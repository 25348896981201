//Refactoring №2
import React from 'react';
import { withTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import { defaultTo, clone } from 'ramda';
import cn from 'classnames';

import Button, { ButtonTheme } from 'components/Button';

import './password-popup.scss';

class Pin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: [],
      isValid: true,
    };
  }

  onPinButtonHandler = (num) => () => {
    const { onPinChange } = this.props;
    if (this.state.password.length >= 4) return;
    let passwords = clone(this.state.password);
    passwords.push(num);

    onPinChange(passwords);
    this.setState({
      password: passwords,
      isValid: true,
    });
  };

  removeButtonHandler = () => {
    const { onPinChange } = this.props;
    const password = clone(this.state.password);
    password.pop();
    onPinChange(password);
    this.setState({
      password,
    });
  };

  onConfirmHandler = () => {
    if (!(this.state.password.length === 4)) {
      this.setState({ isValid: false });
      return;
    }

    this.props.onConfirm(this.state.password.join(''), this);
  };

  renderPinNumArea() {
    const { password, isValid } = this.state;
    const { title, hideIcon, text, isError } = this.props;
    const hasError = !isValid || isError;
    const getVal = (position) => defaultTo('-', password[position] >= 0 && '*');

    return (
      <>
        {!hideIcon && (
          <div className="password-popup__ico ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="48"
              height="48"
              viewBox="0 0 48 48"
            >
              <defs>
                <path
                  id="dhwva"
                  d="M1124 123c0-13.255 10.745-24 24-24s24 10.745 24 24-10.745 24-24 24-24-10.745-24-24z"
                />
                <path
                  id="dhwvb"
                  d="M1140 119a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1h-15a1 1 0 0 1-1-1z"
                />
                <path
                  id="dhwvc"
                  d="M1144 116a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v3h-9z"
                />
                <path
                  id="dhwvg"
                  d="M1147 124.5a1.5 1.5 0 1 1 2 1.415V129h-1v-3.085a1.5 1.5 0 0 1-1-1.415z"
                />
                <clipPath id="dhwvd">
                  <use fill="#fff" xlinkHref="#dhwva" />
                </clipPath>
                <clipPath id="dhwve">
                  <use fill="#fff" xlinkHref="#dhwvb" />
                </clipPath>
                <clipPath id="dhwvf">
                  <use fill="#fff" xlinkHref="#dhwvc" />
                </clipPath>
              </defs>
              <use
                fill="#fff"
                fillOpacity="0"
                stroke="#dcdee9"
                strokeMiterlimit="50"
                strokeWidth="2"
                clipPath='url("#dhwvd")'
                xlinkHref="#dhwva"
                transform="translate(-1124 -99)"
              />
              <use
                fill="#fff"
                fillOpacity="0"
                stroke="currentColor"
                strokeMiterlimit="50"
                strokeWidth="2"
                clipPath='url("#dhwve")'
                xlinkHref="#dhwvb"
                transform="translate(-1124 -99)"
              />
              <use
                fill="#fff"
                fillOpacity="0"
                stroke="currentColor"
                strokeMiterlimit="50"
                strokeWidth="2"
                clipPath='url("#dhwvf")'
                xlinkHref="#dhwvc"
                transform="translate(-1124 -99)"
              />
              <use
                fill="currentColor"
                xlinkHref="#dhwvg"
                transform="translate(-1124 -99)"
              />
            </svg>
          </div>
        )}
        <div className="password-popup__set-text text--24 text--bold">
          {title}
        </div>
        {text && (
          <div className="password-popup__text-pin text--14 text">{text}</div>
        )}
        <div className="password-popup__pin-wrapper">
          <div className="password-popup__res-numbers">
            <div
              className={cn(
                'password-popup__res-num',
                hasError && 'password-popup__res-num-error',
                getVal(0) === '-' && 'default'
              )}
            >
              {getVal(0)}
            </div>
            <div
              className={cn(
                'password-popup__res-num',
                hasError && 'password-popup__res-num-error',
                getVal(1) === '-' && 'default'
              )}
            >
              {getVal(1)}
            </div>
            <div
              className={cn(
                'password-popup__res-num',
                hasError && 'password-popup__res-num-error',
                getVal(2) === '-' && 'default'
              )}
            >
              {getVal(2)}
            </div>
            <div
              className={cn(
                'password-popup__res-num',
                hasError && 'password-popup__res-num-error',
                getVal(3) === '-' && 'default'
              )}
            >
              {getVal(3)}
            </div>
          </div>
          <button
            type="button"
            className="password-popup__remove-char-icon"
            onClick={this.removeButtonHandler}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.61699 24L16.2083 13.5H36V34.5H16.2083L8.61699 24Z"
                stroke={password.length ? '#4B576C' : '#C1C9D9'}
              />
              <path
                d="M25.469 23.9979L28.8983 27.4272C29.0306 27.555 29.0343 27.7659 28.9065 27.8983C28.7787 28.0306 28.5677 28.0343 28.4354 27.9065C28.4326 27.9038 28.4299 27.9011 28.4272 27.8983L24.9979 24.469L21.5686 27.8983C21.4363 28.0261 21.2254 28.0225 21.0976 27.8901C20.9729 27.761 20.9729 27.5563 21.0976 27.4272L24.5268 23.9979L21.0976 20.5686C20.9675 20.4385 20.9675 20.2276 21.0976 20.0975C21.2276 19.9675 21.4386 19.9675 21.5686 20.0975L24.9979 23.5268L28.4272 20.0975C28.5595 19.9697 28.7704 19.9734 28.8983 20.1057C29.0229 20.2349 29.0229 20.4395 28.8983 20.5686L25.469 23.9979Z"
                fill="#C1C9D9"
                stroke={password.length ? '#4B576C' : '#C1C9D9'}
                strokeWidth="0.5"
              />
            </svg>
          </button>
        </div>
      </>
    );
  }

  renderPasswordButton(num) {
    return (
      <button
        type="button"
        className="password-popup__num"
        onClick={this.onPinButtonHandler(num)}
      >
        {num}
      </button>
    );
  }

  renderError() {
    const { isValid } = this.state;
    const { isError, errorText, t } = this.props;
    const canRender = !isValid || isError;

    const errorTextHandler = () => {
      if (errorText === ' Incorrect code, please try again')
        return t('rightFileMenu.security.incorrectCode');
      return errorText;
    };

    return (
      canRender && (
        <div className="activate-error text text--12">{errorTextHandler()}</div>
      )
    );
  }

  render() {
    const { hideButton, t } = this.props;
    const { password } = this.state;
    return (
      <div className="password-popup__content">
        <div className="password-popup__text">{this.renderPinNumArea()}</div>
        {this.renderError()}
        <div className="password-popup__numbers">
          <div className="password-popup__container text--20 text--medium">
            {this.renderPasswordButton(1)}
            {this.renderPasswordButton(2)}
            {this.renderPasswordButton(3)}
          </div>
          <div className="password-popup__container text--20 text--medium">
            {this.renderPasswordButton(4)}
            {this.renderPasswordButton(5)}
            {this.renderPasswordButton(6)}
          </div>
          <div className="password-popup__container text--20 text--medium">
            {this.renderPasswordButton(7)}
            {this.renderPasswordButton(8)}
            {this.renderPasswordButton(9)}
          </div>
          <div className="password-popup__container text--20 text--medium">
            {this.renderPasswordButton(0)}
          </div>
        </div>

        {!hideButton && (
          <Button
            theme={ButtonTheme.DARK}
            className="password-popup__btn"
            onClick={this.onConfirmHandler}
            disabled={password.length !== 4}
          >
            {t('common.confirm')}
          </Button>
        )}
      </div>
    );
  }
}

Pin.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  isError: PropTypes.bool,
  hideButton: PropTypes.bool,
  errorText: PropTypes.string,
  onPinChange: PropTypes.func,
};

Pin.defaultProps = {
  isError: false,
  hideButton: false,
  errorText: 'Incorrect code, please try again',
  text: '',
  onPinChange: () => {},
};

export default withTranslation('owner')(Pin);
