import Loadable from 'react-loadable';

import HomeContainer from './containers/main/MainContainer';
import MoreAppsContainer from './containers/more-apps/MoreAppsContainer';

const LazySharingContainer = Loadable({
  loader: () => import('./containers/sharing/SharingContainer'),
  loading: () => null,
});

const LazyFavoritesContainer = Loadable({
  loader: () => import('./containers/sharing/FavoriteConatainer'),
  loading: () => null,
});

const LazyMonetizedContainer = Loadable({
  loader: () => import('./containers/sharing/MonetizedConatainer'),
  loading: () => null,
});

// const LazyOwnershipContainer = Loadable({
//   loader: () => import('./containers/Ownership/index'),
//   loading: () => null,
// })

const LazySearchContainer = Loadable({
  loader: () => import('./containers/main/SearchContainer'),
  loading: () => null,
});

const LazyDashboardContainer = Loadable({
  loader: () => import('./containers/dashboard/DashboardContainer'),
  loading: () => null,
});

const LazyTeamSharingContainer = Loadable({
  loader: () => import('./containers/sharing/TeamSharing'),
  loading: () => null,
});

const LazySharingFolderContainer = Loadable({
  loader: () => import('./containers/sharing/SharingFolder'),
  loading: () => null,
});

const LazyAccountSettingsContainer = Loadable({
  loader: () => import('./containers/account/Settings'),
  loading: () => null,
});

// web 3
// const LazyAccountNftContainer = Loadable({
//   loader: () => import('./containers/account/nft'),
//   loading: () => null,
// })

// web 3
// const LazyAccountEncryptionContainer = Loadable({
//   loader: () => import('./containers/account/Encryption'),
//   loading: () => null,
// })

const LazyTokenizedContainer = Loadable({
  loader: () => import('./containers/tokenized/shared-folder'),
  loading: () => null,
});

// eslint-disable-next-line no-unused-vars
const LazyAccountPrivacyContainer = Loadable({
  loader: () => import('./containers/account/Privacy'),
  loading: () => null,
});

const LazyAccountSecurityLayersContainer = Loadable({
  loader: () => import('./containers/account/SecurityLayers'),
  loading: () => null,
});

const LazyAccountMultisigContainer = Loadable({
  loader: () => import('./containers/account/Multisig'),
  loading: () => null,
});

const LazyPasswordsContainer = Loadable({
  loader: () => import('./containers/passwords'),
  loading: () => null,
});

const LazySafetyNetPageContainer = Loadable({
  loader: () => import('./containers/safetynet'),
  loading: () => null,
});

const LazyTeamsContainer = Loadable({
  loader: () => import('./containers/teams'),
  loading: () => null,
});

const LazyWorkspaceMemberContainer = Loadable({
  loader: () => import('./containers/workspace/WorkspaceMember'),
  loading: () => null,
});

const LazyEditorContainer = Loadable({
  loader: () => import('./containers/editor/editor-component'),
  loading: () => null,
});

const LazyPageContainer = Loadable({
  loader: () => import('./containers/pages'),
  loading: () => null,
});

const LazyFileContainer = Loadable({
  loader: () => import('./containers/file'),
  loading: () => null,
});

const LazyDeletedContainer = Loadable({
  loader: () => import('./containers/main/DeletedContainer'),
  loading: () => null,
});

const LazyRecentContainer = Loadable({
  loader: () => import('./containers/main/RecentContainer'),
  loading: () => null,
});

// const LazyAutomationContainer = Loadable({
//   loader: () => import('./containers/main/EventsContainer/events-container'),
//   loading: () => null,
// });

const LazyCommentsContainer = Loadable({
  loader: () =>
    import('./containers/main/CommentsContainer/comments-container'),
  loading: () => null,
});

const LazyEventsContainer = Loadable({
  loader: () => import('./containers/main/EventsContainer/events-container'),
  loading: () => null,
});

// const LazyAiGeneratorContainer = Loadable({
//   loader: () => import('./containers/ai-generator'),
//   loading: () => null,
// });

// const LazyDocumentsContainer = Loadable({
//   loader: () => import('./containers/main/Documents'),
//   loading: () => null,
// });

const LazyVaultContainer = Loadable({
  loader: () => import('./containers/vault'),
  loading: () => null,
});

const LazyLinkContainer = Loadable({
  loader: () => import('./containers/link'),
  loading: () => null,
});

const LazyFilteredFilesContainer = Loadable({
  loader: () => import('./containers/filtered-files'),
  loading: () => null,
});

const LazyNoteContainer = Loadable({
  loader: () => import('./containers/note/note-component'),
  loading: () => null,
});

const LazyWsBuilderContainer = Loadable({
  loader: () => import('./containers/workspace/Builder'),
  loading: () => null,
});

const LazyWsCreateContainer = Loadable({
  loader: () => import('./containers/WsCreate'),
  loading: () => null,
});

const LazyBuyCreditsContainer = Loadable({
  loader: () => import('./containers/account/BuyCredits'),
  loading: () => null,
});

const LazyFeaturesContainer = Loadable({
  loader: () => import('./containers/account/Features'),
  loading: () => null,
});

const LazySpaceContainer = Loadable({
  loader: () => import('./containers/account/Space'),
  loading: () => null,
});

// const LazyProductsContainer = Loadable({
//   loader: () => import('./containers/account/Products'),
//   loading: () => null,
// });

// const LazySubscriptionContainer = Loadable({
//   loader: () => import('./containers/subscription/subscription'),
//   loading: () => null,
// });

// const LazyNotificationContainer = Loadable({
//   loader: () => import('./containers/notifications/notification-container'),
//   loading: () => null,
// })

const MainRoutes = [
  // {
  //   path: '/account/membership',
  //   component: LazySubscriptionContainer,
  // },
  {
    path: '/account',
    exact: true,
    component: LazyAccountSettingsContainer,
  },
  {
    path: '/main',
    exact: true,
    component: HomeContainer,
  },
  {
    path: '/entry/verification/:token',
    exact: true,
    component: HomeContainer,
  },
  {
    path: '/',
    exact: true,
    component: HomeContainer,
  },
  {
    path: '/search',
    exact: true,
    component: LazySearchContainer,
  },
  {
    path: '/deleted',
    exact: true,
    component: LazyDeletedContainer,
  },
  {
    path: '/recent',
    exact: true,
    component: LazyRecentContainer,
  },
  {
    path: '/tokenized-files',
    exact: true,
    component: HomeContainer,
  },
  // {
  //   path: '/automation',
  //   exact: true,
  //   component: LazyAutomationContainer,
  // },
  {
    path: '/comments',
    exact: true,
    component: LazyCommentsContainer,
  },
  {
    path: '/folder/:folderId',
    component: HomeContainer,
  },
  {
    path: '/tokenized-files/:workspaceId/:slug/:contract_address/:networkId',
    component: LazyTokenizedContainer,
  },
  {
    path: '/dashboard',
    component: LazyDashboardContainer,
  },
  {
    path: '/sharing',
    component: LazySharingContainer,
  },
  {
    path: '/team-sharing',
    component: LazyTeamSharingContainer,
  },
  {
    path: '/sharing-folder',
    component: LazySharingFolderContainer,
  },
  {
    path: '/teams',
    component: LazyTeamsContainer,
  },

  {
    path: '/more-apps',
    component: MoreAppsContainer,
  },
  {
    path: '/editor',
    exact: true,
    component: LazyEditorContainer,
  },
  {
    path: '/editor/:fileId',
    component: LazyEditorContainer,
  },
  {
    path: '/file/:fileId',
    component: LazyFileContainer,
  },
  {
    path: '/documents',
    component: LazyFilteredFilesContainer,
  },
  {
    path: '/vault',
    exact: true,
    component: LazyVaultContainer,
  },
  {
    path: '/safety-net',
    exact: true,
    component: LazySafetyNetPageContainer,
  },
  {
    path: '/video',
    exact: true,
    component: LazyFilteredFilesContainer,
  },
  {
    path: '/audio',
    exact: true,
    component: LazyFilteredFilesContainer,
  },
  {
    path: '/pictures',
    exact: true,
    component: LazyFilteredFilesContainer,
  },
  // {
  //   path: '/notifications',
  //   component: LazyNotificationContainer,
  // },
  {
    path: '/dataset',
    exact: true,
    component: LazyFavoritesContainer,
  },
  {
    path: '/monetized',
    exact: true,
    component: LazyMonetizedContainer,
  },
  {
    path: '/events',
    exact: true,
    component: LazyEventsContainer,
  },
  {
    path: '/memos',
    exact: true,
    component: LazyPageContainer,
  },
  {
    path: '/memo/:fileId',
    exact: true,
    component: LazyPageContainer,
  },
  {
    path: '/links',
    exact: true,
    component: LazyLinkContainer,
  },
  {
    path: '/link/:fileId',
    exact: true,
    component: LazyLinkContainer,
  },
  // {
  //   path: '/studio',
  //   exact: true,
  //   component: LazyAiGeneratorContainer,
  // },
  {
    path: '/ws-create',
    exact: true,
    component: LazyWsCreateContainer,
  },
  {
    path: '/ws-builder',
    component: LazyWsBuilderContainer,
  },
  {
    exact: true,
    path: '/space',
    component: LazySpaceContainer,
  },
  // {
  //   path: '/products',
  //   exact: true,
  //   component: LazyProductsContainer,
  // }
];

export default MainRoutes;

// const LazyAvatar = Loadable({
//   loader: () => import('containers/account/AiAvatar'),
//   loading: () => null,
// });
// const LazyProfile = Loadable({
//   loader: () => import('containers/account/Profile'),
//   loading: () => null,
// });
const LazyAuthorizationPage = Loadable({
  loader: () => import('containers/account/Authorization'),
  loading: () => null,
});
const LazyWalletPage = Loadable({
  loader: () => import('containers/account/Wallet'),
  loading: () => null,
});
const LazyWorkspaceSettingsPage = Loadable({
  loader: () => import('containers/workspace/WorkspaceSettingsPage'),
  loading: () => null,
});
const LazyWorkspaceEncryption = Loadable({
  loader: () => import('containers/workspace/WorkspaceEncryption'),
  loading: () => null,
});
const LazyWorkspaceUpgradePage = Loadable({
  loader: () => import('containers/workspace/WorkspaceUpgrade'),
  loading: () => null,
});
const LazyWorkspaceMultisigActions = Loadable({
  loader: () => import('containers/workspace/WorkspaceMultisigActions'),
  loading: () => null,
});
const LazyMembersUpgradePage = Loadable({
  loader: () => import('containers/workspace/MembersUpgrade'),
  loading: () => null,
});
const LazyWorkspaceCreatingPage = Loadable({
  loader: () => import('containers/workspace/WorkspaceCreating'),
  loading: () => null,
});
// const LazyWorkspaceApplicationsPage = Loadable({
//   loader: () => import('containers/workspace/WorkspaceApplicationsPage'),
//   loading: () => null,
// });
// const LazyWorkspaceMultisigPage= Loadable({
//   loader: () => import('containers/workspace/WorkspaceMultisigPage'),
//   loading: () => null,
// });
const LazyWorkspaceApiKeyPage = Loadable({
  loader: () => import('containers/workspace/WorkspaceApiKeyPage'),
  loading: () => null,
});
const LazyWorkspaceSmartcontractPage = Loadable({
  loader: () => import('containers/workspace/WorkspaceSmartcontractPage'),
  loading: () => null,
});
// const LazyAccountSettingsPage = Loadable({
//   loader: () => import('containers/account/Settings'),
//   loading: () => null,
// });
const LazyAccountNotificationPage = Loadable({
  loader: () => import('containers/account/Notifications'),
  loading: () => null,
});
const LazyAccountLanguagePage = Loadable({
  loader: () => import('containers/account/Language'),
  loading: () => null,
});
const LazyAccountSecurityContainer = Loadable({
  loader: () => import('./containers/account/Security'),
  loading: () => null,
});

const LazyAccountLogsContainer = Loadable({
  loader: () => import('./containers/account/Logs'),
  loading: () => null,
});

const LazySafetyNetContainer = Loadable({
  loader: () => import('./containers/workspace/SafetyNet'),
  loading: () => null,
});

// const LazyAccountCyberFeaturesContainer = Loadable({
//   loader: () => import('./containers/account/CyberFeatures'),
//   loading: () => null,
// })

// const LazyWorkspaceBillingSettingsContainer = Loadable({
//   loader: () => import('./containers/workspace/Billing'),
//   loading: () => null,
// });
const LazyAccountTokensContainer = Loadable({
  loader: () => import('./containers/account/Tokens'),
  loading: () => null,
});
// const LazyTasksContainer = Loadable({
//   loader: () => import('./containers/main/TasksContainer'),
//   loading: () => null,
// })

export const GuestLayoutRoutes = [
  {
    path: '/wallet/buy-credits',
    exact: true,
    component: LazyBuyCreditsContainer,
  },
  // {
  //   path: '/account/profile',
  //   component: LazyProfile,
  //   exact: true,
  // },
  // {
  //   path: '/account/settings',
  //   component: LazyAccountSettingsPage,
  //   exact: true,
  // },
  {
    path: '/account/notifications',
    component: LazyAccountNotificationPage,
    exact: true,
  },
  {
    path: '/workspace/security-layers',
    component: LazyAccountSecurityLayersContainer,
    exact: true,
  },
  {
    path: '/account/logs',
    component: LazyAccountLogsContainer,
    exact: true,
  },
  {
    path: '/sharing',
    component: LazySharingContainer,
  },
  {
    path: '/recent',
    exact: true,
    component: LazyRecentContainer,
  },
  {
    path: '/editor/:fileId',
    component: LazyEditorContainer,
  },
  {
    path: '/folder/:folderId',
    component: HomeContainer,
  },
  {
    path: '/file/:fileId',
    component: LazyFileContainer,
  },
  // {
  //   path: '/account/membership',
  //   component: LazySubscriptionContainer,
  // },
  {
    path: '/dataset',
    component: LazyFavoritesContainer,
  },
  // {
  //   path: '/notifications',
  //   component: LazyNotificationContainer,
  // },
  {
    path: '/ws-create',
    exact: true,
    component: LazyWsCreateContainer,
  },
];

export const PageLayoutRoutes = [
  // {
  //   path: '/account/ai-avatar',
  //   component: LazyAvatar,
  //   exact: true,
  // },
  // {
  //   path: '/account/profile',
  //   component: LazyProfile,
  //   exact: true,
  // },
  {
    path: '/wallet/buy-credits',
    exact: true,
    component: LazyBuyCreditsContainer,
  },
  {
    path: '/workspace/security-layers',
    component: LazyAccountSecurityLayersContainer,
    exact: true,
  },
  // {
  //   path: '/account/settings',
  //   component: LazyAccountSettingsPage,
  //   exact: true,
  // },
  // web 3
  // {
  //   path: '/account/nft',
  //   component: LazyAccountNftContainer,
  //   exact: true,
  // },
  // web 3
  // {
  //   path: '/account/encryption',
  //   component: LazyAccountEncryptionContainer,
  //   exact: true,
  // },
  // {
  //   path: '/account/privacy',
  //   component: LazyAccountPrivacyContainer,
  //   exact: true,
  // },
  {
    path: '/account/notifications',
    component: LazyAccountNotificationPage,
    exact: true,
  },
  {
    path: '/account/security',
    component: LazyAccountSecurityContainer,
    exact: true,
  },
  {
    path: '/account/logs',
    component: LazyAccountLogsContainer,
    exact: true,
  },
  {
    path: '/workspace/guardians',
    component: LazySafetyNetContainer,
    exact: true,
  },
  // {
  //   path: '/account/cyber-features',
  //   component: LazyAccountCyberFeaturesContainer,
  //   exact: true,
  // },
  {
    path: '/wallet/credits',
    component: LazyAccountTokensContainer,
    exact: true,
  },
  {
    path: '/wallet/bank',
    component: LazyWalletPage,
    exact: true,
  },
  {
    path: '/account/settings',
    component: LazyAuthorizationPage,
    exact: true,
  },
  {
    path: '/account/language',
    component: LazyAccountLanguagePage,
    exact: true,
  },
  {
    path: '/workspace/multisig',
    component: LazyAccountMultisigContainer,
    exact: true,
  },
  {
    path: '/wallet/features',
    component: LazyFeaturesContainer,
    exact: true,
  },
  {
    path: '/passwords',
    exact: true,
    component: LazyPasswordsContainer,
  },
  {
    path: '/notes',
    component: LazyNoteContainer,
  },
  {
    path: '/workspace/settings',
    component: LazyWorkspaceSettingsPage,
    exact: true,
  },
  {
    path: '/workspace/encryption',
    component: LazyWorkspaceEncryption,
    exact: true,
  },
  {
    path: '/workspace/members',
    component: LazyWorkspaceMemberContainer,
  },
  {
    path: '/workspace/settings/upgrade',
    component: LazyWorkspaceUpgradePage,
    exact: true,
  },
  {
    path: '/workspace/multisig/multisigActions',
    component: LazyWorkspaceMultisigActions,
    exact: true,
  },
  // {
  //   path: '/workspace/billing',
  //   component: LazyWorkspaceBillingSettingsContainer,
  //   exact: true,
  // },
  {
    path: '/workspace/members/upgrade',
    component: LazyMembersUpgradePage,
    exact: true,
  },
  {
    path: '/workspace/creating',
    component: LazyWorkspaceCreatingPage,
    exact: true,
  },
  // {
  //   path: '/workspace/applications',
  //   component: LazyWorkspaceApplicationsPage,
  //   exact: true,
  // },
  // {
  //   path: '/multisig',
  //   component: LazyWorkspaceMultisigPage,
  //   exact: true,
  // },
  {
    path: '/workspace/apikey',
    component: LazyWorkspaceApiKeyPage,
    exact: true,
  },
  {
    path: '/workspace/blockchain',
    component: LazyWorkspaceSmartcontractPage,
    exact: true,
  },
  // {
  //   path: '/tasks',
  //   component: LazyTasksContainer,
  //   exact: true,
  // },
  // {
  //   path: '/ownership',
  //   component: LazyOwnershipContainer,
  //   exact: true,
  // },
  {
    path: '/ws-create',
    exact: true,
    component: LazyWsCreateContainer,
  },
];

export const memberPageLayoutRoutes = [
  {
    path: '/wallet/bank',
    component: LazyWalletPage,
    exact: true,
  },
  {
    path: '/wallet/buy-credits',
    exact: true,
    component: LazyBuyCreditsContainer,
  },
  {
    path: '/account/settings',
    component: LazyAuthorizationPage,
    exact: true,
  },
  // {
  //   path: '/account/profile',
  //   component: LazyProfile,
  //   exact: true,
  // },
  // {
  //   path: '/settings/security-layers',
  //   component: LazyAccountSecurityLayersContainer,
  //   exact: true,
  // },
  // {
  //   path: '/account/settings',
  //   component: LazyAccountSettingsPage,
  //   exact: true,
  // },
  {
    path: '/account/notifications',
    component: LazyAccountNotificationPage,
    exact: true,
  },
  {
    path: '/account/language',
    component: LazyAccountLanguagePage,
    exact: true,
  },
  // {
  //   path: '/account/security',
  //   component: LazyAccountSecurityContainer,
  //   exact: true,
  // },
  // {
  //   path: '/settings/logs',
  //   component: LazyAccountLogsContainer,
  //   exact: true,
  // },
  // {
  //   path: '/wallet/credits',
  //   component: LazyAccountTokensContainer,
  //   exact: true,
  // },
  // {
  //   path: '/passwords',
  //   exact: true,
  //   component: LazyPasswordsContainer,
  // },
  // {
  //   path: '/notes',
  //   component: LazyNoteContainer,
  // },
];
