import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import cn from 'classnames';

import { convertFileEffect, getAvailableConvertFormats } from 'store/file/effects';
import { getFilesAdd } from 'store/home/actions';
import MainContext from 'store/main/context/main-context';
import actionsOptions from 'config/actions-options';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';

import useNotification from 'utils/hooks/use-notification';
import { transformSize } from 'utils/storage';
import { handleConvertModal } from 'features/modals/modal-slice';
import { IFile } from 'interfaces/file-folder';

import CustomFileIcon from 'components/CustomFileIcon/CustomFileIcon';
import Button, { BUTTON_TYPE } from '../Template/Button';
import TemplateForm from "containers/main/NeyraDriveContainer/components/ContentDrive/Template";
import Spinner from 'components/Spinner';
import { ConvertIcon } from 'components/svg';
import GhostLoader from 'components/GhostLoader';

import styles from './styles.module.scss';

type ConvertModalPropTypes = {
  entity: IFile;
  dispatch: any,
};

const CreateRename = ({ entity, dispatch }: ConvertModalPropTypes) => {
  const [isConverting, setIsConverting] = useState(false);
  const [isConverted, setIsConverted] = useState(false);
  const [isFormatsLoading, setIsFormatsLoading] = useState(false);
  const [convertFormats, setConvertFormats] = useState([]);
  const [choosenFormat, setChoosenFormat] = useState('');
  const [convertedFile, setConvertedFile] = useState({} as IFile);
  const { addNotification } = useNotification();
  const { t } = useTranslation('account');
  const {
    state: { folder: mainFolder },
  } = useContext(MainContext);
  const folderSlug = useMemo(() => mainFolder?.slug || null, [mainFolder]);
  useEffect(() => {
    setIsFormatsLoading(true);
    getAvailableConvertFormats(entity.extension)
      .then((data) => {
        const convertFormats = data.map((format: any) => format.output_format);
        setConvertFormats(convertFormats);
        setChoosenFormat(convertFormats[0]);
        setIsFormatsLoading(false);
      })
      .catch(() => {
        setIsFormatsLoading(false);
      });
  }, []);

  const onBackButtonClick = () => {
    setIsConverted(false);
  };

  const downloadFile = () => {
    dispatch(
      fileFolderActionHandlerEffect(convertedFile, actionsOptions.download)
    );
  };
  const color = entity.color?.[entity.color.length - 1];
  const iconClassNames = cn(
    {
      [styles.icon__img]: true,
    },
    color?.name
  );

  const chooseFormat = (e: any) => {
    if (e.target.tagName === 'LI') {
      const format = e.target.innerText;
      setChoosenFormat(format.toLowerCase());
    }
  };

  const handleFileConversion = async () => {
    setIsConverting(true);
    try {
      const file = await convertFileEffect(entity.slug, choosenFormat);
      setConvertedFile(file.data);
      dispatch(
        getFilesAdd({
          ...file.data,
          folderId: folderSlug || 'main',
        })
      );
      setIsConverted(true);
      await new Promise((resolve) =>
        setTimeout(() => {
          resolve();
        }, 5000)
      );
    } catch (error) {
      console.error(error.message);
      addNotification(
        error.message ?? t('convertModal.conversionError'),
        'alert'
      );
    } finally {
      setIsConverting(false);
    }
  };

  return (
    <section className={styles.convertModal}>
      <div>
        <div className={styles.convertFromTo}>
          <div className={styles.mimeContainer}>{entity.extension}</div>
          <div className={styles.arrow}>
            <ConvertIcon />
          </div>
          <div className={styles.mimeContainer}>
            {isFormatsLoading ? <Spinner /> : choosenFormat}
          </div>
        </div>

        {!isConverting && (
          <div className={styles.mainData}>
            <div className={styles.fileContainer}>
              <div className={`${iconClassNames} ${styles.fileIcon}`}>
                <CustomFileIcon
                  extension={entity.extension}
                  color={entity?.color[0]?.hex}
                  dateCreated={entity.created_at}
                />
              </div>
              <div className={styles.fileData}>
                <div className={styles.fileData__name}>{entity.name}</div>
                <div className={styles.fileData__info}>
                  {entity.extension}
                  <span> | </span>
                  {transformSize(entity.size + '')}
                </div>
              </div>
            </div>
            {!isConverted ? (
              <ul className={styles.convertOptions} onClick={chooseFormat}>
                {isFormatsLoading ? (
                  <li>
                    <Spinner />
                  </li>
                ) : (
                  <>
                    {convertFormats.map((format, i) => (
                      <li
                        className={
                          format === choosenFormat ? styles.activeOption : ''
                        }
                        key={format + i}
                      >
                        {format}
                      </li>
                    ))}
                  </>
                )}
              </ul>
            ) : (
              <div className={styles.fileContainer}>
                <div className={`${iconClassNames} ${styles.fileIcon}`}>
                  <CustomFileIcon extension={convertedFile.extension} />
                </div>
                <div className={styles.fileData}>
                  <div className={styles.fileData__name}>
                    {convertedFile.name}
                  </div>
                  <div className={styles.fileData__info}>
                    {convertedFile.extension}
                    <span> | </span>
                    {transformSize(convertedFile.size + '')}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {isConverting && !isConverted && (
          <div className={styles.resultContainer}>
            <GhostLoader
              texts={[
                t('convertModal.fileConverting'),
                t('convertModal.pleaseWait'),
              ]}
            />
          </div>
        )}
        {isConverting && isConverted && (
          <div className={styles.resultContainer}>
            <GhostLoader texts={[t('convertModal.complete')]} />
          </div>
        )}
      </div>
      <div className={styles.buttons}>
        {!isConverting && !isConverted && (
          <Button type={BUTTON_TYPE.cancel} onClick={handleFileConversion}>
            {`${t('convertModal.convert')} 1`}
          </Button>
        )}
        {isConverted && !isConverting && (
          <>
            <Button
              type={BUTTON_TYPE.cancel} 
              onClick={onBackButtonClick}
            >
              {t('convertModal.back')}
            </Button>
            <Button onClick={downloadFile}>
              {t('convertModal.download')}
            </Button>
          </>
        )}
      </div>
    </section>
  );
}

const CreateRenameModal = ({ entity, ...rest }: any) => {
  const { t } = useTranslation('account');
  const dispatch = useDispatch();

  const onCloseModal= () => {
    dispatch(handleConvertModal({
      modal: false,
      entity: null,
    }));
  }

  return (
    <TemplateForm
      onClose={onCloseModal}
      title={t('convertModal.convert')}
    >
      <CreateRename
        {...rest}
        entity={entity}
        dispatch={dispatch}
      />
    </TemplateForm>
  )
}

export default CreateRenameModal;