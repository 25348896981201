// Refactoring №3
import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './style.module.scss';

const CustomIconHint = ({ position = '' }: { position?: string }) => {
  const { t } = useTranslation('hint');
  return (
    <div className={`${style.hintWrapper} ${position && style.highPosition}`}>
      {t('playMedia')}
    </div>
  );
};

export default CustomIconHint;
