//Refactoring №4
import React from 'react';
import Modal from 'react-modal';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import CloseIcon from 'components/svg/close';

import styles from './style.module.scss';

type modalType = {
  isOpen: boolean;
  onClose?: () => void;
};

const LoginMetamaskError = ({ isOpen, onClose }: modalType) => {
  const { t } = useTranslation('auth');
  const history = useHistory();
  const goToRegistration = () => {
    history.push('/sign-up-metamask');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modalContainer}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick
    >
      <section className={styles.modal}>
        <button
          type="button"
          className={styles.modal__exit}
          onClick={onClose}
          data-test="modal_crypto_close_button"
        >
          <CloseIcon />
        </button>
        <div>
          <h3 className={styles.title}>{t('signup.youDontHaveAccount')}</h3>
          <p className={styles.description}>
            {t('signup.buyNFTtoBecomeMember')}
          </p>
        </div>
        <div className={styles.modal__buttons}>
          <Button
            htmlElement="button"
            className={styles.button}
            type="button"
            onClick={goToRegistration}
            value={`${t('signup.buyNFT')}`}
          />
        </div>
      </section>
    </Modal>
  );
};

export default React.memo(LoginMetamaskError);
