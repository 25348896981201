import React from 'react';

const DeleteIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 16.8336L2.1665 7.4376L4.4165 2.9166H15.5835L17.8335 7.4376L10 16.8336ZM7.25 7.1461H12.75L11 3.6251H9L7.25 7.1461ZM9.646 15.3336V7.8541H3.4375L9.646 15.3336ZM10.354 15.3336L16.5625 7.8541H10.354V15.3336ZM13.5415 7.1461H16.875L15.104 3.6251H11.7915L13.5415 7.1461ZM3.125 7.1461H6.4585L8.2085 3.6251H4.896L3.125 7.1461Z"
      fill={color}
    />
  </svg>
);

DeleteIcon.defaultProps = {
  color: 'currentColor',
};

export default DeleteIcon;
