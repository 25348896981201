import routes, { PageLayoutRoutes } from 'main-routes';

const excludeList = ['/']

export const isNeyraAvatarLink = () => {
  const routesFilteredList = [...routes, ...PageLayoutRoutes]
    .map((item) => item.path)
    .filter((path) => !excludeList.includes(path))

  const currentRootPath = window.location.pathname.split('/')[1]  
  const res = routesFilteredList.some((item) => item.split('/')[1] === currentRootPath)

  return !res
}
