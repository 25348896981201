// Refactoring №3
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserInfoEffect } from 'store/home/effects';
import { STORAGE_KEY } from 'store/context/theme';
import { setErrors } from 'store/errors/effects/set-error.effect';

import { selectProfileLogin } from 'features/profile/profileSlice';

import SharedFile from 'containers/file/shared-by-link/shared-file';
import SharedFolder from 'containers/folder/shared-folder';
import Preloader from 'components/shared/Preloader';
import DownloadLoader from 'components/DownloadLoader';
import EncryptionKeyModal from 'containers/header/header-container/encryption-key-modal';
import {
  closeEncryptionModal,
  changeEncryptionStatus,
} from 'store/home/actions/file/encryption-key.action';
import { handleShowEncryptionKeyModal } from 'store/home/actions/file/encrypt.action';

type SharedByLinkFlow = {
  match: any;
};

export default function SharedByLinkFlow({ match }: SharedByLinkFlow) {
  const [isRender, setRender] = useState(false);
  const isLogin = useSelector(selectProfileLogin);
  const dispatch = useDispatch();
  const entitiesDownload = useSelector((state) => state.home?.fileDownload);
  const showEncryptionModal = useSelector(
    (state) => state.home.encrypt.showEncryptionKeyModal
  );
  const pathname = window.location.pathname;
  const isFolder = pathname.includes('/folder/');
  const id = pathname.split('/')[2];

  const content = useMemo(() => {
    if (!isFolder) return <SharedFile fileId={id} />;
    if (isFolder) return <SharedFolder folderId={id} />;
  }, [match]);
  const getIfUserMember = async () => {
    await dispatch(getUserInfoEffect());
    setRender(true);
  };

  useEffect(() => {
    dispatch(setErrors(null));
    const header = document.getElementsByClassName('main__header')[0];
    const footer = document.getElementsByTagName('footer')[0];
    const sidebar = document.getElementsByClassName('Pane ')[0];
    if (isLogin) {
      //@ts-ignore
      if (header) header.style.display = 'none';
      if (footer) footer.style.display = 'none';
      //@ts-ignore
      if (sidebar) sidebar.style.display = 'none';

      const theme = localStorage.getItem(STORAGE_KEY) || '';
      document.documentElement.setAttribute(STORAGE_KEY, theme);
      getIfUserMember();
    } else setRender(true);
    return () => {
      //@ts-ignore
      if (header) header.style.display = 'block';
      if (footer) footer.style.display = 'block';
      //@ts-ignore
      if (sidebar) sidebar.style.display = 'block';
    };
  }, []);

  return (
    <>
      {isRender ? (
        <div>
          {content}
          <DownloadLoader
            visible={entitiesDownload.status}
            size={entitiesDownload.size}
            files={entitiesDownload.files}
          />
        </div>
      ) : (
        <Preloader />
      )}
      <EncryptionKeyModal
        closeModal={() => {
          dispatch(handleShowEncryptionKeyModal(false));
          dispatch(changeEncryptionStatus(false));
          dispatch(closeEncryptionModal(true));
        }}
        modalIsOpen={showEncryptionModal}
        file={showEncryptionModal}
      />
    </>
  );
}
