//Refactoring №3
import React from 'react';

import cn from 'classnames';

import styles from './style.module.scss';

export default function Rule({ className, vertical, dark, dashed }) {
  return vertical ? (
    <div className={cn(styles.vertical, className)}>&nbsp;</div>
  ) : (
    <hr
      className={cn(
        styles.rule,
        dark && styles.dark,
        dashed && styles.dashed,
        className
      )}
    />
  );
}
