import React from 'react';
import CN from 'classnames';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css'; // Importing the default stylesheet

import { TooltipProps } from 'rc-tooltip/lib/Tooltip';

import styles from './styles.module.scss'

const TooltipComponent = ({ overlayClassName, ...rest }: TooltipProps) => (
  <Tooltip
    overlayClassName={CN(styles.overlay, overlayClassName)}
    {...rest}
  />
)

export default TooltipComponent