//Refactoring №3
import React, { FC, useRef, KeyboardEvent, useCallback } from 'react';
import { Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import InputFieldFormik from 'components/InputFieldFormik';
import TextareaField from 'components/InputField/TextareaField';
import Button, { ButtonTheme } from 'components/Button';

import { MINUSE_KEY_CODE, E_KEY_CODE } from 'config/key-codes';
import { validationRegExp } from 'utils/validation/regexp';
import styles from './styles.module.scss';

import { ICreateTokenFormData } from '../../interfaces';

interface CreateFormProps {
  onSubmit: (data: ICreateTokenFormData) => void;
  loading: boolean;
}

const initialValues = {
  name: '',
  royalties: 0,
  supply: 0,
  description: '',
};

const CreateForm: FC<CreateFormProps> = ({ onSubmit, loading }) => {
  const { t } = useTranslation('owner');
  const form = useRef(null);

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(`${t('rightFileMenu.item.nameRequired')}`)
      .matches(
        validationRegExp.web3Text,
        `${t('rightFileMenu.item.nameInvalid')}`
      ),
    royalties: Yup.number()
      .max(100, `${t('rightFileMenu.item.royaltiesLimit')}`)
      .required(`${t('rightFileMenu.item.royaltiesRequired')}`),
    supply: Yup.number().required(`${t('rightFileMenu.item.supplyRequired')}`),
    description: Yup.string()
      .min(5, `${t('rightFileMenu.item.descriptionMinLength')}`)
      .max(300, `${t('rightFileMenu.item.descriptionMaxLength')}`)
      .required(`${t('rightFileMenu.item.descriptionRequired')}`)
      .matches(
        validationRegExp.web3Text,
        `${t('rightFileMenu.item.descriptionInvalid')}`
      ),
  });

  const handleSubmit = (data: ICreateTokenFormData) => {
    onSubmit(data);
  };

  const numberKeyPress = useCallback((e: KeyboardEvent) => {
    const code = e.keyCode || e.which;
    if (MINUSE_KEY_CODE === code || E_KEY_CODE === code) e.preventDefault();
  }, []);

  return (
    <Formik
      innerRef={form}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isValid, dirty }) => (
        <Form>
          <InputFieldFormik
            className={styles.input}
            name="name"
            label={t('rightFileMenu.item.itemName')}
            placeholder={t('rightFileMenu.item.placeholder')}
            type="text"
          />
          <InputFieldFormik
            className={styles.numberInput}
            type="number"
            name="royalties"
            label={`${t('rightFileMenu.item.royalties')}(%)`}
            onKeyPress={numberKeyPress}
            placeholder="10%"
            min="0"
          />
          <InputFieldFormik
            className={styles.numberInput}
            type="number"
            name="supply"
            label={`${t('rightFileMenu.item.maxSupply')}`}
            onKeyPress={numberKeyPress}
            placeholder={`${t('rightFileMenu.item.selectSupply')}`}
            min="0"
          />
          <InputFieldFormik
            className={styles.textArea}
            component={TextareaField}
            name="description"
            label={t('rightFileMenu.item.description')}
            placeholder={t('rightFileMenu.item.placeholderPurchasing')}
          />
          <Button
            theme={ButtonTheme.DARK}
            className={styles.saveButton}
            disabled={!(isValid && dirty) || loading}
            showPreloader={loading}
            type="submit"
            value={t('rightFileMenu.item.createNewItem')}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CreateForm;
