import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  EIP1193Provider,
  EIP6963AnnounceProviderEvent,
  EIP6963ProviderDetail,
} from './types';

type SelectedAccountByWallet = Record<string, string | null>;

declare global {
  interface Window {
    readonly metamask: any;
    setMetamask: (provider: EIP1193Provider) => void;
  }
}

export let METAMASK_INSTALLED = false;

(function () {
  let _metamask = window.ethereum;

  window.setMetamask = function (provider: EIP1193Provider) {
    _metamask = provider;
  };

  Object.defineProperty(window, 'metamask', {
    get: function () {
      return _metamask;
    },
    enumerable: true,
    configurable: false,
  });
})();

interface Eip6963ProviderContext {
  wallets: Record<string, EIP6963ProviderDetail>;
  selectedWallet: EIP6963ProviderDetail | null;
  selectedAccount: string | null;
  connectWallet: (walletUuid: string) => Promise<string | undefined>;
}

declare global {
  interface WindowEventMap {
    'eip6963:announceProvider': CustomEvent;
  }
}

export const Eip6963ProviderContext =
  createContext<Eip6963ProviderContext>(null);

export const Eip6963Provider: React.FC<PropsWithChildren> = ({ children }) => {
  const [wallets, setWallets] = useState<Record<string, EIP6963ProviderDetail>>(
    {}
  );
  const [selectedWalletUuid, setSelectedWalletUuid] = useState<string | null>(
    null
  );
  const [selectedAccountByWalletUuid, setSelectedAccountByWalletUuid] =
    useState<SelectedAccountByWallet>({});

  // Find out about all providers by using EIP-6963
  useEffect(() => {
    function onAnnouncement(event: EIP6963AnnounceProviderEvent) {
      if (event.detail.info.name === 'MetaMask') {
        window.setMetamask(event.detail.provider);
        METAMASK_INSTALLED = true;
      }
      setWallets((currentWallets) => ({
        ...currentWallets,
        [event.detail.info.uuid]: event.detail,
      }));
    }

    window.addEventListener('eip6963:announceProvider', onAnnouncement);
    window.dispatchEvent(new Event('eip6963:requestProvider'));

    return () =>
      window.removeEventListener('eip6963:announceProvider', onAnnouncement);
  }, []);

  const connectWallet = useCallback(
    async (walletUuid: string) => {
      try {
        const wallet = wallets[walletUuid];
        const accounts = (await wallet.provider.request({
          method: 'eth_requestAccounts',
        })) as string[];

        if (accounts?.[0]) {
          setSelectedWalletUuid(wallet.info.uuid);
          setSelectedAccountByWalletUuid((currentAccounts) => ({
            ...currentAccounts,
            [wallet.info.uuid]: accounts[0],
          }));
        }
        return accounts?.[0];
      } catch (error) {
        console.error('Failed to connect to provider:', error);
      }
    },
    [wallets]
  );

  const contextValue: Eip6963ProviderContext = {
    wallets,
    selectedWallet:
      selectedWalletUuid === null ? null : wallets[selectedWalletUuid],
    selectedAccount:
      selectedWalletUuid === null
        ? null
        : selectedAccountByWalletUuid[selectedWalletUuid],
    connectWallet,
  };

  return (
    <Eip6963ProviderContext.Provider value={contextValue}>
      {children}
    </Eip6963ProviderContext.Provider>
  );
};
