import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import CN from 'classnames';
import MessageItem from 'containers/auth/NeyraChat/components/MessageItem';
import styles from './styles.module.scss';
import { DARK_THEME, ThemeContext } from 'store/context/theme';

const ConversationHistoryTerminal = ({ className, addNotification, hideShadow = false }) => {
  const { conversationHistory, neyraWrites } = useSelector(
    (state) => state.neyra
  );
  const [autoScroll, setAutoScroll] = useState(false);
  const [requiresScroll, setRequiresScroll] = useState(false);
  const messengerRef = useRef(null);
  const { theme } = useContext(ThemeContext);
  const isDarkTheme = useMemo(() => theme === DARK_THEME, [theme]);

  useEffect(() => {
    setAutoScroll(neyraWrites);
  }, [neyraWrites]);

  const onMessengerScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target;
    if (autoScroll && scrollHeight - Math.ceil(scrollTop + clientHeight) > 20)
      setAutoScroll(false);
  };

  useEffect(() => {
    if (messengerRef.current) {
      const messengerElement = messengerRef.current;
      if (messengerElement.scrollHeight > messengerElement.clientHeight) {
        setRequiresScroll(true);
      } else {
        setRequiresScroll(false);
      }
    }
  }, [conversationHistory]);

  useEffect(() => {
    const handleScrollToEnd = () => {
      messengerRef.current.scrollTop = messengerRef.current.scrollHeight;
    };
    if (messengerRef.current) {
      messengerRef.current.addEventListener('scrollToEnd', handleScrollToEnd);
    }

    return () => {
      messengerRef?.current?.removeEventListener(
        'scrollToEnd',
        handleScrollToEnd
      );
    };
  }, [messengerRef]);

  useEffect(() => {
    if (autoScroll) {
      messengerRef.current.dispatchEvent(new CustomEvent('scrollToEnd', {}));
    }
  }, [conversationHistory, messengerRef, autoScroll]);

  return (
    <div
      className={CN(styles.wrapper, className)}
      ref={messengerRef}
      onScroll={onMessengerScroll}
    >
      {!hideShadow && requiresScroll && isDarkTheme && <div className={styles.shadowBlock} />}
      {!hideShadow && requiresScroll && isDarkTheme && (
        <div className={styles.shadowBlockBottom} />
      )}
      <div className={styles.conversationContainer}>
        {conversationHistory.map((item) => (
          <MessageItem
            message={item}
            key={item.id}
            initialMessage={item.initialMessage || false}
            addNotification={addNotification}
          />
        ))}
      </div>
    </div>
  );
};

export default ConversationHistoryTerminal;
