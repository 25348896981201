//Refactoring №3
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';

import {
  selectStart,
  selectEntities,
  selectAdditionalData,
  selectCurrentChainId,
} from 'store/account/reducers/web3-checker.reducer';
import { selectUserPublicAddresses } from 'store/account/reducers/user-info.reducer';
import { selectedFilesAddEffect } from 'store/home/effects/selected-entity/selected-files-add-effect';
import { updateStartWeb3Checker } from 'store/account/actions/web3-checker.actions';
import { runCheckWeb3 } from 'store/account/actions';

import { isMobile } from 'utils/mobile';
import { checkMetamaskInstalled } from 'utils/crypto/wallet/metamask';
import { handleNftModal } from 'features/modals/modal-slice';

import CryptoCheckPluginModal from 'containers/auth/crypto-check-plugin-modal';
import NetworkNotSupported from 'components/Web3Checker/components/NetworkNotSupported';
import NoAccessWeb3Modal from 'components/Web3Checker/components/NoAccessWeb3Modal';
import NoAttachedAddress from 'components/Web3Checker/components/NoAttachedAddress';
import AddWalletFlow from 'containers/account/Profile/components/ProfileWallets/addWalletFlow';

const Web3Checker = ({
  isStartCheck,
  userPublicAddresses,
  entities,
  selectedFilesAddEffect,
  chainId,
  additionalData,
}) => {
  const dispatch = useDispatch();
  const callback = useSelector((state) => state.account.web3.callback);
  const { isWeb3FeatureRun } = useSelector((state) => state.account.web3);
  const [isExtensionIntaled, setIsExtensionIntaled] = useState(true);
  const [isNoAccess, setisNoAccess] = useState(false);
  const [isNoAttach, setisNoAttach] = useState(false);
  const [isAddWallet, setIsAddWallet] = useState(false);
  const [isIncorrectNetwork, setIsIncorrectNetwork] = useState(false);

  useEffect(() => {
    isStartCheck && checkWeb3Flow();
  }, [isStartCheck]);

  const checkWeb3Flow = () => {
    if (isMobile && !checkMetamaskInstalled()) {
      const dappUrl = `${window?.location?.host}/sign-in`;
      const metamaskAppDeepLink = 'https://metamask.app.link/dapp/' + dappUrl;
      return window.open(metamaskAppDeepLink, '_self');
    }
    if (!checkMetamaskInstalled()) {
      openExtensionModal();
    } else {
      if (userPublicAddresses.length) {
        const isAttached = userPublicAddresses.find(
          (addres) =>
            addres.public_address.toUpperCase() ===
            window?.ethereum?.selectedAddress.toUpperCase()
        );
        if (isAttached) {
          openIncorrectNetworkModal();
        } else {
          openNoAttachModal();
        }
      } else {
        openNoAccessModal();
      }
    }
  };

  //ExtensionModal
  const openExtensionModal = () => {
    setIsExtensionIntaled(false);
  };

  const closeExtensionModal = () => {
    setIsExtensionIntaled(true);
    stopWeb3Checker();
  };

  //NoAccessModal
  const openNoAccessModal = () => {
    setisNoAccess(true);
  };

  const closeNoAccessModal = () => {
    setisNoAccess(false);
    stopWeb3Checker();
  };

  const connectWalletModal = () => {
    openAddWalletFlowModal();
  };

  //NoAttachModal
  const openNoAttachModal = () => {
    setisNoAttach(true);
  };

  const closeNoAttachModal = () => {
    setisNoAttach(false);
    stopWeb3Checker();
  };

  const onAttachHandlerr = () => {
    closeNoAttachModal();
    setIsAddWallet(true);
  };

  //IncorrectNetwork
  const openIncorrectNetworkModal = () => {
    setIsIncorrectNetwork(true);
  };

  const closeIncorrectNetworkModal = () => {
    dispatch(runCheckWeb3(false));
    setIsIncorrectNetwork(false);
    stopWeb3Checker();
  };

  // AddWalletFlow
  const openAddWalletFlowModal = () => {
    setIsAddWallet(true);
  };

  const closeAddWalletFlowModal = () => {
    setIsAddWallet(false);
  };

  // web3Checker
  const stopWeb3Checker = () => {
    dispatch(updateStartWeb3Checker(false));
  };

  const openNftModal = useCallback(() => {
    selectedFilesAddEffect({ entities });
    dispatch(handleNftModal({ status: true }));
  }, [dispatch, entities, selectedFilesAddEffect]);

  const startRetry = () => {
    setisNoAttach(false);
    setTimeout(() => {
      checkWeb3Flow();
    }, 100);
  };

  useEffect(() => {
    additionalData?.openNFTModal && openNftModal();
  }, [additionalData?.openNFTModal, openNftModal]);

  useEffect(() => {
    if (isWeb3FeatureRun) openIncorrectNetworkModal();
  }, [isWeb3FeatureRun]);

  return (
    <>
      <CryptoCheckPluginModal
        onClose={closeExtensionModal}
        isOpen={!isExtensionIntaled}
        type="metamask"
      />
      <NoAccessWeb3Modal
        isOpen={isNoAccess}
        onClose={closeNoAccessModal}
        onConnect={connectWalletModal}
      />
      <NoAttachedAddress
        isOpen={isNoAttach}
        onClose={closeNoAttachModal}
        addressList={userPublicAddresses}
        onRetry={startRetry}
        onAttach={onAttachHandlerr}
      />
      <NetworkNotSupported
        chainId={chainId}
        isOpen={isIncorrectNetwork}
        onClose={closeIncorrectNetworkModal}
        openNftModal={openNftModal}
        {...{ callback, additionalData }}
      />
      <AddWalletFlow
        isAddingWalletStart={isAddWallet}
        close={closeAddWalletFlowModal}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  isStartCheck: selectStart(state),
  userPublicAddresses: selectUserPublicAddresses(state),
  entities: selectEntities(state),
  additionalData: selectAdditionalData(state),
  chainId: selectCurrentChainId(state),
});

const mapDispatchToProps = {
  selectedFilesAddEffect,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(Web3Checker));
