import { createSlice } from '@reduxjs/toolkit'

export const modalMode = {
  NEW_TASK: 'new-task',
  EDIT_TASK: 'edit-task',
  OPEN_TASK: 'open-task',
}

const mapTask = ({ task_users, ...props }) => {
  return {
    ...props,
    taskUsers: task_users.map(({ user }) => ({ ...user })),
  }
}
const initialState = {
  modal: {
    isOpen: false,
    mode: null, //modalMode
    taskId: null,
  },
  editableTask: {
    id: null,
    loading: false,
    updating: false,
    updateSuccess: false,
    error: false,
    data: null,
  },
  deletedTask: {
    id: null,
    deleting: false,
    error: false,
  },
  deletedMultiTasks: {
    deleting: false,
    error: false,
  },
  tasks: {
    data: {},
    allTasksSelected: false,
    selectedTasksCounter: 0,
    loading: false,
    error: false,
  },
  users: {
    data: [],
    loading: false,
    error: false,
  },
  addingTask: false,
  success: false,
  error: false,
}

const taskManager = createSlice({
  name: 'taskManager',
  initialState,
  reducers: {
    toggleTask: (state, action) => {
      const checked = !state.tasks.data[action.payload].checked
      state.tasks.data[action.payload].checked = checked
      if (checked) {
        state.tasks.selectedTasksCounter = state.tasks.selectedTasksCounter + 1
      } else {
        state.tasks.selectedTasksCounter = state.tasks.selectedTasksCounter - 1
      }
    },
    toggleAllTasks: (state) => {
      const checked = !state.tasks.allTasksSelected
      state.tasks.allTasksSelected = checked

      for (const key in state.tasks.data) {
        state.tasks.data[key].checked = checked
      }

      if (checked) {
        state.tasks.selectedTasksCounter = Object.keys(state.tasks.data).length
      } else {
        state.tasks.selectedTasksCounter = 0
      }
    },
    deselectAllTasks: (state) => {
      for (const key in state.tasks.data) {
        state.tasks.data[key].checked = false
        state.tasks.allTasksSelected = false
        state.tasks.selectedTasksCounter = 0
      }
    },
    openModal: (state, { payload: { modalMode, taskId } }) => {
      state.modal.mode = modalMode
      state.editableTask.id = taskId
      state.modal.isOpen = true
    },
    closeModal: (state) => {
      state.modal = initialState.modal
      state.editableTask = initialState.editableTask
      state.error = initialState.error
      state.success = initialState.success
      state.addingTask = initialState.addingTask
    },
    addUser: (state) => {
      state.error = false
      state.success = false
    },
    removeUser: (state) => {
      state.error = false
      state.success = false
    },
    removeUserSuccess: (state, { payload }) => {
      state.editableTask.data.taskUsers =
        state.editableTask.data.taskUsers.filter((item) => item.id !== payload)
    },
    addTask: (state) => {
      state.addingTask = true
      state.error = false
      state.success = false
    },
    addTaskSuccess: (state) => {
      state.addingTask = false
      state.error = false
      state.success = true
    },
    addTaskError: (state) => {
      state.addingTask = false
      state.error = true
      state.success = false
    },
    resetTaskManagerState: (state) => {
      state.addingTask = false
      state.success = false
      state.error = false
    },
    loadAllTasks: (state) => {
      state.tasks.loading = false
      state.tasks.error = false
    },
    loadAllTasksSuccess: (state, { payload }) => {
      state.tasks.data = payload.reduce((acc, taskData) => {
        const key = taskData.id
        const result = {
          ...acc,
          [key]: mapTask(taskData),
        }
        result[key].checked = false
        return result
      }, {})
      state.tasks.allTasksSelected = false
      state.tasks.selectedTasksCounter = 0
      state.tasks.loading = false
      state.tasks.error = false
    },
    loadAllTasksError: (state) => {
      state.tasks.error = true
      state.tasks.loading = false
    },
    loadAllUsers: (state) => {
      state.users.loading = true
      state.users.error = false
    },
    loadAllUsersSuccess: (state, { payload }) => {
      const workspaceUsers = payload
        .filter((data) => !!data.user)
        .map((data) => data.user)
      state.users.data = workspaceUsers
      state.users.loading = false
      state.users.error = false
    },
    loadAllUsersError: (state) => {
      state.users.loading = false
      state.users.error = true
    },
    loadTask: (state) => {
      state.editableTask.data = null
      state.editableTask.error = false
      state.editableTask.loading = true
    },
    loadTaskSuccess: (state, { payload: { task_users, ...rest } }) => {
      const taskUsers = task_users.map((user) => user.user)

      state.editableTask.data = {
        ...rest,
        taskUsers,
      }

      state.editableTask.error = false
      state.editableTask.loading = false
    },
    loadTaskError: (state) => {
      state.editableTask.data = null
      state.editableTask.error = true
      state.editableTask.loading = false
    },
    updateTask: (state) => {
      state.editableTask.updating = true
      state.editableTask.updateSuccess = false
      state.editableTask.error = false
    },
    updateTaskSuccess: (state, { payload: { task_users, ...rest } }) => {
      const taskUsers = task_users.map((user) => user.user)

      state.editableTask.updating = false
      state.editableTask.updateSuccess = true
      state.editableTask.error = false
      state.tasks.data[rest.id] = { ...rest, taskUsers }
    },
    updateTaskError: (state) => {
      state.editableTask.updating = false
      state.editableTask.updateSuccess = false
      state.editableTask.error = true
    },
    deleteTask: (state, { payload }) => {
      state.deletedTask.id = payload
      state.deletedTask.deleting = true
      state.deletedTask.error = false
    },
    deleteTaskSuccess: (state) => {
      state.deletedTask.deleting = false
      state.deletedTask.error = false
      state.tasks.selectedTasksCounter = state.tasks.selectedTasksCounter - 1
    },
    deleteTaskError: (state) => {
      state.deletedTask.deleting = false
      state.deletedTask.error = true
    },
    deleteMultiTasks: (state) => {
      state.deletedMultiTasks.deleting = true
      state.deletedMultiTasks.error = false
    },
    deleteMultiTasksError: (state) => {
      state.deletedMultiTasks.deleting = false
      state.deletedMultiTasks.error = true
    },
    deleteMultiTasksSuccess: (state) => {
      state.deletedMultiTasks.deleting = false
      state.deletedMultiTasks.error = false
      state.tasks.selectedTasksCounter = 0
    },
  },
})
export default taskManager.reducer

export const toggleTask = taskManager.actions.toggleTask
export const toggleAllTasks = taskManager.actions.toggleAllTasks
export const deselectAllTasks = taskManager.actions.deselectAllTasks
export const addTask = taskManager.actions.addTask
export const addUser = taskManager.actions.addUser
export const addUserSuccess = taskManager.actions.addUserSuccess
export const removeUser = taskManager.actions.removeUser
export const removeUserSuccess = taskManager.actions.removeUserSuccess
export const addTaskSuccess = taskManager.actions.addTaskSuccess
export const addTaskError = taskManager.actions.addTaskError
export const resetTaskManagerState = taskManager.actions.resetTaskManagerState
export const loadAllTasks = taskManager.actions.loadAllTasks
export const loadAllTasksSuccess = taskManager.actions.loadAllTasksSuccess
export const loadAllTasksError = taskManager.actions.loadAllTasksError
export const loadAllUsers = taskManager.actions.loadAllUsers
export const loadAllUsersSuccess = taskManager.actions.loadAllUsersSuccess
export const loadAllUsersError = taskManager.actions.loadAllUsersError
export const openTaskModal = (modalMode, taskId) =>
  taskManager.actions.openModal({ modalMode, taskId })
export const closeTaskModal = taskManager.actions.closeModal
export const loadTask = taskManager.actions.loadTask
export const loadTaskSuccess = taskManager.actions.loadTaskSuccess
export const loadTaskError = taskManager.actions.loadTaskError
export const updateTask = taskManager.actions.updateTask
export const updateTaskSuccess = taskManager.actions.updateTaskSuccess
export const updateTaskError = taskManager.actions.updateTaskError
export const deleteTask = taskManager.actions.deleteTask
export const deleteTaskSuccess = taskManager.actions.deleteTaskSuccess
export const deleteTaskError = taskManager.actions.deleteTaskError
export const deleteMultiTasks = taskManager.actions.deleteMultiTasks
export const deleteMultiTasksError = taskManager.actions.deleteMultiTasksError
export const deleteMultiTasksSuccess =
  taskManager.actions.deleteMultiTasksSuccess

export const selectTaskManagerState = (state) => {
  return state.taskManager
}

export const selectAllTasks = (state) => state.taskManager.tasks

export const selectWorkspaceUsers = (state) => state.taskManager.users.data

export const selectTaskModalState = (state) => state.taskManager.modal

export const selectEditableTask = (state) => state.taskManager.editableTask
