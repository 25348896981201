const filesSortOptions = {
  names: {
    name: 'A - Z',
    type: 'names',
    reqParams: {
      order_by: 'name',
    },
  },
  size: {
    name: 'Size',
    type: 'size',
    reqParams: {
      order_by: 'size',
    },
  },
  date: {
    name: 'Date',
    type: 'date',
    reqParams: {
      order_by: 'createdAt',
    },
  },
  type: {
    name: 'Type',
    type: 'type',
    reqParams: {
      order_by: 'type',
    },
  },
}

export default filesSortOptions
