export const PAYMENT_USER_DATA_ADD = 'PAYMENT_USER_DATA_ADD'
export const PAYMENT_USER_DATA_CLEAR = 'PAYMENT_USER_DATA_CLEAR'

export function paymentUserDataAdd(data) {
  return {
    type: PAYMENT_USER_DATA_ADD,
    payload: { data },
  }
}

export function paymentUserDataClear() {
  return {
    type: PAYMENT_USER_DATA_CLEAR,
  }
}
