//Refactoring №3
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import Button, { ButtonTheme } from 'components/Button';
import {
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
} from 'features/app';
import MainContext from 'store/main/context/main-context';

import { ReactComponent as EmptyFolderLogo  } from 'components/shared/Preloader/assets/logo.svg'

// import { ReactComponent as EmptyFolderLogo } from 'static/assets/svg/empty-folder.svg';

import s from './style.module.scss';

const EmptyFolder = ({
  title,
  description,
  btnText,
  dropBtn,
  handleDropDown,
}) => {
  const { state: mainState } = useContext(MainContext);
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);
  const disabledMultisig = isMultisigActivated && !isMultisigPartisipant;
  const isTokenizedFolder = mainState?.folder?.entry_groups?.some((item) => item.is_tokenized)
  const history = useHistory();
  const { t } = useTranslation('owner');

  const translateTitle = () => {
    if (title === 'Empty') {
      return history.location.pathname === '/main'
        ? 'Empty Drive'
        : t('emptyFolder');
    }

    return title;
  };

  const translateDescription = () => {
    const text = 'Drag the file here or press the button to drop files';
    if (description === text) {
      return t('dragFile');
    }
    return description;
  };

  return (
    <section className={s.empty}>
      <EmptyFolderLogo className={s.img} />
      <h4 className={s.title}>{translateTitle()}</h4>
      {!disabledMultisig && (
        <p className={s.description}>{translateDescription()}</p>
      )}
      {dropBtn && !disabledMultisig && (
        <Button
          theme={ButtonTheme.DARK}
          className={s.saveButton}
          disabled={isTokenizedFolder}
          type="button"
          value={btnText ?? 'Upload'}
          onClick={handleDropDown}
          data-test="empty-folder_save_button"
        />
      )}
    </section>
  );
};

EmptyFolder.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  dropBtn: PropTypes.bool,
  handleDropDown: PropTypes.func,
};

EmptyFolder.defaultProps = {
  title: 'Empty',
  description: 'Drag the file here or press the button to drop files',
  dropBtn: true,
  handleDropDown: () => {},
};

export default EmptyFolder;
