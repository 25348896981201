import React from 'react';
import CN from 'classnames';
import styles from './styles.module.scss';

const NeyraLogo = ({ className }) => {
  return (
    <div className={CN(styles.svgContainer, className)}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 681 423"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="288.791" cy="107.767" r="4.06796" fill="currentColor" />
        <circle cx="392.524" cy="107.767" r="4.06796" fill="currentColor" />
        <circle cx="306.08" cy="84.3762" r="3.05097" fill="currentColor" />
        <circle cx="377.269" cy="84.3762" r="3.05097" fill="currentColor" />
        <circle cx="323.369" cy="63.0193" r="2.03398" fill="currentColor" />
        <circle cx="359.98" cy="63.0193" r="2.03398" fill="currentColor" />
        <g transform="translate(211.5, 211.5)">
          <ellipse
            cx="0"
            cy="0"
            rx="208.483"
            ry="209.5"
            stroke="currentColor"
            strokeWidth="3"
            strokeDasharray="1 12"
            className={styles.leftCircle}
          />
        </g>
        <g transform="translate(470.833, 211.5)">
          <ellipse
            cx="0"
            cy="0"
            rx="208.483"
            ry="209.5"
            stroke="currentColor"
            strokeWidth="3"
            strokeDasharray="1 12"
            className={styles.rightCircle}
          />
        </g>
        <circle cx="280.655" cy="130.141" r="4.06796" fill="currentColor" />
        <circle cx="404.728" cy="130.141" r="4.06796" fill="currentColor" />
        <circle cx="268.452" cy="156.583" r="4.06796" fill="currentColor" />
        <path
          d="M417.949 156.583C417.949 158.829 416.128 160.651 413.881 160.651C411.634 160.651 409.813 158.829 409.813 156.583C409.813 154.336 411.634 152.515 413.881 152.515C416.128 152.515 417.949 154.336 417.949 156.583Z"
          fill="currentColor"
        />
        <circle cx="264.384" cy="183.025" r="4.06796" fill="currentColor" />
        <path
          d="M423.034 183.025C423.034 185.271 421.213 187.092 418.966 187.092C416.719 187.092 414.898 185.271 414.898 183.025C414.898 180.778 416.719 178.957 418.966 178.957C421.213 178.957 423.034 180.778 423.034 183.025Z"
          fill="currentColor"
        />
        <circle cx="262.349" cy="211.5" r="4.06796" fill="currentColor" />
        <path
          d="M425.068 211.5C425.068 213.747 423.247 215.568 421 215.568C418.753 215.568 416.932 213.747 416.932 211.5C416.932 209.253 418.753 207.432 421 207.432C423.247 207.432 425.068 209.253 425.068 211.5Z"
          fill="currentColor"
        />
        <circle cx="263.366" cy="236.925" r="5.08495" fill="currentColor" />
        <path
          d="M425.068 238.959C425.068 241.767 422.791 244.044 419.983 244.044C417.175 244.044 414.898 241.767 414.898 238.959C414.898 236.151 417.175 233.874 419.983 233.874C422.791 233.874 425.068 236.151 425.068 238.959Z"
          fill="currentColor"
        />
        <circle cx="268.452" cy="264.383" r="4.06796" fill="currentColor" />
        <path
          d="M418.966 264.383C418.966 266.63 417.145 268.451 414.898 268.451C412.651 268.451 410.83 266.63 410.83 264.383C410.83 262.137 412.651 260.315 414.898 260.315C417.145 260.315 418.966 262.137 418.966 264.383Z"
          fill="currentColor"
        />
        <circle cx="278.621" cy="290.825" r="4.06796" fill="currentColor" />
        <path
          d="M410.83 290.825C410.83 293.072 409.009 294.893 406.762 294.893C404.515 294.893 402.694 293.072 402.694 290.825C402.694 288.579 404.515 286.757 406.762 286.757C409.009 286.757 410.83 288.579 410.83 290.825Z"
          fill="currentColor"
        />
        <circle cx="290.825" cy="315.233" r="4.06796" fill="currentColor" />
        <path
          d="M396.592 315.233C396.592 317.48 394.771 319.301 392.524 319.301C390.278 319.301 388.456 317.48 388.456 315.233C388.456 312.986 390.278 311.165 392.524 311.165C394.771 311.165 396.592 312.986 396.592 315.233Z"
          fill="currentColor"
        />
        <circle cx="304.046" cy="338.624" r="3.05097" fill="currentColor" />
        <path
          d="M380.32 338.624C380.32 340.309 378.954 341.675 377.269 341.675C375.584 341.675 374.219 340.309 374.219 338.624C374.219 336.939 375.584 335.573 377.269 335.573C378.954 335.573 380.32 336.939 380.32 338.624Z"
          fill="currentColor"
        />
        <circle cx="323.369" cy="359.981" r="2.03398" fill="currentColor" />
        <circle cx="341.675" cy="378.286" r="4.06796" fill="currentColor" />
        <path
          d="M362.014 359.981C362.014 361.104 361.104 362.015 359.981 362.015C358.857 362.015 357.947 361.104 357.947 359.981C357.947 358.857 358.857 357.947 359.981 357.947C361.104 357.947 362.014 358.857 362.014 359.981Z"
          fill="currentColor"
        />
      </svg>
    </div>
  );
};

export default NeyraLogo;
