// utils
import authRequest from 'utils/request/auth-request';
import { addNotification } from 'store/main/actions';
import { API_GET_FILE } from 'constants/api-urls';

// store
import {
  fileMove,
  fileMoveSuccess,
  fileMoveFailure,
} from '../../actions/file/file-move.actions';
import { getFilesRemove } from '../../actions/files/get-files.actions';

export default async (dispatch, files, folder) => {
  dispatch(fileMove());
  const url = `${API_GET_FILE}/move/multiply`;
  try {
    await authRequest.put(url, {
      folder: !folder
        ? null
        : typeof folder === 'string'
        ? folder
        : folder.folderId,
      files: files.map((file) => file.slug),
    });
    dispatch(fileMoveSuccess());
    dispatch(getFilesRemove(files));
  } catch (e) {
    addNotification({
      id: new Date().getTime(),
      message: e.response?.data?.message || '',
      type: 'error',
    });
    dispatch(fileMoveFailure(e));
  }
};
