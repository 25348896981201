import * as actions from '../../actions/selected-entity.actions'

export const selectedEntityClearEffect = () => (dispatch) =>
  dispatch(actions.selectedEntityClear())

export const selectedEntityShareClearEffect = () => (dispatch) =>
  dispatch(actions.selectedEntityShareClear())

export const selectedEntityMoveClearEffect = () => (dispatch) =>
  dispatch(actions.selectedEntityMoveClear())

export const selectedEntityAccessPreferencesClear = () => (dispatch) =>
  dispatch(actions.selectedEntityAccessPreferencesClear())
