import React from 'react';

const IconMic = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask
        id="mask0_4290_21919"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_4290_21919)">
        <path
          d="M12 13C11.4359 13 10.9615 12.8077 10.5769 12.4231C10.1923 12.0385 10 11.5641 10 11V5C10 4.4359 10.1923 3.96154 10.5769 3.57693C10.9615 3.19231 11.4359 3 12 3C12.5641 3 13.0385 3.19231 13.4231 3.57693C13.8077 3.96154 14 4.4359 14 5V11C14 11.5641 13.8077 12.0385 13.4231 12.4231C13.0385 12.8077 12.5641 13 12 13ZM11.5 20.5V16.9827C9.93333 16.8391 8.625 16.1987 7.575 15.0616C6.525 13.9244 6 12.5705 6 11H7C7 12.3833 7.4875 13.5625 8.4625 14.5375C9.4375 15.5125 10.6167 16 12 16C13.3833 16 14.5625 15.5125 15.5375 14.5375C16.5125 13.5625 17 12.3833 17 11H18C18 12.5705 17.475 13.9244 16.425 15.0616C15.375 16.1987 14.0667 16.8391 12.5 16.9827V20.5H11.5ZM12 12C12.2833 12 12.5208 11.9042 12.7125 11.7125C12.9042 11.5208 13 11.2833 13 11V5C13 4.71667 12.9042 4.47917 12.7125 4.2875C12.5208 4.09583 12.2833 4 12 4C11.7167 4 11.4792 4.09583 11.2875 4.2875C11.0958 4.47917 11 4.71667 11 5V11C11 11.2833 11.0958 11.5208 11.2875 11.7125C11.4792 11.9042 11.7167 12 12 12Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default IconMic;
