//Refactoring №3
import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';

const VaultTitle = ({ onAddField }) => {
  const { t } = useTranslation('contextMenu');

  return (
    <div className={styles.wrapper}>
      <button type="button" onClick={onAddField} className={styles.action}>
        {`+ ${t('main.addPassword.addField')}`}
      </button>
    </div>
  );
};

export default VaultTitle;
