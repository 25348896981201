//Refactoring №2
import React from 'react';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';
// import styles from './styles.module.css';

export default function AuthFooterInfo({
  className,
  signup = false,
  signin = false,
  NFT = false,
}) {
  const { t } = useTranslation('auth');
  return (
    <div className={cn('auth-footer-info', className)}>
      <div>
        {/* <span className="auth-footer-info-text">
          {signup && t('common.alreadyHaveAccount')}
          {signin && t('signup.dontHaveAccount')}
          {NFT && t('signup.dontHaveAccount')}
        </span> */}
        {/* <br className="auth-footer-break-text" />
        <Link
          data-test="auth_footer_info_sign-in_link"
          className={styles.signInLink}
          to={(signup && '/sign-in') || (signin && '/sign-up') || '#'}
        >
          {signin && ` ${t('signup.signup')}`}
          {signup && ` ${t('common.signin')}`}
        </Link> */}
      </div>
      {(signup || signin || NFT) && (
        <div>
          <span className="auth-footer-info-text auth-footer-info-policy">
            {t('common.byContinuing')}
          </span>
          <br className="auth-footer-break-text" />
          <a
            data-test="auth_footer_info_terms-of-service_link"
            href="https://www.ghostdrive.com/terms-of-service"
            className="policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('common.terms')}
          </a>
          <span className="auth-footer-info-text auth-footer-info-policy">
            ,{' '}
          </span>
          <a
            href="https://www.ghostdrive.com/privacy"
            data-test="auth_footer_info_privacy-policy_link"
            className="policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('common.privacy')},
          </a>
          <br className="auth-footer-break-text" />
          <span className="auth-footer-info-text auth-footer-info-policy">
            {t('common.and')}
          </span>
          <a
            data-test="auth_footer_info_cookie_policy_link"
            href="https://www.ghostdrive.com/privacy"
            className="policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('common.cookie')}
          </a>
          <span className="auth-footer-info-text auth-footer-info-policy">
            .
          </span>
        </div>
      )}
    </div>
  );
}
