export const entitiesTypes = {
  file: {
    type: 'file',
    id: 1,
  },
  folder: {
    type: 'folder',
    id: 2,
  },
};

export const entitiesTypesByKey = {
  1: 'file',
  2: 'folder',
};
