import {
  extendEventsByDate,
  filterEventsByDate,
} from 'utils/history/group-events-by-date';

import { HistoryActionTypes } from '../actions';

export const defaultState = {
  count: 0,
  page: 1,
  historyBlocks: {},
  historyItems: [],
  filteredHistoryItems: [],
  startDate: null,
};

export const initiateState = (initState) => initState;

export default (state, { payload, type }) => {
  const { count, items, date } = payload;
  const historyBlocks = items?.length
    ? extendEventsByDate(state.historyBlocks, items, state.startDate)
    : state.historyBlocks;

  switch (type) {
    case HistoryActionTypes.loadHistory:
      return {
        ...state,
        count,
        historyItems: [...state.historyItems, ...items],
        filteredHistoryItems: [...state.historyItems, ...items],
        historyBlocks,
        page: state.page + 1,
      };

    case HistoryActionTypes.filterByDate:
      return {
        ...state,
        startDate: date,
        filteredHistoryItems: filterEventsByDate(state.historyItems, date),
      };
    case HistoryActionTypes.clearHistoryFilter:
      return {
        ...state,
        startDate: null,
        filteredHistoryItems: [...state.historyItems],
      };

    case HistoryActionTypes.reset:
      return {
        ...defaultState,
      };

    default:
      return state;
  }
};
