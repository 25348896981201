//Refactoring №2
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { inviteMemberToWorkspace } from 'store/workspace/effects';
import { loginUsernameEffect } from 'store/auth/effects/index';
import { selectRecoverUser } from 'store/account/reducers/user-recover.reducer';
import {
  failUserRecovery,
  successUserRecovery,
} from 'store/account/actions/recover-user.actions';

import { validationRegExp } from 'utils/validation/regexp';

import AuthInput from './auth-input';

const CreateWorkspace = () => {
  const [inputValue, setInputValue] = useState('');
  const [workspaceMembers, setWorkspaceMembers] = useState([]);
  const [error, setError] = useState(false);
  const recoveredUser = useSelector(selectRecoverUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const onInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setError(!validationRegExp.email.test(value));
  };

  const onMemberInputEnterHandler = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13 || e.code === 'NumpadEnter') {
      if (inputValue && !error && !workspaceMembers.includes(inputValue)) {
        setWorkspaceMembers([...workspaceMembers, inputValue]);
        setInputValue('');
        return;
      }
      setInputValue(inputValue);
      setError(true);
    }
  };

  const onSkipClickHandler = () => {
    loginUsernameEffect(
      recoveredUser.username,
      recoveredUser.password,
      history
    ).then(() => {
      dispatch(successUserRecovery());
      history.push('/');
    });
  };

  const removeMember = (index) => () => {
    setWorkspaceMembers(
      workspaceMembers.filter((item) => item !== workspaceMembers[index])
    );
  };

  const inviteMembers = () => {
    if (inputValue && !error) {
      const invites =
        inputValue && !error ? {}[inviteMemberToWorkspace(inputValue)] : [];

      workspaceMembers.forEach((member) => {
        invites.push(inviteMemberToWorkspace(member));
      });

      loginUsernameEffect(
        recoveredUser.username,
        recoveredUser.password,
        history
      ).then(() => {
        Promise.all(invites)
          .then(() => {})
          .finally(() => {
            onSkipClickHandler();
          })
          .catch(() => {
            dispatch(failUserRecovery());
          });
      });
    }
  };

  return (
    <section className="auth">
      <header className="head__content">
        <div className="auth-title-wrapper">
          <div className="auth-beta-version-wrapper">
            <Link
              to="/"
              className="header__logo"
              data-test="invite-member_logo_link"
            >
              <img
                src={require('static/assets/img/ghost-logo.svg').default}
                alt="Logo"
              />
            </Link>
          </div>
          <h1 className="auth-title-mobile">Sign Up</h1>
        </div>
      </header>
      <div className="login-form-wrapper">
        <div className="auth-left" />
        <div className="auth-right auth-right__no-height auth-right_signup">
          <div className="auth__form auth__shared step-4 scroll-down">
            <h3 className="form__title">Invite members to your workspace</h3>
            <p className="form__desc">Add one or more emails</p>
            <div className="auth-input__helper">
              <AuthInput
                type="text"
                name="member"
                placeholder="Enter an email"
                value={inputValue || ''}
                withValidation={error}
                error={'Enter a valid email'}
                onChange={onInputChange}
                onBlur={onInputChange}
                onKeyDown={onMemberInputEnterHandler}
                debounceTimeout={0}
                data-test="invite-member_email_field"
              />
              <div className={'auth__input-help'}>Press Enter to ADD</div>
            </div>
            <div className="workspace-invite-member">
              {workspaceMembers.map((member, index) => (
                <div
                  className="workspace-invite-member-item"
                  key={`member-item-${index}`}
                >
                  <span>{member}</span>
                  <button
                    type="button"
                    className="workspace-invite-member-item-remove"
                    onClick={removeMember(index)}
                    data-test={`invite-member_workspace_member_button[${index}]`}
                  />
                </div>
              ))}
            </div>
            <div className="small-end auth-footer-button-container">
              <button
                type="submit"
                className="link auth__square"
                onClick={onSkipClickHandler}
                data-test="invite-member_skip_button"
              >
                skip
              </button>
              <button
                type="submit"
                className="auth__square"
                onClick={inviteMembers}
                disabled={error}
                data-test="invite-member_next_button"
              >
                next
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateWorkspace;
