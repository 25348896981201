import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import CN from 'classnames';

import Input from '../Input';
import CreatingProfile from '../CreatingProfile';
import ButtonsWithIcons from '../ButtonWithIcon';
import NeyraLaunchMessage from '../NeyraLaunchMessage';
import useAnimatedMessage from 'utils/hooks/useAnimatedMessage';
import { validationRegExp } from 'utils/validation/regexp';
import useWalletHandler from 'utils/hooks/useWalletHandler';
import { NEYRA_AI_API } from 'constants/api-urls';
import { loginEmail } from 'web3-auth/lib/es5';
import { verifyUser } from 'client-neyra';

import { ReactComponent as SuccessfulIcon } from '../../assets/successful.svg';
import styles from './styles.module.scss';

const stepsSequence = [
  'name',
  // 'birthday',
  // 'country',
  'connection',
  'email',
  'password',
  'code',
  'creatingProfile',
];

const validatePassword = (password) => {
  const isMinLengthValid = password.length >= 8;
  const isCapitalValid = validationRegExp.hasUpperCaseCharacter.test(password);
  const isLowerCaseValid = validationRegExp.hasLowCaseCharacter.test(password);
  const isNumberValid = validationRegExp.hasDigits.test(password);

  return (
    isMinLengthValid && isCapitalValid && isLowerCaseValid && isNumberValid
  );
};

const validateEmail = async (email) => {
  let schema = yup.object().shape({
    email: yup
      .string()
      .email()
      .matches(/@[^.]*\./),
  });

  try {
    return await schema.isValid({ email });
  } catch (error) {
    return false;
  }
};

const RegistrationStep = () => {
  const { t } = useTranslation('auth');
  const scrollRef = useRef(null);
  const [userAnswers, setUserAnswers] = useState({});
  const [currentStep, setCurrentStep] = useState('initial');
  const [creatingProfile, setCreatingProfile] = useState(false);
  const [continueRegistration, setContinueRegistration] = useState(false);
  const [autoScroll, setAutoScroll] = useState(true);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();
  const { handleClick } = useWalletHandler(history, 'chat');
  const text = t('initialPage.creatingProfile');
  const steps = {
    initial: { question: '', options: null },
    name: { question: t('initialPage.nameQuestion'), options: null },
    birthday: { question: t('initialPage.birthdayQuestion'), options: null },
    country: { question: t('initialPage.countryQuestion'), options: null },
    connection: {
      question: t('initialPage.chooseConnectionMethod'),
      options: ['email', 'wallet'],
      // options: ['telegram', 'email', 'wallet'],
    },
    email: { question: t('initialPage.emailQuestion'), options: null },
    password: { question: t('initialPage.createPassword'), options: null },
    code: { question: t('initialPage.enterCode'), options: null },
  };

  const { animatedMessage, isPrinting } = useAnimatedMessage(
    currentStep === 'creatingProfile' ? text : steps[currentStep].question
  );

  useEffect(() => {
    let interval;
    if (autoScroll) {
      interval = setInterval(scrollToBottom, 500);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [autoScroll]);

  useEffect(() => {
    scrollToBottom();
  }, [currentStep]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const addAnswer = async (value, isOption = false) => {
    if (!value) return;

    try {
      if (currentStep === 'email') {
        const validate = await validateEmail(value);
        if (!validate) {
          setError(t('initialPage.invalidEmail'));
          console.warn('Invalid email');
          return;
        }
      }

      if (currentStep === 'password') {
        if (validatePassword(value)) {
          try {
            await loginEmail({
              name: userAnswers['name'].answer,
              password: value,
              email: userAnswers['email'].answer,
              NEYRA_AI_API,
            });
          } catch (error) {
            if (error.response.data.message === 'Password is incorrect.') {
              setError(t('initialPage.userExists'));
              console.warn('User already exists');
            } else {
              console.error(error);
            }
            return;
          }
        } else {
          setError(t('initialPage.invalidPassword'));
          console.warn(
            'Password must be 8 or more characters with at least 1 uppercase, 1 lowercase, and 1 digit'
          );
          return;
        }
      }

      if (currentStep === 'code') {
        try {
          await verifyUser({
            body: {
              code: value,
              email: userAnswers['email'].answer,
            },
          });
          setCreatingProfile(true);
        } catch (error) {
          if (error.response.message) setError(error.response.message);
          return;
        }
      }

      if (currentStep === 'name') {
        steps.birthday.question = t('initialPage.birthdayQuestionUpdated', {
          value,
        });
      }

      if (currentStep === 'connection' && value === 'wallet') {
        handleClick('wallet');
        return;
      }

      if (currentStep !== 'code' && currentStep !== 'connection') {
        const updatedAnswers = { ...userAnswers };
        updatedAnswers[currentStep] = {
          ...steps[currentStep],
          answer: value,
          isOption,
          successful: true,
          addStatus: currentStep === 'password',
        };
        setUserAnswers(updatedAnswers);
      }

      handleNextStep(value);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextStep = (value) => {
    setError('');
    const currentIndex = stepsSequence.indexOf(currentStep);
    if (currentIndex < stepsSequence.length - 1) {
      if (currentStep === 'connection') {
        setCurrentStep(value);
      } else {
        setCurrentStep(stepsSequence[currentIndex + 1]);
      }
      scrollToBottom();
    }
  };

  const handleContinue = () => {
    setContinueRegistration(true);
    setAutoScroll(false);
    setTimeout(() => {
      setCurrentStep('name');
      scrollToBottom();
    }, 1000);
  };

  useEffect(() => {
    const handleScroll = () => {
      const element = scrollRef.current;
      if (element) {
        const isAtBottom =
          element.scrollHeight - element.scrollTop === element.clientHeight;
        setIsScrolledToBottom(isAtBottom);
      }
    };

    const element = scrollRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className={styles.registrationStepContainer}>
      <div ref={scrollRef} className={styles.scrollContent}>
        <div className={styles.shadowBlock} />
        <div
          className={CN(
            styles.shadowBlockBottom,
            !continueRegistration && styles.lowerShadow,
            isScrolledToBottom || autoScroll ? '' : styles.visible
          )}
        />
        <NeyraLaunchMessage
          finishTyping={() => {
            setAutoScroll(false);
          }}
          hideButton={continueRegistration}
          msgType="profilePlus"
        />
        {Object.entries(userAnswers).map(([key, value]) => (
          <div key={key}>
            <div className={styles.question}>
              {value.question}
              <div className={styles.status}>
                {value.addStatus &&
                  (value.successful ? <SuccessfulIcon /> : <></>)}
              </div>
            </div>
            {key !== 'password' && (
              <div className={styles.answer}>{value.answer}</div>
            )}
          </div>
        ))}
      </div>
      <div className={styles.footerBlock}>
        <div className={styles.question}>{animatedMessage}</div>
        <div className={styles.inputContainer}>
          {creatingProfile ? (
            <CreatingProfile userAnswers={userAnswers} />
          ) : (
            !isPrinting &&
            (steps[currentStep].options ? (
              <ButtonsWithIcons
                options={steps[currentStep].options}
                selectButton={(option) => {
                  addAnswer(option, true);
                }}
              />
            ) : (
              <Input
                error={error}
                addAnswer={addAnswer}
                step={currentStep}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={continueRegistration}
              />
            ))
          )}
          <div
            className={CN(
              styles.continueButtonWrapper,
              continueRegistration && styles.hideButton
            )}
          >
            <button
              onClick={handleContinue}
              className={CN(
                styles.continueButton,
                !autoScroll && styles.activeButton
              )}
            >
              {t('common.continue')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegistrationStep;
