import React from 'react'

const TriangleIcon = ({ color, rotate }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    transform={`rotate(${rotate})`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 3.51953L9.64645 7.16598C9.96143 7.48096 9.73835 8.01953 9.29289 8.01953H2.70711C2.26165 8.01953 2.03857 7.48096 2.35355 7.16598L6 3.51953Z"
      fill={color}
    />
  </svg>
)

TriangleIcon.defaultProps = {
  color: '#7E8A9F',
}

export default TriangleIcon
