//Refactoring №4
import React, { useMemo, useState, useEffect } from 'react';
import languages from 'language-list';
import _ from 'lodash';

import Space from 'components/space';
import AppSelect from 'components/AppSelect';

import styles from './style.module.scss';

export const LANG_KEY = 'NeyraLanguage';

export default function NeyraLanguage() {
  const [selectedLang, setSelectedLang] = useState('en');
  const list = useMemo(() =>  {
    const array = languages().getData() || [];
    return _.sortBy(([...array, { language: "Ukrainian", code: "ukr" }]), 'language')
  }, [])
  const placeholder = useMemo(() => {
    if (selectedLang) {
      return languages().getLanguageName(selectedLang)
    }
    return 'Englist'
  }, [selectedLang])

  const LANGUAGE_OPTIONS = useMemo(
    () => 
      list.map((item) => ({
        label: item.language,
        value: item.code,
      })),
    [list]
  );

  useEffect(() => {
    const key = localStorage.getItem(LANG_KEY);
    if (key) {
      setSelectedLang(key)
    }
  }, [])

  const selectLeng = {
    control: (styles, state) => ({
      ...styles,
      height: 38,
      width: 183,
      borderRadius: 4,
      boxShadow: 'none',
      padding: '8px 13px 6px 11px',
      cursor: 'pointer',
      fontFamily: "'SF Pro Text', sans-serif",
      fontSize: '14px',
      backgroundColor: 'var(--tooltipContent)',
      lineHeight: '21px',
      '&:hover': {
        borderColor: !state.isFocused && 'var(--inputBorder)',
      },
      borderColor: state.isFocused ? 'var(--primeColor)' : 'var(--inputBorder)',
    })
  }

  const changelanguage = (value) => {
    localStorage.setItem(LANG_KEY, value.value) 
    setSelectedLang(value.value)
  }

  return (
    <Space
      className={styles.languageSpace}
      contentClassName={styles.languageContent}
      headerClassName={styles.languageHeader}
      title={'Neyra Language'}
    >
      <AppSelect
        hideLabel
        placeholder={placeholder}
        options={LANGUAGE_OPTIONS}
        onChange={changelanguage}
        className={styles.languageSelect}
        newStyles={selectLeng}
      />
    </Space>
    );
}
