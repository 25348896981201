//Refactoring №3
import React from 'react';
import { useTranslation } from 'react-i18next';

import MetaItem from './item';
import { ReactComponent as SaveIcon } from './assets/SavedIcon.svg';
import { ReactComponent as SharedIcon } from './assets/SharedIcon.svg';
import { ReactComponent as ValuesIcon } from './assets/ValuesIcon.svg';
import { ReactComponent as ViewsIcon } from './assets/ViewsIcon.svg';

import styles from './style.module.scss';

const MetaInfo = ({ viewed, downloaded, shares }) => {
  const { t } = useTranslation('owner');
  return (
    <div className={styles.metaWrapper}>
      <MetaItem
        label={t('rightFileMenu.meta.views')}
        value={viewed}
        icon={<ViewsIcon />}
      />
      <MetaItem
        label={t('rightFileMenu.meta.downloaded')}
        value={downloaded}
        icon={<SaveIcon viewBox="0 0 13 13" />}
      />
      <MetaItem
        label={t('common.shared')}
        value={shares}
        icon={<SharedIcon viewBox="0 0 13 13" />}
      />
      <MetaItem
        label={t('common.values')}
        value={0}
        icon={<ValuesIcon viewBox="0 0 13 13" />}
      />
    </div>
  );
};

export default MetaInfo;
