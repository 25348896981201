//Refactoring №3
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import CN from 'classnames';
import { isEmpty } from 'lodash';
import useNotification from 'utils/hooks/use-notification';
import { useSelector, useDispatch } from 'react-redux';

import { ReactComponent as ArrorIcon } from './assets/arrow.svg';
import { ReactComponent as MetamaskIcon } from 'static/assets/svg/metamask-icon.svg';
import { ReactComponent as PhantomIcon } from 'static/assets/svg/phantom-icon.svg';
import UserIcon from 'components/users/user-icon';

import { ReactComponent as ShareIcon } from './assets/share-link.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';
import { isMetamaskAddress, isPhantomAddress } from 'utils/crypto/validation';
import copyToClipBoard from 'utils/copy-to-clipboard-without-https';

import { entityDeleteSharesEffect } from 'store/home/effects/entity-actions/entity-change-access.effect';
import { unshareFile } from 'store/home/effects/file/share-file.effect';
import {
  unshareFileByEmailMultisig,
  unshareFileByWalletMultisig,
} from 'store/web3/multisig';
import { handleSuccessModal } from 'features/modals/modal-slice';

import { getTypeByEntityType } from 'utils/string';
import {
  selectIsMultisigActivated,
  selectIsMultisigPartisipant,
} from 'features/app';

import MainContext from 'store/main/context/main-context';

import styles from './style.module.scss';

const Members = ({
  slug,
  members = 0,
  invited = 0,
  updateInitialData,
  isInvitedOpen,
  setIsInvitedOpen,
  entityType,
}) => {
  const dispatch = useDispatch();
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const isMultisigPartisipant = useSelector(selectIsMultisigPartisipant);
  const { t } = useTranslation('owner');
  const { addNotification } = useNotification();
  const {
    state: { workspace },
  } = useContext(MainContext);
  const [isMembersOpen, setIsMembersOpen] = useState(false);
  const canCancellInvite = () => {
    return (
      !(isMultisigActivated && !isMultisigPartisipant) ||
      (isMultisigActivated && isMultisigPartisipant) ||
      !isMultisigActivated
    );
  };

  const toggleMembersList = () => {
    setIsMembersOpen(!isMembersOpen);
  };

  const toggleInvitedList = () => {
    setIsInvitedOpen(!isInvitedOpen);
  };

  const unshareAllHandler = () => {
    entityDeleteSharesEffect(slug, { type: 3 }).then((data) => {
      updateInitialData({ shares: data.shares });
    });
  };

  const unshareHandler = (shareId, address, email) => {
    if (isMultisigActivated) {
      if (email) {
        unshareFileByEmailMultisig(slug, email).then(() => {
          dispatch(
            handleSuccessModal({
              isOpen: true,
              text: `The ${getTypeByEntityType(
                entityType,
                false
              )} will be unshared when all multi-signature users will sign the request.`,
            })
          );
        });
      } else {
        unshareFileByWalletMultisig(slug, address).then(() => {
          dispatch(
            handleSuccessModal({
              isOpen: true,
              text: `The ${getTypeByEntityType(
                entityType,
                false
              )} will be unshared when all multi-signature users will sign the request.`,
            })
          );
        });
      }
    } else {
      unshareFile(slug, shareId).then((data) => {
        updateInitialData({ shares: data.shares });
      });
    }
  };

  const copyInvateLink = (token) => {
    const url = `${window.location.protocol}/${window.location.host}/join-guest/${token}?id=${workspace.id}`;
    copyToClipBoard(url);
    addNotification(t('rightFileMenu.sharing.invitationLinkCopied'), 'success');
  };

  const renderIcon = ({
    logo,
    color,
    displayed_name,
    index,
    public_address,
    email,
  }) => {
    if (isEmpty(logo) && (displayed_name || email)) {
      const name = displayed_name || email || '';
      return (
        <div
          style={{ backgroundColor: color }}
          className={styles.memberCustomLogo}
        >
          <span>{name[0].toUpperCase()}</span>
        </div>
      );
    }
    if (isMetamaskAddress(public_address)) return <MetamaskIcon />;
    if (isPhantomAddress(public_address)) return <PhantomIcon />;

    return <img src={logo} alt={`Member Avarat ${index}`} />;
  };

  return (
    <div className={styles.membersContent}>
      <div className={styles.membersTitle}>
        <p className={styles.memberTitle}>
          {`${t('rightFileMenu.sharing.driveTeam')}: ${members.length}`}
        </p>
        <button
          className={CN(
            styles.memberOpenBtn,
            isMembersOpen && styles.memberOpened
          )}
          onClick={toggleMembersList}
        >
          <ArrorIcon />
        </button>
      </div>
      {isMembersOpen && !!members.length && (
        <ul className={styles.memberList}>
          {members.map(({ logo, color, displayed_name }, index) => (
            <li key={`member-${index}`} className={styles.member}>
              <div className={styles.memberLogo}>
                {renderIcon({ logo, index, displayed_name, color })}
              </div>
              <div className={styles.memberAbout}>
                <div className={styles.memberDesc}>
                  <p className={styles.memberName}>{displayed_name}</p>
                  <p className={styles.memberRole}>
                    {t('rightFileMenu.sharing.member')}
                  </p>
                </div>
                <ShareIcon />
              </div>
            </li>
          ))}
        </ul>
      )}
      <div className={styles.membersTitle}>
        <p className={styles.memberTitle}>{`${t(
          'rightFileMenu.sharing.invited'
        )}: ${invited.length}`}</p>
        <button
          className={CN(
            styles.memberOpenBtn,
            isInvitedOpen && styles.memberOpened
          )}
          onClick={toggleInvitedList}
        >
          <ArrorIcon />
        </button>
      </div>
      {isInvitedOpen && !!invited.length && (
        <div>
          <ul className={styles.memberList}>
            {invited.map(
              (
                {
                  public_address,
                  isRegistered,
                  color,
                  token,
                  logo,
                  displayed_name,
                  email,
                  id,
                  ...rest
                },
                index
              ) => (
                <li key={`invited-${index}`} className={styles.member}>
                  <div className={styles.memberLogo}>
                    <UserIcon
                      user={{
                        public_address,
                        displayed_name,
                        email,
                        logo,
                        ...rest,
                      }}
                      color={color}
                    />
                  </div>
                  <div className={styles.memberAbout}>
                    <div className={styles.memberDesc}>
                      <p className={styles.memberName}>
                        {displayed_name || public_address || email}
                      </p>
                    </div>
                    <div className={styles.memberAction}>
                      {!isRegistered && (
                        <p className={styles.memberStatus}>
                          {t('rightFileMenu.sharing.pendingLowerCase')}
                        </p>
                      )}
                      {!isRegistered && (
                        <button
                          onClick={() => {
                            copyInvateLink(token);
                          }}
                          className={styles.memberShareLink}
                        >
                          <ShareIcon />
                        </button>
                      )}
                      {canCancellInvite(isRegistered) && (
                        <button
                          onClick={() => {
                            unshareHandler(id, public_address, email);
                          }}
                          className={styles.removeMember}
                        >
                          <CloseIcon />
                        </button>
                      )}
                    </div>
                  </div>
                </li>
              )
            )}
          </ul>
          <div className={styles.deleteInvited}>
            {!isMultisigActivated && (
              <button onClick={unshareAllHandler}>
                {t('rightFileMenu.sharing.deleteAll')}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Members;
