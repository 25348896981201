//Refactoring №3
import React, { useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useTranslation } from 'react-i18next';

import { addEmailAndPasswordEffect } from 'store/account/effects/edit-user-info.effects';
import useNotification from 'utils/hooks/use-notification';
import { validationRegExp } from 'utils/validation/regexp';

import { addEmailMessage } from 'containers/auth/login-crypto';
import Button, { ButtonTheme } from 'components/Button';
import CloseIcon from 'components/svg/close';
import InputField from 'components/InputField';
import Tooltip from 'components/shared/Tooltip/tooltip';
import { ReactComponent as LoadingIcon } from 'static/assets/svg/loading-icon.svg';

import styles from './style.module.scss';

const validations = [
  'hasMinLength',
  'hasLowCaseCharacter',
  'hasUpperCaseCharacter',
  'hasDigits',
];

const minCharsLength = 8;

export default function AddEmailModal({ isOpen, close, titleName }) {
  const { t } = useTranslation('account');
  const [newEmailAddress, setNewEmailAddress] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [errorMessages, setErrorMessages] = useState({
    email: '',
    newPassword: '',
    repeatPassword: '',
  });
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [valid, setValid] = useState({});
  const [isWaiting, setWaiting] = useState(false);

  const { addNotification } = useNotification();

  const handleTitle = useCallback(() => {
    if (titleName === 'Add email') {
      return t('Profile.addEmail');
    }
    return titleName;
  }, [titleName]);

  const DEFAULT_ERROR_MESSAGES = useMemo(
    () => ({
      emailRequired: t('Profile.emailRequired'),
      emailInvalid: t('Profile.emailInvalid'),
      passwordRequired: t('Profile.passwordRequired'),
      passwordInvalid: t('Profile.passwordInvalid'),
      passwordDoesntMatch: t('Profile.passwordsDontMatch'),
    }),
    []
  );

  const cancelHandler = () => {
    setNewEmailAddress('');
    setNewPassword('');
    setRepeatPassword('');
    setErrorMessages({
      email: '',
      newPassword: '',
      repeatPassword: '',
    });
    setTooltipOpen(false);
    setWaiting(false);
    close();
  };

  const validatePassword = (value) => {
    const validRules = {};
    validations.forEach((validation) => {
      if (validation === 'hasMinLength') {
        validRules[validation] = value.length >= minCharsLength;
      } else {
        validRules[validation] = validationRegExp[validation].test(value);
      }
    });
    setValid(validRules);
    return validRules;
  };

  const errorValidator = (value, isValid, key) => {
    if (!value) {
      return DEFAULT_ERROR_MESSAGES[`${key}Required`];
    }
    if (!isValid) {
      return DEFAULT_ERROR_MESSAGES[`${key}Invalid`];
    } else {
      return '';
    }
  };

  const emailError = useMemo(
    () =>
      errorValidator(
        newEmailAddress,
        validationRegExp.email.test(newEmailAddress),
        'email'
      ),
    [newEmailAddress, errorMessages.email]
  );
  const passwordError = useMemo(
    () =>
      errorValidator(
        newPassword,
        Object.values(validatePassword(newPassword)).every(
          (newPassword) => newPassword
        ),
        'password'
      ),
    [newPassword, errorMessages.newPassword]
  );

  const repeatPasswordError = useMemo(
    () =>
      !repeatPassword && !newPassword
        ? DEFAULT_ERROR_MESSAGES['passwordRequired']
        : newPassword !== repeatPassword
        ? DEFAULT_ERROR_MESSAGES['passwordDoesntMatch']
        : '',
    [repeatPassword, errorMessages.repeatPassword]
  );

  const validatingInputs = (e) => {
    const type = e?.target.name;

    switch (type) {
      case 'email':
        setErrorMessages({ ...errorMessages, email: emailError });
        break;
      case 'new-password':
        setErrorMessages({ ...errorMessages, newPassword: passwordError });
        if (!passwordError) setTooltipOpen(false);
        break;
      case 'repeat-password':
        setErrorMessages({
          ...errorMessages,
          repeatPassword: repeatPasswordError,
        });
        break;
      default:
        setErrorMessages({
          email: emailError,
          newPassword: passwordError,
          repeatPassword: repeatPasswordError,
        });
        return !emailError && !passwordError && !repeatPasswordError
          ? true
          : false;
    }
  };

  const clearErrorInput = (e) => {
    const type = e?.target.name;
    if (type === 'email') setErrorMessages({ ...errorMessages, email: '' });
    if (type === 'new-password') {
      setErrorMessages({ ...errorMessages, newPassword: '' });
      setTooltipOpen(true);
    }
    if (type === 'repeat-password')
      setErrorMessages({ ...errorMessages, repeatPassword: '' });
  };

  const tooltipContent = (
    <section className={styles.tooltip__content}>
      <div className={styles.tooltip__content__title}>
        {t('Profile.keepPasswordSafe')}
      </div>
      <div>{t('Profile.passwordMustBe')}</div>
      <ul>
        <li className={valid[validations[0]] ? styles.green : ''}>
          {t('Profile.rule1')}
        </li>
        <li className={valid[validations[1]] ? styles.green : ''}>
          {t('Profile.rule2')}
        </li>
        <li className={valid[validations[2]] ? styles.green : ''}>
          {t('Profile.rule3')}
        </li>
        <li className={valid[validations[3]] ? styles.green : ''}>
          {t('Profile.rule4')}
        </li>
      </ul>
    </section>
  );

  const addNewEmailHandler = async () => {
    const validateSuccess = validatingInputs();
    if (validateSuccess) {
      setWaiting(true);
      const { address, signature } = await addEmailMessage(newEmailAddress);
      await addEmailAndPasswordEffect(
        newEmailAddress,
        newPassword,
        repeatPassword,
        signature,
        address
      )
        .then(() => {
          cancelHandler();
          addNotification(t('Profile.emailAdded'), 'success');
          setTimeout(() => {
            location.reload();
          }, 3000);
        })
        .catch(() => {
          setWaiting(false);
          setErrorMessages({
            ...errorMessages,
            email: t('Profile.emailAlreadyRegistered'),
          });
        });
    }
  };

  const onInputEnterHandler = (e) => {
    if (e.key === 'Enter' || e.keyCode === 13 || e.code === 'NumpadEnter') {
      addNewEmailHandler();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={styles.overlay}
      className={styles.modal}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick
      onRequestClose={cancelHandler}
    >
      <div className={styles.modal__wrapper} onKeyDown={onInputEnterHandler}>
        <button
          type="button"
          className={styles.modal__exit}
          onClick={cancelHandler}
        >
          <CloseIcon color="#4B576C" />
        </button>
        <div className={styles.modal__addEmailContent}>
          <h2 className={styles.modal__title}>{handleTitle()}</h2>
          <InputField
            label={t('Profile.emailAddress')}
            value={newEmailAddress}
            name="email"
            placeholder={t('Profile.enterEmail')}
            type="email"
            autoComplete="off"
            onChange={(e) => setNewEmailAddress(e.target.value)}
            withValidation
            error={errorMessages.email}
            onBlur={validatingInputs}
            onClick={clearErrorInput}
            className={styles.modal__input}
          />
          <InputField
            label={t('Profile.password')}
            value={newPassword}
            name="new-password"
            type="password"
            isPassword
            placeholder={t('Profile.password')}
            autoComplete="new-password"
            withValidation
            error={errorMessages.newPassword}
            tooltip={
              isTooltipOpen && (
                <Tooltip
                  text={tooltipContent}
                  className={styles.tooltip__wrapper}
                />
              )
            }
            onChange={(e) => setNewPassword(e.target.value)}
            onBlur={validatingInputs}
            onClick={clearErrorInput}
            className={`${styles.modal__input} ${styles.modal__addEmailEye}`}
          />
          <InputField
            label={t('Profile.confirmPassword')}
            value={repeatPassword}
            name="repeat-password"
            type="password"
            id="repeatPassword"
            isPassword
            placeholder={t('Profile.confirmPassword')}
            autoComplete="off"
            withValidation
            error={errorMessages.repeatPassword}
            onBlur={validatingInputs}
            onClick={clearErrorInput}
            onChange={(e) => setRepeatPassword(e.target.value)}
            className={`${styles.modal__input} ${styles.modal__addEmailEye}`}
          />
          <div className={styles.modal__buttons}>
            <Button
              value={t('Profile.cancel')}
              theme={ButtonTheme.TRANSPARENT}
              onClick={cancelHandler}
            />
            <Button
              theme={ButtonTheme.DARK}
              type="button"
              disabled={isWaiting}
              value={
                !isWaiting ? (
                  t('Profile.save')
                ) : (
                  <div className={styles.preload}>
                    <LoadingIcon />
                  </div>
                )
              }
              onClick={addNewEmailHandler}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
