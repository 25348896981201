// Refactoring №3
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Portal } from 'components/Portal';
import { Modal } from 'components/Modal';
import Button, { ButtonTheme } from 'components/Button';

import cn from 'classnames';
import styles from './style.module.scss';

const NoAttachedAddress = ({
  isOpen,
  onClose,
  addressList,
  onRetry,
  onAttach,
}) => {
  const { t } = useTranslation('account');
  return (
    <Portal id={'no-attached-address'}>
      <Modal className={styles.modal} onClose={onClose} isOpen={isOpen}>
        <div className={styles.modalContent}>
          <p className={styles.modalTitle}>
            {t('Profile.currentAddressNotAttached')}
          </p>
          <p className={styles.modalText}>{t('Profile.clickAttachNew')}</p>
          <p className={styles.smartContractsAddress}>
            <span className={styles.networkMarker}>BSC:</span>
            <span className={styles.networkAddress}>
              {window.metamask?.selectedAddress}
            </span>
          </p>
          <p className={cn([styles.modalText, styles.modalTextSeconst])}>
            {t('Profile.switchToAddress')}
          </p>
          <div className={styles.smartContractsAddresses}>
            {addressList.length &&
              addressList.map((address, index) => (
                <p key={index} className={styles.smartContractsAddress}>
                  <span className={styles.networkMarker}>BSC:</span>
                  <span className={styles.networkAddress}>
                    {address.public_address}
                  </span>
                </p>
              ))}
          </div>
          <div className={styles.buttonsContainer}>
            <Button onClick={onAttach} theme={ButtonTheme.TRANSPARENT}>
              {t('Profile.attachNew')}
            </Button>
            <Button onClick={onRetry}>{t('Profile.retry')}</Button>
          </div>
        </div>
      </Modal>
    </Portal>
  );
};

export default NoAttachedAddress;
