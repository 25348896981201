//Refactoring №3
import React from 'react';
import { Link } from 'react-router-dom';

import cn from 'classnames';
import s from './style.module.scss';

export default function PopupMenuItem({ className, link, state, children }) {
  return (
    <Link to={{ pathname: link, state }} className={cn(s.wrapper, className)}>
      {children}
    </Link>
  );
}
