//Refactoring №5
import React, { useCallback } from 'react';
import CN from 'classnames'
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as DeleteIcon } from 'components/VaultModal/assets/delete.svg'
import InputField from 'components/InputField';
import InputDebounceField from 'components/InputField/debounce';
import { DatePickerField } from 'components/DataPickerFormik'
import InputFieldFormik from 'components/InputFieldFormik';
import EditableLabel from 'components/EditableLabel';
import TextareaField from 'components/InputField/TextareaField';
import PsswordFieldProgress from 'components/VaultModal/components/Input/PasswordFieldProgress'
import { isMobile } from 'utils/mobile';

import styles from './style.module.scss'

type IProps = {
  isFirstWebLink: boolean,
  className: string,
  label: string,
  type: string,
  deleteField: (name: string) => void,
  name: string,
  onLabelChange: (label: string, id: string) => void,
  onWebLinkChange: (value: string) => void,
}

const VaultTitle = ({
  isFirstWebLink,
  className,
  label,
  type,
  deleteField,
  name,
  onLabelChange,
  onWebLinkChange,
}: IProps) => {

  const handleDeleteField = useCallback(() => {
    deleteField(name)
  }, [name])

  const handleLabelChange = useCallback((newLabel: string) => {
    onLabelChange(newLabel, name)
  }, [name])

  const inputRender = () => {
    switch(type) {
      case 'text':
        return (
          <InputFieldFormik
            className={styles.textInput}
            name={name}
          />
        );
      case 'website':
        return (
          <InputFieldFormik
            component={InputDebounceField}
            className={styles.textInput}
            name={name}
            debounceTimeout={500}
            handleChange={isFirstWebLink && onWebLinkChange}
            placeholder="https://"
          />
        );
      case 'notes':
        return (
          <InputFieldFormik
            component={TextareaField}
            name={name}
            rows={1}
            className={styles.areaInput}
            withValidation={false}
          />
        );
      case 'password':
        return (
          <PsswordFieldProgress
            className={styles.passwordInput}
            name={name}
          />
        )
      case 'date':
        return (
          <div className={styles.datePickerWrapper}>
            <DatePickerField
              name={name}
              portalId="vaultForm"
              wrapperClassName={styles.datePicker}
              className={styles.datePickerInput}
              popperClassName={styles.datePickerPopup}
              showPopperArrow={false}
              strictParsing
            />
          </div>
        )
      default:
        return (
          <InputField
            name={name}
            className={styles.textInput}
          />
        )
    }
  }

  return (
    <div className={CN(styles.wrapper, className)}>
      <EditableLabel
        className={styles.editableLabel}
        inputClass={styles.editableInput}
        labelClass={styles.labelClass}
        labelMaxLength={isMobile ? null : 11}
        initialValue={label}
        save={handleLabelChange}
      />
      { inputRender() }
      <button
        type="button"
        className={styles.deleteBtn}
        onClick={handleDeleteField}
      >
        <DeleteIcon />
      </button>
    </div>
  )
};

export default VaultTitle;