//Refactoring №3
import React from 'react';
import ReactModal from 'react-modal';

import CloseIcon from 'components/svg/close';

import CN from 'classnames';
import styles from './styles.module.css';

export const Modal = ({
  modalClassName,
  overlayClassName,
  closeBtnClassName,
  className,
  isOpen,
  onClose,
  children,
  ...restProps
}) => (
  <ReactModal
    isOpen={isOpen}
    onRequestClose={onClose}
    className={modalClassName || styles.modal}
    overlayClassName={overlayClassName || styles.overlay}
    {...restProps}
  >
    <section className={CN(styles.content, className)}>
      <button
        className={CN(styles.closeBtn, closeBtnClassName)}
        type="button"
        onClick={onClose}
      >
        <CloseIcon size={13} />
      </button>
      {children}
    </section>
  </ReactModal>
);
