export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
export const CHANGE_PROGRESS = 'CHANGE_PROGRESS';
export const FILTER_FILES = 'FILTER_FILES';
export const END_UPLOAD = 'END_UPLOAD';
export const ADD_SLUG = 'ADD_SLUG';
export const CHANGE_SECONDS_LEFT = 'CHANGE_SECONDS_LEFT';
export const RESET_UPLOADING_FILES = 'RESET_UPLOADING_FILES';
export const SET_DISPLAYED = 'SET_DISPLAYED';

export function uploadFile(file) {
  return {
    type: UPLOAD_FILE,
    payload: file,
  };
}

export function uploadFileSuccess(id) {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payload: id,
  };
}

export function uploadFileFailure(err, file_id) {
  return {
    type: UPLOAD_FILE_FAILURE,
    payload: { err, file_id },
  };
}
export function uploadChangeProgress(progressData) {
  return {
    type: CHANGE_PROGRESS,
    payload: progressData,
  };
}
export function uploadChangeSecondsLeft(secondsLeft) {
  return {
    type: CHANGE_SECONDS_LEFT,
    payload: secondsLeft,
  };
}
export function filterFiles(id) {
  return {
    type: FILTER_FILES,
    payload: id,
  };
}
export function endUpload() {
  return {
    type: END_UPLOAD,
  };
}

export function addSlug(uploadId, data) {
  return {
    type: ADD_SLUG,
    payload: { uploadId, ...data },
  };
}

export function resetUploadingFiles() {
  return {
    type: RESET_UPLOADING_FILES,
  };
}

export function setDisplayed(display) {
  return {
    type: SET_DISPLAYED,
    payload: display,
  };
}
