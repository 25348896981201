import React, { useEffect, useRef, useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import useClickOutside from 'utils/hooks/use-click-outside';

import { ReactComponent as ArrowBackIcon } from './assets/undo.svg';
import { ReactComponent as TrashIcon } from './assets/trash.svg';
import { ReactComponent as ColorIcon } from './assets/color.svg';

import style from './style.module.scss';

export const SettingsPanel = ({
  undo,
  clearCanvas,
  color,
  setColor,
  setBrushSize,
  brushSize,
}) => {
  const slider = document.getElementById('range');
  useEffect(() => {
    if (slider) {
      const min = slider.min;
      const max = slider.max;
      const value = slider.value;
      slider.style.background = `linear-gradient(to right, #fff 0%, #fff ${
        ((value - min) / (max - min)) * 100
      }%, var(--rangeInputBg) ${
        ((value - min) / (max - min)) * 100
      }%, var(--rangeInputBg)  100%)`;
      slider.oninput = function () {
        this.style.background = `linear-gradient(to right, #fff 0%, #fff ${
          ((this.value - this.min) / (this.max - this.min)) * 100
        }%, var(--rangeInputBg)  ${
          ((this.value - this.min) / (this.max - this.min)) * 100
        }%,var(--rangeInputBg)  100%)`;
      };
    }
  }, [slider]);

  const [isColorpickerOpen, setIsColorpickerOpen] = useState(false);
  const pickerRef = useRef(null);

  useClickOutside(pickerRef, () => setIsColorpickerOpen(false));

  const handleSizeChange = (e) => {
    setBrushSize(Number(e.target.value));
  };
  return (
    <div className={style.panelWrapper}>
      <div className={style.brushSize}>
        <span className={style.brushSize__label}>{brushSize}</span>
        <input
          type="range"
          id="range"
          min={1}
          max={100}
          onChange={handleSizeChange}
          value={brushSize}
        />
        <div
          className={style.colorPicker}
          onClick={() => setIsColorpickerOpen(true)}
          ref={pickerRef}
          style={{ color: color }}
        >
          {isColorpickerOpen && (
            <div>
              <HexColorPicker color={color} onChange={setColor} />
            </div>
          )}
          <ColorIcon />
        </div>
      </div>
      <button className={style.undo} onClick={undo}>
        <ArrowBackIcon />
      </button>
      <button className={style.reset} onClick={clearCanvas}>
        <TrashIcon />
      </button>
    </div>
  );
};
