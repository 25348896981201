import authRequest from 'utils/request/auth-request';
import { API_USERS } from 'constants/api-urls';

export const getUserSessionsEffect = async (page = 1) =>
  authRequest
    .get(`${API_USERS}/sessions?page=${page}`)
    .then((response) => {
      return response?.data;
    })
    .catch((req) => {
      throw req.response.data.errors;
    });

export const deleteUserSessionsEffect = async (uuid) =>
  authRequest
    .delete(`${API_USERS}/session/${uuid}`)
    .then((response) => {
      return response?.data;
    })
    .catch((req) => {
      throw req.response.data.errors;
    });
