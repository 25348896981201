// Refactoring №5
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonTheme } from 'components/Button';
import Tooltip from 'components/VaultModal/components/Footer/tooltip';

import styles from './style.module.scss';

type IProps = {
  onClose: () => void,
  isDisabled: boolean,
  loading: boolean,
}

const Footer = ({ onClose, isDisabled, loading }: IProps) => {
  const { t } = useTranslation('contextMenu');
  const [showTooltip, setShowTooltip] = useState(false);

  const onHover = useCallback(() => {
    setShowTooltip(true);
  }, []);

  const onLeave = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <div className={styles.wrapper}>
      <Button onClick={onClose} theme={ButtonTheme.NEW_BORDERRED_DARK}>
        {t('main.createFolder.cancel')}
      </Button>
      <Button
        type="submit"
        className={styles.submitBtn}
        disabled={isDisabled || loading}
        theme={ButtonTheme.NEW_BORDERRED_DARK}
      >
        <p onMouseOver={onHover} onMouseLeave={onLeave} onMouseOut={onLeave}>
          {t('main.imageToImage.save')}
        </p>
      </Button>
      {isDisabled && showTooltip && <Tooltip />}
    </div>
  );
};

export default Footer;
