import * as actions from '../actions'
import USER_ROLE_TYPES from 'config/user-role-types'
import { merge } from 'ramda'

const initialState = {
  entities: {
    email: '',
    phone: '',
    telegram: '',
    wechat: '',
    timezone: '',
    dateformat: '',
  },
  pending: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.EDIT_USER_INFO:
      return merge(state, {
        pending: true,
      })

    case actions.EDIT_USER_INFO_SUCCESS:
      return merge(state, {
        pending: false,
      })

    case actions.EDIT_USER_INFO_FAILURE:
      return merge(state, {
        pending: false,
      })

    case actions.CHANGE_USER_INFO:
      return {
        ...state,
        entities: {
          ...state.entities,
          ...action.payload,
          isGuest: action.payload.role === USER_ROLE_TYPES.GUEST,
        },
      }
    case actions.EDIT_USER_INFO_CHANGE_CONFIGURATION:
      return {
        ...state,
        entities: {
          ...state.entities,
          custom_expired_interval: action.payload.expiredInterval,
          custom_expired_timezone: action.payload.timezone,
        },
      }

    case actions.RESET_USER_INFO:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

// Selectors

export const selectUserInfo = (state) => state.account.userInfo.entities
export const selectUserPublicAddresses = (state) =>
  state.account.userInfo.entities?.user_public_addresses || []
