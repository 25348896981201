// Lib
import { path } from 'ramda';

// Utils

import * as actions from '../../actions/file/toggle-security-file.actions';
import * as getFilesActions from '../../actions/files/get-files.actions';
import * as selectedEntityActions from '../../actions/selected-entity.actions';
import * as pendingFilesFoldersActions from '../../actions/pending-files-folders.actions';
import { updateDocumentSuccess } from 'store/home/actions/documents.actions';
import { createSecurityReqString } from 'utils/request/create-security-req-string';
import authRequest from 'utils/request/auth-request';
import { API_GET_FILE } from 'constants/api-urls';

/**
 *
 * @param {Function} afterCb
 * @param {string} [securityData = '']
 * @param {object} additionalData
 * @returns {Function}
 */
export function toggleSecurityFileEffect(
  afterCb,
  securityData,
  additionalData
) {
  return async (dispatch) => {
    const { entity, securityId, isSecurityActive } = additionalData;
    dispatch(actions.toggleSecurityFile(entity.slug, securityId));
    dispatch(pendingFilesFoldersActions.pendingFilesAdd(entity));

    const url = `${API_GET_FILE}/${
      entity.slug
    }/securities/${securityId}${createSecurityReqString(securityData)}`;
    try {
      const method = isSecurityActive ? 'delete' : 'post';
      const result = await authRequest[method](url);
      const updateData = {
        entity: {
          ...path(['data'], result),
          folderId: entity.folderId,
        },
      };
      dispatch(updateDocumentSuccess(updateData.entity));
      dispatch(getFilesActions.getFilesUpdate(updateData));
      dispatch(selectedEntityActions.selectedEntityUpdate(result));
      dispatch(actions.toggleSecurityFileSuccess());
    } catch (e) {
      dispatch(actions.toggleSecurityFileFailure());
    }
    dispatch(pendingFilesFoldersActions.pendingFilesRemove(entity));
    afterCb && afterCb(entity);
  };
}
