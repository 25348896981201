//Refactoring №3
import React, { useRef, useEffect } from 'react';

import styles from './style.module.css';

export const Canvas = (props) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const w = window.innerWidth;
    const h = window.innerHeight;
    const particles = [];
    const properties = {
      bgColor: props.isLight ? '#F2F4F8' : 'rgba(17, 17, 19, 1)',
      particleColor: 'rgba(5, 221, 254, 1)',
      particleRadius: 3,
      particleCount: 50,
      particleMaxVelocity: 0.5,
      lineLength: 150,
      particleLife: 6,
    };
    let animationFrameId;

    //Our draw came here

    // window.onresize = function () {
    //   (w = canvas.width = innerWidth), (h = canvas.height = innerHeight);
    // };

    class Particle {
      constructor(x, y) {
        this.x = x || Math.random() * w;
        this.y = y || Math.random() * h;
        this.velocityX =
          Math.random() * (properties.particleMaxVelocity * 2) -
          properties.particleMaxVelocity;
        this.velocityY =
          Math.random() * (properties.particleMaxVelocity * 2) -
          properties.particleMaxVelocity;
      }
      position() {
        // eslint-disable-next-line no-unused-expressions
        (this.x + this.velocityX > w && this.velocityX > 0) ||
        (this.x + this.velocityX < 0 && this.velocityX < 0)
          ? (this.velocityX *= -1)
          : this.velocityX;
        // eslint-disable-next-line no-unused-expressions
        (this.y + this.velocityY > h && this.velocityY > 0) ||
        (this.y + this.velocityY < 0 && this.velocityY < 0)
          ? (this.velocityY *= -1)
          : this.velocityY;
        this.x += this.velocityX;
        this.y += this.velocityY;
      }
      reDraw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, properties.particleRadius, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fillStyle = properties.particleColor;
        ctx.fill();
      }
    }

    function reDrawBackground() {
      ctx.fillStyle = properties.bgColor;
      ctx.fillRect(0, 0, w, h);
    }

    function drawLines() {
      var x1, y1, x2, y2, length, opacity;
      for (var i in particles) {
        for (var j in particles) {
          x1 = particles[i].x;
          y1 = particles[i].y;
          x2 = particles[j].x;
          y2 = particles[j].y;
          length = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
          if (length < properties.lineLength) {
            opacity = 1 - length / properties.lineLength;
            ctx.lineWidth = '0.5';
            ctx.strokeStyle = `rgba(2, 92, 241, ${opacity})`;
            ctx.beginPath();
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);
            ctx.closePath();
            ctx.stroke();
          }
        }
      }
    }

    function reDrawParticles() {
      for (var i in particles) {
        particles[i].position();
        particles[i].reDraw();
      }
    }

    function loop() {
      reDrawBackground();
      reDrawParticles();
      drawLines();
      requestAnimationFrame(loop);
    }

    function addParticle(e) {
      if (particles.length < 150) {
        particles.push(new Particle(e.x, e.y));
      }
    }

    document.addEventListener('click', addParticle);
    function init() {
      for (var i = 0; i < properties.particleCount; i++) {
        particles.push(new Particle());
      }
      loop();
    }
    // init();
    animationFrameId = window.requestAnimationFrame(init);

    return () => {
      window.cancelAnimationFrame(animationFrameId);
      document.removeEventListener('click', addParticle);
    };
  }, []);
  return (
    <canvas
      className={styles.canvas}
      ref={canvasRef}
      width={window.innerWidth}
      height={window.innerHeight}
    />
  );
};
