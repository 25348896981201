import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const initialState = 80;

const ResentCodeButton = ({ onClick }) => {
  const [timer, setTimer] = useState(initialState);
  const { t } = useTranslation('auth');

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const handleClick = () => {
    if (timer === 0) {
      onClick();
      setTimer(initialState);
    }
  };

  return (
    <span
      onClick={handleClick}
      className={timer ? styles.resentCodeDisabled : styles.resentCode}
    >
      {timer
        ? t('emailLogin.resendInTimer', { timer })
        : t('emailLogin.resendCode')}
    </span>
  );
};

export default ResentCodeButton;
