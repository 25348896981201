// Libs
import { assoc } from 'ramda';

// Utils
import getData from 'utils/request/get-data'
import { updateSecurities } from 'utils/files-folders/update-securities'
import { updateProperties } from 'utils/object/updateProperties'

// Store
import * as actions from '../actions/selected-entity.actions'

export const initialState = {
  entity: null,
  accessAllow: false,
  entitiesAccess: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SELECTED_ENTITY_ADD:
      return {
        ...state,
        entity: action.payload.entity,
      }

    case actions.SELECTED_ENTITY_ALLOW_ACCESS:
      return assoc('accessAllow', true, state);

    case actions.SELECTED_ENTITY_UPDATE:
      return {
        ...state,
        entity: updateSecurities(getData(action).securities, state.entity),
      }

    case actions.SELECTED_ENTITY_UPDATE_BY_PROPS:
      return {
        ...state,
        entity: updateProperties(
          action.payload.entity,
          state.entity,
          action.payload.updatedPropsList
        ),
      }

    case actions.SELECTED_ENTITY_CLEAR:
      return {
        ...state,
        entity: null,
        accessAllow: false,
      }

    case actions.SELECTED_ENTITY_VIEW_CLEAR:
      return {
        ...state,
        entityView: null,
        entityViewPrev: null,
        entityViewNext: null,
      }

    case actions.SELECTED_ENTITY_VIEW:
      return {
        ...state,
        entityView: action.payload.entity,
        entityViewPrev: action.payload.prevEntity,
        entityViewNext: action.payload.nextEntity,
      }

    case actions.SELECTED_ENTITY_SHARE:
      return {
        ...state,
        entityShare: action.payload.entity,
      }

    case actions.SELECTED_ENTITY_SHARE_CLEAR:
      return {
        ...state,
        entityShare: null,
      }

    case actions.SELECTED_ENTITY_MOVE:
      return {
        ...state,
        entitiesMove: action.payload.entities,
      }

    case actions.SELECTED_ENTITY_MOVE_CLEAR:
      return {
        ...state,
        entitiesMove: null,
      }

    case actions.SELECTED_ENTITY_ACCESS_PREFERENCES:
      return {
        ...state,
        entitiesAccess: action.payload.entity,
      }

    case actions.SELECTED_ENTITY_ACCESS_PREFERENCES_CLEAR:
      return {
        ...state,
        entitiesAccess: null,
      }

    default:
      return state
  }
}
