/* eslint-disable no-undef */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
//Refactoring №3
import i18next from 'i18next';
// Config
import actionsOptions from 'config/actions-options';
import { entitiesTypes } from 'config/entities-types';
import ERROR_MESSAGES from 'config/errors/file-folder';

// Store
import * as selectedEntityActions from '../../actions/selected-entity.actions';
import * as pendingFilesFoldersActions from '../../actions/pending-files-folders.actions';
import * as selectedFilesFoldersActions from '../../actions/selected-files-folders.actions';
import * as actions from '../../actions/file/download-file.action';
import { setErrors } from 'store/errors/actions/index';
import { entitySecurityCheckResetEffect } from './entity-security-check-reset.effect';
import { getImageToImageGeneratorEffect } from 'store/home/effects/ai-generation/image-to-image';
import { getAccessPreference } from 'store/home/effects/activate-security/access-prefereence.effect';
import { getSecurityFilesEffect } from '../file/get-security-files.effect';
import deleteFileEffect from '../file/delete-file.effect';
import removeFileFromTrashEffect from '../file/remove-multi-file-from-trash.effect';
import deleteMultiFiles from 'store/home/effects/file/delete-multi-file';
import restoreFilesEffect from '../file/restore-files.effect';
import fileToggleGhostModeEffect from '../file/file-toggle-ghostmode.effect';
import downloadFileEffect from '../file/download-file.effect';
import { switchWorkspace } from 'store/workspace/effects';
import { toggleSecurityFileEffect } from '../toggle-security-file/toggle-security-file-effect';
import fileMoveEffect from '../file/file-multi-move.effect';
import { pendingFilesAddEffect } from '../pending-entity/pending-files-add.effect';
import { entitiesSecuritySetOption } from '../../actions/file/entities-security-check.actions';
import { removeFileFromSharedEffect } from '../file/share-file.effect';
import { getFilesUpdateFavoriteEffect } from 'store/home/effects/files/get-files.effect';
import * as getFilesAction from '../../actions/files/get-files.actions';
import { deleteMultisigEffect } from 'store/web3/multisig';
import {
  handleGeoModal,
  handleMoveModal,
  handleVaultModal,
  handlePreviewModal,
  handleSuccessModal,
  handleAdditionalData,
  handleSafetyNetModal,
  handleAnalyticsModal,
  handleSaftyAlerttModal,
  handleCreateFolderModal,
  handleMultisigShareModal,
} from 'features/modals/modal-slice';
import {
  selectIsMultisigActivated,
  updateSelectedFilePreview,
  FILE_PREVIEW,
} from 'features/app';
import { setAiFiles } from 'features/neyra';
import { startNeyraRead } from 'store/neyra/effects';
import { validationNeyraReader } from 'utils/validation/common';
import { addNotification } from 'store/main/actions';
import dispatchToEvery from 'utils/store/dispatch-to-every';
import { getTypeByEntityType } from 'utils/string';
import { getAiUpscallerGeneratorEffect } from '../ai-generation/ai-upscaller';
import RESPONSE_STATUS_CODE from 'config/response-status-code';
import { entityClearAllEffect } from './entity-clear-all.effect';
import { deleteQueue } from 'client-neyra';
/**
 *
 * @param {ISecurity[]} securityData
 * @returns {Function}
 */
export default (securityData, history, addData) =>
  async (dispatch, getState) => {
    const {
      edit,
      remove,
      goToSettings,
      goToSecuritySettings,
      goToComments,
      goToTokenization,
      download,
      aiGenerator,
      aiUpscaller,
      ghostMode,
      toggleSecurity,
      move,
      viewFile,
      manageShare,
      accessPreferences,
      removeFromTrash,
      removeAllFromTrash,
      restoreFromTrash,
      removeFromShared,
      removeShared,
      removeDocument,
      geoSecurity,
      guardians,
      showMove,
      goToFolder,
      analytics,
      openPassword,
      openLink,
      editPassword,
      comment,
      color,
      tag,
      myAI,
    } = actionsOptions;
    const state = getState();
    const isMultisigActivated = selectIsMultisigActivated(state);
    const entities = state.home?.entitiesSecurityCheck?.entities;
    const aiFiles = state?.neyra?.aiFiles;
    const actionOption = state.home?.entitiesSecurityCheck?.actionType;
    const workspace_securities =
      state.home?.entitiesSecurityCheck?.workspace_securities;
    const additionalData = state.home?.entitiesSecurityCheck?.addData;
    const allFiles = state.home?.getFiles?.entities;
    const allFilesTypeOne = allFiles.filter((entity) => entity.type === 1);
    let prevEntity = null;
    let nextEntity = null;
    let currentEntityIndex = -1;

    dispatch(entitySecurityCheckResetEffect());
    dispatch(pendingFilesAddEffect(entities));

    if (workspace_securities?.data?.length > 0 && securityData?.[0]?.value) {
      const {
        nextWorkspace,
        onClick,
        reload = true,
        switch_workspace = true,
      } = workspace_securities;
      if (switch_workspace)
        switchWorkspace(nextWorkspace.id).then((data) => {
          onClick && onClick();
          if (data.token && reload) {
            window.location.reload();
            window.location = '/main';
          }
        });
      else {
        onClick && onClick();
      }
    }

    if (
      actionOption.type === goToSettings.type ||
      actionOption.type === goToSecuritySettings.type ||
      actionOption.type === color.type ||
      actionOption.type === tag.type ||
      actionOption.type === comment.type ||
      actionOption.type === goToComments.type ||
      actionOption.type === goToTokenization.type
    ) {
      dispatch(
        selectedEntityActions.selectedEntityAdd({
          entity: entities[0],
        })
      );
      dispatch(entitiesSecuritySetOption(actionOption));
    }

    if (actionOption.type === manageShare.type) {
      dispatch(
        selectedEntityActions.selectedEntityShare({
          entity: entities[0],
        })
      );
    }

    const securedFiles = additionalData?.skipGetData
      ? entities
      : await dispatch(getSecurityFilesEffect(securityData, entities));

    if (securedFiles instanceof Error) {
      dispatch(entitySecurityCheckResetEffect());
      return;
    }

    if (securityData?.length > 0) {
      dispatch(
        getFilesAction.getFilesUpdate({
          entity: securedFiles[0],
          updatedPropsList: ['securities'],
        })
      );
    }

    const doAfterAction = (entity) => {
      dispatch(pendingFilesFoldersActions.pendingFilesRemove(entity));
      dispatch(selectedFilesFoldersActions.selectedFilesRemove(entity));
      if (actionOption.type === download.type) dispatch(actions.endDownload());
    };

    const aiFile = aiFiles.find((item) => item.uid === securedFiles[0].slug);
    switch (actionOption.type) {
      case openLink.type:
        history.push(`/link/${securedFiles[0].slug}`);
        break;

      case openPassword.type:
        dispatch(
          handleVaultModal({ isOpen: true, slug: securedFiles[0].slug })
        );
        break;

      case editPassword.type:
        dispatch(
          handleVaultModal({
            isOpen: true,
            slug: securedFiles[0].slug,
            isEdit: true,
          })
        );
        break;

      case edit.type:
        dispatch(handleAdditionalData(entities[0]));
        dispatch(handleCreateFolderModal(true));
        return;

      case remove.type:
        if (securedFiles.some((item) => !!item.trusted_members?.length)) {
          dispatch(
            handleSaftyAlerttModal({
              isOpen: true,
              data: {
                entities: securedFiles,
                data: additionalData,
                doAfterAction,
              },
            })
          );
          break;
        }

        if (isMultisigActivated && securedFiles.length === 1) {
          deleteMultisigEffect(securedFiles[0].slug).then(() => {
            dispatch(
              handleSuccessModal({
                isOpen: true,
                text: `The ${getTypeByEntityType(
                  securedFiles[0].type,
                  false
                )} will be deleted once all multi-signature users have signed the request.`,
              })
            );
          });
          break;
        } else {
          deleteMultiFiles(securedFiles, dispatch, {})
            .then(() => {
              const { showSuccessNotification } = additionalData || {};
              doAfterAction(securedFiles);
              dispatch(selectedEntityActions.selectedEntityClear());
              showSuccessNotification && showSuccessNotification();
            })
            .catch((error) => {
              const { code, message } = error.response.data || {};
              const { showErrorNotification } = additionalData || {};
              if (message === ERROR_MESSAGES.TOKENIZED_FILE) {
                window.mainContextDispatch(
                  addNotification({
                    id: new Date().getTime(),
                    message: i18next.t('SafetyNet.cannotDeleteTokenizeObject'),
                    type: 'warning',
                    hideIcon: false,
                    width: false,
                    merged: false,
                    duration: 5000,
                  })
                );
              } else if (
                message.startsWith(
                  i18next.t('SafetyNet.cannotDeleteTokenizeObjectClarification')
                )
              ) {
                showErrorNotification && showErrorNotification(message);
              }
              if (code === RESPONSE_STATUS_CODE.FORBIDDEN) {
                dispatch(
                  setErrors({
                    message,
                    code: RESPONSE_STATUS_CODE.ACCESS_DENIED,
                  })
                );
              }
            });
          break;
        }

      case removeShared.type:
        dispatchToEvery(dispatch, securedFiles, deleteFileEffect, [
          doAfterAction,
          securityData,
        ]);
        break;

      case removeDocument.type:
        deleteMultiFiles(securedFiles, dispatch, { isDocumentPage: true }).then(
          () => {
            doAfterAction(securedFiles);
          }
        );
        break;

      case goToSettings.type:
        dispatch(
          selectedEntityActions.selectedEntityAdd({
            entity: securedFiles[0],
          })
        );
        dispatch(
          pendingFilesFoldersActions.pendingFilesRemove(securedFiles[0])
        );
        doAfterAction();
        break;

      case goToSecuritySettings.type:
        dispatch(
          selectedEntityActions.selectedEntityAdd({
            entity: securedFiles[0],
          })
        );
        dispatch(
          pendingFilesFoldersActions.pendingFilesRemove(securedFiles[0])
        );
        doAfterAction();
        break;

      case goToComments.type:
        dispatch(
          selectedEntityActions.selectedEntityAdd({
            entity: securedFiles[0],
          })
        );
        dispatch(
          pendingFilesFoldersActions.pendingFilesRemove(securedFiles[0])
        );
        doAfterAction();
        break;

      case goToTokenization.type:
        dispatch(updateSelectedFilePreview(FILE_PREVIEW.tokenize));
        dispatch(
          selectedEntityActions.selectedEntityAdd({
            entity: securedFiles[0],
          })
        );
        dispatch(
          pendingFilesFoldersActions.pendingFilesRemove(securedFiles[0])
        );
        doAfterAction();
        break;
      case download.type:
        dispatch(
          actions.startDownload(
            getTotalDownloadFileSize(securedFiles),
            securedFiles[0].slug,
            securedFiles
          )
        );
        if (securedFiles[0].type === entitiesTypes.folder.id) {
          break;
        }
        dispatchToEvery(dispatch, securedFiles, downloadFileEffect, [
          doAfterAction,
          securityData,
          addData?.encryptionKey,
          dispatch,
          'download',
        ]);
        break;

      case ghostMode.type:
        dispatchToEvery(dispatch, securedFiles, fileToggleGhostModeEffect, [
          doAfterAction,
          securityData,
        ]);
        break;

      case toggleSecurity.type:
        dispatch(
          toggleSecurityFileEffect(doAfterAction, securityData, additionalData)
        );
        additionalData?.successCallback();
        break;

      case move.type:
        fileMoveEffect(dispatch, securedFiles, additionalData);
        break;

      case viewFile.type:
        if (['memo', 'neyra'].includes(securedFiles[0].extension)) {
          history.push(
            `/memo/${securedFiles[0].slug}?filename=${securedFiles[0]?.name}&filesize=${securedFiles[0]?.size}`
          );
          break;
        }

        currentEntityIndex = allFilesTypeOne.findIndex(
          (entity) => entity.slug === securedFiles[0].slug
        );
        if (currentEntityIndex === 0) {
          prevEntity = null;
          nextEntity =
            allFilesTypeOne.length > 1
              ? allFilesTypeOne[currentEntityIndex + 1]
              : null;
        } else if (currentEntityIndex + 1 === allFilesTypeOne.length) {
          prevEntity =
            allFilesTypeOne.length > 1
              ? allFilesTypeOne[currentEntityIndex - 1]
              : null;
          nextEntity = null;
        } else {
          prevEntity = allFilesTypeOne[currentEntityIndex - 1];
          nextEntity = allFilesTypeOne[currentEntityIndex + 1];
        }

        dispatch(
          handlePreviewModal({
            isOpen: true,
            entity: securedFiles[0],
          })
        );

        // dispatch(
        //   selectedEntityActions.selectedEntityView({
        //     entity: securedFiles[0],
        //     prevEntity,
        //     nextEntity,
        //   })
        // );
        break;

      case manageShare.type:
        dispatch(
          selectedEntityActions.selectedEntityShare({
            entity: securedFiles[0],
          })
        );
        dispatch(
          pendingFilesFoldersActions.pendingFilesRemove(securedFiles[0])
        );
        break;
      case aiGenerator.type:
        dispatch(getImageToImageGeneratorEffect(securedFiles[0]));
        break;
      case aiUpscaller.type:
        dispatch(getAiUpscallerGeneratorEffect(securedFiles[0]));
        break;

      case accessPreferences.type:
        if (isMultisigActivated) {
          dispatch(
            handleMultisigShareModal({ isOpen: true, entity: securedFiles[0] })
          );
        } else {
          dispatch(getAccessPreference(securedFiles[0].slug, securedFiles[0]));
        }
        break;

      case removeFromTrash.type:
        removeFileFromTrashEffect(dispatch, securedFiles);
        dispatch(selectedEntityActions.selectedEntityClear());
        break;

      case removeAllFromTrash.type:
        entityClearAllEffect(dispatch, securedFiles);
        dispatch(selectedEntityActions.selectedEntityClear());
        break;

      case restoreFromTrash.type:
        restoreFilesEffect(dispatch, securedFiles, additionalData);
        dispatch(selectedEntityActions.selectedEntityClear());
        break;

      case removeFromShared.type:
        dispatchToEvery(dispatch, securedFiles, removeFileFromSharedEffect, [
          doAfterAction,
          securityData,
        ]);
        break;

      case showMove.type:
        dispatch(handleMoveModal({ isOpen: true, entity: securedFiles }));
        // dispatch(selectEntityAllowAccessEffect());
        // dispatch(
        //   selectedEntityActions.selectedEntityMove({
        //     entities: securedFiles,
        //   })
        // );
        break;

      case analytics.type:
        dispatch(
          handleAnalyticsModal({
            status: true,
            slug: securedFiles[0].slug,
          })
        );
        break;

      case geoSecurity.type:
        dispatch(
          handleGeoModal({
            modal: true,
            entity: securedFiles[0],
          })
        );
        break;

      case guardians.type:
        dispatch(
          handleSafetyNetModal({
            modal: true,
            entity: securedFiles[0],
          })
        );
        break;

      case myAI.type:
        if (
          validationNeyraReader({
            entity: securedFiles[0],
            addNotification: additionalData.addNotification,
          })
        ) {
          if (aiFile) {
            if (!aiFile.ai) {
              try {
                await deleteQueue({ params: { uid: !aiFile.id } });
                getFilesUpdateFavoriteEffect(securedFiles[0], dispatch);
                setAiFiles(aiFile.map((item) => item.id === aiFile.id));
              } catch (error) {
                console.error(error);
              }
            }
          } else {
            startNeyraRead({
              entity: securedFiles[0],
              dispatch,
              addNotification: additionalData.addNotification,
            })
              .then(() => {
                getFilesUpdateFavoriteEffect(securedFiles[0], dispatch);
              })
              .catch((error) => {
                console.error(error);
              });
          }
        }
        break;

      case goToFolder.type:
        dispatch(
          pendingFilesFoldersActions.pendingFoldersRemove(securedFiles[0])
        );
        history.push(additionalData.url, {
          sharedPage: additionalData.sharedPage,
        });
        dispatch(getFilesAction.getFilesClear());
        dispatch(getFilesAction.getFilesSetSecurities(securityData));
        dispatch(getFilesAction.getFiles());
        break;

      default:
        break;
    }
  };

export const fileUpdateDataSelectedEntityEffect =
  (entityData) => async (dispatch) => {
    if (entityData) {
      dispatch(
        selectedEntityActions.selectedEntityAdd({
          entity: entityData,
        })
      );
      dispatch(pendingFilesFoldersActions.pendingFilesRemove(entityData));
      dispatch(pendingFilesFoldersActions.pendingFilesRemove());
      dispatch(selectedFilesFoldersActions.selectedFilesRemove());
    }
  };

export const getTotalDownloadFileSize = (files) => {
  const totalSize = files.reduce((accumulator, file) => {
    return accumulator + file.size;
  }, 0);

  return totalSize;
};
