// Utils
import authRequest from 'utils/request/auth-request';
import { createSecurityReqObj } from 'utils/request/create-security-req-obj/index';

// Store
import * as actions from '../../actions/file/delete-file.actions';
import * as getFilesActions from '../../actions/files/get-files.actions';
import * as ghostFilesActions from '../../actions/ghost-files.actions';
import { API_GET_FILE } from 'constants/api-urls';

/**
 *
 * @param {IFile} file
 * @param {Function} afterCb
 * @param {ISecurity[]} securitiesParams
 * @returns {Function}
 */
export default (file, afterCb, securitiesParams) => {
  return async (dispatch) => {
    dispatch(actions.fileDelete());

    const url = `${API_GET_FILE}/${file.slug}`;

    try {
      await authRequest.delete(url, {
        params: {
          ...createSecurityReqObj(securitiesParams),
        },
      });
      dispatch(actions.fileDeleteSuccess());
      dispatch(getFilesActions.getFilesRemove(file));
      file.ghost_mode && dispatch(ghostFilesActions.ghostFileRemove(file));
    } catch (e) {
      dispatch(actions.fileDeleteFailure(e));
    }
    afterCb(file);
  };
};
