/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import axios from 'axios';

import SidebarItem from 'containers/main/NeyraDriveContainer/components/SidebarItem';
import { ReactComponent as NeyraIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/neyra.svg';
import SidebarCheck from 'containers/auth/Terminal/TgAnalyzeSidebar/SidebarCkeck';
import AnalyzeBtn from 'containers/auth/Terminal/TgAnalyzeSidebar/AnalyzeBtn';
import AddTelegram from 'containers/auth/Terminal/TgAnalyzeSidebar/AddTelegram';
import {
  useTelegramClient,
  SESSION_KEY,
} from 'utils/hooks/use-telegram-client';
import {
  generateReport,
  getTitle,
} from 'containers/auth/Terminal/TgAnalyzeSidebar/utils';
import getMimeType from 'utils/files-folders/get-mime-type';
import { uploadMultiFileEffectRedux } from 'store/home/effects';
import useNotification from 'utils/hooks/use-notification';

import styles from './styles.module.scss';
import downloadFromBlob from 'utils/download-from-blob';
import { createMemoFile, createTxtFile } from 'utils/file/createFile';
import { store } from 'store/root-store';
import { sendMessage, putDataKnowledge } from 'store/neyra/effects';
import {
  setConversationUid,
  clearConversationHistory,
  setChats,
} from 'features/neyra';
import { errorMessages } from 'containers/auth/Terminal/TgAnalyzeSidebar/constants';
import {
  createConversation,
  createDataset,
  getAllConversations,
} from 'client-neyra';

const TgAnalyzeSidebar = () => {
  const { conversationHistory, conversationUid, avatar } = useSelector(
    (store) => store.neyra
  );
  const dispatch = useDispatch();
  const [tgState, setTgState] = useState({});
  const [selectedDialogs, setSelectedDialogs] = useState([]);
  const [selectedChats, setSelectedChats] = useState([]);
  const [loadingDialogs, setLoadingDialogs] = useState([]);
  const [startAnalyze, setStartAnalyze] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { addNotification } = useNotification();
  const {
    startConnection,
    getChatList: getTelegramChatList,
    getHistory,
    isConnected,
    getFullHistory,
  } = useTelegramClient();

  useEffect(() => {
    const init = async () => {
      try {
        const state = await getTelegramChatList();
        setTgState(state);
      } catch (error) {
        if (error?.message?.includes(errorMessages.unregistered)) {
          localStorage.removeItem(SESSION_KEY);
        } else {
          console.warn(error);
        }
      }
    };
    isConnected && init();
  }, [isConnected]);

  useEffect(() => {
    if (isConnected && isOpen) {
      closeConnectModal();
    }
  }, [isConnected, isOpen]);

  const updateChatList = () => {
    setTimeout(() => {
      getAllConversations({
        params: {
          avatarId: 1,
          themeId: 1,
          external: 0,
          offset: 0,
          limit: 1000,
        },
      }).then((res) => {
        dispatch(setChats(res.data));
      });
    }, [2000]);
  };

  const onChange = async (user, dialog) => {
    if (selectedDialogs.includes(dialog)) {
      setSelectedDialogs((list) => list.filter((item) => item !== dialog));
      setSelectedChats((list) => list.filter((item) => item !== user));
    } else {
      if (selectedDialogs.length === 0) {
        setSelectedDialogs((list) => [...list, dialog]);
        setSelectedChats((list) => [...list, user]);
      }
    }
  };

  const startAnalyzeDialog = async () => {
    try {
      if (!isEmpty(conversationHistory)) {
        const {
          data: { conversation_uid },
        } = await createConversation({
          body: { avatarId: isEmpty(avatar) ? 1 : avatar[0].id, themeId: 1 },
        });
        dispatch(setConversationUid(conversation_uid));
        dispatch(clearConversationHistory());
      }
      if (!isEmpty(selectedDialogs)) {
        setStartAnalyze(true);
        const [dialog] = selectedDialogs;
        const messages = await getHistory(dialog.peer, 250);
        const neyraReport = generateReport(messages);

        if (isEmpty(conversationHistory)) {
          sendMessage(
            null,
            conversationUid,
            neyraReport,
            null,
            null,
            null,
            null,
            null,
            1,
            { chat_mode: 'analyze_chat' }
          ).then(() => {
            const message = store.getState().neyra.conversationHistory[0].text;
            const name = getTitle(selectedChats[0]);
            if (!isEmpty(avatar)) {
              createDataset({
                body: { title: `${dialog.className} ${name}` },
              }).then((data) => {
                putDataKnowledge({
                  message,
                  title: `${dialog.className} ${name}`,
                  avatarId: avatar[0].id,
                  knowledgeId: data.data.id,
                });
              });
            }
          });
          setTimeout(() => {
            updateChatList();
            setStartAnalyze(false);
          }, 2000);
        } else {
          const {
            data: { conversation_uid },
          } = await createConversation({ body: { avatarId: 1, themeId: 1 } });
          dispatch(setConversationUid(conversation_uid));
          dispatch(clearConversationHistory());
          sendMessage(
            null,
            conversation_uid,
            neyraReport,
            null,
            null,
            null,
            null,
            null,
            1,
            { chat_mode: 'analyze_chat' }
          ).then(() => {
            const message = store.getState().neyra.conversationHistory[0].text;
            const name = getTitle(selectedChats[0]);
            if (!isEmpty(avatar)) {
              createDataset({
                body: { title: `${dialog.className} ${name}` },
              }).then((data) => {
                putDataKnowledge({
                  message,
                  title: `${dialog.className} ${name}`,
                  avatarId: avatar[0].id,
                  knowledgeId: data.data.id,
                });
              });
            }
          });
          setTimeout(() => {
            updateChatList();
            setStartAnalyze(false);
          }, 2000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeConnectModal = () => {
    setIsOpen(false);
  };

  const startConnectionHandler = (setMode) => {
    startConnection(setMode);
  };

  const analizeHandler = () => {
    if (!isConnected) {
      setIsOpen(true);
      // startConnection();
    }
  };

  const archiveChat = async (chat, entity) => {
    try {
      setLoadingDialogs((dialogs) => [...dialogs, chat]);
      const history = await getFullHistory(chat.peer);
      const neyraReport = generateReport(history);
      const name = `${getTitle(entity)} (chat history)`;
      const { file } = createMemoFile(neyraReport, name);
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const timestamp = Date.now();
      file.parent = null;
      file.folderId = null;
      file.uploadId = `${file.name}_${file.size}_${file.folderId}_${timestamp}`;
      file.source = source;
      file.mime = getMimeType(file);
      file.fileName = file.name;
      file.needEncryption = false;

      const { url } = createTxtFile(neyraReport, name);
      downloadFromBlob(url, name, 'txt');

      dispatch(
        uploadMultiFileEffectRedux({
          folderData: {},
          files: [file],
          folderId: null,
          setErrors: false,
          isGeneratedByAi: true,
          afterCb: () => {
            addNotification('Chat history was successfully saved', 'success');
          },
        })
      );
      setLoadingDialogs((list) => list.filter((item) => item !== chat));
    } catch (error) {
      addNotification(error.message, 'alert');
      setLoadingDialogs((list) => list.filter((item) => item !== chat));
      console.warn(error);
    }
  };

  return (
    <div className={styles.sidebarList}>
      <div className={styles.sidebarContent}>
        <SidebarItem
          item={{
            name: 'TG chat Analyzer',
            icon: <NeyraIcon />,
            action: () => {},
            isMain: true,
          }}
        />
        {!!tgState?.users?.length && <p className={styles.title}>Chats</p>}
        <div className={styles.chatList}>
          {tgState?.dialogs?.map((dialog, index) => {
            const id =
              dialog.peer.userId || dialog.peer.channelId || dialog.peer.chatId;
            return (
              <SidebarCheck
                key={`tg-chat-${id}`}
                chat={dialog}
                ckecked={selectedDialogs.some(
                  (selecteDialog) => selecteDialog === dialog
                )}
                id={id.value.toString()}
                onChange={onChange}
                tgState={tgState}
                archiveChat={archiveChat}
                loading={loadingDialogs.some(
                  (selecteDialog) => selecteDialog === dialog
                )}
              />
            );
          })}
        </div>
      </div>
      <AnalyzeBtn
        onClick={isConnected ? startAnalyzeDialog : analizeHandler}
        text={!isConnected && 'Connect'}
        startAnalyze={startAnalyze}
        setStartAnalyze={setStartAnalyze}
      />
      <AddTelegram
        isOpen={isOpen}
        onRequestClose={closeConnectModal}
        startConnect={startConnectionHandler}
      />
    </div>
  );
};

export default TgAnalyzeSidebar;
