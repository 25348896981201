import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import account_en from './locales/en/account.json';
import account_zh from './locales/zh/account.json';
import account_fil from './locales/fil/account.json';
import account_id from './locales/id/account.json';
import auth_en from './locales/en/auth.json';
import auth_zh from './locales/zh/auth.json';
import auth_fil from './locales/fil/auth.json';
import auth_id from './locales/id/auth.json';
import common_en from './locales/en/common.json';
import common_zh from './locales/zh/common.json';
import common_fil from './locales/fil/common.json';
import common_id from './locales/id/common.json';
import contextMenu_en from './locales/en/contextMenu.json';
import contextMenu_zh from './locales/zh/contextMenu.json';
import contextMenu_fil from './locales/fil/contextMenu.json';
import contextMenu_id from './locales/id/contextMenu.json';
import owner_en from './locales/en/owner.json';
import owner_zh from './locales/zh/owner.json';
import owner_fil from './locales/fil/owner.json';
import owner_id from './locales/id/owner.json';
import pages_en from './locales/en/pages.json';
import pages_zh from './locales/zh/pages.json';
import pages_fil from './locales/fil/pages.json';
import pages_id from './locales/id/pages.json';
import popup_en from './locales/en/popup.json';
import popup_zh from './locales/zh/popup.json';
import popup_fil from './locales/fil/popup.json';
import popup_id from './locales/id/popup.json';
import sidebar_en from './locales/en/sidebar.json';
import sidebar_zh from './locales/zh/sidebar.json';
import sidebar_fil from './locales/fil/sidebar.json';
import sidebar_id from './locales/id/sidebar.json';
import workspace_en from './locales/en/workspace.json';
import workspace_zh from './locales/zh/workspace.json';
import workspace_fil from './locales/fil/workspace.json';
import workspace_id from './locales/id/workspace.json';
import hint_en from './locales/en/hint.json';
import hint_zh from './locales/zh/hint.json';
import hint_fil from './locales/fil/hint.json';
import hint_id from './locales/id/hint.json';

const resources = {
  en: {
    account: account_en,
    auth: auth_en,
    common: common_en,
    contextMenu: contextMenu_en,
    owner: owner_en,
    pages: pages_en,
    popup: popup_en,
    sidebar: sidebar_en,
    workspace: workspace_en,
    hint: hint_en,
  },
  zh: {
    account: account_zh,
    auth: auth_zh,
    common: common_zh,
    contextMenu: contextMenu_zh,
    owner: owner_zh,
    pages: pages_zh,
    popup: popup_zh,
    sidebar: sidebar_zh,
    workspace: workspace_zh,
    hint: hint_zh,
  },
  fil: {
    account: account_fil,
    auth: auth_fil,
    common: common_fil,
    contextMenu: contextMenu_fil,
    owner: owner_fil,
    pages: pages_fil,
    popup: popup_fil,
    sidebar: sidebar_fil,
    workspace: workspace_fil,
    hint: hint_fil,
  },
  id: {
    account: account_id,
    auth: auth_id,
    common: common_id,
    contextMenu: contextMenu_id,
    owner: owner_id,
    pages: pages_id,
    popup: popup_id,
    sidebar: sidebar_id,
    workspace: workspace_id,
    hint: hint_id,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    detection: {
      order: ['navigator'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
