//Refactoring №2
import React, { useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Formik, Form } from 'formik';
import { string, object } from 'yup';

import InputFieldFormik from 'components/InputFieldFormik';
import Button, { ButtonTheme } from 'components/Button';
import WarningIcon from 'components/svg/warning';

import styles from './styles.module.scss';

const initialValues = {
  username: '',
  password: '',
};

function SignInForm({ onSubmit, loading, history }) {
  const { t } = useTranslation('auth');
  const form = useRef(null);

  const REQUIRED_ERR_MSG = useMemo(() => t('common.requiredField'), []);

  const validationSchema = useMemo(
    () =>
      object({
        username: string()
          .email(t('common.enterValidEmail'))
          .required(REQUIRED_ERR_MSG),
        password: string().required(REQUIRED_ERR_MSG),
      }),
    []
  );

  const submitHandler = (data) => {
    onSubmit(data, form);
  };

  const goBack = () => {
    history.goBack();
  };

  return (
    <Formik
      innerRef={form}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      <Form>
        <div className={styles.fields}>
          <InputFieldFormik
            className={styles.input}
            errorClassName={styles.inputError}
            labelClassName={styles.inputLabel}
            label={t('common.enterEmail')}
            errorIcon={<WarningIcon width={21} height={18} color="#FFB800" />}
            name="username"
            placeholder={t('common.email')}
            autoComplete="off"
            data-test="sign-in-container_email_field"
          />
          <InputFieldFormik
            className={styles.passwordInput}
            errorClassName={styles.inputError}
            labelClassName={styles.inputLabel}
            errorIcon={<WarningIcon width={21} height={18} color="#FFB800" />}
            name="password"
            label={t('common.enterPassword')}
            placeholder={t('signup.password')}
            autoComplete="password"
            type="password"
            isPassword
            data-test="sign-in-container_password_field"
            hidePasswordIcon
          />
          <div className={styles.forgot}>
            <a
              href="/forgot-password"
              className="auth-forgot"
              data-test="sign-in-container_forgot-password-link"
            >
              {t('common.forgotPassword')}
            </a>
          </div>
        </div>
        <div className={styles.actions}>
          <Button
            theme={ButtonTheme.TRANSPARENT}
            className={styles.backButton}
            onClick={goBack}
          >
            {t('common.back')}
          </Button>
          <Button
            type="submit"
            disabled={loading}
            theme={ButtonTheme.NEW_BORDERRED_DARK}
            className={styles.login}
            data-test="sign-in-container_log_email_button"
          >
            {t('common.login')}
          </Button>
        </div>
      </Form>
    </Formik>
  );
}

export default SignInForm;
