/* eslint-disable no-unused-vars */
//Refactoring №3
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Switches from './switches';
import Input from './input';
import Members from './members';
import InviteLink from './inviteLink';
import { getAccessPreferenceEffect } from 'store/home/effects/activate-security/access-prefereence.effect';
import { selectedEntityUpdateByProps } from 'store/home/actions/selected-entity.actions';
import { getFilesUpdate } from 'store/home/actions/files/get-files.actions';

import styles from './style.module.scss';

const SharedTab = ({
  entity,
  setInitialOption,
  tokenizationData,
}) => {
  const dispatch = useDispatch();
  const [initialData, setInitialData] = useState({ shares: [] });
  const [isInvitedOpen, setIsInvitedOpen] = useState(false);
  const invited = initialData.shares.filter((item) => item.type === 3) || [];
  const members = initialData.members;
  const invitedUsers = invited.map((item) => {
    const nextUser = {
      logo: '',
      color: '',
      displayed_name: '',
      public_address: '',
    };
    const user = item.share_right?.user || item.share_right?.invite;
    return {
      ...nextUser,
      ...user,
      isRegistered: !item.share_right?.invite,
      id: item.id,
    };
  });

  useEffect(() => {
    const initialize = async () => {
      try {
        const response = await getAccessPreferenceEffect(entity.slug);
        setInitialData(response);
      } catch (error) {
        console.error(error);
      }
    };
    entity.slug && initialize();
  }, [entity.slug]);

  const updateEntity = (shares) => {
    dispatch(
      selectedEntityUpdateByProps({
        entity: shares,
        updatedPropsList: ['shares'],
      })
    );
    dispatch(
      getFilesUpdate({
        entity: shares,
        updatedPropsList: ['shares'],
      })
    );
  };

  const updateInitialData = (updates) => {
    const shares = { ...initialData, ...updates };
    setInitialData(shares);
    updateEntity(shares);
  };

  return (
    <div className={styles.wrapper}>
      <div>
        <Switches
          slug={entity.slug}
          canDelete={initialData.can_delete}
          isPublic={initialData.is_public}
          isDownloaded={initialData.is_downloaded}
          isPrinted={initialData.is_printed}
          isSharedByToken={initialData.isSharedByToken}
          shares={initialData.shares}
          updateInitialData={updateInitialData}
          entryGroups={entity?.entry_groups}
          tokenizationData={tokenizationData}
          type={entity.type}
          entity={entity}
        />
        <Input
          slug={entity.slug}
          updateInitialData={updateInitialData}
          members={members}
          setIsInvitedOpen={setIsInvitedOpen}
          actionId={initialData.actionId}
          entityType={initialData.type}
        />
        <Members
          slug={entity.slug}
          updateInitialData={updateInitialData}
          members={members}
          invited={invitedUsers}
          isInvitedOpen={isInvitedOpen}
          setIsInvitedOpen={setIsInvitedOpen}
          entityType={initialData.type}
        />
      </div>
      <InviteLink
        entityType={entity.type}
        extension={entity.extension}
        slug={entity.slug}
        entryGroups={entity?.entry_groups}
        tokenizationData={tokenizationData}
        file={entity}
        // setActiveTab={setActiveTab}
        // setInitialOption={setInitialOption}
      />
    </div>
  );
};

export default SharedTab;
