//Refactoring №3
import React, { useState, useRef } from 'react';
import CN from 'classnames';
import { useHistory } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';
import { useTranslation } from 'react-i18next';

import { isEmpty } from 'lodash';
import { useClickAway } from 'ahooks';

import {
  getFiles,
  getFilesClear,
  getFilesSuccess,
} from 'store/home/actions/files/get-files.actions';
import {
  autoCompleteSearchEffect,
  searchResultEffect,
} from 'store/home/effects/files/search.effect';
import { enterKeyPress } from 'utils/actions/enter-key-press';

import SearchOption from './SearchOption';
import { Icon } from 'containers/header/header-container/components/ActionButtonsV2/Icon'

import style from './style.module.scss';

const AutocompleteSearch = ({
  dispatch,
  searchOn,
  setSearchOn,
}) => {
  const inputRef = useRef(null);
  const popupRef = useRef(null);
  const searchRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();
  const { t } = useTranslation('hint');

  useClickAway(({ path = [] }) => {
    const hidePopup = !path.find((node) => node === searchRef.current);
    if (hidePopup && visible) {
      setVisible(false);
      setSearchValue('');
    }
  }, searchRef.current);

  const cleanSearch = () => {
    setSearchValue('');
    setVisible(false);
    setOptions([]);
  };

  const onChange = ({ target: { value } }) => {
    setSearchValue(value);
    setVisible(true);
    if (value) {
      dispatch(autoCompleteSearchEffect(dispatch, value)
        .then((data) => {
          setOptions(data);
        })
        .catch(() => {
          cleanSearch();
        }));
    } else if (history.location.pathname !== '/main') {
      dispatch(getFilesClear());
      dispatch(getFiles());
      history.push('/main');
    }
    setOptions([]);
  };

  const onSearch = () => {
    if (searchValue) {
      setVisible(false);
      dispatch(getFilesClear());
      dispatch(getFiles());
      history.push('/search');
      dispatch(searchResultEffect(dispatch, searchValue)
        .then((data) => {
          dispatch(getFilesSuccess({ data }, true));
        })
        .catch(() => {
          // console.error(error)
        }));
    }
  };

  const onBlurHandler = () => {
    setTimeout(function () {
      setSearchOn(false);
    }, 300);
  };

  const handleKeyPress = (event) => {
    if (enterKeyPress(event) && searchValue) {
      onSearch();
    }
  };

  const onSearchBtnClick = () => {
    setSearchOn(!searchOn);
    if (!searchOn)
      inputRef.current.focus();
  };

  return (
    <div
      ref={searchRef}
      className={CN(style.actionSearch, searchOn && style.actionSearchActive)}
      data-test={`main-header_search_field_input`}
    >
      <div className={style.actionSearchContent}>
        <button
          className={style.searchBtn}
          type="button"
          data-test="main-header_search_field_input"
          title={t('wsSearch')}
          onClick={onSearchBtnClick}
        >
          <Icon type='search' />
        </button>
        <DebounceInput
          inputRef={inputRef}
          className={CN(style.field, searchOn && style.fieldActive)}
          onFocus={() => setSearchOn(true)}
          onBlur={onBlurHandler}
          debounceTimeout={500}
          onChange={onChange}
          onKeyPress={handleKeyPress}
          value={searchValue}
          maxLength="64"
        />
      </div>
      {!isEmpty(options) && visible && (
        <div ref={popupRef} className={style.search__options}>
          {options.map((option, key) => (
            <SearchOption {...option} key={key} onClick={cleanSearch} />
          ))}
        </div>
      )}
    </div>
  );
};

export default AutocompleteSearch;
