/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useRef, useState, useEffect } from "react";
import CN from 'classnames';

import { enterKeyPress } from 'utils/actions/enter-key-press';
import useClickOutside from 'utils/hooks/use-click-outside';

import styles from './styles.module.scss';

const SidebarInput = ({
  icon,
  name,
  placeholder,
  setInputValue,
  setValues,
  // valueForm,
  initialValue,
}) => {
  const [value, setValue] = useState('');
  const [isActive, setIsActive] = useState(false)
  const inputRef = useRef(null);
  useClickOutside(inputRef, () => {setIsActive(false)});

  useEffect(() => {
    setInputValue(value);
  }, [value])

  const doActive = () => {
    if (name !== 'image') {
      setIsActive(true)
      inputRef.current.focus()
    }
  }

  const onBlur = () => {
    setIsActive(false)
  }

  const onKeyDown = (e) => {
    if (enterKeyPress(e)) {
      setValues((values) => ({...values, [name]: value}));
      setInputValue('');
      setIsActive(false)
    }  
  }

  return (
    <div
      className={CN(
        styles.inputSidebarProgress,
        isActive && styles.inputSidebarActivet,
      )}
      onClick={doActive}
    >
      <div className={styles.inputSidebarIcon}>
        { icon }
      </div>
      <div className={CN(styles.inputSidebarProgressInput, isActive && styles.inputSidebarProgressInputActive)}>
        <input
          className={styles.inputSidebarProgressMyInput}
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          onBlur={onBlur}
          onChange={({target}) => {setValue(target.value)}}
          value={value}
          onKeyDown={onKeyDown}
          maxLength={name === 'description' ? 64 : undefined}
        />
        <p className={styles.inputSidebarInputText}>{ initialValue || value || placeholder }</p>
      </div>
    </div>
  )
}

export default SidebarInput;