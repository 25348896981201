import React from 'react';

const IconRec2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <circle cx="12" cy="12.5" r="11.5" stroke="var(--iconColor)" />
      <circle cx="12" cy="12.5" r="4" fill="#FD0000" />
    </svg>
  );
};

export default IconRec2;
