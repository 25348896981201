//Refactoring №3
import React from 'react';
import Select from 'react-select';
import cn from 'classnames';

import s from './style.module.scss';

const customStyles = {
  control: (styles) => {
    return {
      ...styles,
      height: 40,
      borderRadius: 0,
      borderColor: 'var(--inputBorder)',
      backgroundColor: 'var(--tooltipContent)',
      boxShadow: 'none',
      padding: '8px 15px 9px 15px',
      cursor: 'pointer',
      fontFamily: "'SF Pro Text', sans-serif",
      fontSize: '14px',
      lineHeight: '20px',
      '&:hover': {
        borderColor: 'var(--inputBorder)',
      },
    };
  },
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  singleValue: (styles) => ({
    ...styles,
    fontFamily: "'SF Pro Text', sans-serif",
    fontSize: '14px',
    lineHeight: '20px',
    color: 'var(--textColor)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles, state) => {
    return {
      ...styles,
      color: 'var(--iconColor)',
      padding: 0,
      transform:
        (state.selectProps.menuIsOpen && 'rotate(180deg)') || 'rotate(0deg)',
      '&:hover': {
        color: 'var(--iconColor)',
      },
    };
  },
  menuList: (styles) => ({
    ...styles,
    backgroundColor: 'var(--tooltipContent)',
    padding: 0,
  }),
  menu: (styles) => ({
    ...styles,
    margin: 0,
    borderRadius: 0,
    border: '1px solid var(--inputBorder)',
    borderTop: 'none',
    boxShadow: '0 4px 11px hsl(0deg 0% 0% / 10%)',
  }),
  option: (styles, state) => {
    return {
      ...styles,
      padding: '12px 16px',
      textAlign: 'start',
      fontFamily: "'SF Pro Text', sans-serif",
      fontSize: '14px',
      lineHeight: '20px',
      cursor: 'pointer',
      color: 'var(--textColor)',
      backgroundColor:
        (state.isSelected && 'var(--tooltipContent)') || 'var(--baseColor)',
      '&:hover': {
        backgroundColor: 'var(--tooltipContent)',
      },
    };
  },
  input: (styles) => ({
    ...styles,
    padding: 0,
    color: 'var(--textColor)',
  }),
};

export default function AppSelect({
  className,
  name,
  label,
  hideLabel,
  options,
  onChange,
  defaultValue,
  value,
  isSearchable,
  placeholder,
  isDisabled,
  newStyles,
  clearable,
  ...restProps
}) {
  return (
    <div className={cn(s.wrapper, className)}>
      {!hideLabel && (
        <label className={s.label} htmlFor={name}>
          {label}
        </label>
      )}
      <Select
        name={name}
        options={options}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        styles={{ ...customStyles, ...newStyles }}
        isSearchable={isSearchable}
        placeholder={placeholder}
        isDisabled={isDisabled}
        clearable={clearable}
        {...restProps}
      />
    </div>
  );
}
