import React, { useContext } from 'react';
import CN from 'classnames';
import { useHistory } from 'react-router';
import { capitalize } from 'lodash';
import { ReactComponent as BackIcon } from '../../assets/Back.svg';

import style from './style.module.scss';

import UserContext from 'store/home/contexts/user-context';

type IProps = {
  collapsed: boolean;
  isMobile: boolean;
  toggleSplitPanel: () => void;
};

const Back = ({ collapsed, isMobile, toggleSplitPanel }: IProps) => {
  const history = useHistory();
  const { user: me } = useContext(UserContext);
  const credits = me?.point;

  const goToAccountStorage = () => {
    history.push('/account/credits');
  };

  return (
    <div className={CN(style.wrapper, collapsed && style.collapsed)}>
      <div className={style.bank} onClick={goToAccountStorage}>
        <p className={style.bankTitle}>{capitalize('Credits')}</p>
        <span className={style.bankValue}>
          {(credits || 0).toLocaleString()}
        </span>
      </div>
      <div className={style.action}>
        {!isMobile && (
          <button
            className={CN(
              style.titleBack,
              collapsed && style.titleBackCollapsed
            )}
            onClick={toggleSplitPanel}
          >
            <BackIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default Back;
