// utils
import authRequest from 'utils/request/auth-request';
import { API_GET_FILE, API_TAGS } from 'constants/api-urls';

// store

export const tagsGetEffect = async () => {
  return authRequest
    .get(API_TAGS)
    .then((response) => {
      return response.data;
    })
    .catch((response) => {
      return response.message;
    });
};

export const tagAddEffect = async (itemId, tags) => {
  const url = `${API_GET_FILE}/${itemId}/tags`;
  const tagsText = tags.join(',');

  return authRequest
    .post(url, {
      tagsText,
    })
    .then((response) => {
      return response.data?.data;
    })
    .catch((response) => {
      return response.message;
    });
};
