export const showMetamaskMobileOption = () => {
  const metaMaskSDKContainer = document.body.querySelector('.css-1h80o1p');
  const divs = metaMaskSDKContainer?.getElementsByTagName('div');

  if (!metaMaskSDKContainer) return;

  for (let i = 0; i < divs.length; i++) {
    if (divs[i].innerText === 'Mobile') {
      divs[i].click();
      divs[i - 1].style.display = 'none';
      return;
    }
  }
};
