// libs
import { combineReducers } from 'redux'

// reducers
import getAllTariffs from './get-all-tariffs.reducer'
import getUserTariff from './get-user-tariff.reducer'
import workspacePlan from './workspace-plan.reducer'

export const planReducer = combineReducers({
  getUserTariff,
  getAllTariffs,
  workspacePlan,
})
