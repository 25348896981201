export const SELECTED_FILES_ADD = 'SELECTED_FILES_ADD'
export const SELECTED_FILES_REMOVE = 'SELECTED_FILES_REMOVE'
export const SELECTED_FILES_FOLDERS_CLEAR = 'SELECTED_FILES_FOLDERS_CLEAR'
export const SHOW_FILE = 'SHOW_FILE'
export const SHOW_FILE_CLEAR = 'SHOW_FILE_CLEAR'

export function selectedFilesAdd(data) {
  return {
    type: SELECTED_FILES_ADD,
    payload: data,
  }
}

export function selectedFilesRemove(entities) {
  return {
    type: SELECTED_FILES_REMOVE,
    payload: entities,
  }
}

export function selectedFilesFoldersClear() {
  return {
    type: SELECTED_FILES_FOLDERS_CLEAR,
  }
}

export function showFile(data) {
  return {
    type: SHOW_FILE,
    payload: data,
  }
}

export function showFileClear() {
  return {
    type: SHOW_FILE_CLEAR,
  }
}
