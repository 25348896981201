import React from 'react'
import cn from 'classnames'

import './drag-layer.scss'
import FolderIconDrag from 'components/svg/folder-icon-drag'
import FileIconDrag from 'components/svg/file-icon-drag'
import PictureIconDrag from 'components/svg/picture-icon-drag'
import { imageMediaTypes } from 'config/image-file-extensions'

const DragLayer = ({ item, count = 0 }) => {
  const color = item?.color?.[item.color.length - 1]
  const colorHex = color?.hex

  const layers =
    count > 1 ? (
      <>
        <div className="drag-layer drag-layer-b" />
        <div className="drag-layer drag-layer-a" />
      </>
    ) : null
  const mainLayer = (
    <div className={cn('drag-layer', count > 1  && 'drag-layer-multi')}>
      {count > 1 ? <div className="drag-layer-count">{count}</div> : null}
      {item.type === 1 ? (
        imageMediaTypes.includes(item.mime) ? (
          <PictureIconDrag
            color={colorHex}
            className="drag-layer-icon drag-layer-icon-picture"
            showExtention={false}
          />
        ) : (
          <FileIconDrag
            color={colorHex}
            className="drag-layer-icon drag-layer-icon-file"
            showExtention={false}
          />
        )
      ) : (
        <FolderIconDrag
          color={colorHex}
          className="drag-layer-icon drag-layer-icon-folder"
        />
      )}
      <div className="drag-layer-name">{item.name}</div>
    </div>
  )

  return (
    <>
      {layers}
      {mainLayer}
    </>
  )
}

export default DragLayer
