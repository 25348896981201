import React, { memo, useEffect, useState } from 'react';
import CN from 'classnames';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';

import { getNeyraBios } from 'store/neyra/effects';
import { createBios, createConversation, getLocation } from 'client-neyra';
import {
  setBios,
  createMessage,
  setConversationUid,
  setBiosMessage,
  setChatModifiers,
} from 'features/neyra';

import { ReactComponent as ArrowIcon } from '../../assets/arrow-back.svg';
import { ReactComponent as RingIcon } from '../../assets/ring.svg';
import { ReactComponent as RecordIcon } from '../../assets/mainSideBar/record.svg';
import TalentList from 'containers/auth/NeyraChat/components/BiosSidebar/TalentList';
import SidebatButton from 'containers/account/Space/SpaceSidebar/Components/SidebatButton';
import Input from 'containers/mobile/pages/Connect/input';

import boxStyles from '../../styles.module.scss';
import styles from './styles.module.scss';

const newStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'transparent',
    width: '100%',
    border: 'none',
    height: '62px',
    boxShadow: 'none',
  }),
  menuList: () => ({
    padding: '0',
    maxHeight: '120px',
    overflowY: 'auto',
  }),
};

const BiosSidebar = () => {
  const [values, setValues] = useState({ date: '', time: '00:00', city: '' });
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { bios } = useSelector((store) => store?.neyra);
  const { t } = useTranslation('owner');
  const dispatch = useDispatch();
  const isBiosActivated = !isEmpty(bios);

  useEffect(async () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    try {
      const bios = await getNeyraBios();
      if (bios) {
        setBios(bios);
      } else {
        dispatch(
          createMessage({
            id: uuid(),
            text: 'Welcome to Neyra Genetics Graph, where you can unlock a deeper understanding of your abilities and talents. Our platform helps you visualize and represent your unique genetic makeup, providing valuable insights and challenges to enhance your personal development. Embrace this new approach to human design and connect more meaningfully with your true potential. Dive into Neyra Genetics Graph and discover a new way to understand and optimize yourself.',
            voice: false,
            timeStamp: new Date(),
            initialMessage: true,
            isNeyro: true,
          })
        );
      }
    } catch (error) {
      console.warn(error);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setChatModifiers({}));
    };
  }, []);

  useEffect(async () => {
    if (values.city) {
      try {
        const data = await getLocation({ params: { q: values.city } });
        const list = [];
        data.data.forEach((item) => {
          list.push({
            label: `${item.city} / ${item.land} / ${item.sdiv} / ${item.tz}`,
            value: `${item.city} / ${item.land} / ${item.sdiv} / ${item.tz}`,
          });
        });

        setOptions(list);
      } catch (error) {
        console.warn(error);
      }
    }
  }, [values.city]);

  const onSelectShape = async (title, desc) => {
    const {
      data: { conversation_uid },
    } = await createConversation({ body: { avatarId: 1, themeId: 1 } });

    dispatch(setConversationUid(conversation_uid));
    dispatch(
      createMessage({
        id: uuid(),
        text: `${title} \n\n ${desc}`,
        voice: false,
        timeStamp: new Date(),
        isNeyro: true,
      })
    );
    dispatch(setBiosMessage(desc));
  };

  const goToHistory = () => {
    history.push('/ring');
  };

  const goBack = () => {
    history.goBack();
  };

  const onDateChange = ({ target: { value } }) => {
    const formattedDate = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .slice(0, 10);
    setValues((values) => ({ ...values, date: formattedDate }));
  };

  const onCityChangeInput = (value) => {
    value && setValues((values) => ({ ...values, city: value }));
  };

  const onCityChange = (data) => {
    setValues((values) => ({ ...values, city: data.value }));
  };

  const onTimeChange = ({ target: { value } }) => {
    setValues((values) => ({ ...values, time: value }));
  };

  const onActivateBios = async () => {
    try {
      const [day, month, year] = values.date.split('.');
      const [city, , , timeZone] = values.city.split(' / ');
      await createBios({
        body: {
          gender: 'M',
          place_of_birth: city,
          date_of_birth: `${[year, month, day].join('-')} ${values.time}`,
          place_of_birth_timezone: timeZone,
        },
      });
      const bios = await getNeyraBios();
      dispatch(setBios(bios));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={boxStyles.sideBarBlocks}>
      <div className={CN(boxStyles.sideBarBlock, styles.mainBlock)}>
        <button className={boxStyles.mainSideBarButton}>
          <RecordIcon />
        </button>
        <div className={boxStyles.mainSideBarTitle}>{t('chat.bios')}</div>
        <button className={styles.mainSideBarButtonBack} onClick={goBack}>
          <ArrowIcon />
        </button>
      </div>
      <div onClick={goToHistory} className={boxStyles.sideBarBlock}>
        <button className={boxStyles.mainSideBarButton}>
          <RingIcon />
        </button>
        <div className={boxStyles.mainSideBarTitle}>Ring</div>
      </div>
      {!isBiosActivated && !loading && (
        <div className={styles.activateBiosForm}>
          <Input
            className={styles.myInput}
            placeholder="Date of Birth"
            value={values.date}
            onChange={onDateChange}
          />
          <Input
            className={styles.myInput}
            placeholder="City"
            inputType="select"
            onInputChange={onCityChangeInput}
            onChange={onCityChange}
            options={options}
            filterOption={() => true}
            newStyles={newStyles}
          />
          <Input
            className={styles.myInput}
            inputType="time"
            placeholder="00:00"
            onChange={onTimeChange}
            value={values.time || undefined}
            defaultValue={undefined}
          />
          <SidebatButton
            onClick={onActivateBios}
            align="center"
            disabled={!values.date || !values.time || !values.city}
          >
            Activate
          </SidebatButton>
        </div>
      )}
      {!!bios?.spheres?.length && <p className={styles.titleSep}>Skills</p>}
      <TalentList bios={bios} onSelectShape={onSelectShape} />
    </div>
  );
};

export default memo(BiosSidebar);
