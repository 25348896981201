/* eslint-disable no-unused-vars */
import React from "react";

import ColorComponent from 'containers/main/EntitySettingsContainer/components/InfoTab/MetaTab/colors';

import styles from './styles.module.scss';

const ColorWidget = ({ selectedEntity }) => {

  return (
    <div className={styles.meta}>
      <ColorComponent selectedEntity={selectedEntity} />
    </div>
  )
}

export default ColorWidget;