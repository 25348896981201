//Refactoring №3
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { isObject } from 'lodash';

import { selectMultisigContract } from 'store/plan/reducers/workspace-plan.reducer';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';
import { networkSwitcher } from 'store/web3';

import { handleDeleteModal } from 'features/modals/modal-slice';
import { selectIsMultisigActivated } from 'features/app';
import { getTypeByEntityType } from 'utils/string';
import actionsOptions from 'config/actions-options';
import { IFile } from 'interfaces/file-folder';

import { Modal as ModalWindow } from 'components/Modal';
import Button, { ButtonTheme } from 'components/Button';

import { ReactComponent as AlertIcon } from 'static/assets/svg/alert.svg';

import styles from './styles.module.scss';

type IProps = {
  entity: IFile;
  handleClose: () => void;
  handleDelete: () => void;
};

const DeleteModal = ({ entity, handleClose, handleDelete }: IProps) => {
  const { t } = useTranslation('owner');
  const [entityState, setEntityState] = useState({});
  const isMultisigActivated = useSelector(selectIsMultisigActivated);
  const multisigContract = useSelector(selectMultisigContract);
  const dispatch = useDispatch();

  const entityType = getTypeByEntityType(entityState?.type);

  useEffect(() => {
    if (entity && isObject(entity)) {
      setEntityState(entity);
    }
  }, [entity]);

  const onDelete = () => {
    dispatch(fileFolderActionHandlerEffect(entityState, actionsOptions.remove));
    dispatch(handleDeleteModal({ isOpen: false, entity: null }));
    handleDelete && handleDelete();
    handleClose && handleClose();
  };

  const onSuccess = () => {
    if (isMultisigActivated) {
      networkSwitcher(multisigContract.network.chain_id).then(() => {
        onDelete();
      });
    } else {
      onDelete();
    }
  };

  const onClose = () => {
    dispatch(handleDeleteModal({ isOpen: false, entity: null }));
    handleClose && handleClose();
  };

  return (
    <ModalWindow isOpen modalClassName={styles.modal} onClose={onClose}>
      <div className={styles.content}>
        {isMultisigActivated && (
          <h3 className={styles.subTitle}>
            <AlertIcon /> {t('deleted.irreversibleAction')}
          </h3>
        )}
        <h1 className={styles.title}>
          {`Confirm deletion of the ${ entityType }?`}
        </h1>
        <div className={styles.buttonGroup}>
          <Button onClick={onClose} theme={ButtonTheme.BORDERRED_DARK}>
            {t('common.cancel')}
          </Button>
          <Button theme={ButtonTheme.DARK} onClick={onSuccess}>
            {t('common.delete')}
          </Button>
        </div>
      </div>
    </ModalWindow>
  );
};

export default DeleteModal;
