import React from 'react';

const EncryptedIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 18.146L4.4585 13.979C4.36117 13.9097 4.28817 13.8195 4.2395 13.7085C4.19084 13.5972 4.1665 13.479 4.1665 13.354V3.70853C4.1665 3.3752 4.28467 3.09036 4.521 2.85403C4.757 2.61803 5.04167 2.50003 5.375 2.50003H14.625C14.9583 2.50003 15.243 2.61803 15.479 2.85403C15.7153 3.09036 15.8335 3.3752 15.8335 3.70853V13.354C15.8335 13.479 15.8092 13.5972 15.7605 13.7085C15.7118 13.8195 15.6388 13.9097 15.5415 13.979L10 18.146ZM10 17.229L14.9165 13.521C14.9862 13.4794 15.0383 13.4204 15.073 13.344C15.1077 13.2674 15.125 13.1874 15.125 13.104V3.70853C15.125 3.58353 15.073 3.46886 14.969 3.36453C14.8647 3.26053 14.75 3.20853 14.625 3.20853H5.375C5.25 3.20853 5.13534 3.26053 5.031 3.36453C4.927 3.46886 4.875 3.58353 4.875 3.70853V13.104C4.875 13.1874 4.89234 13.2674 4.927 13.344C4.96167 13.4204 5.01384 13.4794 5.0835 13.521L10 17.229ZM9.125 11.9165L13.25 7.79153L12.75 7.29153L9.125 10.9165L7.229 9.04153L6.75 9.54153L9.125 11.9165ZM10 3.20853H4.875H15.125H14.9165H10Z"
      fill={color}
    />
  </svg>
);

EncryptedIcon.defaultProps = {
  color: 'currentColor',
};

export default EncryptedIcon;
