//Refactoring №5
import React, { useState, useCallback } from 'react';

import CopyButton from 'components/Button/copy-button';
import EyeIcon from 'components/svg/eye';

import styles from './style.module.scss'

import { trimLongText } from 'utils';
import { copyToClipboard } from 'utils/string';

type IProps = {
  value: string,
  maxtTextLength: number,
  label: string
}

const PasswordText = ({ value = '', maxtTextLength, label }: IProps) => {
  const [show, setShow] = useState(false)

  const copyPassword = useCallback(() => {
    copyToClipboard(value)
  }, [value])

  const togglePasswordPreview = useCallback(() => {
    setShow(!show)
  }, [show])

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <div className={styles.filedPreviewValue}>
        <p className={styles.password}>
          {trimLongText(show ? value : value.replace(/./g, '*'), maxtTextLength - 13, '')}
        </p>
        { value && (
          <>
            <CopyButton onClick={copyPassword} className={styles.copyBtn} />
            <button
              className={styles.showBtn}
              onClick={togglePasswordPreview}
            >
              <EyeIcon />
            </button>
          </>
        )}
      </div>
    </div>
  )
};

export default PasswordText;
