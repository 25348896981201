/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Route, Redirect, Switch, useHistory } from 'react-router-dom';

// import Connect from './pages/Connect';
import Chant from './pages/Chat';
import Profile from './pages/Profile';
import Start from './pages/Start';
import BuyToken from './pages/BuyToken';
import Drive from './pages/Drive';
import NeyraRegistration from 'containers/auth/NeyraRegistration';
import WelcomePage from 'containers/auth/WelcomePage';

import getToken from 'utils/auth/get-token';

import { getUserInfoClear } from 'store/home/effects/user-info/get-user-info-effect';
import { getNeyraAvatar } from 'store/neyra/effects';
// import SharedByLinkFlow from 'containers/shared-by-link';

import styles from './styles.module.scss';

export const flow = {
  start: {
    name: 'start',
    isAuth: false,
  },
  connect: {
    name: 'connect',
    isAuth: false,
  },
  chat: {
    name: 'chat',
    isAuth: true,
  },
  profile: {
    name: 'profile',
    isAuth: true,
  },
  buyToken: {
    name: 'buy-token',
    isAuth: true,
  },
};

const Container = () => {
  const history = useHistory();
  const [avatar, setAvatar] = useState(null);
  const token = getToken();

  useEffect(async () => {
    if (
      window.location.pathname !== '/registration' &&
      !window.location.pathname.startsWith('/file')
    ) {
      if (token) {
        const user = await getUserInfoClear();
        const avatar = await getNeyraAvatar();
        const avatarItem = avatar.length
          ? { ...avatar[0].profile, tokens: user.tokens }
          : { tokens: user.tokens };
        setAvatar(avatarItem);
        history.push('chat');
      } else {
        history.push('/');
      }
    }
  }, []);

  // const pageRender = () => {
  //   switch (state) {
  //     case flow.start.name:
  //       return (
  //         <Start
  //           changeStep={setState}
  //           setUser={setState}
  //         />
  //       );

  //     case flow.connect.name:
  //       return (
  //         <Connect
  //           changeStep={setState}
  //           setUser={setState}
  //         />
  //       );

  //     case flow.chat.name:
  //       return (
  //         <Chant token={token} changeStep={setState} />
  //       );

  //     case flow.profile.name:
  //       return (
  //         <Profile
  //           user={user}
  //           token={token}
  //           changeStep={setState}
  //           setUser={setUser}
  //         />
  //       );

  //     case flow.buyToken.name:
  //       return (
  //         <BuyToken token={token} changeStep={setState} />
  //       );

  //     default:
  //       return ''
  //   }
  // }

  return (
    <div className={styles.wrapper}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Start setAvatar={setAvatar} avatar={avatar} />}
        />
        {/* <Route
          exact
          path="/connect"
          render={() => <Connect setAvatar={setAvatar} />}
        /> */}
        <Route exact path="/chat" render={() => <Chant token={token} />} />
        <Route
          exact
          path="/profile"
          render={() => <Profile avatar={avatar} setAvatar={setAvatar} />}
        />
        <Route exact path="/account/buy-credits" render={() => <BuyToken />} />
        <Route exact path="/drive" render={() => <Drive />} />
        <Route exact path="/folder/:folderId" render={() => <Drive />} />
        <Route exact path="/registration" component={NeyraRegistration} />
        <Route exact path="/welcome" component={WelcomePage} />
        {/* <Route exact path="/file/:fileId" component={SharedByLinkFlow} /> */}
        <Redirect to="/" />
      </Switch>
    </div>
  );
};

export default Container;
