/* eslint-disable no-unused-vars */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TriangleIcon } from 'containers/main/NeyraDriveContainer/components/assets/triangle.svg';
import { ReactComponent as RemixWidgetIcon } from 'containers/main/NeyraDriveContainer/components/assets/remix.svg';

import CN from 'classnames';
import styles from './styles.module.scss';

const isProfit = true;

const RemixWidget = () => {
  const { t } = useTranslation('owner');

  return (
    <div className={styles.value}>
      <div className={styles.valueHeader}>
        <div className={styles.valueLogo}>
          <RemixWidgetIcon />
          <h1 className={styles.valueHeaderText}>
            {t('rightFileMenu.meta.remixes')}
          </h1>
        </div>
        <div
          className={CN(
            styles.valueChange,
            isProfit ? styles.valueChangeProfit : styles.valueChangeLosses
          )}
        >
          <TriangleIcon />
          <span>0.0%</span>
        </div>
      </div>
      <div className={styles.valueContent}>
        <div className={styles.valueContentText}>0</div>
        <div className={styles.valueContentGraphic}>
        {/* <div className={styles.grafChart} /> */}
        </div>
      </div>
      <p className={styles.valueContentFooter}>
        {t('rightFileMenu.meta.remixesDescription')}
      </p>
    </div>
  );
};

export default RemixWidget;
