import React, { useCallback, useContext, useState, useRef } from 'react';
import { HexColorInput, RgbColorPicker } from 'react-colorful';
import { useTranslation } from 'react-i18next';
import Button, { ButtonTheme } from 'components/Button';
import cn from 'classnames';
import { ReactComponent as CancelIcon } from 'static/assets/img/icons/action/cancel-invite.svg';
import authRequest from 'utils/request/auth-request';
import useClickOutside from 'utils/hooks/use-click-outside';
import useColorConverter from 'utils/color-converter';
import ColorContext from 'store/colors/contexts/colors';

const rgbList = ['R', 'G', 'B'];

const ColorPicker = ({
  isColorPickerOpen,
  color,
  onChange,
  handleAddNewColor,
  hexColor,
  onHexChange,
  handleChangeRGB,
  yourColors,
  activeColor,
  isFolder,
  onClose,
}) => {
  const ref = useRef(null)
  const { t } = useTranslation('owner');
  const { updateColors } = useContext(ColorContext);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [selectedColorId, setSelectedColorId] = useState(activeColor?.id);
  const { hexToRGB } = useColorConverter();

  useClickOutside(ref, () => {onClose()});

  const handleSelectNewColor = useCallback(
    (e) => {
      const id = e.target.name;
      const [color] = yourColors.filter((c) => c.id.toString() === id);
      const rgbColor = hexToRGB(color?.hex);
      onChange(rgbColor);
      setSelectedColorId(color?.id);
    },
    [onChange, yourColors]
  );

  const handleRemoveColor = useCallback(async () => {
    try {
      const [color] = yourColors.filter((c) => c.id === selectedColorId);
      if (!color.name) {
        const {
          data: { data },
        } = await authRequest.delete(
          `${process.env.REACT_APP_API_PATH}/color/${selectedColorId}`
        );
        updateColors(data);
      }
      setIsContextMenuOpen(false);
    } catch {
      setIsContextMenuOpen(false);
    }
    setSelectedColorId(null);
  }, [selectedColorId, yourColors]);

  const handleOpenContextMenu = useCallback(
    (e) => {
      e.preventDefault();
      const id = e.target.name;
      const [color] = yourColors.filter((c) => c.id.toString() === id);
      setSelectedColorId(color?.id);
      setIsContextMenuOpen(!isContextMenuOpen);
    },
    [yourColors, isContextMenuOpen]
  );

  return (
    isColorPickerOpen && (
      <div
        ref={ref}
        style={isFolder ? { bottom: 20 } : null}
        className={cn(
          yourColors.length
            ? 'entity-color-picker_container_with_colors'
            : 'entity-color-picker_container'
        )}
      >
        <section className="entity-color-picker_custom-pointers">
          <RgbColorPicker
            onChange={(color) => {
              onChange(color);
              setSelectedColorId(null);
            }}
            {...{ color }}
          />
        </section>
        <div className="entity-color-picker_input_container">
          <label className="entity-color-picker_rgba-block_label">
            HEX
            <HexColorInput
              prefixed
              maxLength={7}
              className="entity-color-picker_hex-input entity-color-picker_input-text"
              color={hexColor.toUpperCase()}
              onChange={onHexChange}
            />
          </label>
          <div className="entity-color-picker_rga_input_container">
            {rgbList.map((el, i) => (
              <label className="entity-color-picker_rgba-block_label" key={i}>
                {el}
                <input
                  maxLength={3}
                  className="entity-color-picker_rgba-block entity-color-picker_input-text"
                  value={color[el.toLowerCase()]}
                  onChange={(e) =>
                    parseInt(e.target.value) <= 255 &&
                    handleChangeRGB(el.toLowerCase(), e.target.value)
                  }
                />
              </label>
            ))}
          </div>
        </div>
        {yourColors.length > 0 && (
          <div>
            <p className="entity-color-picker_your-colors_label">
              {t('rightFileMenu.meta.yourColors')}
            </p>
            <div className="entity-color-picker_your-colors_container">
              {yourColors.map((yourColor) => (
                <div
                  key={yourColor?.id}
                  onContextMenu={handleOpenContextMenu}
                  className="entity-color-picker_your-colors_block_wrapper"
                  style={{
                    '--hover-color': yourColor?.hex,
                    border:
                      selectedColorId === yourColor.id
                        ? `1px solid ${yourColor?.hex}`
                        : 'none',
                    padding: selectedColorId === yourColor.id ? '1px' : '0',
                  }}
                >
                  <button
                    name={yourColor?.id}
                    onClick={handleSelectNewColor}
                    style={{
                      background: yourColor?.hex,
                    }}
                    className="entity-color-picker_your-colors_block"
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {isContextMenuOpen && (
          <div className="entity-color-picker_context-menu">
            <Button
              className="entity-color-picker_context-menu_button"
              onClick={handleRemoveColor}
              theme={ButtonTheme.TRANSPARENT}
            >
              <CancelIcon /> {t('rightFileMenu.meta.remove')}
            </Button>
          </div>
        )}
        <div
          style={
            selectedColorId
              ? { justifyContent: 'space-between' }
              : { justifyContent: 'flex-end' }
          }
          className="entity-color-picker_button-container"
        >
          {selectedColorId && (
            <Button
              theme={ButtonTheme.TRANSPARENT}
              onClick={handleRemoveColor}
              className="entity-color-picker_remove-button"
            >
              {t('rightFileMenu.meta.remove')}
            </Button>
          )}
          <Button
            onClick={handleAddNewColor}
            theme={ButtonTheme.DARK}
            className="entity-color-picker_add-button"
          >
            {t('rightFileMenu.meta.add')}
          </Button>
        </div>
      </div>
    )
  );
};

export default ColorPicker;
