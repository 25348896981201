import React from 'react'

const Folder = ({ color }) => {
  return (
    <svg width="154" height="112" fill="none">
      <g filter="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.72 3H10v8.64h-.203v84.362h123.586V11.64H57.598L52.72 3Z"
          fill="url(#b)"
        />
      </g>
      <rect
        x="14.29"
        y="16.822"
        width="110.104"
        height="10.054"
        rx="5.027"
        fill="#fff"
      />
      <g filter="url(#c)">
        <mask id="e" fill="#fff">
          <path d="M3 20.594h136L133.382 96H9.795L3 20.594Z" />
        </mask>
        <path d="M3 20.594h136L133.382 96H9.795L3 20.594Z" fill="url(#d)" />
        <path
          d="M3 20.594v-1H1.906l.098 1.09.996-.09Zm136 0 .997.074.08-1.074H139v1ZM133.382 96v1h.928l.069-.926-.997-.074ZM9.795 96l-.996.09.083.91h.913v-1ZM3 21.594h136v-2H3v2Zm135.003-1.075-5.618 75.408 1.994.148 5.618-75.407-1.994-.148Zm-4.621 74.482H9.795v2h123.587v-2Zm-122.59.91L3.995 20.504l-1.992.18L8.799 96.09l1.992-.18Z"
          fill="#fff"
          mask="url(#e)"
        />
      </g>
      <defs>
        <linearGradient
          id="b"
          x1="10"
          y1="22"
          x2="138"
          y2="10"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} />
        </linearGradient>
        <linearGradient
          id="d"
          x1="71"
          y1="20.594"
          x2="71"
          y2="96.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9DCE3" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <filter
          id="a"
          x="6.797"
          y="0"
          width="141.586"
          height="111.002"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="6" dy="6" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0520105 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7116_37254"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_7116_37254"
            result="shape"
          />
        </filter>
        <filter
          id="c"
          x="0"
          y="17.594"
          width="154"
          height="93.407"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="6" dy="6" />
          <feGaussianBlur stdDeviation="4.5" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0520105 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7116_37254"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_7116_37254"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}

Folder.defaultProps = {
  color: '#E9EBEF',
}

export default Folder
