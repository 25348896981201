export const tarrifsTypes = {
  storage: {
    type: 1,
  },
  security: {
    type: 2,
  },
};

export const SUBSCRIPTION_NAME = 'team';

export const SUBSCRIPTIONS_PERIOD = {
  year: 'year',
  month: 'month',
};

export const SUBSCRIPTIONS_PRICING = {
  year: 18000,
  month: 2000,
};
