//Refactoring №4
import React, { useContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CN from 'classnames';

import MainContext from 'store/main/context/main-context';
import {
  selectSidebarWidth,
  selectCollapsed,
} from 'features/leftMenu/leftMenuSlice';
import { workspaceResetFileCountByType } from 'features/workspaceSettings/workspaceSettingsSlice';
import { STORAGE_KEY } from 'store/context/theme';

// web 3
// import { ReactComponent as NftIcon } from 'static/assets/svg/settingsSidebar/nft.svg'
// import { ReactComponent as EncryptionIcon } from 'static/assets/svg/settingsSidebar/encryption.svg'

//
import { ReactComponent as AccountIcon } from './assets/v2/account.svg';
import { ReactComponent as SettingsIcon } from './assets/v2/settings.svg';
import { ReactComponent as NotificationsIcon } from './assets/v2/notifications.svg';
import { ReactComponent as LogsIcon } from './assets/v2/logs.svg';
import { ReactComponent as SecurityIcon } from './assets/v2/security.svg';
import { ReactComponent as MembersIcon } from './assets/v2/members.svg';
import { ReactComponent as ApiIcon } from './assets/v2/api.svg';
import { ReactComponent as BlockchainIcon } from './assets/v2/blockchain.svg';
import { ReactComponent as GuardianIcon } from './assets/v2/guardian.svg';
import { ReactComponent as LanguageIcon } from './assets/v2/language.svg';
import { ReactComponent as WalletIcon } from './assets/v2/wallet.svg';
import { ReactComponent as LoginIcon } from './assets/v2/login.svg';
import { ReactComponent as MultisigIcon } from './assets/v2/multisig.svg';
import { ReactComponent as RechargeIcon } from './assets/v2/recharge.svg';
import { ReactComponent as FeaturesIcon } from './assets/v2/features.svg';
import { ReactComponent as HistoryIcon } from './assets/v2/history.svg';
import { ReactComponent as LogoutIcon } from './assets/v2/log-out.svg';
//

import SidebarMenuItem from './components/SidebarMenuItem';
import LogoutSettingsItem from './settingsItem';
import AppNavLink from './components/AppNavLink';
import { NewStorageInfo } from '../MainSidebar/components/NewStorageInfo';

import removeToken from 'utils/auth/remove-token';

import styles from './style.module.scss';

type SettingsSidebarProps = {
  className: string;
  isGuest: boolean;
};

export default function SettingsSidebar({
  className,
  isGuest,
  isOwner,
}: SettingsSidebarProps) {
  const { t, i18n } = useTranslation('sidebar');
  const dispatch = useDispatch();
  const isAvatarTab = !!useRouteMatch('/account/ai-avatar');
  const isWorkspaceSettings = !!useRouteMatch('/workspace/:slug');
  const {
    state: {
      workspace: { name: workspaceName },
    },
  } = useContext(MainContext);

  const width = useSelector(selectSidebarWidth);
  const collapsed = useSelector(selectCollapsed);

  const workspaceMenuItems = useMemo(
    () => [
      {
        name: t('accountSettings.settings'),
        icon: SettingsIcon.render,
        url: '/workspace/settings',
      },
      {
        name: t('workspaceSettings.members'),
        icon: MembersIcon.render,
        url: '/workspace/members',
      },
      {
        name: t('accountSettings.apiAccess'),
        icon: ApiIcon.render,
        url: '/workspace/apikey',
      },
      // {
      //   name: t('accountSettings.accessControl'),
      //   icon: SecurityIcon.render,
      //   url: '/workspace/security',
      // },
      {
        name: t('accountSettings.blockchain'),
        icon: BlockchainIcon.render,
        url: '/workspace/blockchain',
      },
      // Hide Native apps
      // {
      //   name: 'Native apps',
      //   icon: ApplicationsIcon.render,
      //   url: '/workspace/applications',
      // },
    ],
    [i18n.language]
  );

  const accountMenuItems = useMemo(
    () => [
      // {
      //   name: t('accountSettings.avatar'),
      //   icon: AiAvatarIcon.render,
      //   url: '/account/ai-avatar',
      // },
      // {
      //   name: t('accountSettings.profile'),
      //   icon: AccountIcon.render,
      //   url: '/account/profile',
      // },
      {
        name: 'Wallet',
        icon: WalletIcon.render,
        url: '/account/bank',
      },
      {
        name: 'Authorization',
        icon: LoginIcon.render,
        url: '/account/authorization',
      },
      // {
      //   name: t('accountSettings.accSettings'),
      //   icon: SettingsIcon.render,
      //   url: '/account/settings',
      // },
      {
        name: t('accountSettings.notifications'),
        icon: NotificationsIcon.render,
        url: '/account/notifications',
      },
      {
        name: 'Language',
        icon: LanguageIcon.render,
        url: '/account/language',
      },
    ],
    [i18n.language]
  );

  const guestAccountMenuItems = useMemo(
    () => [
      {
        name: t('accountSettings.profile'),
        icon: AccountIcon.render,
        url: '/account/profile',
      },
      // {
      //   name: t('accountSettings.settings'),
      //   icon: SettingsIcon.render,
      //   url: '/account/settings',
      // },
    ],
    [i18n.language]
  );

  const security = useMemo(
    () => [
      {
        name: t('accountSettings.security'),
        icon: SecurityIcon.render,
        url: '/account/security-layers',
      },
      {
        name: 'Multisig',
        icon: MultisigIcon.render,
        url: '/account/multisig',
      },
      {
        name: 'Guardians',
        icon: GuardianIcon.render,
        url: '/workspace/guardians',
      },
      // {
      //   name: 'Billing History',
      //   icon: BillingIcon.render,
      //   url: '/workspace/billing',
      // },
      {
        name: 'Logs',
        icon: LogsIcon.render,
        url: '/account/logs',
      },
    ],
    [i18n.language]
  );

  const billing = useMemo(
    () => [
      {
        name: 'Recharge',
        icon: RechargeIcon.render,
        url: '/wallet/buy-credits',
      },
      {
        name: 'Features',
        icon: FeaturesIcon.render,
        url: '/account/features',
      },
      {
        name: 'History',
        icon: HistoryIcon.render,
        url: '/account/credits',
      },
      // {
      //   name: 'Billing History',
      //   icon: BillingIcon.render,
      //   url: '/workspace/billing',
      // },
    ],
    [i18n.language]
  );

  const logOut = (e) => {
    document.documentElement.removeAttribute(STORAGE_KEY);
    e.preventDefault();
    e.stopPropagation();
    removeToken();
    dispatch(workspaceResetFileCountByType());
    window.location.href = `${window.location.origin}/connect`;
  };

  const accountList = isGuest ? guestAccountMenuItems : accountMenuItems;
  const workspaceList = isOwner ? workspaceMenuItems : [];

  return (
    <div
      style={!collapsed ? { width: width - 1 + 'px' } : {}}
      className={CN(styles.wrapper, collapsed && styles.collapsed, className)}
    >
      <div className={styles.topContainer}>
        <AppNavLink
          to={isGuest ? '/sharing' : '/main'}
          className={styles.goBackLink}
        />
        <h2 className={styles.wsSubTitle}>Account</h2>
      </div>
      <ul className={styles.list}>
        {accountList.map(({ name, icon, url }, i) => {
          // if (
          //   (isProdEnviroment && name === t('accountSettings.avatar')) ||
          //   (isProdEnviroment && name === t('accountSettings.safetyNet'))
          // )
          //   return;
          return (
            <li key={i}>
              <SidebarMenuItem
                className={styles.menuItem}
                name={name}
                icon={icon}
                settingsItem={true}
                url={
                  isWorkspaceSettings
                    ? { pathname: url, state: { name: workspaceName } }
                    : url
                }
              />
            </li>
          );
        })}
        {isOwner && (
          <h2 className={styles.wsTitle}>{t('accountSettings.drive')}</h2>
        )}
        {workspaceList.map(({ name, icon, url }, i) => {
          // if (
          //   (isProdEnviroment && name === t('accountSettings.avatar')) ||
          //   (isProdEnviroment && name === t('accountSettings.safetyNet'))
          // )
          //   return;
          return (
            <li key={i}>
              <SidebarMenuItem
                className={styles.menuItem}
                name={name}
                icon={icon}
                settingsItem={true}
                url={
                  isWorkspaceSettings
                    ? { pathname: url, state: { name: workspaceName } }
                    : url
                }
              />
            </li>
          );
        })}
        {isOwner && (
          <>
            <h2 className={styles.wsTitle}>File Security</h2>
            {security.map(({ name, icon, url }, i) => {
              return (
                <li key={i}>
                  <SidebarMenuItem
                    className={styles.menuItem}
                    name={name}
                    icon={icon}
                    settingsItem={true}
                    url={
                      isWorkspaceSettings
                        ? { pathname: url, state: { name: workspaceName } }
                        : url
                    }
                  />
                </li>
              );
            })}
          </>
        )}

        {isOwner && (
          <>
            <h2 className={styles.wsTitle}>Credits</h2>
            {billing.map(({ name, icon, url }, i) => {
              return (
                <li key={i}>
                  <SidebarMenuItem
                    className={styles.menuItem}
                    name={name}
                    icon={icon}
                    settingsItem={true}
                    url={
                      isWorkspaceSettings
                        ? { pathname: url, state: { name: workspaceName } }
                        : url
                    }
                  />
                </li>
              );
            })}
          </>
        )}
        <li className={styles.menuLogout} onClick={logOut}>
          <LogoutSettingsItem
            className={CN(
              styles.logoutItem,
              collapsed && styles.logoutItemColapsed
            )}
            name={t('accountSettings.logOut')}
            icon={LogoutIcon.render}
          />
        </li>
      </ul>
      {isAvatarTab && (
        <div className={styles.storageInfo}>
          <NewStorageInfo />
        </div>
      )}
    </div>
  );
}
