// Refactoring №3
import React from 'react';
import CN from 'classnames';

import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { SORT_DIR } from 'features/app';

import styles from './styles.module.scss';

const Sorter = ({ dir, changeDirection }) => {
  return (
    <button
      className={CN(
        styles.directionButton,
        dir === SORT_DIR.asc && styles.directionButtonDesc
      )}
      onClick={changeDirection}
    >
      <ArrowIcon />
    </button>
  );
};

export default Sorter;
