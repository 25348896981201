import 'react-app-polyfill/ie11';
import 'mdn-polyfills/String.prototype.startsWith';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/root-store';
import * as serviceWorker from './serviceWorker';
import Modal from 'react-modal';
import { HelmetProvider } from 'react-helmet-async';
import interceptInit from './interceptors';
import Web3Checker from 'components/Web3Checker';
import walletConnectModule from '@web3-onboard/walletconnect';
import { init } from '@web3-onboard/react';
import injectedModule, { ProviderLabel } from '@web3-onboard/injected-wallets';
import uauthModule from '@web3-onboard/uauth';
import coinbaseWalletModule from '@web3-onboard/coinbase';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from 'store/context/theme';
import i18n from 'translation/i18n';
import logo from 'components/svg/logo.svg';
import { Eip6963Provider, METAMASK_INSTALLED } from 'utils/Eip6963Provider';

interceptInit();

Modal.setAppElement('#root');
// wallet connect
const walletConnect = walletConnectModule({
  projectId: '0ca0eb3cae2b0d4f42ff57794e094527',
  dappUrl: process.env.REACT_APP_API_PATH,
});
// unstoppable wallet
const unstoppableWallet = uauthModule({
  clientID: process.env.REACT_APP_UNSTOPPABLE_CLIENT_ID || 'temporary',
  redirectUri: window.location.origin,
  scope: 'openid wallet email:optional',
  shouldLoginWithRedirect: false,
  bridge: 'YOUR_CUSTOM_BRIDGE_SERVER',
  connectFirstChainId: true,
});

// coinbase wallet
const coinbaseWalletSdk = coinbaseWalletModule();

// custom metamask wallet
const customMetaMask = {
  label: 'MetaMask',
  injectedNamespace: 'ethereum',
  getIcon: async () => (await import('./static/assets/metamask.js')).default,
  getInterface: async () => {
    if (!METAMASK_INSTALLED) {
      throw new Error('Please install or enable MetaMask to continue');
    }
    return {
      provider: window.metamask,
    };
  },
};

// metamask wallet
const injectedWallet = injectedModule({
  displayUnavailable: true,
  sort: (wallets) => {
    // const metaMask = wallets.find(
    //   ({ label }) => label === ProviderLabel.MetaMask
    // );
    const coinbase = wallets.find(
      ({ label }) => label === ProviderLabel.Coinbase
    );
    const walletConnect = wallets.find(
      ({ label }) => label === ProviderLabel.WalletConnect
    );
    return [customMetaMask, coinbase, walletConnect];
  },
  walletUnavailableMessage: (wallet) => {
    if (
      wallet.label === ProviderLabel.MetaMask &&
      (window.ethereum.isTrust || window.ethereum.isTrustWallet)
    ) {
      return 'To connect MetaMask, please disable the default wallet settings in Trust Wallet and try again.';
    } else {
      return `Please install or enable ${wallet.label} to continue`;
    }
  },
});

const customTheme = {
  '--w3o-background-color': '#000000',
  '--w3o-foreground-color': 'unset',
  '--w3o-text-color': '#ffffff',
  '--w3o-border-color': '#5e5e5e',
  '--w3o-action-color': 'unset',
  '--w3o-border-radius': '20px',
  '--w3o-font-family': 'unset',
};

init({
  wallets: [
    injectedWallet,
    walletConnect,
    unstoppableWallet,
    coinbaseWalletSdk,
  ],
  chains: [
    {
      id: '0x1',
      token: 'ETH',
      label: 'Ethereum Mainnet',
      rpcUrl: 'https://mainnet.infura.io/v3/a2948ae8536c465ebff3a54147ff9fd4',
    },
  ],
  theme: customTheme,
  appMetadata: {
    name: 'Ghost Drive',
    icon: logo,
    description: 'Ghost Drive App',
  },
  connect: {
    showSidebar: false,
  },
  accountCenter: {
    desktop: {
      enabled: false,
    },
  },
});

const loadScript = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = process.env.REACT_APP_JWT_LINK;
    script.async = true;

    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script`));

    document.body.appendChild(script);
  });
};

loadScript()
  .catch((error) => {
    console.error(error);
  })
  .finally(() => {
    ReactDOM.render(
      <HelmetProvider>
        <Eip6963Provider>
          <Provider store={store}>
            <Web3Checker />
            <BrowserRouter>
              <I18nextProvider i18n={i18n}>
                <ThemeProvider>
                  <App />
                </ThemeProvider>
              </I18nextProvider>
            </BrowserRouter>
          </Provider>
        </Eip6963Provider>
      </HelmetProvider>,
      document.getElementById('root')
    );

    serviceWorker.unregister();
  });
