import React from 'react'

const ArrowNext = ({ color }) => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="72"
      height="72"
      transform="matrix(4.37114e-08 1 1 -4.37114e-08 0 0)"
      fill="black"
      fillOpacity="0.5"
    />
    <rect
      width="24"
      height="14"
      transform="matrix(4.37114e-08 1 1 -4.37114e-08 30 24)"
      fill="white"
      fillOpacity="0.01"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.6357 45.9497L32.05 47.3639L43.4139 35.9999L32.05 24.636L30.6357 26.0502L40.5855 35.9999L30.6357 45.9497Z"
      fill={color}
    />
  </svg>
)

ArrowNext.defaultProps = {
  color: '#FFFFFF',
}
export default ArrowNext
