/* eslint-disable no-unused-vars */
//Refactoring №3
import React from 'react';
// import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';
import styles from './styles.module.scss';

export default function AuthFooterInfo({
  className,
  signup = false,
  signin = false,
  NFT = false,
  history,
  isoauth,
}) {
  const { t } = useTranslation('auth');
  return (
    <div className={cn(styles.container, className)}>
      {/* <p className={styles.textLine}>
        <span className={styles.text}>
          {signup && t('common.alreadyHaveAccount')}
          {signin && t('signup.dontHaveAccount')}
          {NFT && t('signup.dontHaveAccount')}
        </span>
        <Link
          data-test="auth_footer_info_sign-in_link"
          className={styles.signLink}
          to={
            (signup && `/sign-in${isoauth ? history.location.search : ''}`) ||
            (signin && `/sign-up${isoauth ? history.location.search : ''}`) ||
            '#'
          }
        >
          {signin && t('signup.signup')}
          {signup && t('common.signin')}
        </Link>
      </p> */}
      {(signup || signin || NFT) && (
        <p className={styles.textLine}>
          <span className={styles.policyText}>{`By proceeding, you agree to Neyra Labs'`}</span>
          <a
            data-test="auth_footer_info_terms-of-service_link"
            href="https://www.ghostdrive.com/terms-of-service"
            className={styles.policyLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('common.terms')},
            <br className={styles.mobileBreak} />
          </a>
          <span className={styles.policyText}>{` of Use`}</span>
        </p>
      )}
    </div>
  );
}
