//Refactoring №3
import React, { FC } from 'react';
import { useHistory } from 'react-router';
import ReactModal from 'react-modal';

import { ReactComponent as LogoIcon } from '../../../static/assets/svg/logo.svg';
import CloseIcon from '../../svg/close';

import s from '../styles.module.scss';

interface FullScreenModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: any;
}

export const FullScreenModal: FC<FullScreenModalProps> = ({
  children,
  isOpen,
  onClose,
}) => {
  const history = useHistory();
  const goToSignInHandler = () => history.push('/main');
  return isOpen ? (
    <ReactModal isOpen={isOpen}>
      <div className={s.fullScreenModal}>
        <LogoIcon className={s.logo} onClick={goToSignInHandler} />
        {onClose && (
          <button type={'button'} className={s.exit} onClick={onClose}>
            <CloseIcon />
          </button>
        )}
        <div className={s.content}>{children}</div>
      </div>
    </ReactModal>
  ) : null;
};
