//Refactoring №4
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import cn from 'classnames';

import Info from 'components/Info';
import s from './style.module.scss';

export const TextareaFieldTheme = {
  GENERAL: 'general',
  GET_INVITE: 'get_invite',
};

type TextareaFieldProps = {
  className: string;
  placeholder: string;
  onChange: (arg: React.SyntheticEvent<EventTarget>) => void;
  withValidation: boolean;
  value: string;
  name?: string;
  label?: string;
  calloutText?: string;
  error?: boolean;
  rows?: number;
  touched?: boolean;
  optional?: boolean;
  autoComplete?: string;
  onBlur?: () => void;
  theme?: string;
  readOnly?: boolean;
  disabled?: boolean;
  tooltip?: any;
};

export default function TextareaField({
  name,
  label,
  calloutText,
  className,
  error,
  rows,
  touched = true,
  optional = false,
  placeholder = '',
  withValidation = true,
  autoComplete = 'off',
  value,
  onChange,
  onBlur,
  theme = TextareaFieldTheme.GENERAL,
  readOnly = false,
  disabled,
  tooltip,
}: TextareaFieldProps) {
  const { t } = useTranslation('owner');
  const getTheme = useCallback(
    () =>
      theme === TextareaFieldTheme.GENERAL ? s.themeGeneral : s.themeGetInvite,
    [theme]
  );

  return (
    <div
      className={cn(
        getTheme(),
        className,
        touched && error && s.isError,
        disabled && s.disabled
      )}
    >
      {label && (
        <label className={s.label} htmlFor="">
          {label}
          {optional && (
            <span className={s.optional}>{t('common.optional')}</span>
          )}
        </label>
      )}
      {calloutText && <Info calloutText={calloutText} />}
      <textarea
        className={cn(s.input, s.textarea)}
        name={name}
        rows={rows}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
        autoComplete={autoComplete}
        disabled={disabled}
      />
      {withValidation && (
        <div className={s.errorMessage}>{(touched && error) || '\u00A0'}</div>
      )}
      {tooltip}
    </div>
  );
}
