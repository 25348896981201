import React, { FC, useState, useMemo } from 'react';
import { Contract, ethers } from 'ethers';
import { useParams } from 'react-router';
import { isString } from 'lodash';

import { getSigner } from 'store/auth/effects';
import { getAbi } from 'store/web3';

import {
  GetSigner,
  InitialValues,
} from 'containers/main/EntitySettingsContainer/components/Web3Tab/components/Tokenization/components/Monetization';
import { ContactDetailsByURi } from 'containers/share-by-link-nft';
import { web3ErrorHandler } from 'utils/web3Helper';

import useNotification from 'utils/hooks/use-notification';

import { ReactComponent as UploadIcon } from 'static/assets/svg/uploadPreview.svg';

import { BorderedCard } from './PreviewCard';
import { MemoBorderedButton as BorderedButton } from './BorderedButton';

import { ConvertedPrice, Monetize, PreviewParams, TEXT, THEME } from '../index';

import s from '../styles.module.scss';

interface FrontCardProps {
  fileName: string;
  isDarkMode: boolean;
  isUpload: boolean;
  isPreviewMode: boolean;
  info?: ContactDetailsByURi;
  formData?: InitialValues;
  handleClose?: () => void;
  convertedPrice: ConvertedPrice;
  monetize: Monetize | null;
}

type SPaidMintCallSigner = GetSigner & {
  data: {
    reqCooked: {
      price: string;
    };
  };
};

const FrontCardNoMemo: FC<FrontCardProps> = ({
  isDarkMode,
  info,
  isUpload,
  fileName,
  isPreviewMode,
  formData,
  handleClose,
  convertedPrice,
  monetize,
}) => {
  const [showLoader, setShowLoader] = useState(false);
  const params = useParams<PreviewParams>();
  const { addNotification } = useNotification();
  const cardImg = useMemo(() => {
    const formIngValue = formData?.previewImage?.value
    if (formIngValue) {
      if (isString(formIngValue))
        return formIngValue

        return URL.createObjectURL(formIngValue)
      }
    return info?.image
  }, [formData, info])

  const handleBuy = async () => {
    setShowLoader(true);
    const tokenSlug = stringToBytes32(params.contract_address);
    const { abi, address } = await getAbi(params.networkId, 'shopSigned');
    const provider = new ethers.providers.Web3Provider(window.metamask, 'any');
    const signer = provider.getSigner();
    try {
      //@ts-ignore
      const { data } = (await getSigner({
        type: 'SPaidMintCall',
        contract: 'SignedPriceShop',
        contractAddress: address,
        chainId: params.networkId,
        req: {
          tokenSlug,
          tokenId: info?.tokenId,
          referrer: ethers.constants.AddressZero,
          referrerTokenSlug: tokenSlug,
          referrerTokenId: 0,
        },
        isPublic: !isPreviewMode,
      })) as SPaidMintCallSigner;
      const newContract = new Contract(address, abi, signer);
      const bougthFile = await newContract.buy(data.reqCooked, data.signature, {
        value: data.reqCooked.price,
      });
      const confirmed = await bougthFile.wait();
      if (confirmed) {
        handleClose && handleClose();
        location.reload();
      }
      setShowLoader(false);
    } catch (e: any) {
      const error = web3ErrorHandler(e, false);
      // @ts-ignore
      addNotification(error, 'alert');
      setShowLoader(false);
    }
  };

  return (
    <BorderedCard isDarkMode={isDarkMode}>
      <div className={s.view_image_container}>
        <img
          loading={'lazy'}
          src={cardImg}
          alt={'preview-uploaded-img'}
          className={s.view_image}
        />
        {isUpload ? (
          <div className={s.view_upload_icon_container}>
            <UploadIcon />
          </div>
        ) : null}
      </div>
      <div className={s.view_info}>
        <p
          style={isDarkMode ? undefined : THEME.white.title}
          className={s.view_title}
        >
          {fileName || info?.name || TEXT.title}
        </p>
        <p
          title={info?.description}
          style={isDarkMode ? undefined : THEME.white.description}
          className={s.view_description}
        >
          {info?.description
            ? info?.description.length > 90
              ? `${info?.description.substring(0, 87)}...`
              : info?.description
            : TEXT.description}
        </p>
        { !(!isPreviewMode && !monetize?.price) && (
          <BorderedButton
            onClick={isPreviewMode ? undefined : handleBuy}
            cryptoPrice={
              convertedPrice
                ? `${convertedPrice?.value}${convertedPrice?.name}`
                : ''
            }
            title={
              formData || monetize
                ? `$${isPreviewMode ? formData?.price : monetize?.price}`
                : '$0'
            }
            isDarkMode={isDarkMode}
            showLoader={showLoader}
          />
        )}
      </div>
    </BorderedCard>
  );
};

export const FrontCard = React.memo(FrontCardNoMemo);

function stringToBytes32(text: string) {
  let result = ethers.utils.hexlify(text);
  while (result.length < 66) {
    result += '0';
  }
  if (result.length !== 66) {
    throw new Error('invalid web3 implicit bytes32');
  }
  return result;
}
