export const SELECTED_ENTITY_ADD = 'SELECTED_ENTITY_ADD'
export const SELECTED_ENTITY_ALLOW_ACCESS = 'SELECTED_ENTITY_ALLOW_ACCESS'
export const SELECTED_ENTITY_UPDATE = 'SELECTED_ENTITY_UPDATE'
export const SELECTED_ENTITY_UPDATE_BY_PROPS = 'SELECTED_ENTITY_UPDATE_BY_PROPS'
export const SELECTED_ENTITY_CLEAR = 'SELECTED_ENTITY_CLEAR'
export const SELECTED_ENTITY_VIEW = 'SELECTED_ENTITY_VIEW'
export const SELECTED_ENTITY_VIEW_CLEAR = 'SELECTED_ENTITY_VIEW_CLEAR'
export const SELECTED_ENTITY_SHARE = 'SELECTED_ENTITY_SHARE'
export const SELECTED_ENTITY_SHARE_CLEAR = 'SELECTED_ENTITY_SHARE_CLEAR'
export const SELECTED_ENTITY_MOVE = 'SELECTED_ENTITY_MOVE'
export const SELECTED_ENTITY_MOVE_CLEAR = 'SELECTED_ENTITY_MOVE_CLEAR'
export const SELECTED_ENTITY_ACCESS_PREFERENCES =
  'SELECTED_ENTITY_ACCESS_PREFERENCES'
export const SELECTED_ENTITY_ACCESS_PREFERENCES_CLEAR =
  'SELECTED_ENTITY_ACCESS_PREFERENCES_CLEAR'

export const selectedEntityAdd = (res) => {
  return {
    type: SELECTED_ENTITY_ADD,
    payload: res,
  }
}

export const selectedEntityAllowAccess = () => ({
  type: SELECTED_ENTITY_ALLOW_ACCESS,
})

export const selectedEntityUpdate = (data) => ({
  type: SELECTED_ENTITY_UPDATE,
  payload: data,
})

export const selectedEntityUpdateByProps = (data) => ({
  type: SELECTED_ENTITY_UPDATE_BY_PROPS,
  payload: data,
})

export const selectedEntityClear = () => ({
  type: SELECTED_ENTITY_CLEAR,
})

export const selectedEntityViewClear = () => ({
  type: SELECTED_ENTITY_VIEW_CLEAR,
})

export const selectedEntityView = (res) => ({
  type: SELECTED_ENTITY_VIEW,
  payload: res,
})

export const selectedEntityShare = (res) => ({
  type: SELECTED_ENTITY_SHARE,
  payload: res,
})

export const selectedEntityShareClear = () => ({
  type: SELECTED_ENTITY_SHARE_CLEAR,
})

export const selectedEntityMove = (res) => ({
  type: SELECTED_ENTITY_MOVE,
  payload: res,
})

export const selectedEntityMoveClear = () => ({
  type: SELECTED_ENTITY_MOVE_CLEAR,
})

export const selectedEntityAccessPreferences = (res) => ({
  type: SELECTED_ENTITY_ACCESS_PREFERENCES,
  payload: res,
})

export const selectedEntityAccessPreferencesClear = () => ({
  type: SELECTED_ENTITY_ACCESS_PREFERENCES_CLEAR,
})
