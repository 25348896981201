/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import CN from 'classnames';
import { connect, useDispatch } from 'react-redux';

import { ReactComponent as DriveIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/drive.svg';
import { ReactComponent as MetaIcon } from 'containers/main/NeyraDriveContainer/components/FileInfoSidebar/assets/neyra.svg';
import { ReactComponent as TokenizedIcon } from 'containers/main/NeyraDriveContainer/components/FileInfoSidebar/assets/tokenize.svg';
import { ReactComponent as PlusIcon } from 'containers/main/NeyraDriveContainer/components/FileInfoSidebar/assets/plus.svg';
import { ReactComponent as HistoryIcon } from 'containers/main/NeyraDriveContainer/components/FileInfoSidebar/assets/history.svg';
import { ReactComponent as CommentsIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/comments.svg';
import { ReactComponent as ShareIcon } from 'containers/main/NeyraDriveContainer/components/FileInfoSidebar/assets/share.svg';
import { ReactComponent as SettingsIcon } from 'containers/main/Main/components/SettingsSidebar/assets/v2/settings.svg';
import { ReactComponent as MyAiIcon } from 'containers/main/Main/components/MainSidebar/components/Container/assets/v2/star.svg';

import useNotification from 'utils/hooks/use-notification';
import { selectedEntityClear } from 'store/home/actions/selected-entity.actions';
import {
  selectSelectedFilePreview,
  resetSelectedFilePreview,
  updateSelectedFilePreview,
  FILE_PREVIEW,
} from 'features/app';

import styles from './styles.module.scss';

const FileInfoSidebar = ({ selectedPreview, selectedEntity }) => {
  const dispatch = useDispatch();
  const { addNotification } = useNotification();

  const closeMetaInfo = () => {
    dispatch(selectedEntityClear());
    dispatch(resetSelectedFilePreview());
  };

  const onChnagePreview = (name) => {
    dispatch(updateSelectedFilePreview(name));
  };

  useEffect(() => {
    if (
      selectedEntity?.is_clientside_encrypted &&
      selectedPreview === FILE_PREVIEW.tokenize
    ) {
      onChnagePreview(FILE_PREVIEW.meta);
    }
  }, [selectedEntity]);

  const list = [
    {
      icon: <DriveIcon />,
      name: 'home',
      onClick: closeMetaInfo,
    },
    {
      icon: <MetaIcon />,
      name: 'meta',
      onClick: () => {
        onChnagePreview(FILE_PREVIEW.meta);
      },
    },
    {
      icon: <TokenizedIcon />,
      name: 'tokenize',
      onClick: () => {
        if (selectedEntity?.is_clientside_encrypted) {
          addNotification(
            'This file group contains encrypted files which may not be tokenized',
            'alert'
          );
        } else {
          onChnagePreview(FILE_PREVIEW.tokenize);
        }
      },
    },
    {
      icon: <CommentsIcon color="#fff" />,
      name: 'comment',
      onClick: () => {
        onChnagePreview(FILE_PREVIEW.comment);
      },
    },
    {
      icon: <HistoryIcon color="#fff" />,
      name: 'logs',
      onClick: () => {
        onChnagePreview(FILE_PREVIEW.logs);
      },
    },
    {
      icon: <ShareIcon />,
      name: 'share',
      onClick: () => {
        onChnagePreview(FILE_PREVIEW.share);
      },
    },
    {
      icon: <SettingsIcon />,
      name: 'settings',
      onClick: () => {
        onChnagePreview(FILE_PREVIEW.settings);
      },
    },
    {
      icon: <MyAiIcon />,
      name: 'myAi',
      onClick: () => {
        onChnagePreview(FILE_PREVIEW.myAi);
      },
    },
    {
      icon: <PlusIcon />,
      name: 'plus',
      onClick: () => {},
    },
  ];

  return (
    <>
      {list.map(({ icon, onClick, name }, index) => (
        <div
          key={`meta-option-${index}`}
          name={name}
          className={CN(
            styles.itemSidebar,
            name === selectedPreview && styles.itemSidebarActive
          )}
          onClick={onClick}
        >
          {icon}
        </div>
      ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedPreview: selectSelectedFilePreview(state),
  selectedEntity: state.home?.selectedEntity?.entity,
});

export default connect(mapStateToProps)(FileInfoSidebar);
