import { imageAsObj } from 'containers/header/header-container/components/ImageToImageModal';
import * as actions from '../../actions/file/ai-generator.action';

const initialState = {
  status: false,
};
export type action = {
  payload: imageAsObj;
  type: string;
};
export default (state = initialState, action: action) => {
  switch (action.type) {
    case actions.START_GENERATE:
      return { status: true, imageObj: action.payload };

    case actions.END_GENERATE:
      return { status: false };
    default:
      return state;
  }
};
