//Refactoring №3
import React, { FC, useMemo } from 'react';
import { ReactComponent as TopLeftCorner } from '../../../static/assets/svg/corners/TopLeftCorner.svg';
import { ReactComponent as BottomLeftCorner } from '../../../static/assets/svg/corners/BottomLeftCorner.svg';
import { ReactComponent as TopRightCorner } from '../../../static/assets/svg/corners/TopRightCorner.svg';
import { ReactComponent as BottomRightCorner } from '../../../static/assets/svg/corners/BottomRightCorner.svg';

import CN from 'classnames';
import s from '../styles.module.scss';
interface BorderedCard {
  children: any;
  isDarkMode: boolean;
}

export const BorderedCard: FC<BorderedCard> = ({ children, isDarkMode }) => {
  const whiteTheme = useMemo(() => {
    return !isDarkMode && s.white_mode_background;
  }, [isDarkMode]);

  return (
    <div className={s.view}>
      <div className={CN(s.view_container, whiteTheme)}>
        <TopLeftCorner className={s.view_corner_left} />
        <BottomLeftCorner className={s.view_corner_bottom} />
        {children}
        <TopRightCorner className={s.view_corner_right} />
        <BottomRightCorner className={s.view_corner_top} />
      </div>
    </div>
  );
};
