//Refactoring №4
import React from 'react';

import cn from 'classnames';
import s from './style.module.scss';

type CalloutProps = {
  className: string;
  children: React.ReactNode;
};

export default function Callout({ className, children }: CalloutProps) {
  return <div className={cn(s.wrapper, className)}>{children}</div>;
}
