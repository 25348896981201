export const studioDefaultPrompts = {
  Robot: [
    'cyber',
    'robot',
    'mechanism',
    'automation',
    'high quality',
    'hi tech',
    'unreal',
    'cyberpunk',
    'details',
    'digital',
    'future',
    'terminator',
    '8K',
    'HD',
  ],
  Plant: [
    'micro elements',
    'high quality',
    'art-station',
    'illustration',
    'atmosphere',
    'freedom',
    'real-futurism',
    'plant',
    'life',
    'nature',
    'art',
    'wild life',
    'hydro-dipping style',
    'HD',
  ],
  'Sci-Fi': [
    'cyber',
    'galaxy',
    'cosmos',
    'space',
    'secret geometry',
    'hi tech',
    'unreal',
    'cyberpunk',
    'details',
    'digital',
    'future',
    'sci-fi',
    'cinematic',
    'HD',
  ],
  Magic: [
    'digital illustration',
    'secret geometry',
    'masterpiece',
    'automation',
    'real-futurism',
    'hi tech',
    'unreal',
    'cyberpunk',
    'unreal',
    'illusion',
    'future',
    'generative art',
    'magic',
    'wizard',
  ],
  'HD 8K': [
    'ultra quality',
    'deep-dimensional',
    'mechanism',
    'full of details',
    'high resolution',
    'hi tech',
    'unreal',
    'cyberpunk',
    'high quality',
    'digital',
    'future',
    'masterpiece',
    '8K',
    'HD',
  ],
};

export function getRandomPrompt(max) {
  return Math.floor(Math.random() * max);
}
