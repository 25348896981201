import {
  ENCRYPTION_KEY_WRONG,
  CLOSE_ENCRYPTION_MODAL,
  CHANGE_ENCRYPTION_STATUS,
} from 'store/home/actions/file/encryption-key.action.js';

const initialState = {
  encryptionKeyError: { error: false, message: '' },
  closeEncryptionModal: false,
  isDecryptionProgress: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ENCRYPTION_KEY_WRONG:
      return {
        ...state,
        encryptionKeyError: action.payload,
      };
    case CLOSE_ENCRYPTION_MODAL:
      return {
        ...state,
        closeEncryptionModal: action.payload,
      };
    case CHANGE_ENCRYPTION_STATUS:
      return {
        ...state,
        isDecryptionProgress: action.payload,
      };
    default:
      return state;
  }
};
