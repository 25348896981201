import React from "react";
import CN from 'classnames';

import styles from "./styles.module.scss";

type IProps = {
  className?: string,
  value: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  placeholder?: string,
}

const Button = ({ className, ...rest }: IProps) => {

  return (
    <input
      className={CN(styles.input, className)}
      {...rest}
    />
  )
}

export default Button;