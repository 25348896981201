// Refactoring №5
import { createSlice } from '@reduxjs/toolkit';

export const SORT_PAGE_TYPE = {
  main: 'root_files',
  folder: 'folder_files',
  favorite: 'favorites',
  shared: 'shares',
  deleted: 'deleted',
};

export const SORT_DIR = {
  asc: 'asc',
  desc: 'desc',
};

export const FILE_PREVIEW = {
  meta: 'meta',
  tokenize: 'tokenize',
  comment: 'comment',
  logs: 'logs',
  share: 'share',
  settings: 'settings',
  myAi: 'myAi',
};

const initialState = {
  technicalWorks: false,
  isMultisigActivated: false,
  isMultisigPartisipant: false,
  selectedFilePreview: FILE_PREVIEW.meta,
  sorter: {
    option: 'createdAt',
    dir: SORT_DIR.desc,
    page: SORT_PAGE_TYPE.main,
    initialCommit: true,
  },
  filter: {
    filterName: null,
    fileType: null,
    color: null,
    isTokenized: null,
  },
  tokensCount: 0,
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateSorter(state, { payload }) {
      state.sorter = {
        ...state.sorter,
        ...payload,
      };
    },
    updateFilter(state, { payload }) {
      state.filter = {
        ...state.filter,
        fileType: null,
        isTokenized: null,
        ...payload,
      };
    },
    resetFileFilter(state) {
      state.filter = {
        ...state.filter,
        fileType: null,
        isTokenized: null,
        filterName: null,
      };
    },
    updateFilterColor(state, { payload }) {
      state.filter.color = payload;
    },
    setMultisigActivated(state, { payload }) {
      state.isMultisigActivated = payload;
    },
    setMultisigPartisipant(state, { payload }) {
      state.isMultisigPartisipant = payload;
    },
    setTokenCount(state, { payload }) {
      state.tokensCount = payload;
    },
    extractTokensAction(state, { payload }) {
      state.tokensCount = Number(state.tokensCount) - payload;
    },
    updateSelectedFilePreview(state, { payload }) {
      state.selectedFilePreview = payload;
    },
    resetSelectedFilePreview(state) {
      state.selectedFilePreview = FILE_PREVIEW.meta;
    },
    cleanAppState() {
      return { ...initialState };
    },
  },
});

const { reducer, actions } = slice;

export const {
  updateSorter,
  updateFilter,
  cleanAppState,
  setTokenCount,
  resetFileFilter,
  updateFilterColor,
  extractTokensAction,
  setMultisigActivated,
  setMultisigPartisipant,
  resetSelectedFilePreview,
  updateSelectedFilePreview,
} = actions;

export default reducer;

export const selectTechnicalWorksState = (state) => state.app.technicalWorks;
export const selectSorterState = (state) => state.app.sorter;
export const selectTokenCountState = (state) => state?.app?.tokensCount;
export const selectFilterState = (state) => state.app.filter;
export const selectIsMultisigActivated = (state) =>
  state.app.isMultisigActivated;
export const selectIsMultisigPartisipant = (state) =>
  state.app.isMultisigPartisipant;
export const selectSelectedFilePreview = (state) =>
  state.app.selectedFilePreview;
