//Refactoring №3
import React from 'react';

import PropTypes from 'prop-types';

//import { ReactComponent as EmptyFolderLogo } from 'static/assets/svg/empty-folder.svg';

import { ReactComponent as EmptyFolderLogo } from 'components/shared/Preloader/assets/logo.svg'

import s from './style.module.scss';

const EmptyFolder = ({ title, description }) => {
  return (
    <section className={s.empty}>
      <EmptyFolderLogo className={s.img} />
      <h4 className={s.title}>{title}</h4>
      <p className={s.description}>{description}</p>
    </section>
  );
};

EmptyFolder.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

EmptyFolder.defaultProps = {
  title: 'Empty',
  description: (
    <span>
      Drag the file here or press the
      <br /> button to drop files
    </span>
  ),
};

export default EmptyFolder;
