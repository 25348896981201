import React from 'react';
import CN from 'classnames';
import useIsRegistrationFlow from 'utils/hooks/use-is-registration-flow';

import styles from './styles.module.scss';

const NeyraSidebar = ({ children, className }) => {
  const isRegistrationFlow = useIsRegistrationFlow();

  if (isRegistrationFlow) {
    return null;
  }
  return <div className={CN(styles.sidebar, className)}>{children}</div>;
};

export default NeyraSidebar;
