//Refactoring №5
import React from 'react';

import { ReactComponent as LightLogo } from 'containers/auth/components/Header/assets/lightLogo.svg';

const Header = ({ className }) => (
  <header className={className}>
    <LightLogo />
  </header>
);

export default Header;
