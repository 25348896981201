import React from 'react';

const Warning = ({
  color,
  width = 16,
  viewBoxWidth = 16,
  viewBoxHeight = 16,
  height = 16,
  className,
}) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.501 10.85l-5.375-8.682a2.5 2.5 0 00-4.252 0L.5 10.85a2.5 2.5 0 002.125 3.816h10.752A2.5 2.5 0 0015.5 10.85zm-1.106 1.884a1.153 1.153 0 01-1.02.6H2.626a1.166 1.166 0 01-.992-1.781l5.375-8.684a1.167 1.167 0 011.984 0l5.376 8.683a1.154 1.154 0 01.027 1.183z"
      fill={color}
    />
    <path
      d="M7.333 5.625h1.333v4H7.333v-4zM7.333 10.524h1.333v1.333H7.333v-1.333z"
      fill={color}
    />
  </svg>
);

Warning.defaultProps = {
  className: '',
  color: '#ED1D2D',
};

export default Warning;
