import React from 'react';

const Print = ({ color, width, height, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    width={width}
    height={height}
    viewBox="162 -760 670 670"
  >
    <path
      fill={color}
      d="M645-610v-124H315v124h-22v-146h374v146h-22Zm-461 22h592-592Zm521.553 97.5q15.447 0 26.697-10.82t11.25-25.75q0-14.93-11.166-26.18t-26.238-11.25q-16.096 0-26.346 11.171t-10.25 26.25q0 15.079 10.303 25.829 10.302 10.75 25.75 10.75ZM645-194v-198H315v198h330Zm22.25 22h-374v-165.2H162V-547q0-25.5 18.5-44.25T226-610h508q26.75 0 45.375 18.75T798-547v210H667.25v165ZM776-359v-188.215q0-16.36-12.111-28.572Q751.778-588 733.875-588H226.482q-17.832 0-30.157 12.225T184-547v188h109v-55h374v55h109Z"
    />
  </svg>
);

Print.defaultProps = {
  color: 'currentColor',
  width: 48,
  height: 48,
  style: {},
};

export default Print;
