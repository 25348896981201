//Refactoring №5
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import CN from 'classnames';

import { ReactComponent as EyeIcon } from 'components/VaultModal/assets/eye.svg';
import InputFieldFormik from 'components/InputFieldFormik';
import commonWords from 'config/commin-words';
import { validationRegExp } from 'utils/validation/regexp';

import styles from './style.module.scss';

type IProps = {
  className?: string,
  [key: string]: any;
}

const AddModal = ({ className, ...props }: IProps) => {
  const { t } = useTranslation('contextMenu');
  const [sureness, setSureness] = useState('');
  const isWeakPassword = useCallback((password: string) => {
    const result = { length: false, upLowCase: false };
    if (password.length < 8 && password.length > 2) result.length = true;

    if (
      (validationRegExp.hasLowCaseCharacter.test(password) &&
        !validationRegExp.hasUpperCaseCharacter.test(password)) ||
      (validationRegExp.hasLowCaseCharacter.test(password) &&
        !validationRegExp.hasUpperCaseCharacter.test(password))
    )
      result.upLowCase = true;

    return result.length && result.upLowCase;
  }, []);

  const isMiddlePassword = useCallback((password: string) => {
    const result = { length: false, upLowCase: false, commonWord: false };
    if (password.length > 8) result.length = true;
    if (
      validationRegExp.hasUpperCaseCharacter.test(password) &&
      validationRegExp.hasLowCaseCharacter.test(password)
    )
      result.upLowCase = true;
    if (commonWords.indexOf(password) === -1) result.commonWord = true;

    return result.length && result.upLowCase && result.commonWord;
  }, []);

  const isStrongPassword = useCallback((password: string) => {
    const result = { length: false, upLowCase: false, commonWord: false };
    if (password.length > 12) result.length = true;
    if (
      validationRegExp.hasUpperCaseCharacter.test(password) &&
      validationRegExp.hasLowCaseCharacter.test(password) &&
      validationRegExp.hasDigits.test(password) &&
      validationRegExp.isContainSpecialCharacters.test(password)
    )
      result.upLowCase = true;
    if (!commonWords.some((commonWord) => password.indexOf(commonWord) !== -1))
      result.commonWord = true;

    return result.length && result.upLowCase && result.commonWord;
  }, []);

  const calculatePassword = useCallback((password: string) => {
    if (!password || password.length <= 2) return setSureness('');

    if (isStrongPassword(password))
      setSureness(t('main.addPassword.strong').toLowerCase());
    else if (isMiddlePassword(password))
      setSureness(t('main.addPassword.middle').toLowerCase());
    else if (isWeakPassword(password))
      setSureness(t('main.addPassword.weak').toLowerCase());
  }, []);

  const handleChange = useCallback((value: string) => {
    calculatePassword(value);
  }, []);

  return (
    <div className={styles.password}>
      <InputFieldFormik
        {...props}
        type="password"
        className={className}
        hideErrorMessage
        handleChange={handleChange}
        isPassword
        customEye={<EyeIcon />}
        autoComplete="new-password"
      />
      <div className={styles.progressWrapper}>
        <div className={styles.progressBar}>
          <div
            className={CN(styles.activeBar, styles[`activeBar-${sureness}`])}
          />
        </div>
        <p
          className={CN(
            styles.progressLabel,
            styles[`activeLabel-${sureness}`]
          )}
        >
          {capitalize(sureness)}
        </p>
      </div>
    </div>
  );
};

export default AddModal;
