import { React } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Spinner from 'components/Spinners';
import PublishWidget from './PublishWidget';
import { CopyButton } from 'components/Button';
import { selectNeyrAIFiles } from 'features/neyra';
import { selectTokenCountState } from 'features/app';
import { formatNumberToTokens, copyToClipboard } from 'utils/string';
import actionsOptions from 'config/actions-options';
import useNotification from 'utils/hooks/use-notification';
import fileFolderActionHandlerEffect from 'store/home/effects/entity-actions/entity-action-handler.effect';

import { ReactComponent as PlusIcon } from 'containers/main/NeyraDriveContainer/components/assets/plus.svg'

import styles from './styles.module.scss';

const MyAiTab = ({ entity }) => {
  const dispatch = useDispatch();
  const { addNotification } = useNotification();
  const aiFiles = useSelector(selectNeyrAIFiles);
  const tokenCountRedux = useSelector(selectTokenCountState);
  const aiFile = aiFiles.find((item) => item.uid === entity.slug);
  const isPending = !!aiFile && !aiFile?.ai;

  const handleCreateReport = () => {
    dispatch(fileFolderActionHandlerEffect(entity, actionsOptions.myAI, { addNotification }));
  }

  const onCopy = () => {
    copyToClipboard(aiFile?.ai)
  }

  if (!aiFile || isPending) {
    return (
      <div className={styles.myAiTab}>
        <h2 className={styles.label}>
          Neyra Report
        </h2>
        <div className={styles.infoRow}>
          <p className={styles.name}>Network fee</p>
          <span className={styles.value}>0,5</span>
        </div>
        <div className={styles.infoRow}>
          <p className={styles.name}>Available credits</p>
          <span className={styles.value}>{formatNumberToTokens(
            Math.round(parseFloat(tokenCountRedux)) || 0,
            3
          )}</span>
        </div>
        { isPending ? (
          <div className={styles.statusBlock}>
            <div className={styles.statusText}>
              <span>Status</span>
              <PlusIcon className={styles.statusPlus} />
            </div>
            <div className={styles.loader}>
              <Spinner />
            </div>
          </div>
        ) : (
          <button
            className={styles.createBtn}
            onClick={handleCreateReport}
          >
            Run Report
          </button>
        )}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.myAiTab}>
        <div className={styles.header}>
          <h2 className={styles.label}>
            Neyra Report
          </h2>
          <CopyButton onClick={onCopy} className={styles.copyBtn} />
        </div>
        <p className={styles.text}>{aiFile?.ai}</p>
      </div>
      <PublishWidget />
    </div>
  );
};

export default MyAiTab;
