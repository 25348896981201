// Refactoring №3
import {
  PageActionTypes,
  FolderActionTypes,
  WorkspaceActionTypes,
  NotificationActionTypes,
  AccountActionTypes,
  TrashActionTypes,
} from '../actions';

export const defaultState = {
  pageName: '',
  pageUrl: '/main',
  isDeletedPage: false,
  isActionPage: false,
  isRecentPage: false,
  isLinkPage: false,
  isNotificationPage: false,
  folder: null,
  workspace: { name: '', color: '' },
  workspaces: [],
  notifications: [],
  breadcrumbs: undefined,
  isAccountDataChanged: false,
  saveAccountData: async () => {},
  trashHeaderActionHandler: async () => {},
  isClearAllPressed: false,
};

export const initiateState = (initState) => initState;

export default (state, { payload, type }) => {
  const {
    folder,
    breadcrumbs,
    name,
    url,
    additionalLabel,
    workspace,
    workspaces,
    isDeletedPage,
    isActionPage,
    isRecentPage,
    isDataChanged,
    saveAccountData,
    trashHeaderActionHandler,
    isNotificationPage,
    isDocumentPage,
    isVaultPage,
    isLinkPage,
    isFilteredFilesPage,
    isClearAllPressed,
    isAiGeneratorPage,
    isProductsPage,
  } = payload;

  switch (type) {
    case PageActionTypes.setCurrentPage:
      return {
        ...state,
        pageName: name,
        pageUrl: url,
        additionalLabel,
        isDeletedPage,
        isActionPage,
        isRecentPage,
        isNotificationPage,
        isDocumentPage,
        isVaultPage,
        isLinkPage,
        isFilteredFilesPage,
        isAiGeneratorPage,
        isProductsPage,
      };

    case FolderActionTypes.setCurrentFolder:
      return {
        ...state,
        folder,
        breadcrumbs,
      };

    case WorkspaceActionTypes.setCurrentWorkspace:
      return {
        ...state,
        workspace,
        workspaces: state.workspaces.map((ws) =>
          ws.id === workspace.id ? workspace : ws
        ),
      };

    case WorkspaceActionTypes.setWorkspaces:
      return {
        ...state,
        workspaces,
      };

    case NotificationActionTypes.addNotification:
      return {
        ...state,
        notifications: [...state.notifications, payload.notification],
      };

    case NotificationActionTypes.removeNotification:
      return {
        ...state,
        notifications: state.notifications.filter(
          (item) => item?.id !== payload.notification?.id
        ),
      };

    case NotificationActionTypes.clearAll:
      return {
        ...state,
        notifications: [],
      };

    case AccountActionTypes.setIsAccountDataChanged:
      return {
        ...state,
        isAccountDataChanged: isDataChanged,
      };

    case AccountActionTypes.saveAccountData:
      return {
        ...state,
        saveAccountData,
      };

    case TrashActionTypes.trashHeaderActionHandler:
      return {
        ...state,
        trashHeaderActionHandler,
      };

    case TrashActionTypes.clearAllAction:
      return {
        ...state,
        isClearAllPressed,
      };

    default:
      return state;
  }
};
