import {
  all,
  call,
  put,
  take,
  select,
  takeEvery,
  fork,
} from 'redux-saga/effects';
import {
  addTask,
  addTaskSuccess,
  addTaskError,
  addUser,
  removeUser,
  removeUserSuccess,
  loadAllTasks,
  loadAllTasksSuccess,
  loadAllTasksError,
  loadAllUsers,
  loadAllUsersSuccess,
  loadAllUsersError,
  loadTask,
  loadTaskSuccess,
  loadTaskError,
  updateTask,
  updateTaskSuccess,
  updateTaskError,
  selectEditableTask,
  deleteTask,
  deleteTaskError,
  deleteTaskSuccess,
  selectAllTasks,
  deleteMultiTasks,
} from '../taskManagerSlice';
import authRequest from 'utils/request/auth-request';
import { API_WORKSPACE, API_TASK_MANAGER } from 'constants/api-urls';

export default function* taskManagerSaga() {
  yield all([
    watchAddTaskAction(),
    watchLoadAllTasksAction(),
    watchLoadAllUsersAction(),
    watchLoadTaskAction(),
    watchUpdateTaskAction(),
    watchDeleteTaskAction(),
    watchDeleteMultiTasksAction(),
    wathAddUserToTask(),
    wathRemoveUserFromTask(),
  ]);
}

function* watchLoadAllUsersAction() {
  yield takeEvery(loadAllUsers.type, loadAllUsersSaga);
}

function* loadAllUsersSaga() {
  try {
    const { data } = yield call(apiLoadAllUsers);
    yield put(loadAllUsersSuccess(data.data));
  } catch (e) {
    yield put(loadAllUsersError());
  }
}

async function apiLoadAllUsers() {
  return await authRequest.get(`${API_WORKSPACE}/members`);
}

function* watchLoadAllTasksAction() {
  yield takeEvery(loadAllTasks.type, loadAllTasksSaga);
}

function* loadAllTasksSaga() {
  try {
    const { data } = yield call(apiLoadAllTasks);
    const tasks = data?.data?.length ? [...data.data] : [];
    yield put(loadAllTasksSuccess(tasks));
  } catch (e) {
    yield put(loadAllTasksError());
  }
}

async function apiLoadAllTasks() {
  return await authRequest.get(API_TASK_MANAGER);
}

function* watchAddTaskAction() {
  while (true) {
    const action = yield take(addTask.type);
    let taskData = { ...action.payload };
    delete taskData.assignedTo;

    try {
      const { data: addTaskResponse } = yield call(apiAddTask, taskData);
      yield put(addTaskSuccess());

      const userEmails = action?.payload?.assignedTo;
      if (userEmails?.length) {
        yield call(apiAddUsers, {
          taskId: addTaskResponse.id,
          userEmails,
        });
      }
      yield put(loadAllTasks());
    } catch (e) {
      yield put(addTaskError());
    }
  }
}

export async function apiAddTask(task) {
  const result = await authRequest.post(API_TASK_MANAGER, task);
  return result.data;
}

function* wathAddUserToTask() {
  yield takeEvery(addUser.type, function* ({ payload }) {
    try {
      const {
        data: { data },
      } = yield call(apiAddUsers, payload);
      yield put(loadAllTasks());
      yield put(loadTaskSuccess(data));
      // eslint-disable-next-line no-empty
    } catch (e) {}
  });
}

function* wathRemoveUserFromTask() {
  yield takeEvery(removeUser.type, function* ({ payload }) {
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = yield call(apiRemoveUsers, payload);
      yield put(loadAllTasks());
      yield put(removeUserSuccess(payload.userId));
      // eslint-disable-next-line no-empty
    } catch (e) {}
  });
}

async function apiAddUsers({ taskId, userEmails }) {
  return await authRequest.post(`${API_TASK_MANAGER}/${taskId}/user`, {
    input: userEmails,
  });
}

async function apiRemoveUsers({ taskId, userId }) {
  return await authRequest.delete(
    `${API_TASK_MANAGER}/${taskId}/user/${userId}`
  );
}

function* watchLoadTaskAction() {
  yield takeEvery(loadTask.type, loadTaskSaga);
}

function* loadTaskSaga({ payload: taskId }) {
  try {
    const response = yield call(apiLoadTask, taskId);
    yield put(loadTaskSuccess(response));
  } catch (e) {
    yield put(loadTaskError());
  }
}

async function apiLoadTask(taskId) {
  const result = await authRequest.get(`${API_TASK_MANAGER}/${taskId}`);
  return result.data;
}

function* watchUpdateTaskAction() {
  yield takeEvery(updateTask.type, updateTaskSaga);
}

function* updateTaskSaga({
  payload: {
    taskId,
    title,
    description,
    estimation,
    priority,
    assignedTo: currentTaskUsersEmails,
    status,
  },
}) {
  try {
    const {
      data: { data },
    } = yield call(apiUpdateTask, {
      taskId,
      title,
      description,
      estimation,
      priority,
      status,
    });
    yield put(updateTaskSuccess(data));
    const {
      data: { taskUsers: previousTaskUsers },
    } = yield select(selectEditableTask);

    //filter already assigned users from currentTaskUsers
    const assignedToTaskUsers = currentTaskUsersEmails.filter(
      (cu) => !previousTaskUsers.some((pu) => pu.email === cu)
    );

    if (assignedToTaskUsers?.length) {
      // eslint-disable-next-line no-unused-vars
      const { data: addUsersResponse } = yield call(apiAddUsers, {
        taskId: taskId,
        userEmails: assignedToTaskUsers,
      });
    }
    yield put(loadAllTasks());
  } catch (e) {
    yield put(updateTaskError());
  }
}

async function apiUpdateTask({
  taskId,
  title,
  description,
  estimation,
  priority,
  status,
}) {
  return await authRequest.put(`${API_TASK_MANAGER}/${taskId}`, {
    title,
    description,
    estimation,
    priority,
    status,
  });
}

function* watchDeleteTaskAction() {
  yield takeEvery(deleteTask.type, deleteTaskSaga);
}

function* deleteTaskSaga({ payload: taskId }) {
  try {
    // eslint-disable-next-line no-unused-vars
    const { data } = yield call(apiDeleteTask, taskId);
    yield put(deleteTaskSuccess());
    yield put(loadAllTasks());
  } catch (e) {
    put(deleteTaskError());
  }
}

async function apiDeleteTask(taskId) {
  const result = await authRequest.delete(`${API_TASK_MANAGER}/${taskId}`);
  return result.data;
}

function* watchDeleteMultiTasksAction() {
  yield takeEvery(deleteMultiTasks.type, deleteMultiTasksSaga);
}

function* deleteMultiTasksSaga() {
  const { data } = yield select(selectAllTasks);
  const selectedTasks = Object.values(data).filter((item) => item.checked);

  yield call(function* () {
    for (const task of selectedTasks) {
      yield fork(deleteTaskSaga, { payload: task.id });
    }
  });
  put(deleteTaskSuccess());
}
