import { isEmpty, uniqWith, isEqual } from "lodash";

const MAX_MESSAGE_LENGTH = 1500;

const getTitle = (dialog) => {
  if (dialog.title || dialog.username) {
    return dialog.title || dialog.username 
  }

  if (dialog.firstName || dialog.lastName)
    return `${dialog.firstName || ''} ${dialog.lastName || ''}`.trim();
  if (dialog.username)
    return dialog.username;
}

const getUsername = (user, messagesObject) => {
  if (!user && !isEmpty(messagesObject.chats)) {
    const chat = messagesObject.chats[0];
    return chat.username || chat.title
  }

  if (user.firstName || user.lastName)
    return `${user.firstName || ''} ${user.lastName || ''}`.trim();
  if (user.username)
    return user.username;

  return user.id.toString()
}

const getMessage = (message) => {
  if (message.length > MAX_MESSAGE_LENGTH) 
    return message.split(0, MAX_MESSAGE_LENGTH)
  return message
}

const generateReport = (messagesObject) => {
  const records = [];

  messagesObject?.messages?.forEach((message) => {
    if (message.message) {
      const user = messagesObject.users
      .find((user) => user.id.toString() === message._senderId.toString());
      const username = getUsername(user, messagesObject)
      const messageString = getMessage(message.message)
      records.push(`${username}: ${messageString}`)
    }
  });

  return records.join('\n')
}

const margePageMessages = (array, count) => {
  let margetPages;

  array.forEach((page, index) => {
    if (index === 0) {
      margetPages = { ...page }
    } else {
      margetPages = {
        ...margetPages,
        chats: [ ...margetPages.chats, ...page.chats ],
        users: [ ...margetPages.users, ...page.users ],
        messages: [ ...margetPages.messages, ...page.messages ],
      }
    }
  })

  margetPages = {
    ...margetPages,
    chats: uniqWith(margetPages.chats, isEqual),
    users: uniqWith(margetPages.users, isEqual),
  }

  margetPages = {
    ...margetPages,
    messages: margetPages.messages.slice(0, count),
  }

  return margetPages
}

export { generateReport, margePageMessages, getTitle }