import React from 'react';

const IconPolygon = () => {
  return (
    <svg
      width="30"
      height="18"
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.87421 17L15 1.48615L24.1258 17H5.87421Z"
        stroke="currentColor"
      />
    </svg>
  );
};

export default IconPolygon;
