export const GET_USER_TARIFF = 'GET_USER_TARIFF'
export const GET_USER_TARIFF_SUCCESS = 'GET_USER_TARIFF_SUCCESS'
export const GET_USER_TARIFF_FAILURE = 'GET_USER_TARIFF_FAILURE'
export const GET_USER_TARIFF_CLEAR = 'GET_USER_TARIFF_CLEAR'

export function getUserTariff() {
  return {
    type: GET_USER_TARIFF,
  }
}

export function getUserTariffSuccess(data) {
  return {
    type: GET_USER_TARIFF_SUCCESS,
    payload: data,
  }
}

export function getUserTariffFailure() {
  return {
    type: GET_USER_TARIFF_FAILURE,
  }
}

export function getUserTariffClear() {
  return {
    type: GET_USER_TARIFF_FAILURE,
  }
}
