export default (blobUrl, name, type) => {
  const link = document.createElement('a');

  link.href = blobUrl;
  if (type == null) {
    link.download = name;
  } else {
    link.download = name + `.${type}`;
  }
  document.body.appendChild(link);

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  document.body.removeChild(link);
};
