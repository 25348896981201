// New
export const LINKS_GET = 'LINKS_GET';
export const LINKS_GET_SUCCESS = 'LINKS_GET_SUCCESS';
export const GET_LINKS_REMOVE = 'GET_LINKS_REMOVE';
export const RESET_STATE = 'LINKS_RESET_STATE';
export const SET_LINKS = 'SET_LINKS';
export const LINKS_GET_FAILURE = 'LINKS_GET_FAILURE';
export const UPDATE_LINKS_SUCCESS = 'UPDATE_LINKS_SUCCESS';
export const DELETE_LINKS_SUCCESS = 'DELETE_LINKS_SUCCESS';

export const linksGet = (payload) => ({
  type: LINKS_GET,
  payload: payload,
});

export const linksGetSuccess = (data) => ({
  type: LINKS_GET_SUCCESS,
  payload: data,
});

export const removeSuccess = (data) => ({
  type: GET_LINKS_REMOVE,
  payload: { data },
});

export const vaultsGetFailure = (err) => ({
  type: LINKS_GET_FAILURE,
  payload: err,
});

export const resetState = () => ({
  type: RESET_STATE,
});

export const setLinks = (links) => ({
  type: SET_LINKS,
  payload: links,
});

export const updateLinkSuccess = (link) => ({
  type: UPDATE_LINKS_SUCCESS,
  payload: link,
});

export const deleteLinkSuccess = (slug) => ({
  type: DELETE_LINKS_SUCCESS,
  payload: slug,
});
